import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

const FootstepsContentComponent = observer(class FootstepsContentComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }


	render(){
		const { appstore,
				chatComponentStore } = this.props;
		var self = this;

		return (
			<div className="tabContent">
				{
				  	!chatComponentStore.hideInfoSection && 
				  	<React.Fragment>
						<h6>{appstore.getLanguageTrans("Footsteps")}</h6>
						<div className="col-12 footstepsWrapper data">
						{
							chatComponentStore.footstepsTabContent.map(function(data, dataIndex) {
								return (
									<div className="row" key={dataIndex}>
										<div className="col-2 label">
											<p className="time">{data.time}</p>
										</div>
										<div className="col-10 value" title={data.formUrl}>
											<p className="link">{data.page} <img style={{cursor:"pointer"}} onClick={() => window.open(data.formUrl,'_blank')} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.boxlink_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.boxlink_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.boxlink_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.boxlink_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.boxlink_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.boxlink_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.boxlink_voilet : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.boxlink_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.boxlink_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.boxlink_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.boxlink_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.boxlink_skyblue : imageURLs.boxlink_steelblue} className="img-fluid" /></p>
										</div>
									</div>
								)
							})
						}
						</div>
					</React.Fragment>
				}
			</div>
		)
	}
})
export default FootstepsContentComponent;
