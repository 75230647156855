import { decorate, observable, extendObservable } from 'mobx';
import axios from 'axios';

class ChatInvitationStore {
	  

  	constructor(appstore){
        this.appStore=appstore;
  		this.countryDropDownOptions = [
                {value:1, label:'England'},
                {value:2, label:'France'},
                {value:3, label:'Spain'}
                ];
        
        this.invitations={
        're_invitation' : 0,
        'autoInvitation' :  this.appStore.chatautoInvitation,
        'default_message' : "",
        'default_time_passed' : "",
        'website_uid': this.appStore.selectedWebisiteUid
        }
        this.invitationsloader=false;
        this.errormodel=false; 
        this.inputState=false;
        this.inputStateColor={backgroundColor:"#dedede"};
     }

     setCountry(selectedOption) {
     	this.selectedCountry = selectedOption;
     }

     validatetruefallse(e){ 
      this.inputState=e;
      if(e==true){
         this.inputStateColor={backgroundColor:"#04040400"};
      }else{
         this.invitations.default_time_passed=0;
      this.inputStateColor={backgroundColor:"#dedede"};
      }
     }
     cancelManuvalInvitation(){ 
      this.invitations.re_invitation=this.appStore.userInfo.accountsmodel.re_invitation;
     }
     cancelAutoInvitation(){  
      // this.invitations.autoInvitation=this.appStore.chatautoInvitation; 
      // this.invitations.default_time_passed=this.appStore.userInfo.accountsmodel.default_time_passed  
      
      this.invitations= Object.assign({},this.backupInvitations);    
         this.validatetruefallse(this.invitations.autoInvitation)
       
     }
     savemanuvalInvitation(){   
      this.saveInvitation(); 
     }
     saveAutoInvitation(){  
               if(this.invitations.autoInvitation == true && this.invitations.default_time_passed !=""){
                   this.saveInvitation();
               }
               if(this.invitations.autoInvitation == false){
                  this.saveInvitation();
               } 
      }
 saveInvitation(){
   this.invitationsloader=true; 
   if(this.invitations.default_message.trim()==""){
      this.invitations.default_message="Hello, may I help you with anything?";
   }
   axios({
      method: 'POST', 
      url:this.appStore.url+'chatInvitaions.json',
      headers: { 'content-type': 'application/json' },
      data: this.invitations,
    }).then(result=> {
                 // console.log('chatInvitation response*******');
                  // console.log(result.data); 
                  if(result.data.accountsmodel.length==1){
                     if(result.data.accountsmodel.logoutstatus==1){
                       this.appStore.logout();
                     }
                     }
                  let self=this;
                  if(result.data.accountsmodel.querystatus == 0){
                     self.invitationsloader=false;
                     this.errormodel=true; 
                  }else{                     
                     this.appStore.chatautoInvitation=this.invitations.autoInvitation;
                     this.appStore.userInfo.accountsmodel=result.data.accountsmodel; 
                     if(result.data.allwebistes!=null){
                        this.appStore.userInfo.allwebistes=result.data.allwebistes;
                     }
                     //clone object for cancel button option
                     this.backupInvitations= Object.assign({},this.invitations);                             
                  setTimeout(function() {
                     self.invitationsloader=false;
                  }, 500);
                  }     
                  this.appStore.global_errorcount=0;
      }).catch((error) => {
            //  console.log(error); 
              this.errormodel=true; 
            //   this.props.appstore.logout();
            this.invitationsloader=false;
            console.log(error);
            console.log("error count :"+this.appStore.global_errorcount);
            this.appStore.global_errorcount=this.appStore.global_errorcount+1;
            if(this.appStore.global_errorcount>20){
            // this.appStore.logout();
            }
         }); 
   } 
}

 decorate(ChatInvitationStore, {
     selectedCountry: observable,
     re_invitation: observable,
     autoInvitation: observable,
     default_time_passed: observable,
     default_message: observable,
     invitations:observable,
     invitationsloader:observable,
     errormodel:observable,
     inputState:observable,
     backupInvitations:observable  
    })
 export default ChatInvitationStore;
