import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';


const InvitationImageLibraryModal = observer(class InvitationImageLibraryModal extends Component {

	constructor (props) {
        super(props);
        this.state = {
        	selectedImage: {}
        }

    }

	componentDidMount(){
	}

	setImageIndex(ImageObj){
		this.setState({
			selectedImage: ImageObj
		})
	}


	componentWillUnmount() {
    }

	render(){
		const parentScroll = {
			display: "flex",
			height: "55vh"
		}
		var self = this;

		const { appStore,
				store,
				show } = this.props;

		return (  
				<Modal backdrop='static' className={(appStore ? appStore.selectedColor.value : '')+" imgLibrary"} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => store.getImageFromLibrary = false}>
				  <Modal.Header closeButton>
				    <Modal.Title>{appStore.getLanguageTrans("Image Library")}</Modal.Title>
				  </Modal.Header> 
				<div style={parentScroll} className="custom_img_tour" >
					<CustomScroll flex="1" heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
						<Modal.Body>
							<div style={parentScroll}>
								<div id="ext-comp-3573" className="HelpOnClick-FontIconsLibrary imageLibrary">
									{
										store.invitationImages.map(function (imageObj, imageIndex) {
											return (
												<img key={imageIndex} className={imageObj.value == self.state.selectedImage.value ? 'selected' : ''} src={imageObj.img} onClick={() => self.setImageIndex(imageObj)} />
											)
										})
									}
									<div className="x-clear"></div>
								</div>
							</div>
						</Modal.Body>
					</CustomScroll>
				</div>
				  <Modal.Footer>
				    <Button className="btn_cancel" variant="secondary" onClick={() => store.getImageFromLibrary = false}>{appStore.getLanguageTrans("Close")}</Button>
				    <Button className="btn_cancel" variant="primary" onClick={() => this.props.callBack(this.state.selectedImage)}>{appStore.getLanguageTrans("Select")}</Button>
				  </Modal.Footer>
				</Modal> 
		)
	}
})
export default InvitationImageLibraryModal;
