import React, {Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button} from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';

const HelpTourComponent = observer(class HelpTourComponent extends Component {

	constructor (props) {
        super(props);

    }
	componentWillMount(){

	}

	render(){
		let self =this;
		const {show, 
			   store,
				callBack,
				body_html,
				next_btn,
				appStore} = this.props;
			
		return (
			<Modal backdrop='static' id={"tourStep"+(store.stepNumber+1)} className={'helpTourCommon '+(appStore ? appStore.selectedColor.value : '')+ ' helpTourStep'+(store.stepNumber+1)+" "+ (appStore.userInfo.accountsmodel.display_buy_now ==0 && " helpTourStepWithoutBuynow"+(store.stepNumber+1))} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>{store.help_tour = false;store.closeTour(appStore);}}>
				  <Modal.Header closeButton>
				    <Modal.Title></Modal.Title>
				  </Modal.Header>
				  <Modal.Body>
				    <div class="stepContent">
				         <span style={{paddingLeft: store.stepNumber>8 ? 6 : 10}}>{store.stepNumber+1} </span>{body_html}
				    </div>
				  </Modal.Body>

				  <Modal.Footer>
				    <Button className="btn_save" variant="primary" onClick={() => this.props.callBack()}>{next_btn}</Button>
				  </Modal.Footer>
			</Modal>
		)
	}
})
export default HelpTourComponent;
