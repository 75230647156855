import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import imageURLs from '../../sharedFiles/ImagesURLs';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import EmailTranscriptPopup from '../../sharedComponents/email_transcripts/EmailTranscriptPopup'; 
import Iframe from 'react-iframe'
import ReactToPrint from 'react-to-print';

import { ComponentToPrint } from './ComponentToPrint';
import $ from 'jquery';

const moment = extendMoment(originalMoment);

const ChatInfoComponent = observer(class ChatInfoComponent extends Component {

	constructor(props) {
		super(props);

	
	}
	componentWillMount() {

	}
	btn_printchatinfo =()=>{
		alert();
		$("#btn_printchatinfo").click();

	}
	printChatdata =(chatdata,appStore) =>{
		var printchat="";
		let styles = 'p{margin:10};.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }'
		+' .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 { float: left; }'
		+' .col-12 { width: 100%; } .col-11 { width: 91.66666667%; } .col-10 { width: 83.33333333%; } .col-9 { width: 75%; }'
		+' .col-8 { width: 66.66666667%; } .col-7 { width: 58.33333333%; } .col-6 { width: 50%; } .col-5 { width: 41.66666667%; }'
		+' .col-4 { width: 33.33333333%; } .col-3 { width: 25%; } .col-2 { width: 16.66666667%; } .col-1 { width: 8.33333333%; }; @media print { @page { margin: 20mm; }	} ';
		let self = this;
		let data = chatdata.information;
		let datatranscript = chatdata.transcript;
		printchat=printchat+'<html><head><title>Print Preview</title><style>'+styles+'</style></head><body>';
		printchat=printchat+'<p style="font-size: 16px;font-weight: 600;"><u>' + appStore.getLanguageTrans("Information") + '</u></p>';
		printchat=printchat+'<table border="0" style="word-break: break-word;margin:10px"><tbody>';
		for (let i in data) {
			if (data[i].value != null) {
				let value=data[i].value;
				if(data[i].type ==="dropdown"){
					value=data[i].params[data[i].value].replace(/[\[\]"]+/g,'');
				}else{
					value=data[i].name == "Date" ? moment(data[i].value).format("DD.MM.YYYY"):data[i].value;
				} 
				printchat=printchat+'<tr><td style="width:30%;color: #000000;font-family: Segoe UI Semi Bold;'
					+ 'font-size: 14px;font-weight: 600;">' + data[i].name + ' :  <span style="font-weight: 400">'+value+'</span></td></tr>';
			} 
		};
		printchat=printchat+'<tbody></table>';
		//FULL CONTACT
		if(chatdata.fc!="" && chatdata.fc!=undefined && (chatdata.fc.img != "" || chatdata.fc.full_name != "" || chatdata.fc.gender != "" || 
		chatdata.fc.location != "" || chatdata.fc.social_profiles.length>0 )){ 
			printchat=printchat+'<hr>';
			printchat=printchat+'<p style="font-size: 16px;font-weight: 600;">' + appStore.getLanguageTrans("Information discovered on the web about this visitor") + '</p>';
			printchat=printchat+'<div class="row">';
			var stylerows="margin-left:15px";
			if(chatdata.fc.img!="" && chatdata.fc.img!=undefined){
				stylerows="";
				printchat=printchat+'<div class="col-3"><img alt="'+chatdata.fc.imgfrom+'" style="margin-left:20px;max-height:100px;max-width:100px" src="'+chatdata.fc.img+'" title="'+chatdata.fc.imgfrom+'" /> </div>';
			  
			}
			printchat=printchat+'<div class="col-9">';
			if(chatdata.fc.full_name!="" && chatdata.fc.full_name!=undefined){
				printchat=printchat+'<div class="row" style="'+stylerows+'"><div class="col-12"><p>'+appStore.getLanguageTrans("Full name :")+' '+chatdata.fc.full_name+'</p></div></div>';
			}
			if(chatdata.fc.gender!="" && chatdata.fc.gender!=undefined){
				printchat=printchat+'<div class="row" style="'+stylerows+'"><div class="col-3"><p>'+appStore.getLanguageTrans("Gender :")+' '+chatdata.fc.gender+'</p></div></div>';
			}

			if(chatdata.fc.location!="" && chatdata.fc.location!=undefined){
				printchat=printchat+'<div class="row" style="'+stylerows+'"><div class="col-3"><p>'+appStore.getLanguageTrans("Location :")+' '+chatdata.fc.location+'</p></div></div>';
			}
			if(chatdata.fc.social_profiles!="" && chatdata.fc.social_profiles.length>0){
				
				let socialprofiles=chatdata.fc.social_profiles;
				for(let j in socialprofiles){
					let socialttypeimg= socialprofiles[j].type=="googleplus" ? appStore.otherIcons+"op/images/social/googleplus.png" :
										socialprofiles[j].type=="twitter" ? appStore.otherIcons+"op/images/social/twitter.png" :
										socialprofiles[j].type=="facebook" ? appStore.otherIcons+"op/images/social/facebook.png" :
										socialprofiles[j].type=="linkedin" ? appStore.otherIcons+"op/images/social/linkedin.png" :
										socialprofiles[j].type=="youtube" ? appStore.otherIcons+"op/images/social/youtube.png" :"";
				   let socialttypetitle= socialprofiles[j].type=="googleplus" ? "google plus" :
										socialprofiles[j].type=="twitter" ? "twitter" :
										socialprofiles[j].type=="facebook" ? "facebook" :
										socialprofiles[j].type=="linkedin" ? "linkedin" :
										socialprofiles[j].type=="youtube" ? "youtube" :"";

										printchat=printchat+'<div class="row" style="'+stylerows+'"><div class="col-3"><p>'+appStore.getLanguageTrans("Social profiles :")+'  <img alt="Crop" style="max-height:16px;max-width:16;margin-left:5px;vertical-align:middle" src="'+socialttypeimg+'" title="'+socialttypetitle+'" /></p></div>';
					 
				} 
				printchat=printchat+'</div>';
			}  				  

			printchat=printchat+'</div></div>'; 
		}
		printchat=printchat+'<p style="font-size: 16px;font-weight: 600;"><u>' + appStore.getLanguageTrans("Transcript") + '</u></p>';
		printchat=printchat+'<table  border="0" style="word-break: break-word;margin:10px"><tbody>'; 
		for (let k in datatranscript) {
			var chatname="";
			var chatnamecolor="";
			if(datatranscript[k].op_name=="Visitor")
			{
				chatname=self.getTitle(this.props.chatData);
				chatnamecolor="red";
			}else{
				chatname=datatranscript[k].op_name;
				chatnamecolor="#36f";
			}
			let message= datatranscript[k].message.replace("<p></p>","");
			printchat=printchat+'<tr>'
				+ '<td style="color: '+chatnamecolor+';font-family: Segoe UI Semi Bold;padding: 3px 15px;'
				+ 'font-size: 14px;font-weight: 600; width:10%;padding: 3px 15px;">' + chatname + '</td>'
				+ '<td style="color: rgb(173, 173, 173); width:12%;padding: 3px 15px;">(' + moment(self.convertUTCDateTime(datatranscript[k].date)).format("HH:mm:ss") + ')</td>'
				+ '<td style="width:60%;padding: 3px 15px;"> ' + message + '</td></tr>';
		}
		printchat=printchat+'<tbody></table>';
		printchat=printchat+'</body></html>';
		return printchat;
	}
	printChat =(chatdata,appStore) =>{
		// document.getElementsByClassName('HelpOnClick HelpOnClick-ChatPrinter')[0].style.display="block";
		// var printContents = document.getElementById(divName).innerHTML;
		// var originalContents = document.body.innerHTML;
		// document.body.innerHTML = printContents;
		// window.print('_blank');
		//document.body.innerHTML = originalContents;

		let styles = '.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }'
		+' .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 { float: left; }'
		+' .col-12 { width: 100%; } .col-11 { width: 91.66666667%; } .col-10 { width: 83.33333333%; } .col-9 { width: 75%; }'
		+' .col-8 { width: 66.66666667%; } .col-7 { width: 58.33333333%; } .col-6 { width: 50%; } .col-5 { width: 41.66666667%; }'
		+' .col-4 { width: 33.33333333%; } .col-3 { width: 25%; } .col-2 { width: 16.66666667%; } .col-1 { width: 8.33333333%; }; @media print { @page { margin: 0; }	} ';
		let self = this;
		let data = chatdata.information;
		let datatranscript = chatdata.transcript;
		//var divContents = document.getElementById(divName).innerHTML;
		var printWindow = window.open('', '', 'height=726,width=768');
		printWindow.document.write('<html><head><title>Print Preview</title><style>'+styles+'</style></head><body>');
		printWindow.document.write('<p style="font-size: 16px;font-weight: 600;"><u>' + appStore.getLanguageTrans("Information") + '</u></p>');
		printWindow.document.write('<table  border="0" style="word-break: break-word;"><tbody>')
		for (let i in data) {
			if (data[i].value != null) {
				let value=data[i].value;
				if(data[i].type ==="dropdown"){
					value=data[i].params[data[i].value].replace(/[\[\]"]+/g,'');
				}else{
					value=data[i].name == "Date" ? moment(data[i].value).format("DD.MM.YYYY"):data[i].value;
				} 
				printWindow.document.write('<tr><td style="width:30%;color: #000000;font-family: Segoe UI Semi Bold;'
					+ 'font-size: 14px;font-weight: 600;">' + data[i].name + ' :  <span style="font-weight: 400">'+value+'</span></td></tr>');
			} 
		};
		printWindow.document.write('<tbody></table>');
		//FULL CONTACT
		if(chatdata.fc!="" && chatdata.fc!=undefined && (chatdata.fc.img != "" || chatdata.fc.full_name != "" || chatdata.fc.gender != "" || 
		chatdata.fc.location != "" || chatdata.fc.social_profiles.length>0 )){ 
			printWindow.document.write('<hr>');
			printWindow.document.write('<p style="font-size: 16px;font-weight: 600;">' + appStore.getLanguageTrans("Information discovered on the web about this visitor") + '</p>');
			printWindow.document.write('<div class="row">');
			var stylerows="margin-left:15px";
			if(chatdata.fc.img!="" && chatdata.fc.img!=undefined){
				stylerows="";
				printWindow.document.write('<div class="col-3"><img alt="'+chatdata.fc.imgfrom+'" style="margin-left:20px;max-height:100px;max-width:100px" src="'+chatdata.fc.img+'" title="'+chatdata.fc.imgfrom+'" /> </div>');
			  
			}
			printWindow.document.write('<div class="col-9">');
			if(chatdata.fc.full_name!="" && chatdata.fc.full_name!=undefined){
				printWindow.document.write('<div class="row" style="'+stylerows+'"><div class="col-12"><p>'+appStore.getLanguageTrans("Full name :")+' '+chatdata.fc.full_name+'</p></div></div>');
			}
			if(chatdata.fc.gender!="" && chatdata.fc.gender!=undefined){
				printWindow.document.write('<div class="row" style="'+stylerows+'"><div class="col-3"><p>'+appStore.getLanguageTrans("Gender :")+' '+chatdata.fc.gender+'</p></div></div>');
			}

			if(chatdata.fc.location!="" && chatdata.fc.location!=undefined){
				printWindow.document.write('<div class="row" style="'+stylerows+'"><div class="col-3"><p>'+appStore.getLanguageTrans("Location :")+' '+chatdata.fc.location+'</p></div></div>');
			}
			if(chatdata.fc.social_profiles!="" && chatdata.fc.social_profiles.length>0){
				
				let socialprofiles=chatdata.fc.social_profiles;
				for(let j in socialprofiles){
					let socialttypeimg= socialprofiles[j].type=="googleplus" ? appStore.otherIcons+"op/images/social/googleplus.png" :
										socialprofiles[j].type=="twitter" ? appStore.otherIcons+"op/images/social/twitter.png" :
										socialprofiles[j].type=="facebook" ? appStore.otherIcons+"op/images/social/facebook.png" :
										socialprofiles[j].type=="linkedin" ? appStore.otherIcons+"op/images/social/linkedin.png" :
										socialprofiles[j].type=="youtube" ? appStore.otherIcons+"op/images/social/youtube.png" :"";
				   let socialttypetitle= socialprofiles[j].type=="googleplus" ? "google plus" :
										socialprofiles[j].type=="twitter" ? "twitter" :
										socialprofiles[j].type=="facebook" ? "facebook" :
										socialprofiles[j].type=="linkedin" ? "linkedin" :
										socialprofiles[j].type=="youtube" ? "youtube" :"";

					printWindow.document.write('<div class="row" style="'+stylerows+'"><div class="col-3"><p>'+appStore.getLanguageTrans("Social profiles :")+'  <img alt="Crop" style="max-height:16px;max-width:16;margin-left:5px;vertical-align:middle" src="'+socialttypeimg+'" title="'+socialttypetitle+'" /></p></div>');
					 
				} 
				printWindow.document.write('</div>');
			}  				  

			printWindow.document.write('</div></div>'); 
		}
		
		printWindow.document.write('<p style="font-size: 16px;font-weight: 600;"><u>' + appStore.getLanguageTrans("Transcript") + '</u></p>');
		printWindow.document.write('<table  border="0" style="word-break: break-word;"><tbody>'); 
		for (let k in datatranscript) {
			var chatname="";
			var chatnamecolor="";
			if(datatranscript[k].op_name=="Visitor")
			{
				chatname=self.getTitle(this.props.chatData);
				chatnamecolor="red";
			}else{
				chatname=datatranscript[k].op_name;
				chatnamecolor="#36f";
			}
			let message= datatranscript[k].message.replace("<p></p>","");
			printWindow.document.write('<tr>'
				+ '<td style="color: '+chatnamecolor+';font-family: Segoe UI Semi Bold;padding: 3px 15px;'
				+ 'font-size: 14px;font-weight: 600; width:10%;padding: 3px 15px;">' + chatname + '</td>'
				+ '<td style="color: rgb(173, 173, 173); width:12%;padding: 3px 15px;">(' + moment(self.convertUTCDateTime(datatranscript[k].date)).format("HH:mm:ss") + ')</td>'
				+ '<td style="width:60%;padding: 3px 15px;"> ' + message + '</td></tr>')
		}
		printWindow.document.write('<tbody></table>');
		printWindow.document.write('</body></html>');
		printWindow.document.close();
		printWindow.print();
	}
	exportmail=(chatdata,appstore) =>{
		this.props.store.transcript_emailid=appstore.userInfo.usersmodel.email;
		this.props.store.mailtranscripts=true;
		this.props.store.emailArchiveArray[0]=chatdata;
	}
	

	getLabel =(field)=>{
		if(field.id === "noweb_new_department"){
			return field.name.trim().length !=0 ? field.name+":" :"Consent :";
		}
		else {
			return field.name.trim().length !=0 ? field.name+":" :"";
		}
	}

	getValue = (field) =>{
		if(field.type ==="dropdown"){
           return field.params[field.value].replace(/[\[\]"]+/g,'');
		}
		else if(field.name=="Operator rating"){
			var rating=field.value;
			if(rating.indexOf("out of 5")==-1){
				return rating+"(out of 5)";
			}
			return field.value;
		}
		else{
			
          return field.name=="Date" ? moment(this.convertUTCDateTime(field.value)).format("DD.MM.YYYY"):field.value;
		}
	}

	getTitle = (chatData)=>{
		//console.log(chatData.information);
        let title="";
		for(let i=0; i< chatData.information.length; i++){
			if (chatData.information[i].value != null) {
				if(chatData.information[i].type === "name"){
					title = chatData.information[i].value;
					break;
				}
			}
		}

		return title.trim().length != 0 ? title : "Visitor";//+" "+chatData.information[0].date_created;
		
	}
	convertUTCDateTime = (datestring) =>{  
		// console.log("start");
		// console.log(datestring);
		var newDate = new Date(datestring);
		// console.log(newDate);
		var chatdate=moment(newDate).format("MM/DD/YYYY HH:mm:ss");
		// console.log(chatdate);
		//var dateconverted = new Date(chatdate+" UTC");
		var dateconverted = new Date(chatdate+" UTC");
		// console.log(dateconverted);
		// console.log("end");
		return dateconverted.toString();
	}

	convertUTCDateToLocalDate=(date) =>{
		var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
	// alert(newDate)
		var offset = date.getTimezoneOffset() / 60;
		var hours = date.getHours();
	
		newDate.setHours(hours - offset);
	
		return newDate;   
	}
	// loadiframe = () =>{ 
	// 	var styleta = document.createElement('style');
	// 	 styleta.type = 'text/css'; 
	// 	 styleta.innerHTML = 'body {background:#fff;color:#000;margin:0;padding:15px 15px 0}table{border-collapse:collapse;font:12px Arial,Helvetica,sans-serif;} td{padding:3px 10px}.date, .sys{color:#999} .op{color:#36F} .my{color:#f00}p{margin:0}'; 
	// 	 var heade = document.getElementById('HelpOnClick-ChatPrinter-IFrame').contentDocument.document.head;
	// 	  heade.append(styleta); ;  
	
	// }
	// getIframeContent = () =>{

	// }
	render() {
		const self = this;

		const parentScroll = {
			display: "flex",
			height: "55vh"
		}

		const flexScroll = {
			flex: 1,
			minHeight: 0,
			minWidth: 0

		}

		const { show,
			store,
			callBack,
			body_html,
			email_btn,
			print_btn,
			appStore,
			chatData, } = this.props;
		const chat_timing = { color: "#adadad" }
 
		var printdata=this.printChatdata(chatData,appStore);
		return (
			<Modal backdrop='static' className={'chatArchivePopup ' + (appStore ? appStore.selectedColor.value : '')} show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => store.chatInfo = false}>

				<Modal.Header closeButton className="chatInfoModelHd">
					<Modal.Title style={{ textTransform: "none" }}>{this.getTitle(chatData)}</Modal.Title>
					 
					<div><p>
						<Button className="btn_email text-uppercase chatInfoBtn" variant="secondary" onClick={() => this.exportmail(chatData,appStore)}>{email_btn}</Button>
						{/* <Button className="btn_print text-uppercase chatInfoBtn" style={{ marginLeft: "7px" }} variant="primary" onClick={() => this.printChat(chatData,appStore)}>{print_btn}</Button> */}
						<ReactToPrint
							trigger={() => {
								// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
								// to the root node of the returned component as it will be overwritten.
								return <Button className="btn_print text-uppercase chatInfoBtn" id="btn_printchatinfo" style={{ marginLeft: "7px" }}  variant="primary">Print</Button>;
							}}
							content={() => this.componentRef}
							/>
							<div style={{display:"none"}}><ComponentToPrint ref={el => (this.componentRef = el)} text={printdata}  /></div>
					</p></div> 
					
				</Modal.Header>

				<Modal.Body className="chatInfoScrollWrapper" >
					
					<div style={parentScroll}  >
					{/* <React.Fragment>
										<div
											className="HelpOnClick HelpOnClick-ChatPrinter HelpOnClick-stickyView HelpOnClick-ChatPrinterframe"
											style={{display: "none"}}>
											<div className="HelpOnClick-ChatPrinter-Window"
												style={{left: "136.6px", top: "12.48px", width:" 80%"}}>
												<div className="HelpOnClick-PrintTitle">Print transcript</div>
												<Iframe src="about:blank" id="HelpOnClick-ChatPrinter-IFrame"
													name="HelpOnClick-ChatPrinter-IFrame"
													style="width: 100%; height: 496px;" 	onLoad={this.loadiframe()} frameborder="0"></Iframe>
												<div className="HelpOnClick-PrintFooter">
													<a href="#"
														className="HelpOnClick-ChatPrinter-Button HelpOnClick-PrintBtn"
														onClick={() => this.getIframeContent()}>Print</a><a href="#"
														className="HelpOnClick-ChatPrinter-Button HelpOnClick-CloseBtn">Close</a>
												</div>
											</div>
											<div class="HelpOnClick-ChatPrinter-Shadow"
												style={{height: "669px", width: "100%"}} ></div>
										</div> 
							</React.Fragment> */}
						<CustomScroll flex="1" heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
							<div id="printableArea">
							<p className="modalBodyTitle"><u>{appStore.getLanguageTrans("INFORMATION")}</u></p>
							<div className="row" id="printableAreacss">
								<div className="col-12 table_td" style={{lineHeight:"0.1"}}>
										{
											chatData.information.map((field, index) => {
												if (field.value != null && (!field.hasOwnProperty("id") &&  field.id != "noweb_new_department")) {  //field.hasOwnProperty("id") &&  field.id === "noweb_new_department"
													return (

														<div className="row"  key={Math.random()}>
															{/* <td className="label"> */}
															<p style={field.name=="Start page" ? { marginLeft: "15px", fontWeight: "600",lineHeight:1} : { marginLeft: "15px", fontWeight: "600",lineHeight:1}} dangerouslySetInnerHTML={{ __html: self.getLabel(field) + "<span style='font-weight:400' >&nbsp;&nbsp;" + self.getValue(field) + "</span>" }} ></p>
															{/* </td> */}
															{/* <td className="value"><p dangerouslySetInnerHTML={{ __html: self.getValue(field) }}></p></td> */}
														</div>

													)
												}else if(field.hasOwnProperty("id") && field.id === "noweb_new_department"){
													return (

														<div className="row"  key={Math.random()}>
															<p style={{ marginLeft: "15px", fontWeight: "600",lineHeight:1}} dangerouslySetInnerHTML={{ __html: self.getLabel(field) + "<span style='font-weight:400' >&nbsp;&nbsp;" + self.getValue(field) + "</span>" }} ></p>
														</div>

													)
												}
											})
										}
									{chatData.fc!="" && chatData.fc!=undefined && (chatData.fc.img != "" || chatData.fc.full_name != "" || chatData.fc.gender != "" || 
									chatData.fc.location != "" || chatData.fc.social_profiles.length>0 ) &&
									<React.Fragment>
										<hr/>
										<p className="modalBodyTitle">{appStore.getLanguageTrans("Information discovered on the web about this visitor")}</p>
										<div className="row">
										{chatData.fc.img!="" && chatData.fc.img!=undefined &&
										<div className={"col-12 col-xl-3 col-lg-12"}>
											<td className="label" rowSpan="4">
												<img alt={chatData.fc.imgfrom} style={{maxHeight:"100px",maxWidth:"100px"}}
												 className="img-fluid" src={chatData.fc.img} title={chatData.fc.imgfrom} /></td>
											
										</div>
										}
										<div className={"col-12 col-xl-9 col-lg-12"}>
										{chatData.fc.full_name!="" && chatData.fc.full_name!=undefined  &&
										<div className="row">
											<td className="label"><p>{appStore.getLanguageTrans("Full name :")}</p></td>
											<td className="value"><p>{chatData.fc.full_name}</p></td>
										</div>
										}
										{chatData.fc.gender!="" && chatData.fc.gender!=undefined &&
										<div className="row">
											<td className="label"><p>{appStore.getLanguageTrans("Gender :")}</p></td>
											<td className="value"><p>{chatData.fc.gender}</p></td>
										</div>
										}
										{chatData.fc.location!="" && chatData.fc.location!=undefined  &&
										<div className="row">
											<td className="label"><p>{appStore.getLanguageTrans("Location :")}</p></td>
											<td className="value"><p>{chatData.fc.location}</p></td>
										</div>
										}
										{chatData.fc.social_profiles!=undefined && chatData.fc.social_profiles.length>0 &&
										<div className="row">
											<td className="label"><p>{appStore.getLanguageTrans("Social profiles :")}</p></td>
											<td className="value"> 
											{chatData.fc.social_profiles.map((field,index) =>{  
												return( 
													<img alt="Crop" 
													style={{maxHeight:"16px",maxWidth:"16",marginLeft:"5px",verticalAlign:"middle"}} 
											className="img-fluid" src={
												field.type=="googleplus" ? appStore.otherIcons+"op/images/social/googleplus.png":
												 field.type=="twitter"? appStore.otherIcons+"op/images/social/twitter.png":
												 field.type=="facebook"? appStore.otherIcons+"op/images/social/facebook.png":
												 field.type=="linkedin"? appStore.otherIcons+"op/images/social/linkedin.png":
												 field.type=="youtube"? appStore.otherIcons+"op/images/social/youtube.png":""} title="google plus" />
										 
												) 
												})
											}
											</td>
										</div> 
											}
										</div>
										</div> 
										
										</React.Fragment>
									}
								
								{chatData.information[0].hasOwnProperty("question") &&
									<div className="row">
										<td className="label"><p>{appStore.getLanguageTrans("Question:")}</p></td>
										<td className="value"><p dangerouslySetInnerHTML={{ __html: chatData.information[0].question }}></p></td>
									</div>
								}
								
								{chatData.information[0].hasOwnProperty("date_created") &&
									<div className="row">
										<td className="label"><p>{appStore.getLanguageTrans("Date:")}</p></td>
										<td className="value"><p>{moment(self.convertUTCDateTime(chatData.information[0].date_created)).format("DD-MM-YYYY HH:mm:ss")}</p></td>
									</div>
								}
								{chatData.information[0].hasOwnProperty("chatTime") &&
									<div className="row">
										<td className="label"><p>{appStore.getLanguageTrans("Chat time:")}</p></td>
										{/* <td className="value"><p>{chatData.information[0].chatTime}</p></td> */}
										<td className="value"><p>{chatData.information[0].chatTime}</p></td>
									</div>
								}
								</div>
								<div className="infoTranscript col-12">
									<p className="modalBodyTitle text-uppercase"><u>{appStore.getLanguageTrans("Transcript")}</u></p>
									{chatData.transcript.map(function (data, dataIndex) {
										return (<div className="row"  key={Math.random()}>
											<div className="name">
												<p style={data.op_name=='Visitor' ? {color:"red",wordBreak:"break-word"}:{color:"#36f",wordBreak:"break-word"}}>{data.op_name=='Visitor' ? self.getTitle(chatData): data.op_name}</p> 
											</div>
											<div className="time"> 
												{/* <p style={chat_timing}>{moment(moment.utc(data.date).toDate()).format("HH:mm:ss")}</p> */}
												<p style={chat_timing}>{moment(self.convertUTCDateTime(data.date)).format("HH:mm:ss")}</p>
												
											</div>
											<div className="msg chat_p">
												<div className="chatmsg" style={{width:"100%",wordWrap:"anywhere",marginLeft:"15px"}} dangerouslySetInnerHTML={{ __html: data.message.trim() }}></div>
												{/* <p dangerouslySetInnerHTML={{ __html: data.message.trim() }}></p> */}
											</div>

										</div>
										)
									}
									)}
									{/* <div className="row">
										                        <div className="name">
										                          <p>{op_name}</p>  
										                        </div>
										                        <div className="time">
							                                      <p style={chat_timing}>{op_Msg_Time}</p>
										                        </div>
										                        <div className="msg chat_p">
										                           <p dangerouslySetInnerHTML={{__html: op_msg}}></p>
										                        </div>
									                       
													     </div>
													      */}

				{ store.mailtranscripts &&
                 <EmailTranscriptPopup  show={store.mailtranscripts}
                    store={store}
                    title={"Email Transcripts"}
                    body_html={"Do you want to get the transcript of this chat by email?"} 
                    cancel_btn="Cancel"
					save_btn = "Ok"
					from="archive"
                    closePopup={() => store.mailtranscripts=false}
                    appStore={appStore} />
                } 
								</div>
							</div>

</div>
						</CustomScroll>
					</div>

				</Modal.Body>


			</Modal>
		)
	}
})
 
export default ChatInfoComponent;
