import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import CustomerFileComponent from './CustomerFileComponent';

const CustomerChatComponent = observer(class CustomerChatComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }


	render(){
		const { appstore,
				chatComponentStore,
				chatData,
				name,
				time,
				filePath,
				originalFileName,
				account_uid,
				talkSessionUid,fullData,file_size } = this.props;
		var self = this;   
		// var file= filePath.split("/");

		// var chatobject={account_uid:account_uid,talkSessionUid:talkSessionUid,resourceUid:file[7]}; 

		if(filePath != "" && filePath != null){
			return (
				 <React.Fragment>
				<CustomerFileComponent  fileData={chatComponentStore.fileData} chatData={chatData}
				appstore={appstore}
				chatComponentStore = {chatComponentStore} name={name} time={time}
				talkSessionUid={talkSessionUid} filePath={filePath} file_Size={file_size} originalFileName={originalFileName} fullData={fullData}/> 
				{ chatData.length > 0 &&
					<div className="chatSection customerChat col-12 p-0">
						<p className="chatBy text-right"><span>{time}</span> {name}</p>
						<div className="chatText float-right">
							<p className="response" dangerouslySetInnerHTML={{ __html: chatData }}>
							</p>
						</div>
					</div>
					}
					</React.Fragment>
			) 
		}
		if(chatData.length != 0){
			return (
				<div className="chatSection customerChat col-12 p-0">
					<p className="chatBy text-right"><span>{time}</span> {name}</p>
					<div className="chatText float-right">
						<p className="response" dangerouslySetInnerHTML={{ __html: chatData }}>
						</p>
					</div>
				</div>
			) 
		}
	}
})
export default CustomerChatComponent;
