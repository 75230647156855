import React, { Component } from "react";
import { observer } from 'mobx-react';
import Chart from "react-apexcharts";

const SplineAreaChart = observer(class SplineAreaChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
          
        series: [{
          name: 'Chats',
          data: this.props.store.performanceData.chartsCount
        }],
        options: {
          chart: {
            height: 350,
            type: 'area',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                // reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: []
              },
            },
          },
          
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },

          tooltip: {
            theme:"dark",
            x : {show: false} ,
            marker: {show:false},
            y: {
              formatter: function(timeInSeconds, { series, seriesIndex, dataPointIndex, w }) {
                var pad = function(num, size) { return ('000' + num).slice(size * -1); },
                time = parseFloat(timeInSeconds).toFixed(3),
                hours = Math.floor(time / 60 / 60),
                minutes = Math.floor(time / 60) % 60,
                seconds = Math.floor(time - minutes * 60),
                milliseconds = time.slice(-3);

                if(hours === 0){
                  return pad(minutes, 2) +'m:' + pad(seconds, 2)+'s';
                }
                
                else{
                  return pad(hours, 2) +'h:' + pad(minutes, 2) +'m:' + pad(seconds, 2)+'s';

                }
                
              }
            }
          },
          
          xaxis: {
            type: 'category',
            categories: this.props.store.performanceData.chartsDate
          },

          yaxis: {
            type:'time',
                show:true,
                min:0,
                forceNiceScale: true,
                labels: {
                  formatter: function (value) {
                    return value ;
                  },
                },
           },
           
          tooltip: {
            x: {
              format: 'dd/MM/yy '
            },
          },
          
        },
      
      
      };
    }

    render() {
      return (
        
  <div id="chart">
<Chart 
    options={this.state.options} 
    series={this.state.series} 
    type="area" 
    width="900"
    height={300} />
</div>

        );
    }
})

export default SplineAreaChart;