import firebase from 'firebase' 

const config ={
    apiKey: "AIzaSyD8l5Pmx6AkXQbql6iq0YFvz9bIXBBdS08",
    authDomain: "my-chat-application-3fa68.firebaseapp.com",
    projectId: "my-chat-application-3fa68",
    storageBucket: "my-chat-application-3fa68.appspot.com",
    messagingSenderId: "860798931988",
    appId: "1:860798931988:web:0535b9d0d8bd6797c44301"

}

firebase.initializeApp(config)
// var db = firebase.firestore();
// console.log(db);
// const docRef = db.doc("samples/tokens");
 
// docRef.set({
//     token:'7890gh'
// }).then(function(){
// console.log("tok su");
// }).catch(function(error){
//     console.log("tok er",error);
// })

export default firebase;
