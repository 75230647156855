import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink } from "react-csv";


const operatorChatsCSV = observer(class operatorChatsCSV extends Component {

    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }


    render() {

        let headers = [
            { label: "Operators", key: "nick_name" },
            { label: "Online Time", key: "totaltime" },
            { label: "Chats", key: "_chats" },
            // { label: "Missed Chats", key: "_chat_missed" },
            { label: "Chat Time", key: "totaltime2" },
            { label: "Avg Pickup Time", key: "totaltime4" },
            { label: "Rating", key: "totaltime3" },
            { label: "Number Of Rates", key: "_ops_rating" },

        ];

        let exportdata = this.props.data ? this.props.data :[] ;
        const {appStore}=this.props;
        return (

            <CSVLink data={exportdata} headers={headers} target="_blank" filename={"Operator_Reports_"+this.props.dateselected+".csv"} >
            {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
        );
    }


});

export default operatorChatsCSV;
