import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import imageURLs from '../../sharedFiles/ImagesURLs';

const NotificationComponent = observer(class NotificationComponent extends Component {

	constructor(props) {
		super(props);
		this.readMore = this.readMore.bind(this);
		this.dissMiss = this.dissMiss.bind(this);
	}

	componentDidMount(){

		// document.addEventListener('mousedown', ()=>{
		// 	if(this.props.dataForclose.ref)
		// 	    this.props.dataForclose.ref.tooltipRef =null;
		// 	this.props.dataForclose.tooltip.hide(); 
		// });
		//this.props.store.getNotification(this.props.appStore, true);
		//let scrollPos = document.getElementById('notificationSrollParent').offsetTop + document.getElementById("notificationSrollchild"+String(this.props.store.notificationData.length-1)).offsetTop;
	//	alert(scrollPos)
	
		//this.componentScroll = document.getElementById("response"+String(this.responses.length-1)).offsetTop;
	}

	readMore(data) {
		window.open(data.blog_url, '_blank')
	}

	dissMiss(store, data) {
		store.dismissNotification(this.props.appStore, data);
		//	store.notification_popup = false;
	}

	closeNoti1= () =>{
		//if(document.getElementById("notificationClickme") !=null)
		// document.getElementById("notificationClickme").style.display="none";
	}

	render() {
		const parentScroll = {
			display: "flex",
			height: "55vh"
		}

		const flexScroll = {
			flex: 1,
			minHeight: 0,
			minWidth: 0

		}

		const { show,
			store,
			title,
			callBack,
			body_html,
			dismiss,
			read_more,

			appStore
		} = this.props;
		const self = this;
		return (
			<div backdrop='static' className={(appStore ? appStore.selectedColor.value : '') + " notificationPopup"}>

				<div className="row">
					<div className="col-12 pl-4 pt-2">
						<p className="notificationTitle" style={{ textTransform: "none" }}>{appStore.getLanguageTrans(title)}</p>
						<img className="closeNoti" src={imageURLs.deleteIcon} onClick={() => this.props.closeNoti()}></img>
					</div>
				</div>

				<div className=" notiInfoScrollWrapper">
					<div id="notificationSrollParent" style={parentScroll}>
						<CustomScroll scrollTo={0} flex="1" heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>

							<div className="row notificationContent" style={{marginRight: 0,width:"100%"}}>

								{/* <div className="col-12 table_td back_color">
				                        	  <p className="modalBodyTitle text-uppercase">How to set it up ?</p>
				                        		<div className="row setup_step">
				                        		<ol>	
										            <li className=""><p>Teach the Virtual Agent about your business by <a className="cursor-pointer"  onClick={() => {appStore.navigate('/admin/virtual_agent/script'); this.props.closePopup()}}>adding your custom responses</a></p></li> 
										            <li className=""><p>Choose a name, image, and default settings for your Virtual Agent on <a className="cursor-pointer"  onClick={() => {appStore.navigate('/admin/virtual_agent/settings');this.props.closePopup()}}>Settings section</a></p></li>
										            <li className=""><p><a className="cursor-pointer" onClick={() => {appStore.navigate('/admin/virtual_agent/invitation_rules');this.props.closePopup()}}>Setup invitation rules</a> to get the attention of your visitors</p></li>


										        </ol>
				                        		</div>
										      <div style={{marginTop:"-13px"}}><p>
										     <Button className="dismiss text-uppercase notiInfoBtn" variant="secondary" onClick={() => this.props.closePopup()}>{dismiss}</Button>
										     <Button className="read_more text-uppercase notiInfoBtn" style={{marginLeft:"7px"}} variant="primary" onClick={() => this.props.callBack()}>{read_more}</Button>
										     </p></div>
				                        </div> */}
								{/* <div className="infoTranscript col-12 back_color_va">
										    <p className="modalBodyTitle text-uppercase">What is virtual agent ?</p> 
											     <div className="row">
									                        <div className="va_text">

									                          <p>The Virtual Agent is a set of automated replies for your website live chat. When your chat operators are offline, the Virtual Agent is available to cover any shift 24/7/365. When your chat agents are unavailable, your website visitors can still get live help addressing their questions to the Virtual Agent. </p> 
									                           <Button className="dismiss text-uppercase notiInfoBtn"  variant="primary" onClick={() => this.props.callBack()}>{dismiss}</Button>
									                        </div>
									                    
							                       
											     </div>
											
											     
	                                     </div> */}
								{store.notificationData.length != 0 &&
									store.notificationData.map(function (data, index) {
										return (
											<div id={"notificationSrollchild"+index} className="infoTranscript col-12 back_color_va" key={index}>
												<p className="subject text-uppercase">{data.subject}</p>
												<div className="row">
													<div className="va_text">
														<div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: data.message }} />
														{/* style={{marginTop:"-5px"}} */}
														<div style={{ marginTop: "10px" }} className="notiformBtns">
															<p>
																<Button className="dismiss text-uppercase notiInfoBtn" style={{border: "1px solid"+appStore.selectedColor.color,color:appStore.selectedColor.color}} onClick={() => self.dissMiss(store, data)}>{dismiss}</Button>
																{data.blog_url &&
																	<Button className="read_more text-uppercase notiInfoBtn btn_save" variant="primary" style={{background: appStore.selectedColor.color+" !important",marginLeft: "7px"}}  onClick={() => self.readMore(data)}>{read_more}</Button>
																}
															</p>
														</div>
													</div>


												</div>


											</div>
										)
									})
								}
								{store.notificationData.length == 0 &&
									<div className="infoTranscript col-12 back_color_va" style={{ textAlign: 'center' }}><p>No Unread Notifications</p></div>
								}
							</div>


						</CustomScroll>
					</div>

				</div>


			</div>
		)
	}
})
export default NotificationComponent;
