import React, {Component } from 'react';
import { observer } from 'mobx-react';

import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../sharedFiles/ImagesURLs';

import OnlineOperatorsStore from './OnlineOperatorsStore';




const OnlineOperatorsComponent = observer(class OnlineOperatorsComponent extends Component {

	constructor (props) {
        super(props);
        this.onlineOperatorsStore = new OnlineOperatorsStore();
        this.onlineOperatorRef = React.createRef();
		this.state = {
			onlineSubmenuOperators : Math.random(),
		}

	}
	componentDidUpdate(prevProps, prevState) { 
		if (prevState.onlineSubmenuOperators !== this.props.appstore.onlineSubmenuOperators) { 
			this.setOperators();
			this.onlineOperatorsStore.selectedOperator = undefined;
			this.setState({onlineSubmenuOperators:this.props.appstore.onlineSubmenuOperators});			  
		}
	}

 	componentDidMount(){

	   this.setOperators();
	   this.onlineOperatorsStore.selectedOperator = undefined;
	
	}

	setOperators = async () => {
		await	this.onlineOperatorsStore.getOnlineOperators(this.props.appstore);

		setTimeout(() => {
			this.onlineOperatorsStore.operatorsList.map(function(operator,operatorIndex) {
			//console.log(document.getElementById("op"+operator.id).offsetTop);
			operator.topValue = document.getElementById("op"+operator.id) ? document.getElementById("op"+operator.id).offsetTop : 0;
		});
		},1000);

		
	}

	

	captureScroll(event) {
	//	this.onlineOperatorsStore.selectedOperator = undefined;
	}

	getSelectedOperator(event,operator) {
		// console.log(document.getElementsByClassName("rcs-custom-scroll")[0].offsetTop);
		//operator.topValue = document.getElementById("op"+operator.id).offsetTop;
		if(document.getElementsByClassName("rcs-custom-scroll-handle")[0]) {
			//console.log("ss");
		 	//operator.topValue = document.getElementById("op"+operator.id).offsetTop - document.getElementsByClassName("rcs-custom-scroll-handle")[0].offsetTop;
			 let  currentpath =  window.location.pathname;
			if(currentpath.indexOf('/traffic')!=-1){
				operator.topValue = document.getElementById("op"+operator.id).offsetTop - document.getElementsByClassName("rcs-custom-scroll-handle")[0].offsetTop;
			}else{
				operator.topValue = 405;
			}
			
		 }
		 else {
		 	operator.topValue = document.getElementById("op"+operator.id).offsetTop;
		 }
		 
		 if(this.onlineOperatorsStore.selectedOperator && this.onlineOperatorsStore.selectedOperator === operator.id) {
		 
			this.onlineOperatorsStore.selectedOperator =  undefined;
		 }
		 else {
			 
		 	this.onlineOperatorsStore.selectedOperator = operator.id;
		 }

		 //console.log(this.onlineOperatorsStore.selectedOperator)
		// console.log(document.getElementsByClassName("rcs-custom-scroll-handle")[0].offsetTop);
		// console.log(document.getElementById("option"+operator.id).parentNode.offsetTop);
		// if(!operator.topValue || operator.topValue === 0 || operator.topValue < document.getElementById("option"+operator.id).offsetTop) {
		// 	operator.topValue = document.getElementById("op"+operator.id).offsetTop;
		// }
		//  console.log(operator.topValue );
		
	}

	componentWillUnmount() {
		
	}
	checkisAdminintraffic = (role) =>{
		//alert(this.props.appstore.isAdmin);
		if(window.location.pathname === "/traffic" && !this.props.appstore.isAdmin)
		   return false;
		else
		 return true;
  
	  }
	getOperatorsOption = (selectedOp) =>{
		let isTransferChatEnabled = this.props.appstore.userInfo.accountplan.transfer_chat;
		let arr = this.onlineOperatorsStore.operator_options;
		let newArray;
		if(  selectedOp === this.props.appstore.userInfo.usersmodel.user_uid){
			
			return []; 
		}
		else{

			if(this.props.appstore.isAdmin)
			{
				if(window.location.pathname === "/traffic"){
                    newArray = arr.filter(obj =>{
						if(obj.id === 4)
						return obj;
					})
				}
				else{
					if(isTransferChatEnabled==1){
						newArray = arr;
					}else{
						newArray = arr.filter(obj =>{
							if(obj.id !== 2)
							return obj;
						})
					}
					
				}
			}
			else{
				
				if(window.location.pathname === "/traffic"){
                    return [];
				}
				else{
					if(isTransferChatEnabled==1){
						newArray = arr.filter(obj =>{
							if(obj.id !== 4)
							return obj;
						})
					}else{
						newArray = arr.filter(obj =>{
							if(obj.id !== 4 && obj.id !== 2)
							return obj;
						})
					}
				}
			}
				
		}
	   
		let vistorId=this.props.appstore.requestedVisitorId;

		if(vistorId.trim())
		    newArray  = newArray.map((value)=> {
                 if(value.id==2 || value.id===1)
					 value.active = true; //transfer chat active

				if(this.props.appstore.inviteJoinedByOp[vistorId]){
                      if(this.props.appstore.inviteJoinedByOp[vistorId].includes(selectedOp)) {
						  if(value.id===1)
						  value.active = false; 
					  }
				}

				return value;	 
		  });
		
		return newArray;
	}
	render(){
		const { hideHeader,appstore } = this.props;
        
		let self = this;

		return (
			<div key={this.props.appstore.onlineSubmenuOperators} className="operators_Wrapper" id="op" style={appstore.helpTourStarted ? {pointerEvents:'none'}:{}}>
				{!hideHeader && 
					<h6><span>{appstore.getLanguageTrans("Online")}</span><br/> {appstore.getLanguageTrans("operators")}</h6>
				}
			<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={false} onScroll={(event) => this.captureScroll(event)}>
				
				{ this.onlineOperatorsStore.operatorsList.length !=0 &&
					this.onlineOperatorsStore.operatorsList.map(function(operator,operatorIndex) {
						if(operator.operator_name!=undefined && operator.operator_name.length>0){
						return (
							// onClick={(e) => self.getSelectedOperator(e,operator)}
							<div id={"op"+operator.id} ref={self.onlineOperatorRef} className={"operator_details" + (self.onlineOperatorsStore.selectedOperator === operator.id ? ' active' : '')+(operator.chat_queued === 0 ? '' : ' quedOperator')} style={self.onlineOperatorsStore.selectedOperator === operator.id ? {border: "1px solid "+appstore.selectedColor.value}:{}} key={operatorIndex} >
								<div id={"option"+operator.id} className="optionwrapper" style={operator.topValue > 218?{top: "118px"}:{top: operator.topValue + "px"}}>
									{
										(self.onlineOperatorsStore.selectedOperator === operator.id && self.onlineOperatorsStore.selectedOperator != appstore.userInfo.usersmodel.user_uid) && self.checkisAdminintraffic(operator.role) &&
											<div className="operatorsOptions" >
												 {
											        self.getOperatorsOption(self.onlineOperatorsStore.selectedOperator).map(function(option, optionIndex){
														return (
															<p key={optionIndex} className={(option.active ? 'active ' : '')+((optionIndex==3 && operator.role === 'operator') ? ' active':' ')}
															 onClick={(e) =>self.onlineOperatorsStore.handleOperatorOptions(e,appstore,operator,option,optionIndex)}
															   >
																 <img src={imageURLs.operatorsBullet} />{option.action}</p>
														)
													})
												}
											</div>
									}
								</div>
								
								<div className={operator.role === 'admin' ? "role admin" : "role operator"} >
									<img onClick={(e) => self.getSelectedOperator(e,operator)} style={{width:"55%",height:"54px"}} src={operator.operator_thumbnail} className={"img-fluid " + (operator.chat_queued === 0 ? '' : 'quedImg')}  />
								</div>
								<p onClick={(e) => self.getSelectedOperator(e,operator)}>{operator.operator_name}</p>
								
							</div>
						)
						}
					})
				}
			</CustomScroll>

			</div>
		)
	}
})
export default OnlineOperatorsComponent;
