import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import imageURLs from '../../../sharedFiles/ImagesURLs';
import CheckBoxCustomComponent from '../../../sharedComponents/checkBox_2/checkBoxCustom2';
import ArchiveChatStore from './ArchiveChatStore';
import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import CustomScroll from 'react-custom-scroll';
import ConfirmationComponent from '../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import ChatInfoComponent from '../../../sharedComponents/chatInfoPopup/ChatInfoComponent';
import WarningComponent from '../../../sharedComponents/confirmationPopup/WarningComponent';

import ReactPaginate from 'react-paginate';
import { GridLoader } from 'react-spinners';
import { css } from '@emotion/core';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`	

const ArchiveChat = observer(class ArchiveChat extends Component {

  constructor(props) {
    super(props);
    this.archiveChatStore = new ArchiveChatStore();
	this.state = {
		data: [],
		offset: 0,
	  };
  }

  componentDidMount() {
	 // this.calculateTableHeight();
	  this.archiveChatStore.pageisLoaded=false;
	  this.archiveChatStore.getArchiveHistoryList(this.props.appstore,0,30);
  }

  componentWillUnmount() {
  }


  componentWillReceiveProps(nextProps){
  }

  
  handlePageClick = data => {
    let selected = data.selected;
	let start_offset = Math.ceil(selected * 30);
	this.archiveChatStore.saveinProcess = true;
	this.archiveChatStore.getArchiveHistoryList(this.props.appstore,start_offset,30);
    this.setState({ offset: start_offset }, () => {
     // this.loadCommentsFromServer();
    });
  };
  
  calculateTableHeight() {
  	let heightToSubtract = document.getElementById('header').clientHeight + document.getElementById('header_title').clientHeight
  							 + document.getElementById('top_row').clientHeight + document.getElementById('tableHeader').clientHeight
  							 + document.getElementById('paginationRow').clientHeight + 110;
  	this.archiveChatStore.tableHeight = window.outerHeight - heightToSubtract;
  }

  selectComponent(fileData,index) {
	 	fileData.selected = !fileData.selected;
	 	let checkForFile = this.archiveChatStore.deleteArchiveArray.indexOf(index);
	 	if(checkForFile === -1) {
	 		this.archiveChatStore.deleteArchiveArray.push(index);
	 	}
	 	else {
	 		this.archiveChatStore.allSelected = false;
	 		this.archiveChatStore.deleteArchiveArray.splice(checkForFile,1);
	 	}
	 }

	 handleSearch=(e) =>{
		this.archiveChatStore.searchValue= e.target.value
		this.archiveChatStore.searchValue = this.archiveChatStore.searchValue.trim();
		//this.archiveChatStore.pageisLoaded= false;
		this.archiveChatStore.saveinProcess=true;
		if(this.archiveChatStore.searchValue.length !=0)
			this.archiveChatStore.getArchiveHistoryList(this.props.appstore,0,30);
		else
			this.archiveChatStore.getArchiveHistoryList(this.props.appstore,0,30);
		
	 }
	
  cancelDeleteSelection() {
	this.archiveChatStore.deleteArchiveArray = [];
	this.archiveChatStore.responseData.archivechatdata.map(function(fileData,dataIndex){
		fileData.selected = false;
	});
}
refreshimagesetonhover = (color,button)=>{
	if(button==1){
		document.getElementById("archiveimg").src=imageURLs.refresh_white;
	}else if(button==2){
		document.getElementById("archivedownloadimg").src=imageURLs.download_white;
	} 
}

refreshimagesetonhoverout = (color,button)=>{
	if(button==1){
	let imageurl= "#3ba8c5" == color ? imageURLs.refresh_steelblue : 
				  "#4cb6ac" == color ? imageURLs.refresh_seagreen :
				  "#ec407a" == color ?  imageURLs.refresh_pink :
				  "#6e4c42" == color ?imageURLs.refresh_brown :
				  "#234e91" == color ?imageURLs.refresh_navyblue :
				  "#e89712" == color ?imageURLs.refresh_yellow :
				  "#b968c7" ==color ?imageURLs.refresh_voilet : 
				  "#039be6" == color ? imageURLs.refresh_blue :
				  "#00887a" == color ? imageURLs.refresh_darkgreen :
				  "#4b8f4e" == color ? imageURLs.refresh_green :
				  "#5f7c8c" == color ? imageURLs.refresh_grey :
				  "#64a9e0"== color ? imageURLs.refresh_skyblue : imageURLs.refresh_steelblue;
		document.getElementById("archiveimg").src=imageurl;
	}else if(button==2){
		
		let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
		"#4cb6ac" == color ? imageURLs.download_seagreen :
		"#ec407a" == color ?  imageURLs.download_pink :
		"#6e4c42" == color ?imageURLs.download_brown :
		"#234e91" == color ?imageURLs.download_navyblue :
		"#e89712" == color ?imageURLs.download_yellow :
		"#b968c7" ==color ?imageURLs.download_voilet : 
		"#039be6" == color ? imageURLs.download_blue :
		"#00887a" == color ? imageURLs.download_darkgreen :
		"#4b8f4e" == color ? imageURLs.download_green :
		"#5f7c8c" == color ? imageURLs.download_grey :
		"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.spell_steelblue;
		document.getElementById("archivedownloadimg").src=imageurl;
	}
}

  /**
   * @param route
   */
  
  render() {
  	let self = this;
	  const expcss = {
		pointerEvents: "none",
		opacity: 0.3};
	const nocss = {};	
  	 const checkbx= {
             
             marginRight:14
        }
       const btn_pos={
             marginTop:5
	   } 
	

		const archiveDropDwn = {

			width:"100%",
		    color: "#000000",
		    fontFamily: "Segoe UI Semi Bold",
		    fontSize: 14,
		    fontWeight: 400,
		    //border: "1px solid #dedede",
		    borderRadius: 4
	 	}	
		const aligncol={ paddingLeft: 100}
		const { appstore } = this.props;
		if(!this.archiveChatStore.pageisLoaded){
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}
     //const totalPages =  this.archiveChatStore.pagination.length;
    return (    
    <div className="innercontent_wrapper w-100 scriptWrapper">
		<div className="header_title row align-items-center m-0" id="header_title">
			<img src={imageURLs.chatArchiveIcon} alt="Chat Archive" className="img-fluid"/>
			<h4 className="text-left">{appstore.getLanguageTrans("Chat")} {appstore.getLanguageTrans("Archive")}</h4>
		</div>
	    <div className="row fileTransferWrapper">
			<div className="col-12 w-100 pr-0">
				<div className="settingFormWrapper archiveFormWrapper box">
				    <Row className="top_Row" id="top_row">
				        <Col xs="12" lg="4" md="4" sm="6">
				            <Form>
								<Form.Group as={Row}>
									<Col sm="6" lg="6" className="searchText">
										<Form.Control value={this.archiveChatStore.searchValue} 
										 onChange={(e) => this.handleSearch(e)}
										 type="text" placeholder={appstore.getLanguageTrans("Enter Search Term")} />
									</Col>
								</Form.Group>
							</Form>
					    </Col>
				        <Col className="website_drp" xs="12" lg="4" md="6" sm="6">
							{/* <Form>
							<Form.Group as={Row} >
								<Form.Label column lg="2" sm="4">{appstore.getLanguageTrans("Website")}</Form.Label>
								    <Col sm="8" lg="9" className="customDrpDwn archiveDropDwn" style={archiveDropDwn}>
								    	<DropDownComponent dropDownOptions={this.archiveChatStore.archiveChatDrpDwn} 
									   store={this.archiveChatStore}
									   callBack={(selectedOption) => this.archiveChatStore.fileTransferType = selectedOption}
									   selectedOption = {this.archiveChatStore.fileTransferType}/>
						 
								    </Col>
								</Form.Group>
				           </Form> */}
					    </Col>
				        <Col  xs="5" lg="2" md="6" sm="6" className="top_refBtn">
				            <Form>
				                <Form.Group as={Row} >
								   	<Button className="custom_btn  addBtn text-uppercase top_btn archiverefresh" onMouseOver={() => this.refreshimagesetonhover(appstore.selectedColor.color,1)} onMouseOut={() => this.refreshimagesetonhoverout(appstore.selectedColor.color,1)}  style={btn_pos} onClick={function() {self.archiveChatStore.pageisLoaded=false; self.archiveChatStore.getArchiveHistoryList(appstore,0,30)}}>
						             	<img className="img-fluid" id="archiveimg" style={{cursor:"pointer"}} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.refresh_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.refresh_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.refresh_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.refresh_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.refresh_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.refresh_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.refresh_voilet : 
										"#039be6" == appstore.selectedColor.color ? imageURLs.refresh_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.refresh_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.refresh_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.refresh_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.refresh_skyblue : imageURLs.refresh_steelblue} alt=""   />   {appstore.getLanguageTrans("Refresh")} 
						            </Button>
								        
								</Form.Group>
							</Form>
								 
						</Col>
						<Col  xs="7" lg="2" md="6" sm="6">
						    <Form>
								<Form.Group as={Row} title={appstore.userInfo.accountplan.export_transcripts!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
									<Button className={"top_btn custom_btn  addBtn text-uppercase "+(this.archiveChatStore.deleteArchiveArray.length ==0 && "exportCss")+" "+(appstore.userInfo.accountplan.export_transcripts!=1 && "exportCss")+"" } onMouseOver={() => this.refreshimagesetonhover(appstore.selectedColor.color,2)} onMouseOut={() => this.refreshimagesetonhoverout(appstore.selectedColor.color,2)}  style={btn_pos} onClick={function() {self.archiveChatStore.exportChat(appstore)}} >
									    <img className="img-fluid" id="archivedownloadimg" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.download_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.download_voilet : 
																					"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} alt=""  style={{paddingBottom:'4px'}} /> {appstore.getLanguageTrans("Export chats")}
									</Button>
								</Form.Group>
							</Form>
						</Col>
				       
				     </Row>
				        
		   			<div className="row fileTransferWrapper">		       
		     			<div className="col-12 p-0">
							<div className="scroll_row row m-0">
								<div id="panelGroup" className="panelWrapper col-12 col-lg-12 p-0">
									<div className="col-12" id="tableHeader">
										<div className="row panelHeader align-items-center">
			                                <div className="col-1 pl-0">
												<p className="text-left"></p>
											</div>
											<div className="col-2">
												<p className="text-left">{appstore.getLanguageTrans("Date")}</p>
											</div>
											<div className="col-2">
												<p className="text-left">{appstore.getLanguageTrans("Chat Time")}</p>
											</div>
											<div className="col-2">
												<p className="text-left">{appstore.getLanguageTrans("Visitor")}</p>
											</div>
											<div className="col-2">
												<p className="text-left">{appstore.getLanguageTrans("Pickup Time")}</p>
											</div>
											<div className="col-1">
												<p className="text-left">{appstore.getLanguageTrans("Email")}</p>
											</div>
											<div className="col-2" style={aligncol}>
												<p className="text-left">{appstore.getLanguageTrans("Operators")}</p>
											</div>
						            	</div>
				            		</div>
			            			<div className="col-12 dynamic_firstRow">
				                          { this.archiveChatStore.deleteArchiveArray.length>0 && 	
				                            <div className="row">
				                               <div className="col-1">
									              <p className="text-center dynamic_checkbx" style={checkbx}>
												    <CheckBoxCustomComponent radioValue={this.archiveChatStore.allSelected}
													    							callBack = {() => this.archiveChatStore.selectAll()}/>
												  </p>
												</div>
												
												<div className="col-lg-2 col-3 ">
									                <Button className="custom_btn float-left addBtn text-uppercase " onClick={() => this.archiveChatStore.confirm= true}>
													{appstore.getLanguageTrans("Delete")} ({this.archiveChatStore.deleteArchiveArray.length})
												    </Button>
												</div>
												{
													this.archiveChatStore.confirm && 
													<ConfirmationComponent show={this.archiveChatStore.confirm}
																			store={this.archiveChatStore}
																			title="Delete Files"
																			body_html="<p>Are you sure you want to delete the selected files?</p>"
																			cancel_btn="Cancel"
																			save_btn="Confirm" 
																			appStore={appstore}
																			callBack={() => this.archiveChatStore.confirmAction(appstore)}
																			closePopup={() => this.archiveChatStore.confirm = false}/>
								                  }
												<div className="col-lg-1 col-2"  style={{marginLeft:'-51px'}}>
									              <Button className="custom_btn float-left addBtn text-uppercase" onClick={() => this.cancelDeleteSelection()}>
												  {appstore.getLanguageTrans("Cancel")}
												    </Button>
												</div>
												<div className="col-lg-8 ">
					
												</div>
										    </div>
										    }  
									</div>
			            <div className="scrollWrapper col-12 p-0" style={{'height': this.archiveChatStore.tableHeight !== 'auto' ? this.archiveChatStore.tableHeight+ "px" : this.archiveChatStore.tableHeight}}>
							   <CustomScroll heightRelativeToParent="100%" keepAtBottom={false} allowOuterScroll={false}>
									<div className="scriptPanel row" >
										<div className="col-12">
										{  
											this.archiveChatStore.responseData.archivechatdata.map(function(fileData,dataIndex){
												return (
													    <div className={"row action panelContent cursor-pointer archiveRow align-items-center" + (self.archiveChatStore.selectedChatId == dataIndex ? ' active' : '')} key={dataIndex}>
													
															<div className="col-1 pl-0">
																<p className="text-center">
																    <CheckBoxCustomComponent radioValue={fileData.selected}
								    							         callBack = {() => self.selectComponent(fileData,dataIndex)}/> 
																</p>
															</div>
															<div className="col-2"  onClick={() => self.archiveChatStore.chatSelected(fileData,appstore)}>
																<p className="text-left">							   
																	{fileData.date}
																</p>
															</div>
															<div className="col-2" onClick={() => self.archiveChatStore.chatSelected(fileData,appstore)}>
																<p className="text-left">{fileData.time} </p>
															</div>
															<div className="col-2" onClick={() => self.archiveChatStore.chatSelected(fileData,appstore)}>
																<p className="text-left" style={{wordWrap: "anywhere"}}>{fileData.visitor} </p>
															</div>
															<div className="col-2" onClick={() => self.archiveChatStore.chatSelected(fileData,appstore)}>
																<p className="text-left" style={{wordWrap: "anywhere"}}>{fileData.pickuptime} </p>
															</div>
															<div className="col-1" onClick={() => self.archiveChatStore.chatSelected(fileData,appstore)} >
																<p className="text-left" style={{wordWrap: "anywhere"}}>{fileData.email} </p>
															</div>
															<div className="col-2" style={aligncol} onClick={() => self.archiveChatStore.chatSelected(fileData,appstore)}>
																<p className="text-left ">{fileData.Operators}</p>
															</div> 
													    </div>
												        );
											  })
										 }
										 {
										   this.archiveChatStore.responseData.archivechatdata.length == 0 &&
											 <div className="col-12">
											    <p className="text-left" style={{height: 100,padding: 10}}>{appstore.getLanguageTrans("No entries")}</p>
										    </div> 
										 }
									 </div>
							       </div>
						       </CustomScroll>
							   {this.archiveChatStore.saveinProcess &&
								<div className="chatSectionLoader">
									<GridLoader
										css={override}
										size={20}
										marging={2}
										color={this.props.appstore.selectedColor.color}
									/>
								</div>
				}
						 </div>			
	                 </div>
	              </div>
              </div>
		    </div>
		    <div className="row" id="paginationRow">
			     <div className="col-lg-4 col-sm-2"></div>
			      <div className="col-lg-4 col-sm-6 archive_pagination">
			            {/* <ol className="listInline" style={pageno}>
						{
						 this.archiveChatStore.pagination.map(function(pageno,i){
								
							  return (
									 <li style={floatLi}>{pageno}</li>
								  )
							  })
							 
						} */}
					{/* { this.archiveChatStore.responseData.archivechatdata.length !=0 &&	 */}
						<ReactPaginate
							previousLabel={'previous'}
							nextLabel={'next'}
							breakLabel={'...'}
							breakClassName={'break-me'}
							pageCount={this.archiveChatStore.totalPages}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={this.handlePageClick}
							containerClassName={'pagination'}
							subContainerClassName={'pages pagination'}
							activeClassName={'active'}
       					 />
					
                          { 
							this.archiveChatStore.chatInfo && 
								<ChatInfoComponent show={this.archiveChatStore.chatInfo}
														store={this.archiveChatStore}
														email_btn="Email"
														print_btn="Print"
														 chatData={this.archiveChatStore.userChatdata}
														callBack={() => this.archiveChatStore.chatInfo = false}
														closePopup={() => this.archiveChatStore.chatInfo = false}
														appStore = {appstore}/>		
			                  }
							  { this.archiveChatStore.errormodel &&
								<WarningComponent show={this.archiveChatStore.errormodel}
										store={this.archiveChatStore}  
										cancel_btn="OK"  
										closePopup={() => this.archiveChatStore.errormodel = false}
										appStore = {appstore} />
								}
			          
			            </div>
		        <div className="col-lg-4 col-sm-2"></div>
		   </div>
		    </div>
             
		   
		  </div>	  
	    </div>
          
	</div>
    		
        )
  }
})

export default ArchiveChat;
