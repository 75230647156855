import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button,Card} from 'react-bootstrap';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import CustomScroll from 'react-custom-scroll';
import arrayMove from 'array-move';

import imageURLs from '../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import RuleComponent from './components/RuleComponent';
import AddEditRulesComponent from './components/AddEditRules';
import InvitationRulesStore from './InvitationRulesStore';
import ConfirmationComponent from '../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import WarningComponent from '../../../sharedComponents/confirmationPopup/WarningComponent';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const SortableItem = sortableElement(({invitationRulesStore,rule,pos,appstore}) => <RuleComponent invitationRulesStore = {invitationRulesStore}
																				rule = {rule} pos = {pos} appstore = {appstore}
																				 />);
const SortableContainer = sortableContainer(({children}) => {
  return <div>{children}</div>;
});

const InvitationRulesComponent = observer(class InvitationRulesComponent extends Component {

	constructor (props) {
        super(props);
        this.invitationRulesStore = new InvitationRulesStore(this.props.appstore);
		this.onSortEnd = this.onSortEnd.bind(this); 
		this.state = {websiteUid:this.props.appstore.selectedWebisiteUid}
    }
	componentDidMount(){
		this.invitationRulesStore.isPageLoaded=false;
		this.props.appstore.menuChanged = window.location.pathname;
		this.invitationRulesStore.virtualAgentRules();
		// this.invitationRulesStore.getScriptitem();
		setTimeout(() => { 
		this.invitationRulesStore.isPageLoaded=true;
		},500);
	}

	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {
			this.invitationRulesStore.isPageLoaded=false; 
			let chatinvitaion=undefined;
			  this.invitationRulesStore.cancelSaveResponse(chatinvitaion);
			  this.invitationRulesStore.virtualAgentRules(); 
			  this.invitationRulesStore.getScriptitem();
			  setTimeout(() => { 
	        let elHeight = document.getElementById('rule_section').clientHeight; 
			let windowHeight = window.outerHeight - 61 - 55; 
			this.invitationRulesStore.docHeight = "auto";
			this.invitationRulesStore.scrollToPosition(windowHeight > elHeight ? this.invitationRulesStore.docHeight - elHeight : document.getElementById('rule_section').offsetTop + 55);
     		 },500); 
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});			  
		}
	  }
	componentWillUnmount() {
		window.scrollTo(0, 0);
    }

    onSortEnd (data) {
		this.invitationRulesStore.rules = arrayMove(this.invitationRulesStore.rules, data.oldIndex, data.newIndex);
		this.invitationRulesStore.sortableVertualAgentrules(this.invitationRulesStore.rules);
	}
	checkElement (event) {
		//console.log(event);
	}

	render(){
		if(!this.invitationRulesStore.isPageLoaded){
			return (<div><div className="adminloader">	 
			<GridLoader 
						css={override}
						size={20}
						marging={2} 
						color={this.props.appstore.selectedColor.color}
						// loading={this.responseStore.responsereload}
						/>		 
	          </div> </div>)
		}
		let self = this;
		let chatinvitaion=undefined;
		const {appstore} = this.props;

		return (
			<div id="innerContainer" className="innercontent_wrapper w-100 invitationRulesWrapper" style={{'height': this.invitationRulesStore.docHeight !== 'auto' ? this.invitationRulesStore.docHeight+ "px" : this.invitationRulesStore.docHeight}}>
				<div className="header_title row align-items-center m-0">
						<img src={imageURLs.invitationIcon} alt="faq" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("Invitation Rules")}</h4>
				</div>
				<div className="row">
					<div className="col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form>
								<Form.Group as={Row} controlId="formGroupLanguage">
								    <Form.Label column lg="3" sm="3">{appstore.getLanguageTrans("Select Language")}</Form.Label>
								    <Col sm="9" lg="9" className="customDrpDwn">
										<DropDownComponent dropDownOptions={this.invitationRulesStore.lanDropDownOptions} 
															key={Math.random()}
								    					   store={this.invitationRulesStore}
								    					   callBack={(selectedOption) => this.invitationRulesStore.setLanguage(selectedOption)}
								    					   selectedOption = {this.invitationRulesStore.selectedLanguage}/>
								    </Col>
								  </Form.Group>
							</Form>
							
							<div key={appstore.selectedWebisiteUid} className="row m-0">
								<p className="desc">{appstore.getLanguageTrans("First matched rule will trigger the chat invitation. Drag and drop rules to prioritize.")}</p>
								<div className="panelWrapper col-12 col-lg-12 p-0">
									<div className="scrollWrapper col-12 p-0">
										<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
											<SortableContainer onSortEnd={this.onSortEnd} 
																axis="y" lockAxis="y" 
																lockToContainerEdges={true}
																lockOffset = "0%"
																distance = {2}
																helperClass = "ghostClass" >
											{
												this.invitationRulesStore.rules.map(function(rule,ruleIndex){
													return (
														<SortableItem key={`item-${ruleIndex}`} index={ruleIndex} pos={ruleIndex} rule={rule} 
																	  invitationRulesStore = {self.invitationRulesStore}
																	  appstore = {appstore}/>
													)
												})
											}
											</SortableContainer>
										</CustomScroll>
									</div>

									<Button className="custom_btn float-left addBtn" onClick={() => this.invitationRulesStore.addVaRuleData()}>
									{appstore.getLanguageTrans("ADD NEW RULE")}
									</Button>
								</div>
								
							</div>
							
							<div id="rule_section" className="rules_wrapper">		
							{
								(this.invitationRulesStore.addRule || this.invitationRulesStore.editRule) && 
								<React.Fragment>
										<h4 className="text-left">{this.invitationRulesStore.addRule ? appstore.getLanguageTrans('Add new ') : appstore.getLanguageTrans('Edit ')}invitation rule</h4>
											<AddEditRulesComponent invitationRuleStore={this.invitationRulesStore}
											appstore={appstore}	ruleObj = {this.invitationRulesStore.addRule ? this.invitationRulesStore.newRuleObj : this.invitationRulesStore.selectedRuleToEdit}/>
								</React.Fragment>	
							}

							</div>
						</div>

						
						
					</div>
				</div>
				{
					this.invitationRulesStore.confirm && 
					<ConfirmationComponent show={this.invitationRulesStore.confirm}
											store={this.invitationRulesStore}
											title="Delete Rule"
											body_html="<p>Are you sure you want to delete the selected rule?</p>"
											cancel_btn="Cancel"
											save_btn="Confirm"
											callBack={() => this.invitationRulesStore.confirmDeleteRule(chatinvitaion)}
											closePopup={() => this.invitationRulesStore.confirm = false}
											appStore = {appstore}  />
				}

				{
					this.invitationRulesStore.confirmDelCond && 
					<ConfirmationComponent show={this.invitationRulesStore.confirmDelCond}
											store={this.invitationRulesStore}
											title="Delete Condition"
											body_html="<p>Are you sure you want to delete the selected condition?</p>"
											cancel_btn="Cancel"
											save_btn="Confirm"
											callBack={() => this.invitationRulesStore.confirmDeleteCondition()}
											closePopup={() => this.invitationRulesStore.confirmDelCond = false}
											appStore = {appstore} />
				}
				{ this.invitationRulesStore.errormodel &&
					<WarningComponent show={this.invitationRulesStore.errormodel}
											store={this.invitationRulesStore}  
											cancel_btn="OK"  
											closePopup={() => this.invitationRulesStore.errormodel = false}
											appStore = {appstore} />
				}
			</div>
		)
	}
})
export default InvitationRulesComponent;
