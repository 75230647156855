import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';
import { withBaseIcon } from 'react-icons-kit';
import { exclamationCircle } from 'react-icons-kit/fa/exclamationCircle'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// import '../../library/custom-image-crop.css';
const SideIconContainer =
	withBaseIcon({ size: 64, style: { color: '#EF233C' } });

const ImageWarningPopUp = observer(class ImageWarningPopUpStore extends Component {


	constructor(props) {
		super(props);
	}
	componentWillMount() {

	} 

	render(){
		const {show, 
			   store,
                title, 
				callBack,
				body_html,
				cancel_btn,
				save_btn,
                appStore,
                imagename,
			    singleButton} = this.props;
				//console.log(this.props.callBack());
				
		return (
			<Modal backdrop='static' className={appStore ? appStore.selectedColor.value : ''} show = {show} centered onHide={() => store.confirm = false}>
				 

				  <Modal.Body >
					  <div style={{textAlign: 'center'}}>
				  {/* <SideIconContainer icon={exclamationCircle}/>  */}
				  {/* <p>{appStore.getLanguageTrans("The following files weren't uploaded correctly")}:</p> */}
                  <p>{store.warning_image_name}</p>
				  </div>
				  </Modal.Body>

				  <Modal.Footer>
				    <Button className="btn_cancel" type="button" variant="secondary" onClick={() => this.props.closePopup()} >{appStore.getLanguageTrans("cancel_btn")}</Button>  
				  </Modal.Footer>                      
				</Modal>
		)
	}
})
export default ImageWarningPopUp;
