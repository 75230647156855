import React, {Component } from 'react';
import { observer } from 'mobx-react';

import imageURLs from '../../../../sharedFiles/ImagesURLs'

const ResponseTabChat = observer(class ResponseTabChat extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}

	

	render(){

		let self = this;

		const { 
				response,
				pos,
				appstore,store } = this.props;

	
		return (
			<div  onDoubleClick={() => store.handleDoublclickedResponse(response)}  id={"response"+String(pos)} className={((pos === 0 || pos%2 === 0) ? "row ruleComponent even " : "row ruleComponent odd ")+appstore.selectedColor.value}>
				<div className="align-self-center col-2 pl-0">
					{/* <img style={{opacity:0.5}} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.arrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.arrow_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.arrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.arrow_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.arrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.arrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.arrow_voilet : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.arrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.arrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.arrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.arrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.arrow_skyblue : imageURLs.arrow_steelblue} alt="faq" className="img-fluid"/> */}
				</div>
				<div className="rule_data col-10 p-0">
					<div className="row">
						<div className='col-12 text-left'>
							<p className="ques">
								{response.question}&nbsp;&nbsp;
								{response.imageslist.length>0 &&
								<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.link_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.link_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.link_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.link_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.link_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.link_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.link_voilet : 
								"#039be6" == appstore.selectedColor.color ? imageURLs.link_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.link_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.link_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.link_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.link_skyblue : imageURLs.link_steelblue} alt="" />
								}
							</p>
							<p className="response" style={{wordBreak: "break-word"}} dangerouslySetInnerHTML={{__html: response.value}}>
							</p>							
						</div>
					</div>
				</div>
			</div>
		)
	}
})
export default ResponseTabChat;
