import { decorate, observable, extendObservable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

import { preChatSettings, offlineSettings } from '../../../../assets/JSON/admin_setup_settings';
import axios from 'axios';
import imageURLs from '../../../../sharedFiles/ImagesURLs';

class AddSettingsStore {


  constructor(appstore) {
    this.offlineActionObj = [
      { value: 'msg', label: 'Leave a Message screen' },
      { value: 'link', label: 'Link to external contact page' }
    ];
    this.link = "";
    this.cancel_link = "";
    this.cancel_defaultMsg = "";
    this.preChatEnabled = false;
    this.preChatEnabledVisitors = false;
    this.cancelpreChatEnabled = false;
    this.cancelpreChatEnabledVisitors = false;
    this.email = "";
    this.cancel_email = "";
    this.emailAction = false;
    this.allowChat = false;
    // Object.values return array
    //this.chatFields = appstore.userInfo.accountsmodel.prechat_fields ?Object.values(JSON.parse(appstore.userInfo.accountsmodel.prechat_fields)): [];  //return array)
    //this.offlineChatFields = appstore.userInfo.accountsmodel.leave_message_fields ? Object.values(JSON.parse(appstore.userInfo.accountsmodel.leave_message_fields)) : [];
    this.chatFields = [];
    this.offlineChatFields = [];
    this.addChatFields = true;
    this.addOfflineChatFields = true;
    this.loopCount = 4;
    this.confirm = false;
    this.linkerror = false;

    this.fieldTypeList = [
      { value: "string", label: "Text Line" },
      { value: "text", label: "Large Text" },
      { value: "email", label: "Email" },
      { value: "name", label: "Name" },
      { value: "radio", label: "Radio Buttons" },
      { value: "checkbox", label: "Check Box" },
      { value: "dropdown", label: "Drop Down List" },
      { value: "label", label: "Label" },
      { value: "subject", label: "Subject" },

    ];
    this.offline_action = this.offlineActionObj[0].value;
    this.canceloffline_action = ""
    this.newField = {
      "id": null,
      "name": "",
      "type": this.fieldTypeList[0].value,
      "typeObj": this.fieldTypeList[0],
      "params": "",
      "mandatory": 0,
      "itemorder": null,
      "paramObj": [],
      "edit": true
    }
    this.newOfflineField = {
      "id": null,
      "name": "",
      "type": this.fieldTypeList[0].value,
      "typeObj": this.fieldTypeList[0],
      "params": "",
      "mandatory": 0,
      "itemorder": null,
      "paramObj": [],
      "edit": true
    }
    this.chat_invitation = [
      { index: 0, label: 'None', value: 0 },
      { index: 1, label: 'Package 1 sound', value: imageURLs.chat_invitation1 },
      { index: 2, label: 'Package 2 sound', value: imageURLs.chat_invitation2 },
      { index: 3, label: 'Package 3 sound', value: imageURLs.chat_invitation3 },
      { index: 4, label: 'Package 4 sound', value: imageURLs.chat_invitation4 },
      { index: 5, label: 'Package 5 sound', value: imageURLs.chat_invitation5 },
    ];
    this.visitor_message_received = [
      { index: 0, label: 'None', value: 0 },
      { index: 1, label: 'Package 1 sound', value: imageURLs.msg_received_sound1 },
      { index: 2, label: 'Package 2 sound', value: imageURLs.msg_received_sound2 },
      { index: 3, label: 'Package 3 sound', value: imageURLs.msg_received_sound3 },
      { index: 4, label: 'Package 4 sound', value: imageURLs.msg_received_sound4 },
      { index: 5, label: 'Package 5 sound', value: imageURLs.msg_received_sound5 },

    ];

    this.chat_request = [
      { index: 0, label: 'None', value: 0 },
      { index: 1, label: 'Package 1 sound', value: imageURLs.chat_request1 },
      { index: 2, label: 'Package 2 sound', value: imageURLs.chat_request2 },
      { index: 3, label: 'Package 3 sound', value: imageURLs.chat_request3 },
      { index: 4, label: 'Package 4 sound', value: imageURLs.chat_request4 },
      { index: 5, label: 'Package 5 sound', value: imageURLs.chat_request5 },
      { index: 'legacy', label: 'Default sound', value: imageURLs.chat_request_default_sound },
    ];

    this.message_received = [
      { index: 0, label: 'None', value: 0 },
      { index: 1, label: 'Package 1 sound', value: imageURLs.msg_received_sound1 },
      { index: 2, label: 'Package 2 sound', value: imageURLs.msg_received_sound2 },
      { index: 3, label: 'Package 3 sound', value: imageURLs.msg_received_sound3 },
      { index: 4, label: 'Package 4 sound', value: imageURLs.msg_received_sound4 },
      { index: 5, label: 'Package 5 sound', value: imageURLs.msg_received_sound5 },
      { index: 'legacy', label: 'Default sound', value: imageURLs.msg_received_default_sound },

    ];

    this.message_sent = [
      { index: 0, label: 'None', value: 0 },
      { index: 1, label: 'Package 1 sound', value: imageURLs.msg_sent_sound1 },
      { index: 2, label: 'Package 2 sound', value: imageURLs.msg_sent_sound2 },
      { index: 3, label: 'Package 3 sound', value: imageURLs.msg_sent_sound3 },
      { index: 4, label: 'Package 4 sound', value: imageURLs.msg_sent_sound4 },
      { index: 5, label: 'Package 5 sound', value: imageURLs.msg_sent_sound5 },
    ];

    this.new_visitor = [
      { index: 0, label: 'None', value: 0 },
      { index: 1, label: 'Package 1 sound', value: imageURLs.msg_received_sound1 },
      { index: 2, label: 'Package 2 sound', value: imageURLs.msg_received_sound2 },
      { index: 3, label: 'Package 3 sound', value: imageURLs.msg_received_sound3 },
      { index: 4, label: 'Package 4 sound', value: imageURLs.msg_received_sound4 },
      { index: 5, label: 'Package 5 sound', value: imageURLs.msg_received_sound5 },
      { index: 'legacy', label: 'Default sound', value: imageURLs.new_visitor_default_sound },
    ];

    this.soundSelections = [
      { soundType: 'chat_request', label: 'Chat request', selectedSound: this.chat_request[1], options: this.chat_request },
      { soundType: 'message_received', label: 'Message received', selectedSound: this.message_received[1], options: this.message_received },
      { soundType: 'message_sent', label: 'Message sent', selectedSound: this.message_sent[1], options: this.message_sent },
      { soundType: 'new_visitor', label: 'New visitor on monitor', selectedSound: this.new_visitor[1], options: this.new_visitor }
    ];
    this.visitorsoundSelections = [
      { soundType: 'visitor_chat_invitation', label: 'Chat invitation', selectedSound: this.chat_invitation[1], options: this.chat_invitation },
      { soundType: 'visitor_message_received', label: 'Message received', selectedSound: this.visitor_message_received[1], options: this.visitor_message_received },
      { soundType: 'visitor_message_sent', label: 'Message sent', selectedSound: this.message_sent[1], options: this.message_sent },
    ];

    this.sounds = [
      { index: 0, label: 'None', value: 0 },
      { index: 1, label: 'Package 1 sound', value: 1 },
      { index: 2, label: 'Package 2 sound', value: 2 },
      { index: 3, label: 'Package 3 sound', value: 3 },
      { index: 4, label: 'Package 4 sound', value: 4 },
      { index: 5, label: 'Package 5 sound', value: 5 },
    ];
    this.chat_routing = [
      { index: 0, label: 'Incoming chat rings to all operators at once', value: "ringtoall" },
      { index: 1, label: 'Rotation is based on operators login time', value: "logintime" },
      { index: 2, label: 'Rotation based on chats time', value: "chattime" },
    ];
    this.web_chat_routing = "ringtoall";

    // this.soundSelections = [
    //   { label: 'Chat invitation', selectedSound: this.sounds[0] },
    //   { label: 'Message received', selectedSound: this.sounds[0] },
    //   { label: 'Message sent', selectedSound: this.sounds[0] },
    //   { label: 'New visitor on monitor', selectedSound: this.sounds[0] }
    // ];

    this.overrideSounds = true;
    this.soundPackage = this.sounds[1];
    this.chat_routing_pkg = this.chat_routing[0];

    this.setDefaultValues();
    this.errorMessageComponent = false;
    this.errorMessage = "";
    this.prechatsorted = Math.random();

    this.emailTranscriptError = {
      error: false,
      msg: 'Please enter a valid email.',
    }
  }
  chatroutingselected = (selectedOption) => {
    this.chat_routing_pkg = selectedOption
  }

  soundsToPackage = (selectedOption) => {
    this.soundPackage = selectedOption
    let i = this.soundPackage.index;
    this.soundSelections = [
      { soundType: 'chat_request', label: 'Chat request', selectedSound: this.chat_request[i], options: this.chat_request },
      { soundType: 'message_received', label: 'Message received', selectedSound: this.message_received[i], options: this.message_received },
      { soundType: 'message_sent', label: 'Message sent', selectedSound: this.message_sent[i], options: this.message_sent },
      { soundType: 'new_visitor', label: 'New visitor on monitor', selectedSound: this.new_visitor[i], options: this.new_visitor }
    ];
    this.visitorsoundSelections = [
      { soundType: 'visitor_chat_invitation', label: 'Chat invitation', selectedSound: this.chat_invitation[i], options: this.chat_invitation },
      { soundType: 'visitor_message_received', label: 'Message received', selectedSound: this.message_received[i], options: this.message_received },
      { soundType: 'visitor_message_sent', label: 'Message sent', selectedSound: this.message_sent[i], options: this.message_sent },
    ];

  }

  setOfflineSetting(data) {
    this.offlineChatFields = data.leave_message_fields ? Object.values(JSON.parse(data.leave_message_fields)) : [];
    this.email = data.email_messages;
    this.cancel_email = data.email_messages;
  }
  setDefaultValues() {
    var self = this;
    this.chatFields.map(function (field, fieldIndex) {
      field.edit = false;
      field.paramObj = [];
      let fieldParamObj = field.params.split('\n');
      fieldParamObj.map(function (param, paramIndex) {
        let obj = {
          value: paramIndex + 1,
          label: param
        }
        field.paramObj.push(obj);
      })

      self.fieldTypeList.map(function (listType, listIndex) {
        if (listType.value === field.type) {
          field.typeObj = listType;
        }
      })
      field.clone = Object.assign({}, field);
    })

    this.offlineChatFields.map(function (field, fieldIndex) {
      field.edit = false;
      field.paramObj = [];
      let fieldParamObj = field.params.split('\n');
      fieldParamObj.map(function (param, paramIndex) {
        let obj = {
          value: paramIndex + 1,
          label: param
        }
        field.paramObj.push(obj);
      })
      self.fieldTypeList.map(function (listType, listIndex) {
        if (listType.value === field.type) {
          field.typeObj = listType;
        }
      })
      field.clone = Object.assign({}, field);
    })

    this.chatFieldsClone = cloneDeep(this.chatFields);
    this.offlineChatFieldsClone = cloneDeep(this.offlineChatFields);
    this.confirmDelete = false;
  }
  setValue() {

  }

  addField(type) {
    if (type === 1) {
      this.addChatFields = false;
      this.newField.clone = Object.assign({}, this.newField);
      this.newField.clone.id = this.chatFieldsClone.length + 1;
      this.chatFieldsClone.push(this.newField);
      setTimeout(() => {
        this.componentScroll = document.getElementById("chatComp" + String(type) + String(this.chatFieldsClone.length - 1)).offsetTop;
      }, 0);
    }
    else {
      this.addOfflineChatFields = false;
      this.newOfflineField.clone = Object.assign({}, this.newOfflineField);
      this.newOfflineField.clone.id = this.offlineChatFieldsClone.length + 1;
      this.offlineChatFieldsClone.push(this.newOfflineField);
      setTimeout(() => {
        this.componentScroll = document.getElementById("chatComp" + String(type) + String(this.offlineChatFieldsClone.length - 1)).offsetTop;
      }, 0);
    }

  }

  saveField(fieldCloned, type, fieldChanged) {
    var self = this;
    if (!fieldChanged.edit) {
      if (type === 1) {
        fieldChanged.edit = this.addChatFields;
        this.addChatFields = false;
      }
      else {
        fieldChanged.edit = this.addOfflineChatFields;
        this.addOfflineChatFields = false;
      }
      return;
    }

    if (fieldCloned.type === "") {
      this.cancelField(type, fieldChanged);
      return;
    }
    if (type === 1) {
      if (fieldCloned.type !== "") {
        if (fieldCloned.name == "") {
          this.errorMessageComponent = true;
          this.errorMessage = "Please Enter Field Title*";
          return;
        }
      }
      if (fieldCloned.type == "radio" || fieldCloned.type == "dropdown") {
        if (fieldCloned.params == "") {
          this.errorMessageComponent = true;
          this.errorMessage = "Please Enter Items*";
          return
        }
      }
      this.addChatFields = true;

      for (let fieldIndex = 0; fieldIndex < this.chatFieldsClone.length; fieldIndex++) {

        if (this.chatFieldsClone[fieldIndex].clone.id === fieldChanged.clone.id) {
          this.chatFieldsClone[fieldIndex] = Object.assign({}, fieldCloned);
          this.chatFieldsClone[fieldIndex].edit = false;
          this.chatFieldsClone[fieldIndex].clone = Object.assign({}, self.chatFieldsClone[fieldIndex]);
          break;
        }
      }
      //   this.chatFieldsClone.map(function (field, fieldIndex) {
      //   if (field.clone.id === fieldChanged.clone.id) {
      //     self.chatFieldsClone[fieldIndex] = Object.assign({}, fieldCloned);
      //     self.chatFieldsClone[fieldIndex].edit = false;
      //     self.chatFieldsClone[fieldIndex].clone = Object.assign({}, self.chatFieldsClone[fieldIndex]);
      //   }
      // })

      this.chatFields = cloneDeep(this.chatFieldsClone);
    }
    else {
      if (fieldCloned.type !== "") {
        if (fieldCloned.name == "") {
          this.errorMessageComponent = true;
          this.errorMessage = "Please Enter Field Title*";
          return;
        }
      }
      if (fieldCloned.type == "radio" || fieldCloned.type == "dropdown") {
        if (fieldCloned.params == "") {
          this.errorMessageComponent = true;
          this.errorMessage = "Please Enter Items*";
          return
        }
      }
      this.addOfflineChatFields = true;
      for (let fieldIndex = 0; fieldIndex < this.offlineChatFieldsClone.length; fieldIndex++) {
        if (this.offlineChatFieldsClone[fieldIndex].clone.id === fieldChanged.clone.id) {
          this.offlineChatFieldsClone[fieldIndex] = Object.assign({}, fieldCloned);
          this.offlineChatFieldsClone[fieldIndex].edit = false;
          this.offlineChatFieldsClone[fieldIndex].clone = Object.assign({}, self.offlineChatFieldsClone[fieldIndex]);
          break;
        }
      }
      // this.offlineChatFieldsClone.map(function (field, fieldIndex) {
      //   if (field.id === fieldChanged.id) {
      //     self.offlineChatFieldsClone[fieldIndex] = Object.assign({}, fieldCloned);
      //     self.offlineChatFieldsClone[fieldIndex].edit = false;

      //     self.offlineChatFieldsClone[fieldIndex].clone = Object.assign({}, self.offlineChatFieldsClone[fieldIndex]);
      //   }
      // })


      this.offlineChatFields = cloneDeep(this.offlineChatFieldsClone);
    }
  }

  cancelField(type, fieldChanged) {
    var self = this;
    if (!fieldChanged.edit) {
      //for delete
      if (type === 1) {
        this.deleteType = "onlineField"
        this.prechatfieldtoDelete = fieldChanged;
      } else {
        this.deleteType = "offlineField"
        this.offlinefieldtoDelete = fieldChanged;
      }

      this.confirmDelete = true;
      return;
    }
    //for cancel onlinefield
    if (type === 1) {
      this.addChatFields = true;
      for (let i = 0; i < this.chatFieldsClone.length; i++) {
        if (this.chatFieldsClone[i].id === null) {
          this.chatFieldsClone.splice(i, 1);
          break;
        }
        else if (this.chatFieldsClone[i].id === fieldChanged.id) {
          this.chatFieldsClone[i].clone = Object.assign({}, this.chatFieldsClone[i]);
          this.chatFieldsClone[i].edit = false;
          break;
        }
      }
      this.chatFields = cloneDeep(this.chatFieldsClone);
    }
    //for cancel offlinefield
    else {
      this.addOfflineChatFields = true;
      for (let i = 0; i < this.offlineChatFieldsClone.length; i++) {
        if (this.offlineChatFieldsClone[i].id === null) {
          this.offlineChatFieldsClone.splice(i, 1);
          break;
        }
        else if (this.offlineChatFieldsClone[i].id === fieldChanged.id) {
          this.offlineChatFieldsClone[i].clone = Object.assign({}, this.offlineChatFieldsClone[i]);
          this.offlineChatFieldsClone[i].edit = false;
          break;
        }
      }

      this.offlineChatFields = cloneDeep(this.offlineChatFieldsClone);
    }
  }


  deleteField() {

    if (!this.addChatFields || !this.addOfflineChatFields) {
      this.confirmDelete = false;
      return;
    }

    let self = this;
    if (this.deleteType === "onlineField") {
      //handle online prechatfields deletion here
      const remainingField = this.chatFieldsClone.filter(function (field, index) {
        return field.clone.id != self.prechatfieldtoDelete.id
      });

      //change index
      remainingField.map((field, index) => {
        field.clone.id = index + 1;
        field.id = index + 1;
      });

      this.chatFieldsClone = remainingField;
      this.chatFields = cloneDeep(this.chatFieldsClone);

    } else {
      //for offline field
      const remainingField = this.offlineChatFieldsClone.filter(function (field, index) {
        return field.clone.id != self.offlinefieldtoDelete.id
      });
      //change index
      remainingField.map((field, index) => {
        field.clone.id = index + 1;
        field.id = index + 1;
      });

      this.offlineChatFieldsClone = remainingField;
      this.offlineChatFields = cloneDeep(this.offlineChatFieldsClone);
    }

    this.setDefaultValues();
    this.confirmDelete = false
  }

  savePreChatSettings(appstore) {
    //window.scrollTo(0,document.getElementById('chatPreview').offsetTop);

    if (!this.addChatFields)
      return;

    this.chatFields = cloneDeep(this.chatFieldsClone);
    let data = cloneDeep(this.chatFields);

    for (let i in data) {
      delete data[i]["edit"];
      delete data[i]["paramObj"];
      delete data[i]["typeObj"];
      delete data[i]["clone"];
    }
    let obj = {};
    for (let i in data) {
      obj[parseInt(i) + 1] = data[i]
    }

    const websiteModel = {
      "prechat_fields": JSON.stringify(obj),
      "website_uid": appstore.selectedWebisiteUid,
      "enablePrechat": this.preChatEnabled ? 1 : 0,
      "alwaysShowPrechat": this.preChatEnabledVisitors ? 1 : 0
    }
    this.pageisLoaded = false;
    axios({
      method: 'POST',
      url: appstore.url + 'savecustomprechatfield.json',
      headers: { 'content-type': 'application/json' },
      data: websiteModel
    }).then(result => {
      if (result.data.length == 1) {
        if (result.data.logoutstatus == 1) {
          appstore.logout();
        }
      }
      if (result.data.querystatus == 1) {
        this.chatFields = result.data.prechat_fields ? Object.values(JSON.parse(result.data.prechat_fields)) : [];  //return array
        this.preChatEnabled = result.data.enablePrechat == 1 ? true : false;
        this.preChatEnabledVisitors = result.data.alwaysShowPrechat == 1 ? true : false;
        this.setDefaultValues();

        //clone objects for resetting on clicking cancel
        this.cancelChatFieldsClone = cloneDeep(this.chatFieldsClone);
        this.cancelpreChatEnabled = result.data.enablePrechat == 1 ? true : false;
        this.cancelpreChatEnabledVisitors = result.data.alwaysShowPrechat == 1 ? true : false;
      } else {
        this.errormodel = true;
      }
      this.pageisLoaded = true;
      appstore.global_errorcount = 0;
    }).catch((error) => {
      // if (error.response) { 
      //   appstore.logout();
      // }
      this.pageisLoaded = true;
      console.log(error);
      console.log("error count :" + appstore.global_errorcount);
      appstore.global_errorcount = appstore.global_errorcount + 1;
      if (appstore.global_errorcount > 20) {
        // appstore.logout();
      }
    })
  }

  cancelPreChatSettings() {
    this.chatFieldsClone = cloneDeep(this.cancelChatFieldsClone);
    this.chatFields = cloneDeep(this.cancelChatFieldsClone);
    this.preChatEnabled = this.cancelpreChatEnabled;
    this.preChatEnabledVisitors = this.cancelpreChatEnabledVisitors;
    this.addChatFields = true;
  }

  saveOfflineChatSettings(appstore) {

    if (!this.addOfflineChatFields)
      return;

    // window.scrollTo(0,document.getElementById('offlinePreview').offsetTop);
    this.offlineChatFields = cloneDeep(this.offlineChatFieldsClone);
    let data = cloneDeep(this.offlineChatFields);
    for (let i in data) {
      delete data[i]["edit"];
      delete data[i]["paramObj"];
      delete data[i]["typeObj"];
      delete data[i]["clone"];
    }
    let obj = {};
    for (let i in data) {
      obj[parseInt(i) + 1] = data[i]
    }

    const websiteModel = {
      "leave_message_fields": JSON.stringify(obj),
      "website_uid": appstore.selectedWebisiteUid,
      "email_messages": this.email,
      "offline_action": this.offline_action,
      "offline_msg": this.defaultMsg,
      "offline_link": this.link,
      "send_transcripts": this.emailAction == true ? 1 : 0,
      "sms_web_online": this.allowChat == true ? 1 : 0
    }

    this.pageisLoaded = false;
    this.emailTranscriptError.error = false;
    axios({
      method: 'POST',
      url: appstore.url + 'saveofflineSetting.json',
      headers: { 'content-type': 'application/json' },
      data: websiteModel
    }).then(result => {
      if (result.data.length == 1) {
        if (result.data.logoutstatus == 1) {
          appstore.logout();
        }
      }
      if (result.data.querystatus == 1) {
        // this.chatFields = result.data.prechat_fields ? Object.values(JSON.parse(result.data.prechat_fields)): [];  //return array
        this.offlineChatFields = result.data.leave_message_fields ? Object.values(JSON.parse(result.data.leave_message_fields)) : [];
        this.offline_action = result.data.offline_action;
        this.defaultMsg = result.data.offline_msg;
        this.link = result.data.offline_link;
        this.emailAction = result.data.send_transcripts == 1 ? true : false;
        this.allowChat = result.data.sms_web_online == 1 ? true : false;
        let websites = appstore.userInfo.allwebistes;
        for (let i in websites) {
          if (websites[i].website_uid === appstore.selectedWebisiteUid) {
            websites[i].offline_action = result.data.offline_action;
            websites[i].leave_message_fields = result.data.leave_message_fields;
            websites[i].offline_msg = result.data.offline_msg;
            websites[i].offline_link = result.data.offline_link;
            websites[i].send_transcripts = result.data.send_transcripts;
            websites[i].sms_web_online = result.data.sms_web_online;
            break;
          }
        }

        this.setDefaultValues();

        //clone objects for resetting on clicking cancel
        this.cancelOfflineChatFieldsClone = cloneDeep(this.offlineChatFieldsClone);
        this.canceloffline_action = result.data.offline_action;
        this.cancel_defaultMsg = result.data.offline_msg;
        this.cancel_link = result.data.offline_link;
        this.cancel_emailAction = result.data.send_transcripts == 1 ? true : false;
        this.cancel_allowChat = result.data.sms_web_online == 1 ? true : false;
      } else {
        this.errormodel = true;
      }
      this.pageisLoaded = true;
      appstore.global_errorcount = 0;
      // window.scrollTo(0,450);
    }).catch((error) => {
      // if (error.response) {
      //    appstore.logout();
      // }
      this.pageisLoaded = true;
      console.log(error);
      console.log("error count :" + appstore.global_errorcount);
      appstore.global_errorcount = appstore.global_errorcount + 1;
      if (appstore.global_errorcount > 20) {
        // appstore.logout();
      }
    })
  }

  cancelOfflineChatSettings() {
    this.defaultMsg = "";
    this.email = "";
    this.offlineChatFieldsClone = cloneDeep(this.cancelOfflineChatFieldsClone);
    this.offlineChatFields = cloneDeep(this.cancelOfflineChatFieldsClone);
    this.offline_action = this.canceloffline_action;
    this.defaultMsg = this.cancel_defaultMsg;
    this.email = this.cancel_email;
    this.link = this.cancel_link;
    this.emailAction = this.cancel_emailAction;
    this.allowChat = this.cancel_allowChat;
    this.addOfflineChatFields = true;
  }
  saveChatrouting(event, appstore) {
    event.preventDefault();
    this.pageisLoaded = false;
    var chatrouting_to=this.chat_routing_pkg.value;
    axios({
      method: 'POST',
      url: appstore.url + 'savechatrouting.json',
      headers: { 'content-type': 'application/json' },
      data: {"chat_routing":chatrouting_to,"website_uid":appstore.selectedWebisiteUid},
    }).then(result => {
      if(result==1){
        this.web_chat_routing=this.chat_routing_pkg.value;
      }
      this.pageisLoaded = true;
      appstore.global_errorcount = 0;
    }).catch((error) => { 
      this.pageisLoaded = true;
      console.log(error);
      console.log("error count :" + appstore.global_errorcount);
      appstore.global_errorcount = appstore.global_errorcount + 1;
      if (appstore.global_errorcount > 20) {
        // appstore.logout();
      }
    })
  }

  saveProfileSound(event, appstore) {
    event.preventDefault();

    this.pageisLoaded = false;
    let chat_request = this.soundSelections[0].selectedSound.index
    let message_received = this.soundSelections[1].selectedSound.index
    let message_sent = this.soundSelections[2].selectedSound.index
    let new_visitor = this.soundSelections[3].selectedSound.index
    let loop_count = this.loopCount;
//alert(chat_request+"=r"+message_received+"=s"+message_sent+"=n"+new_visitor);
    //visitor sounds
    let visitor_chat_invitation = this.visitorsoundSelections[0].selectedSound.index;
    let visitor_message_received = this.visitorsoundSelections[1].selectedSound.index;
    let visitor_message_sent = this.visitorsoundSelections[2].selectedSound.index;
    //alert(visitor_chat_invitation+"=r"+visitor_message_received+"=s"+visitor_message_sent);
    let scopeMode = "website";//appstore.userInfo.usersmodel.user_type_id == 2?"operator":"accounts" ;
    let ovverideSounds = this.overrideSounds;

    let soundlist = [];
    let keys = ["soundType", "package_sound", "soundName", "scopeMode", "website_uid"];
    let soundtype = ["chat_request", "message_received", "message_sent", "new_visitor", "loop_count", "visitor_chat_invitation", "visitor_message_received", "visitor_message_sent"];
    let soundtypevalue = [chat_request, message_received, message_sent, new_visitor, loop_count, visitor_chat_invitation, visitor_message_received, visitor_message_sent];

    for (let i = 0; i < soundtype.length; i++) {
      let list = {}
      list[keys[0]] = soundtype[i];
      list[keys[1]] = soundtypevalue[i];
      list[keys[2]] = "";
      list[keys[3]] = scopeMode;
      list[keys[4]] = appstore.selectedWebisiteUid

      soundlist.push(list);
    }

    axios({
      method: 'POST',
      url: appstore.url + 'savesound.json',
      headers: { 'content-type': 'application/json' },
      data: soundlist,
    }).then(result => {
      if (result.data.length == 1) {
        if (result.data[0].logoutstatus == 1) {
          appstore.logout();
        }
      }
      if (result.data.status == 1) {
        // appstore.userInfo.usersmodel.color=result.data.color;
        // appstore.userInfo.usersmodel.custom_message = result.data.custom_message;
        // //clone object for cancel button option
        this.backupSoundSelections = this.soundSelections.slice(0);

      }
      this.visitorsoundcloned = {
        soundSelections: this.visitorsoundSelections
      }
      this.backupvisitorSoundSelections = JSON.parse(JSON.stringify(this.visitorsoundcloned));
      this.pageisLoaded = true;
      window.scrollTo(0, 1750);
      appstore.global_errorcount = 0;
    }).catch((error) => {
      // if (error.response) { 
      //     appstore.logout();
      //  } 
      this.pageisLoaded = true;
      console.log(error);
      console.log("error count :" + appstore.global_errorcount);
      appstore.global_errorcount = appstore.global_errorcount + 1;
      if (appstore.global_errorcount > 20) {
        // appstore.logout();
      }
    })

  }


}

decorate(AddSettingsStore, {
  preChatEnabled: observable,
  preChatEnabledVisitors: observable,
  offline_action: observable,
  link: observable,
  email: observable,
  soundcloned: observable,
  emailAction: observable,
  allowChat: observable,
  chatFields: observable,
  offlineChatFields: observable,
  newField: observable,
  newOfflineField: observable,
  soundPackage: observable,
  soundPlaying: observable,
  confirmDelete: observable,
  chatFieldsClone: observable,
  offlineChatFieldsClone: observable,
  componentScroll: observable,
  defaultMsg: observable,
  addChatFields: observable,
  addOfflineChatFields: observable,
  pageisLoaded: observable,
  prechatfieldtoDelete: observable,
  offlinefieldtoDelete: observable,
  cancelChatFieldsClone: observable,
  cancelOfflineChatFieldsClone: observable,
  errormodel: observable,
  loopCount: observable,
  soundSelections: observable,
  backupSoundSelections: observable,
  cancelpreChatEnabled: observable,
  cancelpreChatEnabledVisitors: observable,
  canceloffline_action: observable,
  cancel_defaultMsg: observable,
  cancel_link: observable,
  cancel_email: observable,
  cancel_emailAction: observable,
  cancel_allowChat: observable,
  confirm: observable,
  linkerror: observable,
  errorMessageComponent: observable,
  errorMessage: observable,
  prechatsorted: observable,
  visitorsoundSelections: observable,
  backupvisitorSoundSelections: observable,
  visitorsoundcloned: observable,
  emailTranscriptError: observable,
  chat_routing_pkg: observable,
  web_chat_routing: observable
})
export default AddSettingsStore;