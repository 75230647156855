import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import MailComponent from '../../../sharedComponents/getWidgetCode/mailPopup/MailComponent';
import AdvancedCodeComponent from '../../../sharedComponents/getWidgetCode/AdvancedCodeComponent';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from 'axios';

const GetWidgetCodeComponent = observer(class GetWidgetCodeComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}

	componentDidMount(){
		this.props.store.codeCopied=false;
	}
	
		
	render(){
		const { appStore,
				store,
				show } = this.props;
		const uid = store.thumbnailGetcode ?  store.thumbnailGetcode.uid :"";		
		return (
			<Modal backdrop='static' className={"mailer " + (appStore ? appStore.selectedColor.value : '')} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => store.getCode = false}>
				  <Modal.Header closeButton>
				    <Modal.Title>{appStore.getLanguageTrans("Get Code")}</Modal.Title>
				    </Modal.Header>
				    <form className="websiteWidgetGetCode">
						<div className="row">
							<div id="code" className="codeWrapper col-12">
					<div className="col-12">
						<p>{appStore.getLanguageTrans("Code")}</p>
					</div>
					<div className="col-12">
						<textarea className="getcodetextarea" name="ipBlackList" rows="7" defaultValue={appStore.generateAssistCode(uid)} />
						<div className="row m-0">
							<div className="col-12 formBtns">
								<div className="">
									<Button className="btn_clr" variant="light" onClick={() => {store.getCode = false}}>
								    	<p>{appStore.getLanguageTrans("Cancel")}</p>
								    </Button>
									<CopyToClipboard text={appStore.generateAssistCode(uid)} onCopy={() => store.codeCopied= true}>
									   <Button className="btn_clr" variant="light">
											<p>{store.codeCopied ? appStore.getLanguageTrans("Copied to Clipboard") : appStore.getLanguageTrans("Copy to Clipboard")}</p>
									   </Button>
									</CopyToClipboard>
								
								    <Button className="btn_clr" variant="light" onClick={() => store.sendCode = true}>
								    	<p>{appStore.getLanguageTrans("Send to Developer")}</p>
								    </Button>
									<Button className="btn_clr" variant="light" onClick={() => store.advancedcode = true}>
								    	<p>{appStore.getLanguageTrans("Adnvanced Code")}</p>
								    </Button>
								</div>
							</div>
						</div>
					</div>

				{
					store.sendCode && 
						<MailComponent store={store} appStore={appStore} show={store.sendCode} getcode={appStore.generateAssistCode(uid)} />
				}

				{
					store.advancedcode && 
						<AdvancedCodeComponent store={store} appStore={appStore} show={store.advancedcode} getcode={appStore.generateAssistCode(uid)} uid={uid} />
				}
			</div>
						</div>
						<Modal.Footer>
						</Modal.Footer>
					</form>

				  
				</Modal>
		)
	}
})
export default GetWidgetCodeComponent;
