import React, {Component } from 'react';
import { observer } from 'mobx-react';
import CustomScroll from 'react-custom-scroll';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import CheckBoxCustomComponent from '../../../../../sharedComponents/checkBox_2/checkBoxCustom2';
import RadioButtonComponent from '../../../../../sharedComponents/radionButton2/RadioButton';

const ChatComponent = observer(class ChatComponent extends Component {

	constructor (props) {
		super(props); 
		// this.state = {
		// 	chatFields: this.props.settingsStore.chatFields
		//   };
    }
	componentWillMount(){

	}

	componentDidUpdate(prevProps, prevState) {
		// if(prevProps.chatFields !== this.props.settingsStore.chatFieldsClone) {
		// 	this.setState({chatFields: this.props.settingsStore.chatFieldsClone});
		//   }
			 
	}

	sendChatData(){
		//this.props.scriptStore.setChatData(this.props.scriptStore.chatUserData);
		//this.props.scriptStore.chatUserData = "";
	}

	render(){
		const { settingsStore,
				fields,appstore} = this.props;  
		return (
			<div  className="chatPreview" >
				<div className="headerTitle active">
					<h6>{appstore.getLanguageTrans("Live Chat")}</h6>
				</div>
				<Form onSubmit= {() => this.sendChatData()}>
					<div className="chatBox">
						<div className="msgArea text-left">
							<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
							{
								fields.map(function(field,fieldIndex){
									if(field.type === "label"){
										return(
											<p key={fieldIndex}>{appstore.getLanguageTrans(field.name)}</p>
										)
									}
									else {
										return (
											<Form.Group key={fieldIndex} as={Row} controlId={"formGroup"+field.name}>
												{ field.type !== "checkbox" && 
											    	<Form.Label column sm="12">{appstore.getLanguageTrans(field.name)}
											    		{field.mandatory === 1 &&
											    			<span className="mandatory">*</span>
											    		}
											    	</Form.Label>
												}
											    <Col sm="12" className={(field.type === "dropdown" ? "customDrpDwn" : "")}>
											    	{ (field.type === "string" || field.type === "email" || field.type === "name" || field.type === "subject") &&
												    	<Form.Control type="text" placeholder="" 
												    					name={field.name}
												    					className="col-12"/>
											    	}
											    	{ field.type === "text" &&

											    		<textarea name={field.name} rows="4" className="col-12" />
											    	}
											    	{ field.type === "radio" &&
											    		<RadioButtonComponent
											    				options={field.paramObj}
											    				groupName={field.name}
											    				callBack = {() => {}}
											    				/>
											    	}
											    	{ field.type === "checkbox" &&
											    		<React.Fragment>
												    		<CheckBoxCustomComponent 
												    			radioValue={field.value}
												    			callBack = {() => field.value = !field.value}/>
												    		<Form.Label column xs="4">{appstore.getLanguageTrans(field.name)}
												    			{field.mandatory === 1 &&
											    					<span className="mandatory">*</span>
											    				}
												    		</Form.Label>
												    	</React.Fragment>
											    	}
											    	{ field.type === "dropdown" &&
											    		<DropDownComponent dropDownOptions={field.paramObj} 
									    					   isFixed = {true}
									    					   callBack = {() => {}}/>
											    	}
											    </Col>
									  		</Form.Group>

										)
									}
								})
							}
							</CustomScroll>
						</div>
						<div className="chatArea">
								
							  <div className="row m-0">
								<div className="col-12 formBtns">
									<p><span className="mandatory">*</span>
									{appstore.getLanguageTrans("Mandatory Fields")}
									</p>
									<div className="pullRight">
										<Button className="btn_send preview_btn" disabled onClick = {() => this.sendChatData()} >
										 {appstore.getLanguageTrans("Chat now")}
									    </Button>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</Form>
			</div>		
		)
	}
})
export default ChatComponent;
