import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import navigationStore from '../../../sharedFiles/navigation-store';
import { nodeName } from 'jquery';


const WebsiteComponent = observer(class WebsiteComponent extends Component {

	constructor (props) {
        super(props);
    }

	componentDidMount(){

		const element = document.getElementsByClassName("websitesDetails");
		if(element !=null && element.length > 1){
            element[element.length-1].firstChild.style.height = element[0].offsetHeight+"px";
		}
	}

	componentWillUnmount() {
    }


	
	render(){

		var self = this;
		const { appstore,
				homeComponentStore,
				website
		      } = this.props;
		return (
			<div className="websitesDetails col-12 col-sm-6 col-md-4" style={appstore.helpTourStarted ? {pointerEvents:"none",opacity:0.3}:{}}>
				<div className="box col-12">
					<p className="activeMode"><span className={website.active? "installed_icon" : "uninstalled_icon"}></span> </p>
					<h4>{website.name}</h4>
					{website.domain_name.length>0 && 
					<p className="webLink">{website.domain_name}</p>
					}{website.domain_name.length==0 && 
						<p className="webLink">&nbsp;</p>
						}
					<div className="actionsWrapper col-12">
						<div className="actions row">
							<p className={appstore.isOwner ? "col-6" : "col-12"} style={{textAlign: appstore.isOwner ? "right":"center"}} >
								<span onClick={() =>{appstore.addNewSite=false;appstore.navigate('/admin/setup/widgets/'+website.website_uid);appstore.editwebsite=true}} style={appstore.userInfo.allwebistes.length > appstore.userInfo.accountplan.max_websites?{pointerEvents:"none",opacity:0.3}:{}}><img src={imageURLs.editWhiteIcon} className="img-fluid" />
								{appstore.getLanguageTrans("Edit")}</span>
							</p>
						{ appstore.isOwner &&
							<p className="col-6">
								<span onClick={() =>{homeComponentStore.deletAction(website)}}><img src={imageURLs.deleteWhiteIcon} className="img-fluid" />
								{appstore.getLanguageTrans("Delete")}</span>
							</p>
						}
						</div>
					</div>
					<div className="col-12 overview">
						<p className="overviewHeader">{appstore.getLanguageTrans("Last 24h Overview")}</p>
						<div className="row">
							<p className="col-4">
							<font size="2">{website.invitations}</font>
								<br/>
								<span>{appstore.getLanguageTrans("invitations")}</span>
							</p>
							<p className="col-4">
							<font size="2">{website.chats}</font>
								<br/>
								<span>{appstore.getLanguageTrans("chats")}</span>
							</p>
							<p className="col-4">
								<font size="2" style={{textTransform: "none"}}>{website.totalChatTime}</font>
								<br/>
								<span>{appstore.getLanguageTrans("TOTAL Chat TIME")}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
})
export default WebsiteComponent;
