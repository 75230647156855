import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../../sharedFiles/navigation-store';
import ImageCropComponent from '../../../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const IconBuilderPreviewComponent = observer(class IconBuilderPreviewComponent extends Component {

	constructor(props) {
		super(props);
		this.scrollValue = 0;
		this.top = 0;
		this.offsetPrev = undefined;
		this.fileInputRef = React.createRef();

	}

	componentDidMount() {
		this.getHeight();
	}


	chooseTheme(color, event) {
		let self = this;
		this.colors.map(function (theme, themeIndex) {
			if (String(theme).toLowerCase() === String(color.hex).toLowerCase()) {
				self.props.widgetStore.iconDetails.themeChoosen = self.props.widgetStore.widgetThemes[themeIndex];
			}
		})
	}

	getHeight() {
		let elHeight = document.getElementsByClassName('rcs-inner-container')[0].scrollHeight;
		 
		//document.getElementsByClassName('rcs-custom-scrollbar').setHeight(elHeight+"px");
	}

	getScroll(event) {
		document.getElementsByClassName('rcs-custom-scroll-handle')[0].style.height = "16px";
		// if(this.offsetPrev === undefined) {
		// 		this.offsetPrev = document.getElementsByClassName('rcs-custom-scroll-handle')[0].offsetTop;
		// 		console.log(this.offsetPrev);
		// }
		if (this.offsetPrev < document.getElementsByClassName('rcs-custom-scroll-handle')[0].offsetTop) {
			this.top = 115;


		}
		else if (this.offsetPrev > document.getElementsByClassName('rcs-custom-scroll-handle')[0].offsetTop) {
			this.top = 6;
			//document.getElementsByClassName('rcs-custom-scroll-handle')[0].style.paddingTop = this.top+"px";
			//this.offsetPrev = undefined;
		}
		this.offsetPrev = document.getElementsByClassName('rcs-custom-scroll-handle')[0].offsetTop;

		document.getElementsByClassName('rcs-inner-handle')[0].style.marginTop = this.top + "px";

	}

	componentWillUnmount() {
	}

	//--  Images --//
	verifyFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0]
			// console.log(currentFile);
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				this.props.widgetStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("The uploaded file is too large. Max size: 10 Mb");
				this.props.widgetStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.props.widgetStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("An internal server error occurred.");
				this.props.widgetStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}
	onSelectOnlineFile = e => {
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				this.props.widgetStore.selectedonlinefile = e.target.files[0];
			}
		}
	};
	onSelectofflineFile = e => {
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				this.props.widgetStore.selectedofflinefile = e.target.files[0];
			}
		}
	};
	// clearselction(id){ 
	// 	alert();
	// 	this.props.widgetStore.icons[id+1].updateimageURLs=""
	// }
	updateSelectOnlineFile(e, id,index,icon_uid,appstore,mode) {  
		const files = e.target.files 
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) { 
				
				// this.props.widgetStore.icons[index].updateimageURLs=e.target.files[0];
				const formData = new FormData()
				formData.set('id', '100'); 
				 	this.setState({ uploading: true })
					formData.append("file", e.target.files[0])	
					formData.append("title", mode)	
					formData.append("icon_uid", icon_uid)				 
				let cont=0;let match=0;
				this.props.widgetStore.updatecustomimg(index,appstore,formData);
				// for(let i in this.props.widgetStore.icons){ 
				// 	// cont=cont+1;   
				// 	if((id) == this.props.widgetStore.icons[i].id){
				// 		match=1; 
				// 	  this.props.widgetStore.icons[i].updateimageURLs=e.target.files[0]; 
				// 	}
				// 	if(match==1)
				// 	break;

				//   }  
			}
		} 
	} 
	setcustomicons = (icon,icon2) =>{ 
		this.props.widgetStore.customselected.offlineimage=icon.image !="" ? icon.image:icon.updateimageURLs!="" ? icon.updateimageURLs: "";
		this.props.widgetStore.customselected.onlineimage=icon2.image !="" ? icon2.image:icon2.updateimageURLs!="" ? icon2.updateimageURLs: "";
 
	}

	render() {

		var self = this;
		const { appstore,
			widgetStore } = this.props;

		return (
			<React.Fragment>
				<div id="iconSection" className="iconWrapper" >
					<div className="topScroll scroll_btn" onClick={() => { widgetStore.iconScroll = document.getElementsByClassName("rcs-custom-scroll-handle")[0].offsetTop - 15 }}>
						{/* <img src={imageURLs.iconTopArrow} className="img-fluid" /> */}
						<img src={ "#3ba8c5" == appstore.selectedColor.color ? imageURLs.toparrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.toparrow_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.toparrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.toparrow_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.toparrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.toparrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.toparrow_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.toparrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.toparrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.toparrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.toparrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.toparrow_skyblue : imageURLs.toparrow_steelblue}  className="img-fluid" />
					</div>
					<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true} scrollTo={widgetStore.iconScroll} minScrollHandleHeight={16} onScroll={(event) => this.getScroll(event)}>
						{/* uploadind files display */}
						{(this.props.widgetStore.selectedonlinefile != "" || this.props.widgetStore.selectedofflinefile != "") &&
							<div className="row iconRow">
								<div className="col" style={{ height: "auto" }}>
									{this.props.widgetStore.selectedonlinefile != "" &&
										<div className="cannedImages" style={{ backgroundImage: "url(" + URL.createObjectURL(this.props.widgetStore.selectedonlinefile) + ")", width: "115px", height: "100px" }}  >
											<div className="img_delete" onClick={() => {this.props.widgetStore.selectedonlinefile = "";}} >
												<img src={imageURLs.deleteIcon} />
											</div>
										</div>
									}
								</div>
								<div className="col" style={{ height: "auto" }}>
									{this.props.widgetStore.selectedofflinefile != "" &&
										<div className="cannedImages" style={{ backgroundImage: "url(" + URL.createObjectURL(this.props.widgetStore.selectedofflinefile) + ")", width: "115px", height: "100px" }}  >
											<div className="img_delete" onClick={() => (this.props.widgetStore.selectedofflinefile = "")}  >
												<img src={imageURLs.deleteIcon} />
											</div>
										</div>
									}
								</div>
							</div>
						}
						{
							widgetStore.icons.map(function (icon, iconIndex) { 
								if (iconIndex === 0 || iconIndex % 2 === 0) {
									if(widgetStore.icons[iconIndex].image != "" || widgetStore.icons[iconIndex+1].image != ""){
										
									if (iconIndex <= 55) {
										return (
											<div className={"row iconRow" + (widgetStore.selectedIcon && widgetStore.selectedIcon.id === icon.id ? ' selected' : '')} key={iconIndex} onClick={() => {widgetStore.selectedIcon = icon}}>
												<div className="col" style={{ height: "auto" }} >
													<img src={widgetStore.icons[iconIndex].image} className="img-fluid" />
												</div>
												<div className={"col" + (!widgetStore.icons[iconIndex + 1] ? ' no-image' : '')} style={{ height: "auto" }} >
													{
														widgetStore.icons[iconIndex + 1] &&
														<img src={widgetStore.icons[iconIndex + 1].image} className="img-fluid" />
													}
												</div>
											</div>

										)
									}
									else {
										return (
											<div className={"row iconRow" + (widgetStore.selectedIcon && widgetStore.selectedIcon.id === icon.id ? ' selected' : '')} key={iconIndex} onClick={() => {widgetStore.selectedIcon = icon;self.setcustomicons(icon,widgetStore.icons[iconIndex+1])}}>
												{widgetStore.icons[iconIndex].image != "" &&
													<div className="col" style={{ height: "auto" }}>
														<div className="cannedImages" style={{ backgroundImage: "url(" + widgetStore.icons[iconIndex].image + ")", width: "115px", height: "100px", borderRadius: "0px" }}  >
															<div className="img_delete" onClick={() => {widgetStore.deletecustomiconconform(iconIndex,widgetStore.icons[iconIndex].icon_uid,"online",appstore)}}>
																<img src={imageURLs.deleteIcon} />
															</div>
														</div>
													</div>
												}
												{widgetStore.icons[iconIndex].image == "" &&
													<div className="col" style={{ height: "auto" }}>
														<div className="uploadBtn">
															<Button variant="light" className="cursor-pointer" style={{ width: "95%" }} >
																<p><img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.upload_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.upload_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.upload_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.upload_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.upload_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.upload_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.upload_voilet : 
										"#039be6" == appstore.selectedColor.color ? imageURLs.upload_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.upload_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.upload_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.upload_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.upload_skyblue : imageURLs.upload_steelblue} className="img-fluid" />{appstore.getLanguageTrans("Offline icon")}</p>
																<input ref={self.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={(e) => self.updateSelectOnlineFile(e, widgetStore.icons[iconIndex].id,iconIndex,widgetStore.icons[iconIndex].icon_uid,appstore,"online")} />
															</Button>
														</div>
													</div> 
												}
												{/* { widgetStore.icons[iconIndex].updateimageURLs != "" && 
												 
														<div className="col" style={{ height: "auto" }}>
															<div className="cannedImages" style={{ backgroundImage: "url(" + URL.createObjectURL(widgetStore.icons[iconIndex].updateimageURLs) + ")", width: "115px", height: "100px", borderRadius: "0px" }}  >
																<div className="img_delete" onClick={() => widgetStore.deletecustomiconconform(iconIndex,widgetStore.icons[iconIndex].icon_uid,"online",appstore)} >
																{iconIndex}<img src={imageURLs.deleteIcon} />
																</div>
															</div>
														</div>
												} */}
												{widgetStore.icons[iconIndex+1].image != "" &&
												<div className={"col" + (!widgetStore.icons[iconIndex + 1] ? ' no-image' : '')} style={{ height: "auto" }} >
													{widgetStore.icons[iconIndex + 1] &&
														<div className="cannedImages" style={{ backgroundImage: "url(" + widgetStore.icons[iconIndex + 1].image + ")", width: "115px", height: "100px", borderRadius: "0px" }}  >
															<div className="img_delete" onClick={() => {widgetStore.deletecustomiconconform(iconIndex+1,widgetStore.icons[iconIndex+1].icon_uid,"offline",appstore);}} >
															 	<img src={imageURLs.deleteIcon} />
															</div>
														</div>
													}
												</div>
												}
												{widgetStore.icons[iconIndex+1].image == "" &&
													<div className="col" style={{ height: "auto" }}>
														<div className="uploadBtn">
															<Button variant="light" className="cursor-pointer" style={{ width: "95%" }} >
																<p><img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.upload_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.upload_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.upload_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.upload_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.upload_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.upload_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.upload_voilet : 
										"#039be6" == appstore.selectedColor.color ? imageURLs.upload_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.upload_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.upload_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.upload_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.upload_skyblue : imageURLs.upload_steelblue} className="img-fluid" />{appstore.getLanguageTrans("Online icon")}</p>
																<input ref={self.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={(e) => self.updateSelectOnlineFile(e, widgetStore.icons[iconIndex + 1].id,iconIndex+1,widgetStore.icons[iconIndex + 1].icon_uid,appstore,"offline")} />
															</Button>
														</div>
													</div> 
												}
												{/* {widgetStore.icons[iconIndex+1].updateimageURLs != "" &&
														<div className="col" style={{ height: "auto" }}>
															<div className="cannedImages" style={{ backgroundImage: "url(" + URL.createObjectURL(widgetStore.icons[iconIndex+1].updateimageURLs) + ")", width: "115px", height: "100px", borderRadius: "0px" }}  >
																<div className="img_delete" onClick={() => widgetStore.deletecustomiconconform(iconIndex+1,widgetStore.icons[iconIndex+1].icon_uid,"offline",appstore)}>
																{iconIndex+1}<img src={imageURLs.deleteIcon} />
																</div>
															</div>
														</div>
												} */}
											</div>
										)
									}
								}
								}
							})
						}
					</CustomScroll>
					<div className="bottomScroll scroll_btn" onClick={() => { widgetStore.iconScroll = document.getElementsByClassName("rcs-custom-scroll-handle")[0].offsetTop + widgetStore.iconScroll + 15 }}>
						{/* <img src={imageURLs.iconBottomArrow} className="img-fluid" /> */}
						<img src={ "#3ba8c5" == appstore.selectedColor.color ? imageURLs.bottomarrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.bottomarrow_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.bottomarrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.bottomarrow_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.bottomarrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.bottomarrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.bottomarrow_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.bottomarrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.bottomarrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.bottomarrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.bottomarrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.bottomarrow_skyblue : imageURLs.bottomarrow_steelblue}  className="img-fluid" />
					</div>
				</div>
				{/* Upload your icon */}
				<br />
				<div className="row">
					<div className="col-6">
						<div className="uploadBtn">
							<Button variant="light" className="cursor-pointer" style={{ width: "95%" }} >
								<p><img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.upload_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.upload_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.upload_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.upload_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.upload_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.upload_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.upload_voilet : 
										"#039be6" == appstore.selectedColor.color ? imageURLs.upload_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.upload_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.upload_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.upload_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.upload_skyblue : imageURLs.upload_steelblue} className="img-fluid" />{appstore.getLanguageTrans("Offline icon")}</p>
								<input ref={this.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={this.onSelectOnlineFile} />
								{/* <input type = "file"/> */}
							</Button>
						</div>
					</div>
					<div className="col-6">
						<div className="uploadBtn">
							<Button variant="light" className="cursor-pointer" style={{ width: "95%", float: "left" }}>
								<p><img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.upload_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.upload_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.upload_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.upload_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.upload_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.upload_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.upload_voilet : 
										"#039be6" == appstore.selectedColor.color ? imageURLs.upload_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.upload_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.upload_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.upload_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.upload_skyblue : imageURLs.upload_steelblue} className="img-fluid" />{appstore.getLanguageTrans("Online icon")}</p>
								<input ref={this.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={this.onSelectofflineFile} />
							</Button>
						</div>
					</div>
				</div>
				{widgetStore.imagewarning &&
					<ImageWarningPopUp show={widgetStore.imagewarning}
						store={widgetStore}
						cancel_btn="OK"
						closePopup={() => widgetStore.imagewarning = false}
						appStore={appstore} />
				}
			</React.Fragment>
		)
	}
})
export default IconBuilderPreviewComponent;
