import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button,Card} from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import ResponseComponent from './components/ResponseComponent';
import ScriptAddNewComponent from './components/ScriptAddNew';
import ChatPreviewComponent from './components/ChatPreviewComponent';
import ConfirmationComponent from '../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import WarningComponent from '../../../sharedComponents/confirmationPopup/WarningComponent';

import ScriptStore from './ScriptStore';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const ScriptComponent = observer(class ScriptComponent extends Component {

	constructor (props) {
        super(props);
        this.scriptStore = new ScriptStore(this.props.appstore);
		this.defaultSectionClass = "col-12 cursor-pointer panelHeading text-uppercase dropdown-toggle text-left";
		this.state = {
			websiteUid:this.props.appstore.selectedWebisiteUid,
			search:""}
    }
	componentDidMount(){
		this.props.appstore.menuChanged = window.location.pathname; 
		this.scriptStore.isPageLoaded = false;
		this.scriptStore.getScriptitem();
		//this.scriptStore.valangsettings();
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
    }
	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {  
			//   this.scriptStore.getScriptitem();
			if(!this.props.appstore.componentDidUpdate){
				//this.scriptStore.valangsettings(); 
				this.scriptStore.isPageLoaded = false;
				this.scriptStore.getScriptitem();
				this.props.appstore.componentDidUpdate=true;
			} 
			this.scriptStore.previewChatData= [];
			  this.scriptStore.selectedDataToEdit = undefined;
			  this.scriptStore.addResponseLink = false;
			  this.scriptStore.editing = false;
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});   
		}
	  }

	  updatescriptSearch(event){
		//console.log(event.target.value);
          this.setState({search:event.target.value.substr(0,20)});

	}

	render(){

		let self = this;
		const {appstore} = this.props;
		let searchFilter = this.state.search;
		if(!this.scriptStore.isPageLoaded){
			return (<div><div className="adminloader">	 
			<GridLoader 
						css={override}
						size={20}
						marging={2} 
						color={this.props.appstore.selectedColor.color} 
						/>		 
	</div> </div>)
		}
		return (
			<div className="innercontent_wrapper w-100 vascriptWrapper">
				<div className="header_title row align-items-center m-0">
						<img src={imageURLs.scriptIcon} alt="faq" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("Script")}</h4>
				</div>					
				<div className="row">
					<div className="col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form>
								<Form.Group as={Row} controlId="formGroupLanguage">
								    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Select Language")}</Form.Label>
								    <Col sm="8" lg="9" className="customDrpDwn">
								    	<DropDownComponent dropDownOptions={this.scriptStore.lanDropDownOptions} 
								    					   store={this.scriptStore}
								    					   callBack={(selectedOption) => this.scriptStore.setLanguage(selectedOption)}
								    					   selectedOption = {this.scriptStore.selectedLanguage}/>
								    </Col>
								  </Form.Group>
							</Form>
							
								<Form.Group as={Row} controlId="formGroupLanguage">
								    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Quick Search")}</Form.Label>
								    <Col sm="8" lg="9" className="searchText">
										<Form.Control type="text"
													  placeholder={appstore.getLanguageTrans("Enter Search Term")}
													  onChange={(e) => this.updatescriptSearch(e)} />
								    </Col>
								  </Form.Group>
						
							<div key={appstore.selectedWebisiteUid} className="row m-0" id="script_section">
								<div id="panelGroup" className="panelWrapper col-12 col-lg-8 p-0">
									<div className="col-12">
										<div className="row panelHeader">
											<div className="col-2 col-sm-1">
												<p>{appstore.getLanguageTrans("No")}</p>
											</div>
											<div className="col-10 col-sm-11">
												<p className="text-left"  >{appstore.getLanguageTrans("Question")}</p>
											</div>
										</div>
									</div>
									<div className="scrollWrapper col-12">
										<div className="scriptPanel row">
											<div className={this.scriptStore.openPanel === 0 ? this.defaultSectionClass : this.defaultSectionClass+" collapsed"} data-toggle="collapse" 
												 data-target="#basicActions" aria-expanded={this.scriptStore.openPanel === 0 ? true : false} aria-controls="basicActions" 
												 onClick = {() => {this.scriptStore.openPanel = this.scriptStore.openPanel === 0 ? -1 : 0; this.scriptStore.scrollToAmt = 0;this.scriptStore.clearpreviechat()}}>
													<p className="col-1"></p><p className="col-11">{appstore.getLanguageTrans("Basic Actions")}</p>
											</div>
											{this.scriptStore.responseData.basicActions &&											
												<ResponseComponent chatTypes = {this.scriptStore.responseData.basicActions.chatTypes}
																	scriptStore = {this.scriptStore}
																	sectionId = "basicActions"
																	defaultOpen = {this.scriptStore.openPanel === 0 ? true : false}
																	chatId = {1} 
																	appstore={appstore}
																	serchoption={searchFilter}
																	/>											
											}
										</div>
										{this.scriptStore.responseData.customResponse &&
											<div className="scriptPanel row">
											<div className={this.scriptStore.openPanel === 1 ? this.defaultSectionClass : this.defaultSectionClass+" collapsed"} data-toggle="collapse" 
												data-target="#customResponses" aria-expanded={this.scriptStore.openPanel === 1 ? true : false} aria-controls="customResponses" 
												onClick = {() => {this.scriptStore.openPanel = this.scriptStore.openPanel === 1 ? -1 : 1; this.scriptStore.scrollToAmt = 0;this.scriptStore.clearpreviechat()}} 
												>
													<p className="col-1"></p><p className="col-11">{appstore.getLanguageTrans("custom responses")}</p>
											</div>
												
											<ResponseComponent chatTypes = {this.scriptStore.responseData.customResponse.chatTypes}
																	scriptStore = {this.scriptStore}
																	sectionId = "customResponses"
																	defaultOpen = {this.scriptStore.openPanel === 1 ? true : false}
																	chatId = {2}
																	appstore={appstore}
																	serchoption={searchFilter}
																	/>
											
										</div>

										}
										{this.scriptStore.responseData.relatedLinks &&
											<div className="scriptPanel row">
												<div className={this.scriptStore.openPanel === 2 ? this.defaultSectionClass : this.defaultSectionClass+" collapsed"} data-toggle="collapse" 
													data-target="#relatedLinks" aria-expanded={this.scriptStore.openPanel === 2 ? true : false} aria-controls="relatedLinks" 
													onClick = {() => {this.scriptStore.openPanel = this.scriptStore.openPanel === 2 ? -1 : 2; this.scriptStore.scrollToAmt = 0;this.scriptStore.clearpreviechat()}}>
														<p className="col-1"></p><p className="col-11">{appstore.getLanguageTrans("related links")}</p>
												</div>
												<ResponseComponent chatTypes = {this.scriptStore.responseData.relatedLinks.chatTypes}
																	scriptStore = {this.scriptStore}
																	sectionId = "relatedLinks"
																	defaultOpen = {this.scriptStore.openPanel === 2 ? true : false}
																	chatId = {3}
																	appstore={appstore}
																	serchoption={searchFilter}
																	/>	
											</div>
										}
									</div>

									<Button className="custom_btn float-left addBtn" onClick={() => this.scriptStore.addResponse()}>
									{appstore.getLanguageTrans("ADD NEW")}
									</Button>
								</div>
								<div key={appstore.selectedWebisiteUid} className="preview col-12 col-lg-4 d-none d-lg-block">
									<ChatPreviewComponent previewChatData = {this.scriptStore.previewChatData}
														  scriptStore = {this.scriptStore}
														  appstore={appstore}
														  responseData = {this.scriptStore.responseData} />
								</div>
							</div>
						</div>
						{
							(this.scriptStore.selectedDataToEdit || this.scriptStore.selectedLinkToEdit || this.scriptStore.addResponseLink) &&
								<ScriptAddNewComponent scriptStore = {this.scriptStore}
													   selectedDataToEdit = { this.scriptStore.selectedDataToEdit }
													   selectedLinkToEdit = { this.scriptStore.selectedLinkToEdit }
													   addResponseLink = { this.scriptStore.addResponseLink }
													   scriptType = {this.scriptStore.scriptType}
													   responseData = {this.scriptStore.responseData}
													   appstore = {appstore} />
						}
						{
							this.scriptStore.confirm && 
							<ConfirmationComponent  show={this.scriptStore.confirm}
													store={this.scriptStore}
													title="Delete Rule"
													body_html="<p>Are you sure you want to delete the selected rule?</p>"
													cancel_btn="Cancel"
													save_btn="Confirm"
													callBack={() => this.scriptStore.confirmDeleteRule()}
													closePopup={() => this.scriptStore.confirm = false}
													appStore = {appstore}  />
						}
						{ this.scriptStore.errormodel &&
							<WarningComponent show={this.scriptStore.errormodel}
											store={this.scriptStore}  
											cancel_btn="OK"  
											closePopup={() => this.scriptStore.errormodel = false}
											appStore = {appstore} />
						}
					</div>
				</div>
			</div>
		)
	}
})
export default ScriptComponent;
