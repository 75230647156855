import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'; 

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';

import CheckBoxComponent from '../../../../../../sharedComponents/checkBox/checkBox'; 

const SheduleTimeComponent = observer(class SheduleTimeComponent extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }
    handleSchedulecheckbox = (day, index) => {
		let since = document.getElementById("time1" + index); //remove error class while user enter time
		let till = document.getElementById("time2" + index);

		day.selected = !day.selected;

		//if disabled then remove error and set to 00:00
		if (!day.selected) {
			since.value = "00:00";
			till.value = "00:00";
			since.classList.remove("has-error");
			till.classList.remove("has-error");

		}
    }
    checkForEnteredNumer(event) {
		let val;

		var key = window.event ? event.keyCode : event.which;
		 
			var charCode = (event.which) ? event.which : event.keyCode; 
			// if (charCode != 46 && charCode > 31 
			// 	&& (charCode < 48 || charCode > 57) && charCode!=61){
			// 	val = false;
			// }
            if (charCode != 46 && charCode > 31 
				&& ((charCode < 48 || (charCode<96 && charCode>57) ) || charCode > 105) && charCode!=61 || event.shiftKey){
				val = false;
			}
			else {
				val = true;
			}
			if (!val) {
				event.preventDefault();
			}
		 
    }
    handleTimeOnBlur = (event, id = null) => {
		let name = event.target.name;

		if (event.target.value.length != 5) { 
				let timeclass = document.getElementById(name + id); //remove error class while user enter time
				let pos = timeclass.getAttribute("class").indexOf("has-error");

				if (pos == -1)
					timeclass.className += " has-error";
			 
		}
	}
    formatTime(event, id = null) {

		let name = event.target.name;
		let timeclass = document.getElementById(name + id); //remove error class while user enter time
		let removed = name == "auto_logoff" ? null : timeclass.classList.remove("has-error");
		let secondTime = name == "time1" ? document.getElementById("time2" + id) : document.getElementById("time1" + id);
 
		let time = event.target.value;
		if (event.target.value.length == 3 && !event.target.value.includes(":")) {
			time = event.target.value.slice(0, 2) + ":" + event.target.value.slice(2, 3);
		} 

		return time;
    }
    getsmsSchedule = () => {
         let textset= this.props.store.setCustomCallScheduletext();
 
         let weekDays = this.props.store.weekDays;
        let keys = { text: "xc", type: "custom", schedule: {} }
        let schedule = {}

        for (let i = 0; i < weekDays.length; i++) {
            let list = {}
            list["enabled"] = weekDays[i].selected;
            list["since"] = weekDays[i].startTime;
            list["till"] = weekDays[i].EndTime;
            if (weekDays[i].selected) {
                schedule[weekDays[i].value] = weekDays[i].startTime + "-" + weekDays[i].EndTime;
            }

        }
        keys.schedule = schedule;
        this.props.store.weekDaysschedule = keys;
        for (let i in this.props.store.toolBarPreviewButtonSettings) {
            if (this.props.store.toolBarPreviewButtonSettings[i].label == "Call") {
                this.props.store.toolBarPreviewButtonSettings[i].customText = "We are available " + textset;
                break;
            }
        }
    }
    clearschedule =() => { 
        this.props.store.weekDays=this.props.store.clearweekDays;
        this.props.store.weekDaysschedule=this.props.store.clearweekDaysschedule;
    }

    render() {
        var self=this;
        const { appStore,
            store,save_btn,cancel_btn,show } = this.props;
        return (
            <Modal backdrop='static' className={appStore ? appStore.selectedColor.value : ''} show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => {this.clearschedule();this.props.closePopup()}} >
                <Modal.Header closeButton>
                    <Modal.Title>{appStore.getLanguageTrans("Schedule")}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width:"460px"}}> 
                    <Form.Group as={Row} className="schedule_zone">
										 	<Col sm="8" lg="9" >
                    {
                        store.weekDays.map(function (day, dayIndex) {
                            return (
                                <div className="row day_wrapper" key={dayIndex}>
                                    <Col className="custom_radioBtn">
                                        <CheckBoxComponent radioValue={day.selected}
                                            callBack={() => self.handleSchedulecheckbox(day, dayIndex)} />
                                    </Col>

                                    <p className="day_label">{day.label}</p>
                                    <Form.Control type="text" name="time1" placeholder="00:00" value={day.startTime}
                                        maxLength="5"
                                        id={"time1" + dayIndex}
                                        //className={self.profileStore.timeNotValid && 'has-error'}
                                        onKeyDown={(e) => { self.checkForEnteredNumer(e) }}
                                        onChange={(e) => day.startTime = self.formatTime(e, dayIndex)}
                                        onBlur={(e) => self.handleTimeOnBlur(e, dayIndex)}
                                        disabled={!day.selected} />
                                    <Form.Control id={"time2" + dayIndex} type="text" name="time2" placeholder="00:00" value={day.EndTime}
                                        maxLength="5"
                                        //className={self.profileStore.timeNotValid && 'has-error'}
                                        onKeyDown={(e) => { self.checkForEnteredNumer(e) }}
                                        onChange={(e) => day.EndTime = self.formatTime(e, dayIndex)}
                                        onBlur={(e) => self.handleTimeOnBlur(e, dayIndex)}
                                        disabled={!day.selected} />
                                </div>
                            )
                        })
                    }
                  </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn_cancel" variant="secondary" onClick={() => {this.clearschedule();this.props.closePopup()}} >{appStore.getLanguageTrans(cancel_btn)}</Button>
                    <Button className="btn_cancel" variant="primary" onClick={() => {self.getsmsSchedule();this.props.closePopup()}} >{appStore.getLanguageTrans(save_btn)}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
});
export default SheduleTimeComponent;