import { decorate, observable, action } from 'mobx';
import { data } from '../../assets/JSON/headerData';
import axios from 'axios';
import $ from 'jquery';

class HeaderStore {

  constructor() {
    this.mappedData = data;
    this.notificationData = [];
    this.notificationCount = 0;
    this.initialrenderCount = 0;
    this.logout = false;
    this.showWelcomeCount = 1;
    this.headerDropDownData = [
      { "label": 'Online', "status": 1 },
      { "label": 'Offline', "status": 0 },
      { "label": 'Log out', "status": -1 }

    ];
    this.helpDropDownData = [
      { "label": 'Video Overview', "status": 1 },
      { "label": 'How to implement', "status": 2 },
      { "label": 'Help guides', "status": 3 },
      { "label": 'Chat with us', "status": 4 }
    ];

    this.helpTourStep = ["This tutorial will show how to configure a live chat widget for your website.",
      "Implementation options of the chat widget on your website are located at Admin -> Setup -> Widgets",
      "Here are some examples of ways to implement the chat on your website",
      "Now let’s create a new toolbar for your website. Click on the “Toolbar” button on the bar",
      "You can create different toolbars for different purposes or sections on your website. Enter a name for this toolbar.",
      "Use default settings for now. You will be able to change them later without the need to replace the code.",
      "Here you can change the position of your toolbar. Try to change it and see how it looks on preview.",
      "Here you can customize the look and feel of your toolbar to match to your website.",
      "Here you can manage the buttons on your toolbar. Let’s add a Chat button.",
      "Check the “show” checkbox and see how the button is added on preview.",
      "Here you can manage the chat invitations to catch the visitor’s attention. Select the “Custom image” option.",
      "Click on “Choose from library” and select the best image for your website.",
      "Choose image.",
      "Here is the preview on your toolbar.",
      "Seems that all setup is done now. Click on the “Save & get code” button.",
      "Copy this piece of code and insert it to your website’s code before the closing </body> tag.",
      "Let’s close all the windows now and continue on the “Operator” tab.",
      "Here you can chat with your website visitors in the left window and monitor your website traffic in the right window.",
      "You can always play this and other tutorials by clicking the “Help” button"
    ];

    this.stepNumber = 0;
    this.playVideo = false;
    this.userStatus = this.mappedData.status;
    this.notification_popup = false;
    this.help_popup = true;
    this.show_welcome = false;
    this.help_tour = false;
    this.stepChange = this.stepLabel();
    this.isdisabled = false;
    this.privacyPolicy = false;
    this.helpTourGuide = false;
    this.countstep = 0;
    this.helptourstart = false;

    this.tourState = {
      steps: [

        {
          target: '.addNewWebsite_tour',
          content: 'Click here to add a website',
          disableBeacon: true,
          title: "HelpOnClik Guide",
          placement: "right",
          stepNumber: 1,
        },

      ],
      stepIndex: 0,
      overlaydisplay: false,
      isHomePage: true,
    };
    this.belliconclicked = false;
    this.accountexpires = "";
  }

  getDate_Format = (dd, mm, yyyy) => {
    var today;
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    today = dd + '-' + mm + '-' + yyyy;
    var myDate = today.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return newDate;
  }

  isAccountDisabled = (appstore) => {
    //alert(new Date().getDate());
    var formated_today = this.getDate_Format(new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear());

    if (appstore.userInfo.hasOwnProperty('accountsmodel')) {
      if (appstore.userInfo.accountsmodel != null) {
        // appstore.ownerEmail
        this.bodyMsg = appstore.isOwner ? "<p>Oops! Your account has expired. Please renew your subscription to activate your live chat account.</p>" :
          "Oops! Your account has expired. Please log in with the owner's email <b>" + appstore.userInfo.usersmodel.ownerEmail + "</b> to renew the subscription. ";
        appstore.userInfo.accountsmodel.disabled == 1 ? this.isdisabled = true : this.isdisabled = false;
        if (appstore.userInfo.accountsmodel.display_buy_now == 1) {
          var op_expires = "";
          op_expires = "" + new Date(appstore.userInfo.accountsmodel.accountExp * 1000);
          var formated_expires = this.getDate_Format(new Date(op_expires.substring(4, 15)).getDate(), new Date(op_expires.substring(4, 15)).getMonth() + 1, new Date(op_expires.substring(4, 15)).getFullYear());
          var expire_msg = "";

          if ((new Date(formated_expires).getTime() - new Date(formated_today).getTime()) / (1000 * 60 * 60 * 24) < 1 && appstore.userInfo.accountsmodel.disabled == 1) {
            expire_msg = "Your trail period has expired";
          } else if(appstore.userInfo.accountsmodel.product_license_id!=149){
            expire_msg = "Your trail period will expire in " + (new Date(formated_expires).getTime() - new Date(formated_today).getTime()) / (1000 * 60 * 60 * 24) + " days";
          }
          this.accountexpires = expire_msg;
        }
      }
    }
    else
      this.isdisabled = false;
  }

  closeAccountDisabled = (appstore) => {
    this.confirm = false;
    appstore.isloggedout = true;
    appstore.logout();
  }

  showPrivacyPolicy = (appstore) => {
    if (appstore.userInfo.hasOwnProperty('usersmodel')) {
      if (appstore.userInfo.usersmodel != null)
        appstore.userInfo.usersmodel.consent_status != 1 ? this.privacyPolicy = true : this.privacyPolicy = false;
    }
    else
      this.privacyPolicy = false;

  }

  openUpgrade(appstore) {

    // if (GUI.Tray)
    //     trayOpenLink(Core.Account.params.upgrade_link);
    // else
    window.open(appstore.userInfo.accountsmodel.upgrade_link, '_blank');
  }

  renewSubcription(appstore) {
    if (appstore.isOwner)
      window.open(appstore.userInfo.accountsmodel.upgrade_link + "&r=home", '_blank');

    else
      appstore.logout();
  }


  setDropDownValue(itemStatus, appstore) {
    let user = appstore.userInfo.usersmodel;    
    const userstatus=user.user_live_status;
    user.user_live_status = itemStatus == 1 ? "online" : "offline";

    user.website_uid = appstore.selectedWebisiteUid;
    appstore.loggedUserOnline = itemStatus == 1 ? true : false;
    axios({
      method: 'POST',
      url: appstore.url + 'setonlineoffline.json',
      headers: { 'content-type': 'application/json' },
      data: user,
    }).then(result => {
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  } 
      let x;
      let index = 0;
      for (x in result.data) {
        result.data[x].admin = result.data[x].user_type_id == 3 ? true : false;
        result.data[x].label = result.data[x].nick_name;
        result.data[x].value = index;
        result.data[x].isPasswordChanged = false;
        if(result.data[x].user_uid === user.user_uid) {
          appstore.userInfo.usersmodel.userlivestatus = result.data[x].user_live_status;
          console.log(appstore.userInfo.usersmodel.userlivestatus);
          this.userStatus = result.data[x].user_live_status == "online" ? 1:0;
          appstore.userlivestatus=result.data[x].user_live_status;
          appstore.loggedUserOnline = result.data[x].user_live_status == "online" ? true : false;
        }
        index++;
      }
      appstore.operators = result.data;
      

      for (let i = 0; i < appstore.userInfo.alloperators.length; i++) {
        if (appstore.userInfo.alloperators[i].user_uid === user.user_uid) {
          appstore.userInfo.alloperators[i] = user;
          break;
        }
      }
 
      appstore.global_errorcount=0;
    }).catch((error) => {
      user.user_live_status=userstatus;
      appstore.userlivestatus=userstatus;
      this.userStatus = userstatus==="online" ? 1:0;
      appstore.loggedUserOnline = userstatus==="online" ? true : false;
      // appstore.logout();
      console.log(error);
    console.log("error count :"+appstore.global_errorcount);
    appstore.global_errorcount=appstore.global_errorcount+1;
    if(appstore.global_errorcount>20){
    // appstore.logout();
    }
    });

    //send online,offline status to all admins
    const currentTime = appstore.getcurrentUtcTime();
    const liveStatus = itemStatus == 1 ? "online" : "offline"

    let opMsg = {
      id: '', system_msg: true, type: "", role: "operator", message: "", creationTime: currentTime,
      sourceStation: "", chatStatus: liveStatus
    }

    //send status to Admin
    for (let i = 0; i < appstore.userInfo.alloperators.length; i++) {
      if (appstore.userInfo.alloperators[i].user_type_id === 3) {
        //send to admin only , 3 is a for admin type
        opMsg.targetStation = '/topic/' + appstore.userInfo.alloperators[i].user_uid;
        opMsg.operatorUid = user.user_uid;

        appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
      }
    }

    //send status to all visitor
    for (let visitor of appstore.trafficVisitorStatus) {
      opMsg.targetStation = '/topic/' + visitor.sourceStation;
      opMsg.operatorUid = user.user_uid;

      appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
    }

    //update online time of opeartor
    if (liveStatus === "online")
      appstore.clientRef.sendMessage("/app/onOpconnect", JSON.stringify({ "operatorUid": user.user_uid, "account_id": user.account_id }));
    else if (liveStatus === "offline") {
      appstore.clientRef.sendMessage("/app/onOpdisconnect", JSON.stringify({ "operatorUid": user.user_uid, "account_id": user.account_id }));
    }
  }

  goToPageFromWelcome(appstore) {
    // appstore.navigate('/admin/setup/widgets');        
    appstore.navigate('/home');
    setTimeout(() => {
      this.tourState = {
        steps: [{
          target: '.addNewWebsite_tour',
          content: 'Click here to add a website',
          disableBeacon: true,
          title: "HelpOnClik Guide",
          placement: "auto",
          stepNumber: 1,
          offset: -10,
        },],
        stepIndex: 0,
        overlaydisplay: false,
        isHomePage: true,
      };

      this.helpTourGuide = true;
      appstore.tourcurrent_step = 0;
      appstore.helpTourStarted = true;
    }, 500);

  }

  starttour(getStatus, appstore) {
    if (getStatus == 1)
      this.playVideo = true;

    if (getStatus == 2) {
      this.helptourstart = true;
    }

    if (getStatus == 3) {
      window.open('https://www.helponclick.com/live-chat-software/help/', '_blank');
    }

    if (getStatus == 4) {
      var ophelpurl=appstore.assistUrl+"ophelp?c=5fd96e8a-fef2-4c5d-a4f3-f23075628fa6&&name="+appstore.userInfo.usersmodel.nick_name+"&&email="+appstore.userInfo.usersmodel.email;
      window.open(ophelpurl,"_blank");
    
    
       //  var string;
      // $.get(appstore.assistUrlhelp+"/assist/help.html", function (data) { // check your main.html path 
      //   string = data; // make string from the data                
      //   var winHtml = data.replaceAll("visitor", appstore.userInfo.usersmodel.nick_name);
      //       winHtml = winHtml.replaceAll("exapmle@abc.com", appstore.userInfo.usersmodel.email);
      //   const winUrl = URL.createObjectURL(
      //     new Blob([winHtml], { type: "text/html" })
      //   );
      //   const win = window.open(
      //     winUrl,
      //     "_blank",
      //     "Helponclick",
      //     `width=800,height=400,screenX=200,screenY=200`
      //   );

      // }, "html");
      
 
      // const winHtml = "<html><head><title>HelpOnClick Live Chat</title><link href='" + appstore.assistUrl + "assist/icon_style.css'></head><body><script type='text/javascript'> var LiveChatCustom ={ 'name': '" + appstore.userInfo.usersmodel.nick_name + "','email': '" + appstore.userInfo.usersmodel.email + "' }</script>  <!-- HelpOnClick --> <script type='text/javascript'>(function() {  var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true; po.src = '" + appstore.assistUrl + "/assist?c=690373fa-082e-4df1-90b7-a1f7d6e66bca';   var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();   </script><!-- /HelpOnClick -->  " + appstore.userInfo.usersmodel.nick_name + " </body></html>";

      // const winUrl = URL.createObjectURL(
      //   new Blob([winHtml], { type: "text/html" })
      // );

      // const win = window.open(
      //   winUrl,
      //   "win",
      //   `width=800,height=400,screenX=200,screenY=200`
      // );

      //var myWindow = window.open(appstore.assistUrl + "assist/new.html", "newWindow", "width=500,height=700");
      // var  asas=appstore.assistUrl+"assist/new.html";
      // console.log(asas);
      // var newWindow = window.open('', '', 'height=726,width=768');
      //newWindow.document.write = appstore.assistUrl+"assist/new.html";

      //var printWindow =  window.open('', '', 'height=726,width=768');
      //printWindow.document.write("<!DOCTYPE html><html lang='en'><head>  <title>Bootstrap Example</title>  <meta charset='utf-8'>  <meta name='viewport' content='width=device-width, initial-scale=1'>  <link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css'>  <script src='https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'></script>  <script src='https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js'></script>  <script src='https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js'></script></head><body><div class='jumbotron text-center'>  <h1>My First Bootstrap Page</h1>  <p>Resize this responsive page to see the effect!</p> </div>  <div class='container'>  <div class='row'>    <div class='col-sm-4'>      <h3>Column 1</h3>      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>    </div>    <div class='col-sm-4'>      <h3>Column 2</h3>      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>    </div>    <div class='col-sm-4'>      <h3>Column 3</h3>              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</p>      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p>    </div>  </div></div></body></html>");
      //printWindow.document.write("<html><head><title>HelpOnClick Live Chat</title><link href='"+appstore.assistUrl+"assist/icon_style.css'></head><body><script type='text/javascript'>var LiveChatCustom ={ 'name': '"+appstore.userInfo.usersmodel.nick_name+"','email': '"+appstore.userInfo.usersmodel.email+"' }</script> "+appstore.userInfo.usersmodel.nick_name+" </body></html>");
      // printWindow.document.write("<script type='text/javascript'>");
      // printWindow.document.write("$( document ).ready(function() {	");
      // printWindow.document.write("alert('hello')");
      // printWindow.document.write("});");
      // printWindow.document.write("</script>");

      // printWindow.document.write("<link href='"+appstore.assistUrl+"assist/icon_style.css'>");
      // printWindow.document.write("<script href='"+appstore.assistUrl+"assist/jquery-3-4-1.min.js'></script>");
      // printWindow.document.write("<script type='text/javascript'>");
      // printWindow.document.write("var LiveChatCustom ={ ");
      // printWindow.document.write('"name": "'+appstore.userInfo.usersmodel.nick_name+'",');
      // printWindow.document.write('"email": "'+appstore.userInfo.usersmodel.email+'" }</script>'); 
      // printWindow.document.write("<!-- HelpOnClick --> <script type='text/javascript'>(function() {"); 
      // printWindow.document.write(" var po = document.createElement('script'); po.type = 'text/javascript'; "); 
      // printWindow.document.write(" po.async = true; po.src = '"+appstore.assistUrl+"/assist?c=690373fa-082e-4df1-90b7-a1f7d6e66bca'; "); 
      // printWindow.document.write(" var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();"); 
      // printWindow.document.write("</script><!-- /HelpOnClick -->"); 
      // printWindow.document.write("</body></html>"); 


    }
  }
  goToPage(getStatus, appstore) {
    if (getStatus == 1)
      this.playVideo = true;

    if (getStatus == 2) {
      //this.help_tour= true;
      this.helpTourGuide = true;
      this.countstep = 0;
      appstore.helpTourStarted = true;
      appstore.navigate('/admin/setup/widgets');

    }

    if (getStatus == 3)
      window.open('https://www.helponclick.com/live-chat-software/help/', '_blank');
  }

  stepLabel(step) {
    if (step == undefined)
      return this.helpTourStep[0];

    return this.helpTourStep[step];

  }

  selectcheck() {
    this.checkSelected = !this.checkSelected;
    //console.log( this.checkSelected);
  }


  getNotification = (appstore, isClicked = false) => {

    axios({
      method: 'GET',
      url: appstore.url + 'getNotifications.json',
      headers: { 'content-type': 'application/json' },
      data: { "consent_status": 1 },
    }).then(result => {
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
      if (this.notificationCount != result.data.length) {
        this.notificationCount = result.data.length;
      }


      if (result.data.length != 0 && result.data != null) {
        this.notificationData = result.data;
      } else {
        this.notificationData = [];
      }

      this.notification_popup = true;
      // 	 if(isClicked){
      // 		this.headerStore.notification_popup=true;
      // 		//let tooltip = document.querySelectorAll(`[data-tip][data-for="notificationClickme"]`)[0]
      //        // ReactTooltip.show(tooltip)
      //    }

      appstore.global_errorcount=0;
    }).catch((error) => {
      // if (error.response) {
      //   console.log(error);
      //   appstore.logout();
      // }
      this.notification_popup = true;
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })

  }

  dismissNotification = (appstore, data) => {
    let toDismiss = data;
    axios({
      method: 'POST',
      url: appstore.url + 'updateNotification.json',
      headers: { 'content-type': 'application/json' },
      data: toDismiss,
    }).then(result => {
      //console.log(result)
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
      if (result.data != null) {
        this.notificationData = result.data;
        this.notificationCount = result.data.length;
      } else {

      }
      appstore.global_errorcount=0;
    }).catch((error) => {
      // if (error.response) {
      //   console.log(error);
      //   appstore.logout();
      // }
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })

  }

  confirmPolicyAction = (appstore) => {
    axios({
      method: 'POST',
      url: appstore.url + 'privatepolicy.json',
      headers: { 'content-type': 'application/json' },
      data: { "consent_status": 1 },
    }).then(result => {

      if (result.data == 1) {
        appstore.userInfo.usersmodel.consent_status = 1;
      }
      //have to check in other scenrio
      this.privacyPolicy = false;
      appstore.global_errorcount=0;
    }).catch((error) => {
      // if (error.response) {
      //   console.log(error);
      //   appstore.logout();
      // }
      this.privacyPolicy = false;
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })
  }


  closeTour(appstore) {
    this.stepNumber = 0;
    appstore.helpTourStarted = false;
    //  appstore.addNewSite = true;
    //  appstore.navigate('/admin/setup/widgets');
    localStorage.clear();
  }

  startTour(appstore, stepIndex) {
    let step = stepIndex;

    switch (step) {
      case 3:
        appstore.selectedtourStep = "step2"
        //appstore.navigate('/admin/setup/widgets/add');
        break;
      case 7:
        appstore.selectedtourStep = "step7"
        appstore.navigate('/admin/setup/widgets/add');
        break;

    }

  }

}

decorate(HeaderStore, {
  mappedData: observable,
  headerDropDownData: observable,
  userStatus: observable,
  notification_popup: observable,
  help_popup: observable,
  helpDropDownData: observable,
  help_tour: observable,
  helpTourStep: observable,
  stepChange: observable,
  stepNumber: observable,
  playVideo: observable,
  notificationData: observable,
  notificationCount: observable,
  logout: observable,
  isdisabled: observable,
  privacyPolicy: observable,
  bodyMsg: observable,
  initialrenderCount: observable,
  show_welcome: observable,
  showWelcomeCount: observable,
  helpTourGuide: observable,
  countstep: observable,
  helptourstart: observable,
  tourState: observable,
  belliconclicked: observable,
  accountexpires: observable
})
export default HeaderStore;