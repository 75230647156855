import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';


const WidgetCarouselComponent = observer(class WidgetCarouselComponent extends Component {

	constructor (props) {
        super(props);
        this.widgetWidth = 0;
        this.menuOpen = undefined;
        this.state = {
        	marginRight: 0,
        	marginLeft: 0,
        	widgetNoToFit: 1,
        	ulWidth:'auto',
        	carouselWidth: 'auto',
        	widgetsToMove: 0,
        	widgetsMoved: 0
        }

        this.setWidths = this.setWidths.bind(this);
        this.setIntialState = this.setIntialState.bind(this);
    }

	componentDidMount(){
		this.menuOpen = this.props.appstore.menuActive;
		//setInterval(() => {
		this.setWidths();
		//},0
	}

	setWidths() {
		let windowWidth = window.innerWidth;
			let sidebarWidth = document.getElementById('sidebar').offsetWidth;
			this.widgetWidth = document.getElementsByClassName('carousel-cell')[0].offsetWidth + 24;
			if(windowWidth<=990) {
				sidebarWidth = 0;
			}
			let areaAvailableForWidgets = windowWidth - sidebarWidth - 30;
			this.setState({
				carouselWidth: areaAvailableForWidgets,
				ulWidth: this.widgetWidth*6 + (Math.floor(areaAvailableForWidgets/this.widgetWidth) == 6 ? 20 : 80),
				widgetNoToFit: Math.floor(areaAvailableForWidgets/this.widgetWidth)
			});
		

	}

	setIntialState(){
		this.setState ({
        	marginRight: 0,
        	marginLeft: 0,
        	widgetsToMove: 0,
        	widgetsMoved: 0
        });
	}

	showOtherCarousels() {
		let widgetsToMove = (6 - this.state.widgetNoToFit) < this.state.widgetNoToFit ? (6 - this.state.widgetNoToFit) : this.state.widgetNoToFit;
		let marginToMove = -(this.state.marginLeft) + widgetsToMove * this.widgetWidth;
		this.setState({marginLeft: -marginToMove});

		this.setState({
			widgetsToMove: widgetsToMove,
			widgetsMoved: this.state.widgetsMoved+widgetsToMove,
			widgetNoToFit: this.state.widgetNoToFit + ((6 - this.state.widgetNoToFit) < this.state.widgetNoToFit ? (6 - this.state.widgetNoToFit) : this.state.widgetNoToFit)
		})
	}

	showPrevCarousels() {
		let marginToMove = -(this.state.marginLeft) - (this.state.widgetsToMove * this.widgetWidth);
		this.setState({
			widgetsMoved: this.state.widgetsMoved-this.state.widgetsToMove,
			widgetNoToFit: this.state.widgetNoToFit - this.state.widgetsToMove,
			marginLeft: -marginToMove});
	}
	componentWillReceiveProps() {
	}

	componentWillUnmount() {
		
    }

	render(){

		var self = this;

		const { appstore,
				widgetStore } = this.props;
		if(appstore.menuActive != this.menuOpen && this.menuOpen !== undefined) {
			this.menuOpen = appstore.menuActive;
			setTimeout(function(){
				if(window.innerWidth>990) {
					self.setIntialState();
					self.setWidths();
				}
			},0);
		}
		return (
			 <div className="carousel" style={{width: this.state.carouselWidth}}> 
			 	{
			 		this.state.marginLeft < 0 &&
			        	<img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.left_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.left_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.left_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.left_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.left_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.left_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.left_voilet : 
						"#039be6" == this.props.appstore.selectedColor.color ? imageURLs.left_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.left_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.left_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.left_grey : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.left_skyblue : imageURLs.left_steelblue} alt="faq" className="img-fluid carouselBtn carousel-left" onClick={() => this.showPrevCarousels()}/>
			 	}
			 	<ul className={widgetStore.selectedWidget !== 0 ? 'widgetMode' : ''} style={{width: this.state.ulWidth,
			 																				marginLeft: this.state.marginLeft}}>
			 		 <li style={appstore.helpTourStarted ? {pointerEvents:"none"}:{}} className={"carousel-cell addwidget_tour" + (widgetStore.selectedWidget === 1 ? ' selected' : '') + (this.state.widgetNoToFit < 1 ? ' d-none' : '') + (this.state.widgetsMoved == 1 ? ' opacity_0' : '')} onClick={()=> widgetStore.selectedWidget = 1}>
			              <h4>{appstore.getLanguageTrans("Widget")}</h4>
			              <div className="img-wrapper">
			              	<img src={imageURLs.widgetItem} className="img-fluid"/>
			              </div>
			         </li> 
		            <li style={appstore.helpTourStarted ? {pointerEvents:"none",opacity:0.3}:{}} className={"carousel-cell" + (widgetStore.selectedWidget === 2 ? ' selected' : '') + (this.state.widgetNoToFit < 2 ? ' d-none' : '') + (this.state.widgetsMoved == 2 ? ' opacity_0' : '')} onClick={()=> widgetStore.selectedWidget = 2}>
		               <h4>{appstore.getLanguageTrans("Toolbar")}</h4>
			              <div className="img-wrapper">
			              	<img src={imageURLs.toolBarItem} className="img-fluid"/>
			              </div>
		            </li> 
		            
		            <li style={appstore.helpTourStarted ? {pointerEvents:"none",opacity:0.3}:{}} className={"carousel-cell" + (widgetStore.selectedWidget === 3 ? ' selected' : '') + (this.state.widgetNoToFit < 3 ? ' d-none' : '') + (this.state.widgetsMoved == 3 ? ' opacity_0' : '')} onClick={()=> widgetStore.selectedWidget = 3}>
		               <h4>{appstore.getLanguageTrans("Icon")}</h4>
			              <div className="img-wrapper">
			              	<img src={imageURLs.iconItem} className="img-fluid"/>
			              </div>
		            </li> 
		            {/* <li className={"carousel-cell" + (widgetStore.selectedWidget === 4 ? ' selected' : '') + (this.state.widgetNoToFit < 4 ? ' d-none' : '') + (this.state.widgetsMoved == 4 ? ' opacity_0' : '')} onClick={()=> widgetStore.selectedWidget = 4}>
		               <h4>{appstore.getLanguageTrans("Direct Link")}</h4>
			              <div className="img-wrapper">
			              	<img src={imageURLs.directLinkItem} className="img-fluid"/>
			              </div>
		            </li>  */}
		            <li style={appstore.helpTourStarted ? {pointerEvents:"none",opacity:0.3}:{}} className={"carousel-cell" + (widgetStore.selectedWidget === 5 ? ' selected' : '') + (this.state.widgetNoToFit < 4 ? ' d-none' : '') + (this.state.widgetsMoved == 5 ? ' opacity_0' : '')} onClick={()=> widgetStore.selectedWidget = 5}>
		               <h4>{appstore.getLanguageTrans("Widget+Icon")}</h4>
			              <div className="img-wrapper">
			              	<img src={imageURLs.widgetIconItem} className="img-fluid"/>
			              </div>
		            </li> 
		            {/* <li className={"carousel-cell" + (widgetStore.selectedWidget === 6 ? ' selected' : '') + (this.state.widgetNoToFit < 6 ? ' d-none' : '')} onClick={()=> widgetStore.selectedWidget = 6}>
		              <h4>{appstore.getLanguageTrans("Button")}</h4>
			              <div className="img-wrapper">
			              	<img src={imageURLs.buttonItem} className="img-fluid"/>
			              </div>
		            </li>  */}
		            
			 	</ul>
			 	{/* {
			 		this.state.widgetNoToFit < 6 &&
			        	<img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.right_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.right_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.right_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.right_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.right_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.right_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.right_voilet : 
									"#039be6" == this.props.appstore.selectedColor.color ? imageURLs.right_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.right_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.right_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.right_grey : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.right_skyblue : imageURLs.right_steelblue} alt="faq" className="img-fluid carouselBtn" onClick={() => this.showOtherCarousels()}/>
			 	} */}
			      </div>
		)
	}
})
export default WidgetCarouselComponent;
