import React from 'react';
 
export class ComponentToPrint extends React.PureComponent {
    constructor(props) {
        super(props); 
      }
    render() {
        const{text} = this.props;
      return ( 
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
      );
    }
  }