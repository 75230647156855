import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink } from "react-csv";


const exportOperatorCSV = observer(class exportOperatorCSV extends Component {

    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    invexport(data) { 
        let exportdata = []
        let chat_Date=0;
        let chat_count=0;
        let chatmissed_count=0;
        if(data == null || !data){
          return exportdata;
        }
        for (let j = 0; j < data.length; j++) {
    
          if (data[j].type === "chats") {
            if (chat_Date=== data[j].quarter_date) {
              chat_count= Number(chat_count) + Number(data[j].cnt);
             exportdata[exportdata.length-1].Chatcount = chat_count;
           }
           else{
            data[j].Date = data[j].quarter_date;
            data[j].Chatcount = data[j].cnt;
            data[j].chatmissed = 0;
            data[j].chat_count = data[j].cnt;
            data[j].chat_Date = data[j].quarter_date;
            exportdata.push(data[j])
           }
          }
          if (data[j].type === "chat_missed") {
            if (chat_Date=== data[j].quarter_date) {
              chatmissed_count= Number(chatmissed_count) + Number(data[j].time);
             exportdata[exportdata.length-1].chatmissed = chatmissed_count;
           }
           else{
            data[j].Date = data[j].quarter_date;
            data[j].chatmissed = data[j].cnt;
            data[j].Chatcount = 0;
            data[j].chat_Date = data[j].quarter_date;
            data[j].chatmissed_missed = data[j].cnt
            exportdata.push(data[j])
          }
        }
        }
        return exportdata;
      }

    render() {

        let headers = [
            { label: "Date", key: "Date" },
            { label: "Chats", key: "Chatcount" },
            // { label: "Missed Chats", key: "chatmissed" },

        ];

        let exportdata = this.invexport(this.props.data)
        const {appStore}=this.props;
        return (

            <CSVLink data={exportdata} headers={headers} target="_blank" filename={"Number_Of_Chats_"+this.props.dateselected+".csv"} >
                {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
        );
    }


});

export default exportOperatorCSV;
