import { decorate, observable, extendObservable } from 'mobx';
import axios from 'axios';


class SettingsStore {


  constructor(appStore) {
    this.appStore = appStore;
    this.lanDropDownOptions = this.appStore.languages;
    this.selectedLanguage = this.lanDropDownOptions[0];
    this.agentDetails = {
      "name": '',
      "leadLabelNameBegin": '',
      "leadLabelNameEnd": '',
      "leadLabelEmailBegin": '',
      "leadLabelEmailEnd": '',
      "leadLabelPhoneBegin": '',
      "leadLabelPhoneEnd": '',
      "file": "",
      "image": "",
      "selectedfile": "",
    };
    this.deleteImg = false;
    //this.getsettingsdata();
    this.dropDownOptions = [
      { value: 0, label: 'Disabled' },
      { value: 1, label: 'When web + mobile operators are offline' },
      { value: 2, label: 'When web operators are offline' },
      { value: 3, label: 'Always use Virtual Agent' }
    ];
    this.mode = this.dropDownOptions[0];
    this.prechat = false;
    this.realchat = false;
    this.defaultSectionClass = "customname";
    this.customname = false;
    this.customnameBegin = false;
    this.customlNameEnd = false;
    this.customEmailBegin = false;
    this.customEmailEnd = false;
    this.customPhoneBegin = false;
    this.customPhoneEnd = false;
    this.errormodel = false;
    this.userProfilePic = '';
    this.editor = null;
    this.scaleValue = 1;
    this.selectedImage = '';
    this.openCropper = false;
    this.fileUploadErrors = '';
    this.cropimage = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    }
    this.imageCropModel = false;
    this.imageRef = null;
    this.imagewarning = false;
    this.warning_image_name = "";
    this.defaultSettings = {
      "name": 'Judy',
      "leadLabelNameBegin": 'Please enter your name',
      "leadLabelNameEnd": 'Thank you!',
      "leadLabelEmailBegin": 'Please enter your email address',
      "leadLabelEmailEnd": 'Thank you!',
      "leadLabelPhoneBegin": 'Please enter your phone number',
      "leadLabelPhoneEnd": 'Thank you!',
    };
  }

  setLanguage(selectedOption) {
    this.selectedLanguage = selectedOption;
    // this.getsettingsdata();
    this.valangsettings();
  }
  cancelsettings() {
    this.deleteImg = false;
    this.cropimage = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    }
    this.getsettingsdata();
  }

  savesettings(formData) {

    formData.append("name", this.agentDetails.name);
    formData.append("leadLabelNameBegin", this.agentDetails.leadLabelNameBegin);
    formData.append("leadLabelNameEnd", this.agentDetails.leadLabelNameEnd);
    formData.append("leadLabelEmailBegin", this.agentDetails.leadLabelEmailBegin);
    formData.append("leadLabelEmailEnd", this.agentDetails.leadLabelEmailEnd);
    formData.append("leadLabelPhoneBegin", this.agentDetails.leadLabelPhoneBegin);
    formData.append("leadLabelPhoneEnd", this.agentDetails.leadLabelPhoneEnd);
    var pre = 1;
    var human = 1;
    if (!this.prechat) { pre = 0; } if (!this.realchat) { human = 0 }
    formData.append("skipPrechatFields", pre);
    formData.append("showChatWithHuman", human);
    formData.append("enableMode", this.mode.value);
    formData.append("image", this.agentDetails.image);
    formData.append("websiteUid", this.appStore.selectedWebisiteUid);
    formData.append("lang", this.selectedLanguage.value);
    formData.append("deleteimage", this.deleteImg);


    axios({
      method: 'POST',
      url: this.appStore.url + 'vaAgentSettingsSave.json',
      headers: { 'content-type': 'multipart/form-data','charset':'utf-8' },
      data: formData
    }).then(result => {
      //console.log(result.data);  
      this.deleteImg=false;
      this.cropimage = {
        src: null,
        crop: {
          unit: '%',
          width: 50,
          aspect: 1 / 1,
        },
        croppedImageUrl: null,
      }
      this.imageCropModel = false;
      this.imageRef = null;
      this.imagewarning = false;
      this.warning_image_name = "";
      if (result.data == 1) {
        this.getsettingsdata();
      } else {
        this.errormodel = true;
      }
      this.appStore.global_errorcount=0;
    }).catch((error) => {
        // this.appStore.logout(); 
        this.deleteImg=false;
        this.cropimage = {
          src: null,
          crop: {
            unit: '%',
            width: 50,
            aspect: 1 / 1,
          },
          croppedImageUrl: null,
        }
        this.imageCropModel = false;
        this.imageRef = null;
        this.imagewarning = false;
        this.warning_image_name = "";
        console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
  }
  valangsettings() {
    this.pagenotLoaded = true;
    let defualtsettings = this.defaultSettings;
    axios({
      method: 'POST',
      url: this.appStore.url + 'va_languagessetting.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: { "lang": this.selectedLanguage.value }
    }).then(result => {
      let languagedata = JSON.parse(result.data.language);
      // console.log(result.data.language); 
      let customlanguagedata = "";
      if (result.data.data !== "") { 
         customlanguagedata = JSON.parse(result.data.data); 
        }

      let name = defualtsettings.name;
      // console.log(name);
      let namebegin = defualtsettings.leadLabelNameBegin;
      let nameend = defualtsettings.leadLabelNameEnd;
      let emailbegin = defualtsettings.leadLabelEmailBegin;
      let emailend = defualtsettings.leadLabelEmailEnd;
      let phonebegin = defualtsettings.leadLabelPhoneBegin;
      let phoneend = defualtsettings.leadLabelPhoneEnd; 
      try{
      if (this.selectedLanguage.value === 'en') { 
        let customename = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.name.toLowerCase())];
        if (customename != undefined && customename[1] !== null) {
          if (customename[1] == undefined || customename[1].length != 0) {
            name = customename[1];
          }
        }
        let customenamebegin = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelNameBegin.toLowerCase())];
        if (customenamebegin != undefined && customenamebegin[1] !== null) {
          if (customenamebegin[1] == undefined || customenamebegin[1].length != 0) {
            namebegin = customenamebegin[1]; 
          }
        }
        let customenameend = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelNameEnd.toLowerCase())];
        if (customenameend != undefined && customenameend[1] !== null) {
          if (customenameend[1] == undefined || customenameend[1].length != 0) {
            nameend = customenameend[1];
          }
        }
        let customeemailbegin = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelEmailBegin.toLowerCase())];
        if (customeemailbegin != undefined && customeemailbegin[1] !== null) {
          if (customeemailbegin[1] == undefined || customeemailbegin[1].length != 0) {
            emailbegin = customeemailbegin[1];
          }
        }
        let customeemailend = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelEmailEnd.toLowerCase())];
        if (customeemailend != undefined && customeemailend[1] !== null) {
          if (customeemailend[1] == undefined || customeemailend[1].length != 0) {
            emailend = customeemailend[1];
          }
        }
        let customephonebegin = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelPhoneBegin.toLowerCase())];
        if (customephonebegin != undefined && customephonebegin[1] !== null) {
          if (customephonebegin[1] == undefined || customephonebegin[1].length != 0) {
            phonebegin = customephonebegin[1];
          }
        }
        let customephonend = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelPhoneEnd.toLowerCase())];
        if (customephonend != undefined && customephonend[1] !== null) {
          if (customephonend[1] == undefined || customephonend[1].length != 0) {
            phoneend = customephonend[1];
          }
        }
      }
      else {  
          let langname = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === defualtsettings.name.toLowerCase())];
          if (langname != undefined && langname[1] !== null) {
            if (langname[1] == undefined || langname[1].length != 0) { 
              if (customlanguagedata != "") {
                let customename = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.name.toLowerCase())];
                if (customename != undefined && customename[1] !== null) {
                  if (customename[1] == undefined || customename[1].length != 0) {
                    name = customename[1];
                  }
                }else{
                  name = langname[1];
                }
              }else{
                name = langname[1];
              }
            }
          }
          let langnamebegin = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelNameBegin.toLowerCase())];
          if (langnamebegin != undefined && langnamebegin[1] !== null) {
            if (langnamebegin[1] == undefined || langnamebegin[1].length != 0) { 
              if (customlanguagedata != "") { 
                let customenamebegin = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelNameBegin.toLowerCase())];
                
                if (customenamebegin != undefined && customenamebegin[1] !== null) {
                  if (customenamebegin[1] == undefined || customenamebegin[1].length != 0) {
                    namebegin = customenamebegin[1]; 
                  }
                }else{
                  namebegin = langnamebegin[1];
                } 
              }else{
                namebegin = langnamebegin[1];
              }

            }
          }
          let langnameend = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelNameEnd.toLowerCase())];
          if (langnameend != undefined && langnameend[1] !== null) {
            if (langnameend[1] == undefined || langnameend[1].length != 0) { 
              if (customlanguagedata != "") {
                let customenameend = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelNameEnd.toLowerCase())];
                if (customenameend != undefined && customenameend[1] !== null) {
                  if (customenameend[1] == undefined || customenameend[1].length != 0) {
                    nameend = customenameend[1]; 
                  }
                }else{
                  nameend = langnameend[1];
                }
              }else{
                nameend = langnameend[1];
              }
            }
          }
          let langemailbegin = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelEmailBegin.toLowerCase())];
          if (langemailbegin != undefined && langemailbegin[1] !== null) {
            if (langemailbegin[1] == undefined || langemailbegin[1].length != 0) { 
              if (customlanguagedata != "") {
                let customeemailbegin = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelEmailBegin.toLowerCase())];
                if (customeemailbegin != undefined && customeemailbegin[1] !== null) {
                  if (customeemailbegin[1] == undefined || customeemailbegin[1].length != 0) {
                    emailbegin = customeemailbegin[1];
                  }
                }else{
                  emailbegin = langemailbegin[1];
                }
              }else{
                emailbegin = langemailbegin[1];
              } 
            }
          }
          let langemailend = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelEmailEnd.toLowerCase())];
          if (langemailend != undefined && langemailend[1] !== null) {
            if (langemailend[1] == undefined || langemailend[1].length != 0) { 
              if (customlanguagedata != "") {
                let customeemailend = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelEmailEnd.toLowerCase())];
                if (customeemailend != undefined && customeemailend[1] !== null) {
                  if (customeemailend[1] == undefined || customeemailend[1].length != 0) {
                    emailend = customeemailend[1];
                  }
                }else{
                  emailend = langemailend[1];
                }
              }else{
                emailend = langemailend[1];
              }  
            }
          }
          let langphonebegin = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelPhoneBegin.toLowerCase())];
          if (langphonebegin != undefined && langphonebegin[1] !== null) {
            if (langphonebegin[1] == undefined || langphonebegin[1].length != 0) { 
              if (customlanguagedata != "") {
                let customephonebegin = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelPhoneBegin.toLowerCase())];
                if (customephonebegin != undefined && customephonebegin[1] !== null) {
                  if (customephonebegin[1] == undefined || customephonebegin[1].length != 0) {
                    phonebegin = customephonebegin[1];
                  }
                }else{
                  phonebegin = langphonebegin[1];
                }
              }else{
                phonebegin = langphonebegin[1];
              }  
            }
          }
          let langphonend = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelPhoneEnd.toLowerCase())];
          if (langphonend != undefined && langphonend[1] !== null) {
            if (langphonend[1] == undefined || langphonend[1].length != 0) { 
              if (customlanguagedata != "") {
                let customephonend = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === defualtsettings.leadLabelPhoneEnd.toLowerCase())];
                if (customephonend != undefined && customephonend[1] !== null) {
                  if (customephonend[1] == undefined || customephonend[1].length != 0) {
                    phoneend = customephonend[1];
                  }
                }else{
                  phoneend = langphonend[1];
                }
              }else{
                phoneend = langphonend[1];
              }
            }
          } 
          // console.log(name);
      }
    }catch(error){

    }
      var svaedata = {
        "name": name,
        "leadLabelNameBegin": namebegin,
        "leadLabelNameEnd": nameend,
        "leadLabelEmailBegin": emailbegin,
        "leadLabelEmailEnd": emailend,
        "leadLabelPhoneBegin": phonebegin,
        "leadLabelPhoneEnd": phoneend,
        "websiteUid": this.appStore.selectedWebisiteUid,
        "lang": this.selectedLanguage.value
      }
      // console.log(basicquestion);
      this.savedefualtsettings(svaedata);
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      // this.appStore.logout();
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });

  }
  savedefualtsettings(data) {
    axios({
      method: 'POST',
      url: this.appStore.url + 'vaAgentdefulatSettings.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: data
    }).then(result => {
      // console.log(result);
      this.getsettingsdata();
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      //console.log(error);
      // this.appStore.logout(); 
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
  }

  getsettingsdata() {
    this.deleteImg = false;
    this.pagenotLoaded = true;
    axios({
      method: 'POST',
      url: this.appStore.url + 'vaAgentSettings.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: { "lang": this.selectedLanguage.value, "websiteUid": this.appStore.selectedWebisiteUid }
    }).then(result => {
      //  console.log(result.data); 
      if(result.data.length==1){
        if(result.data.logoutstatus==1){
          this.appStore.logout();
        }
        }
        
      if (result.data.uid != null) {
        var agentDetailed = {
          "name": result.data.name,
          "leadLabelNameBegin": result.data.leadLabelNameBegin,
          "leadLabelNameEnd": result.data.leadLabelNameEnd,
          "leadLabelEmailBegin": result.data.leadLabelEmailBegin,
          "leadLabelEmailEnd": result.data.leadLabelEmailEnd,
          "leadLabelPhoneBegin": result.data.leadLabelPhoneBegin,
          "leadLabelPhoneEnd": result.data.leadLabelPhoneEnd,
          "image": result.data.websiteSittings.image
        }
        this.agentDetails = agentDetailed;
        var prechating = false;
        var livechat = false
        if (result.data.websiteSittings.skipPrechatFields == 1) {
          prechating = true;
        }
        if (result.data.websiteSittings.showChatWithHuman == 1) {
          livechat = true;
        }
        this.prechat = prechating;
        this.realchat = livechat;
        let self = this;
        let x = self.dropDownOptions.filter(function (option) {
          //filtering defualt English language data first time based on account id 
          if (option.value == result.data.websiteSittings.enableMode) {
            self.mode = option;
          }
        });

      } else {
        this.agentDetails = {
          "name": '',
          "leadLabelNameBegin": '',
          "leadLabelNameEnd": '',
          "leadLabelEmailBegin": '',
          "leadLabelEmailEnd": '',
          "leadLabelPhoneBegin": '',
          "leadLabelPhoneEnd": ''
        };
        this.prechat = false;
        this.realchat = false;
        this.mode = this.dropDownOptions[0];
      }
      this.pagenotLoaded = false;
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      //  this.appStore.logout(); 
      this.pagenotLoaded = false;
      this.agentDetails = {
        "name": '',
        "leadLabelNameBegin": '',
        "leadLabelNameEnd": '',
        "leadLabelEmailBegin": '',
        "leadLabelEmailEnd": '',
        "leadLabelPhoneBegin": '',
        "leadLabelPhoneEnd": ''
      };
      this.prechat = false;
      this.realchat = false;
      this.mode = this.dropDownOptions[0];
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
  }
}

decorate(SettingsStore, {
  selectedLanguage: observable,
  mode: observable,
  state: observable,
  agenteditDetails: observable,
  addata: observable,
  prechat: observable,
  realchat: observable,
  agentDetails: observable,
  customname: observable,
  customnameBegin: observable,
  customlNameEnd: observable,
  customEmailBegin: observable,
  customEmailEnd: observable,
  customPhoneBegin: observable,
  customPhoneEnd: observable,
  pagenotLoaded: observable,
  errormodel: observable,
  userProfilePic: observable,
  editor: observable,
  scaleValue: observable,
  selectedImage: observable,
  openCropper: observable,
  fileUploadErrors: observable,
  cropimage: observable,
  imageCropModel: observable,
  imageRef: observable,
  deleteImg: observable,
  imagewarning: observable,
  warning_image_name: observable,
  defaultSettings: observable

})
export default SettingsStore;