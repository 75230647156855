import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import navigationStore from '../../../sharedFiles/navigation-store';
import FormValidator from '../../../sharedFiles/FormValidator';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import AddWebsiteStore from './addWebsiteStore';
import WarningComponent from '../../../sharedComponents/confirmationPopup/WarningComponent';
// import 'react-image-crop/dist/ReactCrop.css';
import '../../../library/custom-image-crop.css';
import ImageCropComponent from '../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';
import ConfirmationComponent from '../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import axios from 'axios';

import TourGuide from '../../../sharedComponents/TourGuide'; 
import Tour from "react-product-intro"; 

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const AddWebsiteComponent = observer(class AddWebsiteComponent extends Component {

	constructor(props) {
		super(props);
		this.addWebsiteStore = new AddWebsiteStore();
		this.updateProperty = this.updateProperty.bind(this);
		this.fileInputRef = React.createRef();

		//if user has started website tour Guide
			//this.props.appstore.helpTourStarted
            if(this.props.appstore.helpTourStarted ){
				this.props.widgetStore.setwebsiteTourStep(this.props.appstore)
			    this.props.widgetStore.tourGuideStart = true;
			 }
			 else
			 this.props.widgetStore.tourGuideStart = false;

		this.validator = new FormValidator([
			{
				field: 'name',
				method: 'isEmpty',
				validWhen: false,
				message: 'Please enter website name'
			},
			{
				field: 'domainName',
				method: 'isEmpty',
				validWhen: false,
				message: 'Please enter domain name'
			}
		]);

		this.state = {
			validation: this.validator.valid(),
			websiteDetailsClone: "",
			uploading: false,
			images: "",
			actual_file: "",
			// tourStep:2,
		}

		this.submitted = false;

	}

	componentDidMount() {

		this.props.appstore.menuChanged = window.location.pathname; //this added extra for when user click on exits of corousol
		this.props.widgetStore.defaultmenuPath = this.props.widgetStore.defaultmenuPath ? this.props.widgetStore.defaultmenuPath : window.location.pathname;
		if(this.props.appstore.addNewSite){
			//new website add
			
			this.addWebsiteStore.valangsettings(this.props.appstore);
			this.addWebsiteStore.isPageLoaded = true;
		}
		else{
			//edit website
			this.getwidget(this.props.appstore);
			this.setwebsiteData();
			this.addWebsiteStore.valangsettings(this.props.appstore); 
			
		}
		//set this path if user click on edit website. 
		//Edit path contains website_uid due to it wont show changes in website data page when user changes website.
		this.props.widgetStore.defaultmenuPath = "/admin/setup/widgets";
		// setTimeout(() => {
		// 	if(this.props.appstore.helpTourStarted ){
		// 		this.props.widgetStore.tourGuideStart = true;
		// 	 }
		// 	 else
		// 	 this.props.widgetStore.tourGuideStart = false;
		// }, 3000);
	}

	getwidget = async (appstore) => {

		if(appstore.dontLoadWidgets){
			//while editing website, no need to call widgets
			this.addWebsiteStore.isPageLoaded = true;
			appstore.dontLoadWidgets=false;
			return;
		}

		this.addWebsiteStore.isPageLoaded = false;
		let pathArray = this.props.widgetStore.defaultmenuPath.split("/");
		let website_uid;
		//if user edits website, get website_uid
		if (pathArray.length > 4 && pathArray[4].length != 0)
			website_uid = pathArray[pathArray.length - 1];
		//if user clicks on website, get website_uid
		else
			website_uid = appstore.selectedWebisiteUid;

		await axios({
			method: 'POST',
			url: appstore.url + 'getWidgets.json',
			headers: { 'content-type': 'application/json' },
			data: { "website_uid": website_uid },
		}).then(result => {
		//	console.log("getWidgets ****************");
			// console.log(result);
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
			if (result.data.length != 0 && result.data != null) {
				 this.props.widgetStore.setWidget(result.data, appstore);
				 
			} else if (result.data.length == 0) {
				//if data empty
				this.props.widgetStore.websiteWidgetDetails = "";

			} else {
				this.addWebsiteStore.errormodel = true;
			}
			this.addWebsiteStore.isPageLoaded = true;

			
//console.log("--website page this.props.appstore.helpTourStarted "+this.props.appstore.helpTourStarted)			
appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	console.log(error);
			// 	appstore.logout();
			// }
			this.addWebsiteStore.isPageLoaded = true;
			console.log(error);
			console.log("error count :"+appstore.global_errorcount);
			appstore.global_errorcount=appstore.global_errorcount+1;
			if(appstore.global_errorcount>20){
			// appstore.logout();
			}
		})
	}

	setwebsiteData = () => {
		const newWebsite = this.props.widgetStore.getSelectedWebsite(this.props.appstore);
		if (!newWebsite) {
			this.addWebsiteStore.websiteDetails.name = this.props.widgetStore.selectedWebsite.name;
			this.addWebsiteStore.websiteDetails.domainName = this.props.widgetStore.selectedWebsite.domain_name;
			this.addWebsiteStore.websiteDetails.email = this.props.widgetStore.selectedWebsite.email_messages;
			this.addWebsiteStore.websiteDetails.fbId = this.props.widgetStore.selectedWebsite.facebook_link;
			this.addWebsiteStore.websiteDetails.twitterId = this.props.widgetStore.selectedWebsite.twitter_link;
			this.addWebsiteStore.websiteDetails.googleId = this.props.widgetStore.selectedWebsite.googleplus_link;
			this.addWebsiteStore.websiteDetails.website_uid = this.props.widgetStore.selectedWebsite.website_uid;
			this.addWebsiteStore.websiteDetails.account_id = this.props.widgetStore.selectedWebsite.account_id;
			this.addWebsiteStore.websiteDetails.logo = this.props.widgetStore.selectedWebsite.logo;
		}
		//clone object , use when user cancel form
		this.setState({ websiteDetailsClone: Object.assign({}, this.addWebsiteStore.websiteDetails) });

	}

	updateProperty(key, value) {
		this.addWebsiteStore.websiteDetails[key] = value;
	}

	handleChange(event) {
		this.updateProperty(event.target.name, event.target.value);
	}
	handleEventTurechecking(){ 

		const validation = this.validator.validate(this.addWebsiteStore.websiteDetails);
		this.setState({ validation });
		this.submitted = true; 
		return validation.isValid
	}

	handleFormSubmit(event) {
		event.preventDefault();

		const validation = this.validator.validate(this.addWebsiteStore.websiteDetails);
		this.setState({ validation });
		this.submitted = true;
		if (validation.isValid) {
			// check empty string
			let title = this.addWebsiteStore.websiteDetails.name.trim();
			let domain = this.addWebsiteStore.websiteDetails.domainName.trim();
			if (title.length == 0 || domain.length == 0) {
				return;
			}
			const formData = new FormData()
			formData.set('id', '100');
			if (this.addWebsiteStore.websiteDetails.file != '' && this.addWebsiteStore.websiteDetails.file != undefined) {
				// this.settingsStore.agentDetails.file.forEach((file, i) => {
				this.state.images = this.addWebsiteStore.websiteDetails.file;
				this.state.actual_file = this.addWebsiteStore.websiteDetails.selectedfile[0];

				// })
				this.setState({ uploading: true })
				// this.settingsStore.agentDetails.file.forEach((file, i) => {
				formData.append("file", this.state.images)
				formData.append("actualfile", this.state.actual_file)
				formData.append("fileMimeType", this.state.fileMimeType);
				// })
			}
			this.addWebsiteStore.saveWebsiteDetails(this.props.appstore, formData,this.props.widgetStore);
			//clone updated data
			this.setState({ websiteDetailsClone: Object.assign({}, this.addWebsiteStore.websiteDetails) });
		}
	}

	cancelForm = () => {
		this.addWebsiteStore.deleteImg = false;
		this.addWebsiteStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.addWebsiteStore.imageCropModel = false;
		this.addWebsiteStore.websiteDetails.selectedfile = "";
		this.setwebsiteData();
		//this.addWebsiteStore.websiteDetails = this.state.websiteDetailsClone;
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
	}

	//-- Croping Images --//
	verifyFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0]
			// console.log(currentFile);
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				this.addWebsiteStore.warning_image_name = this.props.appstore.getLanguageTrans("Oops, the file is too large. The file size should be less than 10Mb.");
				this.addWebsiteStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.addWebsiteStore.warning_image_name = currentFile.name + ": An internal server error occurred.";
				this.addWebsiteStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}

	onSelectFile = e => {
		this.addWebsiteStore.websiteDetails.selectedfile = e.target.files;
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					// this.setState({ src: reader.result })
					this.addWebsiteStore.cropimage.src = reader.result
				);
				reader.readAsDataURL(e.target.files[0]);
				this.addWebsiteStore.imageCropModel = true;
			}
		}
	};
	onCropComplete = crop => {
		this.addWebsiteStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) { 
		if (this.addWebsiteStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif'; 
			const mimetype=this.state.imagetype == 'image/png' ? 'image/png'
			: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
				: 'image/gif'; 
				this.state.fileMimeType = mimetype; 
			const croppedImageUrl = await this.getCroppedImg(
				this.addWebsiteStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			// this.setState({ croppedImageUrl }); 
			this.addWebsiteStore.cropimage.croppedImageUrl = croppedImageUrl;

		}
	}

	handleClearToDefault = event => {
		this.addWebsiteStore.imageCropModel = false;
		this.addWebsiteStore.websiteDetails.selectedfile = "";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.addWebsiteStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName,mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height; 

		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.addWebsiteStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		this.addWebsiteStore.websiteDetails.file = myNewCroppedFile
		// download file
		// this.props.appstore.downloadBase64File(imageData64, myFilename)
		// this.handleClearToDefault()

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, mimetype);
		});
	}
	selectComponent = () => {
		//this.props.deptStore.deleteImg = true;
		this.addWebsiteStore.deleteImage=true

	} 
	checkwebsitevalidation =(stepcheck) => { 
		if(stepcheck.stepIndex==1){
			//this.handleFormSubmit(event);
			let title = this.addWebsiteStore.websiteDetails.name.trim();
			let domain = this.addWebsiteStore.websiteDetails.domainName.trim();
			if (title.length == 0){
				this.state.validation.name.isInvalid=true;
				//this.props.appstore.tourcurrent_step=1;
				return false;
			}else if(domain.length == 0) { 
				this.state.validation.domainName.isInvalid=true;
				//this.props.appstore.tourcurrent_step=1;
				return false;
				
			}else{
				this.handleFormSubmit();
					return true;
			}
		}else{
			return true;
		} 
	}


	
	render() {
		const { crop, croppedImageUrl, src } = this.addWebsiteStore.cropimage;
		const { appstore,
			widgetStore } = this.props;

		if (!this.addWebsiteStore.isPageLoaded) {
			return (
				<div className="adminloader">
					<GridLoader
						css={override}
						size={20}
						marging={2}
						color={appstore.selectedColor.color}
					/>
				</div>
			)
		}
		if (this.props.widgetStore.iswidgetPageLoaded) {
			return (
				<div className="adminloader">
					<GridLoader
						css={override}
						size={20}
						marging={2}
						color={appstore.selectedColor.color}
					/>
				</div>
			)
		}

		var self = this;

		let validation = this.submitted ?                         // if the form has been submitted at least once
			this.validator.validate(this.addWebsiteStore.websiteDetails) :   // then check validity every time we render
			this.state.validation

		return (
			<div className="websiteContentWrapper"  style={(appstore.tourcurrent_step==1 && appstore.helpTourStarted) ? {background:"gray"}: appstore.helpTourStarted ? {opacity:0.3,pointerEvents:"none"} :{}}>
				{this.addWebsiteStore.saveinProcess &&
					<div className="adminloader">
						<GridLoader
							css={override}
							size={20}
							marging={2}
							color={appstore.selectedColor.color}
						/>
					</div>
				}
				{this.addWebsiteStore.errormodel &&
					<WarningComponent show={this.addWebsiteStore.errormodel}
						store={this.addWebsiteStore}
						cancel_btn="OKAY"
						closePopup={() => this.addWebsiteStore.errormodel = false}
						appStore={appstore} />
				}
				<div className="header_title row align-items-center m-0 website_tour">
					<img src={imageURLs.websitePageIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left cursor-pointer" style={{color:"#000"}} onClick={() => appstore.navigate('/admin')}>{appstore.getLanguageTrans("Websites")}</h4>
					{
						this.addWebsiteStore.websiteName && this.addWebsiteStore.websiteName !== '' &&
						<img src={imageURLs.sectionIcon} alt="faq" className="img-fluid" />
					}

					{
						this.addWebsiteStore.websiteName && this.addWebsiteStore.websiteName !== '' &&
						<h4 className="text-left websiteName" >{this.addWebsiteStore.websiteName}</h4>
					}
					
				</div>

				<div className="row websiteWrapper"> 
				   {
					  widgetStore.tourGuideStart && 
						<React.Fragment>
						<TourGuide 
						store={widgetStore}
						appStore = {appstore}
						tourState = {widgetStore.tourState}
						/>
						
						</React.Fragment>
					// 	<Tour
					// 	active={widgetStore.tourGuideStart}
					// 	step={this.state.tourStep}
					// 	onNext={(step) => step==4 ? (widgetStore.tourGuideStart=false,appstore.navigate('/admin/setup/widgets/add')) : this.setState({tourStep: step})}
					// 	onBack={(step) => this.setState({tourStep: step})}
					// 	onCancel={() => {appstore.helpTourStarted = false;widgetStore.tourGuideStart=false}}
					// 	steps = {appstore.tourstesps}
					//   /> 
					}   

					<div className="settingsForm col-12 w-100 pr-0 ">
						<div className="settingFormWrapper box">
							<div className="websiteFormWrapper row">
								<div className="logoWrapper box col img_box_tour">
									{/* <img src={imageURLs.addImage} className="img-fluid" />
									<p>{appstore.getLanguageTrans("Add logo")}</p>
									<input type="file" /> */} 
									{!this.addWebsiteStore.deleteImg &&
										<React.Fragment>
											{
												!this.addWebsiteStore.cropimage.croppedImageUrl && (this.addWebsiteStore.websiteDetails.logo != '' && this.addWebsiteStore.websiteDetails.logo != undefined) &&
												<div className="img_delete" style={{marginRight:"26px",height:"4px",float:"right"}} onClick={() => this.selectComponent()}><img style={{padding:0,marginTop:"-30px",opacity:0.9,height:"25px",cursor:"pointer",objectFit:"scale-down",zIndex:999,position:"absolute"}} src={imageURLs.deleteIcon} /></div>

											}
											{!this.addWebsiteStore.cropimage.croppedImageUrl && 
												<img src={(this.addWebsiteStore.websiteDetails.logo != '' && this.addWebsiteStore.websiteDetails.logo != undefined) ? this.props.appstore.resourceurl + this.props.appstore.userInfo.accountsmodel.account_uid + "/" + this.addWebsiteStore.websiteDetails.logo : "#3ba8c5" == appstore.selectedColor.color ? imageURLs.add_steelblue2 : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.add_seagreen2 : "#ec407a" == appstore.selectedColor.color ?  imageURLs.add_pink2 : "#6e4c42" == appstore.selectedColor.color ?imageURLs.add_brown2 : "#234e91" == appstore.selectedColor.color ?imageURLs.add_navyblue2 : "#e89712" == appstore.selectedColor.color ?imageURLs.add_yellow2 : "#b968c7" ==appstore.selectedColor.color ?imageURLs.add_voilet2 : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.add_blue2 : "#00887a" == appstore.selectedColor.color ? imageURLs.add_darkgreen2 : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.add_green2 : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.add_grey2 : "#64a9e0"== appstore.selectedColor.color ? imageURLs.add_skyblue2 : imageURLs.mute_steel_blue2}
												style={(this.addWebsiteStore.websiteDetails.logo != '' && this.addWebsiteStore.websiteDetails.logo != undefined)? {width: "100%",borderRadius: "7%",padding: "10px",marginTop: "-31px",opacity:1} : {}}	 alt="" className="img-fluid" />
												 
											}
											{this.addWebsiteStore.cropimage.croppedImageUrl &&
											<React.Fragment>
												<div className="img_delete" style={{marginRight:"26px",height:"4px",float:"right"}} onClick={() => this.handleClearToDefault()}><img style={{padding:0,marginTop:"-30px",opacity:0.9,height:"25px",cursor:"pointer",objectFit:"scale-down",zIndex:999,position:"absolute"}} src={imageURLs.deleteIcon} /></div>
												<img alt="Crop" style={{width: "100%",borderRadius: "7%",padding: "10px",marginTop: "-31px",opacity:1,border: "2px solid "+appstore.selectedColor.color}} className="img-fluid"  src={croppedImageUrl} />
												
											</React.Fragment>
											}
											{!this.addWebsiteStore.cropimage.croppedImageUrl &&
												(this.addWebsiteStore.websiteDetails.logo == '' || this.addWebsiteStore.websiteDetails.logo == undefined) &&
												<p style={{color: appstore.selectedColor.color}}>{appstore.getLanguageTrans("Add logo")}</p>
											}
											<input type="file" ref={this.fileInputRef} multiple={false} accept={acceptedFileTypes} id="file" onChange={this.onSelectFile} />

											
										</React.Fragment>
									}
								</div>
								<form className="col">
									<Form.Group as={Row} controlId="formGroupName">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Title")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={"custom_input_text " + (validation.name.isInvalid && 'has-error')} name="name" placeholder={appstore.getLanguageTrans("Enter Title")}
												value={this.addWebsiteStore.websiteDetails.name}
												onChange={(e) => this.handleChange(e)}
												maxLength="100" />
											<span className="error-block">{validation.name.message}</span>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupDomainName">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Domain Name")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={"newwebsite_tour custom_input_text " + (validation.domainName.isInvalid && 'has-error')} name="domainName" placeholder={appstore.getLanguageTrans("Enter Domain Name")}
												value={this.addWebsiteStore.websiteDetails.domainName}
												onChange={(e) => this.handleChange(e)} />
											<span className="error-block">{validation.domainName.message}</span>
										</Col>
									</Form.Group>
									{/* <Form.Group as={Row} controlId="formGroupemail">
										<Form.Label column lg="3" sm="4" className="forEmail">{appstore.getLanguageTrans("Email to collect left messages and transcripts")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className="custom_input_text" name="email" placeholder="Enter Email"
												value={this.addWebsiteStore.websiteDetails.email}
												onChange={(e) => this.handleChange(e)} />
										</Col>
									</Form.Group> */}
									<Form.Group as={Row} controlId="formGroupFacebookID">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Facebook ID")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className="custom_input_text" name="fbId" placeholder={appstore.getLanguageTrans("Enter ID")}
												value={this.addWebsiteStore.websiteDetails.fbId}
												onChange={(e) => this.handleChange(e)} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formGroupTwitterID">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Twitter ID")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className="custom_input_text" name="twitterId" placeholder={appstore.getLanguageTrans("Enter ID")}
												value={this.addWebsiteStore.websiteDetails.twitterId}
												onChange={(e) => this.handleChange(e)} />
										</Col>
									</Form.Group>

									{/* <Form.Group as={Row} controlId="formGroupGoogleID">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Google+ ID")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className="custom_input_text" name="googleId" placeholder="Enter ID"
												value={this.addWebsiteStore.websiteDetails.googleId}
												onChange={(e) => this.handleChange(e)} />
										</Col>
									</Form.Group> */}

								</form>
							</div>
							<div className="row m-0">
								<div className="col-12 formBtns">
									<div className="pullRight">
										<Button style={appstore.helpTourStarted ? {pointerEvents:"none"}:{}} className="btn_clr" variant="light" onClick={(event) => this.cancelForm()}>
											<p>{appstore.getLanguageTrans("Cancel")}</p>
										</Button>
										<Button style={(appstore.tourcurrent_step==1 && appstore.helpTourStarted) ? {pointerEvents:'auto'} : appstore.helpTourStarted ? {pointerEvents:'none'} :{}} className="btn_save" onClick={(event) => this.handleFormSubmit(event)}>
											<p>{appstore.getLanguageTrans("Save")}</p>
										</Button>
									</div>
								</div>
							</div>
							{this.addWebsiteStore.imagewarning &&
								<ImageWarningPopUp show={this.addWebsiteStore.imagewarning}
									store={this.addWebsiteStore}
									cancel_btn="Close"
									closePopup={() => this.addWebsiteStore.imagewarning = false}
									appStore={appstore} />
							}
							{this.addWebsiteStore.imageCropModel &&
								<ImageCropComponent show={this.addWebsiteStore.imageCropModel}
									store={this.addWebsiteStore}
									cancel_btn="Clear"
									multi="0"
									crop_btn="Save"
									onCropComplete={() => this.onCropComplete(crop)}
									closePopup={() => this.handleClearToDefault()}
									appStore={appstore} />
							}
							{
					this.addWebsiteStore.deleteImage && 
					<ConfirmationComponent show={this.addWebsiteStore.deleteImage}
											store={this.addWebsiteStore}
											title="Delete Image"
											body_html="<p>Are you sure you want to delete the image? The operation cannot be undone.</p>"
											cancel_btn="Cancel"
											save_btn="Delete"
											callBack={() =>{this.addWebsiteStore.deleteImage = false;this.addWebsiteStore.deleteImg=true}}
											closePopup={() => this.addWebsiteStore.deleteImage = false}
											appStore = {appstore}/>
				}
						</div>
					</div>
				</div>

			</div>
		)
	}
})
export default AddWebsiteComponent;
