import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { observer } from 'mobx-react';

import navigationStore from '../sharedFiles/navigation-store';

import ChatComponent from '../components/chats/ChatComponent';
import ArchiveChat from '../components/chats/archives/ArchiveChat';
import ResponseComponent from '../components/chats/response/ResponseComponent';

const ChatsPageLayout = observer(class ChatsPageLayout extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }


  componentWillReceiveProps(nextProps){
  }

  /**
   * @param route
   */
  render() {

    const { appstore } = this.props;

    return (    
      <Router history={navigationStore.history}>
        <Switch>
          <Route exact path="/chats/operator/:id" render={()=><ChatComponent 
                         appstore={appstore} />}/>
          <Route exact path="/chats/archive" render={()=><ArchiveChat 
                        appstore={appstore} />}/>
          <Route exact path="/chats/responses" render={()=><ResponseComponent 
                         appstore={appstore} />}/>
          
        </Switch>
      </Router>
    )
  }
})

export default ChatsPageLayout;
