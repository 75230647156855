import { decorate, observable, extendObservable } from 'mobx';
import axios from 'axios';

class LoginService {
	  

  	constructor(){
  	     
         this.hidden=true;
         this.socket="";
         this.forgot=false;
         this.invalidLogin=false;
         this.failed=false;
         this.loading=false;
         this.formerrors ='';
         this.isValid=false;

        this.user = {
           email: '',
           pass: '',
           lang:'en',
           time_zone_offset:new Date().getTimezoneOffset(),
           platform:'web',
           deviceId: localStorage.getItem("firbasedevicetoken")
        }
        this.forgot_user = {
            email: '',
            pass: '',
            re_pass:'',
         }

        this.forgotloading=false;
        this.forgotstatus="";
        this.forgotsentmail=false;
        this.emailnotregisterd=false;
        this.tokenexpired=false;
        this.newpasssuccess=false;
    }
     
    handleChange(event) {
    this.updateUser(event.target.name, event.target.value);
    }

    updateUser(key, value) {
        this.user[key] = value;      
    }


    handleValidation=() =>{

        let fields = this.user;
        this.isValid=false;
        this.invalidLogin=false;
        this.failed=false;
        this.formerrors='';

        //validate user Email and password
        if(!fields.email){
           this.formerrors = "Email cannot be empty";

               if(!fields.email && !fields.pass)
               {
                   this.formerrors = "Email & Password cannot be empty";
               }
           return false;
        }

        if(typeof fields.email !== "undefined"){
           let lastAtPos = fields.email.lastIndexOf('@');
           let lastDotPos = fields.email.lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields.email.indexOf('@@') == -1 && lastDotPos > 2 && (fields.email.length - lastDotPos) > 2)) {
              this.formerrors = "Email is not valid";
              return false;
            }
       }

       if(!fields.pass){
           this.formerrors = "Password cannot be empty";
           return false;
        }
       
       return true;
   }
    validateforgotemail = (email) => {
        //validate user Email 
        if (!email) {
            this.formerrors = "Email cannot be empty";

            if (!email) {
                this.formerrors = "Email cannot be empty";
            }
            return false;
        }
        if (typeof email !== "undefined") { 
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                this.formerrors = "Email is not valid";
                return false;
            }  
        }  
        return true;
    }
    forgotPass(event,appstore){
          event.preventDefault();

          let fields = this.user;
          this.formerrors = "";
          if(!this.validateforgotemail(fields.email)){ 
            return;
          }else{ 
            this.loading=true;
            axios({
                method: 'POST',
                url: appstore.url+'forgotpass.json',
                headers: { 'content-type': 'application/json' },
                data: this.user,
              }).then(result=> {
                  //console.log(result);
                  this.forgotstatus=result.data==1 ? "The email with instructions are sent. Click hear to " :
                   (result.data==2 || result.data==0)? "Somthing went wrong" : "The email was not registered";
                    this.emailnotregisterd=result.data==3 ? true:false;
                    this.forgotsentmail=true;
                    this.loading=false; 
              }).catch((error) => {
                this.loading=false;
                this.props.appstore.logout();
            });
        }
        
     } 

    login=(event,appstore) => {

        if(this.forgot){ 
            this.forgotPass(event,appstore) 
        }else{
        event.preventDefault();
       
        if(!this.handleValidation())
        {
          this.isValid=true;
          return;
        }
      
        this.showLoader();
        this.invalidLogin=false;
        this.failed=false;
        this.user.deviceId=localStorage.getItem("firbasedevicetoken");
        axios({
            method: 'POST',
            url: appstore.url+'loginuser.json',
            headers: { 'content-type': 'application/json' },
            data: this.user,
          }).then(result=> {
                         if(result.data.usersmodel!=null)
                             {

                                // let opMsg =  {id:'',type:"system_msg", message:"logout operator",time:new Date().toLocaleTimeString(), hostname:'', sourceStation:result.data.usersmodel.user_uid,chatStatus:"logoutOp",
                                // targetStation:'/topic/'+result.data.usersmodel.user_uid}
                                //  appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));    
                               
                                 //store in localstorage and use while page refresh
                                  localStorage.setItem("email",this.user.email);
                                  localStorage.setItem("color",result.data.usersmodel.color);
 
                                appstore.userInfo=result.data;
                                appstore.userlivestatus=appstore.userInfo.usersmodel.user_live_status;
                                appstore.monthly_chat_enable_count = appstore.userInfo.accountsmodel.chatcount;
                                if(appstore.userInfo.accountsmodel.private_label==1){
                                 document.title = appstore.userInfo.accountsmodel.private_label_title;
                                }
                                if(appstore.userInfo.accountplan.chat_routing != 1){
                                    appstore.downgrade();
                                }
                                appstore.setUserStatus_Topics();
                                appstore.localLanguage=JSON.parse(result.data.language);
                                appstore.colorVal=result.data.usersmodel.color;
                                                             
                               
                                appstore.getColor();
                                this.hideLoader();
                                //appstore.userInfo.allwebistes.length=0; 
                                if(appstore.userInfo.allwebistes.length==0 || appstore.userInfo.allwebistes==null) {
                                    if(appstore.isAdmin){
                                        appstore.addNewSite=true;
                                       appstore.navigate('admin');
                                    }else {
                                        appstore.navigate('/traffic'); 
                                    }      
                                    
                                }
                                else if(appstore.userInfo.allwebistes.length > appstore.userInfo.accountplan.max_websites){
                                    if(appstore.isAdmin){
                                       appstore.deletesite=true;
                                       appstore.navigate('admin');
                                    }else {
                                        appstore.deletesite=true;
                                        appstore.navigate('/traffic'); 
                                    }   
                                }
                                else if(appstore.userInfo.alloperators.length > appstore.userInfo.accountplan.max_ops && appstore.userInfo.alldepartments.length > appstore.userInfo.accountplan.max_deps && appstore.userInfo.allwebistes.length <= appstore.userInfo.accountplan.max_websites && appstore.userInfo.accountplan.max_ops !=0 && appstore.userInfo.accountplan.max_deps!=0) {
                                    if(appstore.isAdmin){
                                        
                                       // appstore.navigate('admin/setup/widgets');
                                       appstore.deleteoperator=true;
                                       appstore.navigate('/admin/setup/operators_departments');
                                    }else {
                                        appstore.deleteoperator=true;
                                        appstore.navigate('/traffic'); 
                                    }      
                                }
                                else if(appstore.userInfo.alldepartments.length > appstore.userInfo.accountplan.max_deps && appstore.userInfo.allwebistes.length <= appstore.userInfo.accountplan.max_websites && appstore.userInfo.accountplan.max_deps!=0) {
                                    if(appstore.isAdmin){
                                       // appstore.navigate('admin/setup/widgets');
                                       appstore.deletedept=true;
                                       appstore.navigate('/admin/setup/operators_departments');
                                    }else {
                                        appstore.deletedept=true;
                                        appstore.navigate('/traffic'); 
                                    }      
                                }
                                else if(appstore.userInfo.alloperators.length > appstore.userInfo.accountplan.max_ops && appstore.userInfo.allwebistes.length <= appstore.userInfo.accountplan.max_websites && appstore.userInfo.accountplan.max_ops!=0) {
                                    if(appstore.isAdmin){
                                       // appstore.navigate('admin/setup/widgets');
                                       appstore.deleteopt=true;
                                       appstore.navigate('/admin/setup/operators_departments');
                                    }else {
                                        appstore.deleteopt=true;
                                        appstore.navigate('/traffic'); 
                                    }      
                                }
                                else{
                                 appstore.navigate('/traffic'); 
                                 this.showConsoleMsg();
                                }
                                 
                            }
                        else
                            {   
                                this.hideLoader();
                                appstore.userInfo=result.data.errorMsg;
                                this.invalidLogin=true;
                            } 
                        if(result.data.allassistdata.length != 0 && result.data.allassistdata.length!=null){
                                for(let i in  result.data.allassistdata){
                                    if(JSON.parse(result.data.allassistdata[i].clientConfigJson).toolbar){
                                    var toolbardata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
                                    toolbardata.toolbar.buttons.chat.hideWhenOffline = false;
                                    result.data.allassistdata[i].clientConfigJson = JSON.stringify(toolbardata);
                                   }
                                   if(JSON.parse(result.data.allassistdata[i].clientConfigJson).icon){
                                    var icondata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
                                    icondata.icon.hideWhenOffline = false;
                                    if(JSON.parse(result.data.allassistdata[i].clientConfigJson).widget){
                                       icondata.widget.hideWhenOffline = false;
                                    }
                                    result.data.allassistdata[i].clientConfigJson = JSON.stringify(icondata);
                                   }
                                   if(JSON.parse(result.data.allassistdata[i].clientConfigJson).color && JSON.parse(result.data.allassistdata[i].clientConfigJson).widget && !JSON.parse(result.data.allassistdata[i].clientConfigJson).icon){
                                    var widgetdata = JSON.parse(result.data.allassistdata[i].clientConfigJson);
                                    widgetdata.widget.hideWhenOffline = false;
                                    result.data.allassistdata[i].clientConfigJson = JSON.stringify(widgetdata);
                                   }
                               }
                               appstore.updateclientConfigJson(result.data.allassistdata); 
                            }
  
            }).catch((error) => {
                                if (error.response) {
                                    this.hideLoader(); 
                                    this.failed=true;
                                 }
                            });
        }
    }

   
    showLoader = () => {
     this.loading=true;
  }

   hideLoader = () => {
    this.loading=false;
  }
  showConsoleMsg() {
    const style1 = [
        'color: powderBlue;',
        'text-shadow: 2px 2px purple;', 
        'background: plum', 
        'font-size: 3em',
        'border: 1px solid purple',
        'padding: 20px',
        'font-family: fantasy'
        ];
       
        console.log('%cWelcome To HelpOnClick!', 'background: #222; color: #bada55; font-size:15px; padding:10px');
    //  if( typeof console === 'object' ) {
    //     console.log(
    //         '\n' +
    //         'Hi there, fellow developer! Thanks for visiting.\n' +
    //         'If you’re an aspiring bootstrapper, startup-er,\n' +
    //         'or business owner, make sure you check out this         ("`-’-/").___..--’’"`-._\n' +
    //         'section of the blog for tips and inspiration!            `6_ 6  )   `-.  (     ).`-.__.‘)\n' +
    //         '                                                         (_Y_.)’  ._   )  `._ `. ``-..-’\n' +
    //         'Link: http://www.abeautifulsite.net/topic/business     _..`--’_..-_/  /--’_.’ ,’\n' +
    //         '                                                      (il),-’‘  (li),’  ((!.-‘\n' +
    //         'I’d love to hear what you think!\n' +
    //         '\n' +
    //         '— @claviska\n'
    //     );
    //}
    }
   }



decorate(LoginService, {
    hidden:observable,
    socket:observable,
    forgot:observable,
    invalidLogin:observable,
    failed:observable,
    loading:observable,
    user:observable,
    isValid:observable,
    formerrors:observable,
    forgotloading:observable,
    forgotstatus:observable,
    tokenexpired:observable,
    forgot_user:observable,
    newpasserror:observable,
    newpasssuccess:observable,
    forgotsentmail:observable,
    emailnotregisterd:observable
})
 
export default LoginService;