import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import $ from 'jquery';

const TourGuide = observer(class TourGuide extends Component {

 

  handleJoyrideCallback = data => { 
    //console.log(data)
    //console.log("tour data..............."+this.props.tourState.stepIndex);
    const { action, index, status, type ,step} = data; 
      
        if(data.action ==="close"){
          //console.log(this.props.tourState.stepIndex)

          if(document.getElementById("react-joyride-step-"+ this.props.tourState.stepIndex))
             document.getElementById("react-joyride-step-"+ this.props.tourState.stepIndex).style.display="none"
         
          this.props.tourState.overlaydisplay = true;
          this.props.store.helpTourGuide=false
          this.props.appStore.helpTourStarted = false;
          this.props.appStore.tourcurrent_step=0;

          if(window.location.pathname !== "/admin/setup/widgets")
              this.props.appStore.addNewSite=false 

          return;
        }
     
        let count;
        let btnText = "Next ";
        if(step){
           count = step.stepNumber != 8 ? "("+step.stepNumber+"/8)" :"";
           btnText = step.stepNumber== 8 ? "Exit ":"Next ";
        }
       
           $( ".react-joyride__tooltip" ).find( "button[data-action='primary']" ).html(btnText+count);
    
     if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {

            //console.log(type  +"  "+this.props.tourState.stepIndex)
          if(this.props.tourState.stepIndex==0){ 
             
              this.props.tourState.stepIndex= index + (action === ACTIONS.PREV ? -1 : 1)
               if(this.props.tourState.isHomePage){

                this.props.appStore.addNewSite= this.props.appStore.maxWebsiteCrossed() ? false : true; 

                this.props.appStore.navigate('/admin/setup/widgets');
               }
              //step for widget page step
               if(this.props.tourState.isWidgetPage){
                  this.props.appStore.selectedtourStep = "step2";
                }
              else if(this.props.tourState.isHomePage) {
                  this.props.appStore.selectedtourStep = "";
                }

          }
         
      else {
        
        this.props.tourState.stepIndex= index + (action === ACTIONS.PREV ? -1 : 1)  
        //("else  "+this.props.tourState.stepIndex)

        // Update state to advance the tour
        if(this.props.tourState.stepIndex==2 && this.props.tourState.isWebsitePage){ 
          this.props.appStore.navigate('/admin/setup/widgets/add');
        }

       
        if(this.props.tourState.isWidgetBuilderOpen && this.props.tourState.stepIndex==3)
        {
          this.props.appStore.navigate('/traffic');
        }
        // this.props.store.startTour(this.props.appStore, this.props.tourState.stepIndex);
     
     }
     this.props.appStore.tourcurrent_step=this.props.appStore.tourcurrent_step+1; 
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      //console.log("tour finish")
      // Need to set our running state to false, so we can restart if we click start again.
      this.props.tourState.run=false;

      if( this.props.tourState.isTrafficPage){
        this.props.store.helpTourGuide=false
        this.props.appStore.helpTourStarted = false;
        this.props.appStore.addNewSite=false;
        this.props.appStore.tourcurrent_step=0;
      }
      
    }

    
  };

  render() {
    
    //const { run, stepIndex, steps } = this.state;
    const { appStore} = this.props;
    const {run , stepIndex , steps} = this.props.tourState;
    
   
    return (
      <div className="app">
        <Joyride
          run={run}
          continuous={true}
          showProgress={true}
          //  getHelpers = {() => alert("123")}
          callback={this.handleJoyrideCallback}
          stepIndex={stepIndex}
          steps={steps}
          disableCloseOnEsc={true}
          showSkipButton={false}
          disableOverlayClose={true}
          hideBackButton={true}
          disableOverlay={this.props.tourState.overlaydisplay}
          styles={{
            options: {
              arrowColor: '#e3ffeb',
              backgroundColor: '#e3ffeb',
              primaryColor: '#e89712',
              textColor: '#004a14',
              zIndex: 1000,
             
            }
          }}

        />
      </div>
    );
  }

})
export default TourGuide;