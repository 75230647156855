import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import Tooltip from "react-simple-tooltip";
import { css } from "styled-components";

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';

import InvitationPreviewComponent from './InvitationPreviewComponent';



const ToolBarPreviewComponent = observer(class ToolBarPreviewComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			position: 5,
			center: false
		}

	}

	componentDidMount() {
		if (this.props.widgetStore.toolBarPreviewButtonposition == "right bottom") {
			this.setPosition("bottomRight", 5);
		} else if (this.props.widgetStore.toolBarPreviewButtonposition == "left bottom") {
			this.setPosition("bottomLeft", 4);
		} else if (this.props.widgetStore.toolBarPreviewButtonposition == "right top" || this.props.widgetStore.toolBarPreviewButtonposition == "right center") {
			this.setPosition("right", 2);
		} else if (this.props.widgetStore.toolBarPreviewButtonposition == "left top" || this.props.widgetStore.toolBarPreviewButtonposition == "left center") {
			this.setPosition("left", 1);
		} else if (this.props.widgetStore.toolBarPreviewButtonposition == "center bottom") {
			this.centerToolBar();
		}

	}

	LightenDarkenColor(col, amt) {

		var usePound = false;

		if (col[0] == "#") {
			col = col.slice(1);
			usePound = true;
		}

		var num = parseInt(col, 16);

		var r = (num >> 16) + amt;

		if (r > 255) r = 255;
		else if (r < 0) r = 0;

		var b = ((num >> 8) & 0x00FF) + amt;

		if (b > 255) b = 255;
		else if (b < 0) b = 0;

		var g = (num & 0x0000FF) + amt;

		if (g > 255) g = 255;
		else if (g < 0) g = 0;

		return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

	}

	shadeColor(color, percent) {

		var R = parseInt(color.substring(1,3),16);
		var G = parseInt(color.substring(3,5),16);
		var B = parseInt(color.substring(5,7),16);
	
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
	
		R = (R<255)?R:255;  
		G = (G<255)?G:255;  
		B = (B<255)?B:255;  
	
		var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
		var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
		var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
	
		return "#"+RR+GG+BB;
	}

	getGradient(baseColor,a) { 
		let gradient = "";
		// var bg1=this.shadeColor(baseColor, -10);
		// var bg2=this.shadeColor(baseColor, -30);
		// var bg3=this.shadeColor(baseColor, -13);
		// var bg4=this.shadeColor(baseColor, -15);
		var bg1=this.shadeColor(baseColor, -1);
		var bg2=this.shadeColor(baseColor, -5);
		var bg3=this.shadeColor(baseColor, -13);
		var bg4=this.shadeColor(baseColor, -30);
		var pc1= "5%";
        var pc2= "95%";
		gradient = 'linear-gradient(to bottom,'+ bg1 +' 0%,'+bg2+' 5%,'+bg3+' 95%,'+bg4+ ' 100%)';
		//gradient = baseColor;
		return gradient;
	}
  
	setPosition(pos, position) {
		this.props.widgetStore.toolBarPreviewSettings.marginValue = this.props.widgetStore.toolBarPreviewSettings.marginValue;
		if (pos === 'left' || position === 1) {
			this.props.widgetStore.toolBarPreviewSettings.position = "left top";
			this.props.widgetStore.toolBarPreviewSettings.orientation = "vertical";
			// this.props.widgetStore.toolBarPreviewSettings.thickness= 
			this.props.widgetStore.toolBarPreviewSettings.bottom = 'auto';
			this.props.widgetStore.toolBarPreviewSettings.left = 0;
			this.props.widgetStore.toolBarPreviewSettings.right = 'auto';
			this.props.widgetStore.toolBarPreviewSettings.margin = this.props.widgetStore.toolBarPreviewSettings.margin;
			if (this.props.widgetStore.toolBarPreviewSettings.toolBarWidth === 'fit-content') {
				this.props.widgetStore.toolBarPreviewSettings.toolBarWidth = this.props.widgetStore.toolBarPreviewSettings.toolBarHeight;
				this.props.widgetStore.toolBarPreviewSettings.toolBarHeight = 'fit-content';
				this.props.widgetStore.toolBarPreviewSettings.thickness = 50;
			} else {
				this.props.widgetStore.toolBarPreviewSettings.thickness = this.props.widgetStore.toolBarPreviewSettings.toolBarHeight;
			}
			if(pos!=''){
				// this.setState({ center: false});
				this.state.center=false;
			}
			if (!this.state.center) {
				this.props.widgetStore.toolBarPreviewSettings.top = 0;
			}
			else {
				this.props.widgetStore.toolBarPreviewSettings.top = '50%';
			}
			this.setState({ position: 1 })
		}
		else if (pos === 'right' || position === 2) {
			this.props.widgetStore.toolBarPreviewSettings.position = "right top";
			this.props.widgetStore.toolBarPreviewSettings.orientation = "vertical";
			this.props.widgetStore.toolBarPreviewSettings.bottom = 'auto';
			this.props.widgetStore.toolBarPreviewSettings.left = 'auto';
			this.props.widgetStore.toolBarPreviewSettings.right = 0;
			this.props.widgetStore.toolBarPreviewSettings.margin = this.props.widgetStore.toolBarPreviewSettings.margin;
			if (this.props.widgetStore.toolBarPreviewSettings.toolBarWidth === 'fit-content') {
				this.props.widgetStore.toolBarPreviewSettings.toolBarWidth = this.props.widgetStore.toolBarPreviewSettings.toolBarHeight;
				this.props.widgetStore.toolBarPreviewSettings.toolBarHeight = 'fit-content';
				this.props.widgetStore.toolBarPreviewSettings.thickness = 50;
			} else {
				this.props.widgetStore.toolBarPreviewSettings.thickness = this.props.widgetStore.toolBarPreviewSettings.toolBarHeight;
			}
			if(pos!=''){
				// this.setState({ center: false});
				this.state.center=false;
			}
			if (!this.state.center) {
				this.props.widgetStore.toolBarPreviewSettings.top = 0;
			}
			else {
				this.props.widgetStore.toolBarPreviewSettings.top = '50%';
			}
			this.setState({ position: 2 })
		}
		else if (pos === 'bottomRight' || position === 5) {
			this.props.widgetStore.toolBarPreviewSettings.position = "right bottom";
			this.props.widgetStore.toolBarPreviewSettings.orientation = "horizontal";
			this.props.widgetStore.toolBarPreviewSettings.bottom = 0;
			this.props.widgetStore.toolBarPreviewSettings.top = 'auto';

			this.props.widgetStore.toolBarPreviewSettings.right = 0;		
			if(pos!=''){				
				//this.setState({center: false});
				this.state.center=false;
			}
			if (!this.state.center) {
				this.props.widgetStore.toolBarPreviewSettings.left = 'auto';
				this.props.widgetStore.toolBarPreviewSettings.margin = this.props.widgetStore.toolBarPreviewSettings.margin;
			}
			else {
				this.props.widgetStore.toolBarPreviewSettings.position = "center bottom";
				this.props.widgetStore.toolBarPreviewSettings.orientation = "horizontal";
				this.props.widgetStore.toolBarPreviewSettings.left = 0;
				this.props.widgetStore.toolBarPreviewSettings.margin = 'auto';
			}
			if (this.props.widgetStore.toolBarPreviewSettings.toolBarHeight === 'fit-content') {
				this.props.widgetStore.toolBarPreviewSettings.toolBarHeight = this.props.widgetStore.toolBarPreviewSettings.toolBarWidth;
				this.props.widgetStore.toolBarPreviewSettings.toolBarWidth = 'fit-content';
				this.props.widgetStore.toolBarPreviewSettings.thickness = 50;
			} else {
				this.props.widgetStore.toolBarPreviewSettings.thickness = this.props.widgetStore.toolBarPreviewSettings.toolBarHeight;
			}
			this.setState({ position: 5 })
		}
		else if (pos === 'bottomLeft' || position === 4) {
			this.props.widgetStore.toolBarPreviewSettings.position = "left bottom";
			this.props.widgetStore.toolBarPreviewSettings.orientation = "horizontal";
			this.props.widgetStore.toolBarPreviewSettings.bottom = 0;
			this.props.widgetStore.toolBarPreviewSettings.top = 'auto';
			this.props.widgetStore.toolBarPreviewSettings.left = 0;
			if(pos!=''){
				// this.setState({ center: false}); 
				this.state.center=false;
			} 
			if (!this.state.center) {
				this.props.widgetStore.toolBarPreviewSettings.right = 'auto';
				this.props.widgetStore.toolBarPreviewSettings.margin = this.props.widgetStore.toolBarPreviewSettings.margin;
			}
			else {
				this.props.widgetStore.toolBarPreviewSettings.position = "center bottom";
				this.props.widgetStore.toolBarPreviewSettings.orientation = "horizontal";
				this.props.widgetStore.toolBarPreviewSettings.margin = 'auto';
				this.props.widgetStore.toolBarPreviewSettings.right = 0;
			}
			if (this.props.widgetStore.toolBarPreviewSettings.toolBarHeight === 'fit-content') {
				this.props.widgetStore.toolBarPreviewSettings.toolBarHeight = this.props.widgetStore.toolBarPreviewSettings.toolBarWidth;
				this.props.widgetStore.toolBarPreviewSettings.toolBarWidth = 'fit-content';
				this.props.widgetStore.toolBarPreviewSettings.thickness = 50;
			} else {
				this.props.widgetStore.toolBarPreviewSettings.thickness = this.props.widgetStore.toolBarPreviewSettings.toolBarHeight;
			}
			this.setState({ position: 4 })
		}
	}

	getWidth = (buttonEle) => {
		let self = this;
		if (self.state.position === 1 || self.state.position === 2) {
			return '100%'
		}

		else if (buttonEle.width.value === 1) {
			return 'max-content';
		}

		else {
			return '';
		}

	}

	centerToolBar() {
		this.setState({ center: !this.state.center});
		setTimeout(() => {
			if (this.state.center) {
				this.props.widgetStore.toolBarPreviewSettings.margin = 'auto';
				this.props.widgetStore.toolBarPreviewSettings.left = 0;
				this.props.widgetStore.toolBarPreviewSettings.right = 0;
			}

			this.setPosition('', 5);
		}, 0);


	}

	setMargin(margin) {

		if(margin>200){
			this.props.widgetStore.toolBarPreviewSettings.marginValue=0;
			margin=0;
		}
		if (this.state.position === 1 || this.state.position === 2) {
			this.props.widgetStore.toolBarPreviewSettings.margin = margin + "px 0 0 0";
		}
		else if (this.state.position === 4) {
			this.props.widgetStore.toolBarPreviewSettings.margin = "0 0 0 " + margin + "px";
		}
		else if (this.state.position === 5) {
			this.props.widgetStore.toolBarPreviewSettings.margin = "0 " + margin + "px 0 0";
		}
	}
	setdivheight = () => {
		if (this.state.position === 1 || this.state.position === 2) {
			return '';
		} else if (this.props.widgetStore.toolBarPreviewSettings.toolBarHeight !== 'fit-content') {
			return this.props.widgetStore.toolBarPreviewSettings.toolBarHeight + 'px';
		} else {
			return '';
		}
	}
	getTooltipWidth = (buttonEle) => {

		let self = this;
		if (self.state.position === 1 || self.state.position === 2) {
			return '100%'
		}

		else if (buttonEle.width.value === 1) {
			return 'max-content';
		}

		else {
			return buttonEle.width.width + 'px';
		}

	}

	componentWillUnmount() {
	}
	render() {

		var self = this;

		const { appstore,
			widgetStore } = this.props; 
		return (
			<React.Fragment>
				<div className="toolBarPreviewWrapper">
					{widgetStore.selectedToolBarSetting === 2 &&
						<React.Fragment>
							<p className={"positionIcons left" + (this.state.position === 1 ? ' selected' : '')} onClick={() => this.setPosition('left',1)}>
								{/* <img className="img-fluid" src={imageURLs.positionLeft} /> */}
								<img className="img-fluid" width="18px" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.left_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.left_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.left_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.left_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.left_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.left_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.left_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.left_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.left_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.left_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.left_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.left_skyblue : imageURLs.left_steelblue} />
							</p>


							<p className={"positionIcons right" + (this.state.position === 2 ? ' selected' : '')} onClick={() => this.setPosition('right',2)}>
								{/* <img className="img-fluid" src={imageURLs.positionRight} /> */}
								<img className="img-fluid" width="18px" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.right_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.right_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.right_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.right_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.right_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.right_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.right_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.right_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.right_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.right_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.right_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.right_skyblue : imageURLs.right_steelblue} />
							</p>
							<p className={"positionIcons center" + (this.state.center ? ' selected' : '')} onClick={() => this.centerToolBar()}>
								{/* <img className="img-fluid" src={imageURLs.positionCentered} /> */}
								<img className="img-fluid" width="18px" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.bottomarrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.bottomarrow_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.bottomarrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.bottomarrow_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.bottomarrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.bottomarrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.bottomarrow_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.bottomarrow_steelblue : "#00887a" == appstore.selectedColor.color ? imageURLs.bottomarrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.bottomarrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.bottomarrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.bottomarrow_skyblue : imageURLs.bottomarrow_steelblue} />
							</p>
							<p className={"positionIcons bottomLeft" + (this.state.position === 4 ? ' selected' : '')} onClick={() => this.setPosition('bottomLeft',4)}>
								{/* <img className="img-fluid" src={imageURLs.positionBottomLeft} /> */}
								<img className="img-fluid" width="18px" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.bottomleftarrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.bottomleftarrow_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.bottomleftarrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.bottomleftarrow_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.bottomleftarrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.bottomleftarrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.bottomleftarrow_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.bottomleftarrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.bottomleftarrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.bottomleftarrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.bottomleftarrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.bottomleftarrow_skyblue : imageURLs.bottomleftarrow_steelblue} />
							</p>
							<p className={"positionIcons bottomRight" + ((!this.state.center && this.state.position === 5) ? ' selected' : '')} onClick={() => this.setPosition('bottomRight',5)}>
								{/* <img className="img-fluid" src={imageURLs.positionBottomRight} /> */}
								<img className="img-fluid" width="18px" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.bottomrightarrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.bottomrightarrow_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.bottomrightarrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.bottomrightarrow_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.bottomrightarrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.bottomrightarrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.bottomrightarrow_voilet : 
								 "#039be6" == appstore.selectedColor.color ? imageURLs.bottomrightarrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.bottomrightarrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.bottomrightarrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.bottomrightarrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.bottomrightarrow_skyblue : imageURLs.bottomrightarrow_steelblue} />
							</p>
						</React.Fragment>
					}
					<div className="toolBarWrapper">
						{
							widgetStore.selectedToolBarSetting === 4 &&
							<InvitationPreviewComponent widgetStore={widgetStore} appstore={appstore} />
						}
						{
							widgetStore.selectedToolBarSetting !== 4 &&
							<div className={"ulWrapper" + (this.state.position === 1 || this.state.position === 2 ? ' bar_vertical' : '') + ((this.state.position === 1 || this.state.position === 2) && this.state.center ? ' transform' : '')}
								style={{
									// background: (this.state.position === 1  || this.state.position === 2) ? '' : !widgetStore.toolBarPreviewSettings.gradient ? widgetStore.toolBarPreviewSettings.bgColor : this.getGradient(widgetStore.toolBarPreviewSettings.bgColor), 
									//height:  widgetStore.toolBarPreviewSettings.toolBarHeight !== 'fit-content' ? widgetStore.toolBarPreviewSettings.toolBarHeight+'px' : widgetStore.toolBarPreviewSettings.toolBarHeight,
									// width: widgetStore.toolBarPreviewSettings.toolBarWidth !== 'fit-content' ? widgetStore.toolBarPreviewSettings.toolBarWidth+'px' : widgetStore.toolBarPreviewSettings.toolBarWidth,
									// this.setdivheight()
									height: "auto",
									width: (this.state.position === 1 || this.state.position === 2) ? '100%' : widgetStore.toolBarPreviewSettings.toolBarWidth !== 'fit-content' ? widgetStore.toolBarPreviewSettings.toolBarWidth + 'px' : '',
									borderTopLeftRadius: (this.state.position === 4 || this.state.position === 5 || this.state.position === 2) ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
									borderTopRightRadius: (this.state.position === 4 || this.state.position === 5 || this.state.position === 1) ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
									borderBottomLeftRadius: this.state.position === 2 ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
									borderBottomRightRadius: this.state.position === 1 ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
									bottom: widgetStore.toolBarPreviewSettings.bottom,
									top: widgetStore.toolBarPreviewSettings.top,
									left: widgetStore.toolBarPreviewSettings.left,
									right: widgetStore.toolBarPreviewSettings.right,
									margin: widgetStore.toolBarPreviewSettings.margin,
									paddingTop: (this.state.position === 4 || this.state.position === 5) ? "175px" : '',
								}}>
								<ul className="toolBarBtns" style={{
									background: !widgetStore.toolBarPreviewSettings.gradient ? widgetStore.toolBarPreviewSettings.bgColor : this.getGradient(widgetStore.toolBarPreviewSettings.bgColor),
									height: (this.state.position === 1 || this.state.position === 2) ? "100%" : widgetStore.toolBarPreviewSettings.toolBarHeight !== 'fit-content' ? widgetStore.toolBarPreviewSettings.toolBarHeight + 'px' : 'max-content',
									width: (this.state.position === 1 || this.state.position === 2) ? widgetStore.toolBarPreviewSettings.toolBarHeight !== 'fit-content' ? widgetStore.toolBarPreviewSettings.toolBarHeight + 'px' : '50px' : widgetStore.toolBarPreviewSettings.toolBarWidth !== 'fit-content' ? widgetStore.toolBarPreviewSettings.toolBarWidth + 'px' : 'max-content',
									float: this.state.position == 1 ? "left" : this.state.position == 2 ? "right" : "",
									borderTopLeftRadius: (this.state.position === 4 || this.state.position === 5 || this.state.position === 2) ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
									borderTopRightRadius: (this.state.position === 4 || this.state.position === 5 || this.state.position === 1) ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
									borderBottomLeftRadius: this.state.position === 2 ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
									borderBottomRightRadius: this.state.position === 1 ? widgetStore.toolBarPreviewSettings.borderRadius + 'px' : 0,
								}}>

									{
										widgetStore.toolBarPreviewButtonSettings.map(function (buttonEle, buttonIndex) {
											if (buttonEle.show) {
												return (
													<li className="btn_wrapper"
														key={buttonIndex}
														style={{
															//  width: (self.state.position === 1 || self.state.position === 2) ? '100%' : buttonEle.width.value === 1 ? 'fit-content' : '',
															width: self.getWidth(buttonEle),
															//  height: (self.state.position === 4 || self.state.position === 5) ? '100%' : buttonEle.width.width +'px',
															height: (self.state.position === 4 || self.state.position === 5) ? '100%' : '',
															borderRightColor: self.LightenDarkenColor(widgetStore.toolBarPreviewSettings.bgColor, 20),
															borderLeftColor: self.LightenDarkenColor(widgetStore.toolBarPreviewSettings.bgColor, 64),
															borderBottomColor: self.LightenDarkenColor(widgetStore.toolBarPreviewSettings.bgColor, 20),
															borderTopColor: self.LightenDarkenColor(widgetStore.toolBarPreviewSettings.bgColor, 64),
															borderRight: (self.state.position === 1 || self.state.position === 2) ? 'none' : '1 px solid',
															borderLeft: (self.state.position === 1 || self.state.position === 2) ? 'none' : '1 px solid',
															borderTop: (self.state.position === 4 || self.state.position === 5) ? 'none' : '1 px solid',
															borderBottom: (self.state.position === 4 || self.state.position === 5) ? 'none' : '1 px solid'
														}}>
														<a>
															{
																buttonEle.toolTip !== '' &&
																<Tooltip background={!widgetStore.toolBarPreviewSettings.toolTip.gradient ? widgetStore.toolBarPreviewSettings.toolTip.bgColor : self.getGradient(widgetStore.toolBarPreviewSettings.toolTip.bgColor)}
																	border={widgetStore.toolBarPreviewSettings.toolTip.bgColor}
																	// self.getTooltipWidth(buttonEle) width= {(self.state.position === 1 || self.state.position === 2) ? '100%' : buttonEle.width.value === 1 ? 'fit-content' : buttonEle.width.width +'px'}
																	width={self.getTooltipWidth(buttonEle)}
																	height={(self.state.position === 4 || self.state.position === 5) ? '100%' : buttonEle.width.width + 'px'}
																	radius={widgetStore.toolBarPreviewSettings.toolTip.borderRadius}
																	color={widgetStore.toolBarPreviewSettings.toolTip.textColor}
																	fontFamily={widgetStore.toolBarPreviewSettings.toolTip.font}
																	placement={self.state.position == 1 ? "right" : self.state.position == 2 ? "left" : "top"}
																	offset={(self.state.position === 1 || self.state.position === 2) ? (widgetStore.toolBarPreviewSettings.toolBarHeight > 60 ? 30 : 17) : 2}
																	//   customCss={css`
																	// 	  right: 20px;
																	// 		`}
																	content={buttonEle.toolTip}>
																	{
																		buttonEle.iconType.value !== 2 &&
																		<div>
																			<div className="preview_row">
																				<div className="preview_clmn">
																					{buttonEle.iconUrl == '' &&
																						<span id={"icon" + buttonIndex} className={"btn_icon HelpOnClick-Icon HelpOnClick-fa-" + (buttonEle.libraryIconClass === "" ? "" : buttonEle.libraryIconClass) + (buttonEle.caption !== '' ? ' HelpOnClick-WithText' : '') + " HelpOnClick-fa "} style={{ color: widgetStore.toolBarPreviewSettings.textColor }}>

																						</span>
																					}
																					{buttonEle.iconUrl != '' &&
																						<span id={"icon" + buttonIndex} className="btn_icon HelpOnClick-Icon HelpOnClick-fa- HelpOnClick-fa " style={{ color: widgetStore.toolBarPreviewSettings.textColor }}>
																							<img src={buttonEle.iconUrl == "[object File]" ? URL.createObjectURL(buttonEle.iconUrl) : buttonEle.iconUrl} style={{ height: "24px", width: "24px" }} />
																						</span>
																					}
																				</div>
																			</div>
																		</div>

																	}
																	{
																		buttonEle.caption !== '' && self.state.position !== 1 && self.state.position !== 2 &&
																		<div>
																			<span className="btn_caption" style={{ color: widgetStore.toolBarPreviewSettings.textColor, 'font-family': widgetStore.toolBarPreviewSettings.font }}>
																				{buttonEle.caption} 
																			</span>
																		</div>
																	}
																	{ buttonEle.iconType.value === 2 && (self.state.position === 1 || self.state.position === 2) &&
																		<div>
																			<span className="btn_caption" style={{ color: widgetStore.toolBarPreviewSettings.textColor, 'font-family': widgetStore.toolBarPreviewSettings.font }}>
																			
																			</span>
																		</div>
																	}
																</Tooltip>

															}


															{
																buttonEle.toolTip === '' &&
																<React.Fragment>
																	{
																		buttonEle.iconType.value !== 2 &&
																		<div>
																			<div className="preview_row">
																				<div className="preview_clmn">
																					{/* <span id={"icon" + buttonIndex} className={"btn_icon HelpOnClick-Icon HelpOnClick-fa- HelpOnClick-fa HelpOnClick-fa-" + (buttonEle.libraryIconClass === "" ? buttonEle.iconClass : buttonEle.libraryIconClass) + (buttonEle.caption !== '' ? ' HelpOnClick-WithText' : '')} style={{ color: widgetStore.toolBarPreviewSettings.textColor }}> */}
																						<span id={"icon" + buttonIndex} className={"btn_icon HelpOnClick-Icon HelpOnClick-fa- HelpOnClick-fa HelpOnClick-fa-" + (buttonEle.libraryIconClass === "" ? "" : buttonEle.libraryIconClass) + (buttonEle.caption !== '' ? ' HelpOnClick-WithText' : '')} style={{ color: widgetStore.toolBarPreviewSettings.textColor }}>																						
																						</span>
																				</div>
																			</div>
																		</div>

																	}
																	{
																		buttonEle.caption !== '' && self.state.position !== 1 && self.state.position !== 2 &&
																		<div>
																			<span className="btn_caption" style={{ color: widgetStore.toolBarPreviewSettings.textColor, 'font-family': widgetStore.toolBarPreviewSettings.font }}>
																				{buttonEle.caption}
																			</span>
																		</div>
																	}
																</React.Fragment>

															}

														</a>

													</li>
												)
											}

										})
									}
								</ul>
							</div>
						}

					</div>

				</div>
				{widgetStore.selectedToolBarSetting === 2 && !this.state.center &&
					<div className="customSize previewMargin">
						<Form.Group as={Row} className="style_margin_tour" controlId="formGroupMsg">
							<Form.Label column lg="5" sm="6" className="pr-0">{'Margin' + (this.state.position === 1 ? ' top' : (this.state.position === 2 ? ' top' : (this.state.position === 4 ? ' left' : ' right')))}
							</Form.Label>
							<Col sm="6" lg="7">
								<Form.Control type="text"
									onKeyDown={(e) => { widgetStore.checkForEnteredNumer(e) }}
									name="margin" placeholder=""
									value={widgetStore.toolBarPreviewSettings.marginValue}
									onChange={(e) => { widgetStore.toolBarPreviewSettings.marginValue = e.target.value; this.setMargin(e.target.value) }} />
								<span>px</span>
							</Col>
						</Form.Group>
					</div>
				}
			</React.Fragment>
		)
	}
})
export default ToolBarPreviewComponent;
