import React, {Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button} from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';

const ConfirmationComponent = observer(class ConfirmationComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}

 
	render(){
		const {show, 
			   store,
				title,
				callBack,
				body_html,
				cancel_btn,
				save_btn,
				appStore,
				singleButton,
				logoutCss,
			    closeButtonRequired} = this.props;
		return (
			<Modal backdrop='static' className={(appStore ? appStore.selectedColor.value : '')+" "+(logoutCss ? logoutCss:"")} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => closeButtonRequired ? appStore.logout(): this.props.closePopup() }>
				  <Modal.Header closeButton>
				    <Modal.Title>{appStore.getLanguageTrans(title)}</Modal.Title>
				  </Modal.Header>

				  <Modal.Body style={{maxHeight:"400px",overflow:"auto",scrollbarColor: appStore.selectedColor.color+" transparent",scrollbarWidth: "thin"}} dangerouslySetInnerHTML={{__html: body_html}}>
				    
				  </Modal.Body>

				  <Modal.Footer>
					  {cancel_btn=="Ok" &&  <Button className="btn_save"  type="button" variant="primary" onClick={() => this.props.closePopup()} >{appStore.getLanguageTrans(cancel_btn)}</Button>}
					  {cancel_btn!="Ok" &&  <Button className="btn_cancel" type="button" variant="secondary" onClick={() => this.props.closePopup()} >{appStore.getLanguageTrans(cancel_btn)}</Button>}
				   { !singleButton && <Button className="btn_save"  type="button" variant="primary" onClick={(e) => this.props.callBack(e)}>{appStore.getLanguageTrans(save_btn)}</Button>}
				  </Modal.Footer>                      
				</Modal>
		)
	}
})
export default ConfirmationComponent;
