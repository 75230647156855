import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Select , { components } from 'react-select';

import { CompactPicker } from 'react-color';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../../sharedFiles/navigation-store';

import FormValidator from '../../../../../sharedFiles/FormValidator';

import WidgetCodeComponent from '../../../../../sharedComponents/getWidgetCode/WidgetCodeComponent';

const ClickOutHandler = require('react-onclickout');


const DirectLinkBuilderComponent = observer(class DirectLinkBuilderComponent extends Component {

	constructor (props) {
        super(props);

        this.validator = new FormValidator([
 			{
 				field: 'installationName',
 				method: 'isEmpty',
 				validWhen: false, 
	        	message: 'Installation Name is required.' 
 			}
	    ]);

	    this.state = {
			validation: this.validator.valid(),
			websiteUid:this.props.appstore.selectedWebisiteUid
		}
	
	    this.submitted = false;

	    this.pickTextColorBasedOnBgColorAdvanced = this.pickTextColorBasedOnBgColorAdvanced.bind(this);
    }

	componentDidMount(){
		
		this.props.widgetStore.getCode = false;
		this.props.widgetStore.sendCode = false;
		this.props.widgetStore.topBarColorSelector = false;
		this.props.widgetStore.topBarTextColorSelector = false;
		  //set dropdown data
		this.props.widgetStore.operatorDrpDwnOptions= this.props.appstore.deptOps.allOps;
		this.props.widgetStore.deptDrpDwnOptions=this.props.appstore.deptOps.allDept;
		this.props.widgetStore.directLinkDetails.department = this.props.widgetStore.deptDrpDwnOptions[0];
		this.props.widgetStore.directLinkDetails.operator = this.props.widgetStore.operatorDrpDwnOptions[0];
		this.props.widgetStore.directLinkDetails.language = this.props.appstore.selectedLanguage;
		this.props.widgetStore.setwidgetDataforEdit(this.props.appstore);
		this.props.widgetStore.pageisLoaded=true;
		//document.querySelector('.directLinkWrapper').scrollTop = 0;
	}

	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) { 
			//if user changes website then point to main widgets page
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});	
			  this.props.appstore.navigate('/admin/setup/widgets/');	  
		}
	  }

	handleFormSubmit(event) {
		event.preventDefault();
		const validation = this.validator.validate(this.props.widgetStore.directLinkDetails);
	    this.setState({ validation });
	    this.submitted = true;
		if (validation.isValid) {
			let trimedName = this.props.widgetStore.directLinkDetails.installationName.trim();
			if(trimedName.length==0){
				//stop submission if spaces
				return;
			}
		    // handle actual form submission here
		   this.props.widgetStore.directLinkDetails.installationName =trimedName;
		   this.props.widgetStore.saveDirectLink(this.props.appstore);
	     
	    //  this.props.deptStore.saveDepartment(this.props.widgetStore.directLinkDetails);
	    }
	}

	componentWillUnmount() {
    }

    pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
    	if(bgColor) {
		  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
		  var r = parseInt(color.substring(0, 2), 16); // hexToR
		  var g = parseInt(color.substring(2, 4), 16); // hexToG
		  var b = parseInt(color.substring(4, 6), 16); // hexToB
		  var uicolors = [r / 255, g / 255, b / 255];
		  var c = uicolors.map((col) => {
		    if (col <= 0.03928) {
		      return col / 12.92;
		    }
		    return Math.pow((col + 0.055) / 1.055, 2.4);
		  });
		  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
		  return (L > 0.298) ? darkColor : lightColor;
		}
	}

	render(){

		var self = this;

		let validation = this.submitted ?                         // if the form has been submitted at least once
                      this.validator.validate(this.props.widgetStore.directLinkDetails) :   // then check validity every time we render
                      this.state.validation  

		const { appstore,
				widgetStore } = this.props;

		// if(!widgetStore.pageisLoaded){
		// 	return (<div></div>)
		// }		
        
		return (
				<div  key={appstore.selectedWebisiteUid} className="settingsForm directLinkWrapper col-12 w-100 pr-0">
					<h4 className="text-left">{appstore.getLanguageTrans("Direct Link Builder")} 
					</h4>

					<div className="settingFormWrapper box">
						<div className="row">
							<div className="col-12 col-sm-10 col-md-7">
								<form>
									<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className={"pr-0" + (validation.installationName.isInvalid && ' field-error')}>Installation name <span className="mandatory">*</span></Form.Label>
								    	<Col sm="6" lg="7">
								    		<Form.Control type="text" className={validation.installationName.isInvalid && 'has-error'}
	    											name="installationName" placeholder={appstore.getLanguageTrans("Enter Installation Name")} 
								    				value={widgetStore.directLinkDetails.installationName} 
								    				onChange={(e) => widgetStore.directLinkDetails.installationName = e.target.value} />
								    		<span className="error-block">{validation.installationName.message}</span>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Department")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.deptDrpDwnOptions}
																store={widgetStore}
																key ={Math.random()}
											    				callBack={(selectedOption) => widgetStore.getOpforDept(appstore,widgetStore.directLinkDetails,selectedOption)}
											    				selectedOption = {widgetStore.directLinkDetails.department}/>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Operator")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.operatorDrpDwnOptions}
																store={widgetStore}
																key ={Math.random()}
											    				callBack={(selectedOption) => widgetStore.directLinkDetails.operator = selectedOption}
											    				selectedOption = {widgetStore.directLinkDetails.operator}/>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Language")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
										    <DropDownComponent dropDownOptions={appstore.languages}
												store={appstore}
												key ={Math.random()}
												callBack={(selectedLanguage) =>widgetStore.setLanguage(selectedLanguage,appstore,widgetStore.directLinkDetails)}
												selectedOption = {widgetStore.directLinkDetails.language}/>
								    	</Col>
								  	</Form.Group>
									  <Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("Virtual Agent")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.directLinkDetails.enableVirtualAgent}
									    							callBack = {() => widgetStore.directLinkDetails.enableVirtualAgent = !widgetStore.directLinkDetails.enableVirtualAgent}/>
									    </Col>
								  	</Form.Group>
								  	{/* <Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Enable pre-chat fields")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.directLinkDetails.preChatEnabled}
									    							callBack = {() => widgetStore.directLinkDetails.preChatEnabled = !widgetStore.directLinkDetails.preChatEnabled}/>
									    </Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Show pre-chat fields to invited users")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.directLinkDetails.preChatToUsers}
									    							callBack = {() => widgetStore.directLinkDetails.preChatToUsers = !widgetStore.directLinkDetails.preChatToUsers}/>
									    </Col>
								  	</Form.Group> */}
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Top bar color")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn customOption">
								    		<div className="row m-0">
									    		<DropDownComponent dropDownOptions={widgetStore.topBarColors}
										    						store={widgetStore}
												    				callBack={(selectedOption) => widgetStore.directLinkDetails.topBarColor = selectedOption}
												    				selectedOption = {widgetStore.directLinkDetails.topBarColor}/>
												{
													widgetStore.directLinkDetails.topBarColor.value === "custom" && 
														 <ClickOutHandler onClickOut={() => widgetStore.topBarColorSelector = false}>
															<div className="css-10nd86i text-left colorSelector">
																<div className="css-vj8t7z" style={{backgroundColor: widgetStore.directLinkDetails.topBarColor.colorCode}}>
																	<div className="css-1hwfws3">
																		<div className="css-1g6gooi">
																			<div className="">
																				<input autoComplete="off" 
																					   autoCorrect="off" 
																					   id="react-select-9-input" 
																					   spellCheck="false" tabIndex="0" type="text" 
																					   aria-autocomplete="list" 
																					   value={widgetStore.directLinkDetails.topBarColorCustom}
																					   onChange={ (event) => widgetStore.directLinkDetails.topBarColorCustom = event.target.value}
																					   onBlur={ () => widgetStore.directLinkDetails.topBarColor.colorCode = widgetStore.directLinkDetails.topBarColorCustom}
																					   style={{backgroundColor: widgetStore.directLinkDetails.topBarColor.colorCode, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.directLinkDetails.topBarColor.colorCode,  '#FFFFFF', '#000000')}}
																					   />
																				
																			</div>
																		</div>
																	</div>
																	<div className="css-1wy0on6" onClick={() => {widgetStore.topBarColorSelector = !widgetStore.topBarColorSelector; widgetStore.topBarTextColorSelector = false }}>
																		<span className="css-d8oujb"></span>
																		<div aria-hidden="true" className="css-1ep9fjw">
																			<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
																				<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															{
																widgetStore.topBarColorSelector &&
																	<CompactPicker className="colorSelector"
																					color={ widgetStore.directLinkDetails.topBarColor.colorCode } 
																					onChangeComplete={(color,event) => {widgetStore.directLinkDetails.topBarColor.colorCode = color.hex; widgetStore.directLinkDetails.topBarColorCustom = color.hex}}/>
															}
														    
														</ClickOutHandler>
												}
											</div>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Top bar text color")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn customOption">
								    		<div className="row m-0">
									    		<DropDownComponent dropDownOptions={widgetStore.topBarTextColors}
										    						store={widgetStore}
												    				callBack={(selectedOption) => widgetStore.directLinkDetails.topBarTextColor = selectedOption}
												    				selectedOption = {widgetStore.directLinkDetails.topBarTextColor}/>
												{
													widgetStore.directLinkDetails.topBarTextColor.value === "custom" && 
														<ClickOutHandler onClickOut={() => widgetStore.topBarTextColorSelector=false}>
															<div className="css-10nd86i text-left colorSelector">
																<div className="css-vj8t7z" style={{backgroundColor: widgetStore.directLinkDetails.topBarTextColor.colorCode}}>
																	<div className="css-1hwfws3">
																		<div className="css-1g6gooi">
																			<div className="">
																				<input autoComplete="off" 
																					   autoCorrect="off" 
																					   id="react-select-9-input" 
																					   spellCheck="false" tabIndex="0" type="text" 
																					   aria-autocomplete="list" 
																					   value={widgetStore.directLinkDetails.topBarTextColorCustom}
																					   onChange={ (event) => widgetStore.directLinkDetails.topBarTextColorCustom = event.target.value}
																					   onBlur={ () => widgetStore.directLinkDetails.topBarTextColor.colorCode = widgetStore.directLinkDetails.topBarTextColorCustom}
																					   style={{backgroundColor: widgetStore.directLinkDetails.topBarTextColor.colorCode, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.directLinkDetails.topBarTextColor.colorCode,  '#FFFFFF', '#000000')}}
																					   />
																				
																			</div>
																		</div>
																	</div>
																	<div className="css-1wy0on6" onClick={() => {widgetStore.topBarTextColorSelector = !widgetStore.topBarTextColorSelector; widgetStore.topBarColorSelector = false }}>
																		<span className="css-d8oujb"></span>
																		<div aria-hidden="true" className="css-1ep9fjw">
																			<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
																				<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															{
																widgetStore.topBarTextColorSelector &&
																	<CompactPicker className="colorSelector"
																					color={ widgetStore.directLinkDetails.topBarTextColor.colorCode } 
																					onChangeComplete= {(color,event) => {widgetStore.directLinkDetails.topBarTextColor.colorCode = color.hex; widgetStore.directLinkDetails.topBarTextColorCustom = color.hex }}/>
															}
														    
														</ClickOutHandler>
												}
												
											</div>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Text font")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.textFonts}
									    						store={widgetStore}
											    				callBack={(selectedOption) => widgetStore.directLinkDetails.textFont = selectedOption}
											    				selectedOption = {widgetStore.directLinkDetails.textFont}/>
								    	</Col>
								  	</Form.Group>
								</form>
							</div>
							<div className="col-12 col-sm-2 col-md-5 directLinkpreviewWrapper">
								<p style={{fontFamily: widgetStore.directLinkDetails.textFont.label}}>{appstore.getLanguageTrans("Live Chat")}</p>
								<div className="directLinkpreview" style={{background: widgetStore.directLinkDetails.topBarColor.colorCode+' url('+appstore.imgpath+'themes//toolbarDirect_preview.png)'}}>
								</div>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-12 formBtns">
								<div className="pullRight">
									<Button className="btn_clr" variant="light" onClick={() => widgetStore.selectedWidget = 0}>
								    	<p>{appstore.getLanguageTrans("Cancel")}</p>
								    </Button>
								    <Button className="btn_save" onClick={(event) => this.handleFormSubmit(event)}>
								    	<p>{appstore.getLanguageTrans("Save & Get code")}</p>
								    </Button>
								</div>
							</div>
						</div>
						{
							widgetStore.getCode && 
								<WidgetCodeComponent store = {widgetStore} appstore={appstore}/>
						}
					</div>
				</div>
		)
	}
})
export default DirectLinkBuilderComponent;
