import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Select , { components } from 'react-select';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../../sharedFiles/navigation-store';

import FormValidator from '../../../../../sharedFiles/FormValidator';

import WidgetCodeComponent from '../../../../../sharedComponents/getWidgetCode/WidgetCodeComponent';

import IconBuilderPreviewComponent from './IconBuilderPreviewComponent';

import ToolBarTopComponent from './toolBar/ToolBarTopComponent';
import GeneralSettingsComponent from './toolBar/GeneralSettingsComponent';
import StyleSettingsComponent from './toolBar/StyleSettingsComponent';
import ButtonSettingsComponent from './toolBar/ButtonSettingsComponent';
import InvitationSettingsComponent from './toolBar/InvitationSettingsComponent';
import ToolBarPreviewComponent from './toolBar/PreviewComponent';

const ToolBarComponent = observer(class ToolBarComponent extends Component {

	constructor (props) {
        super(props);

        this.validator = new FormValidator([
 			{
 				field: 'installationName',
 				method: 'isEmpty',
 				validWhen: false, 
	        	message: 'Installation Name is required.' 
			 },
			//  {
			// 	field: 'fbid',
			// 	method: 'isEmpty',
			// 	validWhen: false, 
			//    message: 'Facebook Id is required.' 
			//  }
		]); 

	    this.state = {
			validation: this.validator.valid(), 
			websiteUid:this.props.appstore.selectedWebisiteUid,
			tourStep:this.props.appstore.selectedtourStep
	    }
	    this.submitted = false;

    }

	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) { 
			//if user changes website then point to main widgets page
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});	
			  this.props.appstore.navigate('/admin/setup/widgets/');	  
		}
		if(this.props.appstore.helpTourStarted){
			let stepNo = this.props.appstore.selectedtourStep;
			stepNo = stepNo.slice(4); 
			if(stepNo == 8){
			  for(let i in this.props.widgetStore.toolBarPreviewButtonSettings){
				  if(this.props.widgetStore.toolBarPreviewButtonSettings[i].type === "chat"){
					 this.props.widgetStore.toolBarPreviewButtonSettings[i].defaultOpen = true;
				  }
			  }
			}
			if(stepNo == 4){
				window.scrollTo(0, 350);
			}
		}
	  }

	componentDidMount(){
		this.props.widgetStore.getCode = false;
		this.props.widgetStore.sendCode = false;
		this.props.widgetStore.pageisLoaded= true;	
	}

	handleFormSubmit(event) {
		event.preventDefault();
		
		const validation = this.validator.validate(this.props.widgetStore.toolBarGeneralSettings);
		// console.log(this.props.widgetStore.toolBarPreviewButtonSettings[3]);
		// console.log(this.props.widgetStore.toolBarPreviewButtonSettings[3].faceBookId.value);
		 if(this.props.widgetStore.toolBarPreviewButtonSettings[3].faceBookId.value==2){
			 if(this.props.widgetStore.toolBarPreviewButtonSettings[3].fbid.trim()==""){  
				this.props.widgetStore.toolBarFbButtonid=true;
				return;
			 }else{ 
				this.props.widgetStore.toolBarFbButtonid=false;
			 }  
			
		}else{
			this.props.widgetStore.toolBarFbButtonid=false;
		}
	    this.setState({ validation });
	    this.submitted = true;
		if (validation.isValid) {
		  // handle actual form submission here 
	      this.props.widgetStore.selectedToolBarSetting++;
	      if(this.props.widgetStore.selectedToolBarSetting > 4) { 
			  this.props.widgetStore.saveToolbar(this.props.appstore);
			  this.props.widgetStore.selectedToolBarSetting=4; 
		  }
		
	    //  this.props.deptStore.saveDepartment(this.props.widgetStore.iconDetails);
	    }
	}

	componentWillUnmount() {
    }

	clearToolbardata(appstore) {
		this.props.widgetStore.ToolTipcolornew="";
		this.props.widgetStore.ToolTiptextnew="";
		this.props.widgetStore.bgcolornew="";
		this.props.widgetStore.bgtextnew="";  
		this.props.widgetStore.toobaredit=false;
		this.props.widgetStore.toolBarPreviewButtonSettings = this.props.widgetStore.resttoolBarPreviewButtonSettings;
		this.props.widgetStore.toolBarAddButton = this.props.widgetStore.resttoolBarAddButton;
		this.props.widgetStore.toolBarInvitationSettings=this.props.widgetStore.resttoolBarInvitationSettings
		if(this.props.widgetStore.chatresourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.chatresourceid,appstore);
			this.props.widgetStore.chatresourceid = "";
		}
		if(this.props.widgetStore.emailresourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.emailresourceid,appstore);
			this.props.widgetStore.emailresourceid = "";
		}
		if(this.props.widgetStore.callresourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.callresourceid,appstore);
			this.props.widgetStore.callresourceid = "";
		}
		if(this.props.widgetStore.faccebookresourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.faccebookresourceid,appstore);
			this.props.widgetStore.faccebookresourceid = "";
		}
		if(this.props.widgetStore.twitterresourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.twitterresourceid,appstore);
			this.props.widgetStore.twitterresourceid = "";
		}
		if(this.props.widgetStore.customeOneresourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.customeOneresourceid,appstore);
			this.props.widgetStore.customeOneresourceid = "";
		}
		if(this.props.widgetStore.customeTworesourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.customeTworesourceid,appstore);
			this.props.widgetStore.customeTworesourceid = "";
		}
		if(this.props.widgetStore.customeThreeresourceid != ""){
			this.props.widgetStore.deletefiles(this.props.widgetStore.customeThreeresourceid,appstore);
			this.props.widgetStore.customeThreeresourceid = "";
		} 
		if(this.props.widgetStore.invitationimgUrl != ""){ 
			this.props.widgetStore.deletefiles(this.props.widgetStore.invitationimgUrl,appstore);
			this.props.widgetStore.invitationimgUrl=""; 
		}
		if(this.props.widgetStore.invitationCustUrl != ""){ 
			this.props.widgetStore.deletefiles(this.props.widgetStore.invitationCustUrl,appstore);
			this.props.widgetStore.invitationCustUrl=""; 
		}
		this.props.widgetStore.toolBarGeneralSettings = {
			installationName: "Toolbar",
			department: this.props.widgetStore.deptDrpDwnOptions[0],
			operator: this.props.widgetStore.operatorDrpDwnOptions[0],
			language: this.props.widgetStore.lanDropDownOptions[0],
			chatWindowSize: this.props.widgetStore.chatWindowOptions[0].value,
			customChatWindowSize: {
			  height: 400,
			  width: 365
			},
			preChatEnabled: true,
			preChatToUsers: false,
			enableVirtualAgent:false,
		  }
		this.props.widgetStore.toolBarGeneralSettings.language = this.props.appstore.selectedLanguage;
		this.props.widgetStore.toolBarStyleSettings = {
			bgColor: this.props.widgetStore.topBarColors[1],
			bgColorCustom: this.props.widgetStore.topBarColors[7].colorCode,
			textColor: this.props.widgetStore.topBarTextColors[1],
			textColorCustom: this.props.widgetStore.topBarTextColors[2].colorCode,
			gradient: false,
			roundedCorners: true,
			barThickness: this.props.widgetStore.toolBarThickness[0],
			textFont: this.props.widgetStore.textFonts[0],
			toolTipSettings: this.props.widgetStore.toolTipStyle[0]
		  }
		  this.props.widgetStore.toolBarPreviewButtonSettings = [
			{
			  id: 1,
			  type: "chat",
			  label: 'Chat',
			  show: true,
			  hiddenOffline: false,
			  caption: 'Live Chat With Us',
			  toolTip: 'Live Chat With Us',
			  iconType: this.props.widgetStore.toolBarButtonIcons[0],
			  iconClass: 'HelpOnClick-Icon-chat',
			  libraryIconClass: '',
			  iconUrl: '',
			  width: this.props.widgetStore.toolBarButtonWidth[0],
			  defaultOpen: false,
			  croppedImgUrl : ''
	  
			},
			{
			  id: 2,
			  type: "email",
			  label: 'Email',
			  show: true,
			  hiddenOffline: false,
			  caption: 'Leave us a message',
			  toolTip: 'Leave us a message',
			  iconType: this.props.widgetStore.toolBarButtonIcons[0],
			  iconClass: 'HelpOnClick-Icon-email',
			  libraryIconClass: '',
			  iconUrl: '',
			  width: this.props.widgetStore.toolBarButtonWidth[0],
			  defaultOpen: false,
			  croppedImgUrl : ''
	  
			},
			{
			  id: 3,
			  label: 'Call',
			  show: false,
			  //hiddenOffline: false,
			  type: "call",
			  caption: '',
			  toolTip: 'Call me back',
			  iconType: this.props.widgetStore.toolBarButtonIcons[0],
			  iconClass: 'HelpOnClick-Icon-call',
			  libraryIconClass: '',
			  iconUrl: '',
			  width: this.props.widgetStore.toolBarButtonWidth[0],
			  skypeLogin: '',
			  showWhen: this.props.widgetStore.buttonSettingsTimes[1],
			  useCustomText: false,
			  customText: this.props.widgetStore.callScheduleText[2].label,
			  defaultOpen: false,
			  croppedImgUrl:''
	  
			},
			{
			  id: 4,
			  type: "social_facebook",
			  label: 'Facebook',
			  show: false,
			  // hiddenOffline: false,
			  caption: '',
			  toolTip: 'Like on Facebook',
			  iconType: this.props.widgetStore.toolBarButtonIcons[0],
			  iconClass: 'HelpOnClick-Icon-facebook',
			  libraryIconClass: '',
			  iconUrl: '',
			  width: this.props.widgetStore.toolBarButtonWidth[0],
			  faceBookId: this.props.widgetStore.fbOptions[0],
			  fbid: '',
			  defaultOpen: false,
			  croppedImgUrl: ''
	  
			},
			{
			  id: 5,
			  type: "social_twitter",
			  label: 'Twitter',
			  show: false,
			  //hiddenOffline: false,
			  caption: '',
			  toolTip: 'Follow on Twitter',
			  iconType: this.props.widgetStore.toolBarButtonIcons[0],
			  iconClass: 'HelpOnClick-Icon-twitter',
			  libraryIconClass: '',
			  iconUrl: '',
			  width: this.props.widgetStore.toolBarButtonWidth[0],
			  widgetHtmlCode: '',
			  accountId: '',
			  widgetId: '',
			  defaultOpen: false,
			  croppedImgUrl: ''
	  
			}
		  ];
		  this.props.widgetStore.resttoolBarPreviewButtonSettings = this.props.widgetStore.toolBarPreviewButtonSettings;
	   
		  this.props.widgetStore.toolBarAddButton = {
			id: 6,
			label: '',
			show: false,
			hiddenOffline: false,
			caption: 'Custom',
			toolTip: '',
			iconType: this.props.widgetStore.toolBarCustomButtonIcons[0],
			iconClass: '',
			libraryIconClass: '',
			iconUrl: '',
			width: this.props.widgetStore.toolBarButtonWidth[0],
			contentType: this.props.widgetStore.btnContentTypes[0],
			defaultOpen: true,
			croppedImgUrl: ''
	  
		  }
		  this.props.widgetStore.resttoolBarAddButton = this.props.widgetStore.toolBarAddButton;
		this.props.widgetStore.invitationprieviewimg="";
		this.props.widgetStore.toolBarInvitationSettings = {
			invitationType: this.props.widgetStore.invitationTypes[0].value,
			invitationImage: this.props.widgetStore.invitationImageTypes[0],
			url: '',
			customImageUrl: '',
			margin: 2,
			leftMargin: 20,
			styles: this.props.widgetStore.invitationStyles[0].value,
			bgColor: '#eeeeee',
			bgTextColor: '#272727',
			buttonBgColor: '#dddddd',
			buttonBgTextColor: '#272727',
			croppedCustomImgUrl:'',
			croppedInvUrl:''
		  }
		  this.props.widgetStore.selectedToolBarSetting=1;
		  this.props.widgetStore.toolBarPreviewSettings = {
			position:"right bottom",
			orientation:"horizontal",
			thickness:50,
			bgColor: this.props.widgetStore.toolBarStyleSettings.bgColor.colorCode,
			borderRadius:  5,
			textColor: this.props.widgetStore.toolBarStyleSettings.textColor.colorCode,
			font:  this.props.widgetStore.textFonts[0].label,
			gradient:  this.props.widgetStore.toolBarStyleSettings.gradient,
			bottom: 0,
			top: 'auto',
			left: 'auto',
			right: 0,
			margin: 0,
			marginValue: 0,
			toolBarHeight: this.props.widgetStore.toolBarStyleSettings.barThickness.height,
			toolBarWidth: 'fit-content',
			toolTip: {
			  bgColor: this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode,
			  borderRadius: this.props.widgetStore.toolBarToolTipStyleSettings.roundedCorners ? 5 : 0,
			  textColor: this.props.widgetStore.toolBarToolTipStyleSettings.textColor.colorCode,
			  gradient: false,
			}
		  }
	}

	render() {

		var self = this;

		let validation = this.submitted ?                         // if the form has been submitted at least once
                      this.validator.validate(this.props.widgetStore.toolBarGeneralSettings) :   // then check validity every time we render
					  this.state.validation ; 

		const { appstore,
				widgetStore } = this.props;
		if(!this.props.widgetStore.pageisLoaded){
            return(<div></div>)
		}
		
				
		return (
				<div key={appstore.selectedWebisiteUid} className="settingsForm directLinkWrapper toolBarWrapper col-12 w-100 pr-0">
					<h4 className="text-left">{appstore.getLanguageTrans("Social Chat Toolbar Builder")}
					</h4>

				<div className="settingFormWrapper box">
					<div className="row m-0">
						<ToolBarTopComponent appstore={appstore} widgetStore={widgetStore} />
					</div>
					<div className="row">
						<div className={"col-12 col-xl-7" + (!appstore.menuActive ? ' col-lg-7' : '')}>
							{
								widgetStore.selectedToolBarSetting === 1 &&
								<GeneralSettingsComponent appstore={appstore} widgetStore={widgetStore} validation={validation} />
							}
							{
								widgetStore.selectedToolBarSetting === 2 &&
								<StyleSettingsComponent appstore={appstore} widgetStore={widgetStore} validation={validation} />
							}
							{
								widgetStore.selectedToolBarSetting === 3 &&
								<form>
									<ButtonSettingsComponent appstore={appstore} widgetStore={widgetStore}  validation={validation} />
								</form>
							}
							{
								widgetStore.selectedToolBarSetting === 4 &&
								<InvitationSettingsComponent appstore={appstore} widgetStore={widgetStore} validation={validation} />
							}
						</div> 
						<div className={"col-12 col-xl-5" + (!appstore.menuActive ? ' col-lg-5' : '')}> 
						 
								<ToolBarPreviewComponent widgetStore={widgetStore} appstore={appstore} />
							 
								</div>  
						
					</div>
					<div className="row m-0">
						<div className="col-12 formBtns">
							<div className="pullRight">
								<Button className="btn_clr" variant="light" onClick={() => (this.clearToolbardata(appstore),widgetStore.getCode = false,
		widgetStore.selectedWidget = 0)}>
									<p>{appstore.getLanguageTrans("Cancel")}</p>
								</Button>
								<Button className="btn_save btn_inv_tour" onClick={(event) => this.handleFormSubmit(event)}>
									<p>{widgetStore.selectedToolBarSetting < 4 ? appstore.getLanguageTrans("Next step") : appstore.getLanguageTrans("Save & Get code")}</p>
								</Button>
							</div>
						</div>
					</div>
					{
						widgetStore.getCode &&
						<WidgetCodeComponent store={widgetStore} appstore={appstore} />
					}
				</div>
			</div>
		)
	}
})
export default ToolBarComponent;
