import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';

import InfoContentComponent from './tabs/InfoContentComponent';
import FootstepsContentComponent from './tabs/FootstepsContentComponent';
import HistoryContentComponent from './tabs/HistoryContentComponent';
import ResponsesContentComponent from './tabs/ResponsesContentComponent';

const ChatInfoComponent = observer(class ChatInfoComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }


	render(){
		const { appstore,
				chatComponentStore,
			    defaultTab } = this.props;
				let iscannedEnabled = appstore.userInfo.accountplan.canned_response;
		var self = this;

		return (
			<div key={defaultTab} className={iscannedEnabled!=1 ? 'chatInfoWrapper iscannedEnabled':'chatInfoWrapper'} >
				<Tabs defaultActiveKey={defaultTab ? defaultTab :"info"} id="uncontrolled-tab-example">
				  	
				  <Tab className="infoTab" eventKey="info" title="Info">
				  	<p className="hide_btn"><span onClick={() => chatComponentStore.hideInfoSection = !chatComponentStore.hideInfoSection}><svg  width="11" height="11" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>{!chatComponentStore.hideInfoSection ? appstore.getLanguageTrans("Hide") : appstore.getLanguageTrans("Show")}</span></p>
				  	<InfoContentComponent appstore={appstore} chatComponentStore={chatComponentStore}/>
				  </Tab>
				  <Tab eventKey="footsteps" title="Footsteps">
				  	<p className="hide_btn"><span onClick={() => chatComponentStore.hideInfoSection = !chatComponentStore.hideInfoSection}><svg  width="11" height="11" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>{!chatComponentStore.hideInfoSection ? appstore.getLanguageTrans("Hide") : appstore.getLanguageTrans("Show")}</span></p>
				  	<FootstepsContentComponent appstore={appstore} chatComponentStore={chatComponentStore}/>
				  </Tab>
				  <Tab eventKey="history" title="History">
				  	<p className="hide_btn"><span onClick={() => chatComponentStore.hideInfoSection = !chatComponentStore.hideInfoSection}><svg  width="11" height="11" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>{!chatComponentStore.hideInfoSection ? appstore.getLanguageTrans("Hide") : appstore.getLanguageTrans("Show")}</span></p>
				  	<HistoryContentComponent appstore={appstore} chatComponentStore={chatComponentStore}/>
				  </Tab>
				  <Tab eventKey="response" title="Responses">
				  	<p className="hide_btn"><span onClick={() => chatComponentStore.hideInfoSection = !chatComponentStore.hideInfoSection}><svg  width="11" height="11" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>{!chatComponentStore.hideInfoSection ? appstore.getLanguageTrans("Hide") : appstore.getLanguageTrans("Show")}</span></p>
				  	<ResponsesContentComponent chatComponentStore={chatComponentStore} appstore={appstore}/>
				  </Tab>
				</Tabs>

			</div>
		)
	}
})
export default ChatInfoComponent;
