import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button,Card} from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import axios from 'axios';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import cloneDeep from 'lodash/cloneDeep';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import CheckBoxCustomComponent from '../../../../sharedComponents/checkBox_2/checkBoxCustom2';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import ConfirmationComponent from '../../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import WarningComponent from '../../../../sharedComponents/confirmationPopup/WarningComponent';
import RadioButtonComponent from '../../../../sharedComponents/radionButton2/RadioButton';
import FormValidator from '../../../../sharedFiles/FormValidator';
import SettingsTableComponent from './components/settingsTable';
import ChatComponent from './components/chatComponent';
import AddSettingsStore from './AddSettingsStore';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import { observable } from 'mobx';
import ErrorMessageComponent from '../../../../sharedComponents/confirmationPopup/ErrorMessageComponent';
import { Prompt } from 'react-router'

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const AddSettingsComponent = observer(class AddSettingsComponent extends Component {

	constructor (props) {
        super(props);
        this.soundRef = React.createRef();
        this.addSettingsStore = new AddSettingsStore(this.props.appstore);

        this.validator = new FormValidator([
 			{
 				field: 'email',
 				method: 'isEmail',
 				validWhen: true, 
	        	message: 'Please enter a valid email.' 
 			}
	    ]);

	     this.state = {
			 validation: this.validator.valid(), 
			 websiteUid:this.props.appstore.selectedWebisiteUid,
			
	    }
	    this.submitted = false;
    }
	componentDidMount(){
		this.props.appstore.menuChanged = window.location.pathname;
		this.getPrechatFields();
		this.getWebsiteSounds();
	}

	componentWillUnmount() {
		this.addSettingsStore.confirm = true
    }

// 	componentWillReceiveProps(nextProps) {
// 		console.log(nextProps);
// 		alert("ws")
// 		if (this.props.appstore.selectedWebisiteUid !== nextProps.appstore.selectedWebisiteUid) {
// alert("componentWillReceiveProps");
// 		}
// 	}
	componentDidUpdate(prevProps, prevState) {
		  
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) { 
			this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});
			this.getPrechatFields();			  
			this.getWebsiteSounds();
			
			this.addSettingsStore.addChatFields=true;
			this.addSettingsStore.addOfflineChatFields=true;
		}
	}

	getPrechatFields = () =>{
        this.addSettingsStore.pageisLoaded = false;
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getcustomprechatfield.json',
			headers: { 'content-type': 'application/json' },
			data: {"website_uid" :this.props.appstore.selectedWebisiteUid}
		  }).then(result => {
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				  this.props.appstore.logout();
				}
				}
			  
			  if(result.data){
				//   console.log(result.data.prechat_fields);
				// Object.values return array
				this.addSettingsStore.chatFields = result.data.prechat_fields ? Object.values(JSON.parse(result.data.prechat_fields)): [];  //return array
				this.addSettingsStore.preChatEnabled = result.data.enablePrechat == 1 ? true : false;
				this.addSettingsStore.preChatEnabledVisitors = result.data.alwaysShowPrechat == 1 ? true : false;
				this.addSettingsStore.offline_action = result.data.offline_action;
				this.addSettingsStore.defaultMsg = result.data.offline_msg;
				this.addSettingsStore.link = result.data.offline_link;
				this.addSettingsStore.emailAction = result.data.send_transcripts == 1  ? true :false;
       			this.addSettingsStore.allowChat = result.data.sms_web_online == 1  ? true :false;
				this.addSettingsStore.setOfflineSetting(result.data);
				this.addSettingsStore.setDefaultValues();
				//clone objects for resetting on clicking cancel
				this.addSettingsStore.cancelChatFieldsClone = cloneDeep(this.addSettingsStore.chatFieldsClone);
				this.addSettingsStore.cancelOfflineChatFieldsClone = cloneDeep(this.addSettingsStore.offlineChatFieldsClone);
				this.addSettingsStore.cancelpreChatEnabled = result.data.enablePrechat == 1 ? true : false;
				this.addSettingsStore.cancelpreChatEnabledVisitors = result.data.alwaysShowPrechat == 1 ? true : false;
				this.addSettingsStore.canceloffline_action=result.data.offline_action;
				this.addSettingsStore.cancel_defaultMsg=result.data.offline_msg;
				this.addSettingsStore.cancel_link=result.data.offline_link;
				this.addSettingsStore.cancel_emailAction = result.data.send_transcripts == 1  ? true :false;
				this.addSettingsStore.cancel_allowChat = result.data.sms_web_online == 1  ? true :false;
				this.addSettingsStore.web_chat_routing=result.data.chat_routing; 
				this.addSettingsStore.chat_routing_pkg=this.addSettingsStore.chat_routing[this.addSettingsStore.chat_routing.filter(obj=>obj.value===result.data.chat_routing)[0].index];

			}
			  this.addSettingsStore.pageisLoaded = true;
			  this.props.appstore.global_errorcount=0;
		  }).catch((error) => {
			if (error.response) {
			//   console.log(error);
				//this.props.appstore.logout();
			}
			this.addSettingsStore.pageisLoaded = true;
			console.log(error);
			console.log("error count :"+this.props.appstore.global_errorcount);
			this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
			if(this.props.appstore.global_errorcount>20){
			// this.appstore.logout();
			}
		  })
	}
	cancleChatRouting = () => {   
		this.addSettingsStore.chat_routing_pkg=this.addSettingsStore.chat_routing[this.addSettingsStore.chat_routing.filter(obj=>obj.value===this.addSettingsStore.web_chat_routing)[0].index]; 
	}

	cancleSound = () => {
		//this.getWebsiteSounds();

		this.addSettingsStore.loopCount = JSON.parse(JSON.stringify(this.addSettingsStore.backupSoundSelections.loopCount));
		this.addSettingsStore.soundSelections = JSON.parse(JSON.stringify(this.addSettingsStore.backupSoundSelections.soundSelections));
		this.addSettingsStore.visitorsoundSelections = JSON.parse(JSON.stringify(this.addSettingsStore.backupvisitorSoundSelections.soundSelections));
	}

	getWebsiteSounds = () => {
		this.addSettingsStore.pageisLoaded = false;
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getwebsitesound.json',
			headers: { 'content-type': 'application/json' },
			data: { "website_uid": this.props.appstore.selectedWebisiteUid }
		}).then(result => { 
			let soundData = result.data
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			let storeSounds = this.addSettingsStore.soundSelections;
			let visitorStoreSounds = this.addSettingsStore.visitorsoundSelections;
			let i = 0;

			if (result.data.length === 0) {
				this.addSettingsStore.soundSelections = [
					{ soundType: 'chat_request', label: 'Chat request', selectedSound: this.addSettingsStore.chat_request[0], options: this.addSettingsStore.chat_request },
					{ soundType: 'message_received', label: 'Message received', selectedSound: this.addSettingsStore.message_received[0], options: this.addSettingsStore.message_received },
					{ soundType: 'message_sent', label: 'Message sent', selectedSound: this.addSettingsStore.message_sent[0], options: this.addSettingsStore.message_sent },
					{ soundType: 'new_visitor', label: 'New visitor on monitor', selectedSound: this.addSettingsStore.new_visitor[0], options: this.addSettingsStore.new_visitor }
				];

				this.addSettingsStore.visitorsoundSelections = [
					{soundType:'visitor_chat_invitation', label: 'Chat invitation', selectedSound: this.addSettingsStore.chat_invitation[0], options:this.addSettingsStore.chat_invitation},
					{soundType:'visitor_message_received', label: 'Message received', selectedSound: this.addSettingsStore.visitor_message_received[0], options:this.addSettingsStore.visitor_message_received},
					{soundType:'visitor_message_sent', label: 'Message sent', selectedSound: this.addSettingsStore.message_sent[0], options:this.addSettingsStore.message_sent},
				   ];

			}  
			for (let index in soundData) { 
				if (soundData[index].soundType == "loop_count") {
					this.addSettingsStore.loopCount = soundData[index].package_sound != "" ? soundData[index].package_sound : '';
					i++;
					continue;
				}
				//sounds setting
				if (storeSounds[0].soundType === soundData[i].soundType) {
					 
					let matched = 0;
					for (let j in storeSounds[0].options) {
						if (storeSounds[0].options[j].index == soundData[i].package_sound) {
							matched = 1;
							this.addSettingsStore.soundSelections[0].selectedSound = storeSounds[0].options[j];

						}

						if (matched == 1)
							break;
					}
				}

				if (storeSounds[1].soundType === soundData[i].soundType) {
					let matched = 0;
					for (let j in storeSounds[1].options) {
						if (storeSounds[1].options[j].index == soundData[i].package_sound) {
							matched = 1;
							this.addSettingsStore.soundSelections[1].selectedSound = storeSounds[1].options[j];

						}

						if (matched == 1)
							break;
					}
				}

				if (storeSounds[2].soundType === soundData[i].soundType) {
					let matched = 0;
					for (let j in storeSounds[2].options) {
						if (storeSounds[2].options[j].index == soundData[i].package_sound) {
							matched = 1;
							this.addSettingsStore.soundSelections[2].selectedSound = storeSounds[2].options[j];

						}

						if (matched == 1)
							break;
					}
				}

				if (storeSounds[3].soundType === soundData[i].soundType) {
					let matched = 0;
					for (let j in storeSounds[3].options) {
						if (storeSounds[3].options[j].index == soundData[i].package_sound) {
							matched = 1;
							this.addSettingsStore.soundSelections[3].selectedSound = storeSounds[3].options[j];

						}

						if (matched == 1)
							break;
					}
				}
				//visitor sounds

				if (visitorStoreSounds[0].soundType === soundData[i].soundType) { 
					let matched = 0;
					for (let j in visitorStoreSounds[0].options) {
						//console.log(visitorStoreSounds[0].options[j]);
						if (visitorStoreSounds[0].options[j].index == soundData[i].package_sound) {
							matched = 1;
							this.addSettingsStore.visitorsoundSelections[0].selectedSound = visitorStoreSounds[0].options[j];

						}

						if (matched == 1)
							break;
					}
				}

				if (visitorStoreSounds[1].soundType === soundData[i].soundType) {
					let matched = 0;
					for (let j in visitorStoreSounds[1].options) {
						if (visitorStoreSounds[1].options[j].index == soundData[i].package_sound) {
							matched = 1;
							this.addSettingsStore.visitorsoundSelections[1].selectedSound = visitorStoreSounds[1].options[j];

						}

						if (matched == 1)
							break;
					}
				}

				if (visitorStoreSounds[2].soundType === soundData[i].soundType) {
					let matched = 0;
					for (let j in visitorStoreSounds[2].options) {
						if (visitorStoreSounds[2].options[j].index == soundData[i].package_sound) {
							matched = 1;
							this.addSettingsStore.visitorsoundSelections[2].selectedSound = visitorStoreSounds[2].options[j];

						}

						if (matched == 1)
							break;
					}
				}

				i++;
			}
			this.addSettingsStore.soundcloned = {
				loopCount: this.addSettingsStore.loopCount,
				soundSelections: this.addSettingsStore.soundSelections
			}

			this.addSettingsStore.visitorsoundcloned = {
				soundSelections: this.addSettingsStore.visitorsoundSelections
			}
			this.addSettingsStore.backupSoundSelections = JSON.parse(JSON.stringify(this.addSettingsStore.soundcloned));


			this.addSettingsStore.backupvisitorSoundSelections = JSON.parse(JSON.stringify(this.addSettingsStore.visitorsoundcloned));
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	 this.props.appstore.logout();
			// }
			console.log(error);
			console.log("error count :"+this.props.appstore.global_errorcount);
			this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
			if(this.props.appstore.global_errorcount>20){
			// this.appstore.logout();
			}
		})

		
	}

	// playSound(sound){
	// 	if(sound) {
	// 		var self = this;
	// 		this.addSettingsStore.soundPlaying = this.props.appstore.soundurl+sound;
	// 		setTimeout(()=>{
	// 			self.soundRef.current.play();
	// 		},1000);
	// 	}
	// }

	checkForEnteredNumer(event) {
		let val;

		var key = window.event ? event.keyCode : event.which;

		if (event.keyCode == 8 || event.keyCode == 46
			|| event.keyCode == 37 || event.keyCode == 39) {
			val = true;
		}
		// else if (key < 48 || key > 57) {
		// 	val = false;
		// }
		else if (((key < 48 || (key <96 && key>57) ) || key > 105) || event.shiftKey) {
			val = false;
		}
		else val = true;
		if (!val) {
			event.preventDefault();
		}
	}

	handleFormSubmit(event) {
		event.preventDefault();
if( this.addSettingsStore.offline_action==="link"){
	if(this.addSettingsStore.link.length==0){
		this.addSettingsStore.linkerror=true; 
		return;
	}else{
		this.addSettingsStore.linkerror=false;
	}
}

		this.submitted = true;
		if (this.addSettingsStore.emailAction) {
			const validation = this.validator.validate({ email: this.addSettingsStore.email });
			this.setState({ validation });
			if (validation.isValid) {
				this.addSettingsStore.saveOfflineChatSettings(this.props.appstore)
			}else{
				window.scrollTo(0, 900);
			}
		} else {
				// handle actual form submission here
				this.addSettingsStore.saveOfflineChatSettings(this.props.appstore)
			} 
	    
	}

	 componentWillUnmount() {
		window.scrollTo(0, 0);
    }


	removeError =(event,value) =>{
		
		event.preventDefault();

		if(value.trim() ===""){
		   this.addSettingsStore.emailTranscriptError.error = false;
		   window.scrollTo(0,900);
		   return;
		}
		if(this.props.appstore.validateEmail(value)){
			this.addSettingsStore.emailTranscriptError.error = false;
		
		}
		else
		{
			this.addSettingsStore.emailTranscriptError.error = true;
			
		}
		window.scrollTo(0,900); 
		   
    }

	render(){ 
		const {appstore} = this.props;
		let self = this;
		const excss = {
			pointerEvents: "none",
			opacity: 0.3,
            };
			const nocss = {};	
        if(!this.addSettingsStore.pageisLoaded){
			return(<div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={appstore.selectedColor.color}
				/>
		    </div>)
		}
		
		// let validation = this.submitted ?                         // if the form has been submitted at least once
        //               this.validator.validate({email: this.addSettingsStore.email}) :   // then check validity every time we render
		// 			  this.state.validation  

		return (
			<div key={appstore.selectedWebisiteUid} className="innercontent_wrapper w-100 scriptWrapper widgetSettingsWrapper">
				<div className="header_title row align-items-center m-0">
						<img src={imageURLs.settingsIcon} alt="File Transfer" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("Widget Settings")}</h4>
				</div>

				<div className="row with-header section chatSettings">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Pre-chat")} {appstore.getLanguageTrans("settings")}</h4>
							</div>
							<div className="contentWrapper">
									<div className="row">
										<div className="col-xs-12 col-sm-8 pr-0">
											<SettingsTableComponent settingsStore = {this.addSettingsStore}
																	chatFields = {this.addSettingsStore.chatFieldsClone}
																	type={1}
																	appstore = {appstore} />
											<Form>

												<Form.Group as={Row} controlId="formGrouppreChatFields" className="align-items-center">
												    <Form.Label column xs="6" sm="4">{appstore.getLanguageTrans("Show pre-chat fields")}</Form.Label>
												    <Col className="custom_radioBtn">
												    	<CheckBoxComponent radioValue={this.addSettingsStore.preChatEnabled}
												    							callBack = {() => this.addSettingsStore.preChatEnabled = !this.addSettingsStore.preChatEnabled}/>
												    </Col>
												  </Form.Group>	

												<Form.Group as={Row} controlId="formGroupOfflineMsg" className="align-items-center">
												    <Form.Label column xs="6" sm="4">{appstore.getLanguageTrans("Enable pre-chat fields for invited visitors")}</Form.Label>
												    <Col className="custom_radioBtn">
												    	<CheckBoxComponent radioValue={this.addSettingsStore.preChatEnabledVisitors}
												    							callBack = {() => this.addSettingsStore.preChatEnabledVisitors = !this.addSettingsStore.preChatEnabledVisitors}/>
												    </Col>
												 </Form.Group>
											</Form>
										</div> 
										<div className="preview col-4 d-none d-sm-block" id="chatPreview" >
											<ChatComponent settingsStore={this.addSettingsStore}
															fields = {this.addSettingsStore.chatFields}
															appstore={appstore} />
										</div>
									</div>
									  <div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light" onClick={() => this.addSettingsStore.cancelPreChatSettings()}>
											    	<p>{appstore.getLanguageTrans("Cancel")}</p>
											    </Button>
											    <Button className="btn_save" onClick={() => this.addSettingsStore.savePreChatSettings(appstore)}>
											    	<p>{appstore.getLanguageTrans("Save")}</p>
											    </Button>
											</div>
										</div>
									</div>
							</div>
							
						</div>
					</div>
				</div>

				<div className="row with-header section chatSettings"  id="offlinePreview">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Offline settings")}</h4>
							</div>
							<div className="contentWrapper">
									<div className="row">

										<div className="col-xs-12 col-sm-8 pr-0">
											<Form>
												<Form.Group as={Row} controlId="formGroupLead">
												    <Form.Label column  xs="12" lg="4" sm="4">{appstore.getLanguageTrans("When offline")}</Form.Label>
												    <Col xs="12" sm="8" lg="8" className="radioBtnGrp">
												    	<div className="row m-0"> 
															<RadioButtonComponent selectedOption = {this.addSettingsStore.offline_action}
																	key={Math.random()}
												    				options={this.addSettingsStore.offlineActionObj}
												    				groupName={"offlineaction"}
												    				callBack={(selectedOption) =>{this.addSettingsStore.offline_action = selectedOption}}/>
												    	</div>
															{
															this.addSettingsStore.offline_action === 'link' && 
															<React.Fragment>
													    		<Form.Control type="text" placeholder={appstore.getLanguageTrans("Enter URL")} 
														    		className={'col-12'}
											    					name="link"
											    					value={this.addSettingsStore.link} 
											    					onChange={(e) => this.addSettingsStore.link = e.target.value}/>
																	
																	{ this.addSettingsStore.linkerror==true &&
																	<span className="error-block">{appstore.getLanguageTrans("required field*")}</span>
																	}
															</React.Fragment>
												    	}
												    	
												    	
												    </Col>
											  	</Form.Group>

											  	<Form.Group as={Row} controlId="formGroupMsg">
												    <Form.Label column lg="4" sm="4">{appstore.getLanguageTrans("Default offline message")}</Form.Label>
												    <Col sm="8" lg="8">
												    	<Form.Control type="text" name="defaultMsg" placeholder={appstore.getLanguageTrans("Enter Message")} 
																		value={this.addSettingsStore.defaultMsg} 
												    					onChange={(e) => this.addSettingsStore.defaultMsg = e.target.value} />
												    </Col>
												  </Form.Group>

													<Form.Group as={Row} controlId="formGroupEmail">
													    <Form.Label column lg="4" sm="4">{appstore.getLanguageTrans("Email to collect left messages and transcripts")}</Form.Label>
													    <Col sm="8" lg="8">
														{/* className={validation.email.isInvalid && 'has-error'} */}
													    	<Form.Control type="text"  
													    				   name="email" placeholder={appstore.getLanguageTrans("Enter Email")}
																			value={this.addSettingsStore.email} 
																			onBlur = {(e)=>  this.removeError(e,e.target.value)}
													    					onChange={(e) => {this.addSettingsStore.email = e.target.value;}} />
													    	<span className="error-block">{( this.addSettingsStore.emailTranscriptError.error) && this.addSettingsStore.emailTranscriptError.msg}</span>
													    </Col>
												  </Form.Group>
												  <Form.Group as={Row} controlId="formGroupEmailAction" className="align-items-center">
												    <Form.Label column xs="6" sm="4">{appstore.getLanguageTrans("Automatically email all transcripts")}</Form.Label>
												    {appstore.userInfo.accountplan.send_transcripts!=1 &&
													<span data-tooltip={appstore.getLanguageTrans("Upgrade the plan")} data-tooltip-conf="top" $tooltip-base-bg-color="black">
													<Col className="custom_radioBtn" style={excss}>
												    	<CheckBoxComponent radioValue={this.addSettingsStore.emailAction} disabled={true}
												    							callBack = {() => this.addSettingsStore.emailAction = !this.addSettingsStore.emailAction}/>
												    </Col>
													</span>}
													{appstore.userInfo.accountplan.send_transcripts!=0 &&
								
													<Col className="custom_radioBtn" style={nocss}>
												    	<CheckBoxComponent radioValue={this.addSettingsStore.emailAction} disabled={true}
												    							callBack = {() => this.addSettingsStore.emailAction = !this.addSettingsStore.emailAction}/>
												    </Col>}
												 </Form.Group>
												 {
												 	this.addSettingsStore.offline_action !== 'link' && 
												 		<SettingsTableComponent settingsStore = {this.addSettingsStore}
																		chatFields = {this.addSettingsStore.offlineChatFieldsClone}
																		type = {2}
																		appstore = {appstore} />
												 }
												{/* {
												 	appstore.userInfo.accountsmodel.sms == 1 && 
												<Form.Group as={Row} controlId="formGroupAllowChat" className="align-items-center">
												    <Form.Label column xs="6" sm="4" className="pr-0">{appstore.getLanguageTrans("Allow Chat via SMS only if no one available on desktop")}</Form.Label>
												    <Col className="custom_radioBtn">
												    	<CheckBoxComponent radioValue={this.addSettingsStore.allowChat}
												    							callBack = {() => this.addSettingsStore.allowChat = !this.addSettingsStore.allowChat}/>
												    </Col>
												 </Form.Group>
												} */}
											</Form>
										</div>
										<div className="preview col-4 d-none d-sm-block">
										{
											this.addSettingsStore.offline_action !== 'link' && 
												<ChatComponent settingsStore={this.addSettingsStore}
												             appstore={appstore}
															fields = {this.addSettingsStore.offlineChatFields} />
										}
										</div>
									</div>
									  <div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light" onClick={() => this.addSettingsStore.cancelOfflineChatSettings()}>
											    	<p>{appstore.getLanguageTrans("Cancel")}</p>
											    </Button>
											    <Button className="btn_save" onClick={(event) => this.handleFormSubmit(event)}>
											    	<p>{appstore.getLanguageTrans("Save")}</p>
											    </Button>
											</div>
										</div>
									</div>
							</div>
							
						</div>
					</div>
				</div>

				<div className="row with-header section soundSettings" >
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Sounds")}</h4>
							</div>
							<span data-tooltip={appstore.userInfo.accountplan.custom_sounds!=1?appstore.getLanguageTrans("Upgrade your plan"):""} data-tooltip-conf="top">
							<div className="contentWrapper" style={appstore.userInfo.accountplan.custom_sounds!=1?excss:nocss}>
								{/* <audio className="d-none" ref={this.soundRef} src={this.addSettingsStore.soundPlaying} /> */}
								<Form>
									<Form.Group as={Row} controlId="formGroupSoundPackage" className="sound-grp align-items-center">
										<Form.Label column sm="3" lg="3">{appstore.getLanguageTrans("Set sounds to package")}</Form.Label>
										<Col xs="5" sm="5" lg="5">
											<div className="customDrpDwn">
												<DropDownComponent dropDownOptions={this.addSettingsStore.sounds}
													store={this.addSettingsStore}
													callBack={(selectedOption) => this.addSettingsStore.soundsToPackage(selectedOption)}
													selectedOption={this.addSettingsStore.soundPackage}
												/>


											</div>
										</Col>
									</Form.Group>
									<div className="soundPackageSection">
									<fieldset style={{border:"none"}}>
										      <legend style={{textAlign: "left",fontSize: "14px",fontWeight: 600}}>Sound settings</legend>
										{
											this.addSettingsStore.soundSelections.map(function (soundSection, soundIndex) {
												return (
													<Form.Group as={Row} key={soundIndex} controlId={"formGroupSound" + soundIndex} className="sound-grp align-items-center">
														<Form.Label column sm="3" lg="3">{appstore.getLanguageTrans(soundSection.label)}</Form.Label>
														<Col xs="5" sm="5" lg="5">
															<div className="customDrpDwn">
																<DropDownComponent dropDownOptions={soundSection.options}
																	key={Math.random()}
																	store={self.addSettingsStore}
																	callBack={(selectedOption) => soundSection.selectedSound = selectedOption}
																	selectedOption={soundSection.selectedSound} /> 
																<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.play_steel_blue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.play_sea_green : "#ec407a" == appstore.selectedColor.color ?  imageURLs.play_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.play_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.play_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.play_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.play_voilet : 
									                             "#039be6" == appstore.selectedColor.color ? imageURLs.play_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.play_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.play_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.play_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.play_sky_blue : imageURLs.play_steel_blue} alt="profile" className="img-fluid" onClick={() => appstore.playChattingSound(soundSection.selectedSound.value, self.addSettingsStore.loopCount,soundSection.soundType)} />

															</div>
														</Col>
														{soundIndex === 0 &&
															<React.Fragment>
																<Form.Label column sm="2" lg="2">{appstore.getLanguageTrans("Loop Count")}</Form.Label>
																<Col xs="2" sm="2" lg="2" >
																	<Form.Control type="text" placeholder="Count"
																		name="loopCount"
																		maxLength="2"
																		style={{ width: "65px" }}
																		value={self.addSettingsStore.loopCount}
																		className="loopCount"
																		disabled={!self.addSettingsStore.overrideSounds}
																		onKeyDown={(e) => { self.checkForEnteredNumer(e) }}
																		onChange={(e) => self.addSettingsStore.loopCount = e.target.value} />

																</Col>
															</React.Fragment>
														}
													</Form.Group>
												)
											})
										}
										</fieldset>
									</div>
									<div className="soundPackageSection">
									<fieldset style={{border:"none"}}>
										      <legend style={{textAlign: "left",fontSize: "14px",fontWeight: 600}}>Visitors sounds</legend>
										{
											this.addSettingsStore.visitorsoundSelections.map(function (soundSection, soundIndex) {
												return (
													<Form.Group as={Row} key={soundIndex} controlId={"formGroupSound" + soundIndex} className="sound-grp align-items-center">
														<Form.Label column sm="3" lg="3">{appstore.getLanguageTrans(soundSection.label)}</Form.Label>
														<Col xs="5" sm="5" lg="5">
															<div className="customDrpDwn">
																<DropDownComponent dropDownOptions={soundSection.options}
																	key={Math.random()}
																	store={self.addSettingsStore}
																	callBack={(selectedOption) => soundSection.selectedSound = selectedOption}
																	selectedOption={soundSection.selectedSound} /> 
																<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.play_steel_blue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.play_sea_green : "#ec407a" == appstore.selectedColor.color ?  imageURLs.play_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.play_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.play_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.play_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.play_voilet : 
									                             "#039be6" == appstore.selectedColor.color ? imageURLs.play_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.play_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.play_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.play_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.play_sky_blue : imageURLs.play_steel_blue} alt="profile" className="img-fluid" onClick={() => appstore.playChattingSound(soundSection.selectedSound.value, self.addSettingsStore.loopCount,soundSection.soundType)} />

															</div>
														</Col> 
													</Form.Group>
												)
											})
										}
										</fieldset>
									</div>
								</Form>

								<div className="row m-0">
									<div className="col-12 formBtns">
										<div className="pullRight">
											<Button className="btn_clr" variant="light" onClick={(e) => self.cancleSound(e)}>
												<p>{appstore.getLanguageTrans("Cancel")}</p>
											</Button>
											<Button className="btn_save" onClick={(e) => this.addSettingsStore.saveProfileSound(e, appstore)}>
												<p>{appstore.getLanguageTrans("Save")}</p>
											</Button>
										</div>
									</div>
								</div>
							</div>
							</span>
						</div>
					</div>
				</div>
				<div className="row with-header section soundSettings">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Chat routing")}</h4>
							</div>
							<span data-tooltip={appstore.userInfo.accountplan.chat_routing!=1?appstore.getLanguageTrans("Upgrade your plan"):""} data-tooltip-conf="top">
							<div className="contentWrapper" style={appstore.userInfo.accountplan.chat_routing!=1?excss:nocss}>
								<Form>
									<Form.Group as={Row} controlId="formGroupSoundPackage" className="sound-grp align-items-center">
										<Form.Label column sm="3" lg="3">{appstore.getLanguageTrans("Set chat routing")}</Form.Label>
										<Col xs="5" sm="5" lg="5">
											<div className="customDrpDwn">
												<DropDownComponent dropDownOptions={this.addSettingsStore.chat_routing}
													store={this.addSettingsStore}
													callBack={(selectedOption) => this.addSettingsStore.chatroutingselected(selectedOption)}
													selectedOption={this.addSettingsStore.chat_routing_pkg}
													key={Math.random()}
												/>
											</div>
										</Col>
									</Form.Group> 
								</Form>

								<div className="row m-0">
									<div className="col-12 formBtns">
										<div className="pullRight">
											<Button className="btn_clr" variant="light" onClick={(e) => self.cancleChatRouting(e)}>
												<p>{appstore.getLanguageTrans("Cancel")}</p>
											</Button>
											<Button className="btn_save" onClick={(e) => this.addSettingsStore.saveChatrouting(e, appstore)}>
												<p>{appstore.getLanguageTrans("Save")}</p>
											</Button>
										</div>
									</div>
								</div>
							</div>
							</span>
						</div>
					</div>
				</div>
				{
					this.addSettingsStore.errorMessageComponent &&
					<ErrorMessageComponent show={this.addSettingsStore.errorMessageComponent}
						store={this.addSettingsStore}
						title={"<p style='color:red'>Error Message</p>"}
						body_html={"<p>"+this.addSettingsStore.errorMessage+"</p>"}
						cancel_btn="Ok" 
						// callBack={(event) => this.confirmDeleteRule(event)}
						closePopup={() => {this.addSettingsStore.errorMessageComponent = false;this.addSettingsStore.errorMessage=""}}
						appStore={appstore} />
				}
				{ 
					this.addSettingsStore.confirmDelete && 
					<ConfirmationComponent show={this.addSettingsStore.confirmDelete}
											store={this.addSettingsStore}
											title="Delete Field"
											body_html="<p>Are you sure you want to delete the selected field?</p>"
											cancel_btn="Cancel"
											save_btn="Confirm" 
											callBack={() => this.addSettingsStore.deleteField() }
											closePopup={() => this.addSettingsStore.confirmDelete = false}
											appStore = {appstore}/>
				}

				{
					this.addSettingsStore.confirm && 
					<ConfirmationComponent show={this.addSettingsStore.confirm}
											store={this.addSettingsStore}
											title="Delete Files"
											body_html="<p>Some changes have not been saved. They will be lost if you leave the page now. Click Back to review and save the changes.</p>"
											cancel_btn="Back"
											save_btn="Confirm" 
											//callBack={() => this.addSettingsStore.confirmAction(this.props.appstore)}
											closePopup={() => this.addSettingsStore.confirm = false}
											appStore = {appstore}/>
				}

				{
				   this.addSettingsStore.errormodel &&
						<WarningComponent show={this.addSettingsStore.errormodel}
							store={this.addSettingsStore}
							cancel_btn="OKAY"
							closePopup={() => this.addSettingsStore.errormodel = false}
							appStore={appstore} />
				}

			</div>
		)
	}
})
export default AddSettingsComponent;
