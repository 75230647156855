import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button,Card} from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../sharedFiles/ImagesURLs';
import WarningComponent from '../../../../sharedComponents/confirmationPopup/WarningComponent';

import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import CheckBoxCustomComponent from '../../../../sharedComponents/checkBox_2/checkBoxCustom2';
import ConfirmationComponent from '../../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import FileTransferStore from './FileTransferStore';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`


const FileTransferComponent = observer(class FileTransferComponent extends Component {

	constructor (props) {
        super(props);
		this.fileTransferStore = new FileTransferStore(this.props.appstore);
		this.state = {
			  websiteUid:this.props.appstore.selectedWebisiteUid,
			  location :  window.location.pathname
			}
    }
	componentDidMount(){
		this.props.appstore.menuChanged = window.location.pathname;
		this.fileTransferStore.getallFiles(this.props.appstore);
	}

		
	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) { 
			this.fileTransferStore.ispageLoaded = false;
			this.fileTransferStore.getallFiles(this.props.appstore);
			this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});			  
		}

	 }

	 selectComponent(fileData,index) {
	 	fileData.selected = !fileData.selected;
	 	let checkForFile = this.fileTransferStore.deleteFileArray.indexOf(index);
	 	if(checkForFile === -1) {
	 		this.fileTransferStore.deleteFileArray.push(index);
	 	}
	 	else {
	 		this.fileTransferStore.allSelected = false;
	 		this.fileTransferStore.deleteFileArray.splice(checkForFile,1);
	 	}
	 }

	 
	 componentWillUnmount() {
		window.scrollTo(0, 0);
    }

	getFileName = (name,index) =>{
	  const data =	this.fileTransferStore.responseData.transferredFiles;
	  let count = 1;
	  for(let i=0; i< data.length; i++){
		 if(i==index){
		   break;
	 	}
		 if(name === data[i].name){
           count++;
		}
	  }

	  if(count==1)
		 return name;
	  else	 
	     return name+"("+count+")";
	}

	render(){
		const {appstore} = this.props;
		let self = this;
		if(!this.fileTransferStore.ispageLoaded){
			return(<div className="adminloader">
						<GridLoader
							css={override}
							size={20}
							marging={2}
							color={appstore.selectedColor.color}
						/>
					</div>)
		}

		return (
			<div id={appstore.selectedWebisiteUid} className="innercontent_wrapper w-100 scriptWrapper">
				<div className="header_title row align-items-center m-0">
						<img src={imageURLs.filePageIcon} alt="File Transfer" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("File Transfer : Website settings")}</h4>
				</div>
				<div className="row fileTransferWrapper">
					<div className="col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form>
								<Form.Group as={Row} controlId="formGroupLanguage">
								    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("File transfer")}</Form.Label>
								    <Col sm="8" lg="9" className="customDrpDwn">
								    	<DropDownComponent dropDownOptions={this.fileTransferStore.fileTransferDrpDwn} 
								    					   store={this.fileTransferStore}
								    					   callBack={(selectedOption) => this.fileTransferStore.fileTransferType = selectedOption}
								    					   selectedOption = {this.fileTransferStore.fileTransferType}/>
								    </Col>
								  </Form.Group>
							
								{/* <Form.Group as={Row} controlId="formGroupLanguage">
								    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Canned files lifetime")}</Form.Label>
								    <Col sm="8" lg="9" className="customDrpDwn">
								    	<DropDownComponent dropDownOptions={this.fileTransferStore.fileLifeTimeDrpDwn} 
								    					   store={this.fileTransferStore}
								    					   callBack={(selectedOption) => this.fileTransferStore.cannedFileLifeTimeType = selectedOption}
								    					   selectedOption = {this.fileTransferStore.cannedFileLifeTimeType}/>
								    </Col>
								  </Form.Group> */}
								  <Form.Group as={Row} controlId="formGroupLanguage">
								    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Transferred files lifetime")}</Form.Label>
								    <Col sm="8" lg="9" className="customDrpDwn">
								    	<DropDownComponent dropDownOptions={this.fileTransferStore.fileLifeTimeDrpDwn} 
								    					   store={this.fileTransferStore}
								    					   callBack={(selectedOption) => this.fileTransferStore.transFileLifeTimeType = selectedOption}
								    					   selectedOption = {this.fileTransferStore.transFileLifeTimeType}/>
								    </Col>
								  </Form.Group>
								  <Form.Group as={Row} controlId="formGroupLanguage" className="align-items-center">
								    <Col sm="12" lg="12" className="customField">
								    	<div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button style={{backgroundColor: "#3ba8c5"}} className="btn_clr save_BTN" variant="light" onClick={() => this.fileTransferStore.saveFiledata(appstore)}>
											    	<p style={{color:"#fff",marginTop:1,fontFamily: 'Segoe UI Bold'}} >{appstore.getLanguageTrans("Save")}</p>
											    </Button>
											    
											</div>
										</div>
									</div>
								    </Col>
								  </Form.Group>
							</Form>
							<div className="col-12 p-0">
								<div className="headerTitle">
									<h4 className="text-left">{appstore.getLanguageTrans("Transferred files (for all websites)")}</h4>
								</div>
								<div className="row fileTransferWrapper">
								  <div className="col-12 w-100 pr-0">
									<div className="settingFormWrapper">
										<form>
										<Form.Group as={Row} controlId="formGroupLanguage" className="align-items-center">
											<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Used Space")}</Form.Label>
											<Col sm="8" lg="9" className="customField">
												<div className="row">
													<div className="col-12">
														<p>{this.fileTransferStore.responseData.freeSpace}{this.fileTransferStore.responseData.spaceUnit +" "} 
															of {this.fileTransferStore.responseData.totalSpace}{this.fileTransferStore.responseData.spaceUnit +" "} 
															is available
															<img style={{cursor:"pointer"}} onClick={() => this.fileTransferStore.getStoragespace(appstore)} className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.refresh_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.refresh_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.refresh_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.refresh_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.refresh_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.refresh_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.refresh_voilet : 
																					"#039be6" == appstore.selectedColor.color ? imageURLs.refresh_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.refresh_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.refresh_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.refresh_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.refresh_skyblue : imageURLs.refresh_steelblue} alt="" />
															
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="progress_wrapper">
															<div className="progress" style={{width : this.fileTransferStore.percenSpaceUsed + "%"}}>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Form.Group>
										</form>
								     </div>
								   </div>
								 </div>		
								<div className="scroll_row row m-0">
									<div id="panelGroup" className="panelWrapper col-12 col-lg-12 p-0">
										<div className="col-12">
											<div className="row panelHeader align-items-center">
												<div className="col-1 headerCheck">
													<CheckBoxCustomComponent radioValue={this.fileTransferStore.allSelected}
									    							callBack = {() => this.fileTransferStore.selectAll()}/>
												</div>
												<div className="col-2 pl-0">
													<p className="text-center">{appstore.getLanguageTrans("Sent / Received")}</p>
												</div>
												<div className="col-2 col-xl-3">
													<p className="text-left">{appstore.getLanguageTrans("Name")}</p>
												</div>
												<div className="col-2">
													<p className="text-left">{appstore.getLanguageTrans("Size")}</p>
												</div>
												<div className="col-2">
													<p className="text-left">{appstore.getLanguageTrans("Date")}</p>
												</div>
												<div className="col-3 col-xl-2">
													<p className="text-left"></p>
												</div>
											</div>
										</div>
										<div className="scrollWrapper col-12 p-0">
											<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
												<div className="scriptPanel row">
													<div className="col-12">
													{
														this.fileTransferStore.responseData.transferredFiles.map(function(fileData,dataIndex){
															return (
																// key={dataIndex}
																<div className="row action panelContent align-items-center" key={"transferredFiles"+dataIndex} >
																	<div className="col-1">
																		<CheckBoxCustomComponent radioValue={fileData.selected}
										    							callBack = {() => self.selectComponent(fileData,dataIndex)}/>
										   
																	</div>
																	<div className="col-2 pl-0">
																		<p className="text-center">
																			{
																				fileData.sent === 0 &&
																					<img className="img-fluid  img-wrapper download" src={imageURLs.downloadIcon} alt=""/>
																			
																			}
																			{
																				fileData.sent === 1 &&
																					<img className="img-fluid  img-wrapper upload" src={imageURLs.uploadIcon} alt=""/>
																			}
																		</p>
																	</div>
																	<div className="col-2 col-xl-3">
																		<p className="text-left" style={{ wordWrap: "break-word"}}>{self.getFileName(fileData.name,dataIndex)} </p>
																	</div>
																	<div className="col-2">
																		<p className="text-left">{fileData.size} </p>
																	</div>
																	<div className="col-2">
																		<p className="text-left">{fileData.date} </p>
																	</div>
																	<div className="col-3 col-xl-2">
																	<a onClick={() => self.fileTransferStore.downloadingfile(fileData.fileUrl,fileData.name,appstore)} target="_blank" download>
																			<p className="text-right text-uppercase action_text"><img className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.download_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.download_voilet : 
																					"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.download_steelblue} alt=""/>{appstore.getLanguageTrans("Download")}</p>
																		</a>
																	</div>
																</div>
															);
														})
													}
													</div>
												</div>
											</CustomScroll>
										</div>
									
									
									</div>
								</div>
								{this.fileTransferStore.deleteFileArray.length>0 && 
									<div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light" onClick={() => this.fileTransferStore.setInitialValues()}>
											    	<p>{appstore.getLanguageTrans("Clear selection")}</p>
											    </Button>
											    <Button className="btn_save btn_del" onClick={() => this.fileTransferStore.confirm = true }>
											    	<p>{appstore.getLanguageTrans("Delete selected files")}</p>
											    </Button>
											</div>
										</div>
									</div>
								}
							</div>
						</div>

						
					</div>
				</div>
				{
					this.fileTransferStore.confirm && 
					<ConfirmationComponent show={this.fileTransferStore.confirm}
											store={this.fileTransferStore}
											title="Delete Files"
											body_html="<p>Are you sure you want to delete the selected files?</p>"
											cancel_btn="Cancel"
											save_btn="Confirm" 
											callBack={() => this.fileTransferStore.confirmAction(this.props.appstore)}
											closePopup={() => this.fileTransferStore.confirm = false}
											appStore = {appstore}/>
				}

			{ this.fileTransferStore.errormodel &&
					<WarningComponent show={this.fileTransferStore.errormodel}
											store={this.fileTransferStore}  
											cancel_btn="OK"  
											closePopup={() => this.fileTransferStore.errormodel = false}
											appStore = {appstore} />
				}

			</div>
		)
	}
})
export default FileTransferComponent;
