import React, { Component } from 'react';
import { decorate, observable, extendObservable } from 'mobx';
import {data} from '../../../assets/JSON/admin_archivechat';
import axios from 'axios';


class ReportPerformanceStore {


    constructor(appstore){
      
     // this.isPageLoaded=false;
        this.fileTransferDrpDwn = [
              {value:3, label:'Use account defaults',id:null},
              {value:1, label:'Enable',id:"all"},
              {value:2, label:'Enable for operators only',id:"operators"},
              {value:0, label:'Disable',id:"none"}
              ];
       // this.ispageLoaded = false;

        this.fileLifeTimeDrpDwn = [
              {value:2, label:'Use account defaults',id:null},   
              {value:1, label:'Permanent',id:'persistent'},
              {value:0, label:'Until chat session ends',id:'talk_session'},
              ];

         this.responseData = data[0];
         this.confirm = false;
         this.export= false;
         this.datepicker= false;
        this.selectedfileLifeTimeDrpDwn =  this.fileLifeTimeDrpDwn[0]; 
        this.selectedfileTransferDrpDwn =  this.fileTransferDrpDwn[0];
        this.websiteDrpDwnOptions = [];
        this.allDepartments =[];    
        this.allWebsites =[]; 
        this.exportdata=[];
        this.Pickupexportdata=[];
        this.invitationexportdata=[];
        this.pageLoder= false;
        this.deptDrpDwnOptions=[{"label":"All","value":0,"id":0,"optionType":"deptAll","department_uid":""}];
        this.alldepartment=this.deptDrpDwnOptions[0];
        // this.setDropDownValues(appstore);
         //this.setInitialValues();
   }



setWebsites(websitesmodel,appstore) {
      let websites = JSON.parse(JSON.stringify(websitesmodel));
    
      if(websites !=null){

        let all = {"label":"All","value":0,"id":0,"optionType":"websiteAll","website_uid":""};
        websites.splice(0, 0,all);   //insert All option in dropdown options
        let x;
        let index =0;
        for (x in websites)
        {
          if(x>0){
          websites[x].label=websites[x].name;
          websites[x].value= index;
          }
          index++;
        }
       
         this.websiteDrpDwnOptions = websites;
         this.allwebsite = this.websiteDrpDwnOptions[0]

      }
     }

     setDepartments(departmentsmodel,appstore) {
      let departments = departmentsmodel;
  
      if(departments !=null){

        let all = {"label":"All","value":0,"id":0,"optionType":"deptAll","department_uid":""};
        departments.splice(0, 0,all);   //insert All option in dropdown options  
        let i;
        let index =0;
        for (i in departments)
        {
          if(i>0){
            departments[i].label=departments[i].name;
            departments[i].value= index;
          }
          index++;
        }
          //clone data in new array
           this.deptDrpDwnOptions = departments;
           this.alldepartment=this.deptDrpDwnOptions[0]
      }
     }



}

decorate(ReportPerformanceStore, {
      datepicker:observable,
      selectedfileLifeTimeDrpDwn:observable,
      selectedfileTransferDrpDwn:observable,
      websiteDrpDwnOptions:observable,
      operatorDrpDwnOptions:observable,
      alldepartment:observable,
      allwebsite:observable,
      isPageLoaded:observable,
      responseData:observable,
      performanceData:observable,
      exportdata:observable,
      Pickupexportdata:observable,
      invitationexportdata:observable,
      export:observable,
      pageLoder:observable,
      
})

export default ReportPerformanceStore;