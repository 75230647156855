import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import CheckBoxComponent from '../../../../../../sharedComponents/checkBox/checkBox';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';
import FormValidator from '../../../../../../sharedFiles/FormValidator';
import axios from 'axios';
import { inspect } from 'util'
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropComponent from '../../../../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';
import ConfirmationComponent from '../../../../../../sharedComponents/confirmationPopup/ConfirmationComponent';

var util = require('util')
const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const EditModeComponent = observer(class EditModeComponent extends Component {

	constructor(props) {
		super(props);
		this.updateProperty = this.updateProperty.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.fileInputRef = React.createRef();
		this.validator = new FormValidator([
			{
				field: 'nick_name',
				method: 'isEmpty',
				validWhen: false,
				message: 'Nick name is required.'
			},
			{
				field: 'real_name',
				method: 'isEmpty',
				validWhen: false,
				message: 'Name is required.'
			},
			{
				field: 'email',
				method: 'isEmpty',
				validWhen: false,
				message: 'Email is required.'
			},
			{
				field: 'email',
				method: 'isEmail',
				validWhen: true,
				message: 'Please enter a valid email.'
			},
			{
				field: 'confirmpassword',
				method: this.passwordMatch,   // notice that we are passing a custom function here
				validWhen: true,
				message: 'Password and password confirmation do not match.'
			}
		]);
		
	    if(this.props.deptStore.addOperator) {
	    	this.validator.validations.push(
	      { 
	        field: 'password', 
	        method: 'isEmpty', 
	        validWhen: false, 
	        message: 'Password is required.'
	      },
	      { 
	        field: 'confirmpassword', 
	        method: 'isEmpty', 
	        validWhen: false, 
	        message: 'Password confirmation is required.'
	      })
	    }

		this.state = {
			validation: this.validator.valid(),
			uploading: false,
			images: "",
			actual_file: "",
		}
		this.submitted = false;
	}

    passwordMatch = (confirmation, props) => (props.password === confirmation)
	
	componentDidMount(){

	}

	updateProperty(key, value) {
	   	this.props.operator[key] = value;	    
	}
	
	setRole = (operator) =>{
		if(operator.is_owner){
			operator.admin = true;
		}else if (operator.user_uid === this.props.appstore.userInfo.usersmodel.user_uid){
		 //dont allow login user admin to change its role, only other admin can change
		 operator.admin = true;
		}
		else{
			operator.admin = !operator.admin;
		} 
	}

	handleChange(event) {
		if(event.target.name === "max_chats" && event.target.value.length == 4){
			return;
		}
		if(event.target.name === "max_chats" && event.target.value>10){
			event.target.value=1;
		} 
		
		// else if(event.target.name ==="nick_name"){
		// 	event.target.value = event.target.value.replace(/\s/g, '');
		// }
		this.updateProperty(event.target.name, event.target.value);
	}

	passwordValidation = (e) =>{
		let specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
		let checkForSpecialChar = function (string) {
			for (let i = 0; i < specialChars.length; i++) {
				if (string.indexOf(specialChars[i]) > 2) {
					return true
				}
			}
			return false;
		}
		
		// if (checkForSpecialChar(e.target.value)) {
		// 	alert("Not Valid");
		// } else {
		// 	alert("Valid");
		// }
	}
  
	handleFormSubmit(event) {
		event.preventDefault();
		if(this.props.deptStore.notExistingUserPassword && this.props.operator.password.length ==0 ){
			//skip password field validation for existing user if admin doesnt change password
			this.validator.validations = this.validator.validations.splice(0,5);
		}
		const validation = this.validator.validate(this.props.operator);
	    this.setState({ validation });
		this.submitted = true;
		let opData = this.props.operator;

		if (validation.isValid) {
			this.props.deptStore.op_dptsave=true;
			this.props.deptStore.notExistingUserPassword=false;
			this.props.deptStore.isPageLoaded=false;
			opData.last_ip = this.props.appstore.getIp();
            opData.user_uid =  opData.user_uid.length==0 ? "": opData.user_uid;
			opData.user_type_id = opData.admin ? 3 :2;
			opData.max_chats= opData.max_chats == 0 ? 0 : opData.max_chats;  //for temporary set to 0, have to change later
			opData.sms_enabled=opData.sms_enabled ? 1:0;
			opData.is_pass_changed=false;
			
			if(opData.password.length !=0 ){
				opData.pass= opData.password;
				opData.is_pass_changed=true;
			}

			opData.website_uid = this.props.appstore.selectedWebisiteUid;
			if (this.props.operator.value && this.props.operator.label) {
				delete this.props.operator.value;
				delete this.props.operator.label;
			}
			const formData = new FormData()
			formData.set('id', '100');
			if (this.props.operator.file != '' && this.props.operator.file != undefined) {
				// this.props.operator.file.forEach((file, i) => {
				this.state.images = this.props.operator.file;
				// console.log(this.props.operator.selectedfile);
				this.state.actual_file = this.props.operator.selectedfile;
				// })
				this.setState({ uploading: true })
				// this.props.operator.file.forEach((file, i) => {
				formData.append("file", this.state.images)
				formData.append("actualfile", this.state.actual_file)
				// })
			}
			if (this.props.deptStore.addOperator != true) {
				formData.append("account_id", opData.account_id)
				
				formData.append("is_owner", opData.is_owner)
			}
			formData.append("consent_status", opData.consent_status ? opData.consent_status:0)
			formData.append("image", (opData.image!="undefined" || opData.image!=undefined)  ? opData.image : "")
			formData.append("email", opData.email)
			formData.append("user_uid", opData.user_uid)
			formData.append("pass", opData.pass)
			formData.append("user_type_id", opData.user_type_id)
			formData.append("nick_name", opData.nick_name)
			formData.append("real_name", opData.real_name)
			formData.append("sms_enabled", opData.sms_enabled)
			formData.append("max_chats", opData.max_chats)
			formData.append("last_ip", opData.last_ip)
			formData.append("last_station_uid", opData.last_station_uid)
			formData.append("website_uid", opData.website_uid)
			formData.append("deleteimage", this.props.deptStore.deleteImg);
			formData.append("fileMimeType", this.props.deptStore.fileMimeType);
			formData.append("is_pass_changed", opData.is_pass_changed);
			//return;opData
			axios({
				method: 'POST',
				url: this.props.appstore.url + "saveoperator.json",
				headers: { 'content-type': 'multipart/form-data','charset':'utf-8' },
				data: formData,
			}).then(result => {
				if(result.data.length==1){
					if(result.data[0].logoutstatus==1){
					  this.props.appstore.logout();
					}
				  }
				// handle actual form submission here 
				this.props.deptStore.cropimage = {
					src: null,
					crop: {
					  unit: '%',
					  width: 50,
					  aspect: 1 / 1,
					},
					croppedImageUrl: null,
				  }
				this.props.deptStore.deleteImg = false;
				if (result.data[0].status == -1) {
					this.props.deptStore.isPageLoaded = true;
					this.props.deptStore.op_dptsave=false;
					this.props.deptStore.emailExist = true;
				}
				else if (result.data != null) {
					let self = this;
					this.props.appstore.userInfo.alloperators = result.data[0].allusers;
					let userdata=result.data[0].allusers.find(function(user){
						return user.user_uid === self.props.appstore.userInfo.usersmodel.user_uid}); 
						if(userdata){
								this.props.appstore.userInfo.usersmodel=userdata; 
						}
						this.props.operator.file='';
					this.props.deptStore.addNewOperator(this.props.operator);
					// this.props.deptStore.showOperators(result.data);
					this.props.deptStore.getOpandDeptforAdmin();
					this.props.deptStore.isPageLoaded=true;
					this.props.deptStore.op_dptsave=false; 
					if(opData.user_uid!=""){
						if(self.props.appstore.userInfo.usersmodel.user_uid==opData.user_uid){
							if(!this.props.appstore.assignedTopics.includes("/topic/"+opData.website_uid)){
								this.props.appstore.assignedTopics.push('/topic/'+ opData.website_uid)
							}
							
						}
					} 
				  }
				  this.props.appstore.global_errorcount=0;
				}).catch((error) => {
					// handle error
					this.props.deptStore.op_dptsave=false; 
					this.props.deptStore.isPageLoaded=true;
					// this.props.appstore.logout();
					console.log(error);
					console.log("error count :"+this.props.appstore.global_errorcount);
					this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
					if(this.props.appstore.global_errorcount>20){
					// this.appstore.logout();
					}
				})
				
				this.props.deptStore.isPageLoaded=true;
	    }
	}

	checkForEnteredNumer(event,field) {
		let val;

		var key = window.event ? event.keyCode : event.which;

			if (event.keyCode == 8 || event.keyCode == 46
			 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 9) {
			    val = true;
			}
			// else if ( key < 48 || key > 57 ) {
			//     val = false;
			// }
			else if (((key < 48 || (key <96 && key>57) ) || key > 105) || event.shiftKey) {
			    val = false;
			}
			else if(field === 'maxStats' && key == 48 && this.props.operator.maxStats.length === 0) {
				val = false;
			}
			else val = true;
			if(!val) {
				event.preventDefault();
			}
	}

	componentWillReceiveProps(nextProps) {

	}

	//-- Croping Images --//
	verifyFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0]
			// console.log(currentFile);
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				this.props.deptStore.warning_image_name = this.props.appstore.getLanguageTrans("Oops, the file is too large. The file size should be less than 10Mb.");
				this.props.deptStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.props.deptStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("An internal server error occurred.");
				this.props.deptStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}

	onSelectFile = e => {
		this.props.operator.selectedfile = e.target.files[0];
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					// this.setState({ src: reader.result })
					this.props.deptStore.cropimage.src = reader.result
				);
				reader.readAsDataURL(e.target.files[0]);
				this.props.deptStore.imageCropModel = true;
			}
		}
	};
	onCropComplete = crop => {
		this.props.deptStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) {
		this.props.deptStore.fileMimeType = this.state.imagetype;
		if (this.props.deptStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif';
			const mimetype = this.state.imagetype == 'image/png' ? 'image/png'
				: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
					: 'image/gif';
					this.props.deptStore.fileMimeType = mimetype;
			const croppedImageUrl = await this.getCroppedImg(
				this.props.deptStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			// this.setState({ croppedImageUrl }); 
			this.props.deptStore.cropimage.croppedImageUrl = croppedImageUrl;

		}
	}

	handleClearToDefault = event => {
		this.props.deptStore.imageCropModel = false;
		this.props.operator.selectedfile = "";
		this.props.operator.file="";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.props.deptStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName, mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.props.deptStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		this.props.operator.file = myNewCroppedFile
		// download file
		// this.props.appstore.downloadBase64File(imageData64, myFilename)
		// this.handleClearToDefault()

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, mimetype);
		});
	}
	selectComponent = () => {
		//this.props.deptStore.deleteImg = true;
		this.props.deptStore.deleteImage=true

	}

	render() {

		let validation = this.submitted ?                         // if the form has been submitted at least once
                      this.validator.validate(this.props.operator) :   // then check validity every time we render
                      this.state.validation  
        const vacss ={
			opacity:0.3,
			pointerEvents:"none"
		}
		const { operator,
			deptStore,
			appstore } = this.props;
		const { crop, croppedImageUrl, src } = this.props.deptStore.cropimage;

		return (
			<div className="settingFormWrapper box">
				<div className={"profileImgWrapper" + ((operator.image != '' && operator.image != undefined) ? "" : deptStore.cropimage.croppedImageUrl ? "" : " addImage")}>
					{/* class name for  img tag editImage */}
					
					{!deptStore.deleteImg &&
						<React.Fragment>
							{!deptStore.cropimage.croppedImageUrl &&
								<img src={(operator.image != '' && operator.image != undefined) ? appstore.resourceurl + appstore.userInfo.accountsmodel.account_uid + "/" + operator.image : "#3ba8c5" == appstore.selectedColor.color ? imageURLs.add_steelblue2 : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.add_seagreen2 : "#ec407a" == appstore.selectedColor.color ?  imageURLs.add_pink2 : "#6e4c42" == appstore.selectedColor.color ?imageURLs.add_brown2 : "#234e91" == appstore.selectedColor.color ?imageURLs.add_navyblue2 : "#e89712" == appstore.selectedColor.color ?imageURLs.add_yellow2 : "#b968c7" ==appstore.selectedColor.color ?imageURLs.add_voilet2 : 
								"#039be6" == appstore.selectedColor.color ? imageURLs.add_blue2 : "#00887a" == appstore.selectedColor.color ? imageURLs.add_darkgreen2 : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.add_green2 : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.add_grey2 : "#64a9e0"== appstore.selectedColor.color ? imageURLs.add_skyblue2 : imageURLs.mute_steel_blue2} alt="" style={(operator.image != '' && operator.image != undefined) ? { height: "148px",width: "160px", marginTop: "9px" } : {}} className={"img-fluid" + ((operator.image != '' && operator.image != undefined) ? " " : "")} />
							}
							{deptStore.cropimage.croppedImageUrl &&
								<React.Fragment>
								<img alt="Crop" className="img-fluid" style={{ height: "148px",width: "160px", marginTop: "9px" }} src={croppedImageUrl} />
								<div className="img_delete" style={{right:"2px"}} onClick={() => this.handleClearToDefault()}><img src={imageURLs.deleteIcon} /></div>
								</React.Fragment>
							}
							<p>{(operator.image == '' || operator.image == undefined) ? appstore.getLanguageTrans("Add photo") : appstore.getLanguageTrans("Change photo")}</p>
							<input className="cursor-pointer" type="file" ref={this.fileInputRef} multiple={false} accept={acceptedFileTypes} id="file" onChange={this.onSelectFile} />

							{
								!deptStore.cropimage.croppedImageUrl && (operator.image != '' && operator.image != undefined) &&
								<div className="img_delete" onClick={() => this.selectComponent()}><img src={imageURLs.deleteIcon} /></div>
								// deptStore.deleteProfileImage(operator.id)
							}
						</React.Fragment>
					}
				</div>
					<Form>
				<div className="contentWrapper">

						<Form.Group as={Row} controlId="formGroupNickName">
						    <Col xs="12">
						    	<Form.Control type="text" placeholder={appstore.getLanguageTrans("Nickname")} 
						    		className={validation.nick_name.isInvalid && 'has-error'}
			    					name="nick_name"
			    					value={operator.nick_name} 
			    					onChange={(e) => this.handleChange(e)}
									maxLength="30"
									/>
			    					<span className="error-block">{validation.nick_name.message}</span>
						    </Col>
						  </Form.Group>
						<Form.Group as={Row} controlId="formGroupName">
						    <Col xs="12">
						    	<Form.Control type="text" placeholder={appstore.getLanguageTrans("Real Name")} 
						    		className={validation.real_name.isInvalid && 'has-error'}
			    					name="real_name"
			    					value={operator.real_name} 
			    					onChange={(e) => this.handleChange(e)}
									maxLength="30"
									/>
			    				<span className="error-block">{validation.real_name.message}</span>
						    </Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formGroupAdmin">
						    <Form.Label column xs="7">{appstore.getLanguageTrans("Admin Access")}</Form.Label>
						    <Col className= {"custom_radioBtn "} style={operator.is_owner==true ? {opacity: 0.3}:{}}>
								<CheckBoxComponent radioValue={operator.admin} 
						    							callBack = {() => this.setRole(operator)}/>
						    </Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formGroupEmail">
						    <Col xs="12">
						    	<Form.Control type="email" placeholder={appstore.getLanguageTrans("Email")} 
						    		className={validation.email.isInvalid && 'has-error'}
			    					name="email"
			    					value={operator.email} 
			    					onChange={(e) => this.handleChange(e)}/>
			    					<span className="error-block">{validation.email.message}</span>
						    </Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formGroupSMS" style={appstore.userInfo.accountplan.sms == 0 ?{display: "none"}:{}}>
						    <Form.Label column xs="7">{appstore.getLanguageTrans("SMS")}</Form.Label>
						    <Col className="custom_radioBtn" style={appstore.userInfo.accountsmodel.display_buy_now ? {opacity: 0.3}:{}} title={appstore.userInfo.accountsmodel.display_buy_now ? "Chat via SMS is available on paid plans only":""}>
						    	<CheckBoxComponent radioValue={operator.sms_enabled}
						    							callBack = {() => { operator.sms_enabled = appstore.userInfo.accountsmodel.display_buy_now ? false : !operator.sms_enabled}}/>
						    </Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formGroupStats">
						    <Col xs="12">
								{appstore.userInfo.accountplan.custom_limit_chat==1 &&
						    	<Form.Control type="text" placeholder={appstore.getLanguageTrans("Max concurrent Chats")} 
									name="max_chats"
									maxLength="2"
			    					value={String(operator.max_chats).slice(0,1) === '0' ? 'unlimited' : operator.max_chats>10 ? "1":operator.max_chats} 
			    					onKeyDown={(e) => {this.checkForEnteredNumer(e,'max_chats')}}
									onChange={(e) => this.handleChange(e)}
									/> 
								}
								{appstore.userInfo.accountplan.custom_limit_chat!=1 &&
								    <span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
									<Form.Control type="text" style={vacss} placeholder={appstore.getLanguageTrans("Max concurrent Chats")} 
									name="max_chats"
									maxLength="2"
			    					value="Unlimited" 
									onChange={(e) => this.handleChange(e)}
									/> </span>
								}
						    </Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formGroupPwd">
						    <Col xs="12">
						    	<Form.Control type="password" placeholder={appstore.getLanguageTrans("Password")}
						    		className={validation.password && validation.password.isInvalid && 'has-error'} 
			    					name="password"
									value={operator.password} 
			    					onChange={(e) => this.handleChange(e)}/>
			    					<span className="error-block">{validation.password && validation.password.message}</span>
						    </Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formGroupConfirmPwd">
						    <Col xs="12">
						    	<Form.Control type="password" placeholder={appstore.getLanguageTrans("Confirm Password")}
						    		className={validation.confirmpassword && validation.confirmpassword.isInvalid && 'has-error'} 
			    					name="confirmpassword"
			    					value={operator.confirmpassword} 
			    					onChange={(e) => this.handleChange(e)}/>
			    					<span className="error-block">{validation.confirmpassword && validation.confirmpassword.message}</span>
						    </Col>
						</Form.Group>
						</div>
						<div className="row m-0">
							<div className="col-12 formBtns">
								<div className="col-12 p-0">
									<Button className="btn_clr" variant="light" onClick={() => deptStore.cancelAddition(operator)}>
								    	<p>{appstore.getLanguageTrans("Cancel")}</p>
								    </Button>
								    <Button className="btn_save" type="submit" onClick={(event) => this.handleFormSubmit(event)}>
								    	<p>{appstore.getLanguageTrans("Save")}</p>
								    </Button>
								</div>
							</div>
						</div>
					</Form>
				{deptStore.imagewarning &&
					<ImageWarningPopUp show={deptStore.imagewarning}
						store={deptStore}
						cancel_btn="Close"
						closePopup={() => deptStore.imagewarning = false}
						appStore={appstore} />
				}
				{deptStore.imageCropModel &&
					<ImageCropComponent show={deptStore.imageCropModel}
						store={deptStore}
						cancel_btn="Clear!"
						multi="0"
						crop_btn="Ok"
						onCropComplete={() => this.onCropComplete(crop)}
						closePopup={() => this.handleClearToDefault()}
						appStore={appstore} />
				}

					{
					this.props.deptStore.deleteImage && 
					<ConfirmationComponent show={this.props.deptStore.deleteImage}
											store={this.props.deptStore}
											title="Delete Image"
											body_html="<p>Are you sure you want to delete the image? The operation cannot be undone.</p>"
											cancel_btn="Cancel"
											save_btn="Delete"
											callBack={() =>{this.props.deptStore.deleteImg = true; this.props.deptStore.deleteImage = false}}
											closePopup={() => this.props.deptStore.deleteImage = false}
											appStore = {appstore}/>
				}

			</div>
		)
	}
})
export default EditModeComponent;
