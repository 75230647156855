import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';

const EmailTranscriptPopup = observer(class EmailTranscriptPopup extends Component {

    constructor(props) {
        super(props);
        this.state={
            msg:''
        }
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    handleChange = (event) => {

        if (event.target.name === "email") {
            //dont allow space in email
            event.target.value = event.target.value;
        }
        this.props.store.transcript_emailid = event.target.value;
    }

    checkvalidemail() { 
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (this.props.store.transcript_emailid == "") { 
            this.props.store.transcript_error = "please enter email id";
        }
        else if (reg.test(this.props.store.transcript_emailid) == false) { 
            this.props.store.transcript_error = "please enter valid email id";
        } else {
            this.props.store.transcript_error = "";
            this.props.store.validcondtion = "true";
            this.emailTrascript(this.props.appStore)
        }
    }

    emailTrascript(appstore) {
        if (this.props.from == "chat") {
            if (!appstore.requestedVisitorId)
                return;

            //console.log(appstore.requestedVisitorId.trim());
            //console.log(this.props.store.transcript_emailid.trim());
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            console.log(tz);
            const formData = new FormData()
            formData.set('sourceStation', appstore.requestedVisitorId.trim());
            formData.set('to_mail', this.props.store.transcript_emailid.trim());
            formData.set('clientTimeZone', tz);

            axios({
                method: 'post',
                url: this.props.appStore.url + 'emailtranscript_op.json',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data','charset':'utf-8' }

            }).then(result => {
                 // console.log(result); Email successfully sent to
                //alert(result.data=="Message sent, thank you" ? "Message sent, thank you" :"Somthing wen wrong..")
                this.setState({msg:result.data=="Email successfully sent to" ? "The transcript has been sent to you" :"Somthing went wrong.."});

                //this.props.store.mailtranscripts = false;
                this.props.store.transcript_emailid = appstore.userInfo.usersmodel.email;
                this.props.store.transcript_error = "";
                this.props.store.mailstatus=result.data=="Email successfully sent to" ? true :false; 
                //Error while sending mail ..
                //Message sent, thank you

            }).catch((error) => {
                this.props.store.transcript_emailid = appstore.userInfo.usersmodel.email;
                this.props.store.transcript_error = "";
                this.props.store.mailtranscripts = false;
                this.props.store.mailstatus=false;
                if (error.response) {
                    console.log(error);
                }
            })
        } else {
        //    console.log(this.props.store.emailArchiveArray);
        //    console.log(JSON.stringify(JSON.stringify(this.props.store.emailArchiveArray[0])));
        //    console.log(JSON.stringify(this.props.store.emailArchiveArray[0].transcript));
        //    console.log(this.props.store.transcript_emailid);
           const formData = new FormData()
           formData.set('transciptarray', JSON.stringify(this.props.store.emailArchiveArray[0]));
           formData.set('to_mail', this.props.store.transcript_emailid);
           axios({
               method: 'post',
               url: this.props.appStore.url + 'emailarchive_op.json',
               data: formData,
               headers: { 'Content-Type': 'multipart/form-data','charset':'utf-8' }

           }).then(result => {
              // console.log(result);
              if(result.data=="Message sent, thank you"){
                alert("Message sent, thank you");
              }
               //alert(result.data=="Message sent, thank you" ? "Message sent, thank you" :"Somthing went wrong..")
               this.props.store.mailtranscripts = false;
               this.props.store.transcript_emailid = appstore.userInfo.usersmodel.email;
               this.props.store.transcript_error = "";
               //this.props.store.mailstatus=result.data=="Message sent, thank you" ? true :false;               
               this.props.store.mailstatus=result.data=="Message sent, thank you" ? true :true;               
               //Error while sending mail ..
               //Message sent, thank you

           }).catch((error) => {
               this.props.store.transcript_emailid = appstore.userInfo.usersmodel.email;
               this.props.store.transcript_error = "";
               this.props.store.mailtranscripts = false;
               this.props.store.mailstatus=false;
               if (error.response) {
                   console.log(error);
               }
           }) 
        }
    } 

    render() {
        const { show,
            store,
            title,
            callBack,
            body_html,
            cancel_btn,
            save_btn,
            appStore,
            singleButton,
            logoutCss,
            from,
            closeButtonRequired } = this.props;
        return (
            <Modal backdrop='static' className={(appStore ? appStore.selectedColor.value : '') + " " + (logoutCss ? logoutCss : "")} show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => closeButtonRequired ? appStore.logout() : this.props.closePopup()}>
                <Modal.Header closeButton>
                    <Modal.Title>{appStore.getLanguageTrans(title)}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                { this.state.msg &&
                  <p>{this.state.msg}</p>
                }
                   { !this.state.msg && <Form>
                        <Form.Group as={Row} controlId="formGroupNickName"> 
                            <Form.Label column lg="2" sm="2">{appStore.getLanguageTrans("Email")}</Form.Label>
                            <Col sm="10" lg="10">
                                <Form.Control type="text" placeholder={appStore.getLanguageTrans("Enter Email")}
                                    className={store.transcript_error ? 'has-error' :''}
                                    name="email"
                                    value={store.transcript_emailid}
                                    onChange={(e) => this.handleChange(e)}
                                    maxLength="30" />
                                <span className="error-block">{appStore.getLanguageTrans(store.transcript_error)}</span>
                            </Col> 
                        </Form.Group>
                    </Form>
                  }
                </Modal.Body>

                <Modal.Footer>
                 { !this.state.msg && 
                  <React.Fragment>
                    <Button className="btn_cancel" type="button" variant="secondary" onClick={() => { this.props.closePopup(); store.transcript_error = "" }} >{appStore.getLanguageTrans(cancel_btn)}</Button>

                    <Button className="btn_save" type="button" variant="primary" onClick={() => this.checkvalidemail()}>{appStore.getLanguageTrans(save_btn)}</Button>
                  </React.Fragment> 
                 }
                 {
                    this.state.msg &&  
                    <Button className="btn_cancel" type="button" variant="secondary" onClick={() => { this.props.closePopup(); store.transcript_error = "" }} >{appStore.getLanguageTrans("Okay")}</Button>
 
                 }
                </Modal.Footer>
            </Modal>
        )
    }
})
export default EmailTranscriptPopup;
