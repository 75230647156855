import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import ExportCSV from '../../../sharedComponents/exportCSV/exportCSVComponent';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import ReportPerformanceStore from './ReportPerformanceStore';
import CustomScroll from 'react-custom-scroll';
import imageURLs from '../../../sharedFiles/ImagesURLs';
import PieChartComponent from './PieChartComponent';
import DounutChartComponent from './DounutChartComponent';
import TimePieChartComponent from './TimePieChartComponent';
import InvitationColumChart from './InvitationColumChart';
import Export_CSV from './pickUpTimeCSV';
import Export_invCSV from './invitationsCSV';
import TimeColumChart from './TimeColumChart';
import DateRangePicker from "react-daterange-picker";
import axios from 'axios';
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);


const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const ReportPerformanceComponent = observer(class ReportPerformanceComponent extends Component {

	constructor(props) {
		const today = moment();
		super(props);
		this.reportPerformanceStore = new ReportPerformanceStore(this.props.appstore);
		this.updateSearch = this.updateSearch.bind(this);
		this.state = {
			isOpen: false,
			value: moment.range(today.clone().subtract(14, "days"), today.clone()),
			search: "",
		} 
	}

	componentDidMount() {

		this.reportPerformanceStore.setWebsites(this.props.appstore.userInfo.allwebistes, this.props.appstore)
		this.reportPerformanceStore.setDepartments(this.props.appstore.userInfo.alldepartments, this.props.appstore);
		// this.reportPerformanceStore.setDepartments(this.props.appstore.userInfo.alldepartments, this.props.appstore)

		//	this.reportPerformanceStore.isPageLoaded=true
		var date = new Date().getDate();
		var month = new Date().getMonth() + 1;
		var year = new Date().getFullYear();

		this.getStartPagedata(date + '-' + month + '-' + year);

	}


	getStartPagedata = (dateRange = null) => {
        this.reportPerformanceStore.pageLoder=false;
		const today = moment();
		let since_from_today = today.clone().subtract(14, "days");
		const data = {
			"since": dateRange != null ? since_from_today.format("DD-MM-YYYY"):this.state.value.start.format("DD-MM-YYYY"),
			"till": dateRange != null ? dateRange:this.state.value.end.format("DD-MM-YYYY"),
			"alltype": ["pickup_time", "auto_invitations", "start_pages", "chats","avg_chats"],
			"department_uid": this.reportPerformanceStore.alldepartment.department_uid,
			"website_uid": this.reportPerformanceStore.allwebsite.website_uid,
			"operator_uid": ""
		}
		this.reportPerformanceStore.datepicker = false;
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getPerformanceRecords.json',
			headers: { 'content-type': 'application/json' },
			data: data,
		}).then(result => {
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			this.reportPerformanceStore.exportdata = JSON.parse(JSON.stringify(result.data));
			this.reportPerformanceStore.Pickupexportdata = JSON.parse(JSON.stringify(result.data.pickupTimeRecords));
			this.reportPerformanceStore.invitationexportdata=this.reportPerformanceStore.performanceData;
			let pickupDate = [];
			let invitationDate = [];
			let pickupTime = [];
			let manualinvitationRecords = [];
			let autoinvitationcount = [];
			let manualinvitationcount = [];
			let totalautoSum = 0;
			let totalManualSum = 0;
			let previousDate = "";
			let previousManualDate = "";
			let previoustimeDate = "";
			let statpagedate="";
			let statwebsite="";
			let statcount="";
			let statpagerecordsADD=[]

			for (let i in result.data) {
				if (i === "pickupTimeRecords") {
					if (result.data.pickupTimeRecords != null) {
						for (let j = 0; j < result.data[i].length; j++) {
							if (previoustimeDate === result.data[i][j].quarter_date) {
								pickupTime[pickupTime.length - 1] = pickupTime[pickupTime.length - 1] + result.data[i][j].time;
							}
							else {
								pickupDate.push(result.data[i][j].quarter_date)
								pickupTime.push(result.data[i][j].time)
								previoustimeDate = result.data[i][j].quarter_date;
							}

						}
					}
				}
				else if (i === "invitationRecords") {
					if (result.data.invitationRecords != null) {
						var datearray=[];
						Array.prototype.contains = function(v) {
							for (var i = 0; i < this.length; i++) {
							  if (this[i] === v) return true;
							}
							return false;
						  };
						  
						  Array.prototype.unique = function() {
							var arr = [];
							for (var i = 0; i < this.length; i++) {
							  if (!arr.contains(this[i])) {
								arr.push(this[i]);
							  }
							}
							return arr;
						  }
						  
						  var duplicates = []; 		
						  let autoinvtaionobject={};
						let manulainvtaionobject={};				   
						for (let j = 0; j < result.data[i].length; j++) {
							duplicates.push(result.data[i][j].quarter_date) ;
							if (result.data[i][j].type === "auto_invitations") { 
								autoinvtaionobject[result.data[i][j].quarter_date]={"date":result.data[i][j].quarter_date,"chatcount":result.data[i][j].cnt};
							}
							else{
								manulainvtaionobject[result.data[i][j].quarter_date]={"date":result.data[i][j].quarter_date,"chatcount":result.data[i][j].cnt};
							}
						}
						var uniques = duplicates.unique(); 
						manualinvitationRecords=uniques;
						 for(let a = 0; a < uniques.length; a++){
							 if(autoinvtaionobject.hasOwnProperty(uniques[a])){
								autoinvitationcount.push(autoinvtaionobject[uniques[a]].chatcount);
							 }else{
								autoinvitationcount.push(0);
							 }
							 if(manulainvtaionobject.hasOwnProperty(uniques[a])){
								manualinvitationcount.push(manulainvtaionobject[uniques[a]].chatcount);
							 }else{
								manualinvitationcount.push(0);
							 }
						 }
					}
				}
				else if (i === "startPagesRecords") { 
					if (result.data.startPagesRecords != null) { 
						for (let k = 0; k < result.data[i].length; k++) {  
							// if(statwebsite === result.data[i][k].str && statpagedate === result.data[i][k].quarter_date ){
							// 	statcount = statcount + result.data[i][k].cnt;
							// }
							// else{
							//  let statpagedata={
							// 	str:statwebsite,
							// 	cnt:statcount ,
							// 	quarter_date:statpagedate 
							// }
							//  if(statcount)
							// 	statpagerecordsADD.push(statpagedata)
							// 	statpagedate=result.data[i][k].quarter_date 
							// 	statcount=result.data[i][k].cnt
							// 	statwebsite=result.data[i][k].str
							// }
							let statpagedata={
									str:result.data[i][k].str,
									cnt:result.data[i][k].cnt ,
									quarter_date:result.data[i][k].quarter_date 
								}
								statpagerecordsADD.push(statpagedata)
						} 
					}
				}

			}
			this.reportPerformanceStore.performanceData = {
				"pickupDate": pickupDate,
				"pickupTime": pickupTime,
				"manualinvitationRecords": manualinvitationRecords,
				"autoinvitationcount": autoinvitationcount,
				"manualinvitationcount": manualinvitationcount,
				"totalManualSum": totalManualSum,
				"totalautoSum": totalautoSum,

			};
			// console.log(this.reportPerformanceStore.performanceData);
			this.reportPerformanceStore.responseData.archivechatdata = statpagerecordsADD;
			this.reportPerformanceStore.pageLoder = true
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	console.log(error)
			// 	this.props.appstore.logout();
			// }
			this.reportPerformanceStore.pageLoder = true
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		});

	}

	handleWebsiteFilter = (selectedOption) => {
		//this.reportPerformanceStore.pageLoder = false 
		this.reportPerformanceStore.allwebsite = selectedOption;
		this.reportPerformanceStore.alldepartment = "";
		if(selectedOption.website_uid==""){
			this.reportPerformanceStore.setDepartments(this.props.appstore.userInfo.alldepartments, this.props.appstore);
			this.getStartPagedata();
			return;
		}
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getDeptsforWebsite.json',
			headers: { 'content-type': 'application/json' },
			data: selectedOption,
		}).then(result => {
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			this.reportPerformanceStore.setDepartments(result.data);
			this.getStartPagedata()
			//this.reportPerformanceStore.pageLoder = true
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			if (error.response) {
				// console.log(error)
				// this.props.appstore.logout();
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
			}
		});

	}

	onSelect = (value, states) => {
		this.setState({ value, states });
		this.getStartPagedata();
	};
	refreshimagesetonhover = (color,button)=>{
		if(button==1){
			document.getElementById("invitationimg").src=imageURLs.download_white;
		}else if(button==2){
			document.getElementById("pickupimg").src=imageURLs.download_white;
		}else if(button==3){
			document.getElementById("startpageimg").src=imageURLs.download_white;
		} 
	}
	refreshimagesetonhoverout = (color,button)=>{
		if(button==1){
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("invitationimg").src=imageurl;
		}else if(button==2){
			
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("pickupimg").src=imageurl;
		}else if(button==3){
			
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("startpageimg").src=imageurl;
		}
	}

	updateSearch(event) {
		this.setState({ search: event.target.value.substr(0, 20) });
	}
	filterStartPage = () => {
		
		return this.reportPerformanceStore.responseData.archivechatdata.filter(
			(fileData) => {
				return fileData.str.toLowerCase().indexOf(this.state.search.toLowerCase()) != -1
			})
	}

	renderRowStartpage(fileData, dataIndex) {
		return (
			<div className="row action panelContent align-items-center" key={dataIndex} >

				<div className="col-2" >
					<p className="text-left">{dataIndex + 1} </p>
				</div>
				<div className="col-6">
					<p className="text-left" style={{wordWrap:"anywhere"}}>{fileData.str} </p>
				</div>
				<div className="col-2">
					<p className="text-left">{fileData.quarter_date} </p>
				</div>
				<div className="col-2">
					<p className="text-center">{fileData.cnt} </p>
				</div>
			</div>
		);
	}


	render() {
		const { appstore } = this.props;
		let isExportEnabled = appstore.userInfo.accountplan.export_report;
		const excss = {
			pointerEvents: "none",
			opacity: 0.3,
            minWidth:"150px"};
		const nocss = {minWidth:"150px"};	
		let self = this;
		if (!this.reportPerformanceStore.pageLoder) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}

		const bulletsColor = {
			//content: "\2022",
			color: "red",
			fontWeight: "bold",
			display: "inlineBlock",
			width: "1em",
			marginLeft: "-1em",
		};

		const options = {
			title: {
				text: 'Basic Column Chart in React',
			},
			data: [
				{
					type: 'column',
					dataPoints: [
						{ label: 'Apple', y: 10 },
						{ label: 'Orange', y: 15 },
						{ label: 'Banana', y: 25 },
						{ label: 'Mango', y: 30 },
						{ label: 'Grape', y: 28 },
					],
				},
			],
		};
		let filteredStartPage = this.filterStartPage();
		// if (!this.reportPerformanceStore.isPageLoaded) {
		// 	return (<div>loading,,,,</div>)
		// }

		return (

			<div className="innercontent_wrapper w-100 scriptWrapper">

				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.performanceIcon} alt="Performance" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Performance")}</h4>

				</div>

				<div className="row fileTransferWrapper">
					<div className="col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form>
								<Form.Group as={Row} controlId="formGroupLanguage" >
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Date Range")}</Form.Label>
									<Col sm="8" lg="7" >
										<Form.Control type="text" placeholder={appstore.getLanguageTrans("Select Date Range")}
											name="date_picker"
											value={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
											onClick={() => this.reportPerformanceStore.datepicker = !this.reportPerformanceStore.datepicker}
											onChange={(event) => this.getStartPagedata()}
										/>
										{this.reportPerformanceStore.datepicker &&
											<DateRangePicker
												value={this.state.value}
												onSelect={this.onSelect}
												singleDateRange={true}
											//onKeyDown={}
											/>
										}
									</Col>

								</Form.Group>




								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Websites")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">

										<DropDownComponent dropDownOptions={this.reportPerformanceStore.websiteDrpDwnOptions}
											store={this.reportPerformanceStore}
											key={Math.random()}
											callBack={(selectedOption) => this.handleWebsiteFilter(selectedOption)}
											selectedOption={this.reportPerformanceStore.allwebsite} 
										     />
											   {/* onChange = {(event) => this.getStartPagedata(event)} */}
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Departments")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">
										<DropDownComponent dropDownOptions={this.reportPerformanceStore.deptDrpDwnOptions}
											store={this.reportPerformanceStore}
											key={Math.random()}
											callBack={(selectedOption) => {this.reportPerformanceStore.alldepartment = selectedOption;this.getStartPagedata()}}
											selectedOption={this.reportPerformanceStore.alldepartment} />
										{/* onChange = {(event) => this.getStartPagedata(event)}  */}
									</Col>
								</Form.Group>


							</Form>

							{/* <div className="col-12 p-0">
								<div className="headerTitle">
									<h4 className="text-left">{appstore.getLanguageTrans("Total Summary")}</h4>
								</div>
							</div> */}

							{/* <div>
								<Row className="top_Row" id="top_row">
									<Col xs="12" lg="4" md="4" sm="6">
										<DounutChartComponent />
										<ul className="pie" style={{ marginTop: -75 , marginRight:100 }}>
											<li id="accept">Accepted</li>
											<li id="declined">Declined</li>
										</ul>
									</Col>

									<Col xs="12" lg="4" md="4" sm="6">
										<PieChartComponent 
										 key={Math.random()}
										 data = {this.reportPerformanceStore.performanceData}
										 appstore={appstore}
										 store={this.reportPerformanceStore}/>
										<br />
										<ul className="pie">
											<li id="manualinvitations">Manual Invitations</li>
											<li id="invitations">Automatic Invitations</li>
										</ul>
									</Col>

									<Col xs="12" lg="4" md="4" sm="6">
										<TimePieChartComponent />
										<br />
										<ul className="pie" >
											<li id="time"> Many visitor may leave <br /> before you leave </li>
										</ul>
									</Col>

								</Row>

							</div> */}

							<div className="col-12 p-0">
								<Row className="top_Row" id="top_row">
									<Col xs="12" lg="10" md="4" sm="6">
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Number Of Invitations")}</h4>
										</div>
									</Col>
									<Col xs="4" lg="2" md="6" sm="2">
										<Form.Group as={Row} style={{ paddingTop: 36}} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
											<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,1)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,1)} >

												 <img className="img-fluid" id="invitationimg" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
													"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.download_steelblue}  alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />
								 
												<Export_invCSV
													data={this.reportPerformanceStore.exportdata}
													show={this.reportPerformanceStore.export}
													store={this.reportPerformanceStore}
													appStore={appstore}
													dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
												/>
											</Button>
										</Form.Group>

									</Col>
								</Row>
							</div>
							<div className="row profile_wrapper">
								<div className="settingsForm col-12 w-100 pr-0">
									<div className="settingFormWrapper box">
										<InvitationColumChart
											key={Math.random()}
											data={this.reportPerformanceStore.performanceData}
											appstore={appstore}
											store={this.reportPerformanceStore}
										/>
									</div>
								</div>
							</div>


							<div className="col-12 p-0">
								<Row className="top_Row" id="top_row">
									<Col xs="12" lg="10" md="4" sm="6">
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Pickup Time")}</h4>
										</div>
									</Col>
									<Col xs="4" lg="2" md="6" sm="2">
										<Form.Group as={Row} style={{ paddingTop: 33, right: 15 }} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
											<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,2)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,2)} >

												<img id="pickupimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
													"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue}  alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />
        
											
												<Export_CSV
													data={this.reportPerformanceStore.exportdata}
													show={this.reportPerformanceStore.export}
													store={this.reportPerformanceStore}
													appStore={appstore}
													dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
												/>
											
											</Button>
										</Form.Group>

									</Col>
								</Row>
							</div>

							<div className="row profile_wrapper">
								<div className="settingsForm col-12 w-100 pr-0">
									<div className="settingFormWrapper box">
										<TimeColumChart
											key={Math.random()}
											data={this.reportPerformanceStore.performanceData}
											appstore={appstore}
											store={this.reportPerformanceStore}
										/>
									</div>
								</div>
							</div>

							<div className="col-12 p-0">
								<Row className="top_Row" id="top_row">
									<Col xs="6" lg="3" md="2" sm="2">
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Start Pages")}</h4>
										</div>
									</Col>
									<Col xs="12" lg="7" md="6" sm="8" style={{ paddingRight: 70 }}>
										<div className="headerTitle">
											<Form.Group as={Row} controlId="formGroupLanguage">
												<Form.Label column lg="4" sm="3" className="text-right">{appstore.getLanguageTrans("Quick Search")}</Form.Label>
												<Col sm="6" lg="6" className="searchText ">
													<Form.Control type="text"
														placeholder={appstore.getLanguageTrans("Enter Search Term")}
													onChange={(e) => this.updateSearch(e)}
													/>
												</Col>
											</Form.Group>
										</div>
									</Col>


									<Col xs="4" lg="2" md="4" sm="2">
										<Form>
											<Form.Group as={Row} style={{ paddingTop: 45, right: 15 }} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>

												<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,3)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,3)} >

													<img id="startpageimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
														"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue}  alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />

													<ExportCSV
														data={this.reportPerformanceStore.exportdata}
														show={this.reportPerformanceStore.export}
														store={this.reportPerformanceStore}
														appStore={appstore}
														dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
													/>

												</Button>

											</Form.Group>
										</Form>
									</Col>
								</Row>
							</div>
							<div className="scroll_row row m-0">
								<div id="panelGroup" className="panelWrapper col-12 col-lg-12 p-0">
									<div className="col-12">
										<div className="row panelHeader ">

											<div className="col-2 pl-0">
												<p className="text-left" style={{ paddingLeft: 16 }}>{appstore.getLanguageTrans("S.No")}</p>
											</div>
											<div className="col-6 ">
												<p className="text-left">{appstore.getLanguageTrans("Page URL")}</p>
											</div>
											<div className="col-2 ">
												<p className="text-left">{appstore.getLanguageTrans("Date")}</p>
											</div>
											<div className="col-2">
												<p className="text-right">{appstore.getLanguageTrans("Number Of Charts")}</p>
											</div>

										</div>
									</div>


									<div className="scrollWrapper col-12 p-0">
										<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
											<div className="scriptPanel row">
												<div className="col-12">
													{/* {alert(JSON.stringify(this.reportPerformanceStore.responseData.archivechatdata[0]))} */}
													{/* this.reportPerformanceStore.responseData.archivechatdata.map(function (fileData, dataIndex) { */}
													{													
															filteredStartPage &&
															filteredStartPage.map(function (fileData, dataIndex) {
																return (self.renderRowStartpage(fileData, dataIndex))
														})
													}
													{
														this.reportPerformanceStore.responseData.archivechatdata.length == 0 &&
														<div className="col-12">
															<p className="text-Center" style={{ height: 80, padding: 10, fontSize: 18 }}>{appstore.getLanguageTrans("No Data Available")}</p>
														</div>
													}
												</div>
											</div>
										</CustomScroll>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

		)
	}
})

export default ReportPerformanceComponent;