
import React, { Component } from "react";
import { observer } from 'mobx-react';
import Chart from "react-apexcharts";
//import { ApexCharts } from 'ng-apexcharts';

const SmsTimeColumChart = observer(class SmsTimeColumChart extends Component {
  constructor(props) {
    super(props); 
   this.arr= ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT']; 
   
  
   this.state = {
          
            series: [{
              name: '',
              data: this.props.store.reportUsageData.sms_used,
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                
                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    // reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                  },
                },

                zoom: {
                  enabled: true
                }
              },
              responsive: [{
                breakpoint: 480,
                options: {
                 
                }
              }],
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              dataLabels: {
                enabled: false
              },

              tooltip: {
                theme:"dark",
                position:"topleft",
                x : {show: false} ,
                marker: {show:false},
              },
         
              xaxis: {
                type: 'category',
                categories:  this.props.store.reportUsageData.sms_usedDate
              },
              yaxis: {
                type:'time',
                show:true,
                min:0,
                forceNiceScale: true,
                labels: {
                  formatter: function (value) {
                    return value ;
                  }
                }
               },
              
              fill: {
                opacity: 1
              }
            },
          
          
          };

  }
 
  render() {
   
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              width="900"
              height="300px"
            />
          </div>
        </div>
      </div>
    );
  }
})

export default SmsTimeColumChart;