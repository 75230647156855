import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Select , { components } from 'react-select';
import CustomScroll from 'react-custom-scroll';
import { CompactPicker } from 'react-color';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../../sharedComponents/dropDown/dropDown';
import RadioButtonComponent from '../../../../../../sharedComponents/radionButton2/RadioButton';

const ClickOutHandler = require('react-onclickout');


const StyleSettingsComponent = observer(class StyleSettingsComponent extends Component {

	constructor (props) {
        super(props);
        this.pickTextColorBasedOnBgColorAdvanced = this.pickTextColorBasedOnBgColorAdvanced.bind(this);
    }

	componentDidMount(){
		this.props.widgetStore.bgColorSelector = false;
  		this.props.widgetStore.textColorSelector = false;
  		this.props.widgetStore.toolTipBgSelector = false;
  		this.props.widgetStore.toolTipTextColorSelector = false;

	}

	pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) { 
		if(typeof(bgColor)=="object"){
			bgColor=bgColor.colorCode;
		}
		if(bgColor) {
		  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
		  var r = parseInt(color.substring(0, 2), 16); // hexToR
		  var g = parseInt(color.substring(2, 4), 16); // hexToG
		  var b = parseInt(color.substring(4, 6), 16); // hexToB
		  var uicolors = [r / 255, g / 255, b / 255];
		  var c = uicolors.map((col) => {
		    if (col <= 0.03928) {
		      return col / 12.92;
		    }
		    return Math.pow((col + 0.055) / 1.055, 2.4);
		  });
		  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
		  return (L > 0.298) ? darkColor : lightColor;
		}
	}

	setToolBarBackGround(color){
		if(this.props.widgetStore.toolBarStyleSettings.bgColor.colorCode || color) {
			this.props.widgetStore.toolBarPreviewSettings.bgColor = color ? color : this.props.widgetStore.toolBarStyleSettings.bgColor.colorCode;
			this.props.widgetStore.bgcolornew = color ? color : this.props.widgetStore.toolBarStyleSettings.bgColor.value=="custom" ? this.props.widgetStore.toolBarStyleSettings.bgColor.colorCode : this.props.widgetStore.toolBarStyleSettings.bgColor.value;
			if(this.props.widgetStore.toolBarStyleSettings.toolTipSettings.value === 1) {
				//this.props.widgetStore.toolBarToolTipStyleSettings.bgColor = this.props.widgetStore.toolBarStyleSettings.bgColor;
				//this.setToolTipBackGround(this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode);
			}
		}
	}

	setToolBarText(color){
		if(this.props.widgetStore.toolBarStyleSettings.textColor.colorCode || color) {
			this.props.widgetStore.toolBarPreviewSettings.textColor = color ? color : this.props.widgetStore.toolBarStyleSettings.textColor.colorCode;
			this.props.widgetStore.bgtextnew = color ? color : this.props.widgetStore.toolBarStyleSettings.textColor.value=="custom" ? this.props.widgetStore.toolBarStyleSettings.textColor.colorCode : this.props.widgetStore.toolBarStyleSettings.textColor.value;
		 
			if(this.props.widgetStore.toolBarStyleSettings.toolTipSettings.value === 1) {
				//this.props.widgetStore.toolBarToolTipStyleSettings.textColor = this.props.widgetStore.toolBarStyleSettings.textColor;
				//this.setToolTipText(this.props.widgetStore.toolBarToolTipStyleSettings.textColor.colorCode);
			}
		}
	}

	setBorderRadius() {
		this.props.widgetStore.toolBarPreviewSettings.borderRadius = this.props.widgetStore.toolBarStyleSettings.roundedCorners ? 5 : 0;
		if(this.props.widgetStore.toolBarStyleSettings.toolTipSettings.value === 1) {
			this.props.widgetStore.toolBarToolTipStyleSettings.roundedCorners = this.props.widgetStore.toolBarStyleSettings.roundedCorners;
			this.setToolTipBorderRadius();
		}
	}

	setGradient(){
		this.props.widgetStore.toolBarPreviewSettings.gradient = this.props.widgetStore.toolBarStyleSettings.gradient;
		if(this.props.widgetStore.toolBarStyleSettings.toolTipSettings.value === 1) {
			this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.gradient = this.props.widgetStore.toolBarStyleSettings.gradient;
			this.setToolTipGradient();
		}
	}

	setTextFont() {
		this.props.widgetStore.toolBarPreviewSettings.font = this.props.widgetStore.toolBarStyleSettings.textFont.label;
	}

	checkBarThickness = (e) =>{

		if(e<30){
			this.props.widgetStore.toolBarStyleSettings.barThickness.height =30;
			this.props.widgetStore.toolBarPreviewSettings.toolBarHeight=30;
		}else if( e>80){
			this.props.widgetStore.toolBarStyleSettings.barThickness.height =80;
			this.props.widgetStore.toolBarPreviewSettings.toolBarHeight=80;
		}else{
			this.props.widgetStore.toolBarStyleSettings.barThickness.height =e
			this.props.widgetStore.toolBarPreviewSettings.toolBarHeight=e
		}

	}

	setBarThickness() {
		this.props.widgetStore.toolBarPreviewSettings.toolBarHeight = this.props.widgetStore.toolBarStyleSettings.barThickness.height;
	}

	setToolTipValues() {
		//this.props.widgetStore.toolBarToolTipStyleSettings.bgColor= this.props.widgetStore.toolBarStyleSettings.bgColor;
	    //this.props.widgetStore.toolBarToolTipStyleSettings.textColor= this.props.widgetStore.toolBarStyleSettings.textColor;
	    this.props.widgetStore.toolBarToolTipStyleSettings.gradient= this.props.widgetStore.toolBarStyleSettings.gradient;
	    this.props.widgetStore.toolBarToolTipStyleSettings.roundedCorners= this.props.widgetStore.toolBarStyleSettings.roundedCorners;
	    this.setToolTipBackGround();
	    this.setToolTipText();
	    this.setToolTipBorderRadius();
	    this.setToolTipGradient();
	}

	setToolTipBackGround(color){
		
		if(this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode || color) {
			this.props.widgetStore.toolBarPreviewSettings.toolTip.bgColor = color ? color : this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode;
			this.props.widgetStore.ToolTipcolornew=color ? color : this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.value=="custom" ? this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode : this.props.widgetStore.toolBarToolTipStyleSettings.bgColor.value;
			
		}
	}

	setToolTipText(color){
		if(this.props.widgetStore.toolBarToolTipStyleSettings.textColor.colorCode || color) {
			this.props.widgetStore.toolBarPreviewSettings.toolTip.textColor = color ? color : this.props.widgetStore.toolBarToolTipStyleSettings.textColor.colorCode;
			this.props.widgetStore.ToolTiptextnew=color ? color : this.props.widgetStore.toolBarToolTipStyleSettings.textColor.value=="custom" ? this.props.widgetStore.toolBarToolTipStyleSettings.textColor.colorCode : this.props.widgetStore.toolBarToolTipStyleSettings.textColor.value;
		}
	}

	setToolTipBorderRadius() {
		this.props.widgetStore.toolBarPreviewSettings.toolTip.borderRadius = this.props.widgetStore.toolBarToolTipStyleSettings.roundedCorners ? 5 : 0;
	}

	setToolTipGradient(){
		this.props.widgetStore.toolBarPreviewSettings.toolTip.gradient = this.props.widgetStore.toolBarToolTipStyleSettings.gradient;
	}

	componentWillUnmount() {
	} 

	render(){

		var self = this;

		const { appstore,
				widgetStore,
				validation } = this.props;

		return (

				<form className="toolbar_style_tour">
					<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Background color")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn customOption">
								    		<div className="row m-0">
									    		<DropDownComponent  dropDownOptions={widgetStore.topBarColors}
										    						store={widgetStore}
												    				callBack={(selectedOption) => {widgetStore.toolBarStyleSettings.bgColor = selectedOption; this.setToolBarBackGround()}}
												    				selectedOption = {widgetStore.toolBarStyleSettings.bgColor}/>
												{
													widgetStore.toolBarStyleSettings.bgColor.value === "custom" && 
													
														 <ClickOutHandler onClickOut={() => {widgetStore.bgColorSelector = false;}}>
															<div className="css-10nd86i text-left colorSelector" style={{border:widgetStore.toolBarStyleSettings_bgColorerror? "2px solid rgb(255, 5, 5)":""}}>
																<div className="css-vj8t7z" style={{backgroundColor: widgetStore.bgcolornew}}>
																	<div className="css-1hwfws3">
																		<div className="css-1g6gooi">
																			<div className="">
																				<input autoComplete="off" 
																					   autoCorrect="off" 
																					   id="react-select-9-input" 
																					   spellCheck="false" tabIndex="0" type="text" 
																					   aria-autocomplete="list" 
																					//    value={widgetStore.toolBarStyleSettings.bgColorCustom}
																					   value={ widgetStore.bgcolornew} 
																					   title={widgetStore.toolBarStyleSettings_bgColorerror ?'color must be in a hex format #FFFFFF':""} 
																					   onChange={ (event) => {widgetStore.toolBarStyleSettings.bgColorCustom = event.target.value}}
																					   onBlur={ (event) => {widgetStore.checkhexapattern(event.target.value,1);widgetStore.toolBarStyleSettings.bgColor.colorCode = widgetStore.toolBarStyleSettings.bgColorCustom; this.setToolBarBackGround()}}
																					   style={{backgroundColor: widgetStore.bgcolornew, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarStyleSettings.bgColor.colorCode,  '#FFFFFF', '#000000')}}
																				/>
																				
																			</div>
																		</div>
																	</div>
																	<div className="css-1wy0on6" onClick={() => {widgetStore.bgColorSelector = !widgetStore.bgColorSelector; widgetStore.textColorSelector = false;widgetStore.toolTipBgSelector = false; widgetStore.toolTipTextColorSelector = false;widgetStore.colorpickerevent="bgcolor" }}>
																		<span className="css-d8oujb"></span>
																		<div aria-hidden="true" className="css-1ep9fjw">
																			<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
																				<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															{
																widgetStore.bgColorSelector &&
																	<CompactPicker className="colorSelector"
																					// color={ widgetStore.toolBarStyleSettings.bgColor.colorCode } 
																					color={ widgetStore.bgcolornew } 
																					onChange={() => widgetStore.colorpickerevent="bgcolor"}
																					onChangeComplete={(color,event) => {widgetStore.setcolorpickervalue(color.hex);this.setToolBarBackGround(color.hex)}}/>
															}
														    
														</ClickOutHandler>
												}
											</div>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Text color")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn customOption">
								    		<div className="row m-0">
									    		<DropDownComponent dropDownOptions={widgetStore.topBarTextColors}
										    						store={widgetStore}
												    				callBack={(selectedOption) => {widgetStore.toolBarStyleSettings.textColor = selectedOption; this.setToolBarText()}}
												    				selectedOption = {widgetStore.toolBarStyleSettings.textColor}/>
												{
													widgetStore.toolBarStyleSettings.textColor.value === "custom" && 
														<ClickOutHandler onClickOut={() => {widgetStore.textColorSelector=false;}}>
															<div className="css-10nd86i text-left colorSelector" style={{border:widgetStore.toolBarStyleSettings_bgtextColorerror? "2px solid rgb(255, 5, 5)":""}}>
																<div className="css-vj8t7z" style={{backgroundColor: widgetStore.bgtextnew}}>
																	<div className="css-1hwfws3">
																		<div className="css-1g6gooi">
																			<div className="">
																				<input autoComplete="off" 
																					   autoCorrect="off" 
																					   id="react-select-9-input" 
																					   spellCheck="false" tabIndex="0" type="text" 
																					   aria-autocomplete="list" 
																					//    value={widgetStore.toolBarStyleSettings.textColorCustom}
																					value={widgetStore.bgtextnew}
																					   title={widgetStore.toolBarStyleSettings_bgtextColorerror ?'color must be in a hex format #FFFFFF':""}
																					   onChange={ (event) => widgetStore.toolBarStyleSettings.textColorCustom = event.target.value}
																					   onBlur={ (event) => {widgetStore.checkhexapattern(event.target.value,2);widgetStore.toolBarStyleSettings.textColor.colorCode = widgetStore.toolBarStyleSettings.textColorCustom;this.setToolBarText()}}
																					   style={{backgroundColor: widgetStore.bgtextnew, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarStyleSettings.textColor.colorCode,  '#FFFFFF', '#000000')}}
																					   />
																				
																			</div>
																		</div>
																	</div>
																	<div className="css-1wy0on6" onClick={() => {widgetStore.textColorSelector = !widgetStore.textColorSelector; widgetStore.bgColorSelector = false;widgetStore.toolTipBgSelector = false; widgetStore.toolTipTextColorSelector = false;widgetStore.colorpickerevent="bgtext" }}>
																		<span className="css-d8oujb"></span>
																		<div aria-hidden="true" className="css-1ep9fjw">
																			<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
																				<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															{
																widgetStore.textColorSelector &&
																	<CompactPicker className="colorSelector"
																					// color={ widgetStore.toolBarStyleSettings.textColor.colorCode } 
																					color={ widgetStore.bgtextnew } 
																					onChange={() => widgetStore.colorpickerevent="bgtext"}
																					onChangeComplete= {(color,event) => {widgetStore.setcolorpickervalue(color.hex);this.setToolBarText(color.hex)}}/>
															}
														    
														</ClickOutHandler>
												}
												
											</div>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Gradient")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.toolBarStyleSettings.gradient}
									    							callBack = {() => {widgetStore.toolBarStyleSettings.gradient = !widgetStore.toolBarStyleSettings.gradient;this.setGradient()}}/>
									    </Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Rounded corners")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.toolBarStyleSettings.roundedCorners}
									    							callBack = {() => {widgetStore.toolBarStyleSettings.roundedCorners = !widgetStore.toolBarStyleSettings.roundedCorners;this.setBorderRadius()}}/>
									    </Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Bar thickness")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn customDrpDwn-small">
								    		<DropDownComponent dropDownOptions={widgetStore.toolBarThickness}
																store={widgetStore}
											    				callBack={(selectedOption) => {widgetStore.toolBarStyleSettings.barThickness = selectedOption; this.setBarThickness()}}
											    				selectedOption = {widgetStore.toolBarStyleSettings.barThickness}/>
											{
												widgetStore.toolBarStyleSettings.barThickness.value === 3 &&
													<div className="customSize">
										    			<Form.Group as={Row} controlId="formGroupMsg">
													    	<Col>
																<Form.Control type="text"
															        	onKeyDown={(e) => { widgetStore.checkForEnteredNumer(e) }}
																		name="customHeight" placeholder="" 
													    				value={widgetStore.toolBarStyleSettings.barThickness.height} 
																		onChange={(e) => {widgetStore.toolBarStyleSettings.barThickness.height = e.target.value; this.setBarThickness()}}
																		onBlur={(e) => this.checkBarThickness(e.target.value) } />
													    		<span>px (30-80)</span>
													    	</Col>
													  	</Form.Group>
													</div>
											}
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Text font")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.textFonts}
									    						store={widgetStore}
											    				callBack={(selectedOption) => {widgetStore.toolBarStyleSettings.textFont = selectedOption; this.setTextFont()}}
											    				selectedOption = {widgetStore.toolBarStyleSettings.textFont}/>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Tooltip style")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn">
								    		<DropDownComponent dropDownOptions={widgetStore.toolTipStyle}
									    						store={widgetStore}
											    				callBack={(selectedOption) => {widgetStore.toolBarStyleSettings.toolTipSettings = selectedOption; this.setToolTipValues()}}
											    				selectedOption = {widgetStore.toolBarStyleSettings.toolTipSettings}/>
								    	</Col>
								  	</Form.Group>
								  	{
								  		widgetStore.toolBarStyleSettings.toolTipSettings.value === 2 &&
								  		<div className="toolTipDesignWrapper">
								  		<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Background color")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn customOption">
								    		<div className="row m-0">
									    		<DropDownComponent dropDownOptions={widgetStore.topBarColors}
										    						store={widgetStore}
												    				callBack={(selectedOption) => {widgetStore.toolBarToolTipStyleSettings.bgColor = selectedOption; this.setToolTipBackGround()}}
												    				selectedOption = {widgetStore.toolBarToolTipStyleSettings.bgColor}/>
												{
													widgetStore.toolBarToolTipStyleSettings.bgColor.value ==="custom" && 
														 <ClickOutHandler onClickOut={() => {widgetStore.toolTipBgSelector = false;}}>
															<div className="css-10nd86i text-left colorSelector"  style={{border:widgetStore.toolBartooltip_bgColorerror? "2px solid rgb(255, 5, 5)":""}}>
																<div className="css-vj8t7z" style={{backgroundColor: widgetStore.ToolTipcolornew}}>
																	<div className="css-1hwfws3">
																		<div className="css-1g6gooi">
																			<div className="">
																				<input autoComplete="off" 
																					   autoCorrect="off" 
																					   id="react-select-9-input" 
																					   spellCheck="false" tabIndex="0" type="text" 
																					   aria-autocomplete="list" 
																					//    value={widgetStore.toolBarToolTipStyleSettings.bgColorCustom}
																					   value={widgetStore.ToolTipcolornew}
																					   title={widgetStore.toolBartooltip_bgColorerror ?'color must be in a hex format #FFFFFF':""}
																					   onChange={ (event) => {widgetStore.toolBarToolTipStyleSettings.bgColorCustom = event.target.value}}
																					   onBlur={ (event) => {widgetStore.checkhexapattern(event.target.value,3);widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode = widgetStore.toolBarToolTipStyleSettings.bgColorCustom; this.setToolTipBackGround()}}
																					   style={{backgroundColor: widgetStore.ToolTipcolornew, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode,  '#FFFFFF', '#000000')}}
																					   />
																				
																			</div>
																		</div>
																	</div>
																	<div className="css-1wy0on6" onClick={() => {widgetStore.toolTipBgSelector = !widgetStore.toolTipBgSelector; widgetStore.toolTipTextColorSelector = false;widgetStore.bgColorSelector = false;widgetStore.textColorSelector = false;widgetStore.colorpickerevent="tooltipcolor" }}>
																		<span className="css-d8oujb"></span>
																		<div aria-hidden="true" className="css-1ep9fjw">
																			<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
																				<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															{
																widgetStore.toolTipBgSelector &&
																	<CompactPicker className="colorSelector"
																					// color={ widgetStore.toolBarToolTipStyleSettings.bgColor.colorCode } 
																					color={ widgetStore.ToolTipcolornew } 
																					onChange={() => widgetStore.colorpickerevent="tooltipcolor"}
																					onChangeComplete={(color,event) => {widgetStore.setcolorpickervalue(color.hex);this.setToolTipBackGround()}}
																					/>
															}
														    
														</ClickOutHandler>
												}
											</div>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Text color")}</Form.Label>
								    	<Col sm="6" lg="7" className="customDrpDwn customOption">
								    		<div className="row m-0">
									    		<DropDownComponent dropDownOptions={widgetStore.topBarTextColors}
										    						store={widgetStore}
												    				callBack={(selectedOption) => {widgetStore.toolBarToolTipStyleSettings.textColor = selectedOption; this.setToolTipText()}}
												    				selectedOption = {widgetStore.toolBarToolTipStyleSettings.textColor}/>
												{
													widgetStore.toolBarToolTipStyleSettings.textColor.value === "custom" && 
														<ClickOutHandler onClickOut={() => {widgetStore.toolTipTextColorSelector=false;}}>
															<div className="css-10nd86i text-left colorSelector" style={{border:widgetStore.toolBartooltip_bgtextColorerror? "2px solid rgb(255, 5, 5)":""}}>
																<div className="css-vj8t7z" style={{backgroundColor: widgetStore.ToolTiptextnew}}>
																	<div className="css-1hwfws3">
																		<div className="css-1g6gooi">
																			<div className="">
																				<input autoComplete="off" 
																					   autoCorrect="off" 
																					   id="react-select-9-input" 
																					   spellCheck="false" tabIndex="0" type="text" 
																					   aria-autocomplete="list" 
																					//    value={widgetStore.toolBarToolTipStyleSettings.textColorCustom}
																					   value={ widgetStore.ToolTiptextnew}
																					   title={widgetStore.toolBartooltip_bgtextColorerror ?'color must be in a hex format #FFFFFF':""}
																					   onChange={ (event) => widgetStore.toolBarToolTipStyleSettings.textColorCustom = event.target.value}
																					   onBlur={ (event) => {widgetStore.checkhexapattern(event.target.value,4);widgetStore.toolBarToolTipStyleSettings.textColor.colorCode = widgetStore.toolBarToolTipStyleSettings.textColorCustom; this.setToolTipText()}}
																					   style={{backgroundColor: widgetStore.ToolTiptextnew, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarToolTipStyleSettings.textColor.colorCode,  '#FFFFFF', '#000000')}}
																					   />
																				
																			</div>
																		</div>
																	</div>
																	<div className="css-1wy0on6" onClick={() => {widgetStore.toolTipTextColorSelector = !widgetStore.toolTipTextColorSelector; widgetStore.toolTipBgSelector = false;widgetStore.bgColorSelector = false;widgetStore.textColorSelector = false;widgetStore.colorpickerevent="tooltiptext" }}>
																		<span className="css-d8oujb"></span>
																		<div aria-hidden="true" className="css-1ep9fjw">
																			<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
																				<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
																			</svg>
																		</div>
																	</div>
																</div>
															</div>
															{
																widgetStore.toolTipTextColorSelector &&
																	<CompactPicker className="colorSelector"
																					// color={ widgetStore.toolBarToolTipStyleSettings.textColor.colorCode } 
																					color={ widgetStore.ToolTiptextnew } 
																					onChange={() => widgetStore.colorpickerevent="tooltiptext"}
																					onChangeComplete= {(color,event) => {widgetStore.setcolorpickervalue(color.hex); this.setToolTipText()}}/>
															}
														    
														</ClickOutHandler>
												}
												
											</div>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Gradient")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.toolBarToolTipStyleSettings.gradient}
									    							callBack = {() => {widgetStore.toolBarToolTipStyleSettings.gradient = !widgetStore.toolBarToolTipStyleSettings.gradient; this.setToolTipGradient()}}/>
									    </Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Rounded corners")}</Form.Label>
								    	<Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={widgetStore.toolBarToolTipStyleSettings.roundedCorners}
									    							callBack = {() => {widgetStore.toolBarToolTipStyleSettings.roundedCorners = !widgetStore.toolBarToolTipStyleSettings.roundedCorners; this.setToolTipBorderRadius()}}/>
									    </Col>
								  	</Form.Group>
								  	</div>
								  	}
								  	
				  	
				</form>

		)
	}
})
export default StyleSettingsComponent;
