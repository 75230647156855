import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PieChart from 'react-minimal-pie-chart';


const DounutChartComponent = observer(class DounutChartComponent extends Component {

    constructor (props) {
        super(props);
    }

    
  //   draw() {
  //     Chart.types.Doughnut.prototype.draw.apply(this, arguments);

  //     var width = this.chart.width,
  //         height = this.chart.height;

  //     var fontSize = (height / 114).toFixed(2);
  //     this.chart.ctx.font = fontSize + "em Verdana";
  //     this.chart.ctx.textBaseline = "middle";

  //     var text = "82%",
  //         textX = Math.round((width - this.chart.ctx.measureText(text).width) / 2),
  //         textY = height / 2;

  //     this.chart.ctx.fillText(text, textX, textY);
  // }
    render(){
        const {appstore} = this.props;
        let self = this;

      


        return (
<React.Fragment>

<PieChart
 animate={false}
 animationDuration={500}
 animationEasing="ease-out"
 cx={37}
 cy={37}
 data={[
   {
     color: '#7fae72',
     title: 'One',
     value: 49
   },
   {
     color: 'orange',
     title: 'Two',
     value: 40
   },
   
 ]}
 label={false}
 labelPosition={50}
 
 lengthAngle={360}
 lineWidth={45}
 onClick={undefined}
 onMouseOut={undefined}
 onMouseOver={undefined}
 paddingAngle={1}
 radius={35}
 rounded={false}
 startAngle={0}
 viewBoxSize={[
   100,
   100
 ]}
/>
	
</React.Fragment>

            )
        }
        })
        
export default DounutChartComponent;