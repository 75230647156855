import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

const SupervisorMsgComponent = observer(class SupervisorMsgComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }
    
    clearChat() {

    	if(this.props.appStore.visitorModel.hasOwnProperty('talkSessionMessages'))
    	   this.props.appStore.visitorModel.talkSessionMessages.length=0; 
	 }
	 
	handleClick = (store,appstore,status) =>{
	
		if(status === "opQuit"){
			//for quit chat, not for supervision
			store.quitChatConfirm=true;
			return;
		}
		else if(status === "supervisionQuit"){
			 store.currentChattingVisitor[0].quitAndBackTosupervision = true;
             store.quitChat(appstore);
		}
		else if(status === "joinSupervision"){
		//send supervision status to operator and visitor for setting desitination
		store.joinSupervisor(appstore);

	  }
	} 

	render(){
		const { appStore,
			    store,
				chatComponentStore,
				msgData,
				status
			  } = this.props;
		var self = this;

		return (
				
			<div className="chatSection supervisorChat col-12">
				<div className="chatMsg float-left">
					<p className="response text-center cursor-pointer" dangerouslySetInnerHTML={{__html: msgData}} onClick={() => this.handleClick(store,appStore,status)}>
					</p>
				</div>
			</div>
			
		)
	}
})
export default SupervisorMsgComponent;
