import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const InfoContentComponent = observer(class InfoContentComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
		
	}

	
	componentWillUnmount() {
    }


	render(){
		const { appstore,
				chatComponentStore } = this.props;
		var self = this;
		return (
			<div className="tabContent">
				{
				  	!chatComponentStore.hideInfoSection && 
				  	<React.Fragment>
						<h6>{chatComponentStore.infoTabData.name}</h6>
						<div className="col-12 data">
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Email")}:</p>
								</div>
								<div className="col-6 value">
									<p>{chatComponentStore.infoTabData.email}</p>
								</div>
							</div>
							{chatComponentStore.infoTabData.location!="" &&
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Location")}:</p>
								</div>
								<div className="col-6 value" onClick={() => chatComponentStore.showgeoMap=true}>
									<p className="withImage">{chatComponentStore.infoTabData.location}<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.pin_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.pin_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.pin_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.pin_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.pin_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.pin_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.pin_voilet : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.pin_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.pin_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.pin_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.pin_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.pin_skyblue : imageURLs.pin_steelblue} className="img-fluid" /></p>
								</div>
							</div>
							}
							{chatComponentStore.infoTabData.location=="" &&
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Location")}:</p>
								</div>
								<div className="col-6 value" onClick={() => chatComponentStore.showgeoMap=true}>
									<p className="withImage">{appstore.getLanguageTrans("Location not found.")}</p>
								</div>
							</div>
							}
							{chatComponentStore.infoTabData.privacyPolicyConsent &&
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Consent with Privacy Policy")}:</p>
								</div>
								<div className="col-6 value">
									<p>Yes</p>
								</div>
							</div>
							}
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Website")}:</p>
								</div>
								<div className="col-6 value">
									<p>{chatComponentStore.infoTabData.website}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Department")}:</p>
								</div>
								<div className="col-6 value">
									<p>{chatComponentStore.infoTabData.dept}</p>
								</div>
							</div>
							<div className="row">
								<div className="webInfo col-12">
								 { chatComponentStore.fullcontactFound && 
								   <React.Fragment>
									<p>{appstore.getLanguageTrans("Information discovered on the web about this visitor")}</p>
									<div className="row">
										<div className="col-4 label">
											<p>{appstore.getLanguageTrans("Profile Image")}:</p>
										</div>
										<div className="col-6 value">
									    	<img src={chatComponentStore.infoTabData.fullContact.avatar ? chatComponentStore.infoTabData.fullContact.avatar :imageURLs.profilePageIcon} className="img-fluid fc_profile_img" />
										</div>
									</div>
									
									<div className="row">
										<div className="col-4 label">
											<p>{appstore.getLanguageTrans("Full name")}:</p>
										</div>
										<div className="col-6 value">
											<p>{chatComponentStore.infoTabData.fullContact.fullName}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-4 label">
											<p>{appstore.getLanguageTrans("Gender")}:</p>
										</div>
										<div className="col-6 value">
											<p>{chatComponentStore.infoTabData.fullContact.gender}</p>
										</div>
									</div>
								
									 <div className="row">
										<div className="col-4 label">
											<p>{appstore.getLanguageTrans("Social Profiles")}:</p>
										</div>
										<div className="col-6 value">
										  { chatComponentStore.infoTabData.fullContact.twitter &&
											<a href={chatComponentStore.infoTabData.fullContact.twitter} target="_blank">
												 <img src={appstore.otherIcons+"op/images/social/twitter.png"} className="img-fluid" style={{marginLeft:5,cursor:"pointer"}} />
										   </a>
										  }
										  { chatComponentStore.infoTabData.fullContact.facebook &&
											<a href={chatComponentStore.infoTabData.fullContact.facebook} target="_blank">
												 <img src={appstore.otherIcons+"op/images/social/facebook.png"} className="img-fluid" style={{marginLeft:5,cursor:"pointer"}} />
										   </a>
										  }
										  { chatComponentStore.infoTabData.fullContact.linkedin &&
											<a href={chatComponentStore.infoTabData.fullContact.linkedin} target="_blank">
												 <img src={appstore.otherIcons+"op/images/social/linkedin.png"} className="img-fluid" style={{marginLeft:5,cursor:"pointer"}} />
										   </a>
										  }
										 
										</div>
									</div>
									
									<div className="row">
										<div className="col-4 label">
											<p>{appstore.getLanguageTrans("Location")}:</p>
										</div>
										<div className="col-6 value">
											<p >{chatComponentStore.infoTabData.fullContact.location}</p>
										</div>
									</div>
									</React.Fragment>
							      }
								  {
									  !chatComponentStore.fullcontactFound &&
									//    <p>{appstore.getLanguageTrans("Searching information on the web...")}</p>
									<p>{appstore.getLanguageTrans("Information not found.")}</p>
								  } 
								</div>
								
							</div>
							<div className="col-12 rowLayout">
								<div className="row label">
									<p>{appstore.getLanguageTrans("Current page")}:</p>
								</div>
							{  
							  chatComponentStore.infoTabData.currentPage &&
								<div className="row value">
									<p className="link">{chatComponentStore.infoTabData.currentPage}<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.boxlink_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.boxlink_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.boxlink_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.boxlink_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.boxlink_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.boxlink_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.boxlink_voilet : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.boxlink_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.boxlink_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.boxlink_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.boxlink_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.boxlink_skyblue : imageURLs.boxlink_steelblue} className="img-fluid" onClick={() => window.open(chatComponentStore.infoTabData.currentPage,'_blank')} /></p>
								</div>
							}
							</div>
							
							<div className="col-12 rowLayout">
								<div className="row label">
									<p>{appstore.getLanguageTrans("Start page:")}</p>
								</div>
								{ chatComponentStore.infoTabData.startPage &&
								<div className="row value">
									<p className="link">{chatComponentStore.infoTabData.startPage}<img src={imageURLs.linkIcon} className="img-fluid" onClick={() => window.open(chatComponentStore.infoTabData.currentPage,'_blank')}/></p>
								</div>
								}
							</div>
							
							<div className="col-12 rowLayout">
								<div className="row label">
									<p>{appstore.getLanguageTrans("Referrer")}:</p>
								</div>
								<div className="row value">
									<p className={chatComponentStore.infoTabData.referrer === '' ? 'disabled' : ''}>{chatComponentStore.infoTabData.referrer === '' ? 'Not found' : chatComponentStore.infoTabData.referrer}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Screen")}:</p>
								</div>
								<div className="col-6 value">
									<p>{chatComponentStore.infoTabData.screen}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Browser")}:</p>
								</div>
								<div className="col-6 value">
									<p>{chatComponentStore.infoTabData.browser}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("IP address")}:</p>
								</div>
								<div className="col-6 value">
									<p>{chatComponentStore.infoTabData.ip}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-4 label">
									<p>{appstore.getLanguageTrans("Language")}:</p>
								</div>
								<div className="col-6 value">
									<p>{chatComponentStore.infoTabData.language}</p>
								</div>
							</div>
							<div className="row">
								<div className="notes col-12">
									<h6>{appstore.getLanguageTrans("Notes")}</h6>
									<div className="notesData col-12">
										{  chatComponentStore.infoTabData.notes.length !=0 &&
											chatComponentStore.infoTabData.notes.map(function(note, noteIndex){
												return(
													<div className="row" key={noteIndex}>
														<p className="m-0">
															<span>{moment(note.time).format("DD-MM-YYYY HH:mm:ss")}</span>
															<span> by </span>
															<span>{note.by}</span>
														</p>
														<p>{note.note}</p>
													</div>
												)
											})
										}
									</div>
									{
										chatComponentStore.infoTabAddNotes && 
											<textarea className="col-12" name="note" rows="5" value={chatComponentStore.infoNote} onChange={(e) => chatComponentStore.infoNote = e.target.value} />
									}
											    
									<button type="button" className="custom_btn float-right addBtn btn btn-primary text-uppercase" onClick={() => chatComponentStore.addUserNotes(appstore,chatComponentStore.infoTabData.ip)}>{appstore.getLanguageTrans("Add")}</button>
								</div>
							</div>
						</div>
					</React.Fragment>
				}
			</div>
		)
	}
})
export default InfoContentComponent;
