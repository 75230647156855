import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import CustomScroll from 'react-custom-scroll';
import arrayMove from 'array-move';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import RadioButtonComponent from '../../../sharedComponents/radionButton2/RadioButton';
import CheckBoxComponent from '../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import CheckBoxCustomComponent from '../../../sharedComponents/checkBox_2/checkBoxCustom2';
import navigationStore from '../../../sharedFiles/navigation-store';
import FormValidator from '../../../sharedFiles/FormValidator';
import ConfirmationComponent from '../../../sharedComponents/confirmationPopup/ConfirmationComponent';

import TextEditor from '../../../sharedComponents/textEditor/textEditor';
import ChatResponse from './components/chatResponse';

import ResponseStore from './ResponseStore';
import axios from 'axios';
import ImagesUploader from 'react-images-uploader';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import ImageCropComponent from '../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';
import { GridLoader } from 'react-spinners';
import { css } from '@emotion/core';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

//jpg, png, bmp, pdf, doc, docx, txt types to be allowed for canned messages
const imageMaxSize = 2000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif,application/pdf,image/bmp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-word.document.macroEnabled.12,application/vnd.ms-word.template.macroEnabled.12'
const onlyImages = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const htmlToText = require('html-to-text');

const text = htmlToText.fromString('<h1>Hello World</h1>', {
	wordwrap: 130
});

const ResponseComponent = observer(class ResponseComponent extends Component {


	constructor(props) {
		super(props);
		this.responseStore = new ResponseStore();
		this.fileInputRef = React.createRef();
		//calling ajax method in constructor, because dropdown defaultValue is set only on initial render, so calling in componentdidMount wont work

		this.validator = new FormValidator([
			{
				field: 'question',
				method: 'isEmpty',
				validWhen: false,
				message: 'Title is required.'
			},
			{
				field: 'value',
				method: 'isEmpty',
				validWhen: false,
				message: 'Message is required.'
			}
		]);

		this.state = {
			search: "",
			validation: this.validator.valid(),
			files: [],
			isresponseLoaded: false,
			maxImage: false,
			maxImageText: false,
			uploading: false,
			images: "",
			actual_file: []
		}
		this.submitted = false;
		// this.handleImgChange = this.handleImgChange.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
		this.updateSearch = this.updateSearch.bind(this);
		this.deleteImage = this.deleteImage.bind(this);
		this.setImageforDelete = this.setImageforDelete.bind(this);
	}

	componentDidMount() {
		this.props.appstore.menuChanged = window.location.pathname;
		this.getResponsesData();
	}

	getResponsesData = () => {
		this.setState({ isresponseLoaded: true })
		//get response data for response  
		axios.get(this.props.appstore.url + 'getresponsedata.json')
			.then(result => {
				if(result.data.length==1){
					if(result.data.logoutstatus==1){
					this.props.appstore.logout();
					}
				}
				// var d = new Date();
				// var systime=d.valueOf();
				//load dropdown data
				if (this.props.appstore.isAdmin) {
					this.responseStore.setOperators(result.data.dropdowndatavo.usersmodel, this.props.appstore);
				}
				this.responseStore.setDepartments(result.data.dropdowndatavo.departmentsmodel, this.props.appstore);
				this.responseStore.setWebsites(result.data.dropdowndatavo.websitesmodel, this.props.appstore);

				//load response data
				this.responseStore.showResponses(result.data, this.props.appstore);
				var d1 = new Date();
				// var systimefinal=d1.valueOf();
				// console.log(systime);
				// console.log(systimefinal);
				// console.log(systimefinal-systime);
				this.setState({ isresponseLoaded: false })
				this.props.appstore.global_errorcount=0;
			}).catch((error) => {
				// if (error.response) {
				// 	 this.props.appstore.logout();
				// }
				this.setState({ isresponseLoaded: false })
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.appstore.logout();
				}
			});
	}

	getsaveResponsesData = () => {
		//get response data for response  
		axios.get(this.props.appstore.url + 'getresponsedata.json')
			.then(result => {
				if(result.data.length==1){
					if(result.data.logoutstatus==1){
					  this.props.appstore.logout();
					}
				  }
				//load dropdown data
				if (this.props.appstore.isAdmin) {
					this.responseStore.setOperators(result.data.dropdowndatavo.usersmodel, this.props.appstore);
				}
				this.responseStore.setDepartments(result.data.dropdowndatavo.departmentsmodel, this.props.appstore);
				this.responseStore.setWebsites(result.data.dropdowndatavo.websitesmodel, this.props.appstore);

				//load response data
				this.responseStore.showResponses(result.data, this.props.appstore);
				this.setState({ isresponseLoaded: false })
									
				this.responseStore.saveValues();
				this.props.appstore.global_errorcount=0;
			}).catch((error) => {
				// if (error.response) {
				// 	 this.props.appstore.logout();
				// }
				this.setState({ isresponseLoaded: false })
				this.responseStore.saveValues();
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.appstore.logout();
				}
			});
	}

	handleInputclick(e) {
		this.refs.fileUploader.click();
	}

	setImageforDelete(event, action) {
		this.responseStore.imageForDelete = event.currentTarget.id;
		this.setState({ actionmode: action });
		this.responseStore.deleteImage = true;
	}

	deleteImage(event) {
		let actions = this.state.actionmode;
		if (event == undefined) {
			return;
		}
		this.responseStore.deleteImage = false;
		if (actions == 'new') {
			const id = this.responseStore.imageForDelete;
			let index = id.slice(3);
			let newFileArray = [];
			let newFileselectedArray = [];
			let mimearray = [];
			let filearray = [];
			let filnamearray=[];
			for (let i in this.responseStore.file) {
				if (i != index) {
					newFileArray.push(this.responseStore.arraycroppedimg[i]);
					newFileselectedArray.push(this.responseStore.selectedfile[i]);
					mimearray.push(this.responseStore.fileMimeType[i]);
					filearray.push(this.responseStore.file[i]);
					filnamearray.push(this.responseStore.fileNames[i]);
				}
			}
			this.responseStore.arraycroppedimg = newFileArray;
			this.responseStore.selectedfile = newFileselectedArray;
			this.responseStore.fileMimeType = mimearray;
			this.responseStore.file = filearray;
			this.responseStore.fileNames = filnamearray; 
		}
		if (actions == 'edit') {
			const id = this.responseStore.imageForDelete;
			let index = id.slice(3);
			let newFileArray = [];
			let deleteFileArray = [];
			for (let i in this.responseStore.responseChangeObj.imageslist) {
				if (i != index) {
					// console.log(this.responseStore.responseChangeObj.imageslist[i]); 
					newFileArray.push(this.responseStore.responseChangeObj.imageslist[i]);
				} else {
					deleteFileArray.push(this.responseStore.responseChangeObj.imageslist[i]);
				}
			}

			this.responseStore.modifiedimages.push(deleteFileArray[0].images);

			this.responseStore.deleteResponseImg(this.props.appstore);
			this.responseStore.responseChangeObj.imageslist = newFileArray
			let  response = this.responseStore.responseChangeObj;
			for(let i in this.responseStore.responses){
				if(response.response_uid==this.responseStore.responses[i].response_uid){
					this.responseStore.responses[i].imageslist=this.responseStore.responseChangeObj.imageslist;
					break;
				}
			}

			//console.log(this.responseStore.responseChangeObj.imageslist);
		}
		if (actions == 'existing') {
			const id = this.responseStore.imageForDelete;
			let index = id.slice(3);
			let newFileArray = [];
			for (let i in this.responseStore.responseImage) {
				if (i != index) {
					// console.log(this.responseStore.responseImage[i]);
					newFileArray.push(this.responseStore.responseImage[i]);
				}
			}
			this.responseStore.responseImage = newFileArray
		}
		let countimages = this.responseStore.arraycroppedimg.length + this.responseStore.responseChangeObj.imageslist.length + this.responseStore.responseImage.length;

		if (countimages < 3) {
			// this.setState({ maxImage: false });
			this.responseStore.maxImage = false;
		}
	}

	updateSearch(event) {
		this.setState({ search: event.target.value.substr(0, 20) });

	}
	onSortEnd(data) {
		this.responseStore.responses = arrayMove(this.responseStore.responses, data.oldIndex, data.newIndex);
		// console.log(this.responseStore.responses); 
		let sortedresponse = this.responseStore.responses;
		let rearranged_response = [];
		for (let i in sortedresponse) {
			if (sortedresponse[i].type == 'composite') {
				rearranged_response.push(sortedresponse[i]);
			}
		}
		for (let i in sortedresponse) {
			if (sortedresponse[i].type == 'file') {
				rearranged_response.push(sortedresponse[i]);
			}
		}
		// this.responseStore.responses 
		this.reponse_order(rearranged_response);
	}

	handleFormSubmit(event) {
		event.preventDefault();
		
		let responseData = this.responseStore.responseChangeObj;
		let valuetextediter = responseData.value;
		const textvalue = htmlToText.fromString(valuetextediter);
		responseData.value = textvalue;
		const validation = this.validator.validate(responseData);
		this.setState({ validation });
		this.submitted = true;
		if (validation.isValid) {
			responseData.value = valuetextediter;
			this.setState({ isresponseLoaded: true })
			//set response_uid for new response , avoid for edit response
			responseData.response_uid = responseData.response_uid ? responseData.response_uid : '';
			// handle as per user role
			const formData = new FormData()
			formData.set('id', '100');
			if (this.responseStore.arraycroppedimg.length != 0 && this.responseStore.arraycroppedimg.length != undefined) {

				this.state.images = this.responseStore.arraycroppedimg;
				this.state.mimetype = this.responseStore.fileMimeType;

				this.setState({ uploading: true })
				for (let i = 0; i < this.responseStore.file.length; i++) {
					formData.append("files", this.responseStore.file[i])
					formData.append("actualfile", this.responseStore.selectedfile[i])
				}

				formData.append("fileMimeType", this.state.mimetype);
			}
			if (this.props.appstore.isAdmin) {
				//if dropdown options are not selected then set to empty , while fetching set it as ALL.
				responseData.website = responseData.website == undefined ? "" : responseData.website;
				responseData.department = responseData.department == undefined ? "" : responseData.department;
				responseData.operator = responseData.operator == undefined ? "" : responseData.operator;
				responseData.department_uid = responseData.department.department_uid != undefined ? responseData.department.department_uid : "";
				responseData.website_uid = responseData.website.website_uid != undefined ? responseData.website.website_uid : "";
				responseData.op_uid = responseData.operator.user_uid != undefined ? responseData.operator.user_uid : "";
				formData.append("website", responseData.website);
				formData.append("department", responseData.department);
				formData.append("operator", responseData.operator);

				formData.append("department_uid", responseData.department_uid);
				formData.append("website_uid", responseData.website_uid);
				formData.append("op_uid", responseData.op_uid);
			} else {
				//  responseData.department_uid = "";
				// responseData.website_uid = "";
				responseData.department_uid = responseData.department.department_uid != undefined ? responseData.department.department_uid : "";
				responseData.website_uid = responseData.website.website_uid != undefined ? responseData.website.website_uid : "";
				responseData.op_uid = responseData.op_uid == undefined ? "" : responseData.op_uid;
				responseData.op_uid = responseData.op_uid.length != 0 ? responseData.op_uid : ""; 


				formData.append("department_uid", responseData.department_uid);
				formData.append("website_uid", responseData.website_uid);
				formData.append("op_uid", responseData.op_uid);
			}
			formData.append("response_uid", responseData.response_uid);
			formData.append("deleteimages", this.responseStore.modifiedimages);
			formData.append("question", responseData.question);
			formData.append("value", responseData.value);
			formData.append("type", responseData.type);
			// responseData
			// console.log(formData);
			axios({
				method: 'POST',
				url: this.props.appstore.url + 'saveresponse.json',
				headers: { 'content-type': 'multipart/form-data' },
				data: formData
			}).then(result => {
				if(result.data.length==1){
					if(result.data.logoutstatus==1){
					  this.props.appstore.logout();
					}
					}
				if (result.data.msg) {
					this.responseStore.titleExist = true;
				} else {
					this.responseStore.resetValues();
					this.getsaveResponsesData();	
				}
				this.props.appstore.global_errorcount=0;
			}).catch((error) => {

				// if (error.response) {
				// 	//console.log(error)
				// 	this.props.appstore.logout();
				// }
				this.responseStore.resetValues();
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
			});

			this.submitted = false;
		}
	}



	filterResponses = () => {
		//Object.keys(this.responseStore.responses).length !== 0 && this.responseStore.responses.constructor === Object
		return this.responseStore.responses.filter(
			(response) => {
				return response.question.toLowerCase().indexOf(this.state.search.toLowerCase()) != -1 ||
					response.value.toLowerCase().indexOf(this.state.search.toLowerCase()) != -1;

			})
	}


	componentWillUnmount() {
		window.scrollTo(0, 0);
	}

	confirmDeleteRule = (event) => {
		if (event == undefined) {
			return;
		}
		let deleteResponse = this.responseStore.responseForDelete;
		if (this.responseStore.deleteResponseConfirm) {
			axios({
				method: 'POST',
				url: this.props.appstore.url + 'deleteresponse.json',
				headers: { 'content-type': 'application/json' },
				data: deleteResponse,
			}).then(result => {

				//get update responses
				this.getResponsesData();
				this.responseStore.deleteResponseConfirm = false;
				this.props.appstore.global_errorcount=0;
			}).catch((error) => {

				// if (error.response) {
				// //	console.log(error);
				// 	this.props.appstore.logout();
				// }
				this.responseStore.deleteResponseConfirm = false;
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}

			});
		}

		{/*}  let responseFordelete = this.responseForDelete;
		console.log("responseFordelete :"+responseFordelete);
		if(this.deleteResponseConfirm){
		  console.log("insed");
		  //this.deleteResponseConfirm = false;
		  let count=0;        
		  for (let i in this.responses) {
		  let index = this.responses[i].question.indexOf(responseFordelete);
		  if (index > -1) {
			console.log("count :"+count);
			this.responses.splice(count, 1);
			}
		  count++;    

		}
		  this.responseForDelete="";
		*/}
	}


	handleWebsiteFilter = (event) => {
		this.setState({ search: "" });
		this.responseStore.listFilter.website = event;
		this.responseStore.listFilter.department = this.responseStore.filterDeptDrpDwnOptions[0];

		//set dept belongs to website  
		if (this.responseStore.listFilter.website.website_uid.length == 0) {
			this.responseStore.filterDeptDrpDwnOptions = this.responseStore.allDepartments.slice(0);
			this.responseStore.listFilter.department = this.responseStore.filterDeptDrpDwnOptions[0];
			this.responseStore.responses = this.responseStore.allResponses;
			return;
		}
		this.websiteDeptfilter(event, "website");
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getDeptsforWebsite.json',
			headers: { 'content-type': 'application/json' },
			data: this.responseStore.listFilter.website,
		}).then(result => {
			//console.log('getDeptsforWebsite data*******');
			// console.log(result);
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			this.responseStore.setDepartmentsforWebsites(result.data);
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	//console.log(error)
			// 	this.props.appstore.logout();
			// }
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		});

	}

	websiteDeptfilter = (data, type) => {
		let deptUid = this.responseStore.listFilter.department;
		let webUid = this.responseStore.listFilter.website;

		let filteredResponses = this.responseStore.allResponses.filter(function (response) {
			//|| response["department_uid"] !== deptUid.department_uid
			if (webUid.website_uid && deptUid.department_uid.length == 0) {
				if (response["website_uid"] !== webUid.website_uid)
					return false;
			} else if (webUid.website_uid && deptUid.department_uid) {
				if (response["website_uid"] !== webUid.website_uid || response["department_uid"] !== deptUid.department_uid)
					return false;
			} else if (webUid.website_uid.length == 0 && deptUid.department_uid) {
				if (response["department_uid"] !== deptUid.department_uid)
					return false;
			}

			return true;
		});

		this.responseStore.responses = filteredResponses;
	}

	handleDeptFilter = (event) => {
		this.responseStore.listFilter.department = event;
		//this.responseStore.listFilter.website = this.responseStore.filterWebsiteDrpDwnOptions[0];
		this.websiteDeptfilter(event, "department")
	}

	setaddResponseDropdown = (event) => {

		if (event.website_uid || event.optionType === "websiteAll") {
			//if website option is clicked  
			this.responseStore.responseChangeObj.website = event;
			this.responseStore.responseChangeObj.department = "";
			this.responseStore.responseChangeObj.operator = "";
		} else if (event.department_uid || event.optionType === "deptAll") {
			this.responseStore.responseChangeObj.department = event;
			this.responseStore.responseChangeObj.operator = "";
		} else {
			this.responseStore.responseChangeObj.operator = event;
		}

		let selectedOptions = {
			"website_uid": this.responseStore.responseChangeObj.website.website_uid == null ? "" : this.responseStore.responseChangeObj.website.website_uid,
			"department_uid": this.responseStore.responseChangeObj.department.department_uid == null ? "" : this.responseStore.responseChangeObj.department.department_uid
		}

		if (selectedOptions.website_uid === "" && selectedOptions.department_uid === "") {
			this.responseStore.operatorDrpDwnOptions = JSON.parse(JSON.stringify(this.responseStore.allOperators));
			return;
		}

		axios({
			method: 'POST',
			url: this.props.appstore.url + "getDropdownDataforAdmin.json",
			headers: { 'content-type': 'application/json' },
			data: selectedOptions,
		}).then(result => { 
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			if(selectedOptions.department_uid === ""){
				this.responseStore.setDepartments(result.data.departmentsmodel, this.props.appstore);
				this.responseStore.setOperators(result.data.usersmodel);
			}else{
				this.responseStore.setOperators(result.data.usersmodel);
			}
			
			// if (result.data.departmentsmodel != null) {
			// 	// this.responseStore.setDepartments(result.data.departmentsmodel, this.props.appstore); 
			// 	this.responseStore.setOperators(result.data.usersmodel);
			// } else {				
			// 	this.responseStore.setOperators(result.data.usersmodel);
			// }
			//this.responseStore.setDepartmentsforWebsites(result.data);
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	//console.log(error)
			// 	this.props.appstore.logout();
			// }
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		});

	}

	reponse_order = (order) => {
		//set order in db
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'responseorder.json',
			headers: { 'content-type': 'application/json' },
			data: order,
		}).then(result => {
			//console.log('response order data*******');
			// console.log(result);
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			if (result.data.status) {
				this.props.appstore.userInfo.usersmodel = result.data;
			} else {
				console.log("response order save failed");
			}
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {

			// if (error.response) {
			// 	//console.log(error)
			// 	this.props.appstore.logout();
			// }
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		});
	}


	//-- Croping Images --//
	verifyFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0]
	
			const currentFileType = currentFile.type

			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size;
			if (currentFileSize > imageMaxSize) {
				this.responseStore.warning_image_name = this.props.appstore.getLanguageTrans("Oops, the file is too large. The file size should be less than 2Mb.");
				this.responseStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.responseStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("Oops, File format not supported.");
				this.responseStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}

	verifyNotImage = (files) => {
		const currentFile = files[0];
		const currentFileType = currentFile.type

		if (onlyImages.includes(currentFileType))
			return false;

		else
			return true;
	}

	onSelectFile = e => {

		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) { 
					const currentFileType = files[0].type
					this.responseStore.file.push(files[0]);
					this.responseStore.fileNames.push(files[0].name);
					this.responseStore.selectedfile.push(files[0]);
					this.responseStore.fileMimeType.push(currentFileType);
				if (this.responseStore.arraycroppedimg.length == 0) {
					this.responseStore.arraycroppedimg[0] = files[0];
				} else {
					this.responseStore.arraycroppedimg.push(files[0]);
				}
				let countattachments = this.responseStore.arraycroppedimg.length + this.responseStore.responseChangeObj.imageslist.length;
				if (countattachments >= 3) { 
					this.responseStore.maxImage = true;
				} 
				// if (this.verifyNotImage(files)) {
				// 	//for other files, ot images
				// 	if (this.responseStore.arraycroppedimg.length == 0) {
				// 		this.responseStore.arraycroppedimg[0] = files[0];
				// 	} else {
				// 		this.responseStore.arraycroppedimg.push(files[0]);
				// 	}
				// 	const currentFileType = files[0].type
				// 	this.responseStore.file.push(files[0]);
				// 	this.responseStore.selectedfile.push(files[0]);
				// 	this.responseStore.fileMimeType.push(currentFileType);


				// }
				// else {
				// 	const reader = new FileReader();
				// 	reader.addEventListener('load', () =>
				// 		// this.setState({ src: reader.result })
				// 		this.responseStore.cropimage.src = reader.result
				// 	);
				// 	reader.readAsDataURL(e.target.files[0]);
				// 	this.responseStore.selectedfile.push(e.target.files[0]);
				// 	this.responseStore.imageCropModel = true;
				// }
			}
		}
	};
	onCropComplete = crop => {
		this.responseStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) {
		if (this.responseStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif';
			const mimetype = this.state.imagetype == 'image/png' ? 'image/png'
				: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
					: 'image/gif';
			this.responseStore.fileMimeType.push(mimetype);
			const croppedImageUrl = await this.getCroppedImg(
				this.responseStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			
			if (this.responseStore.arraycroppedimg.length == 0) {
				this.responseStore.arraycroppedimg[0] = croppedImageUrl;
			} else {
				this.responseStore.arraycroppedimg.push(croppedImageUrl);
			}
			let countattachments = this.responseStore.arraycroppedimg.length + this.responseStore.responseChangeObj.imageslist.length;
			if (countattachments >= 3) {
				// this.setState({ maxImage: true });
				this.responseStore.maxImage = true;
			}
			// this.setState({ croppedImageUrl }); 
			this.responseStore.cropimage.croppedImageUrl = croppedImageUrl;

		}
	}
	changestate() {
		// this.setState({ maxImage: false });
		this.responseStore.maxImage = false;
	}

	handleClearToDefault = event => {
		// console.log(event);
		this.responseStore.imageCropModel = false;
		this.responseStore.selectedfile = "";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.responseStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName, mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.responseStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		this.responseStore.file.push(myNewCroppedFile)
		
		// download file
		// this.props.appstore.downloadBase64File(imageData64, myFilename)
		// this.handleClearToDefault()

		// return new Promise((resolve, reject) => {
		// 	canvas.toBlob(blob => {
		// 		if (!blob) {
		// 			//reject(new Error('Canvas is empty'));
		// 			console.error('Canvas is empty');
		// 			return;
		// 		}
		// 		blob.name = fileName;
		// 		window.URL.revokeObjectURL(this.fileUrl);
		// 		this.fileUrl = window.URL.createObjectURL(blob);
		// 		resolve(this.fileUrl);
		// 	}, mimetype);
		// });
		return myNewCroppedFile;
	}


	render() {
		const { crop, croppedImageUrl, src } = this.responseStore.cropimage;
		let validation = this.submitted ?                         // if the form has been submitted at least once
			this.validator.validate(this.responseStore.responseChangeObj) :   // then check validity every time we render
			this.state.validation
		// if (this.state.isresponseLoaded) {
		// 	return <div></div>
		// }
		if (this.state.isresponseLoaded) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}
		
		let searchFilter = this.filterResponses();

		const { appstore } = this.props;
		var self = this;
		let order_count = 0;
		const SortableItem = sortableElement(({ responseStore, response, pos, appstore }) => <ChatResponse responseStore={responseStore}
			response={response} pos={pos} appstore={appstore}
		/>);
		const SortableContainer = sortableContainer(({ children }) => {
			return <div>{children}</div>;
		});

		return (

			<div className="innercontent_wrapper w-100">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.chatResponseIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Responses")}</h4>
				</div>
				<div className="row chatResponseWrapper with-header">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{this.responseStore.add ? 'Add' : 'Edit'} {appstore.getLanguageTrans("New Response")}</h4>
							</div>
							<div className="contentWrapper">
								<form>
									<div className="row">
										<div className="col-12 col-lg-7 col-xl-9">
											<Form.Group as={Row} controlId="formGroupTitle">
												<Form.Label column sm="12">{appstore.getLanguageTrans("Title")}</Form.Label>
												<Col sm="12 pr-0">
													<div className="row">
														<p className="title">#</p>
														<Form.Control type="text" placeholder={appstore.getLanguageTrans("Type #title during chat to get faster access to the message")}
															name="question"
															className={validation.question.isInvalid && 'has-error'}
															value={this.responseStore.responseChangeObj.question}
															className="col-11 pr-0"
															onChange={(e) => this.responseStore.responseChangeObj.question = e.target.value} />
													</div>
													<span className="error-block">{validation.question.message}</span>
												</Col>
											</Form.Group>

											<Form.Group as={Row} controlId="formGroupNickName">
												<Form.Label column sm="12">{appstore.getLanguageTrans("Message")}</Form.Label>
												<Col sm="12">
													<TextEditor store={this.responseStore}
														name="value"
														appstore={this.props.appstore}
														className={validation.value.isInvalid && 'has-error'}
														editorContent={this.responseStore.responseChangeObj.hasOwnProperty('value') ? this.responseStore.responseChangeObj.value : ""} />
													<span className="error-block">{validation.value.message}</span>
												</Col>
											</Form.Group>
										</div>
										
											<div className="col-12 col-lg-5 col-xl-3 reponseDrpDwn">
												<Form.Group as={Row} controlId="formGroupNickName">
													<Form.Label column sm="12">{appstore.getLanguageTrans("Website")}</Form.Label>
													<Col sm="12" className="customDrpDwn">
														<DropDownComponent dropDownOptions={this.responseStore.websiteDrpDwnOptions}
															key={Math.random()}
															store={this.responseStore}
															selectedOption={this.responseStore.responseChangeObj.website}
															callBack={(event) => this.setaddResponseDropdown(event)}
														/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} controlId="formGroupNickName">
													<Form.Label column sm="12">{appstore.getLanguageTrans("Department")}</Form.Label>
													<Col sm="12" className="customDrpDwn">
														<DropDownComponent dropDownOptions={this.responseStore.deptDrpDwnOptions}
															store={this.responseStore}
															key={Math.random()}
															callBack={(event) => this.setaddResponseDropdown(event)}
															selectedOption={this.responseStore.responseChangeObj.department} />
													</Col>
												</Form.Group> 
												{appstore.isAdmin &&
												<Form.Group as={Row} controlId="formGroupNickName">
													<Form.Label column sm="12">{appstore.getLanguageTrans("Operator")}</Form.Label>
													<Col sm="12" className="customDrpDwn">
														<DropDownComponent dropDownOptions={this.responseStore.operatorDrpDwnOptions}
															store={this.responseStore}
															key={Math.random()}
															callBack={(selectedOption) => this.responseStore.responseChangeObj.operator = selectedOption}
															selectedOption={this.responseStore.responseChangeObj.operator} />
													</Col>
												</Form.Group>
												}
											</div>
										
									</div>
									<div className="row">
										<div className="col-12 col-lg-12 col-xl-12">
											{<Form.Group as={Row} controlId="formGroupNickName">
												<Form.Label column sm="12" >{appstore.getLanguageTrans("Attach files")}</Form.Label>
												<Col sm="3">
													<div className={"fileUploaderDiv " + (this.responseStore.maxImage ? "notAllowedDiv" : "")} onClick={(e) => this.handleInputclick(e)}>
														<img style={{ left: 25, top: 23, width: 30, opacity: 0.5 }} className={this.responseStore.maxImage ? "notAllowed" : ""} src={this.responseStore.maxImage ? imageURLs.deleteIcon : "#3ba8c5" == appstore.selectedColor.color ? imageURLs.plus_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.plus_seagreen :
															"#ec407a" == appstore.selectedColor.color ? imageURLs.plus_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.plus_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.plus_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.plus_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.plus_yellow :
																"#039be6" == appstore.selectedColor.color ? imageURLs.plus_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.plus_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.plus_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.plus_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.plus_skyblue : imageURLs.plus_steelblue} />
													</div>
													<div className="maxAllowedMsg">{this.responseStore.maxImage || this.state.maxImageText && "max 3 images are allowed"}</div>
												</Col>
												<Col sm="9">
												  
													<div class="row">
														{/* <img alt="Crop" className="img-fluid" style={{ width: "100px", height: "100px", borderRadius: "50px" }} src={croppedImageUrl} /> */}
														{/* <input type="file" multiple accept='image/*' onChange={this.handleImgChange} id="file" ref="fileUploader" style={{ display: "none" }} /> */}
														<input ref={this.fileInputRef, "fileUploader"} multiple={false} type="file" style={{ display: "none" }} accept={acceptedFileTypes} id="file" onChange={this.onSelectFile} />
														{/* {"this.responseStore.file="+console.log(this.responseStore.file.length)} */}
														{this.responseStore.file.map(function (responseImages, index) {
															return ( 
																 <Col sm="4">
																		<div className="cannedImages" style={{ backgroundImage: "url(" + appstore.getuploadimageurl(responseImages) + ")" }}  >
																			<a href={URL.createObjectURL(responseImages)} download>
																				<div className="img_download" >
																					<img src={imageURLs.downloadIcon} />
																				</div>
																			</a>
																			<div className="img_delete" id={"img" + index} onClick={(e) => self.setImageforDelete(e, "new")}>
																				<img src={imageURLs.deleteIcon} />
																			</div>
																			
																		</div> 
																		<p className="responsetitle" >{self.responseStore.fileNames[index]}</p>
																	</Col>
															)
														})
														}
														{/* edited images data */}
														{/* {"this.responseStore.responseChangeObj.imageslist="+console.log(this.responseStore.responseChangeObj.imageslist)} */}
														{this.responseStore.responseChangeObj.imageslist.length != 0 && this.responseStore.responseChangeObj.imageslist != undefined &&
															this.responseStore.responseChangeObj.imageslist.map(function (responseImage, index) {
															 
																return (
																	<Col sm="4">
																		<div className="cannedImages" style={{ backgroundImage: "url(" + appstore.getimageurl(responseImage.images) + ")" }}  >
																			{/* href={appstore.resourceurl + appstore.userInfo.accountsmodel.account_uid + "/canned_response/" + responseImage.images} */}
																			<a onClick={() => self.responseStore.downloadingfile(appstore.resourceurl + appstore.userInfo.accountsmodel.account_uid + "/canned_response/" + responseImage.images, responseImage.title, appstore)} target="_blank" target="_blank" download>
																				<div className="img_download" >
																					<img src={imageURLs.downloadIcon} title="download" />
																				</div>
																			</a>
																			<div className="img_delete" id={"img" + index} onClick={(e) => self.setImageforDelete(e, "edit")}>
																				<img src={imageURLs.deleteIcon} title="delete" />
																			</div>
																		</div> 
																	<p className="responsetitle" >{responseImage.title}</p>
																</Col> 

																) 
															})

														}


													</div>
												</Col>
											</Form.Group>}
										</div>
									</div>
									<div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light" onClick={() => { this.responseStore.cancelEdit(); this.changestate(); }}>
													<p>{appstore.getLanguageTrans("Cancel")}</p>
												</Button>
												<Button className="btn_save" onClick={(event) => this.handleFormSubmit(event)}>
													<p>{this.responseStore.add ? appstore.getLanguageTrans("Add") : appstore.getLanguageTrans("Save")}</p>
												</Button>
											</div>
										</div>
									</div>
								</form>
								{this.responseStore.imagewarning &&
									<ImageWarningPopUp show={this.responseStore.imagewarning}
										store={this.responseStore}
										cancel_btn="Close"
										closePopup={() => this.responseStore.imagewarning = false}
										appStore={appstore} />
								}
								{this.responseStore.imageCropModel &&
									<ImageCropComponent show={this.responseStore.imageCropModel}
										store={this.responseStore}
										cancel_btn="Clear"
										multi="1"
										crop_btn="Save"
										onCropComplete={() => this.onCropComplete(crop)}
										closePopup={() => this.handleClearToDefault()}
										appStore={appstore} />
								}
							</div>
						</div>

						<div className="settingFormWrapper box p-0 response_wrapper">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Responses")}</h4>
							</div>
							<div className="contentWrapper">
								<form>
									<div className="row">
										<div className="col-12 col-lg-3">
											<Form.Group as={Row} controlId="formGroupSearch">
												<Col sm="12" className="searchText">
													<Form.Control type="text"
														placeholder="Enter Search Term"
														value={self.state.search}
														onChange={(e) => this.updateSearch(e)}
													/>
												</Col>
											</Form.Group>
										</div>
										<div className="col-12 col-lg-4">
											<Form.Group as={Row} controlId="formGroupNickName">
												<Form.Label column lg="3" sm="2">{appstore.getLanguageTrans("Website")}</Form.Label>
												<Col sm="10" lg="9" className="customDrpDwn">
													<DropDownComponent dropDownOptions={this.responseStore.filterWebsiteDrpDwnOptions}
														key={Math.random()}
														store={this.responseStore}
														callBack={(event) => this.handleWebsiteFilter(event)}
														selectedOption={this.responseStore.listFilter.website} />
												</Col>
											</Form.Group>
										</div>
										<div className="col-12 col-lg-5">
											<Form.Group as={Row} controlId="formGroupNickName">
												<Form.Label column lg="3" sm="2">{appstore.getLanguageTrans("Department")}</Form.Label>
												<Col sm="10" lg="9" className="customDrpDwn">

													<DropDownComponent dropDownOptions={this.responseStore.filterDeptDrpDwnOptions}
														key={Math.random()}
														store={this.responseStore}
														callBack={(event) => this.handleDeptFilter(event)}
														selectedOption={this.responseStore.listFilter.department} />
												</Col>
											</Form.Group>
										</div>

									</div>
								</form>
								<div id="responseMsgs" className="panelWrapper col-12 col-lg-12 p-0">
									<div className="scrollWrapper col-12 p-0">
										<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true} scrollTo={this.responseStore.componentScroll}>
											<SortableContainer onSortEnd={this.onSortEnd}
												axis="y" lockAxis="y"
												lockToContainerEdges={true}
												lockOffset="0%"
												distance={2}
												helperClass="ghostClass" >
												{
													searchFilter.map(function (response, responseIndex) {
														return (
															<SortableItem key={`item-${responseIndex}`} index={responseIndex} pos={responseIndex} response={response}
																responseStore={self.responseStore}
																appstore={appstore} />
														)
													})
												}
											</SortableContainer>
										</CustomScroll>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					this.responseStore.deleteResponseConfirm &&
					<ConfirmationComponent show={this.responseStore.deleteResponseConfirm}
						store={this.responseStore}
						title="Delete Response"
						body_html="<p>Are you sure you want to delete the selected response?</p>"
						cancel_btn="Cancel"
						save_btn="Confirm"
						callBack={(event) => this.confirmDeleteRule(event)}
						closePopup={() => this.responseStore.deleteResponseConfirm = false}
						appStore={appstore} />
				}
				{
					this.responseStore.deleteImage &&
					<ConfirmationComponent show={this.responseStore.deleteImage}
						store={this.responseStore}
						title="Delete File"
						body_html="<p>Please confirm that you want to delete the file. The action cannot be undone.</p>"
						cancel_btn="Cancel"
						save_btn="Delete"
						callBack={(event) => this.deleteImage(event)}
						closePopup={() => this.responseStore.deleteImage = false}
						appStore={appstore} />
				}
				{
					this.responseStore.titleExist &&
					<ConfirmationComponent show={this.responseStore.titleExist}
						store={this.responseStore}
						title={"Oops"}
						body_html={'Question "' + this.responseStore.responseChangeObj.question + '" has already been taken.'}
						cancel_btn="Ok"
						singleButton={true}
						callBack={() => this.responseStore.titleExist = false}
						closePopup={() => this.responseStore.titleExist = false}
						appStore={appstore} />
				}


			</div>
		)
	}
})
export default ResponseComponent;
