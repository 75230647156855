import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import CustomScroll from 'react-custom-scroll';
import arrayMove from 'array-move';
import imageURLs from '../../sharedFiles/ImagesURLs';
import ChatInfoComponent from './components/ChatInfoComponent';
import ChatEnterMsgComponent from './components/chatSections/ChatEnterMsgComponent';
import OperatorChatComponent from './components/chatSections/OperatorChatComponent';
import CustomerChatComponent from './components/chatSections/CustomerChatComponent';
import CustomerFileComponent from './components/chatSections/CustomerFileComponent';
import SupervisorChatComponent from './components/chatSections/SupervisorChatComponent';
import SupervisorMsgComponent from './components/chatSections/SupervisorMsgComponent';
import TextEditorComponent from './components/chatSections/textEditor/textEditor';
import ConfirmationComponent from '../../sharedComponents/confirmationPopup/ConfirmationComponent';
import ChatComponentStore from './ChatComponentStore';
import ImageWarningPopUp from '../../sharedComponents/imageCropPopUp/ImageWarningPopUp';
import GeoMapModel from '../../sharedComponents/geoMapPopup/GeoMapModel';
import axios from 'axios';
import EmailTranscriptPopup from '../../sharedComponents/email_transcripts/EmailTranscriptPopup';
import WarningComponent from '../../sharedComponents/confirmationPopup/WarningComponent';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from '../../sharedComponents/chatInfoPopup/ComponentToPrint';
import $ from 'jquery';

const imageMaxSize = 2000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif,application/pdf,image/bmp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-word.document.macroEnabled.12,application/vnd.ms-word.template.macroEnabled.12'

const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const ChatComponent = observer(class ChatComponent extends Component {

    constructor(props) {
        super(props);
        this.chatComponentStore = new ChatComponentStore();
        this.fileInputRef = React.createRef();
        this.state = {
            chatElements: [],
            requestedVisitorId: "",
            fileNameToRemove: "",
        };
    }

    componentDidMount() {
        try {
            this.chatComponentStore.iscannedEnabled = this.props.appstore.userInfo.accountplan.canned_response;
            this.chatComponentStore.isSc_CannedEnabled = this.props.appstore.userInfo.accountplan.shortcut_canned_response;

            this.setState({ requestedVisitorId: this.props.appstore.requestedVisitorId });
            // this.chatComponentStore.getFullContactDetail(this.props.appstore,this.chatComponentStore.userdata.email);
            this.props.appstore.menuChanged = window.location.pathname;

            if (this.props.appstore.requestedVisitorId) {
                localStorage.setItem("visitor_id", this.props.appstore.requestedVisitorId);
                // this.chatComponentStore.getAddressforIp(this.props.appstore)
                // this.chatComponentStore.getUserNotes(this.props.appstore, '103.252.27.168');
                // if(!this.chatComponentStore.chatOnrefresh)
                this.setChatData()
            }

            setInterval(() => {
                let replay = this.chatComponentStore.editorEditedValue.replace("<[^/>]+>[ \n\r\t]*</[^>]+>", "");
                replay = replay.replace(/<p>​<\/p>/gi, '');
                replay = replay.replace(/<p>\s*<\/p>/ig, '');
                replay = replay.replace(/<p\s*\/>/ig, '');
                replay = replay.replace(/\n/g, "");
                if(this.props.appstore.requestedVisitorId && this.chatComponentStore.optypingcount>0 && replay.length>0){
                        const currentTime = this.chatComponentStore.getcurrentUtcTime();
                        const user = this.props.appstore.userInfo.usersmodel;
                        const userRole = user.user_type_id == 3 ? 'admin' : 'operator';
                        let opMsg = {
                        id: '', type: "opsMsg", role: userRole, userUid: this.props.appstore.requestedVisitorId, operatorUid: user.user_uid,
                        message: "", systemMsg : true,creationTime: currentTime, hostname: 'ip', sourceStation: user.user_uid, chatStatus: "op_typing",
                        account_uid: this.props.appstore.userInfo.accountsmodel.account_uid, name: user.nick_name, email: user.email
                        , fileName: "", originalFileName: "", fileSize: "", targetStation: '/topic/' +this.props.appstore.requestedVisitorId
                    };                
                    this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                     this.chatComponentStore.optypingcount=0;
                }
            },5000);

            // this.chatComponentStore.getResponsesData(this.props.appstore);
        }
        catch (error) {
            console.log(error);
        }
        try{
       // if(this.props.appstore.global_eventcount==0){
        var self=this;
            $(".customEditor").on('keyup',function(e) {
                if(e.key == "Enter") {
                    // if(window.event.shiftKey){  
                    if(!window.event.shiftKey){  
                        self.sendMessageTOoVisitor(e);
                    }
                        
                    // if(e.ctrlKey){  
                    //     self.sendMessageTOoVisitor(e);
                    // }
                }
            });
          //  this.props.appstore.global_eventcount=1;
        // }
        }catch (error) {
            console.log(error);
        }
       try{
        window.scrollTo(0, document.getElementsByClassName('textEditorWrapper')[0].offsetTop-428);
        }catch(error){
        }
    }

    componentWillUnmount() {
        window.scrollTo(0, 0);
    }


    componentDidUpdate(prevProps, prevState) {
        this.chatComponentStore.iscannedEnabled = this.props.appstore.userInfo.accountplan.canned_response;
        this.chatComponentStore.isSc_CannedEnabled = this.props.appstore.userInfo.accountplan.shortcut_canned_response;
        if (prevState.requestedVisitorId !== this.props.appstore.requestedVisitorId) {

            this.setChatData();
            this.setState({ requestedVisitorId: this.props.appstore.requestedVisitorId });

            if (this.props.appstore.chatisTransferred) {
                this.chatComponentStore.resetToDefault();
                this.props.appstore.chatisTransferred = false;
            }
        }
        try{
            // if(this.props.appstore.global_eventcount==0){
             var self=this;
                 $(".customEditor").on('keyup',function(e) {
                     if(e.key == "Enter") {
                        // if(window.event.shiftKey){  
                         if(!window.event.shiftKey){  
                             //self.sendMessageTOoVisitor(e);
                              $(".sendImgWrappger").click();
                             $(".sendImgWrappger").focus();
                         }
                         // if(e.ctrlKey){  
                         //     self.sendMessageTOoVisitor(e);
                         // }
                     }
                 });
               //  this.props.appstore.global_eventcount=1;
             // }
             }catch (error) {
                 console.log(error);
             }
             //chat msg scrolling to end
             try{
                this.chatComponentStore.componentScroll=document.getElementById('chatDataWrapper').scrollHeight;
            }catch(error){
            }
            try{
            //scrolling to texteditor
                window.scrollTo(0, document.getElementsByClassName('textEditorWrapper')[0].offsetTop-428);
            }catch(error){
            }
            try{
                //focus texteditor
                this.chatComponentStore.domEditor.focusEditor();
            }catch(error){
            }
    }

    onSelectFile = async e => {
        e.persist();  //That is because of React doing event pooling - all the event's fields get nullified after the callback is done , so to avoid,
        let freeSpace = 0;
        let filestorge = this.props.appstore.userInfo.accountplan.file_storage_space;
        await axios({
            method: 'POST',
            url: this.props.appstore.url + 'getfilestorage.json',
            headers: { 'content-type': 'application/json' },
            data: { "account_id": 0 }
        }).then(result => {
            if (result.data != -1) {
                
                // freeSpace = (200 - result.data / 1024 / 1024).toFixed(2);
                freeSpace = (filestorge - result.data / 1024 / 1024).toFixed(2);
            }
        })
            .catch(error => {
                console.log(error)
                return;
            })

        if (freeSpace >= filestorge) {
            //dont allow to send file if plan file size is full
            this.chatComponentStore.discSizeFull = true;
            return;
        }

        const files = e.target.files
        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                let uid = this.generateUid();
                this.state.selectedfile = e.target.files[0];
                this.state.fileName = uid + "/" + files[0].name;
                var sizeinbytes = e.target.files[0].size;
                var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
                var fSize = sizeinbytes; var i = 0; while (fSize > 900) { fSize /= 1024; i++; }
                this.state.filesizecheck = (Math.round(fSize * 100) / 100) + ' ' + fSExt[i];
                this.state.name = files[0].name;
                let replay = this.chatComponentStore.editorEditedValue.replace("<[^/>]+>[ \n\r\t]*</[^>]+>", "");
                this.chatComponentStore.count = this.chatComponentStore.count + 1;
                this.setState({ fileNameToRemove: files[0].name });
                replay = replay.replace(/<p>​<\/p>/gi, '');
                replay = replay.replace(/<p>\s*<\/p>/ig, '');
                replay = replay.replace(/<p\s*\/>/ig, '');
                replay = replay.replace(/\n/g, "");
                this.chatComponentStore.editorEditedValue = replay + files[0].name;
                // alert(this.chatComponentStore.editorEditedValue)
                this.chatComponentStore.chat = this.chatComponentStore.editorEditedValue;
            }
        }
    };

    generateUid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > imageMaxSize) {
                this.chatComponentStore.warning_image_name = currentFile.name + ": " + this.props.appstore.getLanguageTrans("The uploaded file is too large. Max size: 2 Mb");
                this.chatComponentStore.imagewarning = true;
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
                this.chatComponentStore.warning_image_name = currentFile.name + ": " + this.props.appstore.getLanguageTrans("An internal server error occurred.");
                this.chatComponentStore.imagewarning = true;
                return false
            }
            return true
        }
    }

    setChatData = () => {
        let path = window.location.href;
        let pos = path.lastIndexOf("/");
        let visitorId = path.slice(pos + 1, path.length);
        if (this.props.appstore.requestedVisitorId !== visitorId)
            visitorId = this.props.appstore.requestedVisitorId
        //handleVisitor data
        this.handleVisitorData(visitorId); 
            try{
                this.chatComponentStore.componentScroll=document.getElementById('chatDataWrapper').scrollHeight;
            }catch(error){
            }
            try{  
              window.scrollTo(0, document.getElementsByClassName('textEditorWrapper')[0].offsetTop-428);
            }catch(error){
            }
        }


    handleVisitorData = (visitorId) => {
        if (!this.props.appstore.visitors[visitorId]) {
            this.chatComponentStore.currentChattingVisitor = [];
            return;
        }
        else if (this.props.appstore.visitors[visitorId].length == 0) {
            this.chatComponentStore.currentChattingVisitor = [];
            return;
        }

        this.chatComponentStore.currentChattingVisitor = this.props.appstore.visitors[visitorId];
        
        //console.log(this.chatComponentStore.currentChattingVisitor[1].visitorLeft);
        
        let visitordata = this.chatComponentStore.currentChattingVisitor;
        //console.log(this.props.appstore.visitors);
        //console.log(this.chatComponentStore.currentChattingVisitor);
        // this.chatComponentStore.currentVisitorTyping = this.props.appstore.visitorsTyping[visitorId] ?  this.props.appstore.visitorsTyping[visitorId].message :"";
        if (visitordata) {
            let user = visitordata[0];
            let prechat = this.chatComponentStore.setPrechatFields(visitordata);
            //alert(this.chatComponentStore.currentChattingVisitor[0].prechatfields.replace(/\n/g, " "))
            const websiteUid = this.chatComponentStore.currentChattingVisitor[0].websiteUid
            this.props.appstore.currentChattingWebsite = websiteUid;

            //set website data
            if (this.chatComponentStore.widgetWebsite && this.chatComponentStore.widgetWebsite.website_uid !== websiteUid) {
                //if same widget for website dont allow
                const allwebsites = this.props.appstore.userInfo.allwebistes;
                this.chatComponentStore.setWebsite(websiteUid, allwebsites);
                this.chatComponentStore.getResponsesData(this.props.appstore, websiteUid);
                //set footstep

                this.chatComponentStore.footstepsTabContent = [
                    {
                        time: this.chatComponentStore.getLocalTime(user.creationTime),
                        page: user.pageTitle,
                        formUrl: user.formUrl,
                    },
                ];

                //change new website
                //this.chatComponentStore.widgetWebsite = this.chatComponentStore.currentChattingVisitor[0].websiteUid;
            }

            //check supervision mode by admin
            if (user.supervision) {
                this.chatComponentStore.undersupervision = true;
            } else {
                this.chatComponentStore.undersupervision = false;
            }


            let fields = [];

            if (prechat) {

                fields = this.chatComponentStore.prechatfields;

                // this.chatComponentStore.currentChattingVisitor[0].prechatfields =  fields;
            }
            this.chatComponentStore.userdata.name = "";
            this.chatComponentStore.userdata.email = "";

            for (let i in fields) {
                if (Object.keys(fields[i]).length == 0) {
                    continue;
                }
                if (this.chatComponentStore.userdata.name.trim().length == 0)
                    if (fields[i]["type"].toLowerCase() === "name")
                        this.chatComponentStore.userdata.name = fields[i]["value"].trim() ? fields[i]["value"].trim() : "Visitor";

                if (this.chatComponentStore.userdata.email.trim().length == 0)
                    if (fields[i]["name"].toLowerCase() === "email")
                        this.chatComponentStore.userdata.email = fields[i]["value"].trim() ? fields[i]["value"].trim() : "";
            }

            this.chatComponentStore.userdata.sourceStation = this.chatComponentStore.currentChattingVisitor[0].sourceStation;
            // this.chatComponentStore.userdata.name = this.chatComponentStore.userdata.name ? this.chatComponentStore.userdata.name : "Visitor";
            this.chatComponentStore.infoTabData.name = this.chatComponentStore.userdata.name;
            this.chatComponentStore.infoTabData.email = this.chatComponentStore.userdata.email;
            this.chatComponentStore.infoTabData.privacyPolicyConsent = this.chatComponentStore.getConsent(this.props.appstore, user.websiteUid);
            this.chatComponentStore.infoTabData.website = this.chatComponentStore.getWebsiteName(this.props.appstore, user.websiteUid);
            this.chatComponentStore.infoTabData.dept = this.chatComponentStore.getDeptName(this.props.appstore, user.departmentUid);
            this.chatComponentStore.infoTabData.currentPage = user.formUrl;
            this.chatComponentStore.infoTabData.startPage = user.pageTitle;
            const langLabel = this.props.appstore.languages.find(option => option.value == user.language);
            this.chatComponentStore.infoTabData.language = langLabel ? langLabel.label : '';
            this.chatComponentStore.infoTabData.screen = user.screenSize ? user.screenSize : "";
            if (user.visitorEnvironment != null) {
                this.chatComponentStore.infoTabData.browser = user.visitorEnvironment.browser ? user.visitorEnvironment.browser : "";
                this.chatComponentStore.infoTabData.ip = user.visitorEnvironment.ipAddress ? user.visitorEnvironment.ipAddress : "";

                this.chatComponentStore.getAddressforIp(this.props.appstore, this.chatComponentStore.infoTabData.ip)
                this.chatComponentStore.getUserNotes(this.props.appstore, this.chatComponentStore.infoTabData.ip);
            }

            // get fullcontact details of user based on email
            this.chatComponentStore.getFullContactDetail(this.props.appstore, this.chatComponentStore.userdata.email);
            //get history of visitor based on email
            this.chatComponentStore.getHistoryOfVisitor(this.props.appstore, this.chatComponentStore.userdata.email);
        }
    }

    visitorTyping = () => {
        let path = window.location.href;
        let pos = path.lastIndexOf("/");
        let visitorId = path.slice(pos + 1, path.length);
        let currentVisitorTyping = this.props.appstore.visitorsTyping[visitorId] ? this.props.appstore.visitorsTyping[visitorId].message : "";
        return currentVisitorTyping.trim() ? currentVisitorTyping.trim() : "";
    }

    onselectChatprint = async () => {
        this.chatComponentStore.chatprintdata="";
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
// console.log(tz);
        var formdata = {
            sourceStation: this.props.appstore.requestedVisitorId.trim(),
            clientTimeZone:tz
        }
        axios({
            method: 'POST',
            url: this.props.appstore.url + 'opprinttranscript.json',
            headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
            data: formdata
        }).then(result => {
            // console.log(result.data);
            if (result.data != "") {
                // var printWindow = window.open('', '', 'height=726,width=768');
                // printWindow.document.write(result.data);
                // printWindow.document.close();
                // printWindow.print();
                this.chatComponentStore.chatprintdata=result.data;
                $("#btn_printchatinfo").click();
                // ReactToPrint({
                //     content: () => componentRef.current,
                //   });
            } else {
                this.chatComponentStore.errormodel = true;
            }
        }).catch((error) => {
        });
    }

    sendMessageTOoVisitor = async () => {
        // return;
        this.chatComponentStore.showResponses = false
        let message = this.chatComponentStore.editorEditedValue;
        let fileName = this.state.fileNameToRemove;
        if (this.chatComponentStore.isEmptyOrSpaces(message))
            return;

        if (this.chatComponentStore.cannedFileNameRemove.length != 0) {
            message = this.sendCannedFile(message);
            this.chatComponentStore.cannedFileNameRemove = [];
            if (this.chatComponentStore.isEmptyOrSpaces(message))
                return;
        }
        if (message.indexOf(fileName) != -1 && fileName != "") {
            this.uploadingfile(message);
            if (this.chatComponentStore.isEmptyOrSpaces(message))
                return;

        } else {
            const currentTime = this.chatComponentStore.getcurrentUtcTime();
            const user = this.props.appstore.userInfo.usersmodel;
            const userRole = user.user_type_id == 3 ? 'admin' : 'operator';

            message = message.replace(/<p>​<\/p>/gi, '');
            message = message.replace(/<p>\s*<\/p>/ig, '');
            message = message.replace(/<p\s*\/>/ig, '');
            message = message.replace(/\n/g, "");

            if (message.length == 0)
                return

            let messageUrl = "/app/opsmsg";
            let opMsg = {
                id: '', type: "opsMsg", role: userRole, userUid: this.props.appstore.requestedVisitorId, operatorUid: user.user_uid,
                message: message, creationTime: currentTime, hostname: 'ip', sourceStation: user.user_uid, chatStatus: "chat",
                account_uid: this.props.appstore.userInfo.accountsmodel.account_uid, name: user.nick_name, email: user.email
                , fileName: "", originalFileName: "", fileSize: "", targetStation: '/topic/' + this.props.appstore.requestedVisitorId
            };
            opMsg.avoidEntry = false;
            this.props.appstore.getplaySound(this.chatComponentStore.currentChattingVisitor[0].websiteUid, 'message_sent')
            if (this.chatComponentStore.undersupervision) {
                opMsg.staff_message = true;

                if (this.chatComponentStore.currentChattingVisitor[0].joinedConversation) {
                    //if joined conversation, send messsage to visitor
                    opMsg.sourceStation = user.user_uid;
                    opMsg.acceptedBy = user.user_uid;
                    opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
                    this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));

                    opMsg.acceptedBy = "supervisor";
                }
                //if operator is under supervision , handle sendmessage here 
                //msg should be send to same sourcetation object key and to only operator not visitor untill conversation is joined.
                opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                //set destination and send message to operator
                opMsg.avoidEntry = true;
                const chattingOperators = this.chatComponentStore.currentChattingVisitor[0].allchattingOperators;
                messageUrl = "/app/op2op";
 
                if (chattingOperators && chattingOperators.length != 0) {
                    if (chattingOperators.length > 1) {
                        for (let opid of chattingOperators) {
                            opMsg.targetStation = '/topic/' + opid;
                            this.props.appstore.clientRef.sendMessage(messageUrl, JSON.stringify(opMsg));
                        }
                    }

                    opMsg.targetStation = '/topic/' + chattingOperators[chattingOperators.length - 1];
                }
            }

            if (this.props.appstore.supervisedBy[this.props.appstore.requestedVisitorId]) {
                //operator under supervised, send message to supervising admin
                opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                opMsg.targetStation = '/topic/' + this.props.appstore.supervisedBy[this.props.appstore.requestedVisitorId];
                this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
            }

            if (this.props.appstore.inviteJoinedByOp[this.props.appstore.requestedVisitorId]) {
                const otherOp = this.props.appstore.inviteJoinedByOp[this.props.appstore.requestedVisitorId];
                //operator under supervised, send message to supervising admin
                for (let i = 0; i < otherOp.length; i++) {
                    opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                    opMsg.targetStation = '/topic/' + otherOp[i];
                    this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                }

                opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
            }

            this.props.appstore.clientRef.sendMessage(messageUrl, JSON.stringify(opMsg));
            this.chatComponentStore.currentChattingVisitor.push(opMsg);
            this.chatComponentStore.resetValues();
        }
            try{
                this.chatComponentStore.componentScroll=document.getElementById('chatDataWrapper').scrollHeight;
            }catch(error){
            }
            try{
             this.chatComponentStore.domEditor.focusEditor();
            }catch(error){
            }
    }

    uploadingfile = (message) => {
        let file_size = this.state.filesizecheck;
        const formData = new FormData()
        formData.set('id', '100');
        this.setState({ uploading: true })
        formData.append("file", this.state.selectedfile)
        formData.append("fileName", this.state.fileName)
        formData.append("account_uid", this.props.appstore.userInfo.accountsmodel.account_uid);

        //set transfer type
        const type = this.chatComponentStore.fileData.life.value == 1 ? this.props.appstore.requestedVisitorId : "";
        formData.append("talkSessionUid", type);

        axios({
            method: 'POST',
            url: this.props.appstore.url + 'uploadChatFile.json',
            headers: { 'content-type': 'multipart/form-data' },
            data: formData
        }).then(result => {
            let fileuploaded =  result.data;
            let originalname = this.state.name;
            message = message.replace(this.state.fileNameToRemove, "");
            message = message.replace(/<p>​<\/p>/gi, '');
            message = message.replace(/<p>\s*<\/p>/ig, '');
            message = message.replace(/<p\s*\/>/ig, '');
            message = message.replace(/\n/g, "");


            const user = this.props.appstore.userInfo.usersmodel;


            let opMsg = {
                id: '', role: "operator", type: "opsMsg", cannedType: false, message: message, creationTime: this.chatComponentStore.getcurrentUtcTime(), hostname: 'ip', sourceStation: this.props.appstore.userInfo.usersmodel.user_uid, chatStatus: "chat",
                account_uid: this.props.appstore.userInfo.accountsmodel.account_uid, name: this.props.appstore.userInfo.usersmodel.nick_name, email: this.props.appstore.userInfo.usersmodel.email,
                fileName: fileuploaded, operatorUid: user.user_uid, originalFileName: originalname, fileSize: file_size, targetStation: '/topic/' + this.props.appstore.requestedVisitorId
            }

            opMsg.avoidEntry = false;

            if (this.props.appstore.inviteJoinedByOp[this.props.appstore.requestedVisitorId]) {
                const otherOp = this.props.appstore.inviteJoinedByOp[this.props.appstore.requestedVisitorId];
                //operator under supervised, send message to supervising admin
                for (let i = 0; i < otherOp.length; i++) {
                    opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                    opMsg.targetStation = '/topic/' + otherOp[i];
                    this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                }

                opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
            }

            if (this.chatComponentStore.undersupervision) {

                //for undersupervision chat mode
                opMsg.staff_message = true;

                if (this.chatComponentStore.currentChattingVisitor[0].joinedConversation) {
                    //if joined conversation, send messsage to visitor
                    opMsg.sourceStation = user.user_uid;
                    opMsg.acceptedBy = user.user_uid;
                    opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
                    this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));

                    opMsg.acceptedBy = "supervisor";
                }
                //for avoiding duplicate entry in archive
                opMsg.avoidEntry = true;

                //if operator is under supervision , handle sendmessage here 
                //msg should be send to same sourcetation object key and to only operator not visitor untill conversation is joined.
                opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                //set destination and send message to operator

                const chattingOperators = this.chatComponentStore.currentChattingVisitor[0].allchattingOperators;

                if (chattingOperators && chattingOperators.length != 0) {
                    if (chattingOperators.length > 1) {
                        for (let opid of chattingOperators) {
                            opMsg.targetStation = '/topic/' + opid;
                            //send msg to operator
                            this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                        }
                    }
                }

                opMsg.avoidEntry = false;
            }

            else {
                //for normal chat mode
                opMsg.sourceStation = user.user_uid;
                opMsg.acceptedBy = this.chatComponentStore.currentChattingVisitor[0].chattingOp;


                opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
                this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                //this.chatComponentStore.currentChattingVisitor.push(opMsg);


                if (this.props.appstore.supervisedBy[this.props.appstore.requestedVisitorId]) {
                    //operator under supervised, send message to supervising admin
                    opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                    opMsg.targetStation = '/topic/' + this.props.appstore.supervisedBy[this.props.appstore.requestedVisitorId];
                    this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                    opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
                }

                this.chatComponentStore.resetValues();
            }

            this.chatComponentStore.currentChattingVisitor.push(opMsg);


            this.chatComponentStore.resetValues();
            this.props.appstore.global_errorcount=0;
        }).catch((error) => {

           // this.props.appstore.logout();
           console.log(error);
           console.log("error count :"+this.props.appstore.global_errorcount);
           this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
           if(this.props.appstore.global_errorcount>20){
           // this.appstore.logout();
           }
        });
    }

    sendCannedFile = (message) => {
        const files = this.chatComponentStore.cannedFileNameRemove;
        let delay = 1000;
        for (let i = 0; i < files.length; i++) {
            if (message.indexOf(files[i].title) != -1 && files[i].title != "") {
                let pos = message.indexOf(files[i].title);
                let pos_end = pos + files[i].title.length + 5 //5 for </br> tag length

                const imgName = files[i].title;
                const accuid = this.props.appstore.userInfo.accountsmodel.account_uid;
                const op = this.props.appstore.userInfo.usersmodel;
                const imagePath = this.props.appstore.resourceurl + accuid + "/canned_response/" + files[i].images;
                let fileSize = (files[i].fileSize / 1024).toFixed(2) + " KB";
                let opMsg = {
                    operatorUid: op.user_uid, id: '', role: "operator", cannedType: true, type: "opsMsg", message: "", creationTime: this.chatComponentStore.getcurrentUtcTime(), hostname: 'ip', sourceStation: this.props.appstore.userInfo.usersmodel.user_uid, chatStatus: "chat",
                    account_uid: this.props.appstore.userInfo.accountsmodel.account_uid, name: this.props.appstore.userInfo.usersmodel.nick_name, email: this.props.appstore.userInfo.usersmodel.email,
                    fileName: imagePath, fileSize: fileSize, originalFileName: files[i].title, targetStation: '/topic/' + this.props.appstore.requestedVisitorId
                }

                setTimeout(() => {

                    if (this.props.appstore.inviteJoinedByOp[this.props.appstore.requestedVisitorId]) {
                        const otherOp = this.props.appstore.inviteJoinedByOp[this.props.appstore.requestedVisitorId];

                        //to avoid duplicate entry in archive
                        opMsg.avoidEntry = true;
                        for (let i = 0; i < otherOp.length; i++) {
                            opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                            opMsg.targetStation = '/topic/' + otherOp[i];
                            this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                        }

                        opMsg.avoidEntry = false;
                        opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;

                    }

                    if (this.chatComponentStore.undersupervision) {

                        //for undersupervision chat mode
                        opMsg.type = "";
                        opMsg.staff_message = true;

                        if (this.chatComponentStore.currentChattingVisitor[0].joinedConversation) {
                            //if joined conversation, send messsage to visitor
                            opMsg.sourceStation = op.user_uid;
                            opMsg.acceptedBy = op.user_uid;
                            opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
                            this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));

                            opMsg.acceptedBy = "supervisor";
                        }

                        //to avoid duplicate entry in archive
                        opMsg.avoidEntry = true;

                        //if operator is under supervision , handle sendmessage here 
                        //msg should be send to same sourcetation object key and to only operator not visitor untill conversation is joined.
                        opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                        //set destination and send message to operator

                        const chattingOperators = this.chatComponentStore.currentChattingVisitor[0].allchattingOperators;

                        if (chattingOperators && chattingOperators.length != 0) {
                            if (chattingOperators.length > 1) {
                                for (let opid of chattingOperators) {
                                    opMsg.targetStation = '/topic/' + opid;
                                    //send msg to operator
                                    this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                                }
                            }
                        }

                        opMsg.avoidEntry = false;

                        this.chatComponentStore.currentChattingVisitor.push(opMsg);

                    }

                    else {
                        //for normal chat mode
                        opMsg.type = "opsMsg"
                        opMsg.staff_message = false;
                        opMsg.sourceStation = op.user_uid;
                        opMsg.acceptedBy = this.chatComponentStore.currentChattingVisitor[0].chattingOp;
                        opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
                        this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                        //this.chatComponentStore.currentChattingVisitor.push(opMsg);


                        if (this.props.appstore.supervisedBy[this.props.appstore.requestedVisitorId]) {
                            //operator under supervised, send message to supervising admin
                            opMsg.sourceStation = this.props.appstore.requestedVisitorId;
                            opMsg.targetStation = '/topic/' + this.props.appstore.supervisedBy[this.props.appstore.requestedVisitorId];
                            this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                            opMsg.targetStation = '/topic/' + this.props.appstore.requestedVisitorId;
                        }
                        this.chatComponentStore.currentChattingVisitor.push(opMsg);

                        //this.chatComponentStore.resetValues();
                    }

                    //this.props.appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));
                }, delay)

                delay = delay + 1000;

                message = message.slice(0, pos) + message.slice(pos_end, message.length);

            }
        }
        this.chatComponentStore.resetValues();
        return message;
    }
    handleClick = () =>{
	//function to handle visitor left
	} 


    render() {
        // if(!Object.keys(this.props.appstore.visitors)[0]){
        //     return(<div>no set yet..</div>)
        // }

        const { appstore,
            store,
            chatComponentStore,
            msgData,
            status
          } = this.props;
        var self = this;
        const responseFiltered = this.chatComponentStore.filterResponses();
        return (
            <div key={appstore.requestedVisitorId} className="innercontent_wrapper w-100" >
                {
                    !this.props.appstore.requestedVisitorId &&
                    <div className="waitingforChat">{appstore.getLanguageTrans("Waiting for incoming chat...")}</div>
                }
                {/*  style={{ opacity: this.props.appstore.requestedVisitorId ? 1 : 0.4, pointerEvents: this.props.appstore.requestedVisitorId ? "auto" : "none" }}*/}
                <div className="row chatWrapper with-header" style={{ opacity: this.props.appstore.requestedVisitorId ? 1 : 0.4, pointerEvents: this.props.appstore.requestedVisitorId ? "auto" : "none" }}>
                    <div className="col-12 pr-0">
                        <div className="settingFormWrapper box row">
                            <div className="col-12 col-md-7 col-lg-8">
                                <div className="headerTitle row align-items-center m-0">
                                    <h4 className="text-left col-9 p-0">{appstore.getLanguageTrans("Chatting with")} <span>{this.chatComponentStore.userdata.name ? this.chatComponentStore.userdata.name : appstore.getLanguageTrans("Visitor")}</span></h4>
                                    <div className="col-3 p-0" style={{ left: 50 }}>

                                        {/* onClick={() => this.chatComponentStore.quitChat(appstore)} */}

                                        <img title={appstore.getLanguageTrans("Ban the visitor by IP")} style={{ cursor: "pointer", paddingRight: 10 }} onClick={() => this.chatComponentStore.banVisitor(appstore)} className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.ipban_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.ipban_seagreen :
                                            "#ec407a" == appstore.selectedColor.color ? imageURLs.ipban_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.ipban_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.ipban_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.ipban_yellow :
                                                "#b968c7" == appstore.selectedColor.color ? imageURLs.ipban_voilet : "#039be6" == appstore.selectedColor.color ? imageURLs.ipban_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.ipban_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.ipban_green :
                                                    "#5f7c8c" == appstore.selectedColor.color ? imageURLs.ipban_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.ipban_skyblue : imageURLs.ipban_steelblue} alt="" />

                                        <img title={appstore.getLanguageTrans("Email Transcript")} onClick={() => { this.chatComponentStore.mailtranscripts = true; this.chatComponentStore.transcript_emailid = appstore.userInfo.usersmodel.email }} style={{ cursor: "pointer", paddingLeft: 10, paddingRight: 10 }} className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.email_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.email_seagreen :
                                            "#ec407a" == appstore.selectedColor.color ? imageURLs.email_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.email_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.email_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.email_yellow :
                                                "#b968c7" == appstore.selectedColor.color ? imageURLs.email_voilet : "#039be6" == appstore.selectedColor.color ? imageURLs.email_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.email_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.email_green :
                                                    "#5f7c8c" == appstore.selectedColor.color ? imageURLs.email_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.email_skyblue : imageURLs.email_steelblue} alt="" />

                                        <img title={appstore.getLanguageTrans("Quit Chat")} onClick={() => this.chatComponentStore.quitChatConfirm = true} style={{ cursor: "pointer", paddingLeft: 10 }} className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.exit_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.exit_seagreen :
                                            "#ec407a" == appstore.selectedColor.color ? imageURLs.exit_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.exit_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.exit_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.exit_yellow :
                                                "#b968c7" == appstore.selectedColor.color ? imageURLs.exit_voilet : "#039be6" == appstore.selectedColor.color ? imageURLs.exit_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.exit_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.exit_green :
                                                    "#5f7c8c" == appstore.selectedColor.color ? imageURLs.exit_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.exit_skyblue : imageURLs.exit_steelblue} alt="" />

                                    </div>

                                    <div className="col-12 p-0" style={{maxHeight:"200px",overflowX:"hidden",overflowY:"auto",scrollbarColor: appstore.selectedColor.color+" transparent",scrollbarWidth: "thin"}}>

                                        {(this.chatComponentStore.currentChattingVisitor &&
                                            this.chatComponentStore.prechatfields.length != 0 && this.chatComponentStore.prechatfields != null) &&

                                            this.chatComponentStore.prechatfields.map((field, index) => {
                                                if (field.value != "" && field.value != undefined) {

                                                    if (field.id === "noweb_new_department")
                                                        return (
                                                            <div className="row visitor_field" key={Math.random()} >
                                                                <div className="col-8 pr-0" style={{ lineHeight: "1" }}>
                                                                    <p dangerouslySetInnerHTML={{ __html: field.name + ":&nbsp;&nbsp;&nbsp;<span style='font-family: Segoe UI Semi'>" + field.value + "</span>" }} style={{ fontFamily: "Segoe UI Semi Bold" }}></p>
                                                                </div>
                                                            </div>
                                                        )
                                                    else if (field.type === "dropdown")
                                                        return (
                                                            <div className="row visitor_field"  key={Math.random()}>
                                                                <div className="col label pr-0" >
                                                                    <p>{appstore.getLanguageTrans(field.name)}:&nbsp;&nbsp;&nbsp;<span style={{ fontFamily: "Segoe UI Semi" }}>{field.params[field.value].replace(/[\[\]"]+/g,'')}</span></p>
                                                                </div>
                                                                {/* <div className="col value">
                                                                <p>{field.params[field.value]}</p>
                                                            </div> */}
                                                            </div>
                                                        )
                                                        else if (field.type === "checkbox")
                                                        return (
                                                            <div className="row visitor_field"  key={Math.random()}>
                                                                 <div className="col label pr-0" style={{ lineHeight: "1" }}>
                                                                    <p>{appstore.getLanguageTrans(field.name)}:&nbsp;&nbsp;&nbsp;<span style={{ fontFamily: "Segoe UI Semi" }}>{field.value}</span></p>
                                                                </div>
                                                                {/* <div className="col value">
                                                                <p>{field.params[field.value]}</p>
                                                            </div> */}
                                                            </div>
                                                        )
                                                    else
                                                        return (
                                                            <div className="row visitor_field"  key={Math.random()}>
                                                                <div className="col label pr-0" style={{ lineHeight: "1" }}>
                                                                    <p>{appstore.getLanguageTrans(field.name)}:&nbsp;&nbsp;&nbsp;<span style={{ fontFamily: "Segoe UI Semi" }}>{field.value}</span></p>
                                                                </div>
                                                                {/* <div className="col value">
                                                                <p>{field.value}</p>
                                                            </div> */}
                                                            </div>
                                                        )


                                                }
                                            })

                                        }

                                    </div>
                                </div>

                                <div className="contentWrapper" id="chatscroll">
                                    <CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true} scrollTo={this.chatComponentStore.componentScroll} >
                                        <div id="chatDataWrapper" className="chatData">
                                            {this.chatComponentStore.currentChattingVisitor &&
                                                this.chatComponentStore.currentChattingVisitor.map(visitor => {
                                                   
                                                    if (visitor.role==="visitor" && visitor.vistoryEntryMsg!=true && visitor.system_msg == true && visitor.chatStatus !== "typing" && visitor.chatStatus !== "offline" && visitor.chatStatus !== "online" && visitor.chatStatus !== "op_typing") {
                                                        //alert("system "+visitor.message)
                                                        return <ChatEnterMsgComponent store={self.chatComponentStore} time={this.chatComponentStore.getLocalTime(visitor.creationTime)} chatData={visitor} />
                                                    }else if(visitor.system_msg == true && visitor.vistoryEntryMsg!=true && visitor.chatStatus !== "typing" && visitor.chatStatus !== "offline" && visitor.chatStatus !== "online" && visitor.chatStatus !== "op_typing") {
                                                        //alert("system "+visitor.message)
                                                        return <ChatEnterMsgComponent store={self.chatComponentStore} time={this.chatComponentStore.getLocalTime(visitor.creationTime)} chatData={visitor} />
                                                    }


                                                    else if (visitor.staff_message) {
                                                        // alert("staff "+visitor.message)
                                                        return <SupervisorChatComponent chatData={visitor.message}
                                                            name={appstore.userInfo.usersmodel.user_uid === visitor.opertorUid ? "Me" : visitor.name}
                                                            visitor={visitor}
                                                            time={this.chatComponentStore.getLocalTime(visitor.creationTime)}
                                                            talkSessionUid={appstore.requestedVisitorId}
                                                            chatComponentStore={self.chatComponentStore}
                                                            filePath={visitor.fileName}
                                                            originalFileName={visitor.originalFileName}
                                                            file_size={visitor.fileSize}
                                                            fullData={visitor}
                                                            appstore={appstore} />
                                                    }
                                                    else if (visitor.system_msg != true) {
                                                        // alert(visitor.type+"  "+visitor.message)
                                                        return visitor.type === 'inMsg' ? <OperatorChatComponent appstore={appstore} chatData={visitor.message} talkSessionUid={appstore.requestedVisitorId} name={visitor.name} chatComponentStore={self.chatComponentStore} filePath={visitor.fileName} originalFileName={visitor.originalFileName} time={this.chatComponentStore.getLocalTime(visitor.creationTime)} file_size={visitor.fileSize} fullData={visitor} />
                                                            : <CustomerChatComponent appstore={appstore} name={visitor.name} account_uid={visitor.account_uid} talkSessionUid={appstore.requestedVisitorId} chatComponentStore={self.chatComponentStore} time={this.chatComponentStore.getLocalTime(visitor.creationTime)} chatData={visitor.message} filePath={visitor.fileName} originalFileName={visitor.originalFileName} file_size={visitor.fileSize} fullData={visitor} />
                                                    }
                                                })
                                            }

                                        </div>
                                    </CustomScroll>
                                    
                                    {appstore.visitorTypingMsgChanged && this.visitorTyping().length!=0 &&
                                        <div className="col-12 visitorTypingContent">
                                            <img src={imageURLs.iconTyping} />
                                            <div className="visitorTyping">{this.visitorTyping()}</div>

                                        </div>
                                      
                                    }
                                    {
                                        this.chatComponentStore.currentChattingVisitor.length != 0 &&
                                        <React.Fragment>
                                            {
                                                (this.chatComponentStore.currentChattingVisitor[0].supervision && !this.chatComponentStore.currentChattingVisitor[0].joinedConversation) &&
                                                <SupervisorMsgComponent store={this.chatComponentStore} appStore={appstore} status={this.chatComponentStore.currentChattingVisitor[0].opHasLeft ? "opQuit" : "joinSupervision"} msgData={this.chatComponentStore.currentChattingVisitor[0] && this.chatComponentStore.currentChattingVisitor[0].opHasLeft ? "Operator has left the chat. Click here to <u>quit the chat</u>" : "You are in the Supervision mode. Click here to <u>join the chat</u>"} />
                                            }

                                            {
                                                (this.chatComponentStore.currentChattingVisitor[0].supervision && this.chatComponentStore.currentChattingVisitor[0].joinedConversation) &&
                                                <SupervisorMsgComponent store={this.chatComponentStore} appStore={appstore} status={this.chatComponentStore.currentChattingVisitor[0].opHasLeft ? "opQuit" : "supervisionQuit"} msgData={(this.chatComponentStore.currentChattingVisitor[0] && this.chatComponentStore.currentChattingVisitor[0].visitorLeft) ? "Visitor has left the chat click here to <u>quit the supervision mode</u>" : this.chatComponentStore.currentChattingVisitor[0] && this.chatComponentStore.currentChattingVisitor[0].opHasLeft ? "Operator has left the chat. Click here to <u>quit the chat</u>" : "Back to supervision</u>"} />
                                            }

                                            {
                                                !this.chatComponentStore.currentChattingVisitor[0].supervision &&
                                                <SupervisorMsgComponent store={this.chatComponentStore} appStore={appstore} status={"opQuit"} msgData={(this.chatComponentStore.currentChattingVisitor[0] && this.chatComponentStore.currentChattingVisitor[0].visitorLeft) ? "Visitor has left the chat click here to <u>quit the chat</u>": "Click here to <u>quit the chat</u>"} />
                                            }

                                        </React.Fragment>
                                    }
                                    {/* {appstore.visitorTypingMsgChanged &&
                                        <div className="col-12 visitorTypingContent">
                                            <img src={imageURLs.iconTyping} />
                                            <div className="visitorTyping">{this.visitorTyping()}</div>

                                        </div>
                                    } */}
                                    <div className="textEditorWrapper">
                                        <TextEditorComponent onselectChatprint={this.onselectChatprint} onselectfile={this.onSelectFile} fileinputref={this.fileInputRef} key={this.chatComponentStore.count} appstore={this.props.appstore} store={this.chatComponentStore} editorContent={this.chatComponentStore.chat} />
                                 <div tabIndex="0" className="sendImgWrappger cursor-pointer" onClick={(e) => { this.sendMessageTOoVisitor(e); }}>

                                            <img src={imageURLs.msgSendIcon} className="img-fluid" />
                                        </div>
                                        {this.chatComponentStore.showResponses &&
                                            <div className="findchatResponses" tabindex="0" onBlur={() => { this.chatComponentStore.showResponses = false }}>
                                                <ul>
                                                    {
                                                        responseFiltered.map(function (response, index) {
                                                            return (<li key={index} onClick={() => { self.chatComponentStore.handleSelectedResponse(response) }}
                                                            >{response.question}</li>)
                                                        })

                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <div style={{float: "right",fontSize: "12px",padding: "2px",color: "#adadad",fontWeight: "400",marginRight:"11px"}}><span style={(this.chatComponentStore.iscannedEnabled!=1 && this.chatComponentStore.isSc_CannedEnabled!=1) ? {display:"none"}:{}}>Type <span style={{background: "#ccc",padding: "4px",color: "#000",borderRadius:"5px"}}>#</span> for canned responses, </span><span style={{background: "#ccc",padding: "4px",color: "#000",borderRadius:"5px"}}>Shift+Enter</span> for new line.</div>
                                    <ReactToPrint
							trigger={() => {
								// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
								// to the root node of the returned component as it will be overwritten.
								return <Button id="btn_printchatinfo" style={{ display: "none" }}  variant="primary">Print</Button>;
							}}
							content={() => this.componentRef}
							/>
							<div style={{display:"none"}}><ComponentToPrint ref={el => (this.componentRef = el)} text={this.chatComponentStore.chatprintdata}  /></div>
                                    {/* <div className="transferchat" onClick={() => this.chatComponentStore.showOpforTransferchat = !this.chatComponentStore.showOpforTransferchat}>
                                        TRANSFER CHAT
                                    {this.chatComponentStore.showOpforTransferchat &&
                                            <ul >
                                                {
                                                 appstore.userInfo.alloperators.map(function (op, index) {
                                                        return (<li key={index}
                                                            onClick={() => { self.chatComponentStore.transferChat(op, appstore, self.chatComponentStore.userdata) }}
                                                        >{op.nick_name}</li>)
                                                    })

                                                }
                                            </ul>

                                        }
                                    </div> */}

                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-4">
                                <ChatInfoComponent defaultTab={this.chatComponentStore.defaultTab} appstore={appstore} chatComponentStore={this.chatComponentStore} />
                            </div>
                        </div>
                    </div>

                </div>
                {this.chatComponentStore.imagewarning &&
                    <ImageWarningPopUp show={this.chatComponentStore.imagewarning}
                        store={this.chatComponentStore}
                        cancel_btn="OK"
                        closePopup={() => this.chatComponentStore.imagewarning = false}
                        appStore={appstore} />
                }
                {
                    this.chatComponentStore.showgeoMap &&
                    <GeoMapModel appStore={appstore} store={this.chatComponentStore} show={this.chatComponentStore.showgeoMap} />
                }

                {
                    this.chatComponentStore.discSizeFull &&
                    <ConfirmationComponent show={this.chatComponentStore.discSizeFull}
                        store={appstore}
                        title={"Warning"}
                        body_html={appstore.isAdmin ? "File cannot be sent. You might need to clear your disc space before sending more files.</br> Go to Admin->Website->Setup->File transfer to manage your disc space." : "File cannot be sent. You might need to clear your disc space before sending more files. Please ask your account administrator to go to Admin->Website->Setup->File transfer to manage your disc space."}
                        cancel_btn="Close"
                        singleButton={true}
                        closePopup={() => this.chatComponentStore.discSizeFull = false}
                        appStore={appstore} />
                }
                {
                    this.chatComponentStore.quitChatConfirm &&
                    <ConfirmationComponent show={this.chatComponentStore.quitChatConfirm}
                        store={appstore}
                        title={this.chatComponentStore.userdata.name ? this.chatComponentStore.userdata.name : "Visitor"}
                        body_html={(this.chatComponentStore.currentChattingVisitor[0] && this.chatComponentStore.currentChattingVisitor[0].supervision && !this.chatComponentStore.currentChattingVisitor[0].joinedConversation) ? "Really quit the supervision mode ?" : "Really quit the session ?"}
                        cancel_btn="No"
                        save_btn="Yes"
                        callBack={() => { this.chatComponentStore.currentChattingVisitor[0].quitAndBackTosupervision = false; this.chatComponentStore.quitChat(appstore) }}
                        closePopup={() => this.chatComponentStore.quitChatConfirm = false}
                        appStore={appstore} />
                }
                {this.chatComponentStore.banvisitor_initiate &&
                    <ConfirmationComponent show={this.chatComponentStore.banvisitor_initiate}
                        store={appstore}
                        title={"Ban visitor by ip"}
                        body_html={"Are sure want to ban visitor by ip?"}
                        cancel_btn="Cancel"
                        save_btn="Confirm"
                        callBack={() => this.chatComponentStore.banvisitor_initiate_ip(appstore)}
                        closePopup={() => this.chatComponentStore.banvisitor_initiate = false}
                        appStore={appstore} />
                }
                {
                    this.chatComponentStore.mailtranscripts &&
                    <EmailTranscriptPopup show={this.chatComponentStore.mailtranscripts}
                        store={this.chatComponentStore}
                        title={"Email Transcripts"}
                        body_html={"Email Transcripts"}
                        cancel_btn="Cancel"
                        from="chat"
                        save_btn="Ok"
                        closePopup={() => this.chatComponentStore.mailtranscripts = false}
                        appStore={appstore} />
                }
                {this.chatComponentStore.errormodel &&
                    <WarningComponent show={this.chatComponentStore.errormodel}
                        store={this.chatComponentStore}
                        cancel_btn="OK"
                        closePopup={() => this.chatComponentStore.errormodel = false}
                        appStore={appstore} />
                }

            </div>
        )
    }
})
export default ChatComponent;
