import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';
import DropDownComponent from '../../../../../../sharedComponents/dropDown/dropDown';
import FormValidator from '../../../../../../sharedFiles/FormValidator';
import axios from 'axios';

const DeptEditModeComponent = observer(class DeptEditModeComponent extends Component {

	constructor(props) {
		super(props);
		this.validator = new FormValidator([
			{
				field: 'name',
				method: 'isEmpty',
				validWhen: false,
				message: 'Department Name is required.'
			},
			{
				field: 'operators',
				method: 'isEmpty',
				validWhen: false,
				message: 'Please select an operator'
			}

		]);

		this.state = {
			validation: this.validator.valid(),
		}
		this.submitted = false;
		this.addEmailValidation = this.addEmailValidation.bind(this);
	}

	componentDidMount() {
		this.addEmailValidation();
		const element = document.getElementsByClassName("row action panelContent");
		if(element !=null && element.length > 1){
            element[element.length-1].firstChild.style.height = element[0].offsetHeight+"px";
		}
	}

	componentWillReceiveProps(nextProps) {


	}

	addEmailValidation() {
		var self = this;
		if (this.props.departToEdit.email_messages !== "") {
			if (this.validator.validations.length === 2) {
				this.validator.validations.push(
					{
						field: 'email',
						method: 'isEmail',
						validWhen: true,
						message: 'Please enter a valid email.'
					})
			}
		
		}
		else {
			this.validator.validations.map(function (field, fieldIndex) {
				if (field.field === 'email') {
					self.validator.validations.splice(fieldIndex, 1);
				}
			})
		}
	}

	handleFormSubmit = (event) => {
		event.preventDefault();
		//set email
		
		this.props.deptStore.isPageLoaded = false;
		const email = this.props.departToEdit.email; 
		this.props.departToEdit.email = this.props.departToEdit.email_messages;

		const validation = this.validator.validate(this.props.departToEdit);
	    
		this.setState({ validation },() =>{ this.submitted = true; });
		if (validation.isValid) {
			// save dept
			let deptData = this.props.departToEdit;
			deptData.email = email;

			let operators = deptData.operators;
			let userUids = [];
			deptData.department_uid = deptData.department_uid ? deptData.department_uid : null;
			for (let i in operators) {
				userUids.push(operators[i].user_uid)
			}
			deptData.user_uid = userUids;
			deptData.website_uid = this.props.appstore.selectedWebisiteUid;  //add website 

			axios({
				method: 'POST',
				url: this.props.appstore.url + "savedepartment.json",
				headers: { 'content-type': 'application/json' ,'charset':'utf-8'},
				data: deptData,
			}).then(result => {
				//console.log(result.data);
				if(result.data.length==1){
					if(result.data[0].logoutstatus==1){
					  this.props.appstore.logout();
					}
				  }
				if (result.data != null) {
					//delete deptData["website_uid"];
					this.props.appstore.deptOps.allDept = JSON.parse(JSON.stringify(result.data));
					let newdeptData = this.props.deptStore.setOpforDept(result.data);
					this.props.deptStore.departments = newdeptData;
					this.props.deptStore.deptEditMode = false;
					this.props.deptStore.cancelDeptAddition(this.props.departToEdit);
					if(deptData.department_uid==null){
						if(userUids.includes(this.props.appstore.userInfo.usersmodel.user_uid)){
							if(result.data.length>0){
								var newwebsiteuid=result.data[0].new_department_uid;
								if(!this.props.appstore.assignedTopics.includes("/topic/"+newwebsiteuid)){
									this.props.appstore.assignedTopics.push('/topic/'+ newwebsiteuid);
								}
							}
							
						}
					}else{
						if(userUids.includes(this.props.appstore.userInfo.usersmodel.user_uid)){
							if(!this.props.appstore.assignedTopics.includes("/topic/"+deptData.department_uid)){
								this.props.appstore.assignedTopics.push('/topic/'+ deptData.department_uid)
							}							
						}else{
							if(this.props.appstore.assignedTopics.includes("/topic/"+deptData.department_uid)){
								var value = "/topic/"+ deptData.department_uid;
									var arr = this.props.appstore.assignedTopics;
									arr = arr.filter(function(item) {
										return item !== value
									})
									this.props.appstore.assignedTopics=arr;
							}
						}
					} 
					// console.log(this.props.appstore.assignedTopics);
					this.props.deptStore.getOpandDeptforAdmin();
				} else {
					this.props.deptStore.isPageLoaded = true;
					this.props.deptStore.errormodel = true;
				}
				this.props.appstore.global_errorcount=0;
			}).catch((error) => {
				this.props.deptStore.deptEditMode = false;
				this.props.deptStore.isPageLoaded = true;
				this.props.deptStore.errormodel = true;
				// handle error
				//this.props.appstore.logout();
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.appstore.logout();
				}
			})

			//it add department to list from react side. not from backend data
			//this.props.deptStore.saveDepartment(this.props.departToEdit);
			// this.props.deptStore.resetValue(this.props.departToEdit);

		}
		this.props.deptStore.isPageLoaded=true;
	}

	removeEmailError =(value,validation) =>{
		
		if(this.props.appstore.validateEmail(value)){
			this.props.deptStore.removerEmailErr = true;
		 }
		else 
		 this.props.deptStore.removerEmailErr = false;
	}

	render() {
		let validation = this.submitted ?                         // if the form has been submitted at least once
			this.validator.validate(this.props.departToEdit) :   // then check validity every time we render
			this.state.validation
		const { departToEdit, deptStore, appstore } = this.props;



		return (
			<div className="row action panelContent">
				<form className="col-9 p-0">
					<Form.Group controlId="formGroupDeptChat" className="col-4">
						<Form.Control type="text" placeholder={appstore.getLanguageTrans("Department Name")}
							className={validation.name.isInvalid && 'has-error'}
							name="name"
							value={departToEdit.name}
							onChange={(event) => departToEdit.name = event.target.value} />
						<span className="error-block">{validation.name.message}</span>
					</Form.Group>
					<Form.Group as={Col} controlId="formGroupEmail" className="col-4 pl-0">
						<Form.Control type="email" placeholder={appstore.getLanguageTrans("Email")}
							className={(validation.email && validation.email.isInvalid) && 'has-error'}
							name="email"
							value={departToEdit.email_messages}
							onChange={(event) => { departToEdit.email_messages = event.target.value; this.addEmailValidation(); this.removeEmailError(event.target.value, validation) }} />
						<span className="error-block">{(validation.email  && validation.email.isInvalid && !deptStore.removerEmailErr) && validation.email.message}</span>
					</Form.Group>
					<Form.Group as={Col} controlId="formGroupOperator" className={"col-4 customDrpDwn pl-0 " + (validation.operators.isInvalid ? 'has-error' : '')} style={{width:250}} >
						<Form.Control type="text" placeholder=""
							className='d-none'
							name="operators"
							readOnly
							value={departToEdit.operators}
						/>

						<DropDownComponent dropDownOptions={deptStore.operatorList}
							store={deptStore}
							callBack={(selectedOption) => departToEdit.operators = selectedOption}
							selectedOption={departToEdit.operators}
							ismulti={true} />
						<span className="error-block">{validation.operators.message}</span>
					</Form.Group>
				</form>

				<div className="col-3 optionArea align-self-center">
					<div className="editWrapper row m-0">

						<div className="align-self-center cursor-pointer" onClick={() => deptStore.cancelDeptAddition(departToEdit)}>
							<p><img src={imageURLs.deleteIcon} /><span className="text-uppercase">{appstore.getLanguageTrans("Cancel")}</span></p>
						</div>
						{/* imageURLs.greenTick style={{width: 23}}  */}
						<div className="align-self-center cursor-pointer" onClick={(event) => this.handleFormSubmit(event)}>
							<p><img src={imageURLs.saveIcon} /><span className="text-uppercase">{appstore.getLanguageTrans("save")}</span></p>
						</div>
					</div>
				</div>
			</div>
		)
	}
})
export default DeptEditModeComponent;
