import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink} from "react-csv";
 

const pickUpTimeCSV = observer(class pickUpTimeCSV extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

    }
    secondsToTime(secs) {
      var hours = Math.floor(secs / (60 * 60));
  
      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
  
      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);
  
      if (hours == 0 && minutes == 0) {
        return seconds + "s";
      }
      if (hours == 0) {
        return minutes + "m " + seconds + "s";
      }
      else {
        return hours + "h " + minutes + "m " + seconds + "s";
      }
    }
    invexport(data) {
      let exportdata = []
      let pick_Date=0
      let prev_time=0
      if(data == null || !data){
        return exportdata;
      }
      if (data != null) {
      for (let j = 0; j < data.length; j++) {
        if (pick_Date=== data[j].quarter_date) {
          prev_time= Number(prev_time) + Number(data[j].time);
         exportdata[exportdata.length-1].pickupTime = prev_time + " s";
         exportdata[exportdata.length-1].pickupTimeSec = this.secondsToTime(prev_time);
       }
        else{
          data[j].Date = data[j].quarter_date
          data[j].pickupTime = data[j].time + " s";
          data[j].pickupTimeSec = this.secondsToTime(data[j].time);
          prev_time=Number(data[j].time)
          pick_Date=data[j].quarter_date
         exportdata.push(data[j])
        }
        
        }
      
      }
      return exportdata;
      
    }
    
    render() {
    let  headers = [
        { label: "Date", key: "Date"},
        { label: "PickUp Time(sec)", key: "pickupTime"},
        { label: "PickUp Time", key: "pickupTimeSec" },
      
      ];
        let exportdata = this.invexport(this.props.data.pickupTimeRecords);
        const {appStore}=this.props;
      return(
      <CSVLink  data={exportdata} headers={headers} target="_blank" filename={"PickUp_Time_"+this.props.dateselected+".csv"} >
       {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
      );
      }
});

export default pickUpTimeCSV;
