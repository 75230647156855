
import React, { Component } from "react";
import { observer } from 'mobx-react';
import Chart from "react-apexcharts";
//import { ApexCharts } from 'ng-apexcharts';

const TotalChartTimeChart = observer(class TotalChartTimeChart extends Component {
  constructor(props) {
    super(props);

   this.arr= ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT'];
   
   this.state = {
          
            series: [{
              name: '',
              data: this.props.store.performanceData.chartstime,
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                
                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    // reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                  },
                },
                zoom: {
                  enabled: true
                }
              },
              responsive: [{
                breakpoint: 480,
                options: {
                 
                }
              }],
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              dataLabels: {
                enabled: false
              },

              
              tooltip: {
                theme:"dark",
                position:"topleft",
                x : {show: false} ,
                marker: {show:false},
                y: {
                  formatter: function(timeInSeconds, { series, seriesIndex, dataPointIndex, w }) {
                    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
                    time = parseFloat(timeInSeconds).toFixed(3),
                    hours = Math.floor(time / 60 / 60),
                    minutes = Math.floor(time / 60) % 60,
                    seconds = Math.floor(time - minutes * 60),
                    milliseconds = time.slice(-3);

                    if(hours === 0){
                      return pad(minutes, 2) +'m:' + pad(seconds, 2)+'s';
                    }
                    
                    else{
                      return pad(hours, 2) +'h:' + pad(minutes, 2) +'m:' + pad(seconds, 2)+'s';

                    }
                    
                  }
                }
              },
         
              xaxis: {
                type: 'category',
                categories: this.props.store.performanceData.chartsDate,
              },
              yaxis: {
                forceNiceScale: true,
                min:0,
                type:'time',
                labels: {
                 
                  formatter: function(timeInSeconds, { series, seriesIndex, dataPointIndex, w }) {
                    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
                    time = parseFloat(timeInSeconds).toFixed(3),
                    hours = Math.floor(time / 60 / 60),
                    minutes = Math.floor(time / 60) % 60,
                    seconds = Math.floor(time - minutes * 60),
                    milliseconds = time.slice(-3);

                    if(hours === 0 && minutes != 0){
                      return pad(minutes, 2) +'m';
                    }else if(hours === 0 && minutes === 0){
                      return pad(seconds, 2)+'s';
                    }                       
                    else{
                      return pad(hours, 2) +'h';

                    }
                      // return pad(hours, 2) +'h:';

                    
                    
                  }
                },
               },
              
              fill: {
                opacity: 1
              }
            },
          
          
          };

  }
 
  render() {
   
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              width="900"

              height="300px"
            />
          </div>
        </div>
      </div>
    );
  }
})

export default TotalChartTimeChart;