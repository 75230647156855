import React, {Component } from 'react';
import { observer } from 'mobx-react';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';

const ResponseComponent = observer(class ResponseComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){
		 
	}

	selectComponent(chat){
		this.props.scriptStore.previewChatData = [];
		if(!chat.preventClick){
			this.props.scriptStore.selectedRow = chat;
			this.props.scriptStore.setChatData("",this.props.chatId);
			chat.showOptions = true;
		}
		else {
			chat.preventClick = false;
		}
	}

	showChatOptions(event,chat,value) { 
		event.stopPropagation();
		event.preventDefault();
		let timer = 0;
		if(chat) {
			timer = 100;
		}
		else{
			if(chat.timer) {
				clearTimeout(chat.timer);
			}
		}
		chat.timer = setTimeout(() => {
			
			if(!chat.preventClick){
				chat.showOptions = value;
			}
		},timer);
		
	}
	filterscriptResponses=(e,chatTypes) => {
		return chatTypes.filter( 
			(response) => {
					 if(true){
					  return (response.title.toLowerCase().indexOf(e.toLowerCase()) != -1 || response.reply.toLowerCase().indexOf(e.toLowerCase())  != -1);
					 }
			})
	}
	render(){

		let self = this; 
		const { chatTypes,
				scriptStore,
				sectionId,
				defaultOpen,
				chatId,appstore,serchoption } = this.props;				
		// var rowData = chatTypes.map(function(chat,chatIndex){
			var filterscript=this.filterscriptResponses(serchoption,chatTypes); 
			var rowData=filterscript.map(function(chat,chatIndex){ 
							return (
								<div key={chatIndex} className="row action cursor-pointer" 
										// onMouseOver={(event) => self.showChatOptions(event,chat,true)}
										// onMouseOut={(event) => self.showChatOptions(event,chat,false)}
										onClick={() => self.selectComponent(chat)}>  
										{/* extra class removed becz of this class opacity applying  className =editing */}
									<div className={chat.showOptions ? "col-2 col-sm-1 count  " + (!chat.enabled ? ' disabled' : '') : "col-2 col-sm-1 count" + (!chat.enabled ? ' disabled' : '')}>
										<p>{chatIndex+1}</p>
									</div>
									{/* <div className="col-8 col-sm-11 text-left data"> */}
									<div className="col-12 col-sm-11 text-left data">
										<div className="row"> 
										{/* editing */}
											{/* <div className={chat.showOptions ? 'col-12 col-sm-7 col-lg-5 col-xl-6 ' + (!chat.enabled ? ' disabled' : '') : 'col-12' + (!chat.enabled ? ' disabled' : '')} > */}
											<div className={chatId !== 1 ? (chat.showOptions ? 'col-6 col-sm-7 col-lg-5 col-xl-6 ' + (!chat.enabled ? ' disabled' : '') : 'col-6' + (!chat.enabled ? ' disabled' : '')) 
													: (chat.showOptions ? 'col-8' + (!chat.enabled ? ' disabled' : '') : 'col-8' + (!chat.enabled ? ' disabled' : ''))} >
												<p className="quest">{chat.title}</p>
												{
													chat.reply &&
														<p className="expAns" dangerouslySetInnerHTML={{__html: chat.reply}}></p>
												}
											</div>
												{/* <div className={chat.showOptions ? "col-12 col-sm-5 col-lg-7 col-xl-6 optionArea align-self-center" : "d-none"}> */}
												<div className={chatId !== 1 ? "col-6 optionArea align-self-center" : "col-4 optionArea align-self-center" }>
													<div className="editWrapper row">
														<div className="custom_radioBtn align-self-center">
															<CheckBoxComponent radioValue={chat.enabled}
																				  store = {scriptStore}
																				  callBack = {() => scriptStore.setValue(chat.related_uid)}	/>
														</div>
														<div className="align-self-center cursor-pointer" onClick={() => scriptStore.editResponse(chat,chatId)}>
															<p><img src={imageURLs.editIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("Edit")}</span></p>
														</div>
														{ chatId !== 1 && 
															<div className="align-self-center"  onClick={() => scriptStore.deleteRule(chat,chatId)} >
																<p><img src={imageURLs.deleteIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("Delete")}</span></p>
															</div>
														}
													</div>
												</div>
										</div>
										
									</div>
								</div>
							)
							
						}
						)
		return (
			<div id={sectionId} className={!defaultOpen ? "collapse col-12 p-0" : "col-12 show scrollArea p-0" } data-parent="#panelGroup" style={{marginTop :"0px !important"}} >
				<CustomScroll heightRelativeToParent="100%" keepAtBottom={defaultOpen ? true : false} allowOuterScroll={true} scrollTo={scriptStore.scrollToAmt}>
				{rowData}				
				</CustomScroll>
			</div>
		)
	}
})
export default ResponseComponent;
