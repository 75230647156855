import React, {Component } from 'react';
import { observer } from 'mobx-react';
import CustomScroll from 'react-custom-scroll';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';
import SettingsDataComponent from './settingsDataMode';
import cloneDeep from 'lodash/cloneDeep';


const SortableItem = sortableElement(({settingsStore,field,pos,appstore,type}) => <SettingsDataComponent settingsStore = {settingsStore}
																				field = {field} pos = {pos} appstore = {appstore} type = {type}
																				 />);
const SortableContainer = sortableContainer(({children}) => {
  return <div>{children}</div>;
});

const SettingsTableComponent = observer(class SettingsTableComponent extends Component {

	constructor (props) {
        super(props);
        this.onSortEnd = this.onSortEnd.bind(this);
    }
	componentWillMount(){

	}

	onSortEnd (data) {
		if(this.props.type === 1){  
			this.props.settingsStore.chatFieldsClone = arrayMove(this.props.settingsStore.chatFieldsClone, data.oldIndex, data.newIndex); 
			this.props.settingsStore.chatFields = cloneDeep(this.props.settingsStore.chatFieldsClone);
		}
		else{
			this.props.settingsStore.offlineChatFieldsClone = arrayMove(this.props.settingsStore.offlineChatFieldsClone, data.oldIndex, data.newIndex);	
			this.props.settingsStore.offlineChatFields =  cloneDeep(this.props.settingsStore.offlineChatFieldsClone);
		}
	}

	render(){
		const { settingsStore,
				chatFields,
				type,
				appstore } = this.props;
		return (
			<div className="scroll_row row mx-0">
				<div id="panelGroup" className={"panelWrapper col-12 col-lg-12 p-0" + (chatFields.length % 2 === 0 ? ' odd' : ' even')}>
					<div className="col-12 p-0">
						<div className="row panelHeader align-items-center">
							<div className="col-1">
								
							</div>
							<div className="col-3 pl-0">
								<p className="text-left">{appstore.getLanguageTrans("Field Title")}</p>
							</div>
							<div className="col-2">
								<p className="text-left">{appstore.getLanguageTrans("Input Type")}</p>
							</div>
							<div className="col-2">
								<p className="text-left">{appstore.getLanguageTrans("Items")}</p>
							</div>
							<div className="col-4 pl-0">
								<p className="text-left"><span>*</span> {appstore.getLanguageTrans("Mandatory")}</p>
							</div>
						</div>
					</div>
					<div className="scrollWrapper col-12 p-0">
						<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true} scrollTo={settingsStore.componentScroll}>
							<div className="scriptPanel row">
								<div className="col-12 p-0">
								<SortableContainer onSortEnd={this.onSortEnd} 
																	axis="y" lockAxis="y" 
																	lockToContainerEdges={true}
																	lockOffset = "0%"
																	distance = {2}
																	helperClass = "ghostClass settingsSort" >
									{
										chatFields.map(function(field,fieldIndex){
											return (
											<SortableItem key={`item-${fieldIndex}`} index={fieldIndex} pos={fieldIndex} field={field} 
																		  settingsStore = {settingsStore}
																		  appstore = {appstore}
																		  type = {type}/>
											)
										})
									}
									</SortableContainer>		
										
								</div>
							</div>
						</CustomScroll>
					</div>
					{
						((type === 1 && settingsStore.addChatFields) || (type === 2 && settingsStore.addOfflineChatFields)) && 
				<button type="button" className="custom_btn float-left addBtn btn btn-primary text-uppercase" onClick={() => settingsStore.addField(type)}>{appstore.getLanguageTrans("Add")}</button>
							
					}
				
				</div>
			</div>
		)
	}
})
export default SettingsTableComponent;
