import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';  

const ToobarThumbnailpreview = observer(class ToobarThumbnailpreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            position: 5,
            center: false
        }

    }

    componentDidMount() {
        //this.props.widgetStore.buttonviewarray=this.buttonarray(this.props.widgetdata.toolbar.buttons);
       var self=this;
        // setTimeout(function() {
        //     self.props.widgetdata.buttonviewarray=self.buttonarray(self.props.widgetdata.toolbar.buttons);
        //     console.log("loadetoold");
        // }, 1000);
        
    }  

      gettoolbarcolor=(color)=>{ 
          let colorcode=color;
          for(let i in this.props.widgetStore.topBarColors){
            if(color==this.props.widgetStore.topBarColors[i].value){
                colorcode=this.props.widgetStore.topBarColors[i].colorCode;
                break;
            }
          } 
          return colorcode;
      }
      LightenDarkenColor(color, amt) { 
          
        let col=color;
        for(let i in this.props.widgetStore.topBarColors){
          if(color==this.props.widgetStore.topBarColors[i].value){
            col=this.props.widgetStore.topBarColors[i].colorCode;
              break;
          }
        }   
	    var usePound = false;
	  
	    if (col[0] == "#") {
	        col = col.slice(1);
	        usePound = true;
	    }
	 
	    var num = parseInt(col,16);
	 
	    var r = (num >> 16) + amt;
	 
	    if (r > 255) r = 255;
	    else if  (r < 0) r = 0;
	 
	    var b = ((num >> 8) & 0x00FF) + amt;
	 
	    if (b > 255) b = 255;
	    else if  (b < 0) b = 0;
	 
	    var g = (num & 0x0000FF) + amt;
	 
	    if (g > 255) g = 255;
        else if (g < 0) g = 0; 
        
	    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
	  
    }
    
    // geticonslibray=(font)=>{
    //         for(let i in this.props.widgetStore.iconLibraryFiles){
    //             if(font==this.props.widgetStore.iconLibraryFiles[i].icon){

    //             }
    //         }
    // }

    colorvaluecheck = (color,a) =>{
        var topBarColorsdefult={"blue":"#0481B0","green":"#608706","pink":"#B0097D","orange":"#D6680B","red":"#9D0A09","teal":"#434E55","custom":"#000000","black":"#000"}
        var topBarTextColorsdefult={"black":"#000000","white":"#FFFFFF","custom":"#000000"};
        if(typeof(color)=="string"){
           if(!topBarColorsdefult.hasOwnProperty(color)){
              if(/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color)==false){
                color="#"+color;
              }
           }
        } 
        return  color;
    }

    colortextvaluecheck = (textcolor) =>{
        var topBarColorsdefult={"blue":"#0481B0","green":"#608706","pink":"#B0097D","orange":"#D6680B","red":"#9D0A09","teal":"#434E55","custom":"#000000","black":"#000"}
        var topBarTextColorsdefult={"black":"#000000","white":"#FFFFFF","custom":"#000000"};
       
        if(typeof(textcolor)=="string"){
          if(!topBarTextColorsdefult.hasOwnProperty(textcolor)){
             if(/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(textcolor)==false){
              textcolor="#"+textcolor;
             }
          }
       }
       return  textcolor;
    }
    
    componentWillUnmount() {
    }
    buttonarray = (buttons) => { 
        let buttonsarray = buttons;
        let newarray = [];
        let k=0;
        for (let i in buttons) {  
            // console.log(i);
            var chat_or_email_visivle=false; 
            if(i == "email" || i == "chat"){
                if(buttons[i].hasOwnProperty("visible")==false){
                    chat_or_email_visivle=true;
                    if(i == "chat" && !buttons[i].hasOwnProperty("caption")){
                    buttons[i].caption="Live Chat with us";
                    }
                    if(i == "email" && !buttons[i].hasOwnProperty("caption")){
                        buttons[i].caption="Leave us a message";
                        }
                }
            }
            if (chat_or_email_visivle || buttons[i].visible == true) {
                if (i == "call") {
                    newarray.push(buttons[i])
                    newarray[k].label = "call";
                } else if (i == "chat") {
                    newarray.push(buttons[i])
                    newarray[k].label = "chat";
                } else if (i == "email") {
                    newarray.push(buttons[i])
                    newarray[k].label = "email";
                } else if (i == "social_facebook") {
                    newarray.push(buttons[i])
                    newarray[k].label = "social_facebook";
                } else if (i == "social_twitter") {
                    newarray.push(buttons[i])
                    newarray[k].label = "social_twitter";
                } else if (i == "custom_1") {
                    newarray.push(buttons[i])
                    newarray[k].label = "custom_1";
                } else if (i == "custom_2") {
                    newarray.push(buttons[i])
                    newarray[k].label = "custom_2";
                } else if (i == "custom_3") {
                    newarray.push(buttons[i])
                    newarray[k].label = "custom_3";
                }
                k = k + 1;
            }
        } 
        // console.log(newarray);
        return newarray;
    }

    render() {

        var self = this;

        const { appstore,
            widgetdata,
            widgetStore } = this.props; 
          //  let buttons = this.buttonarray(widgetdata.toolbar.buttons); 
        return (
                    <div id="maindiv">
                        <div id="div_buttons">
                            <ul  style={{background:self.gettoolbarcolor((widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('color')) ?  self.colorvaluecheck(widgetdata.toolbar.style.color,0) : "#0481B0")}}>
                            {widgetdata.buttonviewarray!=undefined && widgetdata.buttonviewarray.map(function(buttonEle, buttonIndex) {  
                                return(
                                <li key={Math.random()} style={{
                                    borderRightColor: self.LightenDarkenColor((widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('color'))  ? self.colorvaluecheck(widgetdata.toolbar.style.color,1) : "#0481B0", 20),
                                    borderLeftColor: self.LightenDarkenColor((widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('color'))  ? self.colorvaluecheck(widgetdata.toolbar.style.color,1) : "#0481B0", 64),
                                    borderBottomColor: self.LightenDarkenColor((widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('color'))  ? self.colorvaluecheck(widgetdata.toolbar.style.color,1) : "#0481B0", 20),
                                    borderTopColor: self.LightenDarkenColor((widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('color'))  ? self.colorvaluecheck(widgetdata.toolbar.style.color,1) : "#0481B0", 64),
                                }}> 
                                    {buttonEle.hasOwnProperty("iconType") &&
                                        <React.Fragment>
                                            {buttonEle.iconType == "font" &&
                                                <React.Fragment> 
                                                    < div >
                                                        <span style={{ paddingRight: "0px",color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }} className={"HelpOnClick-fa HelpOnClick-fa-"+buttonEle.iconFont}></span>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }}>{buttonEle.caption}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {buttonEle.iconType == "none" &&
                                                <React.Fragment>
                                                    <div>
                                                        <span  style={{ paddingRight: "0px" }} className={"HelpOnClick-fa HelpOnClick-fa-"}></span>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }}>{buttonEle.caption}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                           {buttonEle.iconType == "default" &&
                                                <React.Fragment>
                                                    <div>
                                                        <span  style={{ paddingRight: "0px",color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }} className={"HelpOnClick-fa HelpOnClick-fa-chat"}></span>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }}>{buttonEle.caption}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                             {buttonEle.iconType == "custom" &&
                                                <React.Fragment>
                                                    <div>
                                                        <span  style={{ paddingRight: "0px"}}><img src={buttonEle.iconUrl} style={{width:"24px",height:"24px"}}/></span>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }}>{buttonEle.caption}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    } 
                                    {!buttonEle.hasOwnProperty("iconType") && 
                                                <React.Fragment>
                                                     <div>
                                                        <span  style={{ paddingRight: "0px",color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }} className={"HelpOnClick-fa HelpOnClick-fa-" + (buttonEle.label==="chat"? "chat":buttonEle.label==="call"? "phone":buttonEle.label==="email"? "envelope-o":buttonEle.label==="social_facebook"? "facebook":buttonEle.label==="social_twitter"? "twitter":"")+" "}></span>
                                                    </div>
                                                    <div>
                                                        <span style={{ color: (widgetdata.toolbar.hasOwnProperty('style') && widgetdata.toolbar.style.hasOwnProperty('textColor'))  ? self.colortextvaluecheck(widgetdata.toolbar.style.textColor) : "#FFFFFF" }}>{buttonEle.caption}</span>
                                                    </div>
                                                </React.Fragment>  
                                    }
                                    </li>
                                ) 
                        })
                            }
                            </ul>
                        </div>
                        </div> 
        ) 
    }
})
export default ToobarThumbnailpreview;
