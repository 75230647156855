import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PieChart from 'react-minimal-pie-chart';

const PieChartComponent = observer(class PieChartComponent extends Component {

    constructor (props) {
        super(props);
    }

    render(){
        const {appstore} = this.props;
        let self = this;


        return (

<PieChart
  animate={false}
  animationDuration={500}
  animationEasing="ease-out"
  cx={50}
  cy={50}
  data={[
   
    {
      color: '#3ba8c5',
      title: 'Two',
      value: this.props.store.performanceData.totalautoSum
    },
    {
      color: '#7fae72',
      title: 'Three',
      value: this.props.store.performanceData.totalManualSum
    }
  ]}
  label
  labelPosition={50}
  labelStyle={{
    fill: 'White',
    fontFamily: 'sans-serif',
    fontSize: '15px'
  }}
  lengthAngle={360}
  lineWidth={100}
  onClick={undefined}
  onMouseOut={undefined}
  onMouseOver={undefined}
  paddingAngle={0}
  radius={50}
  rounded={false}
  startAngle={0}
  style={{
    height: '200px'
  }}
  viewBoxSize={[
    100,
    100
  ]}
/>


        )
}
})

export default PieChartComponent;