import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';

const SettingsDataComponent = observer(class SettingsTableComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}

	splitParams(field) {
		field.paramObj = [];
		let fieldParamObj = field.params.split('\n');
        fieldParamObj.map(function(param,paramIndex) {
          let obj = {
            value: paramIndex+1,
            label: param
          }
          field.paramObj.push(obj);
        })
	}

	render(){
		const { settingsStore,
				field,
				type,
				appstore,
				pos } = this.props;
		return (
			
				<div id={"chatComp"+type+pos} className={"row m-0 ruleComponent chat data panelContent align-items-center" +(pos === 0 || pos%2 === 0 ? ' even' : ' odd') + (field.edit ? ' editing ' : ' ')+appstore.selectedColor.value}>
					<div className="col-1">
						<img style={{opacity:0.5}} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.arrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.arrow_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.arrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.arrow_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.arrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.arrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.arrow_voilet : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.arrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.arrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.arrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.arrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.arrow_skyblue : imageURLs.arrow_steelblue} alt="faq" className="img-fluid addSettingsIcon"/>
					</div>
					<div className="col-3 pl-0 fieldSection">
						{
							!field.edit && 
								<p className="text-left m-0">
									{field.name}
								</p>
						}
						{
							field.edit && 
								<Form.Control type="text" placeholder="" 
			    					name="field"
			    					value={field.clone.name} 
			    					onChange={(event) => field.clone.name = event.target.value.replaceAll("\"","")}/>
						}
					</div>
					<div className={"col-2 fieldSection" + (field.edit ? ' customDrpDwn pl-0' : '')}>
						{
							!field.edit && 
								<p className="text-left m-0">
									{field.typeObj.label}
								</p>
						}
						{
							field.edit && 
								<DropDownComponent dropDownOptions={settingsStore.fieldTypeList} 
		    					   store={settingsStore}
		    					   callBack={(selectedOption) => {field.clone.typeObj = selectedOption;field.clone.type = selectedOption.value}}
		    					   selectedOption = {field.clone.typeObj}
		    					   isFixed = {true}/>
						}
					</div>
					<div className={"col-2 paramField fieldSection" + (field.edit ? ' pl-0' : '')}>
						{
							!field.edit && 
								<p className="text-left m-0" dangerouslySetInnerHTML={{__html: field.params}}>
								</p>
						}
						{
							field.edit && (field.clone.type === "radio" || field.clone.type === "dropdown") && 
								<textarea name="params" rows="2" value={field.clone.params} onChange={(e) => {field.clone.params = e.target.value.replaceAll("\"",""); this.splitParams(field.clone)}} />
						}
					</div>
					<div className="col-4 optionArea pr-0">
						<div className="editWrapper row">
							{
								!field.edit && 
									<p className="mandatoryData text-left col-3 m-0">{field.clone.mandatory === 0 ? '' : 'On' }</p>
							}
							{
								field.edit && 
								<div className={"custom_radioBtn align-self-center"+(field.type === "label" ? ' opacity-none' : '')+(!field.edit ? ' disabled' : '')}>
									<CheckBoxComponent radioValue={field.clone.mandatory}
														  store = {settingsStore}
														  callBack = {() => field.edit ? field.clone.mandatory = field.clone.mandatory ? 0 : 1 : ''}	/>
								</div>
							}
								<div className="align-self-center cursor-pointer col-4 pr-0 save_edit" onClick={() => settingsStore.saveField(field.clone,type,field)}>
									<p className="m-0"><img style={!field.edit ? {marginRight:"6px"}:{}} src={!field.edit ? imageURLs.editIcon : imageURLs.saveIcon}/><span className="text-uppercase">{!field.edit ? 'edit' : 'save'}</span></p>
								</div>{/*style={{left: !field.edit ? 0: 85}*/}
								<div className="align-self-center cursor-pointer col-4 p-0 del"  onClick={() => settingsStore.cancelField(type,field)}>
									<p className="m-0"> <img src={imageURLs.deleteIcon}/><span className="text-uppercase">{!field.edit ? ' delete' : ' cancel'}</span></p>
								</div>
							</div>
					</div>
					
				</div>
											
		)
	}
})
export default SettingsDataComponent;
