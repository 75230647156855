import { decorate, observable, extendObservable } from 'mobx';
import axios from 'axios';

import {data} from '../../../../assets/JSON/admin_security';
import { propTypes } from 'mobx-react';

class SecurityStore {
	  

  	constructor(appstore){

      this.goBack=false;
        this.appStore=appstore;
  		this.securitytab ={
          "force_ssl": 0,
          "ipBlackList":[],
          "chatHiddenCountries":[], 
          "country_black_list":"", 
          "selectedCountry":'',
          "ip_black_list":[],
          "private_label":0,
          "private_label_title":"",
      };
      this.consentPolicies ={ 
         "enable_consent": 0,
         "enable_consent_offline":0,
         "message_field":"",
         "link_title_field1":"",
         "link_field1":"",
         "link_title_field2":"",
         "link_field2":"",
         "link_title_field3":"",
         "link_field3":"",
         "website_uid":""
    };
  
    this.nonmandatoryfields=
     {
         message_field :{isInvalid:false , message:""},
         link_title_field1:{isInvalid:false , message:""},
         link_field1:{isInvalid:false, message:""},
         link_title_field2:{isInvalid:false , message:""},
         link_field2:{isInvalid:false, message:""},
         link_title_field3:{isInvalid:false, message:""},
         link_field3:{isinvalid:false, message:""}
     }
     this.mandatoryfields=
     {
         link_title_field1:{isInvalid:false , message:""},
         link_field1:{isInvalid:false, message:""},
         message_field:{isInvalid:false, message:""}
     }

  		this.countryDropDownOptions = [
                {value:1, label:'USA'},
                {value:2, label:'England'},
                {value:3, label:'United Kingdom'},
                {value:4, label:'Finland'},
                {value:5, label:'Australia'},
                {value:6, label:'Germany'},
                {value:7, label:'Denmark'},
                {value:8, label:'Sweden'},
                {value:9, label:'Netherlands'},
                {value:10, label:'Italy'},
                {value:11, label:'South Africa'},
                {value:12, label:'Spain'},
                {value:13, label:'France'},
                {value:14, label:'Israel'},
                {value:15, label:'India'}
                ];
        this.splitIpValue();
        this.saveinProcess=false;
        this.errormodel=false;
        this.ispageLoaded=false;
        this.previewconsent="";
     }

     setCountry(selectedOption) {
     	this.securitytab.selectedCountry = selectedOption;
     }

     splitIpValue() {
     	let self = this;
         this.ipText = "";
     	this.securitytab.ipBlackList.map(function(ip,ipIndex){
     		self.ipText+=ip;
     		self.ipText+='\n';
         });
     }
     getAllcountries = () => {
      axios({
         method: 'GET', 
         url: this.appStore.url+'countriesList.json',
         headers: { 'content-type': 'application/json','charset':'utf-8' },
         data: this.securitytab,
       }).then(result=> {
          //console.log(result);
          if(result.data!=null){
            this.countryDropDownOptions=result.data;
          }
         this.appStore.global_errorcount=0;     
      }).catch((error) => { 
        console.log(error);
        console.log("error count :"+this.appStore.global_errorcount);
        this.appStore.global_errorcount=this.appStore.global_errorcount+1;
        if(this.appStore.global_errorcount>20){
        // this.appStore.logout();
        } 
      });
     }
     saveSecurity() { 
        // this.securitytab.ipBlackList = this.ipText.split('\n');
        this.securitytab.ip_black_list = this.securitytab.ipBlackList;  
        if(this.securitytab.selectedCountry.value!=null){  
         this.securitytab.chatHiddenCountries.push(this.securitytab.selectedCountry.label);  
        }
        this.securitytab.country_black_list=JSON.stringify(this.securitytab.chatHiddenCountries);
        this.saveinProcess=true; 
         axios({
            method: 'POST', 
            url: this.appStore.url+'secureIpSettings.json',
            headers: { 'content-type': 'application/json','charset':'utf-8' },
            data: this.securitytab,
          }).then(result=> {
                        //  console.log('ipsettings response*******');
                        //console.log(result.data); 
                        if(result.data.length==1){
                           if(result.data.logoutstatus==1){
                             this.appStore.logout();
                           }
                          }
                        let self=this;
                        if(result.data.querystatus == 0){ 
                           self.saveinProcess=false;
                           this.errormodel=true; 
                        }else{
                           this.appStore.userInfo.accountsmodel=result.data;
                            if( this.appStore.userInfo.accountsmodel.private_label==1){
                              document.title =  this.appStore.userInfo.accountsmodel.private_label_title;
                              }
                              this.securitytab.selectedCountry='';
                           //clone object for cancel button option
                           this.backupSecuritytab= Object.assign({},this.securitytab);
                           setTimeout(function() {
                              self.saveinProcess=false;
                           }, 500);
                            
                        }
                        
                        this.appStore.global_errorcount=0;     
            }).catch((error) => {
               this.saveinProcess=false;
              // this.appStore.logout();
              console.log(error);
              console.log("error count :"+this.appStore.global_errorcount);
              this.appStore.global_errorcount=this.appStore.global_errorcount+1;
              if(this.appStore.global_errorcount>20){
              // this.appStore.logout();
              }
                            }); 
     }
     saveConsent(){ 
      this.saveinProcess=true; 
      axios({
         method: 'POST', 
         url: this.appStore.url+'consentPolicies.json',
         headers: { 'content-type': 'application/json','charset':'utf-8' },
         data: this.consentPolicies,
       }).then(result=> {
                     //  console.log('consentpolicies response*******');
                     // console.log(result.data); 
                     if(result.data.length==1){
                        if(result.data[0].logoutstatus==1){
                          this.appStore.logout();
                        }
                       } 
                       
                     let self=this;
                     if(result.data[0].querystatus == 0){
                        self.saveinProcess=false;
                        this.errormodel=true; 
                     }else{
                        this.appStore.userInfo.allwebistes=result.data;
                        //clone object
                        this.backupConsentPolicies= Object.assign({},this.consentPolicies);
                     setTimeout(function() {
                        self.saveinProcess=false;
                     }, 500);
                  }
                  this.appStore.global_errorcount=0;  
         }).catch((error) => {
            //console.log(error); 
            //this.appStore.logout();
            this.saveinProcess=false;
            console.log(error);
            console.log("error count :"+this.appStore.global_errorcount);
            this.appStore.global_errorcount=this.appStore.global_errorcount+1;
            if(this.appStore.global_errorcount>20){
            // this.appStore.logout();
            }
                         }); 
     }
     
 }

 decorate(SecurityStore, {
     securitytab: observable,
     selectedCountry: observable,
     ipText: observable,
     consentPolicies:observable,
     saveinProcess:observable,
     errormodel:observable,
     ispageLoaded:observable,
     backupSecuritytab:observable,
     backupConsentPolicies:observable,
     goBack:observable,
     mandatoryfields:observable,
     previewconsent:observable,
     countryDropDownOptions:observable
    })
 export default SecurityStore;