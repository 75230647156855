import { decorate, observable, extendObservable } from 'mobx';
import axios from 'axios';
import {data} from '../../../assets/JSON/admin_script';
import {invitationRules} from '../../../assets/JSON/admin_invitationrules';


class InvitationRulesStore {
	  

  	constructor(appstores){ 
          this.appStore=appstores;
          this.lanDropDownOptions = this.appStore.languages;
          this.custom_message_isvalid=true;
          this.ruleOpenFor = [
                {value:'all', label:'All vistors'},
                {value:'lead', label:'First timers'},
                {value:'ret', label:'Returning'}
          ];
          
          this.pageisLoaded =false;

          this.conditionType = [
            {value:'url', label:'Url'},
            {value:'referrer', label:'Referrer'}
          ];

          this.conditionConform = [
                {value:'contains', label:'contains'},
                {value:"no_contains", label:"doesn't contain"},
                {value:'matches', label:'matches'},
                {value:"no_match", label:"doesn't match"},
                {value:"starts_with", label:"starts with"},
                {value:"ends_with", label:"ends with"}
          ];

          this.conditions_combine = [
                {value: '||', label: 'Match Any'},
                {value: '&&', label: 'Match All'}
          ];
          this.onfirstTabClick=false;
          this.invitation_msgs =  [
            {value: 'default', label: 'Default message'},
            {value: 'custom', label: 'Enter new message'},
            {value: 'related', label: 'Script item'}
      ];;

          this.chat_invitation_msg = [
                {value: 'default', label: 'Default message'},
                {value: 'custom', label: 'Enter new message'}
          ];
          this.chat_redirect_dept = [];
          this.chat_redirect_opt = [];

           this.selectedLanguage = this.lanDropDownOptions[0];
            // this.rules = invitationRules;
           this.rules = [];
           this.isPageLoaded=false;
           this.defaultrules=[];
           this.confirm = false;
           this.confirmDelCond = false;
           this.cancelRule = false; 
           this.ruleToDelete = {};
           this.conditionToDelete = {}; 
           this.errormodel=false; 
           this.validate=false;
           this.alloperators=[];
           this.vARules=[];
           extendObservable(this, {
               responseData: [],
            });
            this.selectedScriptItem="";           
          this.getScriptitem();
           this.docHeight = 'auto'     

          
     }
     getScriptitem(){    
      axios({
        method: 'POST', 
        url: this.appStore.url+'va_invitaionsScriptItem.json',
        headers: { 'content-type': 'application/json' }, 
        data: {"lang":this.selectedLanguage.value,"website_uid": this.appStore.selectedWebisiteUid}
      }).then(result=> {
        //  console.log(result.data);
        if(result.data.length==1){
					if(result.data[0].logoutstatus==1){
					  this.appStore.logout();
					}
				  }
        var basicActions=[];
        var customResponse=[];
        var relatedLinks=[];
        for(let i in result.data){
        if(result.data[i].isDefault==1 && result.data[i].type==1){ 
          var basic_Actions={
            "title" : result.data[i].question,
            "id": result.data[i].id,
            "reply": result.data[i].text,
            "keyWords": result.data[i].keywords,
            "related_uid" : result.data[i].va_response_uid,
          }
          basicActions.push(basic_Actions);
        } 
        if(result.data[i].isDefault==0 && result.data[i].type==1){  
          var custom_Response={
            "title" : result.data[i].question,
            "id" : result.data[i].id,
            "reply" : result.data[i].text,
            "keyWords" : result.data[i].keywords,
            "related_uid" : result.data[i].va_response_uid,
          }
          customResponse.push(custom_Response);
        } 
        if(result.data[i].isDefault==0 && result.data[i].type==2){
          var related_Links={
            "title" : result.data[i].linkTitle,
            "id": result.data[i].id,
            "reply": result.data[i].text,
            "keyWords": result.data[i].keywords,
            "link": result.data[i].linkUrl,
            "related_uid" : result.data[i].va_response_uid,
          }
          relatedLinks.push(related_Links);
        }
      }  
     var  scriptItmsarray= 
          {
            "data": [
                    {
                      "basicActions": {
                        "value": 1,
                        "chatTypes":basicActions
                      },
                      "customResponse": {
                        "value": 2,
                        "chatTypes": customResponse	
                      },
                      "relatedLinks" : {
                        "value": 3,
                        "chatTypes": relatedLinks
                      }
                      
                    }
           ]
         }   
         
        this.responseData =  scriptItmsarray.data[0];               
        this.appStore.global_errorcount=0;
        
      }).catch((error) => {
           //console.log(error);
            // this.appStore.logout(); 
            console.log(error);
            console.log("error count :"+this.appStore.global_errorcount);
            this.appStore.global_errorcount=this.appStore.global_errorcount+1;
            if(this.appStore.global_errorcount>20){
            // this.appstore.logout();
            }

          }); 
     
     }
     getselectditems(a){  
        this.selectedScriptItem=a.related_id;  
     }
     validatefields(){ 
      if(this.conditionToEdit.mask != "")
        this.validate=false; 
        else
        this.validate=true;
     }
      //Fetching virtualAgent Invitation rules
     virtualAgentRules(){ 
      this.addRule = false;
      this.editRule = false;   
      let rulesForwebsite_uid=[];
      let self=this;    
      let x = self.appStore.userInfo.virtualAgentRules.filter(function (option) { 
        // console.log(option.lang);
        //filtering defualt English language data first time based on account id
        if(self.appStore.userInfo.accountsmodel.account_uid == option.accountUid  && self.selectedLanguage.value==option.lang){
         // var inv_rules=JSON.stringify(option.invitationRules); //.replace("\"", '\\"');
          //alert(inv_rules);
          // console.log(option.invitationRules);
          let vaRules=JSON.parse(option.invitationRules.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));   
          //filtering invitation rules based on websiteid
          let x = vaRules.filter(function (optionrules) { 
            if(self.appStore.selectedWebisiteUid===optionrules.website_uid){ 
              // console.log(optionrules);
              rulesForwebsite_uid.push(optionrules);
            }
          }); 
        }
    });  
     this.rules=rulesForwebsite_uid;
     this.defaultrules=rulesForwebsite_uid;
     let index=0;
     for(let i in  this.rules){
       this.rules[i].id=index;
       index++;
     }
     setTimeout(() => { 
     this.isPageLoaded=true; 
     },500);
     }
      
     //Sorting virtualAgent Inviation Rules
     sortableVertualAgentrules(rulessorted){ 
      let changednewrules=[];
      let idchanged;  
      let langchanged;    
      let self=this;
      //fetching all rules data except selected website 
      let x = self.appStore.userInfo.virtualAgentRules.filter(function (option) {                     
        // accountid and select websiteid==> if  selected language record is  exists then Pushibg  as new rule to existing rules                  
        if(self.appStore.userInfo.accountsmodel.account_uid == option.accountUid  && self.selectedLanguage.value==option.lang){ 
          let vaRules=JSON.parse(option.invitationRules.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));         
          //filtering  invitation rules  language id("id required for updating") based on websiteid
          let ii=0;
                let x = vaRules.filter(function (optionrules) { 
                if(self.appStore.selectedWebisiteUid===optionrules.website_uid){  
                  idchanged=option.id;
                  langchanged=option.lang; 
                } 
              });
            }
    }); 

    //Setting selected website   rules data 
      let ruleschanged = rulessorted; 
      for(let x in ruleschanged){
        let editruleschanged ={};
          editruleschanged = {
            "enabled":ruleschanged[x].enabled,
            "time_passed":ruleschanged[x].time_passed,
            "will_open":ruleschanged[x].will_open,
            "conditions":ruleschanged[x].conditions,
            "conditions_combine":ruleschanged[x].conditions_combine,
            "message_action":ruleschanged[x].message_action,
            "new_message":ruleschanged[x].new_message,
            "website_uid":ruleschanged[x].website_uid,
            "related_uid":ruleschanged[x].related_uid,
            "department_uid":"",
            "op_uid":"",
            "websites":[]
          }   
          changednewrules.push(editruleschanged); 
      }   
       var sortable=3;         
       //update virtualAgent rules method   
             this.saveVAinvitationrules(idchanged,langchanged,changednewrules,rulessorted,sortable);     
     }
     //Fetching Chat Invitation rules
     getInvationRules=() =>{           
      this.addRule = false;
       this.editRule = false;   
       let rulesForwebsite_uid=[];
       let self=this;
       let rule_inv=this.appStore.userInfo.accountsmodel.rules_invitation.replace(/\n/g,"\\n").replace(/\r/g,"\\r").replace(/\t/g,"\\t");
       if(rule_inv.trim().length !=0){ 
          let x = JSON.parse(rule_inv).filter(function (option) {
          if(self.appStore.selectedWebisiteUid===option.website_uid)
            rulesForwebsite_uid.push(option);
        }); 
      }
      this.rules=rulesForwebsite_uid;
      this.defaultrules=rulesForwebsite_uid;
      let index=0;
      for(let i in  this.rules){
        this.rules[i].id=index;
        index++;
      }
      this.isPageLoaded=true; 
     
    } 
    // Fetching Department and Operator DropDown Data
    dptoptforwebiste=(uid="") =>{    
      axios({
        method: 'POST', 
        url: this.appStore.url+'dptoptforwebiste.json',
        headers: { 'content-type': 'application/json' },
        data:{"website_uid": this.appStore.selectedWebisiteUid,"department_uid":uid},
      }).then(result=> {  
        if(result.data.length==1){
          if(result.data.logoutstatus==1){
          this.appStore.logout();
          }
        }
              let depts = result.data.departmentsmodel;             
              let alldept = {"label":"All Departments","value":"","department_uid":""};
              depts.splice(0, 0,alldept);                 
              let index =1;
              for (let x in depts)
              { 
                if(x>0) {
                depts[x].label = depts[x].name;
                depts[x].value= depts[x].department_uid;       
                depts[x].department_uid =  depts[x].department_uid;
                index++;
                }      
              }        
              this.chat_redirect_dept=[];
              this.chat_redirect_dept=depts;
              let opt = result.data.usersmodel;  
              let allopt = {"label":"All Operators","value":"","user_uid":""};
              opt.splice(0, 0,allopt);                 
              let ind =1;
              for (let x in opt)
              { 
                if(x>0) {
                  opt[x].label = opt[x].nick_name;
                  opt[x].value= opt[x].user_uid;       
                  opt[x].user_uid = opt[x].user_uid;
                  ind++;
                }      
              }         
              this.chat_redirect_opt=opt;
              this.appStore.global_errorcount=0;
            }).catch((error) => {
            //console.log(error);
              //  this.appStore.logout();   
              console.log(error);
              console.log("error count :"+this.appStore.global_errorcount);
              this.appStore.global_errorcount=this.appStore.global_errorcount+1;
              if(this.appStore.global_errorcount>20){
              // this.appstore.logout();
              }
                  }); 
    }
    setDepartmentDropdown(selectedOption){  
      // onchange departments dropdown operator data changing
      this.dptoptforwebiste(selectedOption.department_uid);   
    }

     setLanguage(selectedOption){
         this.selectedLanguage = selectedOption;
        // console.log(this.selectedLanguage);
         this.virtualAgentRules();
         this.getScriptitem();
     }
    
     addRuleData() { 
       this.setToDefault();
       this.newRuleObj = {
        "enabled":1,
        "time_passed":30,
        "will_open":this.ruleOpenFor[0].value,
        "conditions":[],
        "conditions_combine":"||",
        "message_action":"default",
        "new_message":"",
        "website_uid":this.appStore.selectedWebisiteUid,
        "related_uid":"",
        "department_uid":this.chat_redirect_dept[0].value,
        "op_uid":this.chat_redirect_opt[0].value,
        "websites":[],
        "selected_will_open" : this.ruleOpenFor[0],
        "selected_condtions_combine" :  this.conditions_combine[0],
        "selected_invitation_msg": this.invitation_msgs[0],
        "selected_redirect_dept" : this.chat_redirect_dept[0],
        "selected_redirect_opt" : this.chat_redirect_opt[0], 
       }
       this.addRule = true;
       this.editRule = false;
     }
     addVaRuleData() { 
      this.setToDefault();
      this.newRuleObj = {
       "enabled":1,
       "time_passed":30,
       "will_open":this.ruleOpenFor[0].value,
       "conditions":[],
       "conditions_combine":"||",
       "message_action":"default",
       "new_message":"",
       "website_uid":this.appStore.selectedWebisiteUid,
       "related_uid":this.selectedScriptItem,
       "department_uid":"",
       "op_uid":"",
       "websites":[],
       "selected_will_open" : this.ruleOpenFor[0],
        "selected_condtions_combine" :  this.conditions_combine[0],
        "selected_invitation_msg": this.invitation_msgs[0],
        "selected_redirect_dept" : this.chat_redirect_dept[0],
        "selected_redirect_opt" : this.chat_redirect_opt[0],  
      }
      this.addRule = true;
      this.editRule = false;
    }

     editRuleData(rule){   
       this.setToDefault(); 
       this.addRule = false;
       this.editRule = true; 
       this.selectedRuleToEdit = rule;
     
       this.selectedRuleToEdit.selected_will_open= this.setDropdownForEdit(this.ruleOpenFor,this.selectedRuleToEdit.will_open,"will_open")
       this.selectedRuleToEdit.selected_redirect_dept =this.setDropdownForEdit(this.chat_redirect_dept,this.selectedRuleToEdit.department_uid,"department_uid");
       this.selectedRuleToEdit.selected_redirect_opt =this.setDropdownForEdit(this.chat_redirect_opt,this.selectedRuleToEdit.op_uid,"user_uid");
       this.selectedRuleToEdit.related_uid=this.setscrpitDropdownForEdit(this.responseData,this.selectedRuleToEdit.related_uid,"related_uid");
     }
     
     setDropdownForEdit(options,checkfor,type){
      if (checkfor === "") {
        return options[0];
      }
        let y;
        let x = options.filter(function (option) {
        if(checkfor===option.value)
            y = option;
      });        
      return y;
       
     }


     setscrpitDropdownForEdit(options,checkfor,type){
      let y; 
        let a = options.basicActions.chatTypes.filter(function (option) {
          if(checkfor===option.related_uid)
              y = option;
        }); 
        let b = options.customResponse.chatTypes.filter(function (option) {
          if(checkfor===option.related_uid)
              y = option;
        });  
        let c = options.relatedLinks.chatTypes.filter(function (option) {
          if(checkfor===option.related_uid)
              y = option;
        });  
           
    return y;
     
   }
// Adding and Editing New Inviation Rule Data Based On Condtion
     addRuleToParent(rule,chatInvitation){ 
       
       let self=this;  
       let notempty=0;     
         //Will open for time field required validating  is empty or not
          if(rule.time_passed != ""){
            if(rule.message_action === 'custom'){
              if(rule.new_message.trim()==""){
                  this.custom_message_isvalid=false;
                return;
              }else{
                this.custom_message_isvalid=true;
              }
            }              
            if(!chatInvitation){ 
              if(this.addRule){
                let idchanged;  
                let langchanged;   
                let rulesForwebsite_uid=[];   
                 //adding  new rules data 
                 let changednewrules=[];
                 changednewrules = {
                   "enabled":rule.enabled,
                   "time_passed":rule.time_passed,
                   "will_open":rule.will_open,
                   "conditions":rule.conditions,
                   "conditions_combine":rule.conditions_combine,
                   "message_action":rule.message_action,
                   "new_message":rule.new_message.replace("\"", '\\"'),
                   "website_uid":rule.website_uid,
                   "related_uid":rule.related_uid,
                   "department_uid":rule.department_uid,
                   "op_uid":"",
                   "websites":[],
                   }   
                   let matchinglanguage=0;
                let x = self.appStore.userInfo.virtualAgentRules.filter(function (option) {                  
                  // accountid and select websiteid==> if  selected language record is  exists then Pushibg  as new rule to existing rules                  
                  if(self.appStore.userInfo.accountsmodel.account_uid == option.accountUid  && self.selectedLanguage.value==option.lang){ 
                    let vaRules=JSON.parse(option.invitationRules.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));         
                    //filtering invitation rules based on websiteid
                    let ii=0;
                          let x = vaRules.filter(function (optionrules) { 
                          if(self.appStore.selectedWebisiteUid===optionrules.website_uid){
                             //one time loop executing if condition true   
                            matchinglanguage=1;  
                            idchanged=option.id;
                            langchanged=option.lang;
                            rulesForwebsite_uid.push(optionrules); 
                          } 
                        });
                      }
              });     
              // accountid and select websiteid==> if  selected language record is not exists then creating as new record
               if(matchinglanguage==0){
                 let  ids=0;
                 let langs=self.selectedLanguage.value;
                 langchanged=langs;
                 idchanged=ids;
                }    
                rulesForwebsite_uid.push(changednewrules);   
                let addval=0;
                if(this.conditionToEdit==undefined){
                notempty=1; 
                //update rules method 
                this.saveVAinvitationrules(idchanged,langchanged,rulesForwebsite_uid,rule,addval);
                }
              }else{   
                    let changednewrules=[];
                    let idchanged;  
                    let langchanged;    
                    let self=this;
                    //fetching all rules data except selected website 
                    let x = self.appStore.userInfo.virtualAgentRules.filter(function (option) {                     
                      // accountid and select websiteid==> if  selected language record is  exists then Pushibg  as new rule to existing rules                  
                      if(self.appStore.userInfo.accountsmodel.account_uid == option.accountUid  && self.selectedLanguage.value==option.lang){ 
                        let vaRules=JSON.parse(option.invitationRules.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));         
                        //filtering  invitation rules  language id("id required for updating") based on websiteid
                        let ii=0;
                              let x = vaRules.filter(function (optionrules) { 
                              if(self.appStore.selectedWebisiteUid===optionrules.website_uid){  
                                idchanged=option.id;
                                langchanged=option.lang; 
                              } 
                            });
                          }
                  }); 

                  //Setting selected website   rules data 
                    let ruleschanged = this.rules.slice(0); 
                   
                    for(let x in ruleschanged){
                      var relatedids="";    
                      if(ruleschanged[x].related_uid !="" && ruleschanged[x].related_uid !=undefined){                  
                          if(ruleschanged[x].related_uid.id){ 
                            relatedids=ruleschanged[x].related_uid.related_uid; 
                          }else{ 
                            relatedids=ruleschanged[x].related_uid; 
                          } 
                      }else{relatedids=ruleschanged[x].related_uid;} 
                      let editruleschanged ={};
                        editruleschanged = {
                          "enabled":ruleschanged[x].enabled,
                          "time_passed":ruleschanged[x].time_passed,
                          "will_open":ruleschanged[x].will_open,
                         // "will_open":ruleschanged[x].selected_will_open.value,
                          "conditions":ruleschanged[x].conditions,
                          "conditions_combine":ruleschanged[x].conditions_combine,
                          "message_action":ruleschanged[x].message_action,
                          "new_message":ruleschanged[x].new_message.replace("\"", '\\"'),
                          "website_uid":ruleschanged[x].website_uid,
                          "related_uid": relatedids,
                          "department_uid":ruleschanged[x].department_uid,
                          "op_uid":"",
                          "websites":[]
                        }   
                        changednewrules.push(editruleschanged); 
                    }  
                    //console.log(changednewrules);
                  let editval=1;  
                    if(this.conditionToEdit==undefined){
                    notempty=1; 
                    //update rules method 
                    this.saveVAinvitationrules(idchanged,langchanged,changednewrules,this.rules,editval);
                  }
                }
                  if(notempty==1)
                  this.setToDefault();
                
            }else{      
              if(this.addRule){ 
                let ruleschanged=[];
                //fetching all rules data
                if(this.appStore.userInfo.accountsmodel.rules_invitation.trim().length !=0){
                    let rulesForwebsite_uid=JSON.parse(this.appStore.userInfo.accountsmodel.rules_invitation); 
                    if(rulesForwebsite_uid.length !=0)
                    ruleschanged=rulesForwebsite_uid; 
                }
                //adding  new rules data
                      this.changednewrules = {
                      "enabled":rule.enabled,
                      "time_passed":rule.time_passed,
                      "will_open":rule.will_open,
                      "conditions":rule.conditions,
                      "conditions_combine":rule.conditions_combine,
                      "message_action":rule.message_action,
                      "new_message":rule.new_message,
                      "website_uid":rule.website_uid,
                      "related_uid":rule.related_uid,
                      "department_uid":rule.department_uid,
                      "op_uid":rule.op_uid,
                      "websites":rule.websites
                      }  
                      // ruleschanged.push(this.rules.slice(0));
                      ruleschanged.push(this.changednewrules);  
                      let addval=0;
                      if(this.conditionToEdit==undefined){
                      notempty=1; 
                      //update rules method
                      this.saveinvitationrules(ruleschanged,rule,addval);
                      }
                      else{
                        this.validate=true;
                        window.scrollTo(0,1700);
                      }   
                }else{
                  let changednewrules=[]; 
                  let rulesForwebsite_uid=[];
                  let self=this;
                  //fetching all rules data except selected website
                  if(this.appStore.userInfo.accountsmodel.rules_invitation.trim().length !=0){
                      let x = JSON.parse(this.appStore.userInfo.accountsmodel.rules_invitation).filter(function (option) {
                      if(self.appStore.selectedWebisiteUid!==option.website_uid)
                        rulesForwebsite_uid.push(option);
                    }); 
                 }
                if(rulesForwebsite_uid.length !=0)
                changednewrules=rulesForwebsite_uid;
 
                //Setting selected website   rules data 
                  let ruleschanged = this.rules.slice(0); 
                 
                  for(let x in ruleschanged){
                    let editruleschanged ={};
                      editruleschanged = {
                        "enabled":ruleschanged[x].enabled,
                        "time_passed":ruleschanged[x].time_passed,
                        "will_open":ruleschanged[x].will_open,
                        "conditions":ruleschanged[x].conditions,
                        "conditions_combine":ruleschanged[x].conditions_combine,
                        "message_action":ruleschanged[x].message_action,
                        "new_message":ruleschanged[x].new_message,
                        "website_uid":ruleschanged[x].website_uid,
                        "related_uid":ruleschanged[x].related_uid,
                        "department_uid":ruleschanged[x].department_uid,
                        "op_uid":ruleschanged[x].op_uid,
                        "websites":ruleschanged[x].websites
                      }   
                      changednewrules.push(editruleschanged); 
                  }  
                let editval=1; 
                //condition field  validating  is empty or not 
                  if(this.conditionToEdit==undefined){
                  notempty=1;
                  //update rules method
                    this.saveinvitationrules(changednewrules,this.rules,editval);
                  }
                  else{
                    this.validate=true;
                    window.scrollTo(0,1700);
                  }         
                }
                if(notempty==1)
              this.setToDefault();
            }
          }else{
            window.scrollTo(0,1700);
          }
     } 
     //Sorting Inviation Rules
     savesortablerules(rulessorted){ 
      let sortedrules=[];  
      let rulesForwebsite_uid=[];
      let self=this; 
      //fetching all Rules data except selected website 
      if(this.appStore.userInfo.accountsmodel.rules_invitation.trim().length !=0){
          let x = JSON.parse(this.appStore.userInfo.accountsmodel.rules_invitation).filter(function (option) { 
          if(self.appStore.selectedWebisiteUid!==option.website_uid){
            rulesForwebsite_uid.push(option); 
          }
        }); 
      }
    if(rulesForwebsite_uid.length !=0)
       sortedrules=rulesForwebsite_uid;
       
       //setting selected website rules data
       let ruleschanged = rulessorted; 
       for(let x in ruleschanged){
         let editruleschanged ={};
         editruleschanged = {
          "enabled":ruleschanged[x].enabled,
          "time_passed":ruleschanged[x].time_passed,
          "will_open":ruleschanged[x].will_open,
          "conditions":ruleschanged[x].conditions,
          "conditions_combine":ruleschanged[x].conditions_combine,
          "message_action":ruleschanged[x].message_action,
          "new_message":ruleschanged[x].new_message,
          "website_uid":ruleschanged[x].website_uid,
          "related_uid":ruleschanged[x].related_uid,
          "department_uid":ruleschanged[x].department_uid,
          "op_uid":ruleschanged[x].op_uid,
          "websites":ruleschanged[x].websites
       }  
       sortedrules.push(editruleschanged); 
     }   
  
       var sortable=3;         
       //update rules method   
            this.saveinvitationrules(sortedrules,sortedrules,sortable);         
     }
     //Common Update Menthod For Adding, Editing ,Sorting and Delete Invitation Rules
     //checkval ==>'0' for add,'1' for edit ,'2' for delete and '3' for Sort Invitation rlue
     saveinvitationrules(ruleschanged,changedrules,checkval){ 
       if(checkval!=3)
      this.isPageLoaded= false; 



          axios({
            method: 'POST', 
            url: this.appStore.url+'invitationRulesNew.json',
            headers: { 'content-type': 'application/json' },
            data:{"rules_invitation": JSON.stringify(ruleschanged)},
          }).then(result=> { 
            if(result.data.length==1){
              if(result.data.logoutstatus==1){
              this.appStore.logout();
              }
            }
                  if(result.data.querystatus == 1){
                    if(checkval==0){
                    this.rules.push(changedrules);
                    this.defaultrules.push(changedrules);
                    }
                    this.appStore.userInfo.accountsmodel=result.data; 
                  } else{ 
                    this.errormodel=true; 
                  } 
                  this.isPageLoaded= true;   
                  this.appStore.global_errorcount=0;           
            }).catch((error) => {
                 // console.log(error);    
                  // this.appStore.logout();
                  this.isPageLoaded= true;           
                    console.log(error);
                    console.log("error count :"+this.appStore.global_errorcount);
                    this.appStore.global_errorcount=this.appStore.global_errorcount+1;
                    if(this.appStore.global_errorcount>20){
                    // this.appStore.logout();
                    }
                      }); 
     }
     //Common Update Menthod For Adding, Editing ,Sorting and Delete virtualAgent Invitation Rules
     //checkval ==>'0' for add,'1' for edit ,'2' for delete and '3' for Sort virtualAgent Invitation rlue
     saveVAinvitationrules(idchanges,lang,ruleschanged,ruless,checkval){  
      //  if(checkval!=3)
      this.isPageLoaded= false;  

      axios({
        method: 'POST', 
        url: this.appStore.url+'vaInvitaionsRulesSave.json',
        headers: { 'content-type': 'application/json' },
        data:  {"id":JSON.stringify(idchanges),"invitationRules":JSON.stringify(ruleschanged),"lang":lang,"websiteUid":this.appStore.selectedWebisiteUid},
      }).then(result=> {  
            if(result.data.length==1){
              if(result.data[0].logoutstatus==1){
                this.appStore.logout();
              }
              }
              if(result.data[0].querystatus == 1){
                if(checkval==0){
                this.rules.push(ruless);
                this.defaultrules.push(ruless);
                let index=0;
                for(let i in  this.rules){
                  this.rules[i].id=index;
                  index++;
                }
                }
               this.appStore.userInfo.virtualAgentRules=result.data; 
              } else{ 
                this.errormodel=true; 
              }
              this.isPageLoaded= true;  
              this.appStore.global_errorcount=0;               
        }).catch((error) => {
             // console.log(error);    
              // this.appStore.logout();
              this.isPageLoaded= true;       
              console.log(error);
              console.log("error count :"+this.appStore.global_errorcount);
              this.appStore.global_errorcount=this.appStore.global_errorcount+1;
              if(this.appStore.global_errorcount>20){
              // this.appStore.logout();
              }
                  }); 
     }
     
     deleteVAinvitationrules(idchanges,lang,ruleschanged,ruless,checkval){  
      
      this.isPageLoaded= false;  
      axios({
        method: 'POST', 
        url: this.appStore.url+'vaInvitaionsRulesDelete.json',
        headers: { 'content-type': 'application/json' },
        data:  {"id":JSON.stringify(idchanges),"invitationRules":JSON.stringify(ruleschanged),"lang":lang},
      }).then(result=> {  
              if(result.data.length==1){
                if(result.data[0].logoutstatus==1){
                  this.appStore.logout();
                }
                }
              if(result.data[0].querystatus == 1){
                if(checkval==0){
                //this.rules.push(ruless);
                //this.defaultrules.push(ruless);
                this.rules=ruleschanged;
                this.defaultrules=ruleschanged;
                  let index=0;
                  for(let i in  this.rules){
                    this.rules[i].id=index;
                    index++;
                  }
                }
               this.appStore.userInfo.virtualAgentRules=result.data; 
              } else{ 
                this.errormodel=true; 
              }  
              this.isPageLoaded= true;  
              this.appstore.global_errorcount=0;              
        }).catch((error) => {
             // console.log(error);    
              // this.appStore.logout();
              this.isPageLoaded= true;       
              console.log(error);
              console.log("error count :"+this.appStore.global_errorcount);
              this.appStore.global_errorcount=this.appStore.global_errorcount+1;
              if(this.appStore.global_errorcount>20){
              // this.appStore.logout();
              }
                  }); 
     }
     cancelSaveResponse(chatInvitation){ 
       this.setToDefault(); 
       let rulesForwebsite_uid=[];
       let self=this;
       if(!chatInvitation){
        let x = self.appStore.userInfo.virtualAgentRules.filter(function (option) { 
          //filtering defualt English language data first time based on account id
          if(self.appStore.userInfo.accountsmodel.account_uid == option.accountUid  && self.selectedLanguage.value==option.lang){
            let vaRules=JSON.parse(option.invitationRules.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));   
            //filtering invitation rules based on websiteid
            let x = vaRules.filter(function (optionrules) { 
              if(self.appStore.selectedWebisiteUid===optionrules.website_uid){ 
                rulesForwebsite_uid.push(optionrules);
              }
            }); 
          }
      });  
      }else{
        //filtering selected website rules data and refresh the table content
        if(this.appStore.userInfo.accountsmodel.rules_invitation.trim().length !=0){
          let x = JSON.parse(this.appStore.userInfo.accountsmodel.rules_invitation).filter(function (option) {
            if(self.appStore.selectedWebisiteUid===option.website_uid)
            rulesForwebsite_uid.push(option);
              }); 
        }
      }
       this.defaultrules= rulesForwebsite_uid;
       this.rules=this.defaultrules;
       let index=0;
        for(let i in  this.rules){
          this.rules[i].id=index;
          index++;
        }
       
     }

     setToDefault(){
       this.addRule = false;
       this.editRule = false;
       this.docHeight = 'auto';
       this.cancelRule = true; 
       this.newRuleObj = undefined;
       this.selectedRuleToEdit = undefined;
       this.conditionToEdit = undefined;

     }

     editCondition(condition,index) {   
       this.editingCondtion = condition ? true : false;
       var initCondition = {"mask":"","type":"url","conform":"contains","id":0};
       this.conditionIndex = index;
       this.conditionToEdit = condition ? JSON.parse(JSON.stringify(condition)): initCondition;  
     }

     cancelSaveCondition(){
       this.conditionToEdit = undefined;
     }
     addCondition(condition) { 
       if(condition.mask !== '') {
         if(this.addRule) {
         
           condition.id = this.newRuleObj.conditions ? this.newRuleObj.conditions.length : 1; 
           if(!this.editingCondtion){
             this.newRuleObj.conditions.push(condition);
           }
           else if(this.conditionIndex !== undefined) {
              this.newRuleObj.conditions[this.conditionIndex] = condition;
           }
         }
         else { 
           condition.id = this.selectedRuleToEdit.conditions ? this.selectedRuleToEdit.conditions.length : 1; 
           if(!this.editingCondtion) {
             this.selectedRuleToEdit.conditions.push(condition);
           }
           else if(this.conditionIndex !== undefined){
             this.selectedRuleToEdit.conditions[this.conditionIndex] = condition;
           }
         }
       
       this.conditionToEdit = undefined;
      }
      else{
        this.validate=true; 
      }
     }


    scrollToPosition(pos){ 
      setTimeout(() => {
        window.scrollTo(0, pos); 
      },0);
    }

    deleteRule(rule){
       
      this.ruleToDelete = rule;
      this.confirm = true;
    }

    confirmDeleteRule(chatinvitaion){  
      var self = this;
      this.rules.map(function(rule, ruleIndex) {
        if(rule.id === self.ruleToDelete.id) {
          self.rules.splice(ruleIndex,1);
        }
      }); 
      if(!chatinvitaion){ 
              let changednewrules=[];
              let idchanged;  
              let langchanged;    
              let self=this;
              //fetching all rules data except selected website 
              let x = self.appStore.userInfo.virtualAgentRules.filter(function (option) {                     
                // accountid and select websiteid==> if  selected language record is  exists then Pushibg  as new rule to existing rules                  
                if(self.appStore.userInfo.accountsmodel.account_uid == option.accountUid  && self.selectedLanguage.value==option.lang){ 
                  let vaRules=JSON.parse(option.invitationRules.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t"));         
                  //filtering  invitation rules  language id("id required for updating") based on websiteid
                  let ii=0;
                        let x = vaRules.filter(function (optionrules) { 
                        if(self.appStore.selectedWebisiteUid===optionrules.website_uid){  
                          idchanged=option.id;
                          langchanged=option.lang; 
                        } 
                      });
                    }
            }); 

            //Setting selected website   rules data 
              let ruleschanged = this.rules.slice(0);  
              for(let x in ruleschanged){
                let editruleschanged ={};
                  editruleschanged = {
                    "enabled":ruleschanged[x].enabled,
                    "time_passed":ruleschanged[x].time_passed,
                    "will_open":ruleschanged[x].will_open,
                    "conditions":ruleschanged[x].conditions,
                    "conditions_combine":ruleschanged[x].conditions_combine,
                    "message_action":ruleschanged[x].message_action,
                    "new_message":ruleschanged[x].new_message,
                    "website_uid":ruleschanged[x].website_uid,
                    "related_uid":ruleschanged[x].related_uid,
                    "department_uid":ruleschanged[x].department_uid,
                    "op_uid":"",
                    "websites":[]
                  }   
                  changednewrules.push(editruleschanged); 
              }  
              let deleteval=2; 
              //update rules method 
              this.deleteVAinvitationrules(idchanged,langchanged,changednewrules,this.rules,deleteval);
           
      }else{
            let rulesForwebsite_uid=[];
            let changednewrules=[]; 
            //fetching all Rules data except selected website 
            if(this.appStore.userInfo.accountsmodel.rules_invitation.trim().length !=0) {
             let x = JSON.parse(this.appStore.userInfo.accountsmodel.rules_invitation).filter(function (option) { 
             if(self.appStore.selectedWebisiteUid!==option.website_uid){
              rulesForwebsite_uid.push(option); 
             }
            }); 
          }
          if(rulesForwebsite_uid.length !=0)
          changednewrules=rulesForwebsite_uid;

          //Setting selected website   rules data 
          let ruleschanged = this.rules.slice(0); 
          for(let x in ruleschanged){
            let deleteruleschanged ={};
            deleteruleschanged = {
                "enabled":ruleschanged[x].enabled,
                "time_passed":ruleschanged[x].time_passed,
                "will_open":ruleschanged[x].will_open,
                "conditions":ruleschanged[x].conditions,
                "conditions_combine":ruleschanged[x].conditions_combine,
                "message_action":ruleschanged[x].message_action,
                "new_message":ruleschanged[x].new_message,
                "website_uid":ruleschanged[x].website_uid,
                "related_uid":ruleschanged[x].related_uid,
                "department_uid":ruleschanged[x].department_uid,
                "op_uid":ruleschanged[x].op_uid,
                "websites":ruleschanged[x].websites
              }   
              changednewrules.push(deleteruleschanged); 
          }   
            // console.log(JSON.stringify(this.rules));  
            let deleteval=2;
            //update rules method
            this.saveinvitationrules(changednewrules,changednewrules,deleteval);      
        }
      this.ruleToDelete = undefined;
      this.confirm = false;
    }

    deleteCondition(condition){
      this.conditionToDelete = condition;
      this.confirmDelCond = true;
    }

    confirmDeleteCondition(){
      var self = this;
      if(this.addRule) {
         this.newRuleObj.conditions.map(function(condition, conditionIndex) {
            if(condition.id === self.conditionToDelete.id) {
              self.newRuleObj.conditions.splice(conditionIndex,1);
            }
          });
      }
      else{
        this.selectedRuleToEdit.conditions.map(function(condition, conditionIndex) {
          if(condition.id === self.conditionToDelete.id) {
            self.selectedRuleToEdit.conditions.splice(conditionIndex,1);
          }
        });
      }
      this.conditionToDelete = undefined;
      this.confirmDelCond = false;
    }

    getOpforDept= (appstore,ruleObj,selectedOption) =>{

      ruleObj.department_uid = selectedOption.department_uid;
      ruleObj.selected_redirect_dept = selectedOption;
     // invitationRuleStore.setDepartmentDropdown(selectedOption)
     ruleObj.selected_redirect_opt = this.chat_redirect_opt[0];

      if(ruleObj.selected_redirect_dept.department_uid == ""){
        this.chat_redirect_opt = appstore.deptOps.allOps;
        return;
      }

      const selectedOptions = {
        department_uid : selectedOption.department_uid,
        website_uid:"" }
      axios({
        method: 'POST',
        url: appstore.url + "getDropdownDataforAdmin.json",
        headers: { 'content-type': 'application/json' },
        data: selectedOptions,
      }).then(result => {
        if(result.data.length==1){
          if(result.data.logoutstatus==1){
            appstore.logout();
          }
          }
        let opt = result.data.usersmodel;  
        let allopt = {"label":"All Operators","value":"","user_uid":""};
        opt.splice(0, 0,allopt);   
        
        for (let x in opt){ 
          if(x>0) {
            opt[x].label = opt[x].nick_name;
            opt[x].value= opt[x].user_uid;       
            opt[x].user_uid = opt[x].user_uid;
          }      
        }         
        this.chat_redirect_opt = opt;
        appstore.global_errorcount=0;
      }).catch((error) => {
        if (error.response) {
          // console.log(error)
          // appstore.logout();
          console.log(error);
            console.log("error count :"+appstore.global_errorcount);
            appstore.global_errorcount=appstore.global_errorcount+1;
            if(appstore.global_errorcount>20){
            // appstore.logout();
            }
        }
      });
    }
     

 }

 decorate(InvitationRulesStore, {
     selectedLanguage: observable,
     rules: observable,
     selectedRuleToEdit: observable,
     newRuleObj: observable,
     addRule: observable,
     editRule: observable,
     selectedResponseData: observable,
     conditionToEdit: observable,
     selectedUrl:observable,
     selectedConform: observable,
     editingCondtion: observable,
     confirm: observable,
     confirmDelCond: observable,
     docHeight: observable,
     pageisLoaded:observable,
     chat_redirect_opt:observable,
     errormodel:observable,
     defaultrules:observable,
     changednewrules:observable,
     cancelRule:observable, 
     isPageLoaded:observable,
     validate:observable,
     alloperators:observable,
     onfirstTabClick:observable,
     vARules:observable,
     selectedScriptItem:observable,
     custom_message_isvalid:observable 
    })
 export default InvitationRulesStore;
