import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MailComponent from './mailPopup/MailComponent';
import axios from 'axios';

const WidgetCodeComponent = observer(class WidgetCodeComponent extends Component {

	constructor (props) {
		super(props); 

		this.getassisdata();
			this.state={
				 message:'',
				 phpcode:'<script type="text/javascript"><!--\nvar LiveChatCustom = <?php echo json_encode(array(\n"name" => "Visitor known name",\n // ...\n)); ?>;\n--></script>',
				 steptour:this.props.appstore.tourcurrent_step
				}; 
    }
	componentWillMount(){

	}

	componentDidMount(){
		if(document.getElementById('code') !== null) {
			if(!this.props.appstore.helpTourStarted){
				//stop scroll in 5th step if tour is started 
				this.scrollToPosition();
			}
		} 
		this.props.store.codeCopied=false;
	}
	componentDidUpdate(prevProps, prevState) { 
		if (prevState.steptour !== this.props.appstore.tourcurrent_step) {	
			if(this.props.appstore.helpTourStarted && this.props.appstore.tourcurrent_step==6){
				let timer = setTimeout(() => {
					if(document.getElementById('code') !== null) {
						window.scrollTo(0, document.getElementById('code').offsetTop + 120);
					} 
					clearTimeout(timer);
				},500);
			}
			
			this.setState({steptour:this.props.appstore.tourcurrent_step});
		}
	  }


	scrollToPosition(){
	 	
		let timer = setTimeout(() => {
			if(document.getElementById('code') !== null) {
				window.scrollTo(0, document.getElementById('code').offsetTop + 150);
			} 
			clearTimeout(timer);
		},500);
	}
	getassisdata = () => {
        axios({
            method: 'POST',
            url: this.props.appstore.url + 'getassistdata.json',
            headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
            data: { "uid": this.props.store.uid }
        }).then(result => { 

		   if(result.data ==null || result.data ==="")
		   return;

          let config=JSON.parse(result.data.clientConfigJson);
          let mode="";
            if(result.data.modesString==="widget,popupInvitation"){ 
                  mode='[\n       "widget",\n       "popupInvitation" \n      ]'; 
            }
            if(result.data.modesString==="widget"){ 
                mode='[\n       "widget"\n      ]'; 
            }
            if(result.data.modesString==="icon,popupInvitation"){
                mode='[\n       "icon",\n       "popupInvitation" \n      ]'; 
            }
            if(result.data.modesString==="toolbar"){
                mode='[\n       "toolbar"\n      ]';                 
            }
            if(result.data.modesString==="widget,icon,popupInvitation"){
                mode='[\n       "widget",\n       "icon",\n       "popupInvitation" \n      ]';               
            }
            if(result.data.modesString==="widget,icon"){
                mode='[\n       "widget",\n       "icon" \n      ]';               
            }
            let  virtual=result.data.enableVirtualAgent==1 ? true:false;
            let websiteuid = '\n      "websiteUid": "'+result.data.websiteUid+'",';
            let departmentuid = result.data.departmentUid!="" ? '\n      "departmentUid": "'+result.data.departmentUid+'",' :"";
            let operatoruid = result.data.operatorUid!=""? '\n      "operatorUid": "'+result.data.operatorUid+'",':"";
            let advanced = '<script type="text/javascript"><!--\n var LiveChatCustom ={\n    "name": "Visitor known name",\n    "email": "email@example.com",\n    "general": {\n      "accountUid": "'+result.data.accountUid+'",'+websiteuid+departmentuid+operatoruid+'\n      "language": "'+result.data.language+'",\n     "enableVirtualAgent": '+virtual+',\n     "modes": '+mode+'\n   },\n    '+JSON.stringify(config, null, 3).slice(1,-1)+'\n } \n --></script>';
                     
            this.setState({ message: advanced });
			this.props.appstore.global_errorcount=0;
		})
		.catch(err => {
			// this.props.appStore.logout();
			console.log(err);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		})

    }


	render(){
		const { appstore,
				store } = this.props; 
				//console.log(store);
		return (
			<div id="code" className="codeWrapper col-12">
				<div className="row copy_code_tour">
					<div className="col-2 col-sm-3">
		<p>{appstore.getLanguageTrans("Code")}{appstore.tourcurrent_step}</p>
					</div>
					<div className="col-10 col-sm-9 pr-0 getcode_inv_tour">
						<textarea name="ipBlackList" rows="7" value={store.widgetCode}/>
						<div className="row m-0">
							<div className="col-12 formBtns">
								<div className="pullLeft">
																
								    <CopyToClipboard text={store.widgetCode} onCopy={() => store.codeCopied= true}>
									   <Button className="btn_clr" variant="light">
											<p style={{color:appstore.selectedColor.color}}>{store.codeCopied ? appstore.getLanguageTrans("Copied") : appstore.getLanguageTrans("Copy")}</p>
									   </Button>
									</CopyToClipboard>
									
								      <Button className="btn_clr" variant="light" onClick={() => store.sendCode = true}>
								    	<p style={{color:appstore.selectedColor.color}}>{appstore.getLanguageTrans("Send to developer")}</p>
								      </Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row advancedSection">
					<div className="col-3 cursor-pointer advancedOption text-uppercase dropdown-toggle text-left collapsed" data-toggle="collapse" 
												 data-target="#advanced" aria-expanded={false} aria-controls="advanced">
													<p class="col-2"></p><p className="col-10" style={{color:appstore.selectedColor.color}}>{appstore.getLanguageTrans("Advanced")}</p>
					</div>
					<div id="advanced" className="collapse col-12 p-0">
						<p>{appstore.getLanguageTrans("Custom parameters can be passed to the monitor using the following JS code placed before the code received at Code tab:")}</p>
						<textarea className="getcodetextarea" name="message" rows="9" readOnly value={this.state.message}  />
						<p>{appstore.getLanguageTrans("With PHP arrays it could look like following:")}</p>
						<textarea name="ipBlackList" rows="5" value={this.state.phpcode} />
					</div>
				</div>
				{/* getcode={appStore.generateAssistCode(uid)} */}
				{
					store.sendCode && 
						<MailComponent store={store} appStore={appstore} show={store.sendCode} getcode={appstore.generateAssistCode(store.uid)}  />
				}
			</div>
		)
	}
})
export default WidgetCodeComponent;
