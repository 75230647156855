import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';
import OperatorFileComponent from './OperatorFileComponent';
import imageURLs from '../../../../sharedFiles/ImagesURLs';

const SupervisorChatComponent = observer(class SupervisorChatComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }


	render(){
		const { appstore,
				chatComponentStore,
				chatData,
				name,
				time,
				visitor,filePath,talkSessionUid,file_size ,originalFileName,fullData} = this.props;
		var self = this;

		

			if(filePath != "" && filePath != null){
				
				return (
						<OperatorFileComponent fileData={chatComponentStore.fileData} 
						fileBysupervisor={{backgroundColor: "#f1ea4f"} }	
						appstore={appstore} 
						fullData={fullData}
							chatComponentStore = {chatComponentStore} talkSessionUid={talkSessionUid}
							name={name} time={time} filePath={filePath} file_Size={file_size} originalFileName={originalFileName} /> 
				
						)
			}

			else{
					return (
					<div className="chatSection supervisorChat col-12 p-0">
						<p className="chatBy">{name} <span>{time} </span>
						
						{ visitor.acceptedBy !== "supervisor" &&
							<React.Fragment>
								<img src={imageURLs.visibilityIcon} className="img-fluid" />
								<span> Only operators can see this message</span>
							</React.Fragment>
							}
						</p>
						<div className="chatText float-left">
							<p className="response" dangerouslySetInnerHTML={{__html: chatData}}>
							</p>
						</div>
					</div>
					
				)
	 }
	}
})
export default SupervisorChatComponent;
