import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import axios from 'axios';

const MailComponent = observer(class MailComponent extends Component {

	constructor (props) {
        super(props);
        this.sendTodev=this.sendTodev.bind(this);
        this.state={
           email:'',
           message:'Hello, \n\n Please add this code to my website, best to place just before the </body> tag.\n\n'+this.props.getcode+'\n\nThis is for place live chat on the website\n\nThanks!',
		};
		//console.log();

       
         this.updateState=this.updateState.bind(this);
         //this.accountUid = this.props.appStore.Config.responses[0].Op.user_uid;
        // this.userUid = this.props.appStore.Config.responses[0].Op.accountUid;
        // this.version=this.props.appStore.version;
    }
    
	componentWillMount(){
	}

	handleChange(event) {
		//console.log(event.target.value);
		this.updateState(event.target.name,event.target.value);
	}

	updateState(key,value){
     this.setState({[key]:[value]});
	}
	checkvalidemail=(email)=>{
		var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (email == "") { 
			this.props.store.getcodeerror = "Please enter email id";
			return false;
        }
        else if (reg.test(email) == false) { 
			this.props.store.getcodeerror = "Please enter valid email id";
			return false;
        } else {
            this.props.store.getcodeerror = "";
           return true;
        }
	}
  sendTodev(){ 
	  if (this.state.message===""){
		this.props.store.getcodeerror="required feild*";
	  }else{
		let validemail=this.checkvalidemail(this.state.email);
		// alert(validemail);
		if(!validemail)
		return;
 
		let to = this.state.email;
		let text=this.state.message;
		let objects={
			to_mail:to.toString(),
			message:text
		}
		axios({
		method: 'post',
		url: this.props.appStore.url+'sendcodetodevelper.json',
		headers: { 'content-type': 'application/json','charset':'utf-8' },
		data: objects,
		
	}).then(result => {
			//console.log('send Code response*********');
			//console.log(result);
			if(result.status==200)
				alert('email sent succesfsfully');
			else
				alert('email failed');
				this.props.appStore.global_errorcount=0;
		})
		.catch(err=> {
			// this.props.appStore.logout();
			console.log(err);
				console.log("error count :"+this.props.appStore.global_errorcount);
				this.props.appStore.global_errorcount=this.props.appStore.global_errorcount+1;
				if(this.props.appStore.global_errorcount>20){
				// this.props.appStore.logout();
				}
		})
		this.props.store.sendCode = false
	}
}
	render(){
		const { store,
				appStore,
				show } = this.props;
		return (
			<Modal backdrop='static' className={"mailer " + (appStore ? appStore.selectedColor.value : '')} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => store.sendCode = false}>
				  <Modal.Header closeButton>
				    <Modal.Title>{appStore.getLanguageTrans("Send code to developer")}</Modal.Title>
				    </Modal.Header>
				    <form>
						<div className="row">
								<Modal.Body>
									<div className="col-12">
										<Form.Group as={Row} controlId="formGroupTitle">
										    <Form.Label column sm="2">{appStore.getLanguageTrans("Email")}</Form.Label>
										    <Col sm="10 pr-0">
										    	<Form.Control type="text" placeholder={appStore.getLanguageTrans("Email")} 
										    					name="email"
										    					className="col-11 pr-0" value={this.state.email} onChange={(e) => this.handleChange(e)} />
										    <span className="error-block" style={{color:"red"}}>{appStore.getLanguageTrans(store.getcodeerror)}</span>
											</Col> 
								  		</Form.Group>
										  
								  		<Form.Group as={Row} controlId="formGroupTitle">
										    <Form.Label column sm="2">{appStore.getLanguageTrans("Message")}</Form.Label>
										    <Col sm="12 pr-0">
										    	<textarea className="getcodetextarea" name="message" rows="10" value={this.state.message} onChange={(e) => this.handleChange(e)}/>
										    </Col>
								  		</Form.Group>
								  	</div>
						  		</Modal.Body>

						</div>
						<Modal.Footer>
						    <Button className="btn_cancel" variant="secondary" onClick={() => store.sendCode = false}>{appStore.getLanguageTrans("Cancel")}</Button>
						    <Button className="btn_save" variant="primary" onClick={() => {this.sendTodev();}}>{appStore.getLanguageTrans("Send")}</Button>
						</Modal.Footer>
					</form>

				  
				</Modal>
		)
	}
})
export default MailComponent;
