import React, {Component } from 'react';
import { observer } from 'mobx-react';
import ToggleButton from 'react-bootstrap/ToggleButton'

import imageURLs from '../../sharedFiles/ImagesURLs';

const CheckBoxComponent = observer(class CheckBoxComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}

  	buttonClicked(){ 
  		if(this.props.store && this.props.store.checkParentClick){
  			this.props.store.preventParentClick();
  		}
  	}


	render(){
		const { store,
			   radioValue } = this.props;
		return (
			<ToggleButton
		        type="checkbox"
		        value={radioValue}
		        checked={radioValue}
		        onChange={() => this.props.callBack()}
		        onClick={() => this.buttonClicked()}>
		      <div className={radioValue ? "btnIcon active" : "btnIcon"}></div>
		      <p className={radioValue ? "btnText text-uppercase active" : "btnText text-uppercase"}>{radioValue ? 'On' :'Off'}</p>
		      </ToggleButton>
		)
	}
})
export default CheckBoxComponent;
