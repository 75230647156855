import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink } from "react-csv";


const onlineTimeCSV = observer(class onlineTimeCSV extends Component {

    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    invexport(data) {
        let exportdata = []
        let chat_Date=0
        let prev_count=0
        if(data == null || !data){
          return exportdata;
        }
        for (let j = 0; j < data.length; j++) {
          if (data[j].type === "ops_time") {

            if (chat_Date=== data[j].quarter_date) {
              prev_count= Number(prev_count) + Number(data[j].time);
             exportdata[exportdata.length-1].Chattime = prev_count + " s";
             exportdata[exportdata.length-1].Chattimesec = this.secondsToTime(prev_count);
           }
           else{
            data[j].Date = data[j].quarter_date
            data[j].Chattime = data[j].time;
            data[j].Chattimesec = this.secondsToTime(data[j].time);
            prev_count=Number(data[j].time)
            chat_Date=data[j].quarter_date
            exportdata.push(data[j])
           }
          }
         
        }  
        // for(let i in exportdata){
        //   exportdata[i].Chattime=this.secondsToTime(exportdata[i].Chattime);
        // }
        return exportdata;
      }
      secondsToTime(secs) {
        var hours = Math.floor(secs / (60 * 60));
    
        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
    
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
    
        if (hours == 0 && minutes == 0) {
          return seconds + "s";
        }
        if (hours == 0) {
          return minutes + "m " + seconds + "s";
        }
        else {
          return hours + "h " + minutes + "m " + seconds + "s";
        }
      }

    render() {

        let headers = [
            { label: "Date", key: "Date" },
            { label: "Online Time(sec)", key: "Chattime" },
            { label: "Online Time", key: "Chattimesec" },
        ];

        let exportdata = this.invexport(this.props.data);
        const {appStore}=this.props;
        return (

            <CSVLink data={exportdata} headers={headers} target="_blank" filename={"Online_Time_"+this.props.dateselected+".csv"} >
                {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
        );
    }


});

export default onlineTimeCSV;
