import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button, Card } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import WarningComponent from '../../../../sharedComponents/confirmationPopup/WarningComponent';
import imageURLs from '../../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import LanguageTextStore from './LanguageTextStore';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const LanguageTextComponent = observer(class LanguageTextComponent extends Component {

	constructor(props) {
		super(props);
		this.languageTextStore = new LanguageTextStore();
		this.updateSearch = this.updateSearch.bind(this);
		this.state = {
			search: "",
		}
	}

	componentDidMount() {
		this.props.appstore.menuChanged = window.location.pathname;
		this.languageTextStore.pageisLoaded = false;
		this.languageTextStore.lanDropDownOptions = this.props.appstore.languages;
		this.languageTextStore.selectedLanguage = this.languageTextStore.lanDropDownOptions[0];
		this.languageTextStore.setcustomLang(this.languageTextStore.selectedLanguage.value, this.props.appstore.userInfo.allcustomLanguage);
		//clone object and use when user clicks on cancel
		this.languageTextStore.clonedBaseLabels = JSON.parse(JSON.stringify(this.languageTextStore.baseLabels));
		setTimeout(() => { 
			this.languageTextStore.pageisLoaded=true;
		},500); 
	}


	updateSearch(event) {
		this.setState({ search: event.target.value.substr(0, 20) });
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
	}

	filterLanguage = () => {
		return this.languageTextStore.baseLabels.filter(
			(lang) => {
				return lang.label.toLowerCase().indexOf(this.state.search.toLowerCase()) != -1 || 
				lang.value.indexOf(this.state.search) != -1 || lang.custom.indexOf(this.state.search) != -1 
			})
	}

	renderRow(languageData, dataIndex, self) {
		return (

			<div className="row action panelContent" key={dataIndex}>
				<div className={self.languageTextStore.selectedLanguage.value !== "en" ? "col-4" : "col-6"}>
					<p className="text-left eng">{languageData.label} </p>
				</div>
				{self.languageTextStore.selectedLanguage.value !== "en" &&
					<div className="col-4">
						<p className="text-left base">{languageData.value} </p>

					</div>
				}
				<div className={this.languageTextStore.selectedLanguage.value !== "en" ? "col-4" : "col-6"}>
					<Form.Group controlId="formGroupDeptChat" className="col-4">
						<Form.Control type="text"
							name="name"
							value={languageData.custom}
							onChange={(event) => languageData.custom = event.target.value} />
					</Form.Group>
					{/* <p className="text-left custom" onInput={(e) => languageData.custom = e.target.innerHTML} // handle innerHTML change
						  contentEditable
						  dangerouslySetInnerHTML={{__html: languageData.custom}}></p> */}
				</div>
			</div>
		);
	}

	render() {

		let self = this;
		const { appstore } = this.props;
		if (!this.languageTextStore.pageisLoaded) {
			return (<div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={appstore.selectedColor.color}
				/>
			</div>)
		}
		let filteredLang = this.filterLanguage();
		return (
			<div className="innercontent_wrapper w-100 languageWrapper">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.langPageIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Languages & Texts")}</h4>
				</div>
				<div className="row">
					<div className="col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form>
								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Select Language")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">
										<DropDownComponent dropDownOptions={this.languageTextStore.lanDropDownOptions}
											store={this.languageTextStore}
											callBack={(selectedOption) => this.languageTextStore.setLanguage(selectedOption, appstore)}
											selectedOption={this.languageTextStore.selectedLanguage} />
									</Col>
								</Form.Group>
							</Form>
							{/* <Form> commenting bcos on enter, page is refresing*/}
							<Form.Group as={Row} controlId="formGroupLanguage">
								<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Quick Search")}</Form.Label>
								<Col sm="8" lg="9" className="searchText">
									<Form.Control type="text"
										placeholder="Enter Search Term"
										onChange={(e) => this.updateSearch(e)}
									/>
								</Col>
							</Form.Group>
							{/* </Form> */}
							<div className="row m-0">
								<div id="panelGroup" className="panelWrapper col-12 col-lg-12 p-0">
									<div className="col-12">
										<div className="row panelHeader">
											<div className={this.languageTextStore.selectedLanguage.value !== "en" ? "col-4" : "col-6"}>
												<p className="text-left">{appstore.getLanguageTrans("English")}</p>
											</div>
											{this.languageTextStore.selectedLanguage.value !== "en" &&
												<div className="col-4">
													<p className="text-left">{appstore.getLanguageTrans("Base")}</p>
												</div>
											}
											<div className={this.languageTextStore.selectedLanguage.value !== "en" ? "col-4" : "col-6"}>
												<p className="text-left">{appstore.getLanguageTrans("Custom")}</p>
											</div>
										</div>
									</div>
									<div className="scrollWrapper col-12 p-0">
										<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
											<div className="scriptPanel row">
												<div className="col-12">

													{filteredLang &&
														filteredLang.map(function (languageData, dataIndex) {
															return (self.renderRow(languageData, dataIndex, self))
														})
													}
												</div>
											</div>
										</CustomScroll>
									</div>


								</div>
								<div className="col-12 formBtns">
									<div className="pullRight">
										<Button className="btn_clr" variant="light" onClick={() => this.languageTextStore.clearCustom()}>
											<p>{appstore.getLanguageTrans("Cancel")}</p>
										</Button>
										<Button className="btn_save" onClick={() => this.languageTextStore.saveCustomToBase(appstore)}>
											<p>{appstore.getLanguageTrans("Save")}</p>
										</Button>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
				{
					this.languageTextStore.errormodel &&
					<WarningComponent show={this.languageTextStore.errormodel}
						store={this.languageTextStore}
						cancel_btn="OKAY"
						closePopup={() => this.languageTextStore.errormodel = false}
						appStore={appstore} />
				}
			</div>
		)
	}
})
export default LanguageTextComponent;
