import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import imageURLs from '../../sharedFiles/ImagesURLs';
import axios from 'axios';

const AdvancedCodeComponent = observer(class AdvancedCodeComponent extends Component {

	constructor (props) {
        super(props);
        //console.log(this.props.getcode);
        let advanced={
            "name": "Visitor known name",
            "email": "email@example.com",
            "general": {
              "accountUid": "5dd7f33836b845eb9e8c018ca64e71ad",
              "departmentUid": "5dd7f338-3064-4603-8a9d-018ca64e71ad",
              "language": "en",
              "modes": [
                "widget",
                "popupInvitation"
              ]
            },
            "color": "dark_blue",
            "widget": {
              "position": "right bottom",
              "enablePopupInvitation": false,
              "hideWhenOffline": false
            }
            };
            
        this.getassisdata();
        this.state={
           email:'',
           //message:'Hello, \n\n Please add this code to my website, best to place just before the </body> tag.\n\n'+this.props.getcode+'\n\nThis is for place live chat on the website\n\nThanks!',
           message:'',
           phpcode:'<script type="text/javascript"><!--\nvar LiveChatCustom = <?php echo json_encode(array(\n"name" => "Visitor known name",\n // ...\n)); ?>;\n--></script>'
        }; 
    }
    getassisdata = () => {
        axios({
            method: 'POST',
            url: this.props.appStore.url + 'getassistdata.json',
            headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
            data: { "uid": this.props.uid }
        }).then(result => { 
          
         if(result.data ==null || result.data ==="")
		   return;
        
          let config=JSON.parse(result.data.clientConfigJson);
          let mode="";
            if(result.data.modesString==="widget,popupInvitation"){ 
                  mode='[\n       "widget",\n       "popupInvitation" \n      ]'; 
            }
            if(result.data.modesString==="widget"){ 
                mode='[\n       "widget"\n      ]'; 
            }
            if(result.data.modesString==="icon,popupInvitation"){
                mode='[\n       "icon",\n       "popupInvitation" \n      ]'; 
            }
            if(result.data.modesString==="toolbar"){
                mode='[\n       "toolbar"\n      ]';                 
            }
            if(result.data.modesString==="widget,icon,popupInvitation"){
                mode='[\n       "widget",\n       "icon",\n       "popupInvitation" \n      ]';               
            }
            if(result.data.modesString==="widget,icon"){
                mode='[\n       "widget",\n       "icon" \n      ]';               
            }
            let  virtual=result.data.enableVirtualAgent==1 ? true:false;
            let websiteuid = '\n      "websiteUid": "'+result.data.websiteUid+'",';
            let departmentuid = result.data.departmentUid!="" ? '\n      "departmentUid": "'+result.data.departmentUid+'",' :"";
            let operatoruid = result.data.operatorUid!=""? '\n      "operatorUid": "'+result.data.operatorUid+'",':"";
            let advanced = '<script type="text/javascript"><!--\n var LiveChatCustom ={\n    "name": "Visitor known name",\n    "email": "email@example.com",\n    "general": {\n      "accountUid": "'+result.data.accountUid+'",'+websiteuid+departmentuid+operatoruid+'\n      "language": "'+result.data.language+'",\n     "enableVirtualAgent": '+virtual+',\n     "modes": '+mode+'\n   },\n    '+JSON.stringify(config, null, 3).slice(1,-1)+'\n } \n --></script>';
                     
            this.setState({ message: advanced });
            this.props.appStore.global_errorcount=0;
        })
        .catch(err=> {
            // this.props.appStore.logout();
            console.log(err);
				console.log("error count :"+this.props.appStore.global_errorcount);
				this.props.appStore.global_errorcount=this.props.appStore.global_errorcount+1;
				if(this.props.appStore.global_errorcount>20){
				// this.props.appStore.logout();
				}
        })

    }
    
	componentWillMount(){
	}
	render(){
		const { store,
				appStore,
				show } = this.props;
		return (
			<Modal backdrop='static' className={"mailer " + (appStore ? appStore.selectedColor.value : '')} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => store.advancedcode = false}>
				  <Modal.Header closeButton>
				    <Modal.Title>{appStore.getLanguageTrans("Advanced code settings")}</Modal.Title>
				    </Modal.Header>
				    <form>
						<div className="row">
								<Modal.Body>
                                    <div className="col-12">
                                        <p>{appStore.getLanguageTrans("Common")}</p>
                                        <span>{appStore.getLanguageTrans("Custom parameters can be passed to the monitor using the following JS code placed before the code received at Code tab:")}</span>
                                    </div>
									<div className="col-12">										  
								  		<Form.Group as={Row} controlId="formGroupTitle">
										    <Col sm="12 pr-0">
										    	<textarea className="getcodetextarea" name="message" rows="10" readOnly value={this.state.message}  />
										    </Col>
								  		</Form.Group>
								  	</div>
                                    <div className="col-12">
                                        <p>{appStore.getLanguageTrans("With PHP arrays it could look like following:")}</p>
                                    </div>
									<div className="col-12">										  
								  		<Form.Group as={Row} controlId="formGroupTitle">
										    <Col sm="12 pr-0">
										    	<textarea className="getcodetextarea" name="message" rows="5" readOnly value={this.state.phpcode}  />
										    </Col>
								  		</Form.Group>
								  	</div>
						  		</Modal.Body>

						</div>
						<Modal.Footer>
						    <Button className="btn_cancel" variant="secondary" onClick={() => store.advancedcode = false}>{appStore.getLanguageTrans("Close")}</Button>
						</Modal.Footer>
					</form>

				  
				</Modal>
		)
	}
})
export default AdvancedCodeComponent;
