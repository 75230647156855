import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button,  } from 'react-bootstrap';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import { css } from '@emotion/core';
import DailyOverViewStore from './DailyOverViewStore'
import { GridLoader } from 'react-spinners';
import CustomScroll from 'react-custom-scroll';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import TimeColumChart from '../TimeColumChart';
import ChatSplineCharts from './chatSplineCharts';
import originalMoment from "moment";
import axios from 'axios';
import ExportCSV from './exportDailyReportCSV';
import Export_CSV from './chatsCSV';
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const DailyOverViewComponent = observer(class DailyOverViewComponent extends Component {

    constructor(props) {
        const today = moment();
        super(props);
        this.dailyOverViewStore = new DailyOverViewStore(this.props.appstore);
        this.state = {
            isOpen: false,
            value: moment.range(today.clone().subtract(-1, "days"), today.clone())
        }
    }

    componentDidMount() {
        this.dailyOverViewStore.pageLoder = false;
        this.dailyOverViewStore.setWebsites(this.props.appstore.userInfo.allwebistes, this.props.appstore)


        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();

        this.getoptOverviewPagedata(date + '-' + month + '-' + year);
        this.getOverviewdata(date + '-' + month + '-' + year);

    }

    getoptOverviewPagedata = (dateRange = null) => {

        const today = moment();
        let since_from_today = today.clone().subtract(-1, "days");

        const data = {
            "till": dateRange != null ? since_from_today.format("DD-MM-YYYY"):this.state.value.start.format("DD-MM-YYYY"),
            "todaydate": dateRange != null ? dateRange:this.state.value.end.format("DD-MM-YYYY"),
            "alltype": ["ops_time", "chat_missed", "chats", "auto_invitations", "invitations"],
            "website_uid": this.dailyOverViewStore.allwebsite.website_uid
        }

        axios({
            method: 'POST',
            url: this.props.appstore.url + 'getoptrecords.json',
            headers: { 'content-type': 'application/json' },
            data: data,

        }).then(result => {
            if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
            let x = []
            let y = result.data
            let inserted = false
            let index = 0;
            for (let i in y) {
                inserted = false
                for (let j in x) {
                    if (x[j].str == y[i].str) {

                        if (y[i].type == "chats" || y[i].type == "chat_missed") {
                            x[j]["_" + y[i].type] = y[i].totalCount;
                        }
                        else if (y[i].type != "chats" || y[i].type != "chat_missed") {
                            x[j]["_" + "chat_missed"] = 0;
                        }

                        if (y[i].type == "ops_time") {
                            x[j].totaltime = y[i].totaltime;
                            x[j]["_" + y[i].type] = y[i].totalCount;
                        }
                        inserted = true
                    }
                }
                if (inserted == false) {
                    x[index] = y[i]
                    x[index][y[i].type] = y[i].type
                    if (y[i].type == "chats" || y[i].type == "chat_missed") {
                        x[index]["_" + y[i].type] = y[i].totalCount;
                    }
                    else if (y[i].type != "chats" || y[i].type != "chat_missed") {
                        x[index]["_" + "chats"] = 0;
                        x[index]["_" + "chat_missed"] = 0;
                    }
                    index++;
                }
            }
            
            this.dailyOverViewStore.optdata = x;
            this.dailyOverViewStore.exportdata = JSON.parse(JSON.stringify(x));
            this.dailyOverViewStore.pageLoder = true;
            this.props.appstore.global_errorcount=0;
        })
        .catch(err=> {
            // this.props.appstore.logout();
            this.dailyOverViewStore.pageLoder = true;
            console.log(err);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
        })


    }

    getOverviewdata = (dateRange = null) => {
        
        const today = moment();
        let since_from_today = today.clone().subtract(-1, "days");

        const data = {
            "till": dateRange != null ? since_from_today.format("DD-MM-YYYY "):this.state.value.start.format("DD-MM-YYYY"),
            "todaydate": dateRange != null ? dateRange:this.state.value.end.format("DD-MM-YYYY"),
            "alltype": ["ops_time", "chat_missed", "chats", "auto_invitations", "invitations"],
            "website_uid": this.dailyOverViewStore.allwebsite.website_uid
        }

        axios({
            method: 'POST',
            url: this.props.appstore.url + 'getoptoverviewrecords.json',
            headers: { 'content-type': 'application/json' },
            data: data,

        }).then(result => {
            if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  } 
            this.dailyOverViewStore.chatexportdata =JSON.parse(JSON.stringify(result.data));
            let onlinetime = 0;
            let totalcharts = 0;
            let auto_invitationcount = 0;
            let invitationcount = 0;
            let inv_count=0;
            let chartsDate = [];
            let chartsCount = [];
            let totalchartsCount = [];
            let previouschartsDate = "";
            for (let i in result.data) { 
                if (result.data[i].type === "ops_time") {
                    onlinetime = result.data[i].totaltime
                }

                if (result.data[i].type === "chats") {
                    if (previouschartsDate === result.data[i].quarter_date) {
                        chartsCount[chartsCount.length - 1] = chartsCount[chartsCount.length - 1] + result.data[i].cnt;
                        totalchartsCount[totalchartsCount.length - 1] = totalchartsCount[totalchartsCount.length - 1] + result.data[i].totalCount;
                    }
                    else {
                        chartsDate.push(result.data[i].quarter_date + " GMT")
                        chartsCount.push(result.data[i].cnt);
                        totalchartsCount.push(result.data[i].totalCount);
                        totalcharts = result.data[i].totalCount
                        previouschartsDate = result.data[i].quarter_date;
                    }
                }
                if (result.data[i].type === "auto_invitations") {
                    auto_invitationcount = result.data[i].totalCount
                    inv_count=inv_count+auto_invitationcount

                }
                if (result.data[i].type === "invitations") {
                    invitationcount = result.data[i].totalCount
                    inv_count=inv_count+invitationcount
                }
                
            } 
            this.dailyOverViewStore.performanceData = {
                "totalcharts": totalcharts,
                "onlinetime": this.secondsToTime(onlinetime),
                "chartsDate": chartsDate,
                // "chartsCount": chartsCount,
                "chartsCount": totalchartsCount,
                "invitationcount": invitationcount,
                "auto_invitationcount": auto_invitationcount,
                "inv_count":inv_count
            }
            this.props.appstore.global_errorcount=0;
        })
        .catch(err=> {
            // this.props.appstore.logout();
                console.log(err);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
        })

    }

    secondsToTime(secs) {
        var hours = Math.floor(secs / (60 * 60));

        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);

        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);

        if (hours == 0 && minutes == 0) {
            return seconds + "s";
        }
        if (hours == 0) {
            return minutes + "m " + seconds + "s";
        }
        else {
            return hours + "h " + minutes + "m " + seconds + "s";
        }
    }


    handleWebsiteFilter = (selectedOption) => {

        this.dailyOverViewStore.allwebsite = selectedOption;
        this.getoptOverviewPagedata()
        this.getOverviewdata()

    }

    refreshimagesetonhover = (color,button)=>{
		if(button==1){
			document.getElementById("opperformanceimg").src=imageURLs.download_white;
		}else if(button==2){
			document.getElementById("no_chartimg").src=imageURLs.download_white;
		}
	}
	refreshimagesetonhoverout = (color,button)=>{
		if(button==1){
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("opperformanceimg").src=imageurl;
		}else if(button==2){
			
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("no_chartimg").src=imageurl;
		}
	}

    render() {
        const { appstore } = this.props;
        let isExportEnabled = appstore.userInfo.accountplan.export_report;
		const excss = {
			pointerEvents: "none",
			opacity: 0.3,
            minWidth:"150px"};
		const nocss = {minWidth:"150px"};	
        let self = this;
        if (!this.dailyOverViewStore.pageLoder) {
            return (<div><div className="adminloader">
                <GridLoader
                    css={override}
                    size={20}
                    marging={2}
                    color={this.props.appstore.selectedColor.color}
                />
            </div> </div>)
        }

        let currentdate = moment();
        return (

            <div className="innercontent_wrapper w-100 scriptWrapper">
                <div className="header_title row align-items-center m-0">
                    <img src={imageURLs.reportoverView} alt="Charts" className="img-fluid" />
                    <h4 className="text-left">{appstore.getLanguageTrans("Daily Overview")}</h4>

                </div>

                <div className="row fileTransferWrapper">
                    <div className="col-12 w-100 pr-0">
                        <div className="settingFormWrapper box">
                            <Form.Group as={Row} controlId="formGroupLanguage">
                                <Col xs="4" lg="10" md="2" sm="2">
                                    <h4 className="text-left">{currentdate.format("dddd, ll")}</h4>
                                </Col>

                            </Form.Group>


                            <Form.Group as={Row} controlId="formGroupLanguage">
                                <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Websites")}</Form.Label>
                                <Col sm="8" lg="9" className="customDrpDwn">

                                    <DropDownComponent dropDownOptions={this.dailyOverViewStore.websiteDrpDwnOptions}
                                        store={this.dailyOverViewStore}
                                        callBack={(selectedOption) => this.handleWebsiteFilter(selectedOption)}
                                        selectedOption={this.dailyOverViewStore.allwebsite}
                                        onChange={(event) => this.getoptOverviewPagedata(event)}
                                    />

                                    {/* onChange = {(event) => this.getStartPagedata(event)}  */}
                                </Col>
                            </Form.Group>

                            {/* <div className="col-12 p-0">
                                <Row className="top_Row" id="top_row">

                                    <Col xs="12" lg="5" md="4" sm="6" >
                                        <div className="headerTitle">
                                            <Form.Group as={Row} controlId="formGroupNameBefore">
                                                <Form.Label column sm="6" lg="7" style={{ paddingLeft: 0 }}>{appstore.getLanguageTrans("Send Daily Report To My Mail")}</Form.Label>
                                                <Col className="custom_radioBtn">
                                                    <Col sm="6" lg="1" className="searchText ">
                                                        <CheckBoxComponent radioValue={this.dailyOverViewStore.emailreport}
                                                            callBack={() => this.dailyOverViewStore.emailreport = !this.dailyOverViewStore.emailreport}
                                                        />

                                                    </Col>
                                                </Col>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </div> */}

                            <div className={appstore.menuActive ? "performance-wrapper" : "performance-wrapper"}>
                                <div className="performance-content reportcharts row">
                                    <div className="chats col-3">
                                        <div className="reportschartsborder">
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.dailyOverViewStore.performanceData.totalcharts}</p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Chats")}</p>
                                        </div>
                                    </div>
                                    <div className="chats col-3">
                                        <div className="reportschartsborder">
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.dailyOverViewStore.performanceData.onlinetime}</p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Online Time")}</p>
                                        </div>
                                    </div>
                                    <div className="chats col-3 ">
                                        <div className="reportschartsborder">
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.dailyOverViewStore.performanceData.inv_count}</p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Invitations Sent")}</p>
                                        </div>
                                    </div>
                                    <div className="chats col-3 ">
                                        <div>
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.dailyOverViewStore.chats}</p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Invitations Accepted")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-12 p-0">
                                <Row className="top_Row" id="top_row">
                                    <Col xs="12" lg="10" md="4" sm="6">
                                        <div className="headerTitle">
                                            <h4 className="text-left">{appstore.getLanguageTrans("Number Of chats")}</h4>
                                        </div>
                                    </Col>
                                    <Col xs="4" lg="2" md="2" sm="2">
                                        <Form.Group as={Row} style={{ paddingTop: 36, right: 15 }} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
                                            <Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,2)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,2)} >

                                                <img id="no_chartimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
                                                    "#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} alt="" style={{ paddingBottom: '4px' }} alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />

                                                <Export_CSV
                                                    data={this.dailyOverViewStore.chatexportdata}
                                                    show={this.dailyOverViewStore.export}
                                                    store={this.dailyOverViewStore}
                                                    appStore={appstore}
                                                />
                                            </Button>
                                        </Form.Group>

                                    </Col>
                                </Row>
                            </div>

                            <div className="row profile_wrapper">
                                <div className="settingsForm col-12 w-100 pr-0">
                                    <div className="settingFormWrapper box">
                                        {this.dailyOverViewStore.performanceData.chartsDate &&
                                            <ChatSplineCharts
                                                key={Math.random()}
                                                data={this.dailyOverViewStore.performanceData}
                                                appstore={appstore}
                                                store={this.dailyOverViewStore} />
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 p-0">
                                <Row className="top_Row" id="top_row">
                                    <Col xs="12" lg="10" md="4" sm="6">
                                        <div className="headerTitle">
                                            <h4 className="text-left">{appstore.getLanguageTrans("Operaters Performance")}</h4>
                                        </div>
                                    </Col>
                                    <Col xs="4" lg="2" md="2" sm="2">
                                        <Form>
                                            <Form.Group as={Row} style={{ paddingTop: 36, right: 15 }} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
                                                <Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss: nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,1)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,1)} >
                                                    <img id="opperformanceimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
                                                        "#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} alt="" style={{ paddingBottom: '4px' }} alt="" style={{ paddingBottom: '4px' }} alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />
                                                    <ExportCSV
                                                        data={this.dailyOverViewStore.exportdata}
                                                        show={this.dailyOverViewStore.export}
                                                        store={this.dailyOverViewStore}
                                                        appStore={appstore}
                                                    />
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>

                            <div className="scroll_row row m-0">
                                <div id="panelGroup" className="panelWrapper col-12 col-lg-12 p-0">
                                    <div className="col-12">
                                        <div className="row panelHeader ">

                                            <div className="col-4 pl-0">
                                                <p className="text-left" style={{ paddingLeft: 16 }}>{appstore.getLanguageTrans("Operaters")}</p>
                                            </div>
                                            <div className="col-2 ">
                                            {/* text-left */}
                                                <p className="text-center">{appstore.getLanguageTrans("Number of Chats")}</p>
                                            </div>
                                            <div className="col-2 ">
                                                <p className="text-center">{appstore.getLanguageTrans("Missed Chats")}</p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-center">{appstore.getLanguageTrans("Online Time")}</p>
                                            </div>
                                            {/* <div className="col-2">
                                                <p className="text-center">{appstore.getLanguageTrans("Check-In Time")}</p>
                                            </div>
                                            <div className="col-2">
                                                <p className="text-center">{appstore.getLanguageTrans("Check-Out Time")}</p>
                                            </div> */}

                                        </div>
                                    </div>


                                    <div className="scrollWrapper col-12 p-0">
                                        <CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
                                            <div className="scriptPanel row">
                                                <div className="col-12">
                                                    {/* {alert(JSON.stringify(this.reportPerformanceStore.responseData.archivechatdata[0]))} */}
                                                    {
                                                        this.dailyOverViewStore.optdata.map(function (fileData, dataIndex) {
                                                            return (
                                                                <div className="row action panelContent align-items-center" key={dataIndex} >

                                                                    <div className="col-4 text-left" style={{ padding: 3 }}>
                                                                        <img src={fileData.image != '' ? self.props.appstore.resourceurl + self.props.appstore.userInfo.accountsmodel.account_uid + "/" + fileData.image : "#3ba8c5" == appstore.selectedColor.color ? imageURLs.avatar_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.avatar_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.avatar_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.avatar_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.avatar_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.avatar_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.avatar_voilet : 
                                                    "#039be6" == appstore.selectedColor.color ? imageURLs.avatar_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.avatar_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.avatar_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.avatar_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.avatar_skyblue : imageURLs.avatar_steelblue} style={{ width: "45px", height: "45px", borderRadius: "50px", border: "2px solid " + appstore.selectedColor.color + " " }}
                                                                            alt="avatar" className="img-fluid" />{<span className="Sms_operators text-center"> {fileData.nick_name}</span>}
                                                                        {/* <p className="Sms_operators">{fileData.nick_name}</p> */}
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <p className="text-left" style={{ color: appstore.selectedColor.color, fontWeight: 700 }}>{fileData._chats} </p>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        {fileData._chat_missed != 0 &&
                                                                            <p className="text-center" style={{ color: "red", fontWeight: 700 }}>{fileData._chat_missed} </p>
                                                                        }

                                                                        {fileData._chat_missed == 0 &&
                                                                            <p className="text-center" style={{  color: "rgb(14, 155, 30)",fontWeight: 700 }}>{fileData._chat_missed} </p>
                                                                        }

                                                                    </div>
                                                                    <div className="col-4">
                                                                        <p className="text-center">{self.secondsToTime(fileData.totaltime)} </p>
                                                                    </div>
                                                                    {/* <div className="col-2">
                                                                        <p className="text-center">{fileData.CheckIn_Time} </p>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <p className="text-center">{fileData.Check_Out_Time} </p>
                                                                    </div> */}

                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </CustomScroll>
                                    </div>



                                </div>
                            </div>




                        </div>
                    </div>
                </div>

            </div>
        )
    }
});

export default DailyOverViewComponent;