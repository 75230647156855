import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { observer } from 'mobx-react';

import navigationStore from '../sharedFiles/navigation-store';

import ProfileComponent from '../components/profile/ProfileComponent';

const ProfilePageLayout = observer(class ProfilePageLayout extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }


  componentWillReceiveProps(nextProps){
  }

  /**
   * @param route
   */
  render() {

    const { appstore } = this.props;

    return (    
      <Router history={navigationStore.history}>
        <Switch>
          <Route exact path="/profile" render={()=><ProfileComponent 
                         appstore={appstore} />}/>
        </Switch>
      </Router>
    )
  }
})

export default ProfilePageLayout;
