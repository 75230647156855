import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';

import htmlToDraft from 'html-to-draftjs';

import imageURLs from '../../sharedFiles/ImagesURLs';

import SpellCheck from './spellCheckOption';

const TextEditor = observer(class TextEditor extends Component {

	constructor (props) {
        super(props);
        this.state={
        	spellCheck: false
        };
        this.setSpellCheck = this.setSpellCheck.bind(this);
    }
	componentDidMount(){
		const contentBlock = htmlToDraft(this.props.editorContent);
	    if (contentBlock) {
	      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
	      this.props.store.editorState = EditorState.createWithContent(contentState);
	    }
	    else {
        	this.props.store.editorState = EditorState.createEmpty();
	    }

	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.editorContent !== this.props.editorContent && !this.props.store.editing) {
			const contentBlock = htmlToDraft(nextProps.editorContent);
		    if (contentBlock) {
		      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		      nextProps.store.editorState = EditorState.createWithContent(contentState);
		    }
		    else {
	        	nextProps.store.editorState = EditorState.createEmpty();
		    }
		}
	}

	setSpellCheck() {
		let prevValue = this.state.spellCheck;
		this.setState({
			spellCheck: !prevValue
		});
		let self = this;
		console.log(this.props.store.editorState);
		setTimeout(function(){
		self.props.store.onEditorStateChange(self.props.store.editorState)
				
			},1000);
		
		console.log(this.state.spellCheck);
	}

	render(){
		const { store,
				editorContent } = this.props;
		return (
			<Editor
				  editorState={store.editorState}
				  toolbarClassName="editorToolBar"
				  wrapperClassName="editorWrapper"
				  editorClassName="customEditor"
				  spellCheck={this.state.spellCheck}
				  toolbarCustomButtons={[<SpellCheck appstore={this.props.appstore} callBack={this.setSpellCheck}/>]}
				  toolbar={{
						    options: ['inline','emoji','link'],
						    inline: {
						    		className: "editorInline",
								    options: ['bold', 'italic', 'underline'],
								    
  									},
  						
  							emoji: {
  								className: "editorEmoji"
							  },
							  
							  link: {
								inDropdown: false,
								className: "textEditorLink",
								component: undefined,
								popupClassName: undefined,
								dropdownClassName: undefined,
								showOpenOptionOnHover: true,
								defaultTargetOption: '_self',
								options: ['link'],
								linkCallback: undefined
							  },
  							spellCheck: {
  								className: "editorEmoji"
  							}
  
						  }}
				  onEditorStateChange={(editorState) => store.onEditorStateChange(editorState)}
				/>
				
		)
	}
})
export default TextEditor;
