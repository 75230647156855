import { decorate, observable, action } from 'mobx';
//import {data} from '../../assets/JSON/online_operators';
import imageURLs from '../../sharedFiles/ImagesURLs';
import axios from 'axios';
class OnlineOperatorsStore {
	
  	constructor(){
        this.operatorsList = [];
        this.websiteOp =[];
        this.operator_options = [
                           //  {"id":0,"action": "Begin chat", "active": true},
                            {"id":1,"action": "Invite to join", "active": false},
                            {"id":2,"action": "Transfer chat", "active": false},
                            {"id":3,"action": "Cancel invitation", "active": false},
                            {"id":4,"action": "Logout operator", "active": true}
                        ]
      
    }

    getOnlineOperators= async (appstore) =>{

      await axios({
          method:"POST",
          url:appstore.url+"getonlineoperator.json",
          header:{"content-type":"application/JSON"},
          data:{"account_id":appstore.userInfo.usersmodel.account_id}
       })
       .then(result => {
          
          this.operatorsList = [];
          if(result.data ==null)
          return;
         

          if(window.location.pathname.indexOf("chats/operator") != -1){
            if(appstore.requestedVisitorId !="" && appstore.currentChattingWebsite !=""){
              // this.dptoptforwebiste(result.data,appstore)
               //return;  
            }
         }

          for(let i in result.data){
            let profileImg =  (result.data[i].image != '' && result.data[i].image != undefined) ? appstore.resourceurl + appstore.userInfo.accountsmodel.account_uid + "/"+ result.data[i].image : "#3ba8c5" == appstore.selectedColor.color ? imageURLs.avatar_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.avatar_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.avatar_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.avatar_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.avatar_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.avatar_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.avatar_voilet : 
            "#039be6" == appstore.selectedColor.color ? imageURLs.avatar_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.avatar_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.avatar_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.avatar_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.avatar_skyblue : imageURLs.avatar_steelblue;   

            let op = {
                        "operator_name" : result.data[i].nick_name,
                         "operator_thumbnail" :profileImg,
                         "operator_queuedImg" : '',
                         "chat_queued": 0,   //1 for showig at top of all operator
                         "role": result.data[i].user_type_id == 3 ? "admin":"operator",
                         "id": result.data[i].user_uid,

                    };
            this.operatorsList.push(op); 
                 
          }
          appstore.global_errorcount=0;
       })
       .catch(error => {
         // appstore.logout();
         console.log(error);
         console.log("error count :"+appstore.global_errorcount);
         appstore.global_errorcount=appstore.global_errorcount+1;
         if(appstore.global_errorcount>20){
         // appstore.logout();
         }
       })
    } 

    handleOperatorOptions = (event , appstore,operator,option,optionIndex) =>{
      
      event.stopPropagation();
     
     //handle logout operator option
     const loggedOp = appstore.userInfo.usersmodel;
     this.selectedOperator =  undefined;
     if(!option.active)
         return; 
      
     if(option.id==1){
         if(window.location.pathname === "/traffic")
           return;
           
        //invite to join
        this.inviteTojoin(operator, appstore);
        this.operator_options.map(obj => {
           if(obj.id === 3)
              obj.active = true;
            
           if(obj.id === 1)
              obj.active = false;   

             return obj 
        })
     }

     else if(option.id==2){
      //transfer chat
       if(window.location.pathname === "/traffic")
       return;
       
       appstore.transferChat(operator);
     }

     else if(option.id==3){
         this.operator_options.map(obj => {
            if(obj.id === 1)
               obj.active = true;
             
            if(obj.id === 3)
            obj.active = false;   

            return obj 
         })

        this.cancelInviteTojoin(operator, appstore); 
     }
     else if(option.id== 4){
         if (loggedOp.user_type_id ==2 || loggedOp.user_uid ===operator.id){
             //if user is operator not admin 
             return;
         }
          let opMsg =  {id:'',type:"system_msg",system_msg:true, message:"",time:appstore.getcurrentUtcTime(), hostname:'', sourceStation:loggedOp.user_uid,chatStatus:"logoutOp",
          targetStation:'/topic/'+operator.id}
           appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));    

          //setoffline in redis 
          this.setOfflineOperator(appstore,operator);

          let filterOp = this.operatorsList.filter((value)=>{
             if(operator.id !== value.id)
             return value;
          })  

         setTimeout(()=>this.operatorsList=  filterOp,2000);
     }

    }

    setOfflineOperator =(appstore , logoutOp)=>{

         let user = appstore.userInfo.usersmodel;
         let logout_user =  {
            account_id : user.account_id,
            user_uid : logoutOp.id,
            user_live_status : "offline",
            website_uid : appstore.selectedWebisiteUid,
         };
           axios({
             method: 'POST',
             url: appstore.url + 'setonlineoffline.json',
             headers: { 'content-type': 'application/json' },
             data:logout_user,
           }).then(result => {  
            if(result.data.length==1){
               if(result.data[0].logoutstatus==1){
                 this.appstore.logout();
               }
              }
             let x;
             let index = 0;
             for (x in result.data) {
               result.data[x].admin = result.data[x].user_type_id == 3 ? true : false;
               result.data[x].label = result.data[x].nick_name;
               result.data[x].value = index;
               result.data[x].isPasswordChanged = false;
               index++;
             } 
             appstore.operators=result.data;
             
             for(let i=0; i< appstore.userInfo.alloperators.length; i++){
                if(appstore.userInfo.alloperators[i].user_uid === logout_user.user_uid){
                   appstore.userInfo.alloperators[i].user_live_status =  logout_user.user_live_status;
                   break; 
                 }
             }
             appstore.global_errorcount=0;
           }).catch((error) => {
            // appstore.logout();
            console.log(error);
         console.log("error count :"+appstore.global_errorcount);
         appstore.global_errorcount=appstore.global_errorcount+1;
         if(appstore.global_errorcount>20){
         // appstore.logout();
         }
           });  
   
         //   //send online,offline status to all admins
         //   const currentTime = appstore.getcurrentUtcTime();
         //   const liveStatus = "offline"
       
         //   let opMsg =  {id:'',system_msg:true, type:"", role:"operator", message:"",creationTime: currentTime,
         //                 sourceStation:"",chatStatus:liveStatus}
       
           
         //   //send status to all visitor
         //   for(let visitor  of  appstore.trafficVisitorStatus){
         //         opMsg.targetStation ='/topic/'+ visitor.sourceStation;
         //         opMsg.operatorUid = user.user_uid;
   
         //         appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg)); 
         //       }
         //   }
       
    }


    inviteTojoin(operator ,appstore){
      //tranfer chat or invite operator to begin chat
      const user = operator;
      const currentTime = appstore.getcurrentUtcTime();
      let visitorRequestObj;
      if(appstore.requestedVisitorId){
  
        if(appstore.visitors[appstore.requestedVisitorId]){
           visitorRequestObj = appstore.visitors[appstore.requestedVisitorId].find(obj=> obj.chatStatus==="chatRequest")
        }
        else
           visitorRequestObj = undefined;     
      }
  
      if(!visitorRequestObj)
        return;
  
        let opMsg = visitorRequestObj;
        opMsg.targetStation = '/topic/'+user.id;
        opMsg.creationTime = currentTime;
        opMsg.inviteTojoin = true;
        opMsg.joinedOperators = appstore.inviteJoinedByOp[appstore.requestedVisitorId]
        opMsg.invitedBy = appstore.userInfo.usersmodel.user_uid;
    
        appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg)); 
    
     }

     cancelInviteTojoin(operator ,appstore){
    
      const selecteduser = operator;
  
      if(appstore.requestedVisitorId){
  
         const currentTime =  appstore.getcurrentUtcTime();
         const user = appstore.userInfo.usersmodel;
         const userRole = user.user_type_id == 3 ? 'admin':'operator';
        
         let opMsg = {system_msg:true,userUid:appstore.requestedVisitorId,operatorUid:user.user_uid,creationTime:currentTime,role:userRole,account_uid:appstore.userInfo.accountsmodel.account_uid,
         acceptedBy:user.user_uid, message:"",fileName:"",originalFileName:"",fileSize:"", hostname:'', 
         sourceStation:appstore.requestedVisitorId,chatStatus:"cancelInvite", name:user.nick_name, inviteTojoin:false ,invitedBy:"",
         email:user.email,targetStation:'/topic/'+selecteduser.id}
      
        appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg)); 
    
     }

   }

   dptoptforwebiste= async (allOp ,appstore,uid="") =>{   
       
       await axios({
        method: 'POST', 
        url: appstore.url+'dptoptforwebiste.json',
        headers: { 'content-type': 'application/json' },
        data:{"website_uid": appstore.currentChattingWebsite,"department_uid":uid},
     }).then(result =>{
      if(result.data.length==1){
         if(result.data.logoutstatus==1){
         this.appstore.logout();
         }
       }
         if(result.data)
            this.websiteOp = result.data.usersmodel;
         else
           this.websiteOp =[];  
           
           let fileteredOp = [];
           if(this.websiteOp){
               for(let websiteOp of this.websiteOp){
                     for(let onlineOp of allOp){
                            if(websiteOp.user_uid === onlineOp.user_uid){
                                 fileteredOp.push(onlineOp);
                                 break;
                            }
                     }
               }
           }

           this.operatorsList =[];
           for(let i in fileteredOp){
            let profileImg =  (fileteredOp[i].image != '' && fileteredOp[i].image != undefined) ? appstore.resourceurl + appstore.userInfo.accountsmodel.account_uid + "/"+ fileteredOp[i].image : "#3ba8c5" == appstore.selectedColor.color ? imageURLs.avatar_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.avatar_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.avatar_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.avatar_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.avatar_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.avatar_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.avatar_voilet : 
            "#039be6" == appstore.selectedColor.color ? imageURLs.avatar_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.avatar_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.avatar_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.avatar_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.avatar_skyblue : imageURLs.avatar_steelblue;   

            let op = {
                        "operator_name" : fileteredOp[i].nick_name,
                         "operator_thumbnail" :profileImg,
                         "operator_queuedImg" : '',
                         "chat_queued": 0,   //1 for showig at top of all operator
                         "role": fileteredOp[i].user_type_id == 3 ? "admin":"operator",
                         "id": fileteredOp[i].user_uid,

                    };
            this.operatorsList.push(op);      
          }
          appstore.global_errorcount=0;
     })
     .catch(err => {
         // appstore.logout();
         console.log(err);
         console.log("error count :"+appstore.global_errorcount);
         appstore.global_errorcount=appstore.global_errorcount+1;
         if(appstore.global_errorcount>20){
         // appstore.logout();
         }
      })

   }
    
 }

 decorate(OnlineOperatorsStore, {
        selectedOperator: observable,
        adjustedOffset: observable,
        topValue: observable,
        operatorsList:observable,
        websiteOp:observable,
    })
 export default OnlineOperatorsStore;
