import React, { Component } from 'react';
import { decorate, observable, extendObservable } from 'mobx';
import {data} from '../../../../assets/JSON/admin_archivechat';
import axios from 'axios';


class DailyOverViewStore {

    constructor(appstore){
           this.responseData = data[0];
           this.confirm = false;
           this.datepicker= false;
          //this.selectedfileLifeTimeDrpDwn =  this.fileLifeTimeDrpDwn[0]; 
          //this.selectedfileTransferDrpDwn =  this.fileTransferDrpDwn[0];
          this.websiteDrpDwnOptions = [];
          this.allDepartments =[];    
          this.allWebsite =[];
          this.chats = 0; 
          this.prechat = false;
          this.pageLoder= false;
          this.emailreport=false;
          this.totalmissedchartSum=0;
          this.performanceData=0;
          this.exportdata=[];
          this.chatexportdata=[];
                  
          this.testtablefields = [
            {value:1, Operaters:'Adrea', Number_of_Charts:'0' , Missed_Charts:'28' , Online_Time:'8h 02M' , CheckIn_Time:'2:15am', Check_Out_Time:'6:45am'},
            {value:2, Operaters:'Melany', Number_of_Charts:'3' , Missed_Charts:'26' , Online_Time:'8h 02M',CheckIn_Time:'3:15am', Check_Out_Time:'4:54am'},   
            {value:3, Operaters:'Ivy', Number_of_Charts:'0' , Missed_Charts:'24', Online_Time:'8h 02M',CheckIn_Time:'4:11am', Check_Out_Time:'4:45am'},
            {value:4, Operaters:'Melany', Number_of_Charts:'10', Missed_Charts:'20', Online_Time:'8h 02M',CheckIn_Time:'2:11pm', Check_Out_Time:'5:32pm'},
            {value:5, Operaters:'Adrea', Number_of_Charts:'3' , Missed_Charts:'22', Online_Time:'8h 02M',CheckIn_Time:'5:55pm', Check_Out_Time:'7:23pm'},
            {value:6, Operaters:'Ivy', Number_of_Charts:'8' , Missed_Charts:'12', Online_Time:'8h 02M',CheckIn_Time:'5:55pm', Check_Out_Time:'7:23pm'}
                 ];
        
          // this.setDropDownValues(appstore);
           //this.setInitialValues();
     }

     setWebsites(websitesmodel,appstore) {
        let websites = JSON.parse(JSON.stringify(websitesmodel));
       
        if(websites !=null){
  
          let all = {"label":"All","value":0,"id":0,"optionType":"websiteAll","website_uid":""};
          websites.splice(0, 0,all);   //insert All option in dropdown options
          let x;
          let index =0;
          for (x in websites)
          {
            if(x>0){
            websites[x].label=websites[x].name;
            websites[x].value= index;
            }
            index++;
          }
         
           this.websiteDrpDwnOptions = websites;
           this.allwebsite = this.websiteDrpDwnOptions[0]
          
        }
       }
  
       
}

decorate(DailyOverViewStore, {
    datepicker:observable,
    websiteDrpDwnOptions:observable,
    operatorDrpDwnOptions:observable,
    allwebsite:observable,
    isPageLoaded:observable,
    responseData:observable,
    prechat: observable,
    pageLoder:observable,
    charts:observable,
    emailreport:observable,
    totalchartSum:observable,
    totalmissedchartSum:observable,
    totalonlinetime:observable,
    optdata:observable,
    performanceData:observable,
    exportdata:observable,
    export:observable,
    chatexportdata:observable,
})

export default DailyOverViewStore;