import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../sharedFiles/navigation-store';
import FormValidator from '../../../../sharedFiles/FormValidator';
import ConfirmationComponent from '../../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import SecurityStore from './SecurityStore';
import { css } from '@emotion/core';

import { GridLoader } from 'react-spinners';
import WarningComponent from '../../../../sharedComponents/confirmationPopup/WarningComponent';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const SecurityComponent = observer(class SecurityComponent extends Component {

	constructor(props) {
		super(props);
		this.securityStore = new SecurityStore(this.props.appstore);
		this.updateProperty = this.updateProperty.bind(this);

		this.validator = new FormValidator([
			{
				field: 'message_field',
				method: 'isEmpty',
				validWhen: false,
				message: 'Please enter your consent message.'
			},
			{
				field: 'link_title_field1',
				method: 'isEmpty',
				validWhen: false,
				message: 'Please enter the document title.'
			},
			{
				field: 'link_field1',
				method: 'isEmpty',
				validWhen: false,
				message: 'Please enter a valid URL.'
			},

		]);


		this.state = {
			websiteUid: this.props.appstore.selectedWebisiteUid,
			validation: this.validator.valid(),
		}

	}

	componentDidMount() {
		this.props.appstore.menuChanged = window.location.pathname;
		this.clearConsentpolicies();
		this.securityStore.getAllcountries();
		this.securitytab();
		this.consenttab();

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {
			this.securityStore.saveinProcess = true;
			this.setState({ websiteUid: this.props.appstore.selectedWebisiteUid });
			this.clearConsentpolicies();
			this.consenttab();
			setTimeout(() => {
				this.securityStore.saveinProcess = false;
			}, 1000);
		}
	}
	clearConsentpolicies = () => {
		this.securityStore.nonmandatoryfields =
		{
			link_title_field2: { isInvalid: false, message: "" },
			link_field2: { isInvalid: false, message: "" },
			link_title_field3: { isInvalid: false, message: "" },
			link_field3: { isinvalid: false, message: "" }
		}
		this.securityStore.mandatoryfields =
		{
			link_title_field1: { isInvalid: false, message: "" },
			link_field1: { isInvalid: false, message: "" },
			message_field: { isInvalid: false, message: "" }
		}
	}

	ValidURL = (str) => {
		var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
		if (!regex.test(str)) {
			return false;
		} else {
			return true;
		}
	}

	handleSubmission = () => {
		this.securityStore.nonmandatoryfields =
		{
			message_field: { isInvalid: false, message: "" },
			link_title_field1: { isInvalid: false, message: "" },
			link_field1: { isInvalid: false, message: "" },
			link_title_field2: { isInvalid: false, message: "" },
			link_field2: { isInvalid: false, message: "" },
			link_title_field3: { isInvalid: false, message: "" },
			link_field3: { isinvalid: false, message: "" }
		}
		this.securityStore.mandatoryfields =
		{
			link_title_field1: { isInvalid: false, message: "" },
			link_field1: { isInvalid: false, message: "" },
			message_field: { isInvalid: false, message: "" }
		}

		// const validation = this.validator.validate(this.securityStore.consentPolicies);
		// this.setState({ validation });
		// this.submitted = true;
		// if (!validation.isValid) {
		// 	//if not valid then stop form submission.
		// 	//window.scrollTo(0, 0);
		// 	return;
		// }
		if (this.securityStore.consentPolicies.message_field == "") {

			this.securityStore.mandatoryfields.message_field.isInvalid = true
			this.securityStore.mandatoryfields.message_field.message = "Please enter your consent message."

			return;
		}
		if (this.securityStore.consentPolicies.link_field1 != "" && this.securityStore.consentPolicies.link_title_field1 === "") {

			this.securityStore.mandatoryfields.link_title_field1.isInvalid = true
			this.securityStore.mandatoryfields.link_title_field1.message = "Please enter the document title."

			return;
		}
		if (this.securityStore.consentPolicies.link_title_field1 != "" && this.securityStore.consentPolicies.link_field1 === "") {
			this.securityStore.mandatoryfields.link_field1.isInvalid = true
			this.securityStore.mandatoryfields.link_field1.message = "Please enter a valid URL."

			return;
		}


		if (this.securityStore.consentPolicies.link_field2 != "" && this.securityStore.consentPolicies.link_title_field2 === "") {

			this.securityStore.nonmandatoryfields.link_title_field2.isInvalid = true
			this.securityStore.nonmandatoryfields.link_title_field2.message = "Please enter the document title."

			return;
		}
		 
		if (this.securityStore.consentPolicies.link_title_field2 != "" && this.securityStore.consentPolicies.link_field2 === "") {
			this.securityStore.nonmandatoryfields.link_field2.isInvalid = true
			this.securityStore.nonmandatoryfields.link_field2.message = "Please enter a valid URL."

			return;
		}

		if (this.securityStore.consentPolicies.link_field3 != "" && this.securityStore.consentPolicies.link_title_field3 === "") {

			this.securityStore.nonmandatoryfields.link_title_field3.isInvalid = true
			this.securityStore.nonmandatoryfields.link_title_field3.message = "Please enter the document title."

			return;
		}
		if (this.securityStore.consentPolicies.link_title_field3 != "" && this.securityStore.consentPolicies.link_field3 === "") {
			this.securityStore.nonmandatoryfields.link_field3.isInvalid = true
			this.securityStore.nonmandatoryfields.link_field3.message = "Please enter a valid URL."

			return;
		}
		let message_field1 = this.ValidURL(this.securityStore.consentPolicies.message_field);
		if (message_field1) {
			this.securityStore.mandatoryfields.message_field.isInvalid = true
			this.securityStore.mandatoryfields.message_field.message = "Please enter your consent message."
			return;
		} else {
			this.securityStore.mandatoryfields.message_field.isInvalid = false
			this.securityStore.mandatoryfields.message_field.message = ""
		}
		let linktitle1 = this.ValidURL(this.securityStore.consentPolicies.link_title_field1);
		if (linktitle1) {
			this.securityStore.mandatoryfields.link_title_field1.isInvalid = true
			this.securityStore.mandatoryfields.link_title_field1.message = "Please enter valid document title "
			return;
		} else {
			this.securityStore.mandatoryfields.link_title_field1.isInvalid = false
			this.securityStore.mandatoryfields.link_title_field1.message = ""
		}
		let link1 = this.ValidURL(this.securityStore.consentPolicies.link_field1);
		if (!link1) {
			this.securityStore.mandatoryfields.link_field1.isInvalid = true
			this.securityStore.mandatoryfields.link_field1.message = "Please enter a valid URL."
			return;
		} else {
			this.securityStore.mandatoryfields.link_field1.isInvalid = false
			this.securityStore.mandatoryfields.link_field1.message = ""
		}
		let linktitle2 = this.ValidURL(this.securityStore.consentPolicies.link_title_field2);
		if (linktitle2) {
			this.securityStore.nonmandatoryfields.link_title_field2.isInvalid = true
			this.securityStore.nonmandatoryfields.link_title_field2.message = "Please enter valid document title."
			return;
		} else {
			this.securityStore.nonmandatoryfields.link_title_field2.isInvalid = false
			this.securityStore.nonmandatoryfields.link_title_field2.message = ""
		}
		if (this.securityStore.consentPolicies.link_title_field2.trim() != "" && this.securityStore.consentPolicies.link_field2 === "") {
		let link2 = this.ValidURL(this.securityStore.consentPolicies.link_field2);
			if (!link2) {
				this.securityStore.nonmandatoryfields.link_field2.isInvalid = true
				this.securityStore.nonmandatoryfields.link_field2.message = "Please enter a valid URL."
				return;
			} else {
				this.securityStore.nonmandatoryfields.link_field2.isInvalid = false
				this.securityStore.nonmandatoryfields.link_field2.message = ""
			}
		}
		if (this.securityStore.consentPolicies.link_title_field2.trim() != "" && this.securityStore.consentPolicies.link_field2 != "") {
			let link2 = this.ValidURL(this.securityStore.consentPolicies.link_field2);
				if (!link2) {
					this.securityStore.nonmandatoryfields.link_field2.isInvalid = true
					this.securityStore.nonmandatoryfields.link_field2.message = "Please enter a valid URL."
					return;
				} else {
					this.securityStore.nonmandatoryfields.link_field2.isInvalid = false
					this.securityStore.nonmandatoryfields.link_field2.message = ""
				}
			}
		let linktitle3 = this.ValidURL(this.securityStore.consentPolicies.link_title_field3);
		if (linktitle3) {
			this.securityStore.nonmandatoryfields.link_title_field3.isInvalid = true
			this.securityStore.nonmandatoryfields.link_title_field3.message = "Please enter valid document title."
			return;
		} else {
			this.securityStore.nonmandatoryfields.link_title_field3.isInvalid = false
			this.securityStore.nonmandatoryfields.link_title_field3.message = ""
		}
		if (this.securityStore.consentPolicies.link_title_field3 != "" && this.securityStore.consentPolicies.link_field3 === "") {
			let link3 = this.ValidURL(this.securityStore.consentPolicies.link_field3);
			if (!link3) {
				this.securityStore.nonmandatoryfields.link_field3.isInvalid = true
				this.securityStore.nonmandatoryfields.link_field3.message = "Please enter a valid URL."
				return;
			} else {
				this.securityStore.nonmandatoryfields.link_field3.isInvalid = false
				this.securityStore.nonmandatoryfields.link_field3.message = ""
			}
		}
		if (this.securityStore.consentPolicies.link_title_field3 != "" && this.securityStore.consentPolicies.link_field3 != "") {
			let link3 = this.ValidURL(this.securityStore.consentPolicies.link_field3);
			if (!link3) {
				this.securityStore.nonmandatoryfields.link_field3.isInvalid = true
				this.securityStore.nonmandatoryfields.link_field3.message = "Please enter a valid URL."
				return;
			} else {
				this.securityStore.nonmandatoryfields.link_field3.isInvalid = false
				this.securityStore.nonmandatoryfields.link_field3.message = ""
			}
		}

		this.securityStore.saveConsent()


	}

	securitytab = () => {
		this.securityStore.securitytab.ipBlackList = this.props.appstore.userInfo.accountsmodel.ip_black_list;
		this.securityStore.securitytab.force_ssl = this.props.appstore.userInfo.accountsmodel.force_ssl;
		this.securityStore.securitytab.private_label = this.props.appstore.userInfo.accountsmodel.private_label;
		this.securityStore.securitytab.private_label_title = this.props.appstore.userInfo.accountsmodel.private_label_title;
		this.securityStore.securitytab.chatHiddenCountries=this.props.appstore.userInfo.accountsmodel.country_black_list!="" ? JSON.parse(this.props.appstore.userInfo.accountsmodel.country_black_list): [];
		this.securityStore.backupSecuritytab = Object.assign({}, this.securityStore.securitytab);		
	}

	consenttab = () => {
		for (let x in this.props.appstore.userInfo.allwebistes) {
			if (this.props.appstore.userInfo.allwebistes[x].website_uid === this.props.appstore.selectedWebisiteUid) {
				this.securityStore.consentPolicies.website_uid = this.props.appstore.userInfo.allwebistes[x].website_uid;
				this.securityStore.consentPolicies.enable_consent = this.props.appstore.userInfo.allwebistes[x].enable_consent;
				this.securityStore.consentPolicies.enable_consent_offline = this.props.appstore.userInfo.allwebistes[x].enable_consent_offline;
				this.securityStore.consentPolicies.message_field = this.props.appstore.userInfo.allwebistes[x].message_field;
				this.securityStore.consentPolicies.link_title_field1 = this.props.appstore.userInfo.allwebistes[x].link_title_field1;
				this.securityStore.consentPolicies.link_field1 = this.props.appstore.userInfo.allwebistes[x].link_field1;
				this.securityStore.consentPolicies.link_title_field2 = this.props.appstore.userInfo.allwebistes[x].link_title_field2;
				this.securityStore.consentPolicies.link_field2 = this.props.appstore.userInfo.allwebistes[x].link_field2;
				this.securityStore.consentPolicies.link_title_field3 = this.props.appstore.userInfo.allwebistes[x].link_title_field3
				this.securityStore.consentPolicies.link_field3 = this.props.appstore.userInfo.allwebistes[x].link_field3;

			}
		}


		if (this.securityStore.consentPolicies.message_field.trim() == "") {
			this.securityStore.consentPolicies.message_field = "I agree to have my personal data processed by YOUR COMPANY NAME, for support and sales purposes in compliance with";

		}
		if (this.securityStore.consentPolicies.link_title_field1.trim() == "") {
			this.securityStore.consentPolicies.link_title_field1 = "Privacy Policy";
		}
		// && this.securityStore.consentPolicies.link_field1 == "" 
		this.securityStore.backupConsentPolicies = Object.assign({}, this.securityStore.consentPolicies);
		let self = this;
		setTimeout(function () {
			self.securityStore.ispageLoaded = true;
		}, 500);
	}

	updateProperty(key, value) {
		if (key !== 'ipBlackList') {
			this.securityStore.consentPolicies[key] = value;

		}
		else {
			this.securityStore.securitytab[key] = value;
		}

	}

	handleChange(event) { 
		this.updateProperty(event.target.name, event.target.value);
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
	}

	deletcountryblacklist(country){ 
		this.securityStore.securitytab.chatHiddenCountries=this.securityStore.securitytab.chatHiddenCountries.filter(id => {  
				if(id!=country){
					return id;
				}
			});
	}

	render() {
		if (!this.securityStore.ispageLoaded) {
			return (<div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div>)
		}
		const vacss={
			pointerEvents:"none",
			opacity:0.3,
		}
		const nocss={}
		var self = this;
		const { appstore } = this.props;
		let validation = this.submitted ?                         // if the form has been submitted at least once
			this.validator.validate(this.securityStore.consentPolicies) :   // then check validity every time we render
			this.state.validation
		var accountInfo = this.securityStore.securitytab.ipBlackList;

		return (
			<div className="innercontent_wrapper w-100">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.securityPageIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Security")}</h4>
				</div>

				{this.securityStore.saveinProcess &&
					<div className="adminloader">
						<GridLoader
							css={override}
							size={20}
							marging={2}
							color={this.props.appstore.selectedColor.color}
						// loading={this.responseStore.responsereload}
						/>
					</div>
				}
				{this.securityStore.errormodel &&
					<WarningComponent show={this.securityStore.errormodel}
						store={this.securityStore}
						cancel_btn="OK"
						closePopup={() => this.securityStore.errormodel = false}
						appStore={appstore} />
				}

				<div className="row securityWrapper">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form> 
								<Form.Group as={Row} controlId="formGroupCompanyname">
									<Form.Label   column lg="3" sm="4">{appstore.getLanguageTrans("Company name")}</Form.Label>
									<Col  sm="8" lg="9">
										<Form.Control type="text" placeholder=""
											className="custom_input_companytext "
											maxLength="40"
											readOnly={this.securityStore.securitytab.private_label==1 ? false:true}
											name="private_label_title"
											value={this.securityStore.securitytab.private_label_title}
											onChange={(e) => this.securityStore.securitytab.private_label_title=e.target.value}  />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupSSLSecurity">
									<Form.Label column xs="7" sm="4" lg="3">{appstore.getLanguageTrans("Force SSL security")}</Form.Label>
									<Col className="custom_radioBtn">

										<CheckBoxComponent radioValue={this.securityStore.securitytab.force_ssl}
											callBack={() => this.securityStore.securitytab.force_ssl = this.securityStore.securitytab.force_ssl == 0 ? 1 : 0} />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupNickName" className="custom_textArea">
									<Form.Label column xs="12" sm="4" lg="3">{appstore.getLanguageTrans("IP address Black List")}<br />
										<span>123.45.67.89<br />
											234.56.*<br />
											*.example.com</span>
									</Form.Label>
									<Col sm="8" lg="9">
										<textarea name="ipBlackList" rows="9" value={this.securityStore.securitytab.ipBlackList} onChange={(e) => this.handleChange(e)} />
									</Col>
								</Form.Group>
                               
								<Form.Group as={Row} controlId="formGroupNickName">
									    <Form.Label column xs="12" sm="4" lg="3">{appstore.getLanguageTrans("Hide chat in the following countries")}
									    </Form.Label>
									    <Col sm="8" lg="9" className="country_col" key={Math.random()}>
									    	{
									    		this.securityStore.securitytab.chatHiddenCountries.map(function(country,countryIndex) {
									    			return(
									    				<p className="align-self-center country_text" >
									    					<img src={imageURLs.deleteIcon} onClick={()=> self.deletcountryblacklist(country)} />
									    					<span className="text-uppercase">{country}
									    					</span>
									    				</p>
									    			)
									    		})
									    	}
									    </Col>
									</Form.Group>

								<Form.Group as={Row} controlId="formGroupLanguage">
									    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Add a country")}</Form.Label>
										<span data-tooltip={appstore.userInfo.accountplan.hide_widget_specfic_country !=1?appstore.getLanguageTrans("Upgrade your plan"):""} data-tooltip-conf="bottom">
									    <Col sm="8" lg="9" className="customDrpDwn" style={appstore.userInfo.accountplan.hide_widget_specfic_country !=1?vacss:nocss}>
									    	<DropDownComponent dropDownOptions={this.securityStore.countryDropDownOptions}
																key={Math.random()}
									    						store={this.securityStore}
											    				callBack={(selectedOption) => this.securityStore.setCountry(selectedOption)}
											    				selectedOption = {this.securityStore.securitytab.selectedCountry}/>
									    </Col>
										</span>
									  </Form.Group>
							</Form>
							<div className="row m-0">
								<div className="col-12 formBtns">
									<div className="pullRight">
										<Button className="btn_clr" variant="light" onClick={() => this.securityStore.securitytab = Object.assign({}, this.securityStore.backupSecuritytab)}>
											<p>{appstore.getLanguageTrans("Cancel")}</p>
										</Button>
										<Button className="btn_save" onClick={() => this.securityStore.saveSecurity()}>
											<p>{appstore.getLanguageTrans("Save")}</p>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div key={appstore.selectedWebisiteUid} className="row securityWrapper with-header">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Consent with Website Policies")}</h4>
							</div>
							<div className="contentWrapper">
								<Form>
									<Form.Group as={Row} controlId="formGrouppreChatFields">
										<Form.Label column xs="7" sm="4" lg="3">{appstore.getLanguageTrans("Enable consent for pre-chat fields")}</Form.Label>
										<Col className="custom_radioBtn">
											<CheckBoxComponent radioValue={this.securityStore.consentPolicies.enable_consent}
												callBack={() => this.securityStore.consentPolicies.enable_consent = this.securityStore.consentPolicies.enable_consent == 0 ? 1 : 0} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formGroupOfflineMsg">
										<Form.Label column xs="7" sm="4" lg="3">{appstore.getLanguageTrans("Enable consent for offline message mode")}</Form.Label>
										<Col className="custom_radioBtn">
											<CheckBoxComponent radioValue={this.securityStore.consentPolicies.enable_consent_offline}
												callBack={() => this.securityStore.consentPolicies.enable_consent_offline = this.securityStore.consentPolicies.enable_consent_offline == 0 ? 1 : 0} />
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formGroupMsg">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Message field")}<font color="red">*</font></Form.Label>
										<Col sm="8" lg="9">
											<textarea rows="2" className={"custom_input_text " + (validation.message_field.isInvalid ? 'has-error' : "") + (this.securityStore.mandatoryfields.message_field.isInvalid ? ' has-error' : "")} name="message_field" placeholder="I agree to have my personal data processed by YOUR COMPANY NAME, for support and sales purposes in compliance with"
												value={this.securityStore.consentPolicies.message_field}
												onChange={(e) => this.handleChange(e)} style={{ fontFamily: "Segoe UI Semi Bold" }} />
											<br />
											<span className="error-block">{validation.message_field.message}</span>
											<span className="error-block">{this.securityStore.mandatoryfields.message_field.message}</span>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupTitle1">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Link title field 1")}<font color="red">*</font></Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={"custom_input_text " + (validation.link_title_field1.isInvalid ? 'has-error' : "") + (this.securityStore.mandatoryfields.link_title_field1.isInvalid ? ' has-error' : "")} name="link_title_field1" placeholder="Privacy Policy"
												value={this.securityStore.consentPolicies.link_title_field1}
												onChange={(e) => this.handleChange(e)}
											/>
											<span className="error-block">{validation.link_title_field1.message}</span>
											<span className="error-block">{this.securityStore.mandatoryfields.link_title_field1.message}</span>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupConfirm">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Link field 1")}<font color="red">*</font></Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={"custom_input_text " + (validation.link_field1.isInvalid ? 'has-error' : "") + (this.securityStore.mandatoryfields.link_field1.isInvalid ? 'has-error' : "")} name="link_field1" placeholder="Enter URL of the document"
												value={this.securityStore.consentPolicies.link_field1}
												onChange={(e) => this.handleChange(e)} />
											<span className="error-block">{validation.link_field1.message}</span>
											<span className="error-block">{this.securityStore.mandatoryfields.link_field1.message}</span>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupConfirm">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Link title field 2")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={"custom_input_text " + (this.securityStore.nonmandatoryfields.link_title_field2.isInvalid ? 'has-error' : "")} name="link_title_field2" placeholder="Enter document title"
												value={this.securityStore.consentPolicies.link_title_field2}
												onChange={(e) => this.handleChange(e)} />
											<span className="error-block">{this.securityStore.nonmandatoryfields.link_title_field2.message}</span>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupConfirm">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Link field 2")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={"custom_input_text " + (this.securityStore.nonmandatoryfields.link_field2.isInvalid ? 'has-error' : "")} name="link_field2" placeholder="Enter URL of the document"
												value={this.securityStore.consentPolicies.link_field2}
												onChange={(e) => this.handleChange(e)} />
											<span className="error-block">{this.securityStore.nonmandatoryfields.link_field2.message}</span>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupConfirm">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Link title field 3")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={"custom_input_text " + (this.securityStore.nonmandatoryfields.link_title_field3.isInvalid ? 'has-error' : "")} name="link_title_field3" placeholder="Enter document title"
												value={this.securityStore.consentPolicies.link_title_field3}
												onChange={(e) => this.handleChange(e)} />
											<span className="error-block">{this.securityStore.nonmandatoryfields.link_title_field3.message}</span>
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupConfirm">
										<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Link field 3")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" name="link_field3" placeholder="Enter URL of the document"
												className={"custom_input_text " + (this.securityStore.nonmandatoryfields.link_field3.isInvalid ? 'has-error' : "")}
												value={this.securityStore.consentPolicies.link_field3}
												onChange={(e) => this.handleChange(e)} />
											<span className="error-block">{this.securityStore.nonmandatoryfields.link_field3.message}</span>
										</Col>
									</Form.Group>
									<br />
									<div style={{ background: "#fff" }}>
										<Form.Group as={Row}>
											<Col sm="12" lg="12">
												<p style={{ textAlign: "left" }}>
													<input type="checkbox" /> {" "}
													{this.securityStore.consentPolicies.message_field.trim()}{" "}
													{this.securityStore.consentPolicies.link_field1 != "" &&
														<React.Fragment>
															<a style={{ color: "#2c87f2" }} target="_blank" href={this.securityStore.consentPolicies.link_field1}>{this.securityStore.consentPolicies.link_title_field1.trim()}</a>{(this.securityStore.consentPolicies.link_field2 != "" || this.securityStore.consentPolicies.link_field3 != "") ? ", " : ""}
														</React.Fragment>
													}
													{this.securityStore.consentPolicies.link_field2 != "" &&
														<React.Fragment>
															<a style={{ color: "#2c87f2" }} target="_blank" href={this.securityStore.consentPolicies.link_field2}>{this.securityStore.consentPolicies.link_title_field2.trim()}</a>{this.securityStore.consentPolicies.link_field3 != "" ? ", " : ""}
														</React.Fragment>
													}
													{this.securityStore.consentPolicies.link_field3 != "" &&
														<React.Fragment>
															<a style={{ color: "#2c87f2" }} target="_blank" href={this.securityStore.consentPolicies.link_field3}>{this.securityStore.consentPolicies.link_title_field3.trim()}</a>
														</React.Fragment>
													}

												</p>

											</Col>
										</Form.Group>
									</div>

									<div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light" onClick={() => { this.securityStore.consentPolicies = Object.assign({}, this.securityStore.backupConsentPolicies); this.clearConsentpolicies() }}>
													<p>{appstore.getLanguageTrans("Cancel")}</p>
												</Button>
												<Button className="btn_save" onClick={() => this.handleSubmission()}>
													<p>{appstore.getLanguageTrans("Save")}</p>
												</Button>
											</div>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
				{/* {
                this.securityStore.goBack &&
                 <ConfirmationComponent show={this.securityStore.goBack}
                    store={appstore}
                    title={"Warning"}
                    body_html={appstore.isAdmin ? "File cannot be sent. You might need to clear your disc space before sending more files.</br> Go to Admin->Website->Setup->File transfer to manage your disc space.": "File cannot be sent. You might need to clear your disc space before sending more files. Please ask your account administrator to go to Admin->Website->Setup->File transfer to manage your disc space." }
					cancel_btn="Close"
					save_btn="Back"
					callback={() => this.securityStore.goBack = false}
                    closePopup={() => this.securityStore.goBack=false}
                    appStore={appstore} />
                } */}
			</div>
		)
	}
})
export default SecurityComponent;
