import React, {Component } from 'react';
import { observer } from 'mobx-react';

import navigationStore from '../../sharedFiles/navigation-store';
import CurrentChatsStore from './CurrentChatsStore';



const CurrentChatsComponent = observer(class CurrentChatsComponent extends Component {

	constructor (props) {
        super(props);
		this.currentChatsStore = new CurrentChatsStore(this.props.appstore);
		this.state={
			visitorcount:this.props.appstore.visitorList.length
		}
    }
	componentWillMount(){

	}

	componentDidUpdate(prevProps, prevState) {
        if(prevState.visitorcount !== this.props.appstore.visitorList.length) {
			this.currentChatsStore.setVisitorName( this.props.appstore)
            this.setState({ visitorcount: this.props.appstore.visitorList.length});
        }
	}
	
	OpenChat= (operator,appstore) =>{

		for(let i=0; i< appstore.visitorList.length; i++){
			if(appstore.visitorList[i].sourceStation === operator.sourceStation){
			   appstore.visitorList[i].gotNewMsg = false;
               break;
			}
		}


		this.currentChatsStore.selectedChat = operator.sourceStation;
		this.props.appstore.requestedVisitorId = operator.sourceStation;
		navigationStore.push('/chats/operator/'+operator.sourceStation);
	}



	render(){
		let self = this;
        const {appstore} = this.props;
		return (
			<div key={this.props.appstore.visitorList.length} className="operators_Wrapper currentChats">
				<h6>{appstore.getLanguageTrans("Current")}<br/> {appstore.getLanguageTrans("Chats")}</h6>
				<div className="currentChatsWrapper">
					{
						this.currentChatsStore.currentChats.map(function(operator,operatorIndex) {
							return (
								<div className={"operator_details" + (self.currentChatsStore.selectedChat === operator.sourceStation ? ' active' : '')} key={operatorIndex} onClick = {() =>self.OpenChat(operator,appstore)}>
									<p>{operator.name}
									  {(self.currentChatsStore.selectedChat !== operator.sourceStation) && operator.gotNewMsg &&
											<span className="pull-right"></span>
										}
									</p>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
})
export default CurrentChatsComponent;
