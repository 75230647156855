import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../sharedFiles/navigation-store';

import AddWebsiteComponent from './addWebsite/addWebsiteComponent';
import WidgetThumbnail from './components/widgetThumbnail';
import GetWidgetCodeComponent from './components/getCodeComponent';
import ConfirmationComponent from '../../sharedComponents/confirmationPopup/ConfirmationComponent';

import WebsiteWidgetStore from './WidgetStore';


const WebsiteWidgetComponent = observer(class WebsiteWidgetComponent extends Component {

	constructor (props) {
        super(props);
        this.widgetStore = new WebsiteWidgetStore();
		this.resetThumbnail = this.resetThumbnail.bind(this);
		this.widgetStore.tourGuideStart=false;
    }

   	componentDidMount(){
		this.props.appstore.menuChanged = window.location.pathname;
		window.addEventListener("resize", this.resetThumbnail); 
	}

	resetThumbnail(){
		this.widgetStore.thumbnailHeight = 0;
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
		window.removeEventListener("resize", this.resetThumbnail);
    }
     
    navigateTowidget= (appstore) =>{
		appstore.dptoptforwebiste();
		appstore.navigate('/admin/setup/widgets/add');
	}

	render(){

		var self = this;
		const { appstore } = this.props;
		return (
			<div  className="innercontent_wrapper w-100" key={appstore.selectedWebisiteUid} >
				<AddWebsiteComponent appstore={appstore} widgetStore={this.widgetStore} />
			{ 
			 (!appstore.addNewSite || this.props.appstore.helpTourStarted) &&		
				<div className="header_title row align-items-center m-0 widget_tour">
						<img src={imageURLs.widgetPageIcon} alt="faq" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("Widgets")}</h4>
				</div>
	        }
			{/* appstore.userInfo.allwebistes.length !=0 ||  */}
			  
			{ !appstore.addNewSite && 
				<div className="row widgetWebsiteWrapper">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box row">
						{ (!appstore.addNewSite && this.widgetStore.websiteWidgetDetails !=0) &&
								this.widgetStore.websiteWidgetDetails.map(function(widget, widgetIndex) {
									return(
										<WidgetThumbnail key={widgetIndex} appstore={appstore} widget= {widget} widgetStore={self.widgetStore} widgetIndex={widgetIndex}/>
									)
								})
						}
							<div className="widgetThumbnailWrapper col-12 col-sm-6 col-md-4">
								<div className="addnewWidget_tour widgetContent box" style={(appstore.tourcurrent_step==2 && appstore.helpTourStarted) ? {pointerEvents:"none",height: 408, width:305}: appstore.helpTourStarted ? {opacity:0.3,pointerEvents:"none",height: 408, width:305}:{height: 408, width:305}}  >
									<div className="noWrapper" onClick={() => this.navigateTowidget(appstore)}>
										<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.add_steelblue2 : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.add_seagreen2 : "#ec407a" == appstore.selectedColor.color ?  imageURLs.add_pink2 : "#6e4c42" == appstore.selectedColor.color ?imageURLs.add_brown2 : "#234e91" == appstore.selectedColor.color ?imageURLs.add_navyblue2 : "#e89712" == appstore.selectedColor.color ?imageURLs.add_yellow2 : "#b968c7" ==appstore.selectedColor.color ?imageURLs.add_voilet2 : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.add_blue2 : "#00887a" == appstore.selectedColor.color ? imageURLs.add_darkgreen2 : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.add_green2 : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.add_grey2 : "#64a9e0"== appstore.selectedColor.color ? imageURLs.add_skyblue2 : imageURLs.add_steel_blue2} alt="" className="img-fluid" />
										<p style={{color:appstore.selectedColor.color , fontFamily:"Segoe UI Bold" , fontSize:13 , fontWeight:700, textTransform:"uppercase"}}>{appstore.getLanguageTrans("Add new widget")}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	          }

			{ (appstore.addNewSite && this.props.appstore.helpTourStarted ) &&
				<div className="row widgetWebsiteWrapper" style={(appstore.tourcurrent_step==2 && appstore.helpTourStarted) ? {pointerEvents:"none"}: appstore.helpTourStarted ? {opacity:0.3,pointerEvents:"none"}:{}}>
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box row">
						
							<div className="widgetThumbnailWrapper col-12 col-sm-6 col-md-4">
								<div className="addnewWidget_tour widgetContent box" style={{height: 408, width:305}}>
									<div className="noWrapper">
										<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.add_steelblue2 : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.add_seagreen2 : "#ec407a" == appstore.selectedColor.color ?  imageURLs.add_pink2 : "#6e4c42" == appstore.selectedColor.color ?imageURLs.add_brown2 : "#234e91" == appstore.selectedColor.color ?imageURLs.add_navyblue2 : "#e89712" == appstore.selectedColor.color ?imageURLs.add_yellow2 : "#b968c7" ==appstore.selectedColor.color ?imageURLs.add_voilet2 : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.add_blue2 : "#00887a" == appstore.selectedColor.color ? imageURLs.add_darkgreen2 : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.add_green2 : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.add_grey2 : "#64a9e0"== appstore.selectedColor.color ? imageURLs.add_skyblue2 : imageURLs.add_steel_blue2} alt="" className="img-fluid" />
										<p style={{color:appstore.selectedColor.color , fontFamily:"Segoe UI Bold" , fontSize:13 , fontWeight:700, textTransform:"uppercase"}}>{appstore.getLanguageTrans("Add new widget")}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	          }
				<GetWidgetCodeComponent appStore={appstore} store={this.widgetStore} show={this.widgetStore.getCode}/>
				
				{
					this.widgetStore.confirmDelete && 
					<ConfirmationComponent show={this.widgetStore.confirmDelete}
											store={this.widgetStore}
											title="Delete Widget"
											body_html="<p>Are you sure you want to delete the widget? The operation cannot be undone. If the widget is used on any website, it will be disabled, but you need to remove the widget code from the page manually</p>"
											cancel_btn="Cancel"
											save_btn="Delete" 
											callBack={() => this.widgetStore.confirmDeleteWidget(appstore)}
											closePopup={() => this.widgetStore.confirmDelete = false}
											appStore = {appstore}/>
				}
			</div>
		)
	}
})
export default WebsiteWidgetComponent;
