import React, { Component } from 'react';
import { observer } from 'mobx-react';

import imageURLs from '../../sharedFiles/ImagesURLs';
import SideSubMenuComponent from './submenu/SubMenu';
import SideMenuStore from './SideMenuStore';

const SideMenuComponent = observer(class SideMenuComponent extends Component {

	constructor(props) {
		super(props);
		this.menuIcons = [imageURLs.chatIcon, imageURLs.trafficIcon, imageURLs.profileIcon, imageURLs.adminIcon, imageURLs.accountsIcon];
		this.sideMenuStore = new SideMenuStore(this.props.appstore);
		this.sideMenuStore.checkForSelectedMenu(this.props.appstore);
		this.prevMenu = this.props.menuChanged;
	}
	componentWillMount() {

	}

	componentDidMount(){
		// //added to remove last selected submenu 
		// this.props.sideMenuStore.submenuSelectedItem = "";
		// 	this.props.submenuData.map(function(submenu, menuIndex){
		// 		console.log(submenu.subMenuLabel)
		// 			submenu.active = false;
		// 			submenu.openCount = 0;
		// 	});
		
		// console.log("----empty-----------")
	}

	componentWillReceiveProps(nextProps) {
		if (this.prevMenu !== nextProps.menuChanged) {
			this.prevMenu = nextProps.menuChanged;
			this.sideMenuStore.checkForSelectedMenu(this.props.appstore);
		}
	}

	openMenu(menu) {
		this.props.appstore.editwebsite=false;
		//new added to remove last selected submenu 
		if(this.sideMenuStore.selectedMenu.subMenu.length !=0) {
			this.sideMenuStore.selectedMenu.subMenu.map(function(submenu, menuIndex){
				//console.log(submenu.subMenuLabel)
					submenu.active = false;
					submenu.openCount = 0;
			});

			//for unactive submenu's menu
			this.sideMenuStore.submenuSelectedItem = "";
	    }
	
		//down previous
		if (menu.url !== "") {
			this.sideMenuStore.navigate(menu, this.props.appstore);
		}
		//this.props.appstore.menuActive = ((menu.subMenu.length !== 0 || menu.menuId === 2) ) ? true : false;
		if(menu.menuId == 4 && !this.props.appstore.editwebsite){
			this.props.appstore.menuActive=false;
		}else{
			this.props.appstore.menuActive = ((menu.subMenu.length !== 0 || menu.menuId === 2)) ? true : false;
		}
		if (menu.menuId === 1 || menu.menuId === 2) {
			this.props.appstore.operatorMenuActive = true;
		}
		else {
			this.props.appstore.operatorMenuActive = false;
		}
	
		if(this.props.appstore.isOwner && menu.menuLabel==="Manage Subscription"){
			window.open(this.props.appstore.userInfo.accountsmodel.upgrade_link+"&r=home", '_blank');
		}
		this.sideMenuStore.setMenuSelected(menu);
	
	}

	showAdmin = () => {

		if (this.appstore.userInfo.hasOwnProperty('usermodel')) {
			if (this.props.appstore.userInfo.usermodel.user_type_id == 3)
				return true;
			else
				return false;
		}
		else
			return false;

	}
	filterMenu = (menuList) => {
		let appstore = this.props.appstore;

		if (appstore.isOwner) {
			if(this.props.appstore.userInfo.accountsmodel.display_buy_now==1){
				return menuList.slice(0, 4);
			}
			return menuList;
		} else if (appstore.isAdmin && !appstore.isOwner) {
			return menuList.slice(0, 4);
		} else {
			return menuList.slice(0, 3);
		}

	}

	render() {
		var self = this;
	    const vacss={
			pointerEvents:"none",
			opacity:0.8
		};
		const nocss={};
		const { appstore } = this.props;
		var menuList = this.sideMenuStore.menuData.map(function (item, index) {
			return (

				<li className={self.sideMenuStore.selectedMenu.menuId === item.menuId ? "active" : ""} key={index} onClick={() => self.openMenu(item)} style={appstore.userInfo.allwebistes.length > appstore.userInfo.accountplan.max_websites || appstore.userInfo.alloperators.length > appstore.userInfo.accountplan.max_ops && appstore.userInfo.accountplan.max_ops != 0 || appstore.userInfo.alldepartments.length > appstore.userInfo.accountplan.max_deps && appstore.userInfo.accountplan.max_deps != 0  ?vacss:nocss}>
					<img src={self.menuIcons[index]} alt="faq" className="img-fluid" />
					<p className="text-uppercase ">{appstore.getLanguageTrans(item.menuLabel)}</p>
				</li>
			)
		})

		return (
			<nav id="sidebar" className={(!appstore.menuActive ? "row active" : "row open") + ((this.sideMenuStore.selectedMenu.menuId === 2 || this.sideMenuStore.selectedMenu.menuId === 1) ? ' operator_menu' : '')} style={appstore.helpTourStarted ? {pointerEvents:"none",opacity:0.3}:{}}>
			    <ul className="sideMenu list-unstyled components">
					{self.filterMenu(menuList)}

				</ul>
			
				{
					((this.sideMenuStore.selectedMenu.subMenu.length !== 0 || this.sideMenuStore.selectedMenu.menuId === 2) && this.sideMenuStore.selectedMenu.menuId !== 0  || (this.sideMenuStore.selectedMenu.menuId == 4 && appstore.editwebsite)) &&
					<SideSubMenuComponent appstore={appstore} submenuData={this.sideMenuStore.selectedMenu.subMenu} sideMenuStore={this.sideMenuStore} />
				}
				{
					(!(this.sideMenuStore.selectedMenu.menuId === 1 || this.sideMenuStore.selectedMenu.menuId === 2 || this.sideMenuStore.selectedMenu.menuId === 0 || this.sideMenuStore.selectedMenu.subMenu.length === 0) && (this.sideMenuStore.selectedMenu.menuId == 4 && appstore.editwebsite)) &&
					<div className={appstore.menuActive ? "hideIcon row align-items-center" : "hideIcon inactive row align-items-center"} onClick={() => appstore.toggleMenu()}>
						<img src={appstore.menuActive ? imageURLs.hideInactiveIcon : imageURLs.hideActiveIcon} alt="faq" className="float-left img-fluid" />
						{
							appstore.menuActive &&
							<p className="float-left text-uppercase" style={{color: appstore.selectedColor.color+"e8" }}>{appstore.getLanguageTrans("Hide")}</p>
						}
					</div>
				}
			</nav>
		)
	}
})
export default SideMenuComponent;
