import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
 
const AnyReactComponent = ({ text }) => <div>{text}</div>;
 
class GoogleMap extends Component {
  
  constructor (props) {
    super(props);
}

renderMarkers = (map, maps) => {
  let marker = new maps.Marker({
    position: {lat:Number(this.props.coordinates.latitude) ,lng:Number(this.props.coordinates.longitude)},
    map,
    title: ''
  }); 
}
 
  render() {
    const {appstore,coordinates} = this.props;
    const geocordinates= {
      lat: Number(coordinates.latitude),
      lng: Number(coordinates.longitude)
    };

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '70vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: appstore.googleMapkey}}
          defaultCenter={geocordinates}
          defaultZoom={11}
		  onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
        >
        
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default GoogleMap;