import React, { Component } from 'react';
import { decorate, observable, extendObservable } from 'mobx';
import { data } from '../../../../assets/JSON/admin_archivechat';
import axios from 'axios';


class OperatorReportsStore {


  constructor(appstore) {
    this.responseData = data[0];
    this.confirm = false;
    this.datepicker = false;
    this.websiteDrpDwnOptions = [];
    this.allDepartments = [];
    this.allWebsites = [];
    this.chats = 0;
    this.avg_rating = 0;
    this.op_were_rated = 0;
    this.op_onlinetime=0;
    this.pageLoder = false;
    this.pageLodernew=false;
    this.operator_report_opt = [{ "label": "All Operators", "value": 0, "user_uid": "" }];;
    this.alloperators=this.operator_report_opt[0];
    this.performanceData = [];
    this.optdata=[]

    this.testtablefields = [
      { value: 1, Operaters: 'Adrea', Number_of_Charts: '0', Missed_Charts: '28', Online_Time: '8h 02M', CheckIn_Time: '2:15am', Check_Out_Time: '6:45am' },
      { value: 2, Operaters: 'Melany', Number_of_Charts: '3', Missed_Charts: '26', Online_Time: '8h 02M', CheckIn_Time: '3:15am', Check_Out_Time: '4:54am' },
      { value: 3, Operaters: 'Ivy', Number_of_Charts: '0', Missed_Charts: '24', Online_Time: '8h 02M', CheckIn_Time: '4:11am', Check_Out_Time: '4:45am' },
      { value: 4, Operaters: 'Melany', Number_of_Charts: '10', Missed_Charts: '20', Online_Time: '8h 02M', CheckIn_Time: '2:11pm', Check_Out_Time: '5:32pm' },
      { value: 5, Operaters: 'Adrea', Number_of_Charts: '3', Missed_Charts: '22', Online_Time: '8h 02M', CheckIn_Time: '5:55pm', Check_Out_Time: '7:23pm' },
      { value: 6, Operaters: 'Ivy', Number_of_Charts: '8', Missed_Charts: '12', Online_Time: '8h 02M', CheckIn_Time: '5:55pm', Check_Out_Time: '7:23pm' }
    ];
  }


  setWebsites(websitesmodel, appstore) {
    let websites = JSON.parse(JSON.stringify(websitesmodel));
    this.pageLoder = false;

    if (websites != null) {

      let all = { "label": "All", "value": 0, "id": 0, "optionType": "websiteAll", "website_uid": "" };
      websites.splice(0, 0, all);   //insert All option in dropdown options
      let x;
      let index = 0;
      for (x in websites) {
        if (x > 0) {
          websites[x].label = websites[x].name;
          websites[x].value = index;
        }
        index++;
      }

      this.websiteDrpDwnOptions = websites;
      this.allwebsite = this.websiteDrpDwnOptions[0]
     
      setTimeout(()=>{
        this.pageLoder = true;
      },500)
    }
  }
  setOperators(operatorsmodel,appstore){
    let operators = JSON.parse(JSON.stringify(operatorsmodel));;
    if(operators !=null){

      let all = {"label":"All Operators","value":0,"user_uid": ""};
      operators.splice(0, 0,all);   //insert All option in dropdown options  
      let i;
      let index =0;
      for (i in operators)
      {
        if(i>0){ 
          operators[i].label=operators[i].nick_name;
          operators[i].value = operators[i].user_uid;
          operators[i].user_uid = operators[i].user_uid;
        }
        index++;
      }
      this.operator_report_opt=[];
      this.operator_report_opt = operators;
      this.alloperators = this.operator_report_opt[0];
    }
   }


}

decorate(OperatorReportsStore, {
  datepicker: observable,
  websiteDrpDwnOptions: observable,
  operatorDrpDwnOptions: observable,
  allwebsite: observable,
  isPageLoaded: observable,
  responseData: observable,
  charts: observable,
  pageLoder: observable,
  operator_report_opt: observable,
  optdata: observable,
  performanceData: observable,
  exportdata: observable,
  export: observable,
  alloperators:observable,
  avg_rating:observable,
  op_were_rated:observable,
  op_onlinetime:observable,
  pageLodernew:observable
})


export default OperatorReportsStore;