import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import axios from 'axios';
const CustomerFileComponent = observer(class CustomerFileComponent extends Component {

	constructor(props) {
		super(props);
		this.state={
			life:this.props.chatComponentStore.fileLife[0]
		}
	}
	componentDidMount() {
		if (this.props.fullData.cannedType == false) {
			//this.props.chatComponentStore.filelifeupdate(this.props.appstore, this.props.filePath, this.props.fileData.life, this.props.talkSessionUid);
			this.checkfilelife();
		}
		
	}

	checkfilelife = () => {
		var fileid = this.props.filePath.split("/");
		var filename = fileid[7].split(".");
		let dataObj = { "resourceUid": filename[0]};
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getchatfilelife.json',
			headers: { 'content-type': 'application/json' },
			data: dataObj
		}).then(result => {
			if(result.data==1){ 
				this.setState({life: this.props.chatComponentStore.fileLife[1]});
			}else{
				this.setState({life: this.props.chatComponentStore.fileLife[0]});
			}
		}).catch(error => {
		})
	}

	componentWillUnmount() {
	}

	render() {
		const { appstore,
			chatComponentStore,
			fileData,
			name,
			time,
			fileLife,
			filePath,
			chatData,
			originalFileName,
			talkSessionUid, fullData, file_Size } = this.props;
		var self = this;

		return (
			<div className="chatSection customerChat col-12 p-0">
				<p className="chatBy text-right"><span>{time}</span> {name}</p>
				<div className="chatText chatFile float-right">
					<div className="row">
						<div className="fileImg" style={{ backgroundColor: "#fff" }}>
							<img src={appstore.getimageurl(filePath, "chat")} className="img-fluid" />
						</div>
						<div className="col fileDetails">
							<div className="row">
								<p className="col-12 fileDet">{originalFileName}
									<span>{file_Size}</span>
									<span className="cursor-pointer" onClick={(e) => { chatComponentStore.downloadingfile(appstore, filePath, originalFileName) }} ><img src={imageURLs.chat_img_download} className="img-fluid" /></span>
								</p>
							</div>
							{!fullData.cannedType &&
								<div className="row">

									<div className="col-12 customDrpDwn">
										<span>Lifetime:</span>
										<DropDownComponent dropDownOptions={chatComponentStore.fileLife}
											key={this.state.life.value}
											store={chatComponentStore}
											callBack={(selectedOption) => { this.state.life = selectedOption; chatComponentStore.filelifeupdate(appstore, filePath, selectedOption, talkSessionUid) }}
											selectedOption={this.state.life} />
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
})
export default CustomerFileComponent;
