import React, {Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button} from 'react-bootstrap';
import DropDownComponent from '../../sharedComponents/dropDown/dropDown';
import imageURLs from '../../sharedFiles/ImagesURLs';
import ConfirmationComponent from '../../sharedComponents/confirmationPopup/ConfirmationComponent';
const DropdownModel = observer(class DropdownModel extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}
    
    setOption =(options) =>{
        const users = JSON.parse(JSON.stringify(options));
          for(let i in users){
            users[i].value = users[i].user_uid;
            users[i].label = <p>{users[i].nick_name} <Button style={{float:"right",padding: 1}} className="btn_save existing_opcreate"  type="button" variant="">Add</Button></p>
          }
          return users;
    }

    maxOpCrossed= (appStore) =>{
		//setting 5 temporary bcos pojo object is not setting value of max_ops
   const alloperator = this.props.appStore.userInfo.alloperators;
   const account = this.props.appStore.userInfo.accountsmodel;
   const accountplan = this.props.appStore.userInfo.accountplan;

	 if(alloperator.length == 0)
    return false;
	 else if(alloperator.length >= accountplan.max_ops && accountplan.max_ops !=0)
		return true;
	 else
	    return false;		
    }

    getmaxOpContent =(appStore) =>{
        if(!appStore.isOwner){
            return "<p>Your plan includes "+appStore.userInfo.accountplan.max_ops+" operators. If you would like to add more operators to your account, please ask "+this.props.appStore.userInfo.usersmodel.ownerRealname+", the account owner, to upgrade the account.</p>"
        }else{
            return "<p>Your plan includes "+appStore.userInfo.accountplan.max_ops+" operators. If you would like to set up more operators, you have two options:</p> <ul> <li> Upgrade to the Platinum plan, which includes Unlimited operators.</li> <li> Add more operators to your plan for an extra fee.</li> </ul> <p>In both cases you will start a new subscription period. Unused funds will be pro-rated.</p>"
        }
    }

    close= () =>{
        this.props.store.addOperator = false;
        this.props.store.showExisting = false;
        this.props.store.operatorEditing = false;
      
    }
 
    createNewOp =() =>{
      this.props.store.showExisting = false ;
      this.props.store.notExistingUserPassword=false;
    }

	render(){
		const {show, 
			   store,
				title,
				callBack,
                options,
                btnName,
				appStore,
                singleButton} = this.props;
        const alloptions = this.setOption(options)
				//console.log(this.props.callBack());
				
		return (
			<Modal backdrop='static' className={appStore ? appStore.selectedColor.value : ''} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>this.close()}>
				  <Modal.Header closeButton>
				    <Modal.Title>{appStore.getLanguageTrans(title)}</Modal.Title>
				  </Modal.Header>

				  <Modal.Body >
                      <div>
                          {store.existingOps.length !=0 && 
                            <p> {appStore.getLanguageTrans('To add an existing operator, select the operator from the list, and click "Add".')}</p>}
                          {store.existingOps.length ==0 && 
                            <p> {appStore.getLanguageTrans("All existing operators are used in this website.")}</p>}

                            <DropDownComponent dropDownOptions={alloptions}
                                            store={store}
                                            callBack={(selectedOption) =>  store.addExistingOp(selectedOption)}
                                            selectedOption = {""}/>

                        <React.Fragment>
                        <br></br>
                          <p> {appStore.getLanguageTrans('To add a new operator, click "Create new operator".')} </p>
                          {
                           !this.maxOpCrossed() &&
                            <Button className="custom_btn"  type="primary" variant="primary" onClick={() =>this.createNewOp()}>
                                {appStore.getLanguageTrans("Create new operator")}
                            </Button>
				                  }
                          {
                            this.maxOpCrossed() &&
                            <Button className="custom_btn"  type="primary" variant="primary" onClick={() => store.payDollar = true}>
                                {appStore.getLanguageTrans("Create new operator $")}
                            </Button>
                          } 
                        </React.Fragment>

       {
					store.payDollar && 
				  	<ConfirmationComponent show={store.payDollar}
											store={store}
											title={"Upgrade Plan"}
											body_html={this.getmaxOpContent(appStore)}
											cancel_btn={appStore.isOwner ? "Cancel" : "Okay"}
											save_btn="Upgrade"
											callBack={() => {store.openUpgrade(appStore);store.payDollar=false}}
											closePopup={() =>store.payDollar=false }
											appStore = {appStore}
											singleButton={!appStore.isOwner}
											/>

				}
                    </div>
				  </Modal.Body>

				  <Modal.Footer>
				  
				  </Modal.Footer>                      
				</Modal>
		)
	}
})
export default DropdownModel;
