import React, {Component } from 'react';
import { observer } from 'mobx-react';
import Select, { components } from 'react-select';

import imageURLs from '../../../../sharedFiles/ImagesURLs';


const ResponseDropDownComponent = observer(class ResponseDropDownComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){
	}

	getDropDownData () {  
		const options = [];  
		options.push(this.mapToData("Basic Actions",this.props.dropDownOptions.basicActions.chatTypes,1));
		if(this.props.dropDownOptions.customResponse.chatTypes.length > 0) { 
			options.push(this.mapToData("Custom Response",this.props.dropDownOptions.customResponse.chatTypes,2));
		}
		// else{
		// 	options.push('');
		// }
		if(this.props.dropDownOptions.relatedLinks.chatTypes.length > 0) {
			options.push(this.mapToData("Related Links",this.props.dropDownOptions.relatedLinks.chatTypes,3));
		}
		return options;
	}

	mapToData(label,objectData,type){
		let groupObj = {};
		groupObj.label = label;
		groupObj.options = [];
		objectData.map(function(option,optionIndex){  
			let obj = {};
			obj.value = option.id;
			obj.label = optionIndex+1+'. '+option.title;
			obj.type = type;
			obj.related_id = option.related_uid; 
			groupObj.options.push(obj);
		});
		// console.log(groupObj);

		return groupObj;
	}
	getSelectedOption=(selected,selectOptions) =>{
		let x;
		for(let i in selectOptions){ 
		x=	selectOptions[i].options.find(option => option.related_id === selected.relatedids)
		if(x){
			break;
		}
		}
	return x;
	}
	
	render(){
		const { dropDownOptions,
				store,
				selectedOption } = this.props;
		const selectOptions = this.getDropDownData();
		return (
			<Select className="text-left"
			value={selectedOption && selectedOption.typeid ? this.getSelectedOption(selectedOption,selectOptions) : ""}
				key={Math.random()} 
				options={selectOptions}
				onChange={(event) => this.props.callBack(event)  }
		      />
		)
	}
})
export default ResponseDropDownComponent;
