import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { EditorState, convertToRaw, ContentState,getDefaultKeyBinding,KeyBindingUtil } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CustomOptions from './CustomOptions';
import { event } from 'jquery';
const keyBindingFn = (event) => { 
	if (event.ctrlKey && event.keyCode == 13) {
		 console.log('Ctrl+Enter');
	} 
	if(KeyBindingUtil.isCtrlKeyCommand(event)){
		console.log("S");
		console.log(event.keyCode);
	} 
	
	return getDefaultKeyBinding(event);
  };
const TextEditorComponent = observer(class TextEditorComponent extends Component {

	constructor (props) {
        super(props);
		this.setDomEditorRef = ref => this.domEditor = ref;
        this.state={
        	spellCheck: true
        };
        this.setSpellCheck = this.setSpellCheck.bind(this);
        this.getCoBrowsePermission = this.getCoBrowsePermission.bind(this);
        this.print = this.print.bind(this);
        this.exitSession = this.exitSession.bind(this); 
    }
	componentDidMount(){
		const contentBlock = htmlToDraft(this.props.editorContent);

	    if (contentBlock) {
	      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
	   
	      this.props.store.editorState = EditorState.createWithContent(contentState);
	    }
	    else {
        	this.props.store.editorState = EditorState.createEmpty();
	    }
		//this.domEditor.focusEditor();
		try{
		this.props.store.domEditor.focusEditor();
		}catch(error){
		}
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.editorContent !== this.props.editorContent && !this.props.store.editing) {
			const contentBlock = htmlToDraft(nextProps.editorContent);
		    if (contentBlock) {
		      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		      nextProps.store.editorState = EditorState.createWithContent(contentState);
		    }
		    else {
	        	nextProps.store.editorState = EditorState.createEmpty();
		    }
			try{
			this.props.store.domEditor.focusEditor();
			}catch(error){
			}
			//this.domEditor.focusEditor();
		}
	}

	setSpellCheck() {
		let prevValue = this.state.spellCheck;
		this.setState({
			spellCheck: !prevValue
		});
		let self = this;
		setTimeout(function(){
			self.props.store.onEditorStateChange(self.props.store.editorState)
				
		},1000);
		
	}
 
// 	keyBindingFn = (event) => {
// 		console.log(event);
// 		console.log(event.keyCode);
// 		console.log(event.key);
// 		console.log(event.modifiers);
// 		console.log(event.shiftKey);
// 		console.log(event.which);
// 		console.log(KeyBindingUtil.hasCommandModifier(event));
// 		if(KeyBindingUtil.hasCommandModifier(event) && event.keyCode==16){
// //console.log("09876");
// 		}
// 		if(event.keyCode==13){
// 			if (event.shiftKey){
// // console.log("8976898sd");
// 			}
// 		}
// 		if(event.keyCode == 16 && event.shiftKey){
			 
// 				// console.log("shift+enter");
		 
// 			event.preventDefault();
// 		}
// 		if (event.ctrlKey && event.keyCode == 13) {
// 			// console.log("cntrl+enter");
// 			event.preventDefault();
// 		}
 
// 		// we press CTRL + K => return 'bbbold'
// 		// we use hasCommandModifier instead of checking for CTRL keyCode because different OSs have different command keys
// 		// if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 75) {
// 		// 	console.log("--");
// 		// 	}
// 		// manages usual things, like:
// 		// Ctrl+Z => return 'undo'
// 		//return getDefaultKeyBinding(event);
// 	  } 

	getCoBrowsePermission() {

	}

	print() {

	}

	exitSession() {

	}

	render(){
		const { store,
				editorContent } = this.props;
		return (
			<Editor
				  editorState={store.editorState}
				  //ref={this.setDomEditorRef}
				  ref={store.setDomEditorRef}
				  toolbarClassName="editorToolBar"
				  wrapperClassName="editorWrapper"
				  editorClassName="customEditor"
				  spellCheck={this.state.spellCheck}
				  placeholder="Type your message here"
				  onChange={store.displayTyping(this.props.appstore)} 				  
				//   keyBindingFn={keyBindingFn}				  
				  toolbarCustomButtons={[<CustomOptions spellCheckCallBack={this.setSpellCheck} 
														appstore={this.props.appstore}
														store={store}
				  										coBrowseCallBack={this.getCoBrowsePermission}
				  										printCallBack = {this.print}
				  										exitSessionCallBack = {this.exitSession}
														  fileinputref={this.props.fileinputref}  
														  onselectfile={this.props.onselectfile}  
														  onselectChatprint={this.props.onselectChatprint}
														  />]}
				  toolbar={{
						    options: ['inline', 'emoji'],
						    inline: {
						    		className: "editorInline",
								    options: ['bold', 'italic', 'underline'],
								    
  									},
  							
  							emoji: {
  								className: "editorEmoji"
  							},
  							spellCheck: {
  								className: "editorEmoji"
  							}
  
						  }}
				  onEditorStateChange={(editorState) => store.onEditorStateChange(editorState)}
				/>
				
		)
	}
})
export default TextEditorComponent;
