import { decorate, observable, extendObservable } from 'mobx';
import imageURLs from '../../sharedFiles/ImagesURLs';
import draftToHtml from 'draftjs-to-html';
import {  convertToRaw, ContentState } from 'draft-js';
import timezonedata from '../../assets/JSON/timeZone.json';
import axios from 'axios';
class ProfileStore {

  	constructor(){ 
          this.newKey=Math.random();
          
            this.pageisLoaded=false;
            this.timeNotValid=false;
           this.countryCode = [
             {value: 1, label: '  USA (+1)'},
             {value: 2, label: '  Canada (+1)'},
             {value: 3, label: '  United Kingdom (+44)'},
             {value: 4, label: '  Finland (+358)'},
             {value: 5, label: '  Australia (+61)'},
             {value: 6, label: '  Germany (+49)'},
             {value: 7, label: '  Denmark (+45)'},
             {value: 8, label: '  Sweden (+46)'},
             {value: 9, label: '  Netherlands (+31)'},
             {value: 10, label: '  Italy (+39)'},
             {value: 11, label: '  South Africa (+27)'},
             {value: 12, label: '  Spain (+34)'},
             {value: 13, label: '  France (+33)'},
             {value: 14, label: '  Israel (+972)'},
             {value: 0, label: '  Other (type full number)'}
           ];

           this.loopCount=4; 
           this.timeZones =timezonedata.data;
      this.saveinProcess=false;
      this.logoffTimeNotValid=false;
      this.saveProfileLoading=false;
      this.chat_request = [
          {index:0, label:'None', value:0},
          {index:1, label:'Package 1 sound', value:imageURLs.chat_request1},
          {index:2, label:'Package 2 sound', value:imageURLs.chat_request2},
          {index:3, label:'Package 3 sound', value:imageURLs.chat_request3},
          {index:4, label:'Package 4 sound', value:imageURLs.chat_request4},
          {index:5, label:'Package 5 sound', value:imageURLs.chat_request5},
          {index:'legacy', label:'Default Sound', value:imageURLs.chat_request_default_sound}, 
        ];
        this.message_received = [
           {index:0, label:'None', value:0},
           {index:1, label:'Package 1 sound', value:imageURLs.msg_received_sound1},
          {index:2, label:'Package 2 sound', value:imageURLs.msg_received_sound2},
          {index:3, label:'Package 3 sound', value:imageURLs.msg_received_sound3},
          {index:4, label:'Package 4 sound', value:imageURLs.msg_received_sound4},
          {index:5, label:'Package 5 sound', value:imageURLs.msg_received_sound15},
         {index:'legacy', label:'Default sound', value:imageURLs.msg_received_default_sound},
          
        ];
        this.message_sent = [
          {index:0, label:'None', value:0},
          {index:1, label:'Package 1 sound', value:imageURLs.msg_sent_sound1},
          {index:2, label:'Package 2 sound', value:imageURLs.msg_sent_sound2},
          {index:3, label:'Package 3 sound', value:imageURLs.msg_sent_sound3},
          {index:4, label:'Package 4 sound', value:imageURLs.msg_sent_sound4},
          {index:5, label:'Package 5 sound', value:imageURLs.msg_sent_sound5}, 
        ];
        this.new_visitor = [
          {index:0, label:'None', value:0},
          {index:1, label:'Package 1 sound', value:imageURLs.msg_received_sound1},
          {index:2, label:'Package 2 sound', value:imageURLs.msg_received_sound2},
          {index:3, label:'Package 3 sound', value:imageURLs.msg_received_sound3},
          {index:4, label:'Package 4 sound', value:imageURLs.msg_received_sound4},
          {index:5, label:'Package 5 sound', value:imageURLs.msg_received_sound5},
          {index:'legacy', label:'Default sound', value:imageURLs.new_visitor_default_sound},
        ];
        
      this.overrideSounds=false;
  		this.soundSelections = [
  			{soundType:'chat_request', label: 'Chat request', selectedSound: this.chat_request[1], options:this.chat_request},
  			{soundType:'message_received', label: 'Message received', selectedSound: this.message_received[1], options:this.message_received},
  			{soundType:'message_sent', label: 'Message sent', selectedSound: this.message_sent[1], options:this.message_sent},
  			{soundType:'new_visitor', label: 'New visitor on monitor', selectedSound: this.new_visitor[1], options:this.new_visitor}
  		];
  
  		this.invitation_msgs = [
                  {value: 'default', label: 'Default'},
                  {value: 'custom', label: 'Custom'},
        ];
      this.message_action = this.invitation_msgs[0].value;

       
       this.weekDays = [
          {label: 'Sunday', selected: true, startTime: "", EndTime: ""},
          {label: 'Monday', selected: true, startTime: "", EndTime: ""},
          {label: 'Tuesday', selected: true, startTime: "", EndTime: ""},
          {label: 'Wednesday', selected: true, startTime: "", EndTime: ""},
          {label: 'Thursday', selected: true, startTime: "", EndTime: ""},
          {label: 'Friday', selected: true, startTime: "", EndTime: ""},
          {label: 'Saturday', selected: true, startTime: "", EndTime: ""},
        ];
       
        this.changingPassword=true;
        this.emailAlreadyExist=false;

        this.departments = [
        	{deptName : "",
        	 website : [""]}	,
        	 {deptName : "",
        	 website : [""]}	
        ];

    this.personal = {
      nick_name: "",
      real_name: "",
      email: "",
      pass: "",
      pass2: "",
      localTimeZone: this.getOffset(),
      auto_logoff: "",
      autoLogOffDisabled: true,
      lang: "",
      custom_message: "",
      file: "",
      image: "",
      selectedfile:"", 
      autoLogoffChanged:false,
      auto_logoffchanged:""
    }
    this.deleteImg=false; 
        this.smsChats = {
          receiveChats: false,
          phoneCode: this.countryCode[0],
          phoneNumber: "",
          timeZone: this.timeZones[0],
          sms_phone_confirmed:0
        }

        this.chats = 0;
        this.rating = 0;
        
       this.invalidPass=false;

    if (this.personal.logoff === "") {
      this.personal.logoff = "00:00";
    }
    this.cropimage = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    }
    this.imageCropModel = false;
    this.imageRef = null;
    this.imagewarning = false;
    this.warning_image_name= "";
    this.confiem_phone_saved_or_not="";
    this.confirm_code_saved_or_not="";
    this.confirmphone_model=false;
    this.sms_phone_changing=false;
    this.confirmphone_error="";
    this.bal_sms_count=0;
    this.confirmcode="";
    this.confirmcode_error="";
    this.nosmsprovider=false;
    this.isAdmin=false;
    this.custom_message_isvalid=true;
     }

     
     onEditorStateChange(editorState) {
      this.editorState = editorState;
      this.personal.custom_message = draftToHtml(convertToRaw(this.editorState.getCurrentContent()));
      this.editing = true;

    }

     getOffset() {
      let date = new Date().toString();
			let arr = date.split(" ");
			return arr[5].slice(0,6)+":"+arr[5].slice(6,8);
     }

     
     confirmPass(event) {

      if(event.target.name=="pass" && this.personal.pass2==this.personal.pass){
        this.invalidPass=false;
       }

       if(this.personal.pass2==this.personal.pass){
        this.invalidPass=false;
        }else{
          this.invalidPass = true;
        }
     } 
     
    
    

 }

 decorate(ProfileStore, {
     mode: observable,
     overrideSounds: observable,
     soundSelections: observable,
     message_action: observable,
     weekDays: observable,
     chats: observable,
     rating: observable,
     soundPlaying: observable,
     personal: observable,
     smsChats: observable,
     loopCount: observable,
     custom_message:observable,
     invalidPass:observable,
     departments:observable,
     changingPassword:observable,
     timeNotValid:observable,
     emailAlreadyExist:observable,
     saveProfileLoading:observable,
     logoffTimeNotValid:observable,
     chat_request:observable,
     message_received:observable,
     message_sent:observable,
     new_visitor:observable,
     pageisLoaded:observable,
     saveinProcess:observable,
     backupSoundSelections:observable,
     soundcloned:observable,
     clonedSchedule:observable,
     cropimage: observable,
    imageCropModel: observable,
    imageRef: observable,
    deleteImg:observable,
    imagewarning:observable,
    warning_image_name:observable,
    editorState:observable,
    autoLogOffDisabled:observable,
    updateCustomMsg:observable,
    confiem_phone_saved_or_not:observable,
    confirmphone_model:observable,
    bal_sms_count:observable,
    confirmcode:observable,
    confirmcode_error:observable,
    confirmphone_error:observable,
    sms_phone_changing:observable,
    confirm_code_saved_or_not:observable,
    nosmsprovider:observable,
    isAdmin:observable,
    custom_message_isvalid:observable,
})
export default ProfileStore;