import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Router, Route, Switch } from "react-router-dom";
import navigationStore from '../sharedFiles/navigation-store';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import HomeComponent from '../components/homeComponent/HomeComponent';


const HomepageLayout = observer(class HomepageLayout extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }


  componentWillReceiveProps(nextProps){
  }

  /**
   * @param route
   */
  render() {
	  const { appstore } = this.props;
	  
	  return (    
		      <Router history={navigationStore.history}>
		        <Switch>
		          <Route exact path="/home" render={()=><HomeComponent appstore={appstore}/>}/>
		        </Switch>
		      </Router>
		    )
  }
})

export default HomepageLayout;
