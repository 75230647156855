import React, {Component } from 'react';
import { observer } from 'mobx-react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import imageURLs from '../../sharedFiles/ImagesURLs';

const RadioButtonComponent = observer(class RadioButtonComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}

  	buttonClicked(){
  		if(this.props.store && this.props.store.checkParentClick){
  			this.props.store.preventParentClick();
  		}
  	}


	render(){
		const { store,
			   options,
			   selectedOption,
			   appstore,
			   groupName } = this.props;
		return (
			<ToggleButtonGroup type="radio" name={groupName} value={selectedOption} 
								className="align-self-center"
								onChange={(event) => this.props.callBack(event)}>
				{ options &&
					options.map(function(radioBtn, radioBtnIndex) {
						return (
							<ToggleButton key={radioBtnIndex} className="custom_radionBtn" value={radioBtn.value}>
								<span className="customRadioOption"></span>
								{appstore ? appstore.getLanguageTrans(radioBtn.label) : radioBtn.label}
							</ToggleButton>
						)
					})
				}
			</ToggleButtonGroup>
		)
	}
})
export default RadioButtonComponent;
