import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const value = 29;
const SessionOverPopup = observer(class SessionOverPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pass:'',
            errorMsg:'',
            invalidLogin:false,
            failed:false,
            value:30,
        }
    }
    componentWillMount() {

    }

    componentDidMount() {
//this.props.isAutoLoggedOff
       if(this.props.appStore.isAutoLoggedOff){ 
            this.props.appStore.autoLoggedOffCounter = setInterval(()=>{
                if(this.state.value==1){
                    clearInterval(this.props.appStore.autoLoggedOffCounter);
                    this.props.appStore.logout();
                }
                this.setState({value: (this.state.value - 1)})
            },1000)
      }
    }

    stopAutoLoggedOff =() =>{
         this.props.appStore.showSessionOverPopup = false;
         this.props.appStore.isAutoLoggedOff=false;
         clearInterval(this.props.appStore.autoLoggedOffCounter)
    }

    handleChange = (e) =>{
      e.preventDefault();
      this.setState({pass:e.target.value});
    }
    
    handleEnterClick =(e)=> {
       if (e.keyCode == 13){
         this.handleSessionLogin(this.props.appStore)
       }
    }

    getErrorMsg = () =>{
     if(this.state.invalidLogin)
       this.setState({errorMsg:"Password is incorrect"});

       else if(this.state.pass.trim() == "")
       this.setState({errorMsg:'Field can not be empty'});

       else
        this.setState({errorMsg:'something went wrong !'})
    }

    handleSessionLogin = (appstore) =>{

        if (this.state.pass.trim() == "") {
             this.getErrorMsg();
             return;
           }
           appstore.isloggedout = true;
            this.setState({invalidLogin:false});
            this.setState({failed:false});

           const data = {
                email:localStorage.getItem("email"),
                pass:this.state.pass,
                lang:'en',
                time_zone_offset:new Date().getTimezoneOffset()
            }
            axios({
                method: 'POST',
                url: appstore.url+'loginuser.json',
                headers: { 'content-type': 'application/json' },
                data: data,
              }).then(result=> {
                            
                             if(result.data.usersmodel!=null)
                                 {
                                   // store in localstorage and use while page refresh
                                    //   localStorage.setItem("email",this.user.email);
                                    //   localStorage.setItem("color",result.data.usersmodel.color);
     
                                    appstore.userInfo=result.data;
                                    appstore.setUserStatus_Topics();
                                    appstore.localLanguage=JSON.parse(result.data.language);
                                   appstore.colorVal=result.data.usersmodel.color;
                                                                 
                                   
                                    appstore.getColor();
                                     appstore.showSessionOverPopup = false;

                                 }
                            else
                                {   
                                    this.setState({invalidLogin:true},()=> this.getErrorMsg());
                                    //appstore.userInfo=result.data.errorMsg;
                                    
                                } 
                                appstore.isloggedout=false; 
      
                }).catch((error) => {
                                    if (error.response) { 
                                        this.setState({failed:true},()=> this.getErrorMsg());
                                     }
                                });
            }
        

         goToLogin =(appstore) =>{
            localStorage.clear();
            //sessionStorage.clear();
            window.location.href=appstore.url;
            // this.islogged = false;
            //   this.isAdmin = false;
            //  // this.navigate('/'); 
             
            appstore.userInfo = "";
        
            localStorage.setItem("soundOnorOff",1);
         }   
//Auto logging out in 11 seconds
//Stay Logged in

    render() {
        const { show,
            store,
            title,
            callBack,
            body_html,
            cancel_btn,
            save_btn,
            appStore,
            singleButton,
            logoutCss,
            from,
            closeButtonRequired,isAutoLoggedOff } = this.props;
        return (
            <Modal backdrop='static' className={(appStore ? appStore.selectedColor.value : '') + " " + (logoutCss ? logoutCss : "")} show={show} aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header >
                    <Modal.Title>{isAutoLoggedOff ? "Auto logging out in "+this.state.value+" seconds" : appStore.getLanguageTrans(title)}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                { isAutoLoggedOff &&
                    <div style={{margin:"auto",width:"40%"}}>
                        <CircularProgressbar strokeWidth={8}  circleRatio={1} value={this.state.value}  minValue={0} maxValue={30} text={`${this.state.value}sec`} />
                    </div>  
                 }    
                {  !isAutoLoggedOff &&
                    <Form>
                        <Form.Group as={Row} controlId="formGroupNickName"> 
                          
                            <Col sm="10" lg="10">
                                <Form.Control  placeholder={appStore.getLanguageTrans("Enter your password")}
                                    className={this.state.errorMsg ? 'has-error' :''}
                                    name="pass"
                                    type="password"
                                    onKeyDown = {(e) => this.handleEnterClick(e)}
                                    value={this.state.pass}
                                    onChange={(e) => this.handleChange(e)}
                                    maxlength="30" />
                                 <span className="error-block">{appStore.getLanguageTrans(this.state.errorMsg)}</span>
                            </Col> 
                        </Form.Group>
                    </Form>
                }

                </Modal.Body>

                <Modal.Footer>
                    { !isAutoLoggedOff &&
                    <React.Fragment>
                        <div id="goTologin">
                            <p className="" onClick={() => this.goToLogin(appStore)} >Log in to another account</p>

                        </div>
                            <div className="pullRight">
                                <Button className="btn_save" type="button" variant="primary" onClick={() => this.handleSessionLogin(appStore)}>{appStore.getLanguageTrans(save_btn)}</Button>
                            </div>
                     </React.Fragment> 
                    }
                    { isAutoLoggedOff &&
                        <div className="pullRight">
                          <Button className="btn_save" type="button" variant="primary" onClick={() => this.stopAutoLoggedOff()}>{appStore.getLanguageTrans("Stay Logged In")}</Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
})
export default SessionOverPopup;
