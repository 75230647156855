import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink } from "react-csv";


const invitationsCSV = observer(class invitationsCSV extends Component {

  constructor(props) {
    super(props);
  }
  componentWillMount() {

  }
  invexport(data) {
    let exportdata = []
    let inv_date=0;
    let inv_a_date=0
    let prev_manualcount=0;
    let prev_autocount=0;
    if(data == null || !data){
      return exportdata;
    }
    for (let j = 0; j < data.length; j++) {

      if (data[j].type === "invitations") {
        if (inv_date=== data[j].quarter_date) {
          prev_manualcount= Number(prev_manualcount) + Number(data[j].cnt);
         exportdata[exportdata.length-1].manualcount = prev_manualcount ;
       }
       else{
        data[j].Date=data[j].quarter_date;
        data[j].manualcount = data[j].cnt;
        data[j].autocount = 0;
        inv_date=data[j].quarter_date;
        prev_manualcount=data[j].cnt;
        exportdata.push(data[j])
       }
        
      }
      if (data[j].type === "auto_invitations") {
        if (inv_a_date=== data[j].quarter_date) {
          prev_autocount= Number(prev_autocount) + Number(data[j].cnt);
         exportdata[exportdata.length-1].autocount = prev_autocount ;
       }
       else{
        data[j].Date=data[j].quarter_date;
        data[j].autocount = data[j].cnt
        data[j].manualcount = 0;
        inv_a_date=data[j].quarter_date;
        prev_autocount=data[j].cnt;
        exportdata.push(data[j])
       }
      }
    }
    return exportdata;
  }

  render() {
    let headers = [
      { label: "Date", key: "Date" },
      { label: "Auto Invitations", key: "autocount" },
      { label: "Manual Invitations", key: "manualcount" },

    ];

let exportdata = this.invexport(this.props.data.invitationRecords);
const {appStore}=this.props;
    return (
      <CSVLink data={exportdata} headers={headers} target="_blank" filename={"Number_Of_Invitations_"+this.props.dateselected+".csv"} >
       {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
    );
  }
});

export default invitationsCSV;
