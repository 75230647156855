import React, { Component  } from 'react';
import { observer } from 'mobx-react';
import { Form,Row, Col, Button } from 'react-bootstrap';
import imageURLs from '../../sharedFiles/ImagesURLs';
import RadioButtonComponent from '../../sharedComponents/radionButton2/RadioButton';
import CheckBoxComponent from '../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../sharedComponents/dropDown/dropDown';
// import navigationStore from '../../sharedFiles/navigation-store';
import FormValidator from '../../sharedFiles/FormValidator';
import ProfileStore from './ProfileStore';
import axios from 'axios';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
// import Upload from './upload';
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import '../../library/custom-image-crop.css';
import ImageCropComponent from '../../sharedComponents/imageCropPopUp/ImageCropComponent';
import TextEditor from '../../sharedComponents/textEditor/textEditor';
import ImageWarningPopUp from '../../sharedComponents/imageCropPopUp/ImageWarningPopUp';
// import CheckBoxCustomComponent from '../../sharedComponents/checkBox_2/checkBoxCustom2';
import Confirm_phone from '../../sharedComponents/api_conformations/Confirm_phone';  
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);
 
const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 42%; 
opacity: 0.6 ;
position: relative`

const ProfileComponent = observer(class ProfileComponent extends Component {

	constructor(props) {
		super(props);
		this.profileStore = new ProfileStore();
		this.soundRef = React.createRef();
		this.updateProperty = this.updateProperty.bind(this);
		this.fileInputRef = React.createRef();
		this.validator = new FormValidator([
			{
				field: 'nick_name',
				method: 'isEmpty',
				validWhen: false,
				message: 'Nick name is required.'
			},
			{
				field: 'real_name',
				method: 'isEmpty',
				validWhen: false,
				message: 'Real name is required.'
			},
			{
				field: 'email',
				method: 'isEmpty',
				validWhen: false,
				message: 'Email is required.'
			},
			{
				field: 'email',
				method: 'isEmail',
				validWhen: true,
				message: 'Please enter a valid email.'
			}

		]);

		this.state = {
			validation: this.validator.valid(),
			uploading: false,
			images: "",
			actual_file: "",
		}
		this.submitted = false;
	}


	componentDidMount() {
		this.props.appstore.menuChanged = window.location.pathname;
		this.getUserProfile();
		this.getUserPerformance(); 
		
		
	} 
	storeprocedure_Op_dummywebsites = (appstore) => {
		axios({
			method: 'GET',
			url: appstore.url+'storeprocedure_op_dummywebsites.json',
			headers: {'content-type': 'application/json' },
		}).then(result => { 

		}).catch((error) => {
		})
	}

	storeprocedure_dummywebsites = (appstore) => {
		axios({
			method: 'GET',
			url: appstore.url+'storeprocedure_dummywebsites.json',
			headers: {'content-type': 'application/json' },
		}).then(result => { 

		}).catch((error) => {
		})
	}
	storeprocedure_scriptsnew = (appstore) => {
		axios({
			method: 'GET',
			url: appstore.url+'storeprocedure_scripts.json',
			headers: {'content-type': 'application/json' },
		}).then(result => { 

		}).catch((error) => {
		})
	} 

	storeprocedure_settings = (appstore) => {
		axios({
			method: 'GET',
			url: appstore.url+'storeprocedure_settings.json',
			headers: {'content-type': 'application/json' },
		}).then(result => { 

		}).catch((error) => {
		})
	} 

		storeprocedure = (appstore) => {
			axios({
				method: 'GET',
				url: appstore.url+'storeprocedure_chatinvitation.json',
				headers: {'content-type': 'application/json' },
			}).then(result => { 
	
			}).catch((error) => {
			})
		} 
		
		storeprocedureva = (appstore) => {
			axios({
				method: 'GET',
				url: appstore.url+'storeprocedure_vainvitation.json',
				headers: {'content-type': 'application/json' },
			}).then(result => { 
	
			}).catch((error) => {
			})
		} 
	getUserPerformance = () => {
		const today = moment();
		let since_from_today = today.clone().subtract(30, "days");
		const data = { "alltype": ["chats", "ops_rating"] ,"since":since_from_today.format("DD-MM-YYYY HH:mm:ss"),"till":today.format("DD-MM-YYYY HH:mm:ss")};
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getUserperformance.json',
			headers: { 'content-type': 'application/json' },
			data: data,
		}).then(result => {
			// console.log(result);
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			this.profileStore.chats = result.data.type_count;
			this.profileStore.rating = result.data.op_rating;
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// this.props.appstore.logout();
			console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		})
	}
	

	getUserProfile = () => {
		this.profileStore.deleteImg = false;
		this.profileStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		axios({
			method: 'GET',
			url: this.props.appstore.url + 'getprofile.json',
			headers: { 'content-type': 'application/json' },
		}).then(result => {
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			if (result.data.usersmodel != null) {
				this.showProfileData(result.data);
				this.profileStore.pageisLoaded = true;
			}
			this.props.appstore.global_errorcount=0;
			this.profileStore.overrideSounds = this.props.appstore.userInfo.accountplan.custom_sounds == 1 ? true : false;
		}).catch((error) => {
			// if (error.response) {
			// 	console.log(error);
			// 	this.props.appstore.logout();
			// }
			this.profileStore.pageisLoaded = true;
			console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}

		})
	}

	showProfileData = (profiledata) => {
		var self = this;
		let profileData = profiledata;
		Object.keys(self.profileStore.personal).forEach(function (key) {

			if (self.profileStore.personal.hasOwnProperty(key) && profileData.usersmodel.hasOwnProperty(key)) {

				if (key != "pass") {
					if (key == "auto_logoff") {
						self.profileStore.personal.autoLogoffChanged = false;
						self.profileStore.personal[key] = profileData.usersmodel[key] != null ? profileData.usersmodel[key].slice(0, 5) : "";
						self.profileStore.personal.auto_logoffchanged = profileData.usersmodel[key] != null ? profileData.usersmodel[key].slice(0, 5) : "";
						if (self.profileStore.personal[key] === "" ) {
							self.profileStore.personal.auto_logoff = "";
							self.profileStore.personal.autoLogOffDisabled = true;
						} 
						
					    else {
							
							self.profileStore.personal.autoLogOffDisabled = false;
						}

					} else {
						self.profileStore.personal[key] = profileData.usersmodel[key] != null ? profileData.usersmodel[key] : "";
					}
				} else {
					self.profileStore.personal.pass = "";
					self.profileStore.personal.pass2 = "";
				}
			}
		})
		//set custome_message if any
		if (profileData.usersmodel.custom_message.length != 0)
			this.profileStore.message_action = 'custom';
		//show departsments and websites
		if (profileData.departsmentmodellist.length != 0) { this.showDeptWebsite(profileData.departsmentmodellist); }

		//set langauge
		if (profileData.usersmodel.lang != "") {
			let count = 0;
			for (let j in this.props.appstore.languages) {
				if (profileData.usersmodel.lang == this.props.appstore.languages[j].value) {
					break;
				}
				count++;
			}
			this.props.appstore.selectedLanguage = this.props.appstore.languages[count];
		}
		//show logoff mode
		if (profileData.usersmodel.sms_enabled != null) {
			this.profileStore.smsChats.receiveChats = profileData.usersmodel.sms_enabled;
		}
		//show sounds setting
		if (profileData.soundsmodellist.length != 0) {
			this.showSoundsSetting(profileData.soundsmodellist);
		} else {
			this.profileStore.overrideSounds = false;
		}
		this.profileStore.isAdmin = profileData.usersmodel.user_type_id == 3 ? true : false;
		this.profileStore.confiem_phone_saved_or_not = profileData.usersmodel.sms_phone;
		this.profileStore.bal_sms_count = this.props.appstore.userInfo.accountsmodel.sms_monthly_total - this.props.appstore.userInfo.accountsmodel.sms_monthly_used + this.props.appstore.userInfo.accountsmodel.sms_static*1;

		this.profileStore.smsChats.sms_phone_confirmed = profileData.usersmodel.sms_phone_confirmed;
		//this.profileStore.smsChats.phoneCode=profileData.usersmodel.sms_country == -1? 2:profileData.usersmodel.sms_country;
		let phonecode = profileData.usersmodel.sms_country == -1 ? 0 : profileData.usersmodel.sms_country;
		this.profileStore.confirm_code_saved_or_not = phonecode;
		for (let i in this.profileStore.countryCode) {
			if (this.profileStore.countryCode[i].value == phonecode) {
				this.profileStore.smsChats.phoneCode = this.profileStore.countryCode[i];
				break;
			}
		}
		if (phonecode == 0) {
			this.profileStore.nosmsprovider = true;
		} else {
			this.profileStore.nosmsprovider = false;
		}
		let timezone = profileData.usersmodel.sms_timezone;
		for (let i in this.profileStore.timeZones) {
			if (this.profileStore.timeZones[i].value == timezone) {
				this.profileStore.smsChats.timeZone = this.profileStore.timeZones[i];
				break;
			}
		}
		//show sms phoneNumber
		if (profileData.usersmodel.sms_phone !== "") { this.profileStore.smsChats.phoneNumber = profileData.usersmodel.sms_phone }
		//show operator schedule
		if (profileData.usersmodel.sms_schedule != null) {

			let schedulelist = JSON.parse(profileData.usersmodel.sms_schedule);
			//console.log(schedulelist)
			this.showSchedule(schedulelist);
		}
		if (profileData.image != null) {
			this.profileStore.personal.image = profileData.image;
		}
	}

	showDeptWebsite = (deptWebsites) => {

		let arr = [];
		let index;

		for (index in deptWebsites) {
			arr[index] = {}
			arr[index] = { deptName: deptWebsites[index].name, website: deptWebsites[index].website_name }
		}
		this.profileStore.departments = [];
		this.profileStore.departments.push(arr)
	}

	showSoundsSetting = (soundData) => {

		let storeSounds = this.profileStore.soundSelections;
		if (soundData.length != 0 && soundData[0].scopeMode === "account") {
			this.profileStore.overrideSounds = false;
		}
		else {
			this.profileStore.overrideSounds = true;
		}

		let i = 0;
		for (let index in soundData) {
			if (soundData[index].soundType == "loop_count") {
				this.profileStore.loopCount = soundData[index].package_sound != "" ? soundData[index].package_sound : '';
				i++;
				continue;
			}
			else {

				if (storeSounds.length > i) {
					if (storeSounds[i].soundType === soundData[i].soundType) {

						let packageNo = soundData[i].package_sound;
						if (packageNo === "legacy")
							storeSounds[i].selectedSound = this.profileStore[storeSounds[i].soundType][6]
						else
							storeSounds[i].selectedSound = this.profileStore[storeSounds[i].soundType][packageNo]
					}
					i++;
				}
			}
		}

		//clone for clear option
		//if (profileData.usersmodel.custom_message.length != 0)
		this.profileStore.soundcloned = JSON.parse(JSON.stringify({
			message_action: this.profileStore.message_action,
			overrideSounds: this.profileStore.overrideSounds,
			custom_msg: this.profileStore.personal.custom_message,
			loopCount: this.profileStore.loopCount,
			lang: this.props.appstore.selectedColor
		}))

		this.profileStore.backupSoundSelections = JSON.parse(JSON.stringify(storeSounds));
	}

	showSchedule = (schedulelist) => {

		let i;
		//convert object into time format	
		for (i in schedulelist) {
			schedulelist[i].sh = schedulelist[i].sh == 0 ? "00" : schedulelist[i].sh;
			schedulelist[i].sm = schedulelist[i].sm == 0 ? "00" : schedulelist[i].sm;
			schedulelist[i].th = schedulelist[i].th == 0 ? "00" : schedulelist[i].th;
			schedulelist[i].tm = schedulelist[i].tm == 0 ? "00" : schedulelist[i].tm;

			if (schedulelist[i].sh != "00" && schedulelist[i].sh.toString().length == 1)
				schedulelist[i].sh = "0" + schedulelist[i].sh;

			if (schedulelist[i].sm != "00" && schedulelist[i].sm.toString().length == 1)
				schedulelist[i].sm = "0" + schedulelist[i].sm;

			if (schedulelist[i].th != "00" && schedulelist[i].th.toString().length == 1)
				schedulelist[i].th = "0" + schedulelist[i].th;

			if (schedulelist[i].tm != "00" && schedulelist[i].tm.toString().length == 1)
				schedulelist[i].tm = "0" + schedulelist[i].tm;

			schedulelist[i] = { startTime: schedulelist[i].sh + ":" + schedulelist[i].sm, EndTime: schedulelist[i].th + ":" + schedulelist[i].tm }
		}

		let index;
		for (index in this.profileStore.weekDays) {
			if (schedulelist[index] != undefined) {
				this.profileStore.weekDays[index].startTime = schedulelist[index].startTime;
				this.profileStore.weekDays[index].EndTime = schedulelist[index].EndTime;
			} else {
				this.profileStore.weekDays[index].selected = false;
			}
		}

		//clone data
		this.profileStore.clonedSchedule = JSON.parse(JSON.stringify(this.profileStore.weekDays));
	}


	// playSound(sound, count) {
	// 	var self = this;
	// 	this.profileStore.soundPlaying = this.props.appstore.soundurl+sound;

	// //	self.soundRef.current.play();



	// 	// let stopped = false;
	// 	// let interval = setInterval(()=>{
	// 	// 	stopped = document.getElementById('usersound').paused;
	// 	// 	if(stopped){
	// 	// 		self.playSound(sound, count);
	// 	// 		clearInterval(interval);
	// 	// 	}
	// 	// },2000);


	// 	setTimeout(() => {
	// 		this.playSound(sound, count);
	// 	},2000);

	// 	// var self = this;
	// 	// this.profileStore.soundPlaying = sound;
	// 	if(sound) {
	// 		var count = 1
	// 		document.getElementById('usersound').addEventListener('ended', function(){
	// 		this.currentTime = 0;
	// 		if(count <= 3){
	// 			self.soundRef.current.play();
	// 		}
	// 		alert(count)
	// 		count++;
	// 		}, false);

	// 	}

	// }

	handleSchedulecheckbox = (day, index) => {
		let since = document.getElementById("time1" + index); //remove error class while user enter time
		let till = document.getElementById("time2" + index);

		day.selected = !day.selected;

		//if disabled then remove error and set to 00:00
		if (!day.selected) {
			since.value = "00:00";
			till.value = "00:00";
			since.classList.remove("has-error");
			till.classList.remove("has-error");

		}
	}

	formatTime(event, id = null) {
		let name = event.target.name;
		let timeclass = document.getElementById(name + id); //remove error class while user enter time
		let removed = name == "auto_logoff" ? null : timeclass.classList.remove("has-error");
		let secondTime = name == "time1" ? document.getElementById("time2" + id) : document.getElementById("time1" + id);

		this.profileStore.timeNotValid = false;
		this.profileStore.logoffTimeNotValid = false;
		let time = event.target.value;
		if (event.target.value.length == 3 && !event.target.value.includes(":")) {
			time = event.target.value.slice(0, 2) + ":" + event.target.value.slice(2, 3);
		}

		if (event.target.value.length == 5) {
			let a = event.target.value.slice(0, 2);
			let b = event.target.value.slice(3, 5);
			if (b > 59) {
				this.profileStore.logoffTimeNotValid = true;
				if (timeclass != null) {
					timeclass.className += " has-error";
					this.profileStore.timeNotValid = true;
				}
			}
			if (a >= 24 && b > 0 || a > 24 && b >= 0) {
				if (name == "auto_logoff") {
					this.profileStore.logoffTimeNotValid = true;
				}
				else {
					this.profileStore.timeNotValid = true;
					timeclass.className += " has-error";
				}
			}
			//check starttime time less than endtime
			if (id != null && secondTime.value != '') {
				let c = secondTime.value == '' ? 0 : secondTime.value.slice(0, 2);
				let d = secondTime.value == '' ? 0 : secondTime.value.slice(3, 5);
				let errorAlready1 = timeclass.getAttribute("class").indexOf("has-error");
				let errorAlready2 = secondTime.getAttribute("class").indexOf("has-error");
				//errorAlready1 == -1 means no error till now
				if (errorAlready1 == -1 && errorAlready2 == -1) {
					if (name == "time1") {

						this.profileStore.timeNotValid = a < c || a == c && b < d ? false : true;
						timeclass.className += a < c || a == c && b < d ? "" : " has-error";
					} else {
						this.profileStore.timeNotValid = a > c || a == c && b > d ? false : true;
						timeclass.className += a > c || a == c && b > d ? "" : " has-error";
					}
				}
			}
		}

		return time;
	}

	handleTimeOnBlur = (event, id = null) => {
		let name = event.target.name;

		if (event.target.value.length != 5) {
			if (name == "auto_logoff") {
				this.profileStore.logoffTimeNotValid = true;
			}
			else {
				this.profileStore.timeNotValid = true;
				let timeclass = document.getElementById(name + id); //remove error class while user enter time
				let pos = timeclass.getAttribute("class").indexOf("has-error");

				if (pos == -1)
					timeclass.className += " has-error";
			}
		}
	}

	handleChange = (event) => {
		this.profileStore.emailAlreadyExist = false;

		// if(event.target.name ==="nick_name"){
		// 	//dont allow space in nickname
		// 	event.target.value = event.target.value.replace(/\s/g, '');
		// }	

		this.updateProperty(event.target.name, event.target.value);

	}

	updateProperty(key, value) {
		this.profileStore.personal[key] = value;
		this.profileStore.invalidPass = false;
		if (key == "pass2" || key == "pass2") {
			this.checkPassword(key, value);
		}
	}

	checkPassword = (key, value) => {

		if (this.profileStore.personal.pass2 != this.profileStore.personal.pass) {
			this.profileStore.invalidPass = true;
		}
	}

	checkForEnteredNumer(event) {
		let val;

		var key = window.event ? event.keyCode : event.which;
		if (this.profileStore.smsChats.phoneCode.value != 0) {

			if (event.keyCode == 8 || event.keyCode == 46
				|| event.keyCode == 37 || event.keyCode == 39) {
				val = true;
			}
			// else if (key < 48 || key > 57) {
			// 	val = false;
			// }
			else if (((key < 48 || (key <96 && key>57) ) || key > 105) || event.shiftKey) {
			    val = false;
			}
			else val = true;
			if (!val) {
				event.preventDefault();
			}
		} else {
			var charCode = (event.which) ? event.which : event.keyCode;
			//console.log(charCode);
			if (charCode != 46 && charCode > 31
				&& (charCode < 48 || charCode > 57) && charCode != 61) {
				val = false;
			}
			else {
				val = true;
			}
			if (!val) {
				event.preventDefault();
			}
		}
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
	}
	getsmsSchedule() {
		let weekDays = this.profileStore.weekDays;
		var sms_schedulelist = [];
		let allSelected = 0;
		let sms_enabled = this.profileStore.smsChats.receiveChats ? 1 : 0;
		let sms_phone = this.profileStore.smsChats.phoneNumber; //phone number
		let sms_timezone = this.profileStore.smsChats.timeZone;
		let sms_phonecode = this.profileStore.smsChats.phoneCode.value == 0 ? -1 : this.profileStore.smsChats.phoneCode.value;
		let keys = []

		for (let i = 0; i < weekDays.length; i++) {
			let list = {}
			list["enabled"] = weekDays[i].selected;
			list["since"] = weekDays[i].startTime;
			list["till"] = weekDays[i].EndTime;
			list["sms_enabled"] = sms_enabled
			list["sms_phone"] = sms_phone
			list["sms_timezone"] = sms_timezone.value
			list["allSelected"] = allSelected
			list["sms_country"] = sms_phonecode
			sms_schedulelist.push(list);
			if (weekDays[i].selected) {
				allSelected = i - 1;
			}
		}
		return sms_schedulelist;
	}

	saveProfileSound(event, appstore) {
		event.preventDefault();
		let message = this.profileStore.personal.custom_message;
		message = message.replace(/<p>​<\/p>/gi, '');
		message = message.replace(/<p>\s*<\/p>/ig, '');
		message = message.replace(/<p\s*\/>/ig, '');
		if (this.profileStore.message_action == 'custom') {
			if (message.trim() == "") {
				this.profileStore.custom_message_isvalid = false;
				return;
			} else {
				this.profileStore.custom_message_isvalid = true;
			}
		}
		this.profileStore.saveinProcess = true;
		let chat_request = this.profileStore.soundSelections[0].selectedSound.index
		let message_received = this.profileStore.soundSelections[1].selectedSound.index
		let message_sent = this.profileStore.soundSelections[2].selectedSound.index
		let new_visitor = this.profileStore.soundSelections[3].selectedSound.index
		let loop_count = this.profileStore.loopCount;

		let scopeMode = "operator";//appstore.userInfo.usersmodel.user_type_id == 2?"operator":"accounts" ;
		let ovverideSounds = this.profileStore.overrideSounds;

		let soundlist = [];
		let keys = ["soundType", "package_sound", "soundName", "scopeMode", "overrideSounds", "color", "custom_message"];
		let soundtype = ["chat_request", "message_received", "message_sent", "new_visitor", "loop_count"];
		let soundtypevalue = [chat_request, message_received, message_sent, new_visitor, loop_count];

		for (let i = 0; i < soundtype.length; i++) {
			let list = {}
			list[keys[0]] = soundtype[i];
			list[keys[1]] = soundtypevalue[i];
			list[keys[2]] = "";
			list[keys[3]] = scopeMode;
			list[keys[4]] = ovverideSounds;
			list[keys[5]] = appstore.selectedColor.value;
			if (this.profileStore.message_action == 'custom') {
				list[keys[6]] = this.profileStore.personal.custom_message;
			}
			soundlist.push(list);
		}

		axios({
			method: 'post',
			url: appstore.url + 'savesound.json',
			headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
			data: soundlist,
		}).then(result => {
			//console.log('save profile sound data*********');
			//console.log(result);
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
			if (this.profileStore.personal.custom_message.length == 0) {
				this.message_action = "default";
			}
			if (result.data[0].status == 1) {
				appstore.userInfo.usersmodel.color = result.data[0].color;
				appstore.userInfo.usersmodel.custom_message = result.data[0].custom_message;
				this.showSoundsSetting(result.data);
				//clone object for cancel button option
				this.profileStore.soundcloned = JSON.parse(JSON.stringify({
					message_action: this.profileStore.message_action,
					overrideSounds: this.profileStore.overrideSounds,
					custom_msg: result.data.custom_message,
					loopCount: this.profileStore.loopCount,
					lang: appstore.selectedColor
				}))

				this.profileStore.backupSoundSelections = this.profileStore.soundSelections.slice(0);
			}

			this.profileStore.saveinProcess = false;
			appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	console.log(error);
			// 	appstore.logout();
			// }
			this.profileStore.saveinProcess = false;
			console.log(error);
				console.log("error count :"+appstore.global_errorcount);
				appstore.global_errorcount=appstore.global_errorcount+1;
				if(appstore.global_errorcount>20){
				// appstore.logout();
				}
		})

	}

	saveUserSchedule = (event) => {
		event.preventDefault();
		if (this.profileStore.timeNotValid) {
			return;
		}
		if (this.profileStore.confirmphone_error != "") {
			return;
		}
		this.profileStore.saveinProcess = true;
		let self = this;
		setTimeout(function () {
			self.saveinProcess = false;
		}, 1000);
		let sms_schedulelist = this.getsmsSchedule();
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'saveuserloggoff.json',
			headers: { 'content-type': 'application/json' },
			data: sms_schedulelist,
		}).then(result => {
			//console.log('schedule response*******');
			//console.log(result);
			if (result.data.status == 1) {
				//console.log('success');
				this.getUserProfile();
			}
			let self = this;
			setTimeout(function () {
				self.profileStore.saveinProcess = false;
			}, 1000);
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	console.log(error);
			// 	this.props.appstore.logout();
			// }
			this.profileStore.saveinProcess = false;
			console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		})
	}
	selectComponent = () => {
		this.profileStore.deleteImg = true;
	}
	saveProfile = (event) => {
		event.preventDefault();

		this.profileStore.emailAlreadyExist = false;
		const validation = this.validator.validate(this.profileStore.personal);
		this.setState({ validation });
		this.submitted = true;
		let profileData = this.profileStore.personal;
		if (!validation.isValid) {
			//if not valid then stop form submission.
			window.scrollTo(0, 0);
			return;
		}
		// if (this.profileStore.logoffTimeNotValid)
		// return;

		this.profileStore.saveinProcess = true;
		//this.profileStore.saveProfileLoading=true;

		if (profileData.autoLogoffChanged == false) {
			profileData.auto_logoff = profileData.auto_logoffchanged;
			if (profileData.auto_logoff === "") {
				profileData.autoLogOffDisabled = true;
			} if (profileData.auto_logoff === "00:00") {
				profileData.auto_logoff = "00:00";
				profileData.autoLogOffDisabled = true;
			}
		} else {
			if (profileData.auto_logoff === "") {
				profileData.autoLogOffDisabled = true;
			} if (profileData.auto_logoff === "00:00") {
				profileData.auto_logoff = "00:00";
				profileData.autoLogOffDisabled = true;
			}
		}
		let onlinestatus = this.props.appstore.loggedUserOnline == true ? "online" : "offline";
		profileData.email = profileData.email.trim();
		profileData.lang = this.props.appstore.selectedLanguage.value;
		const formData = new FormData()
		formData.set('id', '100');
		if (profileData.file != '') {
			// profileData.file.forEach((file, i) => {
			this.state.images = profileData.file;
			//			console.log(profileData.selectedfile);
			this.state.actual_file = profileData.selectedfile[0];
			// })
			this.setState({ uploading: true })

			// profileData.file.forEach((file, i) => {
			formData.append("file", this.state.images)
			formData.append("actualfile", this.state.actual_file)
			formData.append("fileMimeType", this.state.fileMimeType);
			// })
		}

		formData.append("nick_name", profileData.nick_name);
		formData.append("real_name", profileData.real_name);
		formData.append("email", profileData.email);
		formData.append("pass", profileData.pass);
		formData.append("pass2", profileData.pass2);
		formData.append("localTimeZone", profileData.localTimeZone);
		formData.append("auto_logoff", profileData.auto_logoff);
		formData.append("autoLogOffDisabled", profileData.autoLogOffDisabled);
		formData.append("lang", profileData.lang);
		formData.append("custom_message", profileData.custom_message);
		formData.append("image", profileData.image);
		formData.append("deleteimage", this.profileStore.deleteImg);
		formData.append("user_live_status", onlinestatus);

		axios({
			method: 'post',
			url: this.props.appstore.url + 'saveprofile.json',
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data', 'charset': 'utf-8' }
		}).then(result => {
			//console.log('profile save response*******');
			//console.log(result);
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				this.props.appstore.logout();
				}
			}
			if (result.data.status == 1) {
				//update global user data
				this.props.appstore.userInfo.usersmodel = result.data;
				//set auto logoff
				this.props.appstore.autoLogout(result.data.auto_logoff);
				this.getUserProfile();
				this.props.appstore.localLanguage = JSON.parse(result.data.language);

				//update operator from alloperator array
				for (let i = 0; i < this.props.appstore.userInfo.alloperators.length; i++) {
					if (this.props.appstore.userInfo.alloperators[i].user_uid === result.data.user_uid) {
						this.props.appstore.userInfo.alloperators[i] = result.data;
						break;
					}
				}

				//set for refresh
				localStorage.setItem("email", this.profileStore.personal.email);

			} else if (result.data.status == -1) {
				window.scrollTo(0, 0);
				this.profileStore.emailAlreadyExist = true;
			} else {
				console.log('save failed');
			}

			let self = this;
			self.profileStore.saveinProcess = false;
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	console.log(error);
			// 	this.props.appstore.logout();
			// }
				this.profileStore.saveinProcess = false;
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		});
	}


	clearSound = () => {
		this.profileStore.updateCustomMsg = Math.random();
		this.profileStore.message_action = this.profileStore.soundcloned.message_action;
		this.profileStore.personal.custom_message = this.profileStore.soundcloned.custom_msg;
		this.profileStore.overrideSounds = this.profileStore.soundcloned.overrideSounds;
		this.profileStore.loopCount = this.profileStore.soundcloned.loopCount;
		this.props.appstore.selectedColor = Object.assign({}, this.profileStore.soundcloned.lang);
		this.profileStore.soundSelections = JSON.parse(JSON.stringify(this.profileStore.backupSoundSelections));
	}

	clearSchedule = () => {
		this.profileStore.weekDays = JSON.parse(JSON.stringify(this.profileStore.clonedSchedule));
		this.profileStore.confirmphone_error = "";

	}

	verifyFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0]
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				// currentFile.name + ": "+
				this.profileStore.warning_image_name = this.props.appstore.getLanguageTrans("Oops, the file is too large. The file size should be less than 10Mb.");
				this.profileStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.profileStore.warning_image_name = currentFile.name + ": " + this.props.appstore.getLanguageTrans("An internal server error occurred.");
				this.profileStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}

	onSelectFile = e => {
		this.profileStore.personal.selectedfile = e.target.files;
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					// this.setState({ src: reader.result })
					this.profileStore.cropimage.src = reader.result
				);
				reader.readAsDataURL(e.target.files[0]);
				this.profileStore.imageCropModel = true;
			}
		}
	};

	onCropComplete = crop => {
		this.profileStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) {
		// this.state.fileMimeType = this.state.imagetype
		if (this.profileStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif';
			const mimetype = this.state.imagetype == 'image/png' ? 'image/png'
				: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
					: 'image/gif';
			this.state.fileMimeType = mimetype;
			const croppedImageUrl = await this.getCroppedImg(
				this.profileStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			// this.setState({ croppedImageUrl }); 
			this.profileStore.cropimage.croppedImageUrl = croppedImageUrl;

		}
	}

	handleClearToDefault = event => {
		this.profileStore.imageCropModel = false;
		this.profileStore.personal.selectedfile = "";
		this.profileStore.personal.file = "";
		this.state.actual_file = "";
		this.state.fileMimeType = "";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.profileStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName, mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.profileStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		this.profileStore.personal.file = myNewCroppedFile
		// download file
		// this.props.appstore.downloadBase64File(imageData64, myFilename)
		// this.handleClearToDefault()

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, mimetype);
		});
	}
	numberChanging = () => {
		if (this.profileStore.smsChats.phoneNumber != this.profileStore.confiem_phone_saved_or_not) {
			this.profileStore.sms_phone_changing = true
		} else {
			this.profileStore.sms_phone_changing = false
		}
		let code = this.profileStore.smsChats.phoneCode.value;
		if (code == 0) {
			this.profileStore.nosmsprovider = true;
			if (this.profileStore.smsChats.phoneNumber.indexOf("+") == -1) {
				this.profileStore.confirmphone_error = "Please provide valide phone number";
			} else {
				this.profileStore.confirmphone_error = "";
			}
		} else {
			this.profileStore.nosmsprovider = false;
			if (this.profileStore.smsChats.phoneNumber.indexOf("+") != -1) {
				this.profileStore.confirmphone_error = "Please provide valide phone number";
			} else {
				this.profileStore.confirmphone_error = "";
			}
		}
	}
	checksmsprovide = () => {
		let code = this.profileStore.smsChats.phoneCode.value;
		if (code == 0) {
			this.profileStore.nosmsprovider = true;
			if (this.profileStore.smsChats.phoneNumber.indexOf("+") == -1) {
				this.profileStore.confirmphone_error = "Please provide valide phone number";
			} else {
				this.profileStore.confirmphone_error = "";
			}
		} else {
			this.profileStore.nosmsprovider = false;
			if (this.profileStore.smsChats.phoneNumber.indexOf("+") != -1) {
				this.profileStore.confirmphone_error = "Please provide valide phone number";
			} else {
				this.profileStore.confirmphone_error = "";
			}
		}
	}
	confiemphonenumber = () => {
		this.profileStore.confirmphone_error = "";
		let code = this.profileStore.smsChats.phoneCode.value;
		if (this.profileStore.confiem_phone_saved_or_not != this.profileStore.smsChats.phoneNumber
			|| this.profileStore.confirm_code_saved_or_not != code) {
			alert(this.props.appstore.getLanguageTrans("Please save changes"));
		} else {

			axios({
				method: 'POST',
				url: this.props.appstore.url + 'confirm_phone.json',
				headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
				data: { "sms_phone": this.profileStore.smsChats.phoneNumber }
			}).then(result => {
				//console.log(result.data);
				if (result.data == "Success") {
					this.profileStore.confirmphone_model = true;
					this.profileStore.confirmphone_error = ""
				} else {
					this.profileStore.confirmphone_error = result.data;
				}

			}).catch((error) => {
				this.profileStore.confirmphone_error = "Somthing went wrong"
				if (error.response) {
					console.log(error);
				}
			})
		}
	}

	// getFullContactDetail = () =>{ 
	// 	  alert();

	// 	   axios.get("http://localhost/getfullcontact.json?e=bart.lorang@fullcontact.com")
	// 	   .then(response => {
	// 		 // handle success
	// 		 console.log(response)
	// 	   })
	// 	   .catch(error => {
	// 		 // handle error
	// 		 console.log(error);
	// 	   }) 
	//    }
	 
	render() {
		const { crop, croppedImageUrl, src } = this.profileStore.cropimage;

		const { appstore } = this.props;
		var self = this;
		const vacss = {
			pointerEvents:"none",
			opacity: 0.3
		}
		const nocss = {};
		let validation = this.submitted ?                         // if the form has been submitted at least once
			this.validator.validate(this.profileStore.personal) :   // then check validity every time we render
			this.state.validation
		if (!this.profileStore.pageisLoaded) {
			return (<div className="profileLoader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				// loading={this.responseStore.responsereload}
				/>
			</div>)
		}

		return (
			<div className="innercontent_wrapper w-100">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.profilePageIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left "  >{appstore.getLanguageTrans("Profile")}</h4>
				</div>
				{/* { appstore.userInfo.accountsmodel.account_id=="35526" &&
				<React.Fragment>
				<div className="header_title row align-items-center m-0">
				<img src={imageURLs.profilePageIcon} alt="faq" className="img-fluid" />
				<h4 className="text-left " onClick={() => {this.storeprocedure(appstore)}} >Store procedure-1</h4>
				</div>

				<div className="header_title row align-items-center m-0">
				<img src={imageURLs.profilePageIcon} alt="faq" className="img-fluid" />
				<h4 className="text-left "  onClick={() => {this.storeprocedureva(appstore)}} >Store procedure-2</h4>
				</div>

				<div className="header_title row align-items-center m-0">
				<img src={imageURLs.profilePageIcon} alt="faq" className="img-fluid" />
				<h4 className="text-left " onClick={() => {this.storeprocedure_scriptsnew(appstore)}} >Store procedure-3</h4>
				</div>

				<div className="header_title row align-items-center m-0">
				<img src={imageURLs.profilePageIcon} alt="faq" className="img-fluid" />
				<h4 className="text-left " onClick={() => {this.storeprocedure_settings(appstore)}}  >Store procedure-4</h4>
				</div>
				<div className="header_title row align-items-center m-0">
				<img src={imageURLs.profilePageIcon} alt="faq" className="img-fluid" />
				<h4 className="text-left " onClick={() => {this.storeprocedure_dummywebsites(appstore)}} >Store procedure-5</h4>
				</div>
				<div className="header_title row align-items-center m-0">
				<img src={imageURLs.profilePageIcon} alt="faq" className="img-fluid" />
				<h4 className="text-left " onClick={() => {this.storeprocedure_Op_dummywebsites(appstore)}} >Store procedure-6</h4>
				</div>
				</React.Fragment>
				} */}
				{/* onClick={() => {this.storeprocedure(appstore)}}  */}				
				{/* onClick={() => {this.storeprocedureva(appstore)}} */}
				{/* onClick={() => {this.storeprocedure_scriptsnew(appstore)}} */}
				{/* onClick={() => {this.storeprocedure_settings(appstore)} */}
				{/* onClick={() => {this.storeprocedure_dummywebsites(appstore)}} */}
				{/* onClick={() => {this.storeprocedure_Op_dummywebsites(appstore)}} */}
				{this.profileStore.saveinProcess &&
					<div className="profileLoader">
						<GridLoader
							css={override}
							size={20}
							marging={2}
							color={this.props.appstore.selectedColor.color}
						/>
					</div>
				}
				<div className="row profile_wrapper">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<div className="row">
								<div className={appstore.menuActive ? "col-12 col-xl-7 col-lg-12" : "col-12 col-lg-7 col-xl-7"}>
									<div className="headerTitle">
										<h4 className="text-left" >{appstore.getLanguageTrans("Personal Information")}</h4>
									</div>
									<Form>
										<Form.Group as={Row} controlId="formGroupNickName">
											<Form.Label column lg="5" sm="4">{appstore.getLanguageTrans("Nickname")}</Form.Label>
											<Col sm="8" lg="7">
												<Form.Control type="text" placeholder={appstore.getLanguageTrans("Enter Nickname")}
													className={validation.nick_name.isInvalid && 'has-error'}
													name="nick_name"
													value={this.profileStore.personal.nick_name}
													onChange={(e) => this.handleChange(e)}
													maxLength="30" />
												<span className="error-block">{validation.nick_name.message}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupRealName">
											<Form.Label column lg="5" sm="4">{appstore.getLanguageTrans("Real Name")}</Form.Label>
											<Col sm="8" lg="7">
												<Form.Control type="text" placeholder={appstore.getLanguageTrans("Enter Real Name")}
													className={validation.real_name.isInvalid && 'has-error'}
													name="real_name"
													value={this.profileStore.personal.real_name}
													onChange={(e) => this.handleChange(e)}
													maxLength="30" />
												<span className="error-block">{validation.real_name.message}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupEmail">
											<Form.Label column lg="5" sm="4">{appstore.getLanguageTrans("Email")}</Form.Label>
											<Col sm="8" lg="7">
												<Form.Control type="text" placeholder={appstore.getLanguageTrans("Enter Email")}
													className={(validation.email.isInvalid || this.profileStore.emailAlreadyExist) && 'has-error'}
													name="email"
													value={this.profileStore.personal.email}
													onChange={(e) => this.handleChange(e)} />
												<span className="error-block">{validation.email.isInvalid && validation.email.message || this.profileStore.emailAlreadyExist && "Email already exits."}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupPassword">
											<Form.Label column lg="5" sm="4">{appstore.getLanguageTrans("Password")}</Form.Label>
											<Col sm="8" lg="7">
												<Form.Control type="password" placeholder={appstore.getLanguageTrans("Enter Password")}
													name="pass"
													className={self.profileStore.invalidPass && 'has-error'}
													value={this.profileStore.personal.pass}
													onChange={(e) => this.handleChange(e)}
													onBlur={(e) => this.profileStore.confirmPass(e)}
												/>

											</Col>

										</Form.Group>
										<Form.Group as={Row} controlId="formGroupConfirm">
											<Form.Label column lg="5" sm="4">{appstore.getLanguageTrans("Confirm Password")}</Form.Label>
											<Col sm="8" lg="7">
												<Form.Control type="password"
													className={self.profileStore.invalidPass && 'has-error'}
													value={this.profileStore.personal.pass2}
													name="pass2"
													placeholder={appstore.getLanguageTrans("Re-Enter Password")}
													onChange={(e) => this.handleChange(e)}
													onBlur={(e) => this.profileStore.confirmPass(e)}
												/>
												<span className="error-block">{self.profileStore.invalidPass && "passwords doesn't match"}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupImage">
											<Form.Label column lg="5" sm="4">{appstore.getLanguageTrans("Image")}</Form.Label>
											<Col>
												<div className="image_upload">
													<Button variant="light" className="cursor-pointer">
														<p>{appstore.getLanguageTrans("Upload & edit")}</p>
														{/* onChange={(e) => this.onImgChange(e)} */}
														<input ref={this.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={this.onSelectFile} />
													</Button>
													<div className="profileImg row align-items-center w-100 h-100 m-0">
														{this.profileStore.cropimage.croppedImageUrl && (
															<React.Fragment>
																<img alt="Crop" className="img-fluid" style={{ width: "100px", height: "100px", borderRadius: "50px" }} src={croppedImageUrl} />
																<div className="img_delete pointerhand" onClick={() => this.handleClearToDefault()}><p className="rotate">+</p><p>{appstore.getLanguageTrans("Delete current image")}</p></div>
															</React.Fragment>
														)}
														{(!this.profileStore.cropimage.croppedImageUrl && this.profileStore.personal.image != ''
															&& this.profileStore.deleteImg == false) &&
															<React.Fragment>
																<img src={this.props.appstore.resourceurl + this.props.appstore.userInfo.accountsmodel.account_uid + "/" + this.profileStore.personal.image} style={{ width: "100px", height: "100px", borderRadius: "50px" }} alt="avatar" className="img-fluid" />
																<div className="img_delete pointerhand" onClick={() => this.selectComponent()}><p className="rotate">+</p><p>{appstore.getLanguageTrans("Delete current image")}</p></div>
															</React.Fragment>
														}
														{/* <p className="rotate">+</p> */}

													</div>
												</div>
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupTimeZone" className="align-items-center">
											<Form.Label column xs="7" lg="5" sm="4">{appstore.getLanguageTrans("Local Time Zone")}</Form.Label>
											<Col xs="5" sm="8" lg="7">
												<p className="text-left">{this.profileStore.personal.localTimeZone}</p>
											</Col>
										</Form.Group>

										<Form.Group as={Row} controlId="formGroupLanguage">
											<Form.Label column sm="4" lg="5">{appstore.getLanguageTrans("Dashboard Language")}</Form.Label>
											<Col sm="8" lg="7" className="customDrpDwn">
												<DropDownComponent dropDownOptions={appstore.languages}
													store={appstore}
													key={Math.random()}
													callBack={(selectedOption) => appstore.setLanguage(selectedOption)}
													selectedOption={appstore.selectedLanguage} />
												{/* <DropDownComponent dropDownOptions={this.profileStore.lanDropDownOptions}
									    						store={this.profileStore}
											    				callBack={(selectedOption) => this.profileStore.setLanguage(selectedOption)}
											    				selectedOption = {this.profileStore.selectedLanguage}/>					 */}
											</Col>
										</Form.Group>

										<Form.Group as={Row} controlId="formGroupAutoLogoff">
											<Form.Label column lg="5" sm="4">{appstore.getLanguageTrans("Auto log-off time")}</Form.Label>
											<Col sm="3" lg="3">
												<Form.Control type="text"
													name="auto_logoff"
													value={this.profileStore.personal.auto_logoff}
													placeholder="HH:MM"
													maxLength="5"
													onBlur={(e) => this.handleTimeOnBlur(e)}
													onKeyDown={(e) => { this.checkForEnteredNumer(e) }}
													//className={this.profileStore.personal.autoLogOffDisabled ? 'logOff' : '' + (self.profileStore.logoffTimeNotValid && ' has-error')}
													 
													className={(this.profileStore.personal.autoLogOffDisabled && this.profileStore.personal.auto_logoffchanged=="") ? 'logOff' : '' + (this.profileStore.personal.autoLogOffDisabled ? ' greyout ':'')}
													onChange={(e) => { this.profileStore.personal.autoLogOffDisabled = false; this.profileStore.personal.autoLogoffChanged = true; this.profileStore.personal.auto_logoff = this.formatTime(e) }}
												/>
											</Col>
										</Form.Group>
									</Form>
								</div>
								<div className={appstore.menuActive ? "col-12 col-xl-5 col-lg-12" : "col-12 col-lg-5 col-xl-5"}>
									<div className={appstore.menuActive ? "performance-wrapper pull-left" : "performance-wrapper"}>
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Last 30-day performance")}</h4>
										</div>
										<div className="performance-content row">
											<div className="chats col-6">
												<p className="count">{this.profileStore.chats}</p>
												<p className="type text-uppercase">{appstore.getLanguageTrans("Chats")}</p>
											</div>
											<div className="rating col-6">
												<p className="count">{this.profileStore.rating}<img src={this.profileStore.rating == 0 ? imageURLs.heartIcon1
													: (this.profileStore.rating > 0 && this.profileStore.rating <= 1.25) ? imageURLs.heartIcon2
														: (this.profileStore.rating > 1.25 && this.profileStore.rating <= 2.5) ? imageURLs.heartIcon3
															: (this.profileStore.rating > 2.5 && this.profileStore.rating <= 3.75) ? imageURLs.heartIcon4 : imageURLs.heartIcon} alt="rating" className="img-fluid" /></p>
												<p className="type text-uppercase">{appstore.getLanguageTrans("Rating")}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="dept_section text-left">
								<h5>{appstore.getLanguageTrans("Departments")}</h5>
								{Array.isArray(this.profileStore.departments[0]) &&
									this.profileStore.departments[0].map(function (dept, deptIndex) {
										return (
											<div className="dept_wrapper" key={deptIndex}>
												<h6>{dept.website}</h6>
												<ul>
													{
														dept.deptName.split(",").map(function (deptChild, childIndex) {
															return (
																<li key={childIndex}> {deptChild} </li>
															)
														})
													}
												</ul>
											</div>
										)
									})
								}
								{/* {
									this.profileStore.departments.map(function(dept,deptIndex){
										return(
											<div className="dept_wrapper" key={deptIndex}>
												<h6>{dept.deptName}</h6>
												<ul>
													{
														dept.depts.map(function(deptChild,childIndex){
															return(
																<li key={childIndex}>{deptChild}</li>
															)
														})
													}
												</ul>
											</div>
										)
									})
								} */}

							</div>

							<div className="row m-0">
								<div className="col-12 formBtns">
									<div className="pullRight">
										<Button className="btn_clr" variant="light" onClick={(e) => this.getUserProfile()}>
											<p>{appstore.getLanguageTrans("Cancel")}</p>
										</Button>
										<Button className="btn_save" type="button" onClick={(e) => self.saveProfile(e)}>
											<p>{appstore.getLanguageTrans("Save")}</p>
										</Button>
									</div>
								</div>
							</div>
							{this.profileStore.imageCropModel &&
								<ImageCropComponent show={this.profileStore.imageCropModel}
									store={this.profileStore}
									cancel_btn="Clear"
									multi="0"
									crop_btn="Save"
									onCropComplete={() => this.onCropComplete(crop)}
									closePopup={() => this.handleClearToDefault()}
									appStore={appstore} />
							}
							{this.profileStore.imagewarning &&
								<ImageWarningPopUp show={this.profileStore.imagewarning}
									store={this.profileStore}
									cancel_btn="Close"
									closePopup={() => this.profileStore.imagewarning = false}
									appStore={appstore} />
							}
						</div>
					</div>
				</div>

				<div className="row profile_wrapper">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box" >
							<div className="headerTitle\">
								<h4 className="text-left">{appstore.getLanguageTrans("My dashboard sound settings")}</h4>
							</div>
							<Form>
								<Form.Group as={Row} controlId="formGroupNameSoundSetting">
									<Form.Label column xs="7" sm="4" lg="3">{appstore.getLanguageTrans("Override default sound settings")}</Form.Label>
									{appstore.userInfo.accountplan.custom_sounds !=1 &&
									<span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
									<Col className="custom_radioBtn" style={vacss}>
										<CheckBoxComponent radioValue={this.profileStore.overrideSounds}
											callBack={() => this.profileStore.overrideSounds = !this.profileStore.overrideSounds} />
									</Col></span>}
									{appstore.userInfo.accountplan.custom_sounds !=0 &&
									<Col className="custom_radioBtn" style={nocss}>
										<CheckBoxComponent radioValue={this.profileStore.overrideSounds}
											callBack={() => this.profileStore.overrideSounds = !this.profileStore.overrideSounds} />
									</Col>}
								</Form.Group>
								{/* <audio id="usersound" className="d-none" ref={this.soundRef} src={this.profileStore.soundPlaying} /> */}
								{
									this.profileStore.soundSelections.map(function (soundSection, soundIndex) {
										return (
											<Form.Group as={Row} key={soundIndex} controlId={"formGroupSound" + soundIndex} className="sound-grp align-items-center">
												<Form.Label column sm="4" lg="3">{soundSection.label}</Form.Label>
												<Col sm="8" lg="9">
													<div className={"customDrpDwn " + (!self.profileStore.overrideSounds && "customDrpDwnDisabled")}>
														<DropDownComponent dropDownOptions={soundSection.options}
															store={self.profileStore}
															key={Math.random()}
															selectedOption={soundSection.selectedSound}
															callBack={(selectedOption) => soundSection.selectedSound = selectedOption}
															disabled={!self.profileStore.overrideSounds}
														/>

														<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.play_steel_blue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.play_sea_green : "#ec407a" == appstore.selectedColor.color ? imageURLs.play_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.play_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.play_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.play_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.play_voilet :
															"#039be6" == appstore.selectedColor.color ? imageURLs.play_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.play_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.play_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.play_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.play_sky_blue : imageURLs.play_steel_blue} alt="profile" className="img-fluid" onClick={() => appstore.playChattingSound(soundSection.selectedSound.value, self.profileStore.loopCount, soundSection.soundType)} />
														{
															soundIndex === 0 &&
															<React.Fragment>
																<Form.Label column>{appstore.getLanguageTrans("Loop Count")}</Form.Label>
																<Form.Control type="text" placeholder={appstore.getLanguageTrans("Count")}
																	name="loopCount"
																	maxLength="2"
																	value={self.profileStore.loopCount}
																	className="loopCount"
																	disabled={!self.profileStore.overrideSounds}
																	onKeyDown={(e) => { self.checkForEnteredNumer(e) }}

																	onChange={(e) => self.profileStore.loopCount = e.target.value} />
															</React.Fragment>
														}
													</div>
												</Col>
											</Form.Group>
										)
									})
								}
								<div className="operator_options">
									<div className="headerTitle">
										<h4 className="text-left">{appstore.getLanguageTrans("My Dashboard Color")}</h4>
									</div>
									<Form.Group as={Row} controlId="formGroupLead">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Dashboard Color") + ":"}</Form.Label>
										<Col sm="8" lg="9">
											<div className="customDrpDwn">
												<DropDownComponent dropDownOptions={appstore.colourPalette}
													store={self.appstore}
													key={Math.random()}
													callBack={(selectedOption) => appstore.setColor(selectedOption)}
													onChange={this.dashColor}
													selectedOption={appstore.selectedColor} />

											</div>
										</Col>
									</Form.Group>
								</div>
								<div className="operator_options">
									<div className="headerTitle">
										<h4 className="text-left">{appstore.getLanguageTrans("Operator Options")}</h4>
									</div>
									<Form.Group as={Row} controlId="formGroupLead">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Invitation message (for manual invitations)") + ":"}</Form.Label>
										<Col sm="8" lg="9" className="invitation_msg">
											<div className="row m-0">
												<RadioButtonComponent selectedOption={this.profileStore.message_action}
													options={this.profileStore.invitation_msgs}
													groupName={"messages"}
													key={Math.random()}
													appstore={appstore}
													callBack={(select) => { this.profileStore.message_action = select }} />
											</div>
											{
												this.profileStore.message_action === 'custom' &&
												<div style={{ marginTop: 10 }}>
													{/* <textarea type="text" name="custom_message" value={self.profileStore.personal.custom_message} placeholder={appstore.getLanguageTrans("Enter Custom Message")} onChange={(e) => this.handleChange(e)} /> */}

													<TextEditor store={this.profileStore}
														key={self.profileStore.updateCustomMsg}
														appstore={this.props.appstore}
														name="custom_message"
														editorContent={self.profileStore.personal.custom_message} />
													<span className="error-block">{this.profileStore.custom_message_isvalid ? "" : "Invitation message was empty*"}</span>
												</div>
											}
											{/*<Button className="custom_btn float-left addBtn text-uppercase">
											Edit Custom Invitation
										</Button>*/}
										</Col>
									</Form.Group>
								</div>
								<div className="row m-0">
									<div className="col-12 formBtns">
										<div className="pullRight">
											<Button className="btn_clr" variant="light" onClick={(e) => self.clearSound()}>
												<p>{appstore.getLanguageTrans("Cancel")}</p>
											</Button>
											<Button className="btn_save" onClick={(e) => this.saveProfileSound(e, appstore)} type="button">
												<p>{appstore.getLanguageTrans("Save")}</p>
											</Button>
										</div>
									</div>
								</div>
							</Form>
						</div>
					</div>
				</div>

				{/* {(appstore.userInfo.accountsmodel.sms == 1 ? true : false) && */}
				{(0 == 1 ? true : false) &&
					<div className="row profile_wrapper">
						<div className="settingsForm col-12 w-100 pr-0">
							<div className="settingFormWrapper box">
								<div className="headerTitle">
									<h4 className="text-left">{appstore.getLanguageTrans("Chat via SMS")} {appstore.getLanguageTrans("when I’m logged off")}</h4>
								</div>
								<Form onSubmit={this.saveUserSchedule} >
									<Form.Group as={Row} controlId="formGroupChatSetting" className="align-items-center">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("When I’m logged off")}</Form.Label>
										<Col sm="8" lg="9" className="chat_settings">
											<div className="custom_radioBtn float-left">
												<CheckBoxComponent radioValue={this.profileStore.smsChats.receiveChats}
													callBack={() => this.profileStore.smsChats.receiveChats = !this.profileStore.smsChats.receiveChats} />
											</div>
											{this.profileStore.isAdmin &&
												<p className="float-left">{appstore.getLanguageTrans("Receive chats via SMS")}
													{(this.profileStore.bal_sms_count > 0) ?
														" (" + appstore.getLanguageTrans("credits available") + ":" + this.profileStore.bal_sms_count + ")" :
														<span style={{ color: "#fc3300" }}> ({appstore.getLanguageTrans("No SMS credits available ")}
															<a href={this.props.appstore.userInfo.accountsmodel.sms_purchase_link} style={{ color: "#6282c2", fontSize: "14px" }}
																target="_blank">{appstore.getLanguageTrans("Buy SMS credits")}</a>)</span>}</p>
											}
											{!this.profileStore.isAdmin &&
												<p className="float-left">{appstore.getLanguageTrans("Receive chats via SMS")} ({appstore.getLanguageTrans("credits available")}: {this.profileStore.bal_sms_count}) :
											</p>
											}
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formGroupNumber" className="align-items-center">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Phone number")}</Form.Label>
										<Col sm="8" lg="9" className="customDrpDwn form_control_repeat">
											<DropDownComponent dropDownOptions={this.profileStore.countryCode}
												store={this.profileStore}
												callBack={(selectedOption) => (this.profileStore.smsChats.phoneCode = selectedOption, this.checksmsprovide())}
												selectedOption={this.profileStore.smsChats.phoneCode}
											/>
											<Form.Control type="text" placeholder="" value={this.profileStore.smsChats.phoneNumber}
												name="phoneNumber" onKeyDown={(e) => { this.checkForEnteredNumer(e) }}
												onChange={(e) => (this.profileStore.smsChats[e.target.name] = e.target.value, this.numberChanging())} />
											{(this.profileStore.smsChats.sms_phone_confirmed == 0 || this.profileStore.sms_phone_changing == true) &&
												<Button className="custom_btn float-left addBtn text-uppercase" onClick={() => this.confiemphonenumber()}>
													{appstore.getLanguageTrans("Confirm")}
												</Button>
											}
											{this.profileStore.smsChats.sms_phone_confirmed == 1 && this.profileStore.sms_phone_changing == false &&
												<span className="float-left addBtn" style={{ color: appstore.userInfo.usersmodel.color, fontSize: "14px" }} >{appstore.getLanguageTrans("Confirmed")}</span>
											}
										</Col>

									</Form.Group>
									{this.profileStore.nosmsprovider &&
										<Form.Group as={Row} controlId="formGroupNumber" className="align-items-center">
											<Form.Label column sm="4" lg="3">{""}</Form.Label>
											<Col sm="8" lg="9" className="customDrpDwn form_control_repeat">
												<span className="error-block">{appstore.getLanguageTrans("No SMS provider found for the selected country, international SMS rates may apply")}</span>
											</Col>
										</Form.Group>
									}
									{this.profileStore.confirmphone_error != "" &&
										<Form.Group as={Row} controlId="formGroupNumber" className="align-items-center">
											<Form.Label column sm="8" lg="5">{""}</Form.Label>
											<Col sm="4" lg="7" className="customDrpDwn form_control_repeat">
												<span className="error-block">{appstore.getLanguageTrans(this.profileStore.confirmphone_error)}</span>
											</Col>
										</Form.Group>
									}
									<Form.Group as={Row} controlId="formGroupPhnZone">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Phone time zone")}</Form.Label>
										<Col sm="8" lg="9" className="customDrpDwn">
											<DropDownComponent dropDownOptions={this.profileStore.timeZones}
												store={this.profileStore}
												callBack={(selectedOption) => this.profileStore.smsChats.timeZone = selectedOption}
												selectedOption={this.profileStore.smsChats.timeZone}
											/>
										</Col>
									</Form.Group>
									{/*removed from From.Group controlId="formGroupPhnAvailability" */}
									<Form.Group as={Row} className="available_zone">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("I’m available on")}</Form.Label>
										<Col sm="8" lg="9" >

											{
												this.profileStore.weekDays.map(function (day, dayIndex) {
													return (
														<div className="row day_wrapper" key={dayIndex}>
															<Col className="custom_radioBtn">
																<CheckBoxComponent radioValue={day.selected}
																	callBack={() => self.handleSchedulecheckbox(day, dayIndex)} />
															</Col>

															<p className="day_label">{day.label}</p>
															<Form.Control type="text" name="time1" placeholder="00:00" value={day.startTime}
																maxLength="5"
																id={"time1" + dayIndex}
																//className={self.profileStore.timeNotValid && 'has-error'}
																onKeyDown={(e) => { self.checkForEnteredNumer(e) }}
																onChange={(e) => day.startTime = self.formatTime(e, dayIndex)}
																onBlur={(e) => self.handleTimeOnBlur(e, dayIndex)}
																disabled={!day.selected} />
															<Form.Control id={"time2" + dayIndex} type="text" name="time2" placeholder="00:00" value={day.EndTime}
																maxLength="5"
																//className={self.profileStore.timeNotValid && 'has-error'}
																onKeyDown={(e) => { self.checkForEnteredNumer(e) }}
																onChange={(e) => day.EndTime = self.formatTime(e, dayIndex)}
																onBlur={(e) => self.handleTimeOnBlur(e, dayIndex)}
																disabled={!day.selected} />
														</div>
													)
												})
											}
										</Col>
									</Form.Group>

									<div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light" onClick={(e) => this.clearSchedule()}>
													<p>{appstore.getLanguageTrans("Cancel")}</p>
												</Button>
												<Button className="btn_save" type="submit">
													<p>{appstore.getLanguageTrans("Save")}</p>
												</Button>
											</div>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				}
				{this.profileStore.confirmphone_model &&
					<Confirm_phone show={this.profileStore.confirmphone_model}
						store={this.profileStore}
						save_btn="OK"
						cancel_btn="Cancel"
						title="Confirmation code was sent in SMS. Type it here:"
						closePopup={() => this.profileStore.confirmphone_model = false}
						appStore={appstore} />
				} 
 
			</div>
		)
	}
}) 
export default ProfileComponent;
