import { decorate, observable, extendObservable, isObservableObject } from 'mobx';
import { data } from '../../../../assets/JSON/admin_operators';
import { dept } from '../../../../assets/JSON/admin_department';
import axios from 'axios';

class OperatorsDeptStore {


  constructor(appstore) {

    this.removerEmailErr = false;
    this.appStore=appstore;
    this.chatRingsDirectOptions = [
      { "value": 0, "label": "All Operators" },
      { "value": 1, "label": "Based on the number of chats" },
      { "value": 2, "label": "Based on chat time" }
    ];
    this.payDollar = false;
    this.operatorList = [];
    this.existingOps = [];
    this.isPageLoaded = false;
    this.chatRingsDirect = this.chatRingsDirectOptions[0].value;
    this.showImage = true;
    this.payDollarforDept = false;
    this.clonedDepartments=[];
    this.operators = [
      {
        "label": "",
        "id": "",
        "pic": "",
        "nick_name": "",
        "real_name": "",
        "admin": "",
        "email": "",
        "user_type_id": "",
        "isPasswordChanged": false,
        "user_uid": "",
        "sms_enabled": "",
        "max_chats": "",
        "online": true,
        "roleEditable": "",
        "profileImage": "",
        "image" : "",
        "selectedfile":"",
        "file": "",
      }
    ];
    this.addOperator = false;
    this.emailExist = false;
    this.errormodel=false;
    this.canNotdeleteOwner=false;
    this.departments = dept;
    this.operatorToEdit = {};
    this.operatorEditing = false;
    this.deptEditMode = false;
    this.initFunction();
    this.previouswebsite_uid = "";
    this.cropimage = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        aspect: 1 / 1,
      },
      croppedImageUrl: null,
    }
    this.imageCropModel = false;
    this.imageRef = null;
    this.imagewarning = false;
    this.warning_image_name= "";
    this.deleteImg=false;
    this.fileMimeType="";
    this.op_dptsave=false;
    this.deleteImage=false;
    this.deleteallopt=false;
    this.notrelatedoperators = null;
    this.viewallopt = false;
  }
  initFunction() {
    this.operatorToEdit = {
      "label": "",
      "id": 0,
      "pic": "",
      "nick_name": "",
      "real_name": "",
      "admin": true,
      "email": "",
      "user_type_id": "",
      "user_uid": "",
      "sms_enabled": false,
      "max_chats": "",
      "online": true,
      "password": "",
      "pass": "",
      "confirmpassword": "",
      "isPasswordChanged": false,
      "roleEditable": true,
      "edit": true,
      "profileImage": false
    }
    this.departToEdit = {
      "name": "",
      "department_uid": "",
      "email": "",
      "email_messages": "",
      "operators": [],
      "id": null,
      "department_uid": "",
      "user_uid": [],
      "websiteUids": []
    }
    this.departments.map(function (dept, deptIndex) {
      dept.edit = false;
    })
    // this.operators.map(function (operator, operatorIndex) {
    //   operator.edit = false;
    // })
    this.appStore.operators.map(function (operator, operatorIndex) {
      operator.edit = false;
    })
  }


 
  openUpgrade(appstore) {

    // if (GUI.Tray)
    //     trayOpenLink(Core.Account.params.upgrade_link);
    // else
        window.open(appstore.userInfo.accountsmodel.upgrade_link, '_blank');
  }
  
  goToAddOperatorModeafterdelete(appstore){
    this.existingOps = [];
    const allOp = appstore.userInfo.alloperators.slice(0);
    for (let i = 0; i < this.appStore.operators.length; i++) {
      for (let j = 0; j < allOp.length; j++) {
        if (this.appStore.operators[i].user_uid === allOp[j].user_uid) {
          let index = allOp.indexOf(allOp[j]);
          if (index > -1) {
            allOp.splice(index, 1);
          }
        }
      }
    }

    this.existingOps = allOp;
  }

  goToAddOperatorMode(appstore,addOP=false) {

    if (!this.operatorEditing) {
      this.operatorEditing = true;
      this.existingOps = [];
      const allOp = appstore.userInfo.alloperators.slice(0);
      
      //get existing operator
      // for (let i = 0; i < this.operators.length; i++) {
      //   for (let j = 0; j < allOp.length; j++) {
      //     if (this.operators[i].user_uid === allOp[j].user_uid) {
      //       let index = allOp.indexOf(allOp[j]);
      //       if (index > -1) {
      //         allOp.splice(index, 1);
      //       }
      //     }
      //   }
      // }

      for (let i = 0; i < this.appStore.operators.length; i++) {
        for (let j = 0; j < allOp.length; j++) {
          if (this.appStore.operators[i].user_uid === allOp[j].user_uid) {
            let index = allOp.indexOf(allOp[j]);
            if (index > -1) {
              allOp.splice(index, 1);
            }
          }
        }
      }

      this.existingOps = allOp;
      
      if(addOP){
       this.showExisting = true;
       this.addOperator = true;

      }
    }
  }
  openUpgrade = (appstore) => {
 
        window.open(appstore.userInfo.accountsmodel.upgrade_link, '_blank');
  }

  getOpandDeptforAdmin() {
		let websiteUid = { "website_uid": this.appStore.selectedWebisiteUid };
		axios({
			method: 'POST',
			url:this.appStore.url + 'getOpandDeptforAdmin.json',
			headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
			data: websiteUid,
		}).then(result => {
			//this.operatorsDeptStore.operators= result.data.usersmodel;
      if(result.data.length==1){
        if(result.data.logoutstatus==1){
        this.appStore.logout();
        }
      }
      try{
			this.showOperators(result.data.usersmodel);
      let deptData = this.setOpforDept(result.data.departmentsmodel);
      //testing
      //let dupduplic=deptData[0].operators[0];
      
			this.departments = deptData;
      }catch(error){
      }
			this.isPageLoaded = true;
      this.appStore.global_errorcount=0;
		}).catch((error) => {
			// handle error
			//console.log(error);
      this.isPageLoaded = true;
		//	this.appStore.logout();
    console.log(error);
			console.log("error count :"+this.appStore.global_errorcount);
			this.appStore.global_errorcount=this.appStore.global_errorcount+1;
			if(this.appStore.global_errorcount>20){
			// this.appStore.logout();
			}
		})
	}
  showOperators(usersmodel) {
    this.deleteImg=false;
    let operators = usersmodel;
    try{
      if (operators != null) {
        let x;
        let index = 0;
        for (x in operators) {
          operators[x].admin = operators[x].user_type_id == 3 ? true : false;
          operators[x].label = operators[x].nick_name;
          operators[x].value = index;
          operators[x].isPasswordChanged = false;
          index++;
        }

        this.operatorList = JSON.parse(JSON.stringify(operators));
        this.operators = JSON.parse(JSON.stringify(operators));
        this.appStore.operators=JSON.parse(JSON.stringify(operators));
      }
    }catch(error){
      console.log(error);
    }
  }

  setOpforDept(departments,appstore) {
    try{
    if (departments != null) {
      for (let i = 0; i < departments.length; i++) {
        let operators = departments[i].operators;
        let x;
        for (x in operators) {
          let found = this.operatorList.find(op => {
            return op.user_uid === operators[x].user_uid
          })
          operators[x] = found;
        }
        
      }
      this.clonedDepartments = JSON.parse(JSON.stringify(departments));
    }
  }catch(error){
    console.log(error);
  }
    return departments;
  }

  addNewOperator(operator) {
    if (this.addOperator) {
      //operator.id = this.operators.length + 1;
      operator.id = this.appStore.operators.length + 1;
      operator.maxStats = (!operator.maxStats || operator.maxStats === "" || String(operator.maxStats).slice(0, 1) === '0') ? "Unlimited" : operator.maxStats;
      //  this.operators.push(operator);
      this.operatorToEdit = {
        "label": "",
        "id": 0,
        "pic": "",
        "nick_name": "",
        "real_name": "",
        "role": true,
        "email": "",
        "user_type_id": "",
        "user_uid": "",
        "sms_enabled": "",
        "max_chats": "unlimited",
        "online": true,
        "password": "",
        "pass": "", //for database column name Pass
        "isPasswordChanged": false,
        "confirmpassword": "",
        "roleEditable": true,
        "edit": true,
        "profileImage": false
      }
    }
    this.addOperator = false;
    operator.edit = false;
    operator.maxStats = (!operator.maxStats || operator.maxStats === "" || String(operator.maxStats).slice(0, 1) === '0') ? "Unlimited" : operator.maxStats;
    this.operatorEditing = false;
  }

  cancelAddition(operator) {
    this.deleteImg=false;
    this.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
    this.addOperator = false;
    this.operatorEditing = false;
    operator.edit = false;
    this.operatorToEdit = {
      "label": "",
      "id": 0,
      "pic": "",
      "nick_name": "",
      "real_name": "",
      "role": true,
      "email": "",
      "users_uid": "",
      "user_type_id": "",
      "user_uid": "",
      "sms_enabled": false,
      "max_chats": "",
      "online": true,
      "password": "",
      "pass": "",
      "confirmpassword": "",
      "isPasswordChanged": false,
      "roleEditable": true,
      "edit": true,
      "profileImage": false,
      "image" : "",
      "selectedfile":"",
      "file": "",
    }
    let self = this;
    // this.operators.map(function (optData, optIndex) {
    //   if (optData.cloned && optData.id == optData.cloned.id) {
    //     self.operators[optIndex] = optData.cloned;
    //   }
    // })
    this.appStore.operators.map(function (optData, optIndex)  {
      if (optData.cloned && optData.id == optData.cloned.id) {
        self.appStore.operators[optIndex] = optData.cloned;
      }
    })
  }

  editOperator(operator) {
    if (!this.operatorEditing) {
      operator.cloned = Object.assign({}, operator);
      operator.edit = true;
      operator.password = "";
      operator.confirmpassword = "";
      operator.maxStats = operator.maxStats === "Unlimited" ? '' : operator.maxStats;
      this.operatorEditing = true;
    }
  }

  editDepartment(dept) {
    dept.cloned = Object.assign({}, dept);
    dept.edit = true;
    this.deptEditMode = true;
  }

  deleteDepartment(dept) {

  }
  addNewDept(appstore) {
    for(let i in appstore.userInfo.allwebistes){
    if(appstore.userInfo.allwebistes[i].website_uid===appstore.selectedWebisiteUid){
      this.departToEdit.email_messages=appstore.userInfo.allwebistes[i].email_messages
    }
  }
    this.departToEdit.cloned = Object.assign({}, this.departToEdit);
    this.departToEdit.edit = true;
    this.departments.push(this.departToEdit);
    this.deptEditMode = true;
  }
  cancelDeptAddition(dept) {
    this.departToEdit = {
      "name": "",
      "department_uid": "",
      "email": "",
      "email_messages": "",
      "operators": [],
      "id": null,
      "user_uid": [],
      "websiteUids": []
    }
    dept.edit = false;
    this.departments = JSON.parse(JSON.stringify(this.clonedDepartments));
    //let self = this;
    // this.departments.map(function (deptData, deptIndex) {
    //   if (!deptData.department_uid) {
    //     self.departments.splice(deptIndex, 1);
    //   }
    //   // else if(deptData.id == dept.cloned.id) {
    //   //   self.departments[deptIndex] = dept.cloned;   //commented bcos it add new dept to list by frontend only
    //   // }
    // })
    this.deptEditMode = false;
  }
  saveDepartment(dept) {
    dept.edit = false;
    // if(dept.id === null) {
    //   dept.id=this.departments.length+1;
    // }
    this.departToEdit = {
      "name": "",
      "department_uid": "",
      "email": "",
      "email_messages": "",
      "operators": [],
      "id": null,
      "user_uid": [],
      "websiteUids": []
    }
    this.deptEditMode = false;
  }
  resetValue(dept) {
    dept.edit = false;
    this.departToEdit = {
      "name": "",
      "department_uid": "",
      "email": "",
      "email_messages": "",
      "operators": [],
      "id": null,
      "user_uid": [],
      "websiteUids": []
    }
    this.deptEditMode = false;
  }
  deleteComp(data, name) {
    this.confirm = true;
    this.componentToDelTitle = name;
    this.componentToDel = data;
    if(name=="Department"){
      this.componentToDeltext=" The action cannot be undone.<br/>If any of your widgets are assigned to this department alone, all incoming chats on that widget will ring to all operators after you delete the department.";
    }else{
      this.componentToDeltext="";
    }
  }

  deleteProfileImage(compId) {
    this.confirm = true;
    this.componentToDel = 'Image';
  }

  addExistingOp(operator) {

    this.operatorToEdit = operator;
    this.operatorToEdit.online = true;
    this.operatorToEdit.admin = operator.user_type_id == 3 ? true : false;
    this.operatorToEdit.password ='';
    this.operatorToEdit.confirmpassword = '';
    this.operatorToEdit.isPasswordChanged = false;
    this.operatorToEdit.roleEditable = true;
    this.operatorToEdit.edit = true;
    this.operatorToEdit.profileImage = false;
    this.showExisting = false;
    //for existing user password validation
    this.notExistingUserPassword = true;
  }

  maxDeptCrossed = (appstore) => {
    if (this.departments.length == 0 )
      return false;
    else if (this.departments.length >= appstore.userInfo.accountplan.max_deps && appstore.userInfo.accountplan.max_deps !=0)
      return true;
    else
      return false;
  }

  getmaxDeptContent = (appstore) => {
    if (!appstore.isOwner) {
      return "<p>Your plan includes " + appstore.userInfo.accountsmodel.max_deps + " departments. If you would like to add more departments to your account, please ask " + appstore.userInfo.usersmodel.ownerRealname + ", the account owner, to upgrade the account.</p>"
    } else {
      return "<p style={{fontFamily:'Segoe UI'}}>Your plan includes " + appstore.userInfo.accountplan.max_deps + " departments. If you would like to set up more departments, you have two options:</p> <ul> <li> Upgrade to the Platinum plan, which includes Unlimited departments.</li> <li> Add more departments to your plan for an extra fee.</li> </ul> <p>In both cases you will start a new subscription period. Unused funds will be pro-rated.</p>"
    }
  }
  confirmDeleteallopt = (notrelatedoperators) =>{
    this.deleteallopt = false;
    let deleteallop = notrelatedoperators;
		axios({
			method: 'POST',
			url:this.appStore.url + 'deleteallunassignedopt.json',
			headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
			data: deleteallop,
		}).then(result => {
      this.appStore.userInfo.alloperators =  result.data.alloperators;
      this.appStore.userInfo.alloperatorsnotrelated = result.data.alloperatorsnotrelated;
			this.isPageLoaded = true;
      this.appStore.global_errorcount=0;
		}).catch((error) => {
			// handle error
			//console.log(error);
      this.isPageLoaded = true;
		//	this.appStore.logout();
    console.log(error);
			console.log("error count :"+this.appStore.global_errorcount);
			this.appStore.global_errorcount=this.appStore.global_errorcount+1;
			if(this.appStore.global_errorcount>20){
			// this.appStore.logout();
			}
		})
	}
}
decorate(OperatorsDeptStore, {
  chatRingsDirect: observable,
  showImage: observable,
  operators: observable,
  departments: observable,
  addOperator: observable,
  operatorToEdit: observable,
  operatorEditing: observable,
  departToEdit: observable,
  confirm: observable,
  componentToDel: observable,
  componentToDelTitle: observable,
  clonedDept: observable,
  deptEditMode: observable,
  confirmDeleteImage: observable,
  isPageLoaded: observable,
  emailExist: observable,
  previouswebsite_uid: observable,
  existingOps: observable,
  showExisting: observable,
  operatorList: observable,
  payDollar: observable,
  payDollarforDept: observable,
  errormodel:observable,
  canNotdeleteOwner:observable,
  notExistingUserPassword:observable,
  clonedDepartments:observable,
  cropimage:observable,
  imageCropModel:observable,
  imageRef:observable,
  deleteImg:observable,
  imagewarning:observable,
  warning_image_name:observable,
  fileMimeType:observable,
  op_dptsave:observable,
  deleteImage:observable,
  appStore:observable,
  componentToDeltext:observable,
  removerEmailErr:observable,
  deleteallopt:observable,
  notrelatedoperators:observable,
  viewallopt:observable,
})
export default OperatorsDeptStore;


