import { decorate, observable, extendObservable } from 'mobx';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState } from 'draft-js';
import axios from 'axios';

import { data } from '../../../assets/JSON/admin_script';
import { defualtdata } from '../../../assets/JSON/defualt_script';

class ScriptStore {

  editScriptRef;

  constructor(appStore) {
    this.appStore = appStore;
    this.lanDropDownOptions = this.appStore.languages;
    this.defualtdata = defualtdata;
  
    this.scriptType = [
      {
        "label": "Custom Response",
        "value": 1
      },
      {
        "label": "Related Link",
        "value": 2
      }
    ];

    this.count=0;
    this.selectedLanguage = this.lanDropDownOptions[0];
    this.checkParentClick = true;
    extendObservable(this, {
      responseData: [],
      previewChatData: [],
    });
    this.triggers = [
      { label: "TRIGGER_CUSTOM", value: 1 },
      { label: "TRIGGER_VISITOR_CLICKED", value: 2 },
      { label: "TRIGGER_VISITOR_INVITED", value: 3 },
      { label: "TRIGGER_NO_FOUND_INFORMATION", value: 4 },
      { label: "TRIGGER_ABOUT_ME", value: 5 },
      { label: "TRIGGER_GOODBYE", value: 6 },
      { label: "TRIGGER_HELLO", value: 7 },
      { label: "TRIGGER_I_GOOD", value: 8 },
      { label: "TRIGGER_NO_REACTION", value: 9 },
      { label: "TRIGGER_HOW_IT_WORK", value: 10 },
      { label: "TRIGGER_TALK_WITH_HUMAN", value: 11 },
      { label: "TRIGGER_CONTACT_ME", value: 12 },
      { label: "TRIGGER_TEST", value: 13 },
      { label: "TRIGGER_STUPID", value: 14 },
      { label: "TRIGGER_COOKIES", value: 15 },
      { label: "TRIGGER_THANK_YOU", value: 16 }
    ];
    // this.getScriptitem();
    // this.valangsettings();
    //  this.setEditMode();
    this.addResponseLink = false;
    this.editorEditedValue = "";
    this.openPanel = 0;
    this.editing = false;
    this.confirm = false;
    this.scriptToDelete = {};
    this.validate = false;
    this.validateques = false;
    this.errormodel = false;
    this.isPageLoaded = false;
    this.trueorfalse = false;
    this.previewsend = false;
    this.leadname = 0;
    this.leademail = 0;
    this.leadphone = 0;
    this.languagesetting = "";
    this.leadnamevalid = false;
    this.leademailvalid = false;
    this.leadphonevalid = false;
    this.serachresponse = "";

  }
  clearpreviechat(){
   this.previewChatData= [];
   this.previewsend = false;
    this.leadname = 0;
    this.leademail = 0;
    this.leadphone = 0; 
    this.leadnamevalid = false;
    this.leademailvalid = false;
    this.leadphonevalid = false;
    this.serachresponse = "";
  }
  validatefields(e) {
    if (e.keyWords != "")
      this.validate = false;
    else
      this.validate = true;
  }
  validatequestion(e) {
    if (e.title != "")
      this.validateques = false;
    else
      this.validateques = true;
  }
  valangsettings() {
    let default_Data = this.defualtdata;
    let default_triggers = this.triggers;
    this.isPageLoaded = false;
    if(this.appStore.userInfo.accountsmodel.canUseVirtualAgent == 1){ 
    axios({
      method: 'POST',
      url: this.appStore.url + 'va_languagessetting.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: { "lang": this.selectedLanguage.value }
    }).then(result => {
      if(result.data.length==1){
        if(result.data.logoutstatus==1){
				  this.appStore.logout();
        }
				}
			  
      let languagedata = JSON.parse(result.data.language); 
      this.languagesetting = result.data.language; 
      let customlanguagedata = "";
      if (result.data.data !== "") { customlanguagedata = JSON.parse(result.data.data); }
      let basicquestion = [];
      for (let i in default_Data) {
        let questionvalue = default_Data[i].question;
        let textvalue = default_Data[i].text;
        if (this.selectedLanguage.value === 'en') {
          if (customlanguagedata != "") {
            let customequestionvalue = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === default_Data[i].question.toLowerCase())];
            if (customequestionvalue != undefined && customequestionvalue[1] !== null) {
              if (customequestionvalue[1] != undefined || customequestionvalue[1].length != 0) {
                questionvalue = customequestionvalue[1];
              }
            }
            let text=default_Data[i].text;
          let prompt=false;
          if(default_Data[i].text.indexOf("[prompt lead inputs]") !== -1){
            prompt=true;
            text= default_Data[i].text.replace("[prompt lead inputs]","")
          }
            let custometextvalue = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === text.toLowerCase())];
            if (custometextvalue != undefined && custometextvalue[1] !== null) {
              if (custometextvalue[1] != undefined || custometextvalue[1].length != 0) {
                textvalue = (prompt == true ? custometextvalue[1]+"[prompt lead inputs]" : custometextvalue[1]);
              }
            }
          }
        }

        if (this.selectedLanguage.value !== 'en') {
          let langquestionvalue = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === default_Data[i].question.toLowerCase())];

          if (langquestionvalue != undefined && langquestionvalue[1] !== null) {
            if (langquestionvalue[1] != undefined || langquestionvalue[1].length != 0) {
              if (customlanguagedata != "") {
                let customequestionvalue = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === langquestionvalue[1].toLowerCase())];
                if (customequestionvalue != undefined && customequestionvalue[1] !== null) {
                  if (customequestionvalue[1] != undefined || customequestionvalue[1].length != 0) {
                    questionvalue = customequestionvalue[1];
                  }
                } else {
                  questionvalue = langquestionvalue[1];
                }
              } else {
                questionvalue = langquestionvalue[1];
              }
            }
          }
          let text=default_Data[i].text;
          let prompt=false;
          if(default_Data[i].text.indexOf("[prompt lead inputs]") !== -1){
            prompt=true;
            text= default_Data[i].text.replace("[prompt lead inputs]","")
          }
          let lang_textvalue = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === text.toLowerCase())];
          if (lang_textvalue != undefined && lang_textvalue[1] !== null) {
            if (lang_textvalue[1] != undefined || lang_textvalue[1].length != 0) {
              if (customlanguagedata != "") {
                let cust_textvalue = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === lang_textvalue[1].toLowerCase())];
                if (cust_textvalue != undefined && cust_textvalue[1] !== null) {
                  if (cust_textvalue[1] != undefined || cust_textvalue[1].length != 0) {
                    textvalue = (prompt == true ? cust_textvalue[1]+"[prompt lead inputs]" : cust_textvalue[1]);
                  }
                } else {
                  textvalue = (prompt == true ?  lang_textvalue[1]+"[prompt lead inputs]" :lang_textvalue[1]);
                }
              } else {
                textvalue = (prompt == true ?  lang_textvalue[1]+"[prompt lead inputs]" :lang_textvalue[1]);
              }
            }
          } 

        }
        let keywords = ""; let related1 = ""; let related2 = "";
        let related3 = ""; let trigger = "";
        for (let k in default_triggers) {
          if (default_triggers[k].label == default_Data[i].trigger) {
            trigger = default_triggers[k].value;
          }
        }

        if (default_Data[i].hasOwnProperty("keywords")) {
          for (let k in default_Data[i].keywords) {
            if (keywords !== "") {
              keywords = keywords + ",";
            }
            if (this.selectedLanguage.value === 'en') {
              let keywordsdata = "";
              if (customlanguagedata != "") {
                let cust_keywords = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === default_Data[i].keywords[k].toLowerCase())];
                if (cust_keywords != undefined && cust_keywords[1] !== null) {
                  if (cust_keywords[1] != undefined || cust_keywords[1].length != 0) {
                    keywordsdata = cust_keywords[1];
                    keywords = keywords + keywordsdata;
                  }
                } else {
                  keywords = keywords + default_Data[i].keywords[k];
                }
              } else {
                keywords = keywords + default_Data[i].keywords[k];
              }

            }

            if (this.selectedLanguage.value !== 'en') {
              let keywordsdata = "";
              let lang_keywords = languagedata[Object.keys(languagedata).find(key => key.toLowerCase() === default_Data[i].keywords[k].toLowerCase())];
              if (lang_keywords != undefined && lang_keywords[1] !== null) {
                if (lang_keywords[1] != undefined || lang_keywords[1].length != 0) {
                  if (customlanguagedata != "") {
                    let cust_keywords = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === lang_keywords[1].toLowerCase())];
                    if (cust_keywords != undefined && cust_keywords[1] !== null) {
                      if (cust_keywords[1] != undefined || cust_keywords[1].length != 0) {
                        keywordsdata = cust_keywords[1];
                        keywords = keywords + keywordsdata;
                      }
                    } else {
                      keywordsdata = lang_keywords[1];
                      keywords = keywords + keywordsdata;
                    }
                  } else {
                    keywordsdata = lang_keywords[1];
                    keywords = keywords + keywordsdata;
                  }
                }
                else {
                  keywords = keywords + default_Data[i].keywords[k];
                }
              } else {
                keywords = keywords + default_Data[i].keywords[k];
              }
            }
          }
        }
        if (default_Data[i].hasOwnProperty("relates")) {
          for (let k in default_Data[i].relates) {
            if (k == 0) {
              related1 = default_Data[i].relates[k];
            } else if (k == 1) {
              related2 = default_Data[i].relates[k];
            } else if (k == 2) {
              related3 = default_Data[i].relates[k];
            }
          }
        }
        let useLeadName = 0;
        let useLeadEmail = 0; let useLeadPhone = 0;
        if (default_Data[i].hasOwnProperty("leads")) {
          useLeadName = 1;
          useLeadEmail = 1;
          useLeadPhone = 1;
        }
        var actions = {
          "question": questionvalue, "id": i, "text": textvalue, "keywords": keywords,
          "useLeadName": useLeadName, "useLeadEmail": useLeadEmail, "useLeadPhone": useLeadPhone,
          "related_1": related1, "related_2": related2, "related_3": related3,
          "lang": "", "trigger": trigger, "enable": 1
        }
        basicquestion.push(actions);

      } 
      this.addbasicquestions(basicquestion);
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      // console.log(error);
      // this.appStore.logout();
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appstore.logout();
      }
    });
  }else{
    this.getScriptitem();
  }

  }

  addbasicquestions(data) {
    axios({
      method: 'POST',
      url: this.appStore.url + 'va_ScriptBasicQues.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: { "lang": this.selectedLanguage.value, "website_uid": this.appStore.selectedWebisiteUid, "basicquestion": data }
    }).then(result => {
      // console.log(result);
      this.getScriptitem();
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      // console.log(error);
      // this.appStore.logout();
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
  }

  //script Item Table data
  getScriptitem() {
    
    this.previewChatData = [];
    axios({
      method: 'POST',
      url: this.appStore.url + 'va_invitaionsScriptItem.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: { "lang": this.selectedLanguage.value, "website_uid": this.appStore.selectedWebisiteUid }
    }).then(result => {
      //console.log(result.data);
      // alert();
      if(result.data.length==1){
        if(result.data[0].logoutstatus==1){
          this.appStore.logout();
        }
        }
      var basicActions = [];
      var customResponse = [];
      var relatedLinks = [];
      for (let i in result.data) {
        var enb = false;
        var name = false;
        var email = false;
        var phone = false;
        if (result.data[i].enable == 1) {
          enb = true;
        }
        if (result.data[i].useLeadName == 1) {
          name = true;
        }
        if (result.data[i].useLeadEmail == 1) {
          name = true;
        }
        if (result.data[i].useLeadPhone == 1) {
          name = true;
        }
        var actions = {
          "title": result.data[i].question,
          "id": result.data[i].id,
          "reply": result.data[i].text,
          "type": result.data[i].type,
          "isDefault": result.data[i].isDefault,
          "keyWords": result.data[i].keywords,
          "linkTitle": result.data[i].linkTitle,
          "linkUrl": result.data[i].linkUrl,
          "useLeadName": result.data[i].useLeadName,
          "useLeadEmail": result.data[i].useLeadEmail,
          "useLeadPhone": result.data[i].useLeadPhone,
          "lead_email_target": result.data[i].lead_email_target,
          "related_1": result.data[i].related_1,
          "related_2": result.data[i].related_2,
          "related_3": result.data[i].related_3,
          "lang": result.data[i].lang,
          "trigger": result.data[i].trigger,
          "related_uid": result.data[i].va_response_uid,
          "leadId": [result.data[i].useLeadName, result.data[i].useLeadEmail, result.data[i].useLeadPhone],
          "leadMail": result.data[i].lead_email_target,
          "responseIds": [
            {
              "typeid": 1,
              "id": result.data[i].id,
              "relatedids": result.data[i].related_1
            }, {
              "typeid": 1,
              "id": result.data[i].id,
              "relatedids": result.data[i].related_2
            }, {
              "typeid": 1,
              "id": result.data[i].id,
              "relatedids": result.data[i].related_3
            }],
          "enabled": enb
        }
       // if (result.data[i].isDefault == 1 && result.data[i].type == 1) {
        if (result.data[i].trigger != 1 && result.data[i].type == 1) {

          basicActions.push(actions);
        }
       // if (result.data[i].isDefault == 0 && result.data[i].type == 1) {
        if (result.data[i].trigger ==1 && result.data[i].type == 1) {
          customResponse.push(actions);
        }
        if (result.data[i].trigger ==1 && result.data[i].type == 2) {

          var related_Links = {
            "title": result.data[i].linkTitle,
            "link": result.data[i].linkUrl,
            "type": result.data[i].type,
            "isDefault": result.data[i].isDefault,
            "trigger": result.data[i].trigger,
            "id": result.data[i].id,
            "reply": result.data[i].text,
            "keyWords": result.data[i].keywords,
            "related_uid": result.data[i].va_response_uid,
            "enabled": enb
          }
          relatedLinks.push(related_Links);
        }
      }
      var scriptItmsarray =
      {
        "data": [
          {
            "basicActions": {
              "value": 1,
              "chatTypes": basicActions
            },
            "customResponse": {
              "value": 2,
              "chatTypes": customResponse
            },
            "relatedLinks": {
              "value": 3,
              "chatTypes": relatedLinks
            }

          }
        ]
      }
      this.responseData = scriptItmsarray.data[0];
      this.setEditMode();
      this.isPageLoaded = true;
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      // console.log(error);
      // this.appStore.logout();
      this.isPageLoaded = true;
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });

  }


  onEditorStateChange(editorState) {
    
    this.editorState = editorState;
    this.editorEditedValue = draftToHtml(convertToRaw(this.editorState.getCurrentContent()));
    //console.log(this.editorEditedValue)  
    this.editing = true;
  }

  setLanguage(selectedOption) {
    this.isPageLoaded = false;
    this.selectedLanguage = selectedOption;
    // this.getScriptitem();
    this.valangsettings();
  }

  setScriptType(selectedOption) {
    this.selectedScriptType = selectedOption;
    if (this.selectedScriptType.value === 1) {
      this.addScript();
    }
    else {
      this.addLink();
    }
  }

  setValue(responseid) {
    this.isPageLoaded = false;
    this.selectedRow.enabled = !this.selectedRow.enabled;
    var act_dact = 0;
    if (this.selectedRow.enabled == true) {
      act_dact = 1;
    }
    axios({
      method: 'POST',
      url: this.appStore.url + 'vaAgentScriptsenabledisable.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: { "enable": act_dact, "va_response_uid": responseid }
    }).then(result => {
      //console.log(result.data);  
      if (result.data == 1) {
        this.getScriptitem();
      } else {
        this.errormodel = true;
      }
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      //console.log(error);
      // this.appStore.logout();
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
  }

  preventParentClick() {
    if (this.selectedRow) {
      this.selectedRow.preventClick = true;
    }
  }

  setEditMode() {
    this.responseData.basicActions.chatTypes.map(function (chat, chatIndex) {
      chat.showOptions = false;
      chat.preventClick = false;
    })
    this.responseData.customResponse.chatTypes.map(function (chat, chatIndex) {
      chat.showOptions = false;
      chat.preventClick = false;
    })
    this.responseData.relatedLinks.chatTypes.map(function (chat, chatIndex) {
      chat.showOptions = false;
      chat.preventClick = false;
    })
  }

  editResponse(chatData, chatId) {
    this.selectedScriptType = chatId;
    this.addResponseLink = false;
    if (chatId !== 3) {
      this.selectedDataToEdit = chatData;
      this.selectedLinkToEdit = undefined;
    }
    else {
      this.selectedLinkToEdit = chatData;
      this.selectedDataToEdit = undefined;
    }
  }

  addScript() {
    this.selectedDataToEdit = {
      "title": "",
      "id": this.responseData.basicActions.chatTypes.length,
      "reply": "",
      "keyWords": "",
      "leadId": [0, 0, 0],
      "leadMail": "",
      "responseIds": [{}, {}, {}],
      "enabled": true,
      "va_response_id": ""
    };

    this.selectedLinkToEdit = undefined;
    this.editing = false;
  }

  addLink() {
    this.selectedLinkToEdit = {
      "title": "",
      "link": "",
      "enabled": true,
      "va_response_id": ""
    };

    this.selectedDataToEdit = undefined;
  }

  addResponse() {

    this.selectedScriptType = this.scriptType[0];
    this.addResponseLink = true;
    this.addScript();
  }

  saveResponse(data, addData, varesponseid) {
    this.isPageLoaded = false;
    data.reply = this.editorEditedValue;
    if (data.title != "" && data.keyWords != "") {
      let scriptItems = [];
      let keys = ["website_uid", "trigger", "type", "linkTitle", "linkUrl", "question", "text", "keywords", "related_1", "related_2",
        "related_3", "useLeadName", "useLeadEmail", "useLeadPhone", "enable", "lang", "lead_email_target", "va_response_uid"];
      let list = {}
      list[keys[0]] = this.appStore.selectedWebisiteUid;
      list[keys[1]] = 1;
      list[keys[2]] = 1;
      list[keys[3]] = "";
      list[keys[4]] = "";
      list[keys[5]] = data.title;
      list[keys[6]] = data.reply;
      list[keys[7]] = data.keyWords;
      list[keys[8]] = data.responseIds[0].relatedids;
      list[keys[9]] = data.responseIds[1].relatedids;
      list[keys[10]] = data.responseIds[2].relatedids;
      // list[keys[8]]="";
      // list[keys[9]]="";
      // list[keys[10]]="";
      if (data.leadId[0] == true) { list[keys[11]] = 1; }
      else { list[keys[11]] = 0; }
      if (data.leadId[1] == true) { list[keys[12]] = 1; }
      else { list[keys[12]] = 0; }
      if (data.leadId[2] == true) { list[keys[13]] = 1; }
      else { list[keys[13]] = 0; }
      if (!data.enabled) { list[keys[14]] = 0; } else { list[keys[14]] = 1; }
      list[keys[15]] = this.selectedLanguage.value;
      list[keys[16]] = data.leadMail;
      list[keys[17]] = varesponseid;
      scriptItems.push(list);
      if (addData) {
        axios({
          method: 'POST',
          url: this.appStore.url + 'vaAgentScriptsAdd.json',
          headers: { 'content-type': 'application/json' ,'charset':'utf-8'},
          data: scriptItems
        }).then(result => {
          //  console.log(result.data); 
          if (result.data == 1) {
            this.getScriptitem();
            // this.responseData.customResponse.chatTypes.push(data);
            this.openPanel = 1;
          } else {
            this.errormodel = true;
          }
          this.appStore.global_errorcount=0;
        }).catch((error) => {
          //console.log(error);
          // this.appStore.logout();
          console.log(error);
          console.log("error count :"+this.appStore.global_errorcount);
          this.appStore.global_errorcount=this.appStore.global_errorcount+1;
          if(this.appStore.global_errorcount>20){
          // this.appStore.logout();
          }
        });
      } else {
        scriptItems[0].trigger = data.trigger;
        scriptItems[0].type = data.type; 
        axios({
          method: 'POST',
          url: this.appStore.url + 'vaAgentScriptsUpdate.json',
          headers: { 'content-type': 'application/json','charset':'utf-8' },
          data: scriptItems
        }).then(result => {
          // console.log(result.data);  
          if (result.data == 1) {
            this.validateques = false;
            this.validate = false;
            this.getScriptitem();
          } else {
            this.errormodel = true;
          }
          this.appStore.global_errorcount=0;
        }).catch((error) => {
          //console.log(error);
          // this.appStore.logout();
          this.validateques = false;
          this.validate = false;
          console.log(error);
          console.log("error count :"+this.appStore.global_errorcount);
          this.appStore.global_errorcount=this.appStore.global_errorcount+1;
          if(this.appStore.global_errorcount>20){
          // this.appStore.logout();
          }
        });
      }
      this.selectedDataToEdit = undefined;
      this.addResponseLink = false;
      this.editing = false;
    } else {
      if (data.keyWords == "") {
        this.validate = true;
      } else {
        this.validate = false;
      }

      if (data.title == "") {
        this.validateques = true;
      }
      else { this.validateques = false; }

      this.isPageLoaded = true;
      window.scrollTo(0, 800);

    }

  }
  deleteRule(srcipt) {
    this.scriptToDelete = srcipt;
    this.confirm = true;
  }
  confirmDeleteRule() {
    this.isPageLoaded = false;
    var self = this;
    axios({
      method: 'POST',
      url: this.appStore.url + 'vaAgentScriptsDelete.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: { "va_response_uid": self.scriptToDelete.related_uid, "lang": this.selectedLanguage.value, "website_uid": this.appStore.selectedWebisiteUid }
    }).then(result => {
      // console.log(result.data); 
      if (result.data == 1) {
        this.getScriptitem();
      } else {
        this.errormodel = true;
      }
      this.appStore.global_errorcount=0;
    }).catch((error) => {
      //console.log(error);
      // this.appStore.logout();
      console.log(error);
      console.log("error count :"+this.appStore.global_errorcount);
      this.appStore.global_errorcount=this.appStore.global_errorcount+1;
      if(this.appStore.global_errorcount>20){
      // this.appStore.logout();
      }
    });
    this.scriptToDelete = undefined;
    this.confirm = false;
  }
  cancelSaveResponse() {
    this.getScriptitem();
    this.previewsend = false;
    this.leadname = 0;
    this.leademail = 0;
    this.leadphone = 0; 
    this.leadnamevalid = false;
    this.leademailvalid = false;
    this.leadphonevalid = false;
    this.serachresponse = "";
    this.validateques = false;
    this.validate = false;
    this.selectedDataToEdit = undefined;
    this.addResponseLink = false;
    this.editing = false;
  }

  cancelSaveLink() {
    this.getScriptitem();
    this.selectedLinkToEdit = undefined;
    this.addResponseLink = false;
  }

  saveLink(data, addData, varesponseid) {
    this.isPageLoaded = false;
    let scriptItems = [];
    let keys = ["website_uid", "trigger", "type", "linkTitle", "linkUrl", "question", "text", "keywords", "related_1", "related_2",
      "related_3", "useLeadName", "useLeadEmail", "useLeadPhone", "enable", "lang", "lead_email_target", "va_response_uid"];
    let list = {}
    list[keys[0]] = this.appStore.selectedWebisiteUid;
    list[keys[1]] = 1;
    list[keys[2]] = 2;
    list[keys[3]] = data.title;
    list[keys[4]] = data.link;
    list[keys[5]] = "";
    list[keys[6]] = "";
    list[keys[7]] = "";
    list[keys[8]] = "";
    list[keys[9]] = "";
    list[keys[10]] = "";
    list[keys[11]] = "";
    list[keys[12]] = "";
    list[keys[13]] = "";
    if (!data.enabled) { list[keys[14]] = 0; } else { list[keys[14]] = 1; }
    list[keys[15]] = this.selectedLanguage.value;
    list[keys[16]] = "";
    list[keys[17]] = varesponseid;
    scriptItems.push(list);
    if (addData) {
      axios({
        method: 'POST',
        url: this.appStore.url + 'vaAgentScriptsAdd.json',
        headers: { 'content-type': 'application/json','charset':'utf-8' },
        data: scriptItems
      }).then(result => {
        // console.log(result.data); 
        if (result.data == 1) {
          this.getScriptitem();
          this.openPanel = 2;
          // this.responseData.relatedLinks.chatTypes.push(data);  
        } else {
          this.errormodel = true;
        }
        this.appStore.global_errorcount=0;
      }).catch((error) => {
        //console.log(error);
        // this.appStore.logout();
        console.log(error);
        console.log("error count :"+this.appStore.global_errorcount);
        this.appStore.global_errorcount=this.appStore.global_errorcount+1;
        if(this.appStore.global_errorcount>20){
        // this.appStore.logout();
        }
      });
    } else {
      axios({
        method: 'POST',
        url: this.appStore.url + 'vaAgentScriptsUpdate.json',
        headers: { 'content-type': 'application/json','charset':'utf-8' },
        data: scriptItems
      }).then(result => {
        // console.log(result.data);  
        if (result.data == 1) {
          this.getScriptitem();
        } else {
          this.errormodel = true;
        }
        this.appStore.global_errorcount=0;
      }).catch((error) => {
        //console.log(error);
        // this.appStore.logout();
        console.log(error);
        console.log("error count :"+this.appStore.global_errorcount);
        this.appStore.global_errorcount=this.appStore.global_errorcount+1;
        if(this.appStore.global_errorcount>20){
        // this.appStore.logout();
        }
      });
    }
    this.selectedLinkToEdit = undefined;
    this.addResponseLink = false;
  }

  setEditFormLead(leadPos, reply, id) {

    // let editorText= draftToHtml(convertToRaw(this.editorState.getCurrentContent()));
    this.selectedDataToEdit.leadId[leadPos] = !this.selectedDataToEdit.leadId[leadPos];
    //  if(this.selectedDataToEdit.leadId[leadPos]){
    //   editorText=editorText+"<p>----Hello---</p>"
    //  }     
    //  this.selectedDataToEdit.reply=editorText;
    //  for(let i in this.selectedDataToEdit.leadId){
    //    if(this.selectedDataToEdit.leadId[i]){
    //     this.trueorfalse=true;
    //     break;
    //    }
    //  }
    //  if(this.selectedDataToEdit.leadId[0]==false && this.selectedDataToEdit.leadId[1]==false && this.selectedDataToEdit.leadId[2]==false){
    //   this.trueorfalse=false;
    //  }   

    //  if(this.trueorfalse){       
    //  this.selectedDataToEdit.reply=reply+"editData.reply";
    //  }else{
    //   this.selectedDataToEdit.reply="editData";
    //  }
  }
  //  defualt basic questions for all 
  // let default_Data = this.defualtdata;
  // var actions = {
  //   "title": default_Data[1].question, "id": 0, "reply": default_Data[1].text,
  //   "keyWords": "", "linkTitle": "", "linkUrl": "", "useLeadName": "", "useLeadEmail": "",
  //   "useLeadPhone": "", "lead_email_target": "", "related_1": "", "related_2": "",
  //   "related_3": "", "lang": "", "trigger": "", "related_uid": "", "leadId": [0, 0, 0],
  //   "leadMail": "", "responseIds": [{}, {}, {}], "enabled": false
  // }
  // // let relatedData = this.responseData.basicActions.chatTypes[7]; 
  // let relatedData = actions;
  // let matched = 0;
  // for (let i in default_Data) {
  //   default_Data[i].keywords.map(function (key, a) {
  //     if (key.toLowerCase().indexOf(data.toLowerCase()) !== -1) {
  //       matched = 1;
  //       var actions = {
  //         "title": default_Data[i].question, "id": 0, "reply": default_Data[i].text,
  //         "keyWords": "", "linkTitle": "", "linkUrl": "", "useLeadName": "",
  //         "useLeadEmail": "", "useLeadPhone": "", "lead_email_target": "", "related_1": "",
  //         "related_2": "", "related_3": "", "lang": "", "trigger": "", "related_uid": "",
  //         "leadId": [0, 0, 0], "leadMail": "", "responseIds": [{}, {}, {}], "enabled": false
  //       }
  //       relatedData = actions;
  //     }
  //   })
  //   if (matched == 1)
  //     break;

  // }
  getChatData(data) {
    let basic = 0;
    let custom = 0;
    let default_Data = this.defualtdata;
    var actions = {
      "title": default_Data[1].question, "id": 0, "reply": default_Data[1].text,
      "keyWords": "", "linkTitle": "", "linkUrl": "", "useLeadName": "", "useLeadEmail": "",
      "useLeadPhone": "", "lead_email_target": "", "related_1": "", "related_2": "",
      "related_3": "", "lang": "", "trigger": "", "related_uid": "", "leadId": [0, 0, 0],
      "leadMail": "", "responseIds": [{}, {}, {}], "enabled": false
    }
    let relatedData = "";
    let matched = 0;
    // this.responseData.basicActions.chatTypes.map(function (chat, chatIndex) {
    let basicactions = this.responseData.basicActions.chatTypes;
    for (let i in basicactions) {
      // if (chat.keyWords.indexOf(data) !== -1 || chat.title.indexOf(data) !== -1 || chat.reply.indexOf(data) !== -1)
      // if (chat.keyWords.indexOf(data) !== -1) {
      //   relatedData = chat;
      // }
      if (basicactions[i].hasOwnProperty("keyWords")) {
        let keys = basicactions[i].keyWords.split(',')
        keys.map(function (key, a) {
          if (key.toLowerCase().indexOf(data.toLowerCase()) !== -1) {
            matched = 1;
            basic=0;
            relatedData = basicactions[i];
          }
        });
      }
      if (matched == 1)
        break;
    };
    if (basic == 0) {
      let customeactions = this.responseData.customResponse.chatTypes; 
      // this.responseData.customResponse.chatTypes.map(function (chat, chatIndex) {
      for (let i in customeactions) {
        // if (chat.keyWords.indexOf(data) !== -1 || chat.title.indexOf(data) !== -1 || chat.reply.indexOf(data) !== -1
        if (customeactions[i].hasOwnProperty("keyWords")) {
          let keys = customeactions[i].keyWords.split(',')
          keys.map(function (key, a) {
            if (key.toLowerCase().indexOf(data.toLowerCase()) !== -1) {
              matched = 1;
              custom =0;
              relatedData = customeactions[i];
            }

          });
        }
        if (matched == 1)
          break;
      };
    }
    if (basic == 0 && custom == 0) {
      let relatedactions = this.responseData.relatedLinks.chatTypes;
      // this.responseData.relatedLinks.chatTypes.map(function (chat, chatIndex) {
      for (let i in relatedactions) {
        if (relatedactions[i].title.toLowerCase().indexOf(data.toLowerCase()) !== -1) {
          matched = 1;
          relatedData = relatedactions[i];
        }
        if (matched == 1)
          break;
      };
    }
    return relatedData;
  }

  setChatData(userData, chatId) {
    let chatObj = {
    };
    this.previewsend = true;
    if (this.selectedRow && this.previewChatData.length === 0) {
      this.serachresponse = this.selectedRow;
      let changedreplay = this.selectedRow.reply;
      this.leadname = 0; this.leademail = 0; this.leadphone = 0;
      this.leadnamevalid = false; this.leademailvalid = false; this.leadphonevalid = false;
      chatObj.responseChat=this.selectedRow.responseIds
      if (this.selectedRow.hasOwnProperty("leadId")) {
        if (this.selectedRow.leadId[0] == 1 || this.selectedRow.leadId[1] == 1 || this.selectedRow.leadId[2] == 1) {
          if (this.selectedRow.leadId[0] == 1) { this.leadname = 1; }
          if (this.selectedRow.leadId[1] == 1) { this.leademail = 1; }
          if (this.selectedRow.leadId[2] == 1) { this.leadphone = 1; }
          let namedata = ""; let emaildata = ""; let phonedata = "";
          let con =   (this.selectedRow.leadId[0] == 1 && this.selectedRow.leadId[1] != 1 && this.selectedRow.leadId[2] != 1) ? "<p>Please leave your name, and we will contact you shortly</p>"
                    : (this.selectedRow.leadId[0] != 1 && this.selectedRow.leadId[1] == 1 && this.selectedRow.leadId[2] != 1) ? "<p>Please leave your Email, and we will contact you shortly</p>"
                    : (this.selectedRow.leadId[0] != 1 && this.selectedRow.leadId[1] != 1 && this.selectedRow.leadId[2] == 1) ? "<p>Please leave your Phone number, and we will contact you shortly</p>"
                    : (this.selectedRow.leadId[0] == 1 && this.selectedRow.leadId[1] == 1 && this.selectedRow.leadId[2] != 1) ? "<p>Please leave your name, Email, and we will contact you shortly</p>" 
                    : (this.selectedRow.leadId[0] != 1 && this.selectedRow.leadId[1] == 1 && this.selectedRow.leadId[2] == 1) ? "<p>Please leave your Email,Phone number, and we will contact you shortly</p>"
                    : (this.selectedRow.leadId[0] == 1 && this.selectedRow.leadId[1] != 1 && this.selectedRow.leadId[2] == 1) ? "<p>Please leave your name,Phone number, and we will contact you shortly</p>" 
                    : "";
          
          if (this.leadname == 1) { 
            this.leadnamevalid = true;
            let name = "Please enter your name";
            namedata = this.searchlanguagesetting(name);
            chatObj.responseChat=[];
            if (this.selectedRow.leadId[1] == 1) { this.leademailvalid = true; }
            if (this.selectedRow.leadId[2] == 1) { this.leadphonevalid = true; }
          } else if (this.leademail == 1) { 
            this.leademailvalid = true;
            let email = "Please enter your email address";
            emaildata = this.searchlanguagesetting(email);
            chatObj.responseChat=[];
            if (this.selectedRow.leadId[0] == 1) { this.leadnamevalid = true; }
            if (this.selectedRow.leadId[2] == 1) { this.leadphonevalid = true; }
          } else if(this.leadphone == 1){
            this.leadphonevalid = true;
            let ph = "Please enter your phone number";
            chatObj.responseChat=[];
            phonedata = this.searchlanguagesetting(ph);
            if (this.selectedRow.leadId[0] == 1) { this.leadnamevalid = true; }
            if (this.selectedRow.leadId[1] == 1) { this.leademailvalid = true; }
          } 


          let leads = this.selectedRow.reply.substring(0, this.selectedRow.reply.indexOf('[prompt lead inputs]'));
          let rep = this.selectedRow.reply.indexOf("[prompt lead inputs]") == -1 ? this.selectedRow.reply : leads + con;
          changedreplay = rep + "<p>" + namedata + "</p><p>" + emaildata + "</p><p>" + phonedata + "</p>";
        }
      }
      chatObj.userChat = "";
      // chatObj.replyChat = this.selectedRow.reply;
      chatObj.replyChat = changedreplay;
      if (chatId === 3) {
        chatObj.replyChat = this.selectedRow.link ? '<a class="reply cursor-pointer" target="_blank" href=' + this.selectedRow.link + '>' + this.selectedRow.title + '</p>' : "";
      }
      // chatObj.responseChat = this.selectedRow.responseIds;
    }
    else if (userData && userData != "") {
      chatObj.userChat = userData;
      let response = this.serachresponse; 
      // chatObj.replyChat = response.reply;
      // chatObj.responseChat = response.responseIds;
      chatObj.responseChat = response.responseIds;
      if (this.leadnamevalid == false && this.leademailvalid == false && this.leadphonevalid == false) {
        response = this.getChatData(userData);
        this.serachresponse = response;
        let changedreplay = response.reply;
        if (response.hasOwnProperty("leadId")){
        if (response.leadId[0] == 1 || response.leadId[1] == 1 || response.leadId[2] == 1) {

          this.leadname = 0; this.leademail = 0; this.leadphone = 0;
          this.leadnamevalid = false; this.leademailvalid = false; this.leadphonevalid = false;
          if (response.leadId[0] == 1) { this.leadname = 1; }
          if (response.leadId[1] == 1) { this.leademail = 1; }
          if (response.leadId[2] == 1) { this.leadphone = 1; }
          let namedata = ""; let emaildata = ""; let phonedata = "";
          if (this.leadname == 1) {
            this.leadnamevalid = true;
            let name = "Please enter your name";
            chatObj.responseChat=[];
            namedata = this.searchlanguagesetting(name);
            if (response.leadId[1] == 1) { this.leademailvalid = true; }
            if (response.leadId[2] == 1) { this.leadphonevalid = true; }
          } else if (this.leademail == 1) {
            this.leademailvalid = true;
            let email = "Please enter your email address";
            chatObj.responseChat=[];
            emaildata = this.searchlanguagesetting(email);
            if (response.leadId[0] == 1) { this.leadnamevalid = true; }
            if (response.leadId[2] == 1) { this.leadphonevalid = true; }
          } else  if(this.leadphone == 1){
            this.leadphonevalid = true;
            let ph = "Please enter your phone number";
            chatObj.responseChat=[];
            phonedata = this.searchlanguagesetting(ph);
            if (response.leadId[0] == 1) { this.leadnamevalid = true; }
            if (response.leadId[1] == 1) { this.leademailvalid = true; }
          }
          let leads = response.reply.substring(0, response.reply.indexOf('[prompt lead inputs]'));
          let rep = response.reply.indexOf("[prompt lead inputs]") == -1 ? response.reply : leads;
          changedreplay = rep + "<p>" + namedata + "</p><p>" + emaildata + "</p><p>" + phonedata + "</p>";
        }
      }
        chatObj.replyChat = changedreplay;
        chatObj.responseChat = response.responseIds;
      } else {
        let thankFornamedata = ""; let thankForemaildata = ""; let thankForphonedata = "";
        let emaildata = ""; let phonedata = "";
        if (this.leadnamevalid == true) {
          let thankForname = "Thank you!";
          thankFornamedata = this.searchlanguagesetting(thankForname);
          let email = "";
          if (response.leadId[1] == 1) {
            email = "Please enter your email address";
            chatObj.responseChat=[];
            emaildata = this.searchlanguagesetting(email);
          }
          this.leadnamevalid = false;
          this.leadname = 0;
        } else if (this.leademailvalid == true) {
          let thankForemail = "Thank you!";
          thankForemaildata = this.searchlanguagesetting(thankForemail);
          let phone = "";
          if (response.leadId[2] == 1) {
            phone = "Please enter your phone number";
            chatObj.responseChat=[];
            phonedata = this.searchlanguagesetting(phone);
          }
          this.leademailvalid = false;
          this.leademail = 0;
        } else if (this.leadphonevalid == true) {
          let thankForph = "Thank you!";
          thankForphonedata = this.searchlanguagesetting(thankForph);
          this.leadphonevalid = false;
          this.leadphone = 0;
        } 

        let leads = response.reply.substring(response.reply.indexOf('[prompt lead inputs]') + 20);
        let rep = (this.leadnamevalid == false && this.leademailvalid == false && this.leadphonevalid == false) ? leads : "";
     
        let thanksdata = thankFornamedata + thankForemaildata + thankForphonedata;
        let email_phone = "<p>" + emaildata + "</p><p>" + phonedata + "<p/>";
        let thanks = (thanksdata != "") ? "<p>" + thanksdata + "<p/>" : "";
        let email_phonedata = (email_phone != "") ? "<p>" + email_phone + "<p/>" : "";
        let changedreplay = thanks + email_phonedata + rep;
        chatObj.replyChat = changedreplay;
        // chatObj.responseChat = response.responseIds;
      }

    }
    else {
      chatObj.userChat = "";
      chatObj.replyChat = "";
      chatObj.responseChat = [];
    }
    this.previewChatData.push(chatObj);

  }
  searchlanguagesetting(texts) {
   //(texts);
    // let langs = JSON.parse(this.languagesetting); 
    let langs = this.languagesetting; 
    let langquestionvalue = langs[Object.keys(langs).find(key => key.toLowerCase() === texts.toLowerCase())];
    if (langquestionvalue != undefined && langquestionvalue[1] !== null) {
      if (langquestionvalue[1] != undefined && langquestionvalue[1].length != 0) {
        return langquestionvalue[1];
      }
    }
    return texts;
  }

  setRelatedResponseData(value, type) {
    this.selectedDataToEdit.responseIds[type - 1] = { "typeid": value.type, "id": value.value, "relatedids": value.related_id };
  }

  addChatToPreview(related_response) { 
    let chatObj = {
    };
    chatObj.userChat = "";
    chatObj.replyChat = "";
    chatObj.responseChat = [];
    let matched = 0;
    // let relatedData = this.responseData.basicActions.chatTypes[7];
    if (related_response.typeid === 1) {
      let basicactions = this.responseData.basicActions.chatTypes;
      // this.responseData.basicActions.chatTypes.map(function (chat, chatIndex) {        
      for (let i in basicactions) {
        if (basicactions[i].related_uid === related_response.response.related_uid) { 
          this.serachresponse = basicactions[i];
          matched = 1;
          let changedreplay = basicactions[i].reply; 
          chatObj.responseChat = basicactions[i].responseIds;
          this.leadname = 0; this.leademail = 0; this.leadphone = 0;
          this.leadnamevalid = false; this.leademailvalid = false; this.leadphonevalid = false;
          if (basicactions[i].hasOwnProperty("leadId")) {
            if (basicactions[i].leadId[0] == 1 || basicactions[i].leadId[1] == 1 || basicactions[i].leadId[2] == 1) {
              if (basicactions[i].leadId[0] == 1) { this.leadname = 1; }
              if (basicactions[i].leadId[1] == 1) { this.leademail = 1; }
              if (basicactions[i].leadId[2] == 1) { this.leadphone = 1; }
              let namedata = ""; let emaildata = ""; let phonedata = ""; 
              let con = (basicactions[i].leadId[0] == 1 && basicactions[i].leadId[1] != 1 && basicactions[i].leadId[2] != 1) ? "<p>Please leave your name, and we will contact you shortly</p>"
              : (basicactions[i].leadId[0] != 1 && basicactions[i].leadId[1] == 1 && basicactions[i].leadId[2] != 1) ? "<p>Please leave your Email, and we will contact you shortly</p>"
              : (basicactions[i].leadId[0] != 1 && basicactions[i].leadId[1] != 1 && basicactions[i].leadId[2] == 1) ? "<p>Please leave your Phone number, and we will contact you shortly</p>"
              : (basicactions[i].leadId[0] == 1 && basicactions[i].leadId[1] == 1 && basicactions[i].leadId[2] != 1) ? "<p>Please leave your name, Email, and we will contact you shortly</p>" 
              : (basicactions[i].leadId[0] != 1 && basicactions[i].leadId[1] == 1 && basicactions[i].leadId[2] == 1) ? "<p>Please leave your Email,Phone number, and we will contact you shortly</p>"
              : (basicactions[i].leadId[0] == 1 && basicactions[i].leadId[1] != 1 && basicactions[i].leadId[2] == 1) ? "<p>Please leave your name,Phone number, and we will contact you shortly</p>" 
              : "";
              if (this.leadname == 1) {
                this.leadnamevalid = true;
                let name = "Please enter your name";
                chatObj.responseChat = [];
                namedata = this.searchlanguagesetting(name);
                if (basicactions[i].leadId[1] == 1) { this.leademailvalid = true; }
                if (basicactions[i].leadId[2] == 1) { this.leadphonevalid = true; }
              } else if (this.leademail == 1) { 
                this.leademailvalid = true;
                let email = "Please enter your email address";
                chatObj.responseChat = [];
                emaildata = this.searchlanguagesetting(email);
                if (basicactions[i].leadId[0] == 1) { this.leadnamevalid = true; }
                if (basicactions[i].leadId[2] == 1) { this.leadphonevalid = true; }
              } else {
                this.leadphonevalid = true;
                let ph = "Please enter your phone number";
                chatObj.responseChat = [];
                phonedata = this.searchlanguagesetting(ph);
                if (basicactions[i].leadId[0] == 1) { this.leadnamevalid = true; }
                if (basicactions[i].leadId[1] == 1) { this.leademailvalid = true; }
              } 


              let leads = basicactions[i].reply.substring(0, basicactions[i].reply.indexOf('[prompt lead inputs]'));
              let rep = basicactions[i].reply.indexOf("[prompt lead inputs]") == -1 ? basicactions[i].reply : leads + con;
              changedreplay = rep + "<p>" + namedata + "</p><p>" + emaildata + "</p><p>" + phonedata + "</p>";
            }
          }
          chatObj.userChat = '';
          chatObj.replyChat = changedreplay;
          // chatObj.responseChat = basicactions[i].responseIds;
        }
        if (matched == 1)
          break;

      }
    }

    if (related_response.typeid === 2) {
      // this.responseData.customResponse.chatTypes.map(function (chat, chatIndex) {
      //   if (chat.id === related_response.id) {
      //     chatObj.userChat = '';
      //     chatObj.replyChat = chat.reply;
      //     chatObj.responseChat = chat.responseIds;
      //   }
      // });      
      let customeactions = this.responseData.customResponse.chatTypes;

      for (let i in customeactions) {
        if (customeactions[i].related_uid === related_response.response.related_uid) {
          this.serachresponse = customeactions[i];
          matched = 1;
          let changedreplay = customeactions[i].reply;
          chatObj.responseChat = customeactions[i].responseIds;
          this.leadname = 0; this.leademail = 0; this.leadphone = 0;
          this.leadnamevalid = false; this.leademailvalid = false; this.leadphonevalid = false;
          if (customeactions[i].hasOwnProperty("leadId")) {
            if (customeactions[i].leadId[0] == 1 || customeactions[i].leadId[1] == 1 || customeactions[i].leadId[2] == 1) {
              if (customeactions[i].leadId[0] == 1) { this.leadname = 1; }
              if (customeactions[i].leadId[1] == 1) { this.leademail = 1; }
              if (customeactions[i].leadId[2] == 1) { this.leadphone = 1; }
              let namedata = ""; let emaildata = ""; let phonedata = "";
              let con = (customeactions[i].leadId[0] == 1 && customeactions[i].leadId[1] != 1 && customeactions[i].leadId[2] != 1) ? "<p>Please leave your name, and we will contact you shortly</p>"
              : (customeactions[i].leadId[0] != 1 && customeactions[i].leadId[1] == 1 && customeactions[i].leadId[2] != 1) ? "<p>Please leave your Email, and we will contact you shortly</p>"
              : (customeactions[i].leadId[0] != 1 && customeactions[i].leadId[1] != 1 && customeactions[i].leadId[2] == 1) ? "<p>Please leave your Phone number, and we will contact you shortly</p>"
              : (customeactions[i].leadId[0] == 1 && customeactions[i].leadId[1] == 1 && customeactions[i].leadId[2] != 1) ? "<p>Please leave your name, Email, and we will contact you shortly</p>" 
              : (customeactions[i].leadId[0] != 1 && customeactions[i].leadId[1] == 1 && customeactions[i].leadId[2] == 1) ? "<p>Please leave your Email,Phone number, and we will contact you shortly</p>"
              : (customeactions[i].leadId[0] == 1 && customeactions[i].leadId[1] != 1 && customeactions[i].leadId[2] == 1) ? "<p>Please leave your name,Phone number, and we will contact you shortly</p>" 
              : "";
              if (this.leadname == 1) {
                 this.leadnamevalid = true;
                let name = "Please enter your name";
                chatObj.responseChat = [];
                namedata = this.searchlanguagesetting(name);
                if (customeactions[i].leadId[1] == 1) { this.leademailvalid = true; }
                if (customeactions[i].leadId[2] == 1) { this.leadphonevalid = true; }
              } else if (this.leademail == 1) {
                this.leademailvalid = true;
                let email = "Please enter your email address";
                chatObj.responseChat = [];
                emaildata = this.searchlanguagesetting(email);
                if (customeactions[i].leadId[0] == 1) { this.leadnamevalid = true; }
                if (customeactions[i].leadId[2] == 1) { this.leadphonevalid = true; }
              } else {
                 this.leadphonevalid = true;
                let ph = "Please enter your phone number";
                chatObj.responseChat = [];
                phonedata = this.searchlanguagesetting(ph);
                if (customeactions[i].leadId[0] == 1) { this.leadnamevalid = true; }
                if (customeactions[i].leadId[1] == 1) { this.leademailvalid = true; }
              } 


              let leads = customeactions[i].reply.substring(0, customeactions[i].reply.indexOf('[prompt lead inputs]'));
              let rep = customeactions[i].reply.indexOf("[prompt lead inputs]") == -1 ? customeactions[i].reply : leads + con;
              changedreplay = rep + "<p>" + namedata + "</p><p>" + emaildata + "</p><p>" + phonedata + "</p>";

            }
          }
          chatObj.userChat = '';
          chatObj.replyChat = changedreplay;
          // chatObj.responseChat = customeactions[i].responseIds;
        }
        if (matched == 1)
          break;

      }
    }
    this.previewChatData.push(chatObj);
  }

}

decorate(ScriptStore, {
  selectedLanguage: observable,
  selectedScriptType: observable,
  checkParentClick: observable,
  selectedRow: observable,
  selectedDataToEdit: observable,
  selectedLinkToEdit: observable,
  addResponseLink: observable,
  userEnteredChat: observable,
  replyChat: observable,
  responseChat: observable,
  responseDropDownData: observable,
  chatUserData: observable,
  editorState: observable,
  previewOnFocus: observable,
  openPanel: observable,
  editing: observable,
  scrollToAmt: observable,
  confirm: observable,
  scriptToDelete: observable,
  validate: observable,
  validateques: observable,
  errormodel: observable,
  isPageLoaded: observable,
  trueorfalse: observable,
  previewsend: observable,
  defualtdata: observable,
  triggers: observable,
  leadname: observable,
  leademail: observable,
  leadphone: observable,
  languagesetting: observable,
  leadnamevalid: observable,
  leademailvalid: observable,
  leadphonevalid: observable,
  serachresponse: observable,
  count:observable
})
export default ScriptStore;
