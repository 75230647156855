import { decorate, observable, extendObservable } from 'mobx';
import axios from 'axios';

class WebsiteWidgetStore {


  constructor() {
    this.tourGuideStart=false;
    this.websiteWidgetDetails = [];
    this.getCode = false;
    this.sendCode = false;
    this.advancedcode=false;
    this.confirmDelete = false;
    this.thumbnailHeight = 0;
    this.selectedWebsite = "";
    this.widgetfordelete = "";
    this.widgetThemes = [];
    this.widgetDetails = {}
    this.getcodeerror="";
    this.iswidgetPageLoaded=false;

    this.topBarColors = [
      { value: 'black', label: 'Black', colorCode: '#000000' },
      { value: 'blue', label: 'Blue', colorCode: '#0481B0' },
      { value: 'green', label: 'Green', colorCode: '#608706' },
      { value: 'orange', label: 'Orange', colorCode: '#D6680B' },
      { value: 'pink', label: 'Pink', colorCode: '#B0097D' },
      { value: 'red', label: 'Red', colorCode: '#9D0A09' },
      { value: 'teal', label: 'Teal', colorCode: '#434E55' },
      { value: 'custom', label: 'Custom', colorCode: '#000000' }
    ];

    this.iconLibraryFiles = [
      { value: 1, icon: "glass" },
      { value: 2, icon: "music" },
      { value: 3, icon: "search" },
      { value: 4, icon: "envelope-o" },
      { value: 5, icon: "heart" },
      { value: 6, icon: "star" },
      { value: 7, icon: "star-o" },
      { value: 8, icon: "user" },
      { value: 9, icon: "film" },
      { value: 10, icon: "th-large" },
      { value: 11, icon: "th" },
      { value: 12, icon: "th-list" },
      { value: 13, icon: "check" },
      { value: 14, icon: "remove" },
      { value: 15, icon: "close" },
      { value: 16, icon: "times" },
      { value: 17, icon: "search-plus" },
      { value: 18, icon: "search-minus" },
      { value: 19, icon: "power-off" },
      { value: 20, icon: "signal" },
      { value: 21, icon: "gear" },
      { value: 22, icon: "cog" },
      { value: 23, icon: "trash-o" },
      { value: 24, icon: "home" },
      { value: 25, icon: "file-o" },
      { value: 26, icon: "clock-o" },
      { value: 27, icon: "road" },
      { value: 28, icon: "download" },
      { value: 29, icon: "arrow-circle-o-down" },
      { value: 30, icon: "arrow-circle-o-up" },
      { value: 31, icon: "inbox" },
      { value: 32, icon: "play-circle-o" },
      { value: 33, icon: "rotate-right" },
      { value: 34, icon: "repeat" },
      { value: 35, icon: "refresh" },
      { value: 36, icon: "list-alt" },
      { value: 37, icon: "lock" },
      { value: 38, icon: "flag" },
      { value: 39, icon: "headphones" },
      { value: 40, icon: "volume-off" },
      { value: 41, icon: "volume-down" },
      { value: 42, icon: "volume-up" },
      { value: 43, icon: "qrcode" },
      { value: 44, icon: "barcode" },
      { value: 45, icon: "tag" },
      { value: 46, icon: "tags" },
      { value: 47, icon: "book" },
      { value: 48, icon: "bookmark" },
      { value: 49, icon: "print" },
      { value: 50, icon: "camera" },
      { value: 51, icon: "font" },
      { value: 52, icon: "bold" },
      { value: 53, icon: "italic" },
      { value: 54, icon: "text-height" },
      { value: 55, icon: "text-width" },
      { value: 56, icon: "align-left" },
      { value: 57, icon: "align-center" },
      { value: 58, icon: "align-right" },
      { value: 59, icon: "align-justify" },
      { value: 60, icon: "list" },
      { value: 61, icon: "dedent" },
      { value: 62, icon: "outdent" },
      { value: 63, icon: "indent" },
      { value: 64, icon: "video-camera" },
      { value: 65, icon: "photo" },
      { value: 66, icon: "image" },
      { value: 67, icon: "picture-o" },
      { value: 68, icon: "pencil" },
      { value: 69, icon: "map-marker" },
      { value: 70, icon: "adjust" },
      { value: 71, icon: "tint" },
      { value: 72, icon: "edit" },
      { value: 73, icon: "pencil-square-o" },
      { value: 74, icon: "share-square-o" },
      { value: 75, icon: "check-square-o" },
      { value: 76, icon: "arrows" },
      { value: 77, icon: "step-backward" },
      { value: 78, icon: "fast-backward" },
      { value: 79, icon: "backward" },
      { value: 80, icon: "play" },
      { value: 81, icon: "pause" },
      { value: 82, icon: "stop" },
      { value: 83, icon: "forward" },
      { value: 84, icon: "fast-forward" },
      { value: 85, icon: "step-forward" },
      { value: 86, icon: "eject" },
      { value: 87, icon: "chevron-left" },
      { value: 88, icon: "chevron-right" },
      { value: 89, icon: "plus-circle" },
      { value: 90, icon: "minus-circle" },
      { value: 91, icon: "times-circle" },
      { value: 92, icon: "check-circle" },
      { value: 93, icon: "question-circle" },
      { value: 94, icon: "info-circle" },
      { value: 95, icon: "crosshairs" },
      { value: 96, icon: "times-circle-o" },
      { value: 97, icon: "check-circle-o" },
      { value: 98, icon: "ban" },
      { value: 99, icon: "arrow-left" },
      { value: 100, icon: "arrow-right" },
      { value: 101, icon: "arrow-up" },
      { value: 102, icon: "arrow-down" },
      { value: 103, icon: "mail-forward" },
      { value: 104, icon: "share" },
      { value: 105, icon: "expand" },
      { value: 106, icon: "compress" },
      { value: 107, icon: "plus" },
      { value: 108, icon: "minus" },
      { value: 109, icon: "asterisk" },
      { value: 110, icon: "exclamation-circle" },
      { value: 111, icon: "gift" },
      { value: 112, icon: "leaf" },
      { value: 113, icon: "fire" },
      { value: 114, icon: "eye" },
      { value: 115, icon: "eye-slash" },
      { value: 116, icon: "warning" },
      { value: 117, icon: "exclamation-triangle" },
      { value: 118, icon: "plane" },
      { value: 119, icon: "calendar" },
      { value: 120, icon: "random" },
      { value: 121, icon: "comment" },
      { value: 122, icon: "magnet" },
      { value: 123, icon: "chevron-up" },
      { value: 124, icon: "chevron-down" },
      { value: 125, icon: "retweet" },
      { value: 126, icon: "shopping-cart" },
      { value: 127, icon: "folder" },
      { value: 128, icon: "folder-open" },
      { value: 129, icon: "arrows-v" },
      { value: 130, icon: "arrows-h" },
      { value: 131, icon: "bar-chart-o" },
      { value: 132, icon: "bar-chart" },
      { value: 133, icon: "twitter-square" },
      { value: 134, icon: "facebook-square" },
      { value: 135, icon: "camera-retro" },
      { value: 136, icon: "key" },
      { value: 137, icon: "gears" },
      { value: 138, icon: "cogs" },
      { value: 139, icon: "comments" },
      { value: 140, icon: "thumbs-o-up" },
      { value: 141, icon: "thumbs-o-down" },
      { value: 142, icon: "star-half" },
      { value: 143, icon: "heart-o" },
      { value: 144, icon: "sign-out" },
      { value: 145, icon: "linkedin-square" },
      { value: 146, icon: "thumb-tack" },
      { value: 147, icon: "external-link" },
      { value: 148, icon: "sign-in" },
      { value: 149, icon: "trophy" },
      { value: 150, icon: "github-square" },
      { value: 151, icon: "upload" },
      { value: 152, icon: "lemon-o" },
      { value: 153, icon: "phone" },
      { value: 154, icon: "square-o" },
      { value: 155, icon: "bookmark-o" },
      { value: 156, icon: "phone-square" },
      { value: 157, icon: "twitter" },
      { value: 158, icon: "facebook" },
      { value: 159, icon: "github" },
      { value: 160, icon: "unlock" },
      { value: 161, icon: "credit-card" },
      { value: 162, icon: "rss" },
      { value: 163, icon: "hdd-o" },
      { value: 164, icon: "bullhorn" },
      { value: 165, icon: "bell" },
      { value: 166, icon: "certificate" },
      { value: 167, icon: "hand-o-right" },
      { value: 168, icon: "hand-o-left" },
      { value: 169, icon: "hand-o-up" },
      { value: 170, icon: "hand-o-down" },
      { value: 171, icon: "arrow-circle-left" },
      { value: 172, icon: "arrow-circle-right" },
      { value: 173, icon: "arrow-circle-up" },
      { value: 174, icon: "arrow-circle-down" },
      { value: 175, icon: "globe" },
      { value: 176, icon: "wrench" },
      { value: 177, icon: "tasks" },
      { value: 178, icon: "filter" },
      { value: 179, icon: "briefcase" },
      { value: 180, icon: "arrows-alt" },
      { value: 181, icon: "group" },
      { value: 182, icon: "users" },
      { value: 183, icon: "chain" },
      { value: 184, icon: "link" },
      { value: 185, icon: "cloud" },
      { value: 186, icon: "flask" },
      { value: 187, icon: "cut" },
      { value: 188, icon: "scissors" },
      { value: 189, icon: "copy" },
      { value: 190, icon: "files-o" },
      { value: 191, icon: "paperclip" },
      { value: 192, icon: "save" },
      { value: 193, icon: "floppy-o" },
      { value: 194, icon: "square" },
      { value: 195, icon: "navicon" },
      { value: 196, icon: "reorder" },
      { value: 197, icon: "bars" },
      { value: 198, icon: "list-ul" },
      { value: 199, icon: "list-ol" },
      { value: 200, icon: "strikethrough" },
      { value: 201, icon: "underline" },
      { value: 202, icon: "table" },
      { value: 203, icon: "magic" },
      { value: 204, icon: "truck" },
      { value: 205, icon: "pinterest" },
      { value: 206, icon: "pinterest-square" },
      { value: 207, icon: "google-plus-square" },
      { value: 208, icon: "google-plus" },
      { value: 209, icon: "money" },
      { value: 210, icon: "caret-down" },
      { value: 211, icon: "caret-up" },
      { value: 212, icon: "caret-left" },
      { value: 213, icon: "caret-right" },
      { value: 214, icon: "columns" },
      { value: 215, icon: "unsorted" },
      { value: 216, icon: "sort" },
      { value: 217, icon: "sort-down" },
      { value: 218, icon: "sort-desc" },
      { value: 219, icon: "sort-up" },
      { value: 220, icon: "sort-asc" },
      { value: 221, icon: "envelope" },
      { value: 222, icon: "linkedin" },
      { value: 223, icon: "rotate-left" },
      { value: 224, icon: "undo" },
      { value: 225, icon: "legal" },
      { value: 226, icon: "gavel" },
      { value: 227, icon: "dashboard" },
      { value: 228, icon: "tachometer" },
      { value: 229, icon: "comment-o" },
      { value: 230, icon: "comments-o" },
      { value: 231, icon: "flash" },
      { value: 232, icon: "bolt" },
      { value: 233, icon: "sitemap" },
      { value: 234, icon: "umbrella" },
      { value: 235, icon: "paste" },
      { value: 236, icon: "clipboard" },
      { value: 237, icon: "lightbulb-o" },
      { value: 238, icon: "exchange" },
      { value: 239, icon: "cloud-download" },
      { value: 240, icon: "cloud-upload" },
      { value: 241, icon: "user-md" },
      { value: 242, icon: "stethoscope" },
      { value: 243, icon: "suitcase" },
      { value: 244, icon: "bell-o" },
      { value: 245, icon: "coffee" },
      { value: 246, icon: "cutlery" },
      { value: 247, icon: "file-text-o" },
      { value: 248, icon: "building-o" },
      { value: 249, icon: "hospital-o" },
      { value: 250, icon: "ambulance" },
      { value: 251, icon: "medkit" },
      { value: 252, icon: "fighter-jet" },
      { value: 253, icon: "beer" },
      { value: 254, icon: "h-square" },
      { value: 255, icon: "plus-square" },
      { value: 256, icon: "angle-double-left" },
      { value: 257, icon: "angle-double-right" },
      { value: 258, icon: "angle-double-up" },
      { value: 259, icon: "angle-double-down" },
      { value: 260, icon: "angle-left" },
      { value: 261, icon: "angle-right" },
      { value: 262, icon: "angle-up" },
      { value: 263, icon: "angle-down" },
      { value: 264, icon: "desktop" },
      { value: 265, icon: "laptop" },
      { value: 266, icon: "tablet" },
      { value: 267, icon: "mobile-phone" },
      { value: 268, icon: "mobile" },
      { value: 269, icon: "circle-o" },
      { value: 270, icon: "quote-left" },
      { value: 271, icon: "quote-right" },
      { value: 272, icon: "spinner" },
      { value: 273, icon: "circle" },
      { value: 274, icon: "mail-reply" },
      { value: 275, icon: "reply" },
      { value: 276, icon: "github-alt" },
      { value: 277, icon: "folder-o" },
      { value: 278, icon: "folder-open-o" },
      { value: 279, icon: "smile-o" },
      { value: 280, icon: "frown-o" },
      { value: 281, icon: "meh-o" },
      { value: 282, icon: "gamepad" },
      { value: 283, icon: "keyboard-o" },
      { value: 284, icon: "flag-o" },
      { value: 285, icon: "flag-checkered" },
      { value: 286, icon: "terminal" },
      { value: 287, icon: "code" },
      { value: 288, icon: "mail-reply-all" },
      { value: 289, icon: "reply-all" },
      { value: 290, icon: "star-half-empty" },
      { value: 291, icon: "star-half-full" },
      { value: 292, icon: "star-half-o" },
      { value: 293, icon: "location-arrow" },
      { value: 294, icon: "crop" },
      { value: 295, icon: "code-fork" },
      { value: 296, icon: "unlink" },
      { value: 297, icon: "chain-broken" },
      { value: 298, icon: "question" },
      { value: 299, icon: "info" },
      { value: 300, icon: "exclamation" },
      { value: 301, icon: "superscript" },
      { value: 302, icon: "subscript" },
      { value: 303, icon: "eraser" },
      { value: 304, icon: "puzzle-piece" },
      { value: 305, icon: "microphone" },
      { value: 306, icon: "microphone-slash" },
      { value: 307, icon: "shield" },
      { value: 308, icon: "calendar-o" },
      { value: 309, icon: "fire-extinguisher" },
      { value: 310, icon: "rocket" },
      { value: 311, icon: "maxcdn" },
      { value: 312, icon: "chevron-circle-left" },
      { value: 313, icon: "chevron-circle-right" },
      { value: 314, icon: "chevron-circle-up" },
      { value: 315, icon: "chevron-circle-down" },
      { value: 316, icon: "html5" },
      { value: 317, icon: "css3" },
      { value: 318, icon: "anchor" },
      { value: 319, icon: "unlock-alt" },
      { value: 320, icon: "bullseye" },
      { value: 321, icon: "ellipsis-h" },
      { value: 322, icon: "ellipsis-v" },
      { value: 323, icon: "rss-square" },
      { value: 324, icon: "play-circle" },
      { value: 325, icon: "ticket" },
      { value: 326, icon: "minus-square" },
      { value: 327, icon: "minus-square-o" },
      { value: 328, icon: "level-up" },
      { value: 329, icon: "level-down" },
      { value: 330, icon: "check-square" },
      { value: 331, icon: "pencil-square" },
      { value: 332, icon: "external-link-square" },
      { value: 333, icon: "share-square" },
      { value: 334, icon: "compass" },
      { value: 335, icon: "toggle-down" },
      { value: 336, icon: "caret-square-o-down" },
      { value: 337, icon: "toggle-up" },
      { value: 338, icon: "caret-square-o-up" },
      { value: 339, icon: "toggle-right" },
      { value: 340, icon: "caret-square-o-right" },
      { value: 341, icon: "euro" },
      { value: 342, icon: "eur" },
      { value: 343, icon: "gbp" },
      { value: 344, icon: "dollar" },
      { value: 345, icon: "usd" },
      { value: 346, icon: "rupee" },
      { value: 347, icon: "inr" },
      { value: 348, icon: "cny" },
      { value: 349, icon: "rmb" },
      { value: 350, icon: "yen" },
      { value: 351, icon: "jpy" },
      { value: 352, icon: "ruble" },
      { value: 353, icon: "rouble" },
      { value: 354, icon: "rub" },
      { value: 355, icon: "won" },
      { value: 356, icon: "krw" },
      { value: 357, icon: "bitcoin" },
      { value: 358, icon: "btc" },
      { value: 359, icon: "file" },
      { value: 360, icon: "file-text" },
      { value: 361, icon: "sort-alpha-asc" },
      { value: 362, icon: "sort-alpha-desc" },
      { value: 363, icon: "sort-amount-asc" },
      { value: 364, icon: "sort-amount-desc" },
      { value: 365, icon: "sort-numeric-asc" },
      { value: 366, icon: "sort-numeric-desc" },
      { value: 367, icon: "thumbs-up" },
      { value: 368, icon: "thumbs-down" },
      { value: 369, icon: "youtube-square" },
      { value: 370, icon: "youtube" },
      { value: 371, icon: "xing" },
      { value: 372, icon: "xing-square" },
      { value: 373, icon: "youtube-play" },
      { value: 374, icon: "dropbox" },
      { value: 375, icon: "stack-overflow" },
      { value: 376, icon: "instagram" },
      { value: 377, icon: "flickr" },
      { value: 378, icon: "adn" },
      { value: 379, icon: "bitbucket" },
      { value: 380, icon: "bitbucket-square" },
      { value: 381, icon: "tumblr" },
      { value: 382, icon: "tumblr-square" },
      { value: 383, icon: "long-arrow-down" },
      { value: 384, icon: "long-arrow-up" },
      { value: 385, icon: "long-arrow-left" },
      { value: 386, icon: "long-arrow-right" },
      { value: 387, icon: "apple" },
      { value: 388, icon: "windows" },
      { value: 389, icon: "android" },
      { value: 390, icon: "linux" },
      { value: 391, icon: "dribbble" },
      { value: 392, icon: "skype" },
      { value: 393, icon: "foursquare" },
      { value: 394, icon: "trello" },
      { value: 395, icon: "female" },
      { value: 396, icon: "male" },
      { value: 397, icon: "gittip" },
      { value: 398, icon: "sun-o" },
      { value: 399, icon: "moon-o" },
      { value: 400, icon: "archive" },
      { value: 401, icon: "bug" },
      { value: 402, icon: "vk" },
      { value: 403, icon: "weibo" },
      { value: 404, icon: "renren" },
      { value: 405, icon: "pagelines" },
      { value: 406, icon: "stack-exchange" },
      { value: 407, icon: "arrow-circle-o-right" },
      { value: 408, icon: "arrow-circle-o-left" },
      { value: 409, icon: "toggle-left" },
      { value: 410, icon: "caret-square-o-left" },
      { value: 411, icon: "dot-circle-o" },
      { value: 412, icon: "wheelchair" },
      { value: 413, icon: "vimeo-square" },
      { value: 414, icon: "turkish-lira" },
      { value: 415, icon: "try" },
      { value: 416, icon: "plus-square-o" },
      { value: 417, icon: "space-shuttle" },
      { value: 418, icon: "slack" },
      { value: 419, icon: "envelope-square" },
      { value: 420, icon: "wordpress" },
      { value: 421, icon: "openid" },
      { value: 422, icon: "institution" },
      { value: 423, icon: "bank" },
      { value: 424, icon: "university" },
      { value: 425, icon: "mortar-board" },
      { value: 426, icon: "graduation-cap" },
      { value: 427, icon: "yahoo" },
      { value: 428, icon: "google" },
      { value: 429, icon: "reddit" },
      { value: 430, icon: "reddit-square" },
      { value: 431, icon: "stumbleupon-circle" },
      { value: 432, icon: "stumbleupon" },
      { value: 433, icon: "delicious" },
      { value: 434, icon: "digg" },
      { value: 435, icon: "pied-piper" },
      { value: 436, icon: "pied-piper-alt" },
      { value: 437, icon: "drupal" },
      { value: 438, icon: "joomla" },
      { value: 439, icon: "language" },
      { value: 440, icon: "fax" },
      { value: 441, icon: "building" },
      { value: 442, icon: "child" },
      { value: 443, icon: "paw" },
      { value: 444, icon: "spoon" },
      { value: 445, icon: "cube" },
      { value: 446, icon: "cubes" },
      { value: 447, icon: "behance" },
      { value: 448, icon: "behance-square" },
      { value: 449, icon: "steam" },
      { value: 450, icon: "steam-square" },
      { value: 451, icon: "recycle" },
      { value: 452, icon: "automobile" },
      { value: 453, icon: "car" },
      { value: 454, icon: "cab" },
      { value: 455, icon: "taxi" },
      { value: 456, icon: "tree" },
      { value: 457, icon: "spotify" },
      { value: 458, icon: "deviantart" },
      { value: 459, icon: "soundcloud" },
      { value: 460, icon: "database" },
      { value: 461, icon: "file-pdf-o" },
      { value: 462, icon: "file-word-o" },
      { value: 463, icon: "file-excel-o" },
      { value: 464, icon: "file-powerpoint-o" },
      { value: 465, icon: "file-photo-o" },
      { value: 466, icon: "file-picture-o" },
      { value: 467, icon: "file-image-o" },
      { value: 468, icon: "file-zip-o" },
      { value: 469, icon: "file-archive-o" },
      { value: 470, icon: "file-sound-o" },
      { value: 471, icon: "file-audio-o" },
      { value: 472, icon: "file-movie-o" },
      { value: 473, icon: "file-video-o" },
      { value: 474, icon: "file-code-o" },
      { value: 475, icon: "vine" },
      { value: 476, icon: "codepen" },
      { value: 477, icon: "jsfiddle" },
      { value: 478, icon: "life-bouy" },
      { value: 479, icon: "life-buoy" },
      { value: 480, icon: "life-saver" },
      { value: 481, icon: "support" },
      { value: 482, icon: "life-ring" },
      { value: 483, icon: "circle-o-notch" },
      { value: 484, icon: "ra" },
      { value: 485, icon: "rebel" },
      { value: 486, icon: "ge" },
      { value: 487, icon: "empire" },
      { value: 488, icon: "git-square" },
      { value: 489, icon: "git" },
      { value: 490, icon: "hacker-news" },
      { value: 491, icon: "tencent-weibo" },
      { value: 492, icon: "qq" },
      { value: 493, icon: "wechat" },
      { value: 494, icon: "weixin" },
      { value: 495, icon: "send" },
      { value: 496, icon: "paper-plane" },
      { value: 497, icon: "send-o" },
      { value: 498, icon: "paper-plane-o" },
      { value: 499, icon: "history" },
      { value: 500, icon: "circle-thin" },
      { value: 501, icon: "header" },
      { value: 502, icon: "paragraph" },
      { value: 503, icon: "sliders" },
      { value: 504, icon: "share-alt" },
      { value: 505, icon: "share-alt-square" },
      { value: 506, icon: "bomb" },
      { value: 507, icon: "soccer-ball-o" },
      { value: 508, icon: "futbol-o" },
      { value: 509, icon: "tty" },
      { value: 510, icon: "binoculars" },
      { value: 511, icon: "plug" },
      { value: 512, icon: "slideshare" },
      { value: 513, icon: "twitch" },
      { value: 514, icon: "yelp" },
      { value: 515, icon: "newspaper-o" },
      { value: 516, icon: "wifi" },
      { value: 517, icon: "calculator" },
      { value: 518, icon: "paypal" },
      { value: 519, icon: "google-wallet" },
      { value: 520, icon: "cc-visa" },
      { value: 521, icon: "cc-mastercard" },
      { value: 522, icon: "cc-discover" },
      { value: 523, icon: "cc-amex" },
      { value: 524, icon: "cc-paypal" },
      { value: 525, icon: "cc-stripe" },
      { value: 526, icon: "bell-slash" },
      { value: 527, icon: "bell-slash-o" },
      { value: 528, icon: "trash" },
      { value: 529, icon: "copyright" },
      { value: 530, icon: "at" },
      { value: 531, icon: "eyedropper" },
      { value: 532, icon: "paint-brush" },
      { value: 533, icon: "birthday-cake" },
      { value: 534, icon: "area-chart" },
      { value: 535, icon: "pie-chart" },
      { value: 536, icon: "line-chart" },
      { value: 537, icon: "lastfm" },
      { value: 538, icon: "lastfm-square" },
      { value: 539, icon: "toggle-off" },
      { value: 540, icon: "toggle-on" },
      { value: 541, icon: "bicycle" },
      { value: 542, icon: "bus" },
      { value: 543, icon: "ioxhost" },
      { value: 544, icon: "angellist" },
      { value: 545, icon: "cc" },
      { value: 546, icon: "shekel" },
      { value: 547, icon: "sheqel" },
      { value: 548, icon: "ils" },
      { value: 549, icon: "meanpath" }];

      this.buttonviewarray=[];
  }

  settoDefault() {
    this.selectedWebsite = {
      name: "",
      domain_name: "",
      email_messages: "",
      facebook_link: "",
      twitter_link: "",
      website_uid: "",
      account_id: ""
    }

  }
  loadCustomlibrary(appstore) {
    this.widgetThemes = [
      { image: appstore.imgpath + "themes/blue/preview.png", theme: 'blue' },
      { image: appstore.imgpath + "themes/green/preview.png", theme: 'green' },
      { image: appstore.imgpath + "themes/orange/preview.png", theme: 'orange' },
      { image: appstore.imgpath + "themes/pink/preview.png", theme: 'pink' },
      { image: appstore.imgpath + "themes/red/preview.png", theme: 'red' },
      { image: appstore.imgpath + "themes/teal/preview.png", theme: 'teal' },
      { image: appstore.imgpath + "themes/dark_blue/preview.png", theme: 'dark blue' },
      { image: appstore.imgpath + "themes/dark_green/preview.png", theme: 'dark green' },
      { image: appstore.imgpath + "themes/dark_orange/preview.png", theme: 'dark orange' },
      { image: appstore.imgpath + "themes/dark_pink/preview.png", theme: 'dark pink' },
      { image: appstore.imgpath + "themes/dark_red/preview.png", theme: 'dark red' },
      { image: appstore.imgpath + "themes/dark_teal/preview.png", theme: 'dark teal' },
      { image: appstore.imgpath + "themes/light_blue/preview.png", theme: 'light blue' },
      { image: appstore.imgpath + "themes/light_green/preview.png", theme: 'light green' },
      { image: appstore.imgpath + "themes/light_grey/preview.png", theme: 'light grey' },
      { image: appstore.imgpath + "themes/light_teal/preview.png", theme: 'light teal' },
      { image: appstore.imgpath + "themes/dark_blue/preview.png", theme: 'dark_blue' },
      { image: appstore.imgpath + "themes/dark_green/preview.png", theme: 'dark_green' },
      { image: appstore.imgpath + "themes/dark_orange/preview.png", theme: 'dark_orange' },
      { image: appstore.imgpath + "themes/dark_pink/preview.png", theme: 'dark_pink' },
      { image: appstore.imgpath + "themes/dark_red/preview.png", theme: 'dark_red' },
      { image: appstore.imgpath + "themes/dark_teal/preview.png", theme: 'dark_teal' },
      { image: appstore.imgpath + "themes/light_blue/preview.png", theme: 'light_blue' },
      { image: appstore.imgpath + "themes/light_green/preview.png", theme: 'light_green' },
      { image: appstore.imgpath + "themes/light_grey/preview.png", theme: 'light_grey' },
      { image: appstore.imgpath + "themes/light_teal/preview.png", theme: 'light_teal' }
    ];
    this.icons = [
      { image: appstore.imgpath + "icons/1_offline.png", id: 1 },
      { image: appstore.imgpath + "icons/1_online.png", id: 2 },
      { image: appstore.imgpath + "icons/2_offline.png", id: 3 },
      { image: appstore.imgpath + "icons/2_online.png", id: 4 },
      { image: appstore.imgpath + "icons/3_offline.png", id: 5 },
      { image: appstore.imgpath + "icons/3_online.png", id: 6 },
      { image: appstore.imgpath + "icons/4_offline.png", id: 7 },
      { image: appstore.imgpath + "icons/4_online.png", id: 8 },
      { image: appstore.imgpath + "icons/5_offline.gif", id: 9 },
      { image: appstore.imgpath + "icons/5_online.gif", id: 10 },
      { image: appstore.imgpath + "icons/6_offline.png", id: 11 },
      { image: appstore.imgpath + "icons/6_online.png", id: 12 },
      { image: appstore.imgpath + "icons/7_offline.png", id: 13 },
      { image: appstore.imgpath + "icons/7_online.png", id: 14 },
      { image: appstore.imgpath + "icons/8_offline.gif", id: 15 },
      { image: appstore.imgpath + "icons/8_online.gif", id: 16 },
      { image: appstore.imgpath + "icons/9_offline.gif", id: 17 },
      { image: appstore.imgpath + "icons/9_online.gif", id: 18 },
      { image: appstore.imgpath + "icons/10_offline.gif", id: 19 },
      { image: appstore.imgpath + "icons/10_online.gif", id: 20 },
      { image: appstore.imgpath + "icons/11_offline.png", id: 21 },
      { image: appstore.imgpath + "icons/11_online.png", id: 22 },
      { image: appstore.imgpath + "icons/12_offline.png", id: 23 },
      { image: appstore.imgpath + "icons/12_online.png", id: 24 },
      { image: appstore.imgpath + "icons/13_offline.png", id: 25 },
      { image: appstore.imgpath + "icons/13_online.png", id: 26 },
      { image: appstore.imgpath + "icons/14_offline.png", id: 27 },
      { image: appstore.imgpath + "icons/14_online.png", id: 28 },
      { image: appstore.imgpath + "icons/15_offline.png", id: 29 },
      { image: appstore.imgpath + "icons/15_online.png", id: 30 },
      { image: appstore.imgpath + "icons/16_offline.png", id: 31 },
      { image: appstore.imgpath + "icons/16_online.png", id: 32 },
      { image: appstore.imgpath + "icons/17_offline.png", id: 33 },
      { image: appstore.imgpath + "icons/17_online.png", id: 34 },
      { image: appstore.imgpath + "icons/18_offline.gif", id: 35 },
      { image: appstore.imgpath + "icons/18_online.gif", id: 36 },
      { image: appstore.imgpath + "icons/19_offline.gif", id: 37 },
      { image: appstore.imgpath + "icons/19_online.gif", id: 38 },
      { image: appstore.imgpath + "icons/20_offline.gif", id: 39 },
      { image: appstore.imgpath + "icons/20_online.gif", id: 40 },
      { image: appstore.imgpath + "icons/21_offline.gif", id: 41 },
      { image: appstore.imgpath + "icons/21_online.gif", id: 42 },
      { image: appstore.imgpath + "icons/22_offline.gif", id: 43 },
      { image: appstore.imgpath + "icons/22_online.gif", id: 44 },
      { image: appstore.imgpath + "icons/23_offline.png", id: 45 },
      { image: appstore.imgpath + "icons/23_online.png", id: 46 },
      { image: appstore.imgpath + "icons/24_offline.png", id: 47 },
      { image: appstore.imgpath + "icons/24_online.png", id: 48 },
      { image: appstore.imgpath + "icons/25_offline.png", id: 49 },
      { image: appstore.imgpath + "icons/25_online.png", id: 50 },
      { image: appstore.imgpath + "icons/26_offline.png", id: 51 },
      { image: appstore.imgpath + "icons/26_online.png", id: 52 },
      { image: appstore.imgpath + "icons/27_offline.png", id: 53 },
      { image: appstore.imgpath + "icons/27_online.png", id: 54 },
      { image: appstore.imgpath + "icons/28_offline.png", id: 55 },
      { image: appstore.imgpath + "icons/28_online.png", id: 56 },
    ];

    this.invitationImages = [
      { value: 1, img: appstore.resourceurl + "resourcesPreset/02d711e0-6429-424e-be8a-04544bf14dab.100x100xfit.png" },
      { value: 2, img: appstore.resourceurl + "resourcesPreset/cf07c355-2d59-4fbb-b1b7-b5aa2b37f9f9.100x100xfit.png" },
      { value: 3, img: appstore.resourceurl + "resourcesPreset/0d712896-e835-42f8-8bf4-e16fc06a8593.100x100xfit.png" },
      { value: 4, img: appstore.resourceurl + "resourcesPreset/d8e40b96-2ffb-4ad2-8566-babbd0451da9.100x100xfit.png" },
      { value: 5, img: appstore.resourceurl + "resourcesPreset/8ce4224e-f864-4f17-99b3-706f858ebe30.100x100xfit.png" },
      { value: 6, img: appstore.resourceurl + "resourcesPreset/bb1a6a97-2bc4-4f4f-a35d-f4450181c881.100x100xfit.png" },
      { value: 7, img: appstore.resourceurl + "resourcesPreset/bf9131a9-a954-491c-8cab-2ea9dbf8c171.100x100xfit.png" },
      { value: 8, img: appstore.resourceurl + "resourcesPreset/00ab47c5-8d08-429d-be87-1f86c2fb78e7.100x100xfit.png" },
      { value: 9, img: appstore.resourceurl + "resourcesPreset/be0390c2-a840-4fb1-9ab0-161d07b076ae.100x100xfit.png" },
      { value: 10, img: appstore.resourceurl + "resourcesPreset/b02a5005-6120-443d-8c63-a5d986e193b3.100x100xfit.png" },
      { value: 11, img: appstore.resourceurl + "resourcesPreset/dea178f5-198b-4c4f-b86c-39c8f40c8600.100x100xfit.png" },
      { value: 12, img: appstore.resourceurl + "resourcesPreset/941e2cea-40ce-4e9b-956a-4bca6a54a714.100x100xfit.png" },
      { value: 13, img: appstore.resourceurl + "resourcesPreset/a106ec38-0008-4d01-b3fd-eeb4e77a5603.100x100xfit.png" },
      { value: 14, img: appstore.resourceurl + "resourcesPreset/e33aa745-f201-48e4-8919-d7f7ac3a09c2.100x100xfit.png" },
      { value: 15, img: appstore.resourceurl + "resourcesPreset/9b6f1ef1-d985-406b-93e1-166f853385bc.100x100xfit.png" },
      { value: 16, img: appstore.resourceurl + "resourcesPreset/53ed4f2d-dc7d-4210-aaed-2bf42ffbf054.100x100xfit.png" },
      { value: 17, img: appstore.resourceurl + "resourcesPreset/3c921f1d-515b-4b06-a77d-f5204c817496.100x100xfit.png" },
      { value: 18, img: appstore.resourceurl + "resourcesPreset/33d32217-967f-40f0-ba46-2551f318b887.100x100xfit.png" },
      { value: 19, img: appstore.resourceurl + "resourcesPreset/e56718fb-5dd5-4f3c-9e52-53fae85fa9f1.100x100xfit.png" },
      { value: 20, img: appstore.resourceurl + "resourcesPreset/7ab5caee-84f1-44e3-8c56-0a8ab1a2e8a7.100x100xfit.png" },
      { value: 21, img: appstore.resourceurl + "resourcesPreset/cd4c85b3-2cb3-4377-9e5f-435680db9406.100x100xfit.png" },
      { value: 22, img: appstore.resourceurl + "resourcesPreset/b1dffe55-bed2-4c51-aaa8-b01d15580c8d.100x100xfit.png" },
      { value: 23, img: appstore.resourceurl + "resourcesPreset/39b438c6-a633-40ba-a4b4-453be32b6ae3.100x100xfit.png" },
      { value: 24, img: appstore.resourceurl + "resourcesPreset/2f1cbdd9-2f70-4a39-bec1-85c4b25c9262.100x100xfit.png" },
      { value: 25, img: appstore.resourceurl + "resourcesPreset/0d62893f-7d5f-4100-a2f4-32c6dc7ba8b6.100x100xfit.png" },
      { value: 26, img: appstore.resourceurl + "resourcesPreset/e63d24c1-e577-4b4b-928e-18ba074bc526.100x100xfit.png" },
      { value: 27, img: appstore.resourceurl + "resourcesPreset/56c04d11-a9de-410e-88b1-6c31e8dbefd5.100x100xfit.png" },
      { value: 28, img: appstore.resourceurl + "resourcesPreset/ec5803e8-4f3d-430c-9c6c-35ece887cbec.100x100xfit.png" },
      { value: 29, img: appstore.resourceurl + "resourcesPreset/bb633426-1cd9-42fa-b14a-760bc757c21f.100x100xfit.png" },
      { value: 30, img: appstore.resourceurl + "resourcesPreset/8d09d5e5-1a58-4806-9734-2ce551cf851c.100x100xfit.png" },
      { value: 31, img: appstore.resourceurl + "resourcesPreset/0574f1be-1104-4c35-886a-b11b19a6b55b.100x100xfit.png" },
      { value: 32, img: appstore.resourceurl + "resourcesPreset/0a6a9e5f-e741-4ce1-b56b-a1a1a30ce0b6.100x100xfit.png" },
      { value: 33, img: appstore.resourceurl + "resourcesPreset/d9e513a7-3409-4c54-b2f3-d3af02b78a20.100x100xfit.png" },
      { value: 34, img: appstore.resourceurl + "resourcesPreset/c0b460ce-f622-4f0f-8a70-dce2e928476c.100x100xfit.png" },
      { value: 35, img: appstore.resourceurl + "resourcesPreset/f536034d-07a6-45f4-bc9b-52b67af188af.100x100xfit.png" },
      { value: 36, img: appstore.resourceurl + "resourcesPreset/1d0e9fca-f355-485a-a787-c1bb67d8e1a9.100x100xfit.png" },
      { value: 37, img: appstore.resourceurl + "resourcesPreset/0fc9c315-9849-468b-9f2d-cd700e212bbf.100x100xfit.png" },
      { value: 38, img: appstore.resourceurl + "resourcesPreset/bd7e8868-6a58-4402-aafb-1b1db2424220.100x100xfit.png" },
      { value: 39, img: appstore.resourceurl + "resourcesPreset/431c5784-49e2-44fb-8c22-e63004968b92.100x100xfit.png" },
      { value: 40, img: appstore.resourceurl + "resourcesPreset/0977925c-915f-420c-ae41-66dc91575f9d.100x100xfit.png" },
      { value: 41, img: appstore.resourceurl + "resourcesPreset/1668eefd-6557-43c5-97d4-40f3ca9813c4.100x100xfit.png" },
      { value: 42, img: appstore.resourceurl + "resourcesPreset/8660c6c2-11df-4340-8bad-d164dc106138.100x100xfit.png" },
      { value: 43, img: appstore.resourceurl + "resourcesPreset/eacc64ae-c634-4945-87a5-7666a23e8d64.100x100xfit.png" },
      { value: 44, img: appstore.resourceurl + "resourcesPreset/8273aa2c-8b29-47e4-a7d1-e74a91a4747c.100x100xfit.png" },
      { value: 45, img: appstore.resourceurl + "resourcesPreset/b239d4e9-2568-4695-adee-5b7be4059edf.100x100xfit.png" },
      { value: 46, img: appstore.resourceurl + "resourcesPreset/1c6a9203-130a-4d3f-819e-023fa9640e12.100x100xfit.png" },
      { value: 47, img: appstore.resourceurl + "resourcesPreset/75a07a2a-b3cb-4677-a3b4-0670d42c3c90.100x100xfit.png" },
      { value: 48, img: appstore.resourceurl + "resourcesPreset/7beed0fa-0ce4-4a91-a315-b31fc2d43dda.100x100xfit.png" },
      { value: 49, img: appstore.resourceurl + "resourcesPreset/1d3f08af-b823-484b-81f6-24637e1088de.100x100xfit.png" },
      { value: 50, img: appstore.resourceurl + "resourcesPreset/699c68cd-1341-44bd-8b13-a7a49bcc29b1.100x100xfit.png" },
      { value: 51, img: appstore.resourceurl + "resourcesPreset/ab0ffe1f-125b-42a4-b26e-08703e8ce217.100x100xfit.png" },
      { value: 52, img: appstore.resourceurl + "resourcesPreset/47917ac0-ee9f-4c99-acee-80d8c3b39192.100x100xfit.png" },
      { value: 53, img: appstore.resourceurl + "resourcesPreset/7c00604a-09cf-4879-939d-f34a24a7f2f3.100x100xfit.png" },
      { value: 54, img: appstore.resourceurl + "resourcesPreset/22f2d907-30cf-4305-997e-3ca32681cb19.100x100xfit.png" },
      { value: 55, img: appstore.resourceurl + "resourcesPreset/e52b5d4c-84a0-48b1-ae3c-7130a1cb4d63.100x100xfit.png" },
      { value: 56, img: appstore.resourceurl + "resourcesPreset/06fa965c-a316-482a-9bc0-7cc0c772c787.100x100xfit.png" },
      { value: 57, img: appstore.resourceurl + "resourcesPreset/a0bc538a-cee1-4523-9ef4-0d80b1769d6d.100x100xfit.png" },
      { value: 58, img: appstore.resourceurl + "resourcesPreset/64a275c2-3ac2-41a0-86a9-0aac7ec093a9.100x100xfit.png" },
      { value: 59, img: appstore.resourceurl + "resourcesPreset/8c925aae-1cf2-4dbe-9687-79a31a50fe7e.100x100xfit.png" },
      { value: 60, img: appstore.resourceurl + "resourcesPreset/666ae23f-ecc1-45c9-9979-15dea4ad3f48.100x100xfit.png" },
      { value: 61, img: appstore.resourceurl + "resourcesPreset/19bb7202-ec12-455b-9484-f764def995a8.100x100xfit.png" },
      { value: 62, img: appstore.resourceurl + "resourcesPreset/e998bc41-54eb-4308-825c-c787ce908108.100x100xfit.png" },
      { value: 63, img: appstore.resourceurl + "resourcesPreset/faabaf9c-04e9-4752-b8a2-18a050dae645.100x100xfit.png" },
      { value: 64, img: appstore.resourceurl + "resourcesPreset/9eea23eb-c79f-48dd-b38a-6c74db11a29f.100x100xfit.png" },
      { value: 65, img: appstore.resourceurl + "resourcesPreset/98f30e73-ff2f-405e-bf25-b0acbd968d03.100x100xfit.png" },
      { value: 66, img: appstore.resourceurl + "resourcesPreset/9b11c6b9-f8c1-4f3a-b72b-61e1bc1e7b1b.100x100xfit.png" },
      { value: 67, img: appstore.resourceurl + "resourcesPreset/86b9556d-9757-4602-ae02-a4ff4bbc6d4d.100x100xfit.png" },
      { value: 68, img: appstore.resourceurl + "resourcesPreset/0560c174-7ab5-40b0-8430-9d6f0dafa199.100x100xfit.png" },
      { value: 69, img: appstore.resourceurl + "resourcesPreset/9d1d4c3a-a2fb-4561-9293-fd061893ae0f.100x100xfit.png" },
      { value: 70, img: appstore.resourceurl + "resourcesPreset/e3867368-cbb7-4931-9c06-a67e32641ed5.100x100xfit.png" },
      { value: 71, img: appstore.resourceurl + "resourcesPreset/3d45a7a6-4fe4-43a9-bc1f-10bc5875a216.100x100xfit.png" },
      { value: 72, img: appstore.resourceurl + "resourcesPreset/b925967b-f839-4059-b958-bb4ba6fb9f10.100x100xfit.png" },
      { value: 73, img: appstore.resourceurl + "resourcesPreset/48b0643a-6fa7-4610-af20-46f0dd782aa5.100x100xfit.png" },
      { value: 74, img: appstore.resourceurl + "resourcesPreset/0dd74927-8ddb-43ff-b30b-91c5b04736c1.100x100xfit.png" },
      { value: 75, img: appstore.resourceurl + "resourcesPreset/7daf627f-72e4-46dd-95df-e2319bb3219d.100x100xfit.png" },
      { value: 76, img: appstore.resourceurl + "resourcesPreset/b93affc6-83ae-4ba0-b357-4fdb5c6577ad.100x100xfit.png" },
      { value: 77, img: appstore.resourceurl + "resourcesPreset/641fee3e-8002-4de7-9ec9-0c702241629e.100x100xfit.png" },
      { value: 78, img: appstore.resourceurl + "resourcesPreset/8f43d1c1-598b-49da-92e2-d9e3a2bd03e6.100x100xfit.png" },
      { value: 79, img: appstore.resourceurl + "resourcesPreset/3142157f-5e17-4e42-8306-89673a156ade.100x100xfit.png" },
      { value: 80, img: appstore.resourceurl + "resourcesPreset/934da3c0-eec3-4ea1-8983-63effd024bbd.100x100xfit.png" },
      { value: 81, img: appstore.resourceurl + "resourcesPreset/43238aea-66b2-4568-a83f-03cb1b5e1a41.100x100xfit.png" },
      { value: 82, img: appstore.resourceurl + "resourcesPreset/f5bc0033-9c57-4716-894e-7f6963955d90.100x100xfit.png" },
      { value: 83, img: appstore.resourceurl + "resourcesPreset/6e333601-5792-4cd7-bcfb-8c3da3eecd5a.100x100xfit.png" },
      { value: 84, img: appstore.resourceurl + "resourcesPreset/142316f9-6a6a-47bf-8177-b67b0527b8be.100x100xfit.png" },
      { value: 85, img: appstore.resourceurl + "resourcesPreset/bed49364-2512-476f-bb9c-1f9314d9375a.100x100xfit.png" },
      { value: 86, img: appstore.resourceurl + "resourcesPreset/f9e5fe16-747b-4dce-90e5-34d0ea70c6cf.100x100xfit.png" },
      { value: 87, img: appstore.resourceurl + "resourcesPreset/49dad026-b5d6-4b1e-9e4c-539bf6045ea0.100x100xfit.png" },
      { value: 88, img: appstore.resourceurl + "resourcesPreset/e2548001-ec83-4d7a-a4b7-74fe9277306e.100x100xfit.png" },
      { value: 89, img: appstore.resourceurl + "resourcesPreset/b02ec098-45f3-41cd-9fa5-2a7361b73674.100x100xfit.png" },
      { value: 90, img: appstore.resourceurl + "resourcesPreset/c388be83-be3a-4fdd-8d22-23f544746703.100x100xfit.png" },
      { value: 91, img: appstore.resourceurl + "resourcesPreset/ab18ae7a-b64c-48bb-bced-cf5696434d3b.100x100xfit.png" },
      { value: 92, img: appstore.resourceurl + "resourcesPreset/69fad056-9b58-4eca-baf2-87e872da14e4.100x100xfit.png" },
      { value: 93, img: appstore.resourceurl + "resourcesPreset/d02f7dee-9dbe-44b4-a17d-3b8eb1052e8e.100x100xfit.png" },
      { value: 94, img: appstore.resourceurl + "resourcesPreset/43ca5337-33e8-49b0-b913-58a6cdc48973.100x100xfit.png" },
      { value: 95, img: appstore.resourceurl + "resourcesPreset/d3eda512-40fc-4105-8182-a89afd259e23.100x100xfit.png" },
      { value: 96, img: appstore.resourceurl + "resourcesPreset/941d188f-bce8-472b-bd94-599b28827bce.100x100xfit.png" },
      { value: 97, img: appstore.resourceurl + "resourcesPreset/6690a933-78d5-458a-adf8-0ef206327377.100x100xfit.png" },
      { value: 98, img: appstore.resourceurl + "resourcesPreset/acaebacb-ddba-4d53-b376-1f67f81c397c.100x100xfit.png" },
      { value: 99, img: appstore.resourceurl + "resourcesPreset/cadf013a-d828-442f-8fcd-0cdb21fff004.100x100xfit.png" },
      { value: 100, img: appstore.resourceurl + "resourcesPreset/559493c6-7140-40e9-900b-3f71bf486c75.100x100xfit.png" },
      { value: 101, img: appstore.resourceurl + "resourcesPreset/6b7b41ef-3d17-42dc-b44a-cccbd6d1c346.100x100xfit.png" },
      { value: 102, img: appstore.resourceurl + "resourcesPreset/27e5e151-a2ee-4b55-93a7-e8750154c789.100x100xfit.png" },
      { value: 103, img: appstore.resourceurl + "resourcesPreset/3d5e3bb3-8782-40c9-a3af-8e8f49732cac.100x100xfit.png" },
      { value: 104, img: appstore.resourceurl + "resourcesPreset/514fdf99-ff11-4c72-bb4b-fd91ab32562a.100x100xfit.png" },
      { value: 105, img: appstore.resourceurl + "resourcesPreset/7c289064-a563-483c-8d2c-31177b472b2d.100x100xfit.png" },
      { value: 106, img: appstore.resourceurl + "resourcesPreset/b21acfaa-9159-490e-a7a4-712e990f6ea8.100x100xfit.png" },
      { value: 107, img: appstore.resourceurl + "resourcesPreset/20458fce-8432-48ff-8326-2e5e5c0c9b3f.100x100xfit.png" },
      { value: 108, img: appstore.resourceurl + "resourcesPreset/cadae489-8e3d-4a59-a68c-b3cf8a03ceed.100x100xfit.png" },
      { value: 109, img: appstore.resourceurl + "resourcesPreset/86aa04a5-c61b-42c2-9b44-07523f0ebd9a.100x100xfit.png" },
      { value: 110, img: appstore.resourceurl + "resourcesPreset/64fc60fa-de57-4dad-9ff3-77aeb71cc303.100x100xfit.png" },
      { value: 111, img: appstore.resourceurl + "resourcesPreset/5c0c9011-8064-46a7-abb5-c15439418962.100x100xfit.png" },
      { value: 112, img: appstore.resourceurl + "resourcesPreset/6cdbb264-784e-4adc-a6cc-9293e72a7418.100x100xfit.png" },
      { value: 113, img: appstore.resourceurl + "resourcesPreset/4bba2850-2e27-4cd3-aa5d-f8ccc1664f2b.100x100xfit.png" },
      { value: 114, img: appstore.resourceurl + "resourcesPreset/e4fa1673-593e-4f54-92bd-81715a37be61.100x100xfit.png" },
      { value: 115, img: appstore.resourceurl + "resourcesPreset/c71fdef2-ed0e-4b95-ae32-8e9722a907ad.100x100xfit.png" },
      { value: 116, img: appstore.resourceurl + "resourcesPreset/0e876ea5-5814-43e9-9dc5-f8a65d719532.100x100xfit.png" },
      { value: 117, img: appstore.resourceurl + "resourcesPreset/fb2fb236-f02f-4d6d-a4af-8a516ca38c4d.100x100xfit.png" },
      { value: 118, img: appstore.resourceurl + "resourcesPreset/cf0e8b0b-03e4-4137-a070-3a32c38b07c3.100x100xfit.png" },
      { value: 119, img: appstore.resourceurl + "resourcesPreset/e93d2c38-f407-4c0a-b652-5c86a4ea9e50.100x100xfit.png" },
      { value: 120, img: appstore.resourceurl + "resourcesPreset/55294bbd-bf9e-4350-8f14-45cd82835deb.100x100xfit.png" },
      { value: 121, img: appstore.resourceurl + "resourcesPreset/4e1462d5-39f9-483e-ac7b-684b75b311b9.100x100xfit.png" },
      { value: 122, img: appstore.resourceurl + "resourcesPreset/e0d88aa6-2f17-4244-a354-3c6b03a59ed6.100x100xfit.png" },
      { value: 123, img: appstore.resourceurl + "resourcesPreset/1e3e17c0-f4f9-4048-8c75-f92f118bdab1.100x100xfit.png" },
      { value: 124, img: appstore.resourceurl + "resourcesPreset/e3b51d42-f5ff-45b2-a60f-1fd01b06d4ff.100x100xfit.png" },
      { value: 125, img: appstore.resourceurl + "resourcesPreset/37f029ea-2157-4ec4-b668-a13d0948d629.100x100xfit.png" },
      { value: 126, img: appstore.resourceurl + "resourcesPreset/87e574ef-a2ec-41e0-b0ca-be33d156dd94.100x100xfit.png" },
      { value: 127, img: appstore.resourceurl + "resourcesPreset/e0717c4e-088e-4957-bf89-4468e7d55474.100x100xfit.png" },
      { value: 128, img: appstore.resourceurl + "resourcesPreset/799481f8-34e7-4c70-b11d-8c54fa43c8ad.100x100xfit.png" },
      { value: 129, img: appstore.resourceurl + "resourcesPreset/3848ca56-ebdc-40f1-83fb-ec76e8349eeb.100x100xfit.png" },
      { value: 130, img: appstore.resourceurl + "resourcesPreset/493e0e3a-bd01-4b4b-9dba-2c51d8ef2c06.100x100xfit.png" },
      { value: 131, img: appstore.resourceurl + "resourcesPreset/a960a4d6-822f-4166-b9d1-c50f33c3fcca.100x100xfit.png" },
      { value: 132, img: appstore.resourceurl + "resourcesPreset/209565e8-e031-4ed5-9199-53497113534b.100x100xfit.png" },
      { value: 133, img: appstore.resourceurl + "resourcesPreset/db363e58-2cc5-44cb-8d8b-8ccceefa8724.100x100xfit.png" },
      { value: 134, img: appstore.resourceurl + "resourcesPreset/23655a6b-5b64-48aa-836e-315e421d6331.100x100xfit.png" },
      { value: 135, img: appstore.resourceurl + "resourcesPreset/2fb2be5f-12e3-46b3-9095-b4060428baf7.100x100xfit.png" },
      { value: 136, img: appstore.resourceurl + "resourcesPreset/4d828644-86f7-4201-8b9e-49658179a0a6.100x100xfit.png" },
      { value: 137, img: appstore.resourceurl + "resourcesPreset/3fe02015-95a6-41ce-8277-65078be20ab7.100x100xfit.png" },
      { value: 138, img: appstore.resourceurl + "resourcesPreset/761c5a5e-2887-45a8-8e3a-a5463a395493.100x100xfit.png" },
      { value: 139, img: appstore.resourceurl + "resourcesPreset/282cd7e0-e014-45f3-be44-cdd17e5039af.100x100xfit.png" },
      { value: 140, img: appstore.resourceurl + "resourcesPreset/aac005ab-8643-49dc-aa24-6163619b9ede.100x100xfit.png" },
      { value: 141, img: appstore.resourceurl + "resourcesPreset/f37336e8-7707-4af9-bf75-95cd01b5e06a.100x100xfit.png" },
      { value: 142, img: appstore.resourceurl + "resourcesPreset/559248a2-98d2-478b-a4eb-92d09e3813dc.100x100xfit.png" },
      { value: 143, img: appstore.resourceurl + "resourcesPreset/4374d52d-3e7a-4cae-9c9e-c7b58be13e32.100x100xfit.png" },
      { value: 144, img: appstore.resourceurl + "resourcesPreset/b67ccc7e-be3e-49a7-a885-2b0247090e68.100x100xfit.png" },

    ]

  }

   setwebsiteTourStep(appStore) {
    this.tourState = {
			steps: [
			 
				{
					target: '.newwebsite_tour',
					content: appStore.getLanguageTrans('Enter your website title and domain name,then click save.'),
					disableBeacon: true,
					title: "HelpOnClik Guide", 
          placement:"bottom",
          stepNumber:2,
          offset: -10,
				  },
				  
				  {
					target: '.addnewWidget_tour',
					content: appStore.getLanguageTrans('Click here to create a chat widget for your website.'),
					disableBeacon: true,
					title: "HelpOnClik Guide",
          placement:"auto",
          stepNumber:3,
          offset: -10,
          },
			],
			stepIndex: 0,
			overlaydisplay:false,
      isHomePage : false,
      isWebsitePage:true,
      };
    }
  


  getSelectedWebsite(appstore) {

    //if user add new website 
    if (appstore.addNewSite) {
      const newWbsite = true;
      this.settoDefault();
      return newWbsite;
    }

    let pathArray = this.defaultmenuPath.split("/");
    let website_uid;
    //if user edits website, get website_uid
    if (pathArray.length > 4 && pathArray[4].length != 0) {
      website_uid = pathArray[pathArray.length - 1];
      appstore.selectedWebisiteUid = website_uid;
    }
    //if user clicks on website, get website_uid
    else
      website_uid = appstore.selectedWebisiteUid;

    let websites = appstore.userInfo.allwebistes;
    for (let i in websites) {
      if (websites[i].website_uid === website_uid) {
        this.selectedWebsite = websites[i];
        break;
      }
    }


  }

  setWidget = (data, appstore) => {
    this.iswidgetPageLoaded=true;
    let allwidgets = [];

    
    const activeWidget  = appstore.getActiveWebsiteWidget();
    activeWidget.then(activeRecord =>{
      
        for (let i = 0; i < data.length; i++) { 
          let widget = {};
          widget.accountUid = data[i].accountUid;
          widget.uid = data[i].uid;
          widget.websiteUid = data[i].websiteUid;
          widget.modesString = data[i].modesString;
          widget.installation_name = data[i].title;
          widget.language = appstore.getLangLabel(data[i].language);
          widget.departments = data[i].dept_name && data[i].departmentUid ? [data[i].dept_name] : [];
          widget.domains = [data[i].host];
          widget.operators = data[i].nick_name && data[i].operatorUid ? [data[i].nick_name] : [];
          widget.clientConfigJson = this.clientconfigwidget(data[i].clientConfigJson, data[i].modesString,appstore);
    

          if(activeRecord && activeRecord.data){
            widget.installed =  activeRecord.data[widget.uid] ? true : false;
          }
          allwidgets.push(widget);
        }
        
        this.websiteWidgetDetails = allwidgets;
        appstore.storedWidgets = data.slice(0);
     })
     this.iswidgetPageLoaded=false;
    
  }
  

  clientconfigwidget = (data, title,appstore) => {
    let widgetsconfigdata = JSON.parse(data); 
    let datareturn = null; 

    if (title == "widget,popupInvitation" || title == "widget" ) {
      datareturn = widgetsconfigdata;
    } else if (title == "toolbar") {
      widgetsconfigdata.buttonviewarray=this.buttonarray(widgetsconfigdata.toolbar.buttons);
// console.log(widgetsconfigdata);
      datareturn = widgetsconfigdata;
    } else if(title == "icon,popupInvitation"){
        if(widgetsconfigdata.icon.hasOwnProperty("iconLinks") && widgetsconfigdata.icon.iconLinks==null){
          widgetsconfigdata.icon.iconLinks={
            offline:appstore.imgpath + "icons/1_offline.png",
            online:appstore.imgpath + "icons/1_online.png"
          }
        }else if(!widgetsconfigdata.icon.hasOwnProperty("iconLinks")){
          widgetsconfigdata.icon.iconLinks={
            offline:appstore.imgpath + "icons/1_offline.png",
            online:appstore.imgpath + "icons/1_online.png"
          }
        }
      datareturn=widgetsconfigdata.icon.iconLinks
    } else if(title == "widget,icon,popupInvitation" || title == "widget,icon"){
      if(widgetsconfigdata.icon.hasOwnProperty("iconLinks") && widgetsconfigdata.icon.iconLinks==null){
        widgetsconfigdata.icon.iconLinks={
          offline:appstore.imgpath + "icons/1_offline.png",
          online:appstore.imgpath + "icons/1_online.png"
        }
      }else if(!widgetsconfigdata.icon.hasOwnProperty("iconLinks")){
        widgetsconfigdata.icon.iconLinks={
          offline:appstore.imgpath + "icons/1_offline.png",
          online:appstore.imgpath + "icons/1_online.png"
        }
      }
      // console.log(widgetsconfigdata);
      datareturn=widgetsconfigdata
    }
    else{
      datareturn = "green";
    }  
    return datareturn;

  }

  buttonarray = (buttons) => { 
    let buttonsarray = buttons;
    let newarray = [];
    let k=0;
    for (let i in buttons) {  
        // console.log(i);
        var chat_or_email_visivle=false; 
        if(i == "email" || i == "chat"){
            if(buttons[i].hasOwnProperty("visible")==false){
                chat_or_email_visivle=true;
                if(i == "chat" && !buttons[i].hasOwnProperty("caption")){
                buttons[i].caption="Live Chat with us";
                }
                if(i == "email" && !buttons[i].hasOwnProperty("caption")){
                    buttons[i].caption="Leave us a message";
                    }
            }
        }
        if (chat_or_email_visivle || buttons[i].visible == true) {
            if (i == "call") {
                newarray.push(buttons[i])
                newarray[k].label = "call";
            } else if (i == "chat") {
                newarray.push(buttons[i])
                newarray[k].label = "chat";
            } else if (i == "email") {
                newarray.push(buttons[i])
                newarray[k].label = "email";
            } else if (i == "social_facebook") {
                newarray.push(buttons[i])
                newarray[k].label = "social_facebook";
            } else if (i == "social_twitter") {
                newarray.push(buttons[i])
                newarray[k].label = "social_twitter";
            } else if (i == "custom_1") {
                newarray.push(buttons[i])
                newarray[k].label = "custom_1";
            } else if (i == "custom_2") {
                newarray.push(buttons[i])
                newarray[k].label = "custom_2";
            } else if (i == "custom_3") {
                newarray.push(buttons[i])
                newarray[k].label = "custom_3";
            }
            k = k + 1;
        }
    } 
    // console.log(newarray);
    return newarray;
}

  confirmDeleteWidget(appstore) {
    this.errorPopup = false;
    let deleteobject={
      accountUid: this.widgetfordelete.accountUid,
      websiteUid: this.widgetfordelete.websiteUid,
      uid: this.widgetfordelete.uid
    }
    axios({
      method: 'POST',
      url: appstore.url + 'deleteWidget.json',
      headers: { 'content-type': 'application/json' },
      data: deleteobject,
    }).then(result => {
      if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  appstore.logout();
				}
			  }
      if (result.data != null && result.data.length != 0) {
        //display updated widgets
        this.setWidget(result.data, appstore);
        this.confirmDelete = false;
      } else {
        //show error 
        this.errorPopup = true;
      }
      //  this.saveinProcess=false;
      appstore.global_errorcount=0;
    }).catch((error) => {
      // if (error.response) {
      //   //console.log(error);
      //   appstore.logout();
      // }
      this.confirmDelete = false;
      console.log(error);
      console.log("error count :"+appstore.global_errorcount);
      appstore.global_errorcount=appstore.global_errorcount+1;
      if(appstore.global_errorcount>20){
      // appstore.logout();
      }
    })
  }

}
decorate(WebsiteWidgetStore, {
  websiteWidgetDetails: observable,
  getCode: observable,
  sendCode: observable,
  confirmDelete: observable,
  thumbnailHeight: observable,
  selectedWebsite: observable,
  widgetfordelete: observable,
  errorPopup: observable,
  thumbnailGetcode: observable,
  defaultmenuPath: observable,
  widgetThemes: observable,
  widgetDetails: observable,
  topBarColors: observable,
  iconLibraryFiles: observable,
  getcodeerror:observable,
  advancedcode:observable,
  buttonviewarray:observable,
  tourGuideStart:observable,
  tourState:observable,
  iswidgetPageLoaded:observable,
})

export default WebsiteWidgetStore;