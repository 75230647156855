import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink } from "react-csv";


const exportDailyReportCSV = observer(class exportDailyReportCSV extends Component {

    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }


    render() {

        let headers = [
            { label: "Operators", key: "nick_name" },
            { label: "Online Time", key: "totaltime" },
            { label: "Chats", key: "_chats" },
            // { label: "Missed Chats", key: "_chat_missed" },
        ];

        let exportdata = this.props.data ? this.props.data : [];
        const {appStore}=this.props;
        return (

            <CSVLink data={exportdata} headers={headers} target="_blank" filename={"DailyOverView Reports.csv"} >
                {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
        );
    }


});

export default exportDailyReportCSV;
