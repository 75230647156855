import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Select , { components } from 'react-select';

import { CirclePicker } from 'react-color';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../sharedComponents/dropDown/dropDown';


const ThemeSelectorComponent = observer(class ThemeSelectorComponent extends Component {

	constructor (props) {
		super(props);
		this.props.widgetStore.loadCustomlibrary(this.props.appstore);
        this.state = {
        	selectedTheme: -1
        }
	    this.colors = [
	    	["#0475A8"], ["#5F8706"], ["#D6680B"], ["#B0097D"], ["#9D0A09"], ["#424E54"], ["#037FAF","#333333"], ["#608706","#333333"],
	    	["#D26009","#333333"], ["#AF097D","#333333"], ["#950909","#333333"], ["#728992","#333333"], ["#0476A8","#F4F4F4"], ["#98B45D","#F4F4F4"], ["#ADADAD","#F4F4F4"], ["#98A7AD","#F4F4F4"]
	    ];
		
    }

	componentDidMount(){
	}

	chooseTheme(themeIndex) { 
		this.setState({
			selectedTheme: themeIndex
		}); 
		
		this.props.widgetStore.widgetDetails.themeChoosen = this.props.widgetStore.widgetThemes[themeIndex]; 
	}
	getGradient(colorObj) {
		let gradient = "";
		//gradient = 'linear-gradient(to bottom,'+ this.LightenDarkenColor(baseColor,20) +' 0%,'+this.LightenDarkenColor(baseColor,25)+' 5%,'+this.LightenDarkenColor(baseColor,50)+' 95%,'+this.LightenDarkenColor(baseColor,60)+ ' 100%)';
		gradient = 'linear-gradient(to right,'+colorObj[0] + (colorObj[1] ? ' 50%,' + colorObj[1] + ' 50%' : '') + ')';
		return gradient;
	}

	componentWillUnmount() {
    }

	render(){

		var self = this;

		const { appstore,
				widgetStore } = this.props;

		return (
				<React.Fragment>
						<div className="preview" style={{backgroundImage: 'url('+ widgetStore.widgetDetails.themeChoosen.image + ')'}}>
							<div className="widget-preview">
								<div className="widget-preview-title">{appstore.getLanguageTrans("Live Chat")}</div>
								<div className="widget-preview-text">{appstore.getLanguageTrans("Type your question here")}</div>
								<div className="widget-preview-copy"></div>
							</div> 
						</div>
						<div className="widgetPosition">
							<p>
								<span className={widgetStore.widgetDetails.widgetPosition === 'Bottom Left' ? 'selected' : ''}
										onClick={() => widgetStore.widgetDetails.widgetPosition = 'Bottom Left' }>{appstore.getLanguageTrans("Bottom Left")}</span>
								<span className={widgetStore.widgetDetails.widgetPosition === 'Bottom Right' ? 'selected' : ''}
										onClick={() => widgetStore.widgetDetails.widgetPosition = 'Bottom Right' }>{appstore.getLanguageTrans("Bottom Right")}</span>
							</p>
						</div>
						<div className="themeSelectCompWrapper">
						{
							this.colors.map(function(theme, themeIndex){
								return (
									<div key={themeIndex} className={"themeSelectComp" + (self.state.selectedTheme === themeIndex ? ' selected' : '')} onClick={() => self.chooseTheme(themeIndex)} style={{border: self.state.selectedTheme === themeIndex ? "2px solid "+theme[0] : ''}}>
										<span><div style={{backgroundColor: theme[0],background: self.getGradient(theme)}}></div></span>
									</div>
								)
							})
						}
						</div>
						
				</React.Fragment>
		)
	}
})
export default ThemeSelectorComponent;
