
import React, {Component } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import imageURLs from '../../sharedFiles/ImagesURLs';

const SpellCheck = observer(class SpellCheck extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  addStar() {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '⭐',
      editorState.getCurrentInlineStyle(),
    );
    onChange(this.props.callBack());
  };

  render() {
    return (
      <div className="editorSpellCheck" onClick={() => this.props.callBack()}><img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.spell_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.spell_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.spell_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.spell_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.spell_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.spell_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.spell_voilet : 
      "#039be6" == this.props.appstore.selectedColor.color ? imageURLs.spell_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.spell_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.spell_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.spell_grey : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.spell_skyblue : imageURLs.spell_steelblue} className="img-fluid" /></div>
    );
  }
})
export default SpellCheck;
