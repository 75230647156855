import React, {Component } from 'react';
import { observer } from 'mobx-react';

import imageURLs from '../../../../sharedFiles/ImagesURLs'

const ChatResponse = observer(class ChatResponse extends Component {

	constructor (props) {
		super(props);
		this.isResponseForOp = this.isResponseForOp.bind(this);
    }
	componentWillMount(){

	}
	isResponseForOp() { 
		let loggedOp = this.props.appstore.userInfo.usersmodel.user_uid;
	    return this.props.response.op_uid === loggedOp ? true :false;
    }
	
	render(){

		let self = this;

		const { responseStore,
				response,
				pos,
				appstore } = this.props;
	
		return (
			<div id={"response"+String(pos)} className={((pos === 0 || pos%2 === 0) ? "row ruleComponent even " : "row ruleComponent odd ")+appstore.selectedColor.value}>
				<div className="align-self-center">
					<img style={{opacity:0.5}} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.arrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.arrow_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.arrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.arrow_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.arrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.arrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.arrow_voilet : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.arrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.arrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.arrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.arrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.arrow_skyblue : imageURLs.arrow_steelblue} alt="faq" className="img-fluid"/>
				</div>
				<div className="rule_data">
					<div className="row">
						<div className='col-12 col-sm-7 col-xl-8 text-left'>
							<p className="ques">
								{response.question}
							</p> 
							<p className="response" dangerouslySetInnerHTML={{__html: response.value}}>
							</p>
						</div>
						<div className='col-12 col-sm-1 col-xl-1 text-right'>
							
								{ response.imageslist.length>0 &&
								<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.link_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.link_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.link_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.link_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.link_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.link_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.link_voilet : 
								"#039be6" == appstore.selectedColor.color ? imageURLs.link_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.link_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.link_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.link_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.link_skyblue : imageURLs.link_steelblue} alt="attachment" />
								// <img src={imageURLs.link} />
								}
					
						</div>
							<div className={"col-12 col-sm-4 col-xl-3 optionArea align-self-center "+(this.isResponseForOp() || !appstore.isAdmin  && "cursorNotAllowed") }>
								<div className={"editWrapper row m-0 "+(this.isResponseForOp() || !appstore.isAdmin  && "disabledbutton") }>
									
									<div className="align-self-center cursor-pointer" onClick={() => responseStore.editResponseData(response,appstore)}>
										<p><img src={imageURLs.editIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("edit")}</span></p>
									</div>
										<div className="align-self-center cursor-pointer" onClick={() => responseStore.deleteResponse(response)}>
											<p><img src={imageURLs.deleteIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("delete")}</span></p>
										</div>
								</div>
							</div>
					</div>
				</div>
			</div>
		)
	}
})
export default ChatResponse;
