import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink} from "react-csv";
 

const smsExportCSV = observer(class smsExportCSV extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

    }
    invexport(data) {
        let exportdata = []
        let chat_Date=0
        let prev_count=0
        if(data == null || !data){
        
          return exportdata;
        }
        for (let j = 0; j < data.length; j++) {
    
          if (data[j].type === "sms_usage") {
            if (chat_Date=== data[j].quarter_date) {
              prev_count= Number(prev_count) + Number(data[j].cnt);
             exportdata[exportdata.length-1].Chattime = prev_count;
           }
           else{
            data[j].Date = data[j].quarter_date
            data[j].SMSCount = data[j].cnt;
            prev_count=Number(data[j].cnt)
            chat_Date=data[j].quarter_date
            exportdata.push(data[j])
           }
          }
         
        }
        return exportdata;
      }

    render() {
      
    let  headers = [
        { label: "Date", key: "Date" },
        { label: "SMS Used", key: "SMSCount" },
      ];

      let exportdata = this.invexport(this.props.data);
      const {appStore}=this.props;
      return(
      <CSVLink  data={exportdata} headers={headers} target="_blank" filename={"Usage Reports.csv"} >
               {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
      );
      }


});

export default smsExportCSV;
