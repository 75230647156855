import { decorate, observable, extendObservable } from 'mobx';

import {data} from '../../../../assets/JSON/admin_fileTransfer';
import axios from 'axios';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);
class FileTransferStore {
	  
    editScriptRef;

  	constructor(appstore){
          this.fileTransferDrpDwn = [
                // {value:3, label:'Use account defaults',id:""},
                {value:1, label:'Enable',id:"all"},
                {value:2, label:'Enable for operators only',id:"operators"},
                {value:0, label:'Disable',id:"none"}
                ];
          this.ispageLoaded = false;

          this.fileLifeTimeDrpDwn = [
                // {value:2, label:'Use account defaults',id:""},   
                {value:1, label:'Permanent',id:'persistent'},
                {value:0, label:'Until chat session ends',id:'talk_session'},
                ];

           this.responseData = {};
           this.confirm = false;
           this.percenSpaceUsed = ((this.responseData.totalSpace - this.responseData.freeSpace)/this.responseData.totalSpace)*100; 

          // this.setDropDownValues(appstore);
           //this.setInitialValues();
     }

     setInitialValues() {
       this.deleteFileArray = [];
       this.allSelected = false;
       this.responseData.transferredFiles.map(function(filetype,typeIndex){
           filetype.selected = false;
       });
     }

     setDropDownValues(appstore) {
       let self = this;
       let allwebsite = appstore.userInfo.allwebistes;

       let selectedWebsite =   allwebsite.find(function(website,index){
         return appstore.selectedWebisiteUid == website.website_uid;
        })


       if(selectedWebsite.enableFileTransfer == null)
          self.fileTransferType = this.fileTransferDrpDwn[0]; 
       else{
         this.fileTransferDrpDwn.map(function(filetype,typeIndex){
          if(filetype.id === selectedWebsite.enableFileTransfer) 
             self.fileTransferType = filetype;
         });
       }
 
        // if(selectedWebsite.cannedLinkLiveType == null)
        //     self.cannedFileLifeTimeType = this.fileLifeTimeDrpDwn[0];
        // else{
        //   this.fileLifeTimeDrpDwn.map(function(lifeTimetype,typeIndex){
        //     if(lifeTimetype.id === selectedWebsite.cannedLinkLiveType) 
        //      self.cannedFileLifeTimeType = lifeTimetype;
        //   });
        // }

        if(selectedWebsite.transferLinkLiveType == null)
             self.transFileLifeTimeType = this.fileLifeTimeDrpDwn[0];
        else{
          this.fileLifeTimeDrpDwn.map(function(lifeTimetype,typeIndex){
           if(lifeTimetype.id === selectedWebsite.transferLinkLiveType) 
             self.transFileLifeTimeType = lifeTimetype;
      
         });
        }          
     }

     downloadingfile = (fileurl,filename,appstore) =>{
      appstore.toDataURL(fileurl, function(dataUrl) { 
        appstore.downloadBase64File(dataUrl, filename)
      });
    }


     saveFiledata(appstore){
      this.ispageLoaded = false;
      let data = {
        "enableFileTransfer" :this.fileTransferType.id === "" ? appstore.userInfo.accountsmodel.enableFileTransfer :this.fileTransferType.id ,
        // "cannedLinkLiveType": this.cannedFileLifeTimeType.id ==="" ? appstore.userInfo.accountsmodel.cannedLinkLiveType : this.cannedFileLifeTimeType.id ,
        "transferLinkLiveType":this.transFileLifeTimeType.id === "" ? appstore.userInfo.accountsmodel.transferLinkLiveType : this.transFileLifeTimeType.id ,
        "website_uid" : appstore.selectedWebisiteUid
      }

      axios({
        method: 'POST',
        url: appstore.url+'savefiletransfer.json',
        headers: { 'content-type': 'application/json' },
        data:data
      }).then(result=> {
        if(result.data==502){
				  appstore.logout();
				}
                if(result.data !=0){
                    let allwebsite = appstore.userInfo.allwebistes;
                    let selectedWebsite =  allwebsite.find(function(website,index){
                      return appstore.selectedWebisiteUid == website.website_uid;
                      })
                      selectedWebsite ["enableFileTransfer"] = data.enableFileTransfer;
                      // selectedWebsite["cannedLinkLiveType"] = data.cannedLinkLiveType;
                      selectedWebsite["transferLinkLiveType"] = data.transferLinkLiveType;
                    
                  }else{
                    this.errormodel = true;
                  }

                this.ispageLoaded = true;
                appstore.global_errorcount=0;
                    
        }).catch((error) => {
                    // appstore.logout();
                    this.ispageLoaded = true;
                    console.log(error);
                    console.log("error count :"+appstore.global_errorcount);
                    appstore.global_errorcount=appstore.global_errorcount+1;
                    if(appstore.global_errorcount>20){
                    // appstore.logout();
                    }
                 });

     }

  getallFiles(appstore){
    this.ispageLoaded = false;
      axios({
        method: 'GET',
        url: appstore.url+'gettransferredFiles.json',
        headers: { 'content-type': 'application/json' }
      }).then(result=> {
        if(result.data.length==1){
          if(result.data[0].logoutstatus==1){
            appstore.logout();
          }
          }
                    if(result.data){
                      this.responseData.transferredFiles=[];
                      for(let i in result.data){
                            let time = result.data[i].creationTime;
                            var createddate=moment(result.data[i].creationTime).format("YYYY-MM-DD HH:mm:ss")
                            var d= time.split("T");
                            var t=d[1].split(".");
                            //set sent recived icon status
                            let sentstatus = result.data[i]["folder"].indexOf("chat_transfer") == -1 ? 0 :1;

                        this.responseData.transferredFiles.push({
                              "uid"  : result.data[i].uid,
                              "fileUrl": appstore.resourceurl + appstore.userInfo.accountsmodel.account_uid + "/chat_transfer/" +result.data[i].fileName,
                              "name" : result.data[i].title,
                              "size" : (result.data[i].fileSize/1000)+" kB", 
                              // "date" : d[0]+" "+t[0],
                              "date" : createddate,
                              "sent" :sentstatus,
                            });
                       
                      }
                      // //set file transfer dropdown
                     this.setDropDownValues(appstore);
                     this.setInitialValues();
                     this.getStoragespace(appstore);
                     }

                    this.ispageLoaded = true;
                    appstore.global_errorcount=0;
                    
        }).catch((error) => {
                    // appstore.logout();
                    this.ispageLoaded = true;
                    console.log(error);
                    console.log("error count :"+appstore.global_errorcount);
                    appstore.global_errorcount=appstore.global_errorcount+1;
                    if(appstore.global_errorcount>20){
                    // appstore.logout();
                    }

                 });
      }


      getStoragespace(appstore){
        this.ispageLoaded = false;
          axios({
            method: 'POST',
            url: appstore.url+'getfilestorage.json',
            headers: { 'content-type': 'application/json' },
            data:{"account_id":0}
          }).then(result=> {
            if(result.data==502){
              appstore.logout();
            }
            let filestorge = appstore.userInfo.accountplan.file_storage_space;
            
                        if(result.data!=-1){
                          this.responseData.freeSpace=(filestorge - result.data/1024/1024).toFixed(2);
                          this.responseData.spaceUnit ="Mb";
				                  // this.responseData.totalSpace =200;
                          this.responseData.totalSpace = filestorge;
                         }else{
                          // this.responseData.freeSpace=200;
                          this.responseData.freeSpace= filestorge;
                          this.responseData.spaceUnit ="Mb";
				                  // this.responseData.totalSpace =200;
                          this.responseData.totalSpace = filestorge;
                         }  
                         this.ispageLoaded = true;   
                         appstore.global_errorcount=0;     
            }).catch((error) => {
                      //  appstore.logout();
                        this.ispageLoaded = true;
                        console.log(error);
                    console.log("error count :"+appstore.global_errorcount);
                    appstore.global_errorcount=appstore.global_errorcount+1;
                    if(appstore.global_errorcount>20){
                    // appstore.logout();
                    }
            });
          }

          
     selectAll() {
       let self = this;
       this.allSelected = !this.allSelected;
       if(this.allSelected) {
         this.responseData.transferredFiles.map(function(filetype,typeIndex){
           filetype.selected = true;
           self.deleteFileArray.push(typeIndex);
         });
       }
       else{
         this.responseData.transferredFiles.map(function(filetype,typeIndex){
           filetype.selected = false;
         });
         this.deleteFileArray = [];
       }
     }

     confirmAction(appstore) {
       this.confirm = false;
       this.ispageLoaded = false;
       let deletefiles =[];
       let self = this;
       for(let i in this.deleteFileArray){
         deletefiles.push(
          this.responseData.transferredFiles.find(function(value,index){
           return index == self.deleteFileArray[i];
          })
        )
       }
       
       axios({
        method: 'POST',
        url: appstore.url+'deletetransferredFiles.json',
        headers: { 'content-type': 'application/json' },
        data:deletefiles
      }).then(result=> {
        if(result.data.length==1){
          if(result.data[0].logoutstatus==1){
            appstore.logout();
          }
          }
                    console.log('deletetransferredFiles response*******');
                    console.log(result);
                    if(result.data){
                      this.responseData.transferredFiles=[];
                      for(let i in result.data){
                           
                          let time = result.data[i].creationTime;
                          var d= time.split("T");
                          var t=d[1].split(".");
                          let sentstatus = result.data[i]["folder"].indexOf("chat_transfer") == -1 ? 0 :1;

                          this.responseData.transferredFiles.push({
                              "uid"  : result.data[i].uid,
                              "name" : result.data[i].title,
                              "size" : (result.data[i].fileSize/1000)+" kB", 
                              "date" : d[0]+" "+t[0],
                              "sent" :sentstatus,
                            });    
                       
                      }
                      this.getStoragespace(appstore);
                     }
                    this.ispageLoaded = true;
                    appstore.global_errorcount=0;
        }).catch((error) => {
                    // if (error.response) {
                    //   console.log(error.response)
                    // }
                    // appstore.logout();
                    this.ispageLoaded = true;
                    console.log(error);
                    console.log("error count :"+appstore.global_errorcount);
                    appstore.global_errorcount=appstore.global_errorcount+1;
                    if(appstore.global_errorcount>20){
                    // appstore.logout();
                    }
         });
       this.setInitialValues();
     }

     getFileName = (name) =>{
     
      let newName= name;
      const files =	this.responseData.transferredFiles;
      let count = 0;
      for(let i=0; i< files.length; i++){
         if(name === files.name){
                count++;
         }
      }

      return newName;
    }

 }

 decorate(FileTransferStore, {
      fileTransferType: observable,
      cannedFileLifeTimeType: observable,
      transFileLifeTimeType: observable,
      responseData: observable,
      deleteFileArray: observable,
      percenSpaceUsed: observable,
      confirm: observable,
      ispageLoaded:observable,
      errormodel:observable
    })
 export default FileTransferStore;