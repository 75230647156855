import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

const ScriptAddEditLinkComponent = observer(class ScriptAddEditLinkComponent extends Component {

	constructor (props) {
        super(props);

    }
	

	render(){

		const { scriptStore,
				editData,
				addResponseLink,
			    appstore } = this.props;
		return (
					<Form>
						 <Form.Group as={Row} controlId="formGroupLinkTitle">
							    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Link Title")}</Form.Label>
							    <Col sm="8" lg="9">
							    	<Form.Control type="text" placeholder={appstore.getLanguageTrans("Please enter title")} 
							    					value={editData.title}
							    					onChange={(e) => editData.title = e.target.value}  />
							    </Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupLink">
							    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Link")}</Form.Label>
							    <Col sm="8" lg="9">
							    	<Form.Control type="text" placeholder={appstore.getLanguageTrans("Please enter link")} 
							    					value={editData.link}
							    					onChange={(e) => editData.link = e.target.value}/>
							    </Col>
						  </Form.Group>

						  <div className="row m-0">
							<div className="col-12 formBtns">
								<div className="pullRight">
									<Button className="btn_clr" variant="light" onClick={() => scriptStore.cancelSaveLink()}>
								    	<p>{appstore.getLanguageTrans("Cancel")}</p>
								    </Button>
								    <Button className="btn_save" onClick={() => scriptStore.saveLink(editData,addResponseLink,editData.related_uid)}>
								    	<p>{appstore.getLanguageTrans("Save")}</p>
								    </Button>
								</div>
							</div>
						</div>
					</Form>
		)
	}
})
export default ScriptAddEditLinkComponent;
