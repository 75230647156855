import React, { Component } from 'react';
import { decorate, observable, extendObservable } from 'mobx';
import {data} from '../../../../assets/JSON/admin_archivechat';
import axios from 'axios';


class ReportChartsStore {

    constructor(appstore){
      this.ispageLoaded = false;
           this.responseData = data[0];
           this.confirm = false;
           this.datepicker= false;
          //this.selectedfileLifeTimeDrpDwn =  this.fileLifeTimeDrpDwn[0]; 
          //this.selectedfileTransferDrpDwn =  this.fileTransferDrpDwn[0];
          this.websiteDrpDwnOptions = [];
          this.allDepartments =[];  
          this.allWebsites =[];
          this.chats = 80; 
          this.totalchartSum = 0;
          this.totalchartstime=0;
          this.pageLoder= false;
          this.Simultaneous = false;
          this.chat_report_dept=[{"label":"All Departments","value":0,"id":0,"optionType":"deptAll","department_uid":""}];
          this.chat_report_opt=[{ "label": "All Operators", "value": 0, "user_uid": "" }];
          this.alldepartment=this.chat_report_dept[0];
          this.alloperators=this.chat_report_opt[0];
          this.performanceData = {
            "chartsDate": "",
            "chartsCount": 0,
            "totalchartSum": 0,
            "totalchartstime": 0,
            "chartstime": 0,
            "Chatlength": 0,
            "AverageChatlength": 0,
          };
          this.avg_performanceData = {
            "chartsDate": "",
            "chartsCount": 0,
            "chartstime": 0,
            "Chatlength": 0,
          };
          // this.setDropDownValues(appstore);
           //this.setInitialValues();
     }

     setWebsites(websitesmodel,appstore) {
        let websites = JSON.parse(JSON.stringify(websitesmodel));
        this.pageLoder =false;
      
        if(websites !=null){
  
          let all = {"label":"All","value":0,"id":0,"optionType":"websiteAll","website_uid":""};
          websites.splice(0, 0,all);   //insert All option in dropdown options
          let x;
          let index =0;
          for (x in websites)
          {
            if(x>0){
            websites[x].label=websites[x].name;
            websites[x].value= index;
            }
            index++;
          }
         
           this.websiteDrpDwnOptions = websites;
           this.allwebsite = this.websiteDrpDwnOptions[0]
           this.pageLoder =true;
  
        }
       }
       setOperators(operatorsmodel,appstore){
        let operators = JSON.parse(JSON.stringify(operatorsmodel));;
        if(operators !=null){
  
          let all = {"label":"All Operators","value":0,"user_uid": ""};
          operators.splice(0, 0,all);   //insert All option in dropdown options  
          let i;
          let index =0;
          for (i in operators)
          {
            if(i>0){ 
              operators[i].label=operators[i].nick_name;
              operators[i].value = operators[i].user_uid;
					    operators[i].user_uid = operators[i].user_uid;
            }
            index++;
          }
          this.chat_report_opt=[];
          this.chat_report_opt = operators;
		    	this.alloperators = this.chat_report_opt[0];
        }
       }
       setDepartments(departmentsmodel,appstore) {
        let departments = JSON.parse(JSON.stringify(departmentsmodel));
        this.pageLoder =false;
    
        if(departments !=null){
  
          let all = {"label":"All Departments","value":0,"id":0,"optionType":"deptAll","department_uid":""};
          departments.splice(0, 0,all);   //insert All option in dropdown options  
          let i;
          let index =0;
          for (i in departments)
          {
            if(i>0){
              departments[i].label=departments[i].name;
              departments[i].value= index;
            }
            index++;
          }
            //clone data in new array
            this.deptDrpDwnOptions = departments;
            this.chat_report_dept = [];
            this.chat_report_dept = departments;
            this.alldepartment=this.chat_report_dept[0]
            this.pageLoder =true;
        }
       }
       
}

decorate(ReportChartsStore, {
    datepicker:observable,
    websiteDrpDwnOptions:observable,
    operatorDrpDwnOptions:observable,
    alldepartment:observable,
    alloperators:observable,
    allwebsite:observable,
    isPageLoaded:observable,
    responseData:observable,
    performanceData:observable,
    Simultaneous: observable,
    charts:observable,
    pageLoder:observable,
    chat_report_opt:observable,
    chat_report_dept:observable,
    exportdata:observable,
    export:observable,
    avg_performanceData:observable
})

export default ReportChartsStore;