import React, {Component } from 'react';
import { observer } from 'mobx-react';

import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';


const AddConditionComponent = observer(class AddConditionComponent extends Component {

	constructor (props) {
        super(props);

        this.conditonsUrl = [
        						{label: 'Url',value:'url'},
        						{label: 'Referrer',value:'referrer'}
        ];

        this.conditonsConform = [ 
        						{label: 'contains',value:'contains'},
        						{label: "doesn't contain",value:"no_contains"},
        						{label: 'matches',value:'matches'},
        						{label: "doesn't match",value:"no_match"},
        						{label: 'starts with',value:'starts_with'},
        						{label: 'ends with',value:'ends_with'},
        ];
		// this.conditonsUrl[0]
		// this.conditonsConform 
        this.props.invitationRuleStore.selectedUrl = this.setDropdownForEdit(this.conditonsUrl,this.props.invitationRuleStore.selectedUrl);
		this.props.invitationRuleStore.selectedConform = this.setDropdownForEdit(this.conditonsConform,this.props.invitationRuleStore.selectedConform);
		
	}
	setDropdownForEdit(options,checkfor){ 
        let y;
        let x = options.filter(function (option) {
        if(checkfor===option.value)
            y = option;
      });
        
	  return y;
	}
	componentDidMount(){
		let self = this;

		this.conditonsUrl.map(function(url,urlIndex) {
			if(url.value === self.props.invitationRuleStore.conditionToEdit.type) {
				self.props.invitationRuleStore.selectedUrl = url;
			}
		})

		this.conditonsConform.map(function(conform,conformIndex) {
			if(conform.value === self.props.invitationRuleStore.conditionToEdit.conform) {
				self.props.invitationRuleStore.selectedConform = conform;
			}
		})
	}

	componentWillReceiveProps(nextProps){
		let self = this;
			this.conditonsUrl.map(function(url,urlIndex) {
				if(url.value === nextProps.invitationRuleStore.conditionToEdit.type) {
					self.props.invitationRuleStore.selectedUrl = url;
				}
			})
			this.conditonsConform.map(function(conform,conformIndex) {
				if(conform.value === nextProps.invitationRuleStore.conditionToEdit.conform) {
					self.props.invitationRuleStore.selectedConform = conform;
				}
			})
	}
	getConform(conform){
		let label=""; 
		this.props.invitationRuleStore.conditionConform.filter(function (option) {
			if(conform===option.value)
				label = option.label;
		  });
		return label;
	}
	render(){

		let self = this;

		const { invitationRuleStore,
				 } = this.props;

	
		return (
						
			<div className="conditionsWrapper col-12 conditionComponent">
				<div className="row">

						<Form.Group controlId="formGroupConditionUrl" className="col-12 col-sm-3">
						    <Col sm="12" className="customDrpDwn pl-0 align-self-center">
								<DropDownComponent dropDownOptions={this.conditonsUrl} 
												   key={Math.random()}
						    					   store={invitationRuleStore}
						    					   callBack={(selectedOption) =>{invitationRuleStore.selectedUrl = selectedOption;invitationRuleStore.conditionToEdit.type = selectedOption.value}}
						    					   selectedOption = {invitationRuleStore.selectedUrl}/>
						    </Col>
					  </Form.Group>
					  <Form.Group controlId="formGroupConditionUrl" className="col-12 col-sm-4">
						    <Col sm="12" className="customDrpDwn pl-0 align-self-center">
								<DropDownComponent dropDownOptions={this.conditonsConform} 
								                   key={Math.random()}
						    					   store={invitationRuleStore}
						    					   callBack={(selectedOption) =>{invitationRuleStore.selectedConform = selectedOption;invitationRuleStore.conditionToEdit.conform = selectedOption.value}}
						    					   selectedOption = {invitationRuleStore.selectedConform}/>
						    </Col>
					  </Form.Group>

					  <Form.Group controlId="formGroupConditionUrl" className="col-12 col-sm-5">
						    <Col sm="12" className="pl-0">
								<Form.Control className="customInput" type="text" placeholder="" 												
						    					value={invitationRuleStore.conditionToEdit.mask}
						    				  onChange={(e) => {invitationRuleStore.conditionToEdit.mask = e.target.value;invitationRuleStore.validatefields()}} required  />
						    {invitationRuleStore.validate &&
							<span className="error-block">*required</span>
							}
							</Col>
					  </Form.Group>
					  <p className="conditonPreview">The condition is: <span className="text-capitalize">{invitationRuleStore.conditionToEdit.type}</span>{" "+self.getConform(invitationRuleStore.conditionToEdit.conform)}{" "+invitationRuleStore.conditionToEdit.mask}</p>
						<div className="col-12 formBtns">
							<div className="pullRight">
								<Button className="btn_clr" variant="light" onClick={() => invitationRuleStore.cancelSaveCondition()}>
							    	<p>Cancel</p>
							    </Button>
							    <Button className="btn_save" onClick={() => invitationRuleStore.addCondition(invitationRuleStore.conditionToEdit)}>
							    	<p>Save</p>
							    </Button>
							</div>
						</div>
				</div>
			</div>
		)
	}
})
export default AddConditionComponent;
