import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../sharedFiles/ImagesURLs';
import navigationStore from '../sharedFiles/navigation-store';

import { css } from '@emotion/core';
import { ScaleLoader } from 'react-spinners';
import LoginService from './LoginService';
import FormValidator from '../sharedFiles/FormValidator';
import axios from 'axios';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: #3ba8c5;
     background-color:transparent;
     margin-left:160px;`;



const ForgotComponent = observer(class ForgotComponent extends Component {

    constructor(props) {
        super(props);
        console.log(props)
        console.log("forgot");
        this.loginService = new LoginService(this.props)
        this.submitted = false;
        this.loginService.forgotloading = true;
    }

    checkKeyEnter = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('loginbtn').click()
        }
    }

    componentDidMount() {
        this.checkvalidtoken();

    }

    componentWillUnmount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState) {
    }

    checkvalidtoken = () => {
        this.loginService.tokenexpired = false;
        var tokenobject = {
            "email": this.props.routerProps.match.params.email,
            "token": this.props.routerProps.match.params.token
        }
        axios({
            method: 'POST',
            url: this.props.appstore.url + 'tokenpass.json',
            headers: { 'content-type': 'application/json' },
            data: tokenobject,
        }).then(result => {
            // console.log(result);
            //this.loginService.forgotstatus = result.data == "success" ? : ;
            this.loginService.tokenexpired = result.data == "success" ? false : true;
            this.loginService.forgotloading = false;
        }).catch((error) => {
            this.props.appstore.logout();
        });

    }
    newpass = (event, appstore) => {
        this.loginService.newpasssuccess = false;
        this.loginService.newpasserror = "";
        event.preventDefault();
        this.loginService.forgotloading = true;
        let fields = this.loginService.forgot_user;

        if (fields.pass == "") {
            this.loginService.newpasserror = "Password is empty";
            this.loginService.forgotloading = false;
            return
        } else if (fields.pass !== fields.re_pass) {
            this.loginService.newpasserror = "Password and confirmation do not match each to other";
            this.loginService.forgotloading = false;
            return
        } else {
            var tokenobject = {
                "email": this.props.routerProps.match.params.email,
                "pass": fields.pass
            }
            axios({
                method: 'POST',
                url: this.props.appstore.url + 'newpassword.json',
                headers: { 'content-type': 'application/json' },
                data: tokenobject,
            }).then(result => {
                console.log(result);
                if (result.data > 0) {
                    this.loginService.newpasssuccess = true;
                }
                this.loginService.forgotloading = false;
            }).catch((error) => {
                this.props.appstore.logout();
            });
        }

    }

    handleChange(event) {
        this.updateUser(event.target.name, event.target.value);
    }

    updateUser(key, value) {
        this.loginService.forgot_user[key] = value;
    }

    render() {

        const { appstore } = this.props;
        var self = this;
        return (

            <div className="innercontent_wrapper w-100">
                <div className="websitesWrapper">
                    <div className="websiteDetailsWrapper loginWrapper">

                        <div className="websitesDetails col-12 col-sm-6 col-md-4">
                            <div className="box col-12">
                                <div className="loginHeader">
                                    <h6>{appstore.getLanguageTrans("Restore Password")}</h6>
                                </div>
                                <div className={this.loginService.tokenexpired==true? "content contentlogin":"content"}>
                                    {this.loginService.forgotloading && <div className='sweet-loading'>
                                        <ScaleLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            height={35}
                                            width={4}
                                            radius={2}
                                            color={'#3ba8c5'}
                                            loading={this.loginService.loading}
                                        />
                                    </div>
                                    }

                                    {(this.loginService.tokenexpired) &&
                                        <p className="invalidLogin" >{appstore.getLanguageTrans("The link was expired. This may happen if you have used it once, or logged in with your existent password.")} <span
                                            onClick={(e) => (this.props.appstore.navigate('/'))} style={{ cursor: "pointer",color:"#3752ed" }} >
                                            <span><u>{appstore.getLanguageTrans("Try again")}</u></span>
                                        </span> {appstore.getLanguageTrans("or contact our support")}</p>
                                    }
                                    {(this.loginService.newpasserror != "") &&
                                        <p className="invalidLogin">{this.loginService.newpasserror}</p>
                                    }

                                    {!this.loginService.tokenexpired && !this.loginService.newpasssuccess &&
                                        <div>
                                            <h6>{appstore.getLanguageTrans("Type your new password below in both fields.")}</h6>
                                        </div>
                                    }
                                    {this.loginService.newpasssuccess &&
                                        <div>
                                            <h6>{appstore.getLanguageTrans("The new password was set.")} <span className="forgotnew" onClick={(e) => this.props.appstore.navigate('/')} >{appstore.getLanguageTrans("Login ?")}</span></h6>
                                        </div>
                                    }

                                </div>
                                {!this.loginService.tokenexpired && !this.loginService.newpasssuccess &&
                                    <form className="loginForm">
                                        <Form.Group as={Row} controlId="loginPassword">
                                            <Form.Label column lg="12" sm="12">New password:</Form.Label>
                                            <Col sm="12" lg="12">
                                                <Form.Control type={"password"}
                                                    placeholder={appstore.getLanguageTrans("Enter Password")}
                                                    name="pass" value={this.loginService.forgot_user.pass}
                                                    onChange={(e) => this.handleChange(e)}
                                                    onKeyDown={(e) => this.checkKeyEnter(e)}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="loginPassword">
                                            <Form.Label column lg="12" sm="12">{appstore.getLanguageTrans("New password confirmation")}:</Form.Label>
                                            <Col sm="12" lg="12">
                                                <Form.Control type={"password"}
                                                    placeholder={appstore.getLanguageTrans("Re-Enter Password")}
                                                    name="re_pass" value={this.loginService.forgot_user.re_pass}
                                                    onChange={(e) => this.handleChange(e)}
                                                    onKeyDown={(e) => this.checkKeyEnter(e)}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <div className="row m-0">
                                            <div className="col-12 loginBtns formBtns">
                                                <div className="pullRight">
                                                    <Button id="loginbtn" className={'btn_save'}
                                                        type="button" onClick={(e) => this.newpass(e, this.props.appstore)} >
                                                        <p>{"Apply"}</p>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
})
export default ForgotComponent;
