import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../sharedFiles/navigation-store';

import RuleComponent from '../../invitationRules/components/RuleComponent';
import AddEditRulesComponent from '../../invitationRules/components/AddEditRules';
import ConfirmationComponent from '../../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import InvitationRulesStore from '../../invitationRules/InvitationRulesStore';
import WarningComponent from '../../../../sharedComponents/confirmationPopup/WarningComponent';
import ChatInvitationStore from './ChatInvitationStore';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const SortableItem = sortableElement(({invitationRulesStore,rule,pos,appstore}) => <RuleComponent invitationRulesStore = {invitationRulesStore}
																				rule = {rule} pos = {pos} appstore = {appstore}
																				 />);
const SortableContainer = sortableContainer(({children,invitationRules}) => {	
  return <div>{children}</div> ;
});

const ChatInvitationComponent = observer(class ChatInvitationComponent extends Component {

	constructor (props) {
        super(props);
        this.chatInvitationStore = new ChatInvitationStore(this.props.appstore);
        this.invitationRulesStore = new InvitationRulesStore(this.props.appstore);
        this.updateProperty = this.updateProperty.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
		this.state = {websiteUid:this.props.appstore.selectedWebisiteUid}
		 
    }
	componentDidMount(){
		this.props.appstore.menuChanged = window.location.pathname;
		// this.invitationRulesStore.pageisLoaded=true;
		this.getmanualinvitation();
		this.invitationRulesStore.getInvationRules(this.chatInvitationStore.chatrules);
		this.invitationRulesStore.dptoptforwebiste();  
		this.chatInvitationStore.validatetruefallse(this.chatInvitationStore.invitations.autoInvitation);
		// setTimeout(() => { 
		// 	this.invitationRulesStore.pageisLoaded=false;
		// },500);
		
	} 
	componentDidUpdate(prevProps, prevState) { 
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {
			this.invitationRulesStore.pageisLoaded=true;
			this.chatInvitationStore.invitations.website_uid=this.props.appstore.selectedWebisiteUid;
			this.getmanualinvitation();
			this.chatInvitationStore.validatetruefallse(this.chatInvitationStore.invitations.autoInvitation);
			this.invitationRulesStore.dptoptforwebiste(); 
			let chatinvitaion=true; 
			  this.invitationRulesStore.cancelSaveResponse(chatinvitaion);
			  this.invitationRulesStore.getInvationRules(); 
			  setTimeout(() => { 
	        let elHeight = document.getElementById('rule_section').clientHeight; 
			let windowHeight = window.outerHeight - 61 - 55; 
			this.invitationRulesStore.docHeight = "auto";
			this.invitationRulesStore.scrollToPosition(windowHeight > elHeight ? this.invitationRulesStore.docHeight - elHeight : document.getElementById('rule_section').offsetTop + 55);
     		 },0); 
			  this.setState({websiteUid:this.props.appstore.selectedWebisiteUid});
			  setTimeout(() => { 
				this.invitationRulesStore.pageisLoaded=false;
			},500);			  
		}
	  }

	getmanualinvitation(){
		let website_uid = this.props.appstore.selectedWebisiteUid;
        var selectedWebsite="";
		let websites = this.props.appstore.userInfo.allwebistes;
		for (let i in websites) {
		  if (websites[i].website_uid === website_uid) {
			selectedWebsite = websites[i];
			break;
		  }
		}
		this.chatInvitationStore.invitations.re_invitation=this.props.appstore.userInfo.accountsmodel.re_invitation;
		//this.chatInvitationStore.invitations.default_message=this.props.appstore.userInfo.accountsmodel.default_message.replace(/\n/g, "\\n");
		this.chatInvitationStore.invitations.default_message=selectedWebsite.default_message.replace(/\n/g, "\\n");
		//this.chatInvitationStore.invitations.default_time_passed=this.props.appstore.userInfo.accountsmodel.default_time_passed;
		this.chatInvitationStore.invitations.default_time_passed=selectedWebsite.default_time_passed;
		this.chatInvitationStore.invitations.autoInvitation= this.chatInvitationStore.invitations.default_time_passed>0 ? true :false;
		this.chatInvitationStore.backupInvitations = Object.assign({}, this.chatInvitationStore.invitations);
	}


	updateProperty(key, value) {
	    	this.chatInvitationStore.formData[key] = value;	    
	  }

	handleChange(event) {
		this.updateProperty(event.target.name, event.target.value);
	}
	
	onSortEnd (data) {
		this.invitationRulesStore.rules = arrayMove(this.invitationRulesStore.rules, data.oldIndex, data.newIndex)
		this.invitationRulesStore.savesortablerules(this.invitationRulesStore.rules);
	}

	componentWillUnmount() { 
		window.scrollTo(0, 0);
    }

    validateSecondsField(event) {
		let val;

		var key = window.event ? event.keyCode : event.which;

			if (event.keyCode == 8 || event.keyCode == 46
			 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 9) {
			    val = true;
			}
			// else if ( key < 48 || key > 57 ) {
			//     val = false;
			// }
			else if (((key < 48 || (key <96 && key>57) ) || key > 105) || event.shiftKey) {
			    val = false;
			}
			else val = true;
			if(!val) {
				event.preventDefault();
			}
	}


	render(){ 
		if(!this.invitationRulesStore.isPageLoaded){
			return (<div><div className="adminloader">	 
			<GridLoader 
						css={override}
						size={20}
						marging={2} 
						color={this.props.appstore.selectedColor.color}
						// loading={this.responseStore.responsereload}
						/>		 
	</div> </div>)
		}
		var self = this;
		let chatinvitaion=true; 
		const vacss = {
			pointerEvents:"none",
			opacity: 0.3
		}
		const nocss = {};	
		const { appstore } = this.props;

		return (
			<div id="innerContainer" className="innercontent_wrapper w-100" style={{'height': this.invitationRulesStore.docHeight !== 'auto' ? this.invitationRulesStore.docHeight+ "px" : this.invitationRulesStore.docHeight}}>
				<div className="header_title row align-items-center m-0">
						<img src={imageURLs.chatInvtPageIcon} alt="faq" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("Chat Invitations")}</h4>
				</div>
				{this.chatInvitationStore.invitationsloader &&
					<div className="adminloader">	 
							<GridLoader 
										css={override}
										size={20}
										marging={2} 
										color={this.props.appstore.selectedColor.color}
										// loading={this.responseStore.responsereload}
										/>		 
					</div> 
						
					}
					{  this.invitationRulesStore.pageisLoaded && 
					<div className="adminloader">	 
							<GridLoader 
										css={override}
										size={20}
										marging={2} 
										color={this.props.appstore.selectedColor.color}
										// loading={this.responseStore.responsereload}
										/>		 
					</div> 
						
					} 
				
			 
				<div className="row chatInvWrapper with-header">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Manual invitations  (for all websites)")}</h4>
							</div>
							<div className="contentWrapper">
								<Form>
									<Form.Group as={Row} controlId="formGroupManual">
									    <Form.Label column xs="7" sm="4" lg="3">{appstore.getLanguageTrans("Prevent operators from inviting when visitor refuses")}</Form.Label>
									    <Col className="custom_radioBtn">
									    	<CheckBoxComponent radioValue={this.chatInvitationStore.invitations.re_invitation}
									    							callBack = {() => this.chatInvitationStore.invitations.re_invitation = this.chatInvitationStore.invitations.re_invitation==0?1:0}/>
									    </Col>
									  </Form.Group>
									  <div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light"  onClick={() => this.chatInvitationStore.cancelManuvalInvitation()} >
											    	<p>{appstore.getLanguageTrans("Cancel")}</p>
											    </Button>
											    <Button className="btn_save" onClick={() => this.chatInvitationStore.savemanuvalInvitation()}>
											    	<p>{appstore.getLanguageTrans("Save")}</p>
											    </Button>
											</div>
										</div>
									</div>
								</Form>
							</div>
							
						</div>
					</div>
				</div>

				<div key={appstore.selectedWebisiteUid} className="row chatInvWrapper with-header" >
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0" >
						<div className="headerTitle">
							   
								<h4 className="text-left">{appstore.getLanguageTrans("Automated invitations")}</h4>
							    
							</div>
							
							<span data-tooltip={appstore.userInfo.accountplan.enable_auto_invite != 1 ?appstore.getLanguageTrans("Upgrade Your Plan"):""} data-tooltip-conf="top" style={{backgroundColor:"violet"}}>
						<div className="contentWrapper" style={appstore.userInfo.accountplan.enable_auto_invite != 1?vacss:nocss}>
							<h6 className="sub-header">{appstore.getLanguageTrans("Default")}</h6>
							<div className="section box">
								<Form>
									<Form.Group as={Row} controlId="formGroupMsg" className="custom_textArea">
									    <Form.Label column xs="12" sm="4" lg="3">{appstore.getLanguageTrans("Default message")}
									    </Form.Label>
									    <Col sm="8" lg="9">
									    	<textarea name="defaultMsg" rows="3" value={this.chatInvitationStore.invitations.default_message} onChange={(e) => this.chatInvitationStore.invitations.default_message = e.target.value}  />
									    </Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formGroupChatTime" className="align-items-center">
										<Col className="custom_radioBtn pr-0">
									    	<CheckBoxComponent radioValue={this.chatInvitationStore.invitations.autoInvitation}
									    							callBack = {() => {this.chatInvitationStore.invitations.autoInvitation = !this.chatInvitationStore.invitations.autoInvitation; this.chatInvitationStore.validatetruefallse(this.chatInvitationStore.invitations.autoInvitation)}}
																  />
									    </Col>
									    <Form.Label column className="custom_label"><span>{appstore.getLanguageTrans("If none of the rules below are triggered, initiate chat invitation after")}</span>
									    				<Form.Control className="customInput"  type="text" placeholder="" 
																		readOnly={!this.chatInvitationStore.inputState}
																		required={this.chatInvitationStore.invitations.autoInvitation}
																		maxLength="4"
																		style={this.chatInvitationStore.inputStateColor}
									    				 				onKeyDown={(e) => {this.validateSecondsField(e)}}
					    												value={this.chatInvitationStore.invitations.default_time_passed}
					    												onChange={(e) => this.chatInvitationStore.invitations.default_time_passed = e.target.value}
																		onBlur={(e) => {this.chatInvitationStore.invitations.autoInvitation ? 
																			(e.target.value==0 ? this.chatInvitationStore.invitations.default_time_passed=5:
																				this.chatInvitationStore.invitations.default_time_passed=e.target.value )
																			: this.chatInvitationStore.invitations.default_time_passed = 0}}
																		/> 
													 
					    								<span>{appstore.getLanguageTrans("seconds")}</span>
									    </Form.Label>
									    
									</Form.Group>

									<div className="row m-0">
										<div className="col-12 formBtns">
											<div className="pullRight">
												<Button className="btn_clr" variant="light" onClick={() => this.chatInvitationStore.cancelAutoInvitation()}  >
											    	<p>{appstore.getLanguageTrans("Cancel")}</p>
											    </Button>
											    <Button className="btn_save" onClick={() => this.chatInvitationStore.saveAutoInvitation()}>
											    	<p>{appstore.getLanguageTrans("Save")}</p>
											    </Button>
											</div>
										</div>
									</div>
									</Form>
							</div>
							
						</div>
						</span>
						<div key={appstore.selectedWebisiteUid} className="contentWrapper">	
						 <span data-tooltip={appstore.userInfo.accountplan.enable_auto_invite != 1?appstore.getLanguageTrans("Upgrade your plan"):""} data-tooltip-conf="top">					
							<h6 className="sub-header">{appstore.getLanguageTrans("Invitation rules")}</h6>
							
							<div className="section invitationRulesWrapper">
								<div className="row m-0">
									<p className="desc">{appstore.getLanguageTrans("First matched rule will trigger the chat invitation. Drag and drop rules to prioritize.")}</p>
								
							         <div className="panelWrapper col-12 col-lg-12 p-0" style={appstore.userInfo.accountplan.enable_auto_invite != 1?vacss:nocss} >
									 
										<div className="scrollWrapper col-12 p-0">
											
											<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
												<SortableContainer onSortEnd={this.onSortEnd} 
																axis="y" lockAxis="y" 
																lockToContainerEdges={true}
																lockOffset = "0%"
																distance = {2}
																helperClass = "ghostClass" 
																 >
													{
														this.invitationRulesStore.rules.map(function(rule,ruleIndex){
															if(rule.enabled==1)
															return (
																<SortableItem key={`item-${ruleIndex}`} index={ruleIndex} pos={ruleIndex} rule={rule} 
																			  invitationRulesStore = {self.invitationRulesStore}
																			  appstore = {appstore}/>
															)
														})
													}
												</SortableContainer>
											</CustomScroll>
										</div>
										
							            
										<Button className="custom_btn float-left addBtn" onClick={() => this.invitationRulesStore.addRuleData()}>
										
							            	
										{appstore.getLanguageTrans("ADD NEW RULE")}
										
										</Button>
										

									</div>
								</div>
								
								
										<div id="rule_section" className="rules_wrapper">
									{
									(this.invitationRulesStore.addRule || this.invitationRulesStore.editRule) &&  
										<React.Fragment>
											<h4 className="text-left">{this.invitationRulesStore.addRule ? 'Add new ' : 'Edit '}invitation rule</h4>
												<AddEditRulesComponent appstore={appstore} invitationRuleStore={this.invitationRulesStore}
																		chatInvitation = {true}
																		ruleObj = {this.invitationRulesStore.addRule ? this.invitationRulesStore.newRuleObj : this.invitationRulesStore.selectedRuleToEdit}/>
									    </React.Fragment>	
									}
					     				</div>
										
	
							</div>
							</span>
						</div>
						</div>	
					</div>
				</div>
				{
					this.invitationRulesStore.confirm && 
					<ConfirmationComponent show={this.invitationRulesStore.confirm}
											store={this.invitationRulesStore}
											title="Delete Rule"
											body_html="<p>Are you sure you want to delete the selected rule?</p>"
											cancel_btn="Cancel"
											save_btn="Confirm"
											callBack={() => this.invitationRulesStore.confirmDeleteRule(chatinvitaion)}
											closePopup={() => this.invitationRulesStore.confirm = false}
											appStore = {appstore} />
				}

				{
					this.invitationRulesStore.confirmDelCond && 
					<ConfirmationComponent show={this.invitationRulesStore.confirmDelCond}
											store={this.invitationRulesStore}
											title="Delete Condition"
											body_html="<p>Are you sure you want to delete the selected condition?</p>"
											cancel_btn="Cancel"
											save_btn="Confirm"
											callBack={() => this.invitationRulesStore.confirmDeleteCondition()}
											closePopup={() => this.invitationRulesStore.confirmDelCond = false}
											appStore = {appstore} />
				} 

				{ this.chatInvitationStore.errormodel &&
					<WarningComponent show={this.chatInvitationStore.errormodel}
											store={this.chatInvitationStore}  
											cancel_btn="OK"  
											closePopup={() => this.chatInvitationStore.errormodel = false}
											appStore = {appstore} />
				}
				{ this.invitationRulesStore.errormodel &&
					<WarningComponent show={this.invitationRulesStore.errormodel}
											store={this.invitationRulesStore}  
											cancel_btn="OK"  
											closePopup={() => this.invitationRulesStore.errormodel = false}
											appStore = {appstore} />
				}
			</div>
		)
	}
})
export default ChatInvitationComponent;
