import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

import ResponseTabChat from './ReponsesChat';

const ResponsesContentComponent = observer(class ResponsesContentComponent extends Component {

	constructor (props) {
		super(props);
		this.state = {
			search: "",
		}
    }
	componentDidMount(){
	}

	componentWillUnmount() {
	}
	
	updateSearch(event) {
		this.setState({ search: event.target.value.substr(0, 20) });

	}
	
	filterResponses = () => {
		//Object.keys(this.responseStore.responses).length !== 0 && this.responseStore.responses.constructor === Object
		return this.props.chatComponentStore.responsesTabContent.filter(
			(response) => {
				return response.question.toLowerCase().indexOf(this.state.search.toLowerCase()) != -1 ||
					response.value.toLowerCase().indexOf(this.state.search.toLowerCase()) != -1;

			})
	}

	render(){
		const { appstore,
				chatComponentStore } = this.props;
		var self = this;
		let searchFilter = this.filterResponses();

		return (
			<div className="tabContent responseWrapper">
				{
				  	!chatComponentStore.hideInfoSection && 
				  	<React.Fragment>
						<h6>{appstore.getLanguageTrans("Responses")}</h6>
						<form className="col-12 searchForm">
							<div className="row">
								<div className="col-12">
									<Form.Group as={Row} controlId="formGroupSearch">
									    <Col sm="12" className="searchText">
										   <Form.Control type="text"
										   className="foot_Responsesearch"
														placeholder={appstore.getLanguageTrans("Enter Search Term")}
														value={self.state.search}
														onChange={(e) => this.updateSearch(e)}
													/>
									    </Col>
									  </Form.Group>
									  <p>{appstore.getLanguageTrans("Type #title in the chat field to get faster access to the message")}</p>
								</div>
							</div>
						</form>
						<div id="responseMsgs" className="panelWrapper col-12 col-lg-12 p-0">
							<div className="scrollWrapper col-12 p-0">
								<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
									
									{
										searchFilter.map(function(response,responseIndex){
											return (
												<ResponseTabChat key={`item-${responseIndex}`} store = {chatComponentStore}
																 response = {response} pos = {responseIndex} appstore = {appstore}
																						 />
											)
										})
									}
								</CustomScroll>
							</div>
						</div>
					</React.Fragment>
				}
			</div>
		)
	}
})
export default ResponsesContentComponent;
