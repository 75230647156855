import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink} from "react-csv";
 

const exportCSVComponent = observer(class exportCSVComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

    }
    invexport(data) {
      let exportdata = []
      let prev_count=0
      let prev_url=0
      let prev_Date=0
      if(data == null || !data){
      
        return exportdata;
      }
      if (data != null) {
      for (let j = 0; j < data.length; j++) {
        if(prev_url === data[j].str && prev_Date === data[j].quarter_date ){
          //alert("if")
          prev_count = Number(prev_count) + Number(data[j].cnt);
          exportdata[exportdata.length-1].chartsCount = prev_count;
        }
        else{
          //alert("aa")
          data[j].statDate = data[j].quarter_date
          data[j].chartsCount = data[j].cnt;
          data[j].page_url = data[j].str;
          prev_count= data[j].cnt;
          prev_Date=data[j].quarter_date;
          prev_url=data[j].str;
          exportdata.push(data[j])
        }
        }
       
      }
      return exportdata;
    }

    render() {
    let  headers = [
        { label: "Date", key: "statDate"},
        { label: "Page URL", key: "page_url"},
        { label: "Count", key: "chartsCount"},
      ];
      const { appStore } = this.props;

        let exportdata = this.invexport(this.props.data.startPagesRecords);
      return(
      <CSVLink  data={exportdata} headers={headers} target="_blank" filename={"Start_pages_"+this.props.dateselected+".csv"} >
       {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
      );
      }


});

export default exportCSVComponent;
