import React, {Component} from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button} from 'react-bootstrap';
import  GoogleMap from './GoogleMap'

const GeoMapModel = observer(class GeoMapModel extends Component {

	constructor (props) {
        super(props);
    }
    
	componentWillMount(){

	}

	render(){
	
		const {show, 
			   store,
                title,
                coordinates,
				callBack,
				appStore
			     } = this.props;

		return (
			   <Modal backdrop='static' className={'geoMapPopup '+ (appStore ? appStore.selectedColor.value : '')} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => store.showgeoMap = false}>
				
					  <Modal.Header closeButton className="">
						    <Modal.Title class="modalBodyTitle" style={{textTransform:"none"}}>{store.infoTabData.ipAddress}</Modal.Title>
						
					  </Modal.Header>

				  <Modal.Body className="helpScrollWrapper">
							     <div className="row">
                                    <div className="col-12"> 
				                         <GoogleMap appstore={appStore} coordinates={store.infoTabData.coordinates}/>
						           </div>

	                                       
						       </div> 
            
				  </Modal.Body>

				  	
				</Modal>
		)
	}
})
export default GeoMapModel;
