import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';



const ToolBarTopComponent = observer(class ToolBarTopComponent extends Component {

	constructor (props) {
        super(props);

    }

	componentDidMount(){
	}


	componentWillUnmount() {
    }

	render(){

		var self = this;

		const { appstore,
				widgetStore } = this.props;

		return (
				<div className="toolBarMenuWrapper col-12">
					<div className="toolBarBoxWrapper"></div>
					<div className="toolBarBox"></div>
					<div className="row">
						<div className={"menuList col-3 p-0" + (widgetStore.selectedToolBarSetting >= 1 ? ' selected' : '')} onClick={() => widgetStore.selectedToolBarSetting = 1}>
							<p className="text-uppercase">{appstore.getLanguageTrans("General")}</p>
							<div className="lineWrapper">
								<div className={"line1"  + (widgetStore.selectedToolBarSetting >= 2 ? ' selectedNext' : '')}></div>
    							<div className={"line2" + (widgetStore.selectedToolBarSetting >= 2 ? ' selectedNext' : '')}></div>
    							<div className={"line2 mask" + (widgetStore.selectedToolBarSetting >= 2 ? ' selectedNext' : '')}></div>
							</div>
							
						</div>
						<div className={"menuList col-3 p-0" + (widgetStore.selectedToolBarSetting >= 2 ? ' selected' : '')} onClick={() => widgetStore.selectedToolBarSetting = 2}>
							<p className="text-uppercase">{appstore.getLanguageTrans("Style")}</p>
							<div className="lineWrapper">
								<div className={"line1"  + (widgetStore.selectedToolBarSetting >= 3 ? ' selectedNext' : '')}></div>
    							<div className={"line2" + (widgetStore.selectedToolBarSetting >= 3 ? ' selectedNext' : '')}></div>
    							<div className={"line2 mask" + (widgetStore.selectedToolBarSetting >= 3 ? ' selectedNext' : '')}></div>
							</div>
						</div>
						<div className={"menuList col-3 p-0" + (widgetStore.selectedToolBarSetting >= 3 ? ' selected' : '')} onClick={() => widgetStore.selectedToolBarSetting = 3}>
							<p className="text-uppercase">{appstore.getLanguageTrans("Buttons")}</p>
							<div className="lineWrapper">
								<div className={"line1"  + (widgetStore.selectedToolBarSetting >= 4 ? ' selectedNext' : '')}></div>
    							<div className={"line2" + (widgetStore.selectedToolBarSetting >= 4 ? ' selectedNext' : '')}></div>
    							<div className={"line2 mask" + (widgetStore.selectedToolBarSetting >= 4 ? ' selectedNext' : '')}></div>
							</div>
						</div>
						<div className={"menuList col-3 p-0" + (widgetStore.selectedToolBarSetting >= 4 ? ' selected' : '')} onClick={() => widgetStore.selectedToolBarSetting = 4}>
							<p className="text-uppercase">{appstore.getLanguageTrans("Invitation")}</p>
							<div className="lineWrapper">
								<div className="line1"></div>
    							<div className="line2"></div>
    							<div className="line2 mask"></div>
							</div>
						</div>
					</div>
					<div className="toolBarBoxWrapper bottom"></div>
				</div>
		)
	}
})
export default ToolBarTopComponent;
