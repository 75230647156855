import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

const ChatEnterMsgComponent = observer(class ChatEnterMsgComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }


	showSystemMessage = (visitor) =>{
		const store = this.props.store;
		if(visitor.role==="visitor"){
           return store.userdata.name ? store.userdata.name + visitor.message : "Visitor" + visitor.message
		}else
		   return visitor.message;
	}

	render(){
		const { appstore,
				store,
				chatData,
				time} = this.props;
		var self = this;

		return (
			<div className="chatEnterMsg col-12">
				<p>{this.showSystemMessage(chatData)}<span>{time} {chatData.vistoryEntryMsg}</span></p>
			</div>
			
		)
	}
})
export default ChatEnterMsgComponent;
