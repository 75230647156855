import React, {Component } from 'react';
import { observer } from 'mobx-react';
import ToggleButton from 'react-bootstrap/ToggleButton'

import imageURLs from '../../sharedFiles/ImagesURLs';

const CheckBoxCustomComponent = observer(class CheckBoxCustomComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}

  	buttonClicked(){
  		if(this.props.store && this.props.store.checkParentClick){
  			this.props.store.preventParentClick();
  		}
  	}

	render(){
		const { store,
			   radioValue } = this.props;

		return (
			<ToggleButton
		        type="checkbox"
		        className="checkbox_custom cursor-pointer"
		        value={radioValue}
		        checked={radioValue}
		        onChange={() => this.props.callBack()}
		        onClick={() => this.buttonClicked()}>
		        {radioValue && 
		        	<img src={imageURLs.check} alt="check" className="img-fluid"/>

		        }
		      </ToggleButton>
		)
	}
})
export default CheckBoxCustomComponent;
