import React, { Component } from "react";
import { observer } from 'mobx-react';
import Chart from "react-apexcharts";

const TimeColumChart = observer(class TimeColumChart extends Component {
  constructor(props) {
    super(props);
// alert(this.props.store.performanceData.manualinvitationcount);  
// alert(this.props.store.performanceData.autoinvitationcount);    
   this.state = {
          
            series: [{
              name: 'Manual',
              data: this.props.store.performanceData.manualinvitationcount
            }, {
              name: 'Auto',
              data: this.props.store.performanceData.autoinvitationcount
            }],

            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,

                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    // reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                  },
                },
                zoom: {
                  enabled: true
                }
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                  }
                }
              }],
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },

              dataLabels: {
                enabled: false
              },
              
              tooltip: {
                theme:"dark",
                position:"topleft",
                x : {show: false},
              },
              xaxis: {
                type: 'categorys',
                categories: this.props.store.performanceData.manualinvitationRecords,
                  labels: {
                    datetimeFormatter: {
                      year: 'yyyy',
                      month: 'MMM \'yy',
                      day: 'dd MMM',
                      hour: 'HH:mm'
                    }
                  }
                
              },

              yaxis: {
                type:'time',
                show:true,
                min:0,
                forceNiceScale: true,
                labels: {
                  formatter: function (value) {
                    return value ;
                  }
                },
                
               },
              fill: {
                opacity: 1
              }
            },

           
          
          
          };
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              width="900"
              height="300px"
            />
          </div>
        </div>
      </div>
    );
  }
})

export default TimeColumChart;