import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink} from "react-csv";
 

const chatCountCSV = observer(class chatCountCSV extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

    }

    invexport(data) {
        let exportdata = []
        let chat_Date=0
        let prev_count=0
        if(data == null || !data){
          return exportdata;
        }
        if (data != null) {
        for (let j = 0; j < data.length; j++) {
          if (chat_Date=== data[j].quarter_date) {
            prev_count= Number(prev_count) + Number(data[j].cnt);
           exportdata[exportdata.length-1].count = prev_count;
         }
          else{
            data[j].Date = data[j].quarter_date
            data[j].count = data[j].cnt ;
            prev_count=Number(data[j].cnt)
            chat_Date=data[j].quarter_date
           exportdata.push(data[j])
          }
          
          }
        
        }
        return exportdata;
        
      }
      
    render() {
      
    let  headers = [
        { label: "Date", key: "Date" },
        { label: "Chat Count", key: "count" },
       
      ];

      let exportdata = this.invexport(this.props.data.chartRecords);
      const {appStore}=this.props;
      return(
      <CSVLink  data={exportdata} headers={headers} target="_blank" filename={"Number_Of_Chats_"+this.props.dateselected+".csv"} >
               {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
      );
      }


});

export default chatCountCSV;
