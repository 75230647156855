import { decorate, observable, extendObservable } from 'mobx';
import {isEmpty} from "lodash"
import {data} from '../../../../assets/JSON/admin_languages';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
//import {langdata} from '../../../../assets/JSON/languages/locale/en/LC_MESSAGES/default'
class LanguageTextStore {
	  
    editScriptRef;

  	constructor(){
          this.pageisLoaded=false;
          this.lanDropDownOptions = [
                {value:1, label:'English'},
                {value:2, label:'French'},
                {value:3, label:'Spanish'}
                ];

           this.selectedLanguage = this.lanDropDownOptions[0];
           this.langArray = require('../../../../assets/JSON/languages/locale/en/LC_MESSAGES/default.json');
           this.englishLabels  = this.setdefaultLang(this.langArray);
           this.baseLabels = JSON.parse(JSON.stringify(this.englishLabels));
          
     }

    setdefaultLang(langArray,type) {

      if(type === "base"){
         let arr =[];
          for(let key in this.englishLabels){
            let obj={}
            if((this.englishLabels[key]["label"] != undefined && this.englishLabels[key]["label"] !="") && this.englishLabels[key]["label"] !=null) {
                  if(langArray.hasOwnProperty(this.englishLabels[key]["label"])) {
                    obj["label"]= this.englishLabels[key]["label"];
                    obj["value"]= langArray[this.englishLabels[key]["label"]];
                    obj["custom"]="";
                    arr.push(obj); 
                  }
                  else {
                    obj["label"]= this.englishLabels[key]["label"];
                    obj["value"]= "";
                    obj["custom"]="";
                    arr.push(obj); 
                  }
                }
            }  
        return arr;
      }
      else{ 
        //for english 
         let arr =[]; 
          for(let i in langArray){
            let obj={};
            if(([i][0] != undefined && [i][0] !=null) && [i][0] !=""){ 
              obj["label"]= [i][0];
              obj["value"]=[i][0]; 
              obj["custom"]="";
              arr.push(obj)
             }
            }
        return arr;
     }
    }
    
    setLanguage(selectedOption,appstore){
         this.selectedLanguage = selectedOption;
         const lang = this.selectedLanguage.value;
         let allCustomLang = appstore.userInfo.allcustomLanguage;
         const langArray = require('../../../../assets/JSON/languages/locale/'+lang+'/LC_MESSAGES/default.json');
         this.baseLabels = this.setdefaultLang(langArray,"base");
         this.setcustomLang(lang,allCustomLang);
         //clone object and use when user clicks on cancel
		     this.clonedBaseLabels = JSON.parse(JSON.stringify(this.baseLabels));
     }

     setcustomLang(selectedLang,allCustomLang){
       //set custom lang for selected langauge
      let self = this;
        allCustomLang.map(function(language,languageIndex) {
        if(language.lang === selectedLang) {
           const data =  JSON.parse(language.data);
           for(let label in data){
             self.baseLabels.map(function(base,index) {
              if(base.label === label){
                base.custom = data[label][1];
              }
             })
            }
          }
        });
      }


  saveCustomToBase(appstore) {
    this.pageisLoaded = false;
    let customData = {}
    let custeditedData = []
    let custactualData = []
    let mainlData = []
    let self=this;
    let olddata = this.clonedBaseLabels; 
    // console.log(olddata);
    // console.log(this.baseLabels); 
    this.baseLabels.map(function (language, languageIndex) {
      let mainbasedata={}
      if(self.selectedLanguage.value == "en"){
       mainbasedata={
        text :olddata[languageIndex].label == undefined || olddata[languageIndex].label == null ? "" :olddata[languageIndex].label,
        editedtext : olddata[languageIndex].custom
      }  
    }else{
      mainbasedata={
        text : olddata[languageIndex].value[1] == undefined || olddata[languageIndex].value[1] == null ? "" :olddata[languageIndex].value[1],
        editedtext : olddata[languageIndex].custom
      } 
    }
    mainlData.push(mainbasedata);
      if (language.custom && language.custom !== "") { 
        customData[language.label] = {
          "1": language.custom
        }
        if(self.selectedLanguage.value == "en"){ 
        if (olddata[languageIndex]) {
          let pre_value = {
            text : olddata[languageIndex].label == undefined || olddata[languageIndex].label == null ? "" :olddata[languageIndex].label,
            editedtext : olddata[languageIndex].custom,
            id:languageIndex
          }

          // pre_value[aa[languageIndex].label] = aa[languageIndex].custom; 
            custactualData.push(pre_value);
        }
        let labelname = language.label == undefined || language.label == null ? "" :language.label ; 
        let val = language.custom;
        let custlang = {
            text : labelname,
            editedtext : val,
            id:languageIndex
        }
        custeditedData.push(custlang);
      }else{ 
        if (olddata[languageIndex]) {
          let pre_value = {
            text : olddata[languageIndex].value[1] == undefined || olddata[languageIndex].value[1] == null ? "" :olddata[languageIndex].value[1], 
            editedtext : olddata[languageIndex].custom,
            id:languageIndex
          }

          // pre_value[aa[languageIndex].label] = aa[languageIndex].custom; 
            custactualData.push(pre_value);
        }
        let labelname = language.value[1] == undefined || language.value[1] == null ? "" :language.value[1]; 
        let val = language.custom;
        let custlang = {
            text : labelname,
            editedtext : val,
            id:languageIndex
        }
        custeditedData.push(custlang);
      }
        // custlang[labelname] = val;
        

      }
    }); 
    // console.log(custeditedData); 
    // console.log(custactualData);
    // console.log(mainlData);
    let data = {
      lang: this.selectedLanguage.value,
      data: isEmpty(customData) ? "{}" : JSON.stringify(customData),
      actualdata: custactualData,
      editeddata: custeditedData,
      maindata: mainlData
    }

    axios({
      method: 'POST',
      url: appstore.url + 'savecustomlang.json',
      headers: { 'content-type': 'application/json','charset':'utf-8' },
      data: data
    }).then(result => {
      if(result.data.length==1){
        if(result.data[0].logoutstatus==1){
          appstore.logout();
        }
        }
      if (result.data.length != 0 && result.data[0].queryStatus != -1) {
        appstore.userInfo.allcustomLanguage = result.data;
        const lang = this.selectedLanguage.value;
        const langArray = require('../../../../assets/JSON/languages/locale/' + lang + '/LC_MESSAGES/default.json');
        this.baseLabels = this.setdefaultLang(langArray, "base");
        this.setcustomLang(lang, appstore.userInfo.allcustomLanguage);
        //clone object and use when user clicks on cancel
        this.clonedBaseLabels = JSON.parse(JSON.stringify(this.baseLabels));

      } else if (result.data.length != 0 && result.data[0].queryStatus == -1) {
        this.errormodel = true;

         }else if(result.data.length ==0 ){
              appstore.userInfo.allcustomLanguage = result.data;
              const lang = this.selectedLanguage.value;
              const langArray = require('../../../../assets/JSON/languages/locale/'+lang+'/LC_MESSAGES/default.json');
              this.baseLabels = this.setdefaultLang(langArray,"base");
              this.setcustomLang(lang,appstore.userInfo.allcustomLanguage);
              //clone object and use when user clicks on cancel
              this.clonedBaseLabels = JSON.parse(JSON.stringify(this.baseLabels));
         }else{
              this.errormodel = true;
         }

         this.pageisLoaded = true;
         appstore.global_errorcount=0;
       }).catch((error) => {
        //  if (error.response) {
        //    console.log(error);
        //   appstore.logout();
        //  }
        this.pageisLoaded = true;
        console.log(error);
        console.log("error count :"+appstore.global_errorcount);
        appstore.global_errorcount=appstore.global_errorcount+1;
        if(appstore.global_errorcount>20){
        // appstore.logout();
        }
       })
     }

     clearCustom(){
      this.baseLabels = JSON.parse(JSON.stringify(this.clonedBaseLabels));
     }

 }

 decorate(LanguageTextStore, {
     selectedLanguage: observable,
      baseLabels:observable,
      pageisLoaded:observable,
      clonedBaseLabels:observable,
      errormodel:observable
    })
 export default LanguageTextStore;