import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import { GridLoader } from 'react-spinners';
import { css } from '@emotion/core';
import imageURLs from '../../../../../../sharedFiles/ImagesURLs';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`
const DataModeComponent = observer(class DataModeComponent extends Component {

	constructor (props) {
        super(props);
 
    }
	
	componentDidMount(){


	}

	componentWillReceiveProps(nextProps){

		
	}

	allowOpdelete =(operator,appstore) => {

		const loggeduser = appstore.userInfo.usersmodel; 
		if(operator.is_owner){
			// console.log(operator.totalWebsite);
			if(loggeduser.is_owner)
			return operator.totalWebsite > 1 ? true : false;
			
			else
			return false;
		}
		else if(!operator.is_owner){
		
			if(operator.user_uid != loggeduser.user_uid ){

				return true;
			}
			else if(operator.user_uid === loggeduser.user_uid){
				return operator.totalWebsite > 1 ? true : false;
			}
	  }
	}

	render(){

		const { operator,
				deptStore,
				appstore } = this.props;
		
				if (deptStore.op_dptsave) {
					return (<div><div className="adminloader">
						<GridLoader
							css={override}
							size={20}
							marging={2}
							color={this.props.appstore.selectedColor.color}
						/>
					</div> </div>)
				}
		return (
			<div className="settingFormWrapper box">
				{/* !operator.profileImage */}
				<div className={"profileImgWrapper" + ((operator.image =='' || operator.image == undefined || operator.image =="undefined") ? " noImage" : "")}>
					{
						(operator.image !='' && operator.image != undefined && operator.image !="undefined") && 
							<img src={this.props.appstore.resourceurl + this.props.appstore.userInfo.accountsmodel.account_uid + "/" + operator.image} style={{height:"148px",width:"160px" , marginTop:"9px"}} alt="" className="img-fluid " />
							// class name data_image
					}
					{
						(operator.image =='' || operator.image == undefined || operator.image =="undefined") &&
							<div className="defaultImage"
							 style={"#3ba8c5" == appstore.selectedColor.color ? {border:"2px solid #3ba8c52e",backgroundColor: "#3ba8c51a"} : "#4cb6ac" == appstore.selectedColor.color ? {border:"2px solid #4cb6ac2e",backgroundColor: "#4cb6ac1a"} : "#ec407a" == appstore.selectedColor.color ? {border:"2px solid #ec407a2e",backgroundColor: "#ec407a1a"} : "#6e4c42" == appstore.selectedColor.color ? {border:"2px solid #6e4c422e",backgroundColor: "#6e4c421a"} : "#234e91" == appstore.selectedColor.color ? {border:"2px solid #234e912e",backgroundColor: "#234e911a"} : "#e89712" == appstore.selectedColor.color ? {border:"2px solid #e897122e",backgroundColor: "#e897121a"} : "#b968c7" ==appstore.selectedColor.color ? {border:"2px solid #b968c72e",backgroundColor: "#b968c71a"} : 
							 "#039be6" == appstore.selectedColor.color ? {border:"2px solid #039be62e",backgroundColor: "#039be61a"} : "#00887a" == appstore.selectedColor.color ? {border:"2px solid #00887a2e",backgroundColor: "#00887a1a"} : "#4b8f4e" == appstore.selectedColor.color ? {border:"2px solid #4b8f4e2e",backgroundColor: "#4b8f4e1a"} : "#5f7c8c" == appstore.selectedColor.color ? {border:"2px solid #5f7c8c2e",backgroundColor: "#5f7c8c1a"} : "#64a9e0"== appstore.selectedColor.color ? {border:"2px solid #64a9e02e",backgroundColor: "#64a9e01a"} : {border:"2px solid #3ba8c52e",backgroundColor: "#3ba8c51a"}}>
								<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.avatar_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.avatar_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.avatar_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.avatar_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.avatar_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.avatar_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.avatar_voilet : 
                                                    "#039be6" == appstore.selectedColor.color ? imageURLs.avatar_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.avatar_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.avatar_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.avatar_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.avatar_skyblue : imageURLs.avatar_steelblue} alt="" className="img-fluid" />
								<p>{appstore.getLanguageTrans("No Photo")}</p>
							</div>

					}
				</div>
				<div className="operatorNick">
					<p style={{ wordWrap: "break-word"}}>{operator.nick_name} <span className={ "status "+ (operator.user_live_status==="online" ? "online" : "offline")} ></span></p>
					{operator.is_owner &&
						<p style={{fontSize:13}}>{'('+appstore.getLanguageTrans("Owner")+')'}</p>
					}
				</div>
				<div className="operatorDetails">
					<p style={{ wordWrap: "break-word"}}>{operator.real_name}</p>
					<p>{operator.user_type_id==3 ? appstore.getLanguageTrans("Administrator") : appstore.getLanguageTrans("Operator")}</p>
					<p className="email">{operator.email}</p>
					<p style={appstore.userInfo.accountplan.sms==0 ? {display:"none"}:{}}>{appstore.getLanguageTrans("SMS")} <span>{operator.sms_enabled==1 ? appstore.getLanguageTrans(" On") : appstore.getLanguageTrans(" Off")}</span></p>
					<p>{appstore.getLanguageTrans("Max concurrent chats")} <span>{(!operator.max_chats || operator.max_chats === '' ) ? appstore.getLanguageTrans("Unlimited") : operator.max_chats}</span></p>
				</div>
			{ (!operator.is_owner || appstore.isOwner) &&
				<div className="row m-0 btnRow">
					<div className="col-12 formBtns">
						<div className="col-12 p-0">
						 		
						   {  this.allowOpdelete(operator,appstore) &&
							<Button className="btn_clr" variant="light" onClick = {() => deptStore.deleteComp(operator,"Operator")}>
						    	<p>{appstore.getLanguageTrans("Delete")}</p>
						    </Button>
	                        }
							
						    <Button className="btn_save" onClick = {() => deptStore.editOperator(operator)}>
						    	<p>{appstore.getLanguageTrans("Edit")}</p>
						    </Button>
					 	</div>
					  </div>
				   </div>
				}
			   </div>
		)
	}
})
export default DataModeComponent;
