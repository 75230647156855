import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';


const IconFontLibraryModal = observer(class IconFontLibraryModal extends Component {

	constructor (props) {
        super(props);
        this.state = {
        	selectedIcon: {}
        }

    }

	componentDidMount(){
	}

	setIconIndex(IconObj){
		this.setState({
			selectedIcon: IconObj
		})
	}


	componentWillUnmount() {
    }

	render(){
		const parentScroll = {
			display: "flex",
			height: "55vh"
		}
		var self = this;

		const { appStore,
				store,
				show } = this.props; 

		return (
				<Modal backdrop='static' className={appStore ? appStore.selectedColor.value : ''} show = {show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => store.getIconFromLibrary = false}>
				  <Modal.Header closeButton>
				    <Modal.Title>{appStore.getLanguageTrans("Iconic Font Library")}</Modal.Title>
				  </Modal.Header>
				<div style={parentScroll}>
				<CustomScroll flex="1" heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
				  <Modal.Body>
				  <div style={parentScroll}>
				  	<div id="ext-comp-3573" className="HelpOnClick-FontIconsLibrary">
				  		{
				  			store.iconLibraryFiles.map(function(iconObj, iconIndex) {
				  				return (
				  						<div key={iconIndex} className={"cursor-pointer HelpOnClick-fa HelpOnClick-fa-"+iconObj.icon + (self.state.selectedIcon.value === iconIndex+1 ? ' x-list-selected' : '')} onClick={() => self.setIconIndex(iconObj)}></div>
				  					)
				  			})
				  		}
				  		<div className="x-clear"></div>
				  	</div>
				    </div>
				  </Modal.Body>
				  </CustomScroll>
				  </div>

				  <Modal.Footer>
				    <Button className="btn_cancel" variant="secondary" onClick={() => store.getIconFromLibrary = false}>{appStore.getLanguageTrans("Close")}</Button>
				    <Button className="btn_cancel" variant="primary" onClick={() => this.props.callBack(this.state.selectedIcon)}>{appStore.getLanguageTrans("Select")}</Button>
				  </Modal.Footer>
				</Modal>
		)
	}
})
export default IconFontLibraryModal;
