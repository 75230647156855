var imageURLs = {
  logoIcon: require('../assets/images/logo.png'),
  cartIcon: require('../assets/images/cart.png'),
  cartMobileIcon: require('../assets/images/mobileCart.png'),
  bellIcon: require('../assets/images/Bell.png'),
  downArrowIcon: require('../assets/images/downArrow.png'),
  faqIcon: require('../assets/images/faq.png'),
  avatarIcon: require('../assets/images/avatar.png'),
  chatIcon: require('../assets/images/chats.png'),
  payDollar:require('../assets/images/payDollar.png'),
  chatArchiveIcon:require('../assets/images/chatArchiveIcon.png'),
  trafficIcon: require('../assets/images/traffic.png'),
  adminIcon: require('../assets/images/admin.png'),
  accountsIcon: require('../assets/images/accounts.png'),
  profileIcon: require('../assets/images/profile.png'),
  hideInactiveIcon: require('../assets/images/hide_inactive.png'),
  hideActiveIcon: require('../assets/images/hide_active.png'),
  logo: require('../assets/images/logoIcon.png'),
  openMenuIcon: require('../assets/images/openMenu.png'),
  closeMenuIcon: require('../assets/images/closeMenu.png'),
  bulletIcon: require('../assets/images/menuBullet.png'),
  settingsIcon: require('../assets/images/settings.png'),
  profileImageIcon: require('../assets/images/profile_image.png'),
  scriptIcon: require('../assets/images/script.png'),
  searchIcon: require('../assets/images/search.png'),
  overviewIcon: require('../assets/images/overview.png'),
  collapsedIcon: require('../assets/images/collapsed.png'),
  expandIcon: require('../assets/images/expand.png'),
  editIcon: require('../assets/images/edit.png'),
  deleteIcon: require('../assets/images/delete.png'),
  greenTick: require('../assets/images/green_tick.png'),
  invitationIcon: require('../assets/images/invitation.png'),
  crossIcon: require('../assets/images/crossLi.png'),
  profilePageIcon: require('../assets/images/profileIcon.png'),
  playIcon: require('../assets/images/playBtn.png'),
  heartIcon: require('../assets/images/heart.png'),
  heartIcon1: require('../assets/images/heart1.png'),
  heartIcon2: require('../assets/images/heart2.png'),
  heartIcon3: require('../assets/images/heart3.png'),
  heartIcon4: require('../assets/images/heart4.png'),
  chat_request_default_sound:require('../assets/sounds/chat_request/default_sound.mp3'),
  chat_request1: require('../assets/sounds/chat_request/sound1.mp3'),
  chat_request2: require('../assets/sounds/chat_request/sound2.mp3'),
  chat_request3: require('../assets/sounds/chat_request/sound3.mp3'),
  chat_request4: require('../assets/sounds/chat_request/sound4.mp3'),
  chat_request5: require('../assets/sounds/chat_request/sound5.mp3'),
  chat_invitation1: require('../assets/sounds/chat_invitation/sound1.mp3'),
  chat_invitation2: require('../assets/sounds/chat_invitation/sound2.mp3'),
  chat_invitation3: require('../assets/sounds/chat_invitation/sound3.mp3'),
  chat_invitation4: require('../assets/sounds/chat_invitation/sound4.mp3'),
  chat_invitation5: require('../assets/sounds/chat_invitation/sound5.mp3'),
  msg_sent_sound1: require('../assets/sounds/msg_sent/sound1.mp3'),
  msg_sent_sound2: require('../assets/sounds/msg_sent/sound2.mp3'),
  msg_sent_sound3: require('../assets/sounds/msg_sent/sound3.mp3'),
  msg_sent_sound4: require('../assets/sounds/msg_sent/sound4.mp3'),
  msg_sent_sound5: require('../assets/sounds/msg_sent/sound5.mp3'),
  msg_received_default_sound: require('../assets/sounds/msg_received/default_sound.mp3'),
  msg_received_sound1: require('../assets/sounds/msg_received/sound1.mp3'),
  msg_received_sound2: require('../assets/sounds/msg_received/sound2.mp3'),
  msg_received_sound3: require('../assets/sounds/msg_received/sound3.mp3'),
  msg_received_sound4: require('../assets/sounds/msg_received/sound4.mp3'),
  msg_received_sound5: require('../assets/sounds/msg_received/sound5.mp3'),
  new_visitor_default_sound: require('../assets/sounds/new_visitor/default_sound.mp3'),
  new_visitor_sound1: require('../assets/sounds/new_visitor/sound1.mp3'),
  new_visitor_sound2: require('../assets/sounds/new_visitor/sound2.mp3'),
  new_visitor_sound3: require('../assets/sounds/new_visitor/sound3.mp3'),
  new_visitor_sound4: require('../assets/sounds/new_visitor/sound4.mp3'),
  new_visitor_sound5: require('../assets/sounds/new_visitor/sound5.mp3'),
  securityPageIcon: require('../assets/images/security.png'),
  langPageIcon: require('../assets/images/langPage.png'),
  filePageIcon: require('../assets/images/fileTransferPage.png'),
  refreshIcon: require('../assets/images/refresh.png'),
  downloadIcon: require('../assets/images/download.png'),
  uploadIcon: require('../assets/images/upload.png'),
  downloadFileIcon: require('../assets/images/downloadFile.png'),
  chatInvtPageIcon: require('../assets/images/chatInvPage.png'),
  soundIcon: require('../assets/images/sound.png'),
  check: require('../assets/images/check.png'),
  optDeptPageIcon: require('../assets/images/operatorDeptPage.png'),
  optImage: require('../assets/images/operatorImage.jpeg'),
  addImage: require('../assets/images/addOpt.png'),
  saveIcon: require('../assets/images/save_btn.png'),
  cancelIcon: require('../assets/images/cancel_btn.png'),
  operatorsBullet: require('../assets/images/operatorBullet.png'),
  trafficPageIcon: require('../assets/images/trafficPageIcon.png'),
  eyeIcon: require('../assets/images/eyeIcon.png'),
  linkIcon: require('../assets/images/link.png'),
  link: require('../assets/images/linkIcon.png'),
  chatResponseIcon: require('../assets/images/chatsResponsePageIcon.png'),
  defaultImage: require('../assets/images/default_image.png'),
  websitePageIcon: require('../assets/images/websitePageIcon.png'),
  widgetPageIcon: require('../assets/images/widgetPageIcon.png'),
  sectionIcon: require('../assets/images/sectionArrow.png'),
  addLogo: require('../assets/images/addLogo.png'),
  widget1Logo: require('../assets/images/widgetLogo.png'),
  widget2Logo: require('../assets/images/widget2Logo.png'),
  infoIcon: require('../assets/images/infoIcon.png'),
  uploadIconImage: require('../assets/images/uploadIcon.png'),
  widgetItem: require('../assets/images/widgetItem.png'),
  iconItem: require('../assets/images/IconItem.png'),
  toolBarItem: require('../assets/images/toolBarItem.png'),
  widgetIconItem: require('../assets/images/widgetIconItem.png'),
  directLinkItem: require('../assets/images/directLinkItem.png'),
  buttonItem: require('../assets/images/buttonItem.png'),
  selectIcon: require('../assets/images/selectIcon.png'),
  iconTopArrow: require('../assets/images/iconTopArrow.png'),
  iconBottomArrow: require('../assets/images/iconBottomArrow.png'),
  positionLeft: require('../assets/images/positionLeft.png'),
  positionRight: require('../assets/images/positionRight.png'),
  positionCentered: require('../assets/images/positionCentered.png'),
  positionBottomLeft: require('../assets/images/positionBottomLeft.png'),
  positionBottomRight: require('../assets/images/positionBottomRight.png'),
  carouselNext: require('../assets/images/carouselNext.png'),
  carouselPrev: require('../assets/images/carouselPrev.png'),
  exitBtnIcon: require('../assets/images/exitBtn.png'),
  infotabSelected: require('../assets/images/infotab_selected.png'),
  responsetab: require('../assets/images/responsetab.png'),
  footstepstab: require('../assets/images/footstepstab.png'),
  historytab: require('../assets/images/historytab.png'),
  tabContentShow: require('../assets/images/tabContentShow.png'),
  locationIcon: require('../assets/images/locationIcon.png'),
  visibilityIcon: require('../assets/images/visibilityIcon.png'),
  editWhiteIcon: require('../assets/images/edit_whiteIcon.png'),
  deleteWhiteIcon: require('../assets/images/delete_whiteIcon.png'),
  downloadWhiteIcon: require('../assets/images/download_white.png'),
  chatFileIcon: require('../assets/images/chatFile.svg'),
  spellCheckIcon: require('../assets/images/spellCheckIcon.png'),
  coBrowseIcon: require('../assets/images/coBrowseIcon.png'),
  printIcon: require('../assets/images/printIcon.png'),
  exitChatIcon: require('../assets/images/exitChatIcon.png'),
  msgSendIcon: require('../assets/images/msgSendIcon.png'),
  one_offline: require('../assets/images/icons/1_offline.png'),
  one_online: require('../assets/images/icons/1_online.png'),
  two_offline: require('../assets/images/icons/2_offline.png'),
  two_online: require('../assets/images/icons/2_online.png'),
  three_offline: require('../assets/images/icons/3_offline.png'),
  three_online: require('../assets/images/icons/3_online.png'),
  foure_offline: require('../assets/images/icons/4_offline.png'),
  four_online: require('../assets/images/icons/4_online.png'),
  five_offline: require('../assets/images/icons/5_offline.gif'),
  five_online: require('../assets/images/icons/5_online.gif'),
  six_offline: require('../assets/images/icons/6_offline.png'),
  six_online: require('../assets/images/icons/6_online.png'),
  seven_offline: require('../assets/images/icons/7_offline.png'),
  seven_online: require('../assets/images/icons/7_online.png'),
  eight_offline: require('../assets/images/icons/8_offline.gif'),
  eight_online: require('../assets/images/icons/8_online.gif'),
  nine_offline: require('../assets/images/icons/9_offline.gif'),
  nine_online: require('../assets/images/icons/9_online.gif'),
  ten_offline: require('../assets/images/icons/10_offline.gif'),
  ten_online: require('../assets/images/icons/10_online.gif'),
  elven_offline: require('../assets/images/icons/11_offline.png'),
  eleven_online: require('../assets/images/icons/11_online.png'),
  twelve_offline: require('../assets/images/icons/12_offline.png'),
  twelve_online: require('../assets/images/icons/12_online.png'),
  thirteen_offline: require('../assets/images/icons/13_offline.png'),
  thirteen_online: require('../assets/images/icons/13_online.png'),
  fourteen_offline: require('../assets/images/icons/14_offline.png'),
  fourteen_online: require('../assets/images/icons/14_online.png'),
  fifteen_offline: require('../assets/images/icons/15_offline.png'),
  fifteen_online: require('../assets/images/icons/15_online.png'),
  sixteen_offline: require('../assets/images/icons/16_offline.png'),
  sixteen_online: require('../assets/images/icons/16_online.png'),
  seventeen_offline: require('../assets/images/icons/17_offline.png'),
  seventeen_ofnline: require('../assets/images/icons/17_online.png'),
  eighteen_offline: require('../assets/images/icons/18_offline.gif'),
  eighteen_online: require('../assets/images/icons/18_online.gif'),
  nineteen_offline: require('../assets/images/icons/19_offline.gif'),
  nineteen_online: require('../assets/images/icons/19_online.gif'),
  twenty_offline: require('../assets/images/icons/20_offline.gif'),
  twenty_online: require('../assets/images/icons/20_online.gif'),
  twentyone_offline: require('../assets/images/icons/21_offline.gif'),
  twentyone_online: require('../assets/images/icons/21_online.gif'),
  twentytwo_offline: require('../assets/images/icons/22_offline.gif'),
  twentytwo_online: require('../assets/images/icons/22_online.gif'),
  twentythree_offline: require('../assets/images/icons/23_offline.png'),
  twentythree_online: require('../assets/images/icons/23_online.png'),
  twentyfour_offline: require('../assets/images/icons/24_offline.png'),
  twentyfour_online: require('../assets/images/icons/24_online.png'),
  twentyfive_offline: require('../assets/images/icons/25_offline.png'),
  twentyfive_online: require('../assets/images/icons/25_online.png'),
  twentysix_offline: require('../assets/images/icons/26_offline.png'),
  twentysix_online: require('../assets/images/icons/26_online.png'),
  twentyseven_offline: require('../assets/images/icons/27_offline.png'),
  twentyseven_online: require('../assets/images/icons/27_online.png'),
  twentyeight_offline: require('../assets/images/icons/28_offline.png'),
  twentyeight_online: require('../assets/images/icons/28_online.png'),
  he_one_offline: require('../assets/images/icons/he_1_offline.png'),
  he_one_online: require('../assets/images/icons/he_1_online.png'),
  he_two_offline: require('../assets/images/icons/he_2_offline.png'),
  he_two_online: require('../assets/images/icons/he_2_online.png'),
  he_three_offline: require('../assets/images/icons/he_3_offline.png'),
  he_three_online: require('../assets/images/icons/he_3_online.png'),
  he_four_offline: require('../assets/images/icons/he_4_offline.png'),
  he_four_online: require('../assets/images/icons/he_4_online.png'),
  he_five_offline: require('../assets/images/icons/he_5_offline.png'),
  he_five_online: require('../assets/images/icons/he_5_online.png'),
  he_six_offline: require('../assets/images/icons/he_6_offline.png'),
  he_six_online: require('../assets/images/icons/he_6_online.png'),
  he_seven_offline: require('../assets/images/icons/he_7_offline.png'),
  he_seven_online: require('../assets/images/icons/he_7_online.png'),
  he_eight_online: require('../assets/images/icons/he_8_offline.png'),
  he_eight_online: require('../assets/images/icons/he_8_online.png'),
  pl_one_offline: require('../assets/images/icons/pl_1_offline.png'),
  pl_one_online: require('../assets/images/icons/pl_1_online.png'),
  pl_two_offline: require('../assets/images/icons/pl_2_offline.png'),
  pl_two_online: require('../assets/images/icons/pl_2_online.png'),
  pl_three_offline: require('../assets/images/icons/pl_3_offline.png'),
  pl_three_online: require('../assets/images/icons/pl_3_online.png'),
  pl_four_offline: require('../assets/images/icons/pl_4_offline.png'),
  pl_four_online: require('../assets/images/icons/pl_4_online.png'),
  pl_five_offline: require('../assets/images/icons/pl_5_offline.png'),
  pl_five_online: require('../assets/images/icons/pl_5_online.png'),
  pl_six_offline: require('../assets/images/icons/pl_6_offline.png'),
  pl_six_online: require('../assets/images/icons/pl_6_online.png'),
  pl_seven_offline: require('../assets/images/icons/pl_7_offline.png'),
  pl_seven_online: require('../assets/images/icons/pl_7_online.png'),
  pl_eight_offline: require('../assets/images/icons/pl_8_offline.png'),
  pl_eight_online: require('../assets/images/icons/pl_8_online.png'),
  pl_nine_offline: require('../assets/images/icons/pl_9_offline.png'),
  pl_nine_online: require('../assets/images/icons/pl_9_online.png'),
  pl_ten_offline: require('../assets/images/icons/pl_10_offline.png'),
  pl_ten_online: require('../assets/images/icons/pl_10_online.png'),
  ru_one_offline: require('../assets/images/icons/ru_01_offline.png'),
  ru_one_online: require('../assets/images/icons/ru_01_online.png'),
  ru_two_offline: require('../assets/images/icons/ru_02_offline.png'),
  ru_two_online: require('../assets/images/icons/ru_02_online.png'),
  ru_three_offline: require('../assets/images/icons/ru_03_offline.png'),
  ru_three_online: require('../assets/images/icons/ru_03_online.png'),
  ru_four_offline: require('../assets/images/icons/ru_04_offline.png'),
  ru_four_online: require('../assets/images/icons/ru_04_online.png'),
  ru_five_offline: require('../assets/images/icons/ru_05_offline.png'),
  ru_five_online: require('../assets/images/icons/ru_05_online.png'),
  ru_six_offline: require('../assets/images/icons/ru_06_offline.png'),
  ru_six_online: require('../assets/images/icons/ru_06_online.png'),
  ru_seven_offline: require('../assets/images/icons/ru_07_offline.png'),
  ru_seven_online: require('../assets/images/icons/ru_07_online.png'),
  ru_eight_offline: require('../assets/images/icons/ru_08_offline.png'),
  ru_eight_online: require('../assets/images/icons/ru_08_online.png'),
  ru_nine_offline: require('../assets/images/icons/ru_09_offline.png'),
  ru_nine_online: require('../assets/images/icons/ru_09_online.png'),
  ru_ten_offline: require('../assets/images/icons/ru_10_offline.png'),
  ru_ten_online: require('../assets/images/icons/ru_10_online.png'),
  ru_eleven_offline: require('../assets/images/icons/ru_11_offline.png'),
  ru_eleven_online: require('../assets/images/icons/ru_11_online.png'), 
  ru_twelve_offline: require('../assets/images/icons/ru_12_online.png'),
  ru_twelve_online: require('../assets/images/icons/ru_12_online.png'),
  ru_thirteen_offline: require('../assets/images/icons/ru_13_offline.png'),
  ru_thirteen_online: require('../assets/images/icons/ru_13_online.png'),
  ru_fourteen_offline: require('../assets/images/icons/ru_14_offline.png'),
  ru_fourteen_online: require('../assets/images/icons/ru_14_online.png'),
  ru_fifteen_offline: require('../assets/images/icons/ru_15_offline.png'),
  ru_fifteen_online: require('../assets/images/icons/ru_15_online.png'),
  ru_sixteen_offline: require('../assets/images/icons/ru_16_offline.png'),
  ru_sixteen_online: require('../assets/images/icons/ru_16_online.png'),
  ru_seventeen_offline: require('../assets/images/icons/ru_17_offline.png'),
  ru_seventeen_online: require('../assets/images/icons/ru_17_online.png'),
  tr_one_offline: require('../assets/images/icons/tr_1_offline.png'),
  tr_one_online: require('../assets/images/icons/tr_1_online.png'),
  tr_two_offline: require('../assets/images/icons/tr_2_offline.png'),
  tr_two_online: require('../assets/images/icons/tr_2_online.png'),
  tr_three_offline: require('../assets/images/icons/tr_3_offline.png'),
  tr_three_online: require('../assets/images/icons/tr_3_online.png'),
  tr_four_offline: require('../assets/images/icons/tr_4_offline.png'),
  tr_four_online: require('../assets/images/icons/tr_4_online.png'),
  tr_five_offline: require('../assets/images/icons/tr_5_offline.png'),
  tr_five_online: require('../assets/images/icons/tr_5_online.png'),
  tr_six_offline: require('../assets/images/icons/tr_6_offline.png'),
  tr_six_online: require('../assets/images/icons/tr_6_online.png'),
  tr_seven_offline: require('../assets/images/icons/tr_7_offline.png'),
  tr_seven_online: require('../assets/images/icons/tr_7_online.png'),
  tr_eight_offline: require('../assets/images/icons/tr_8_offline.png'),
  tr_eight_online: require('../assets/images/icons/tr_8_online.png'),
  tr_nine_offline: require('../assets/images/icons/tr_9_offline.png'),
  tr_nine_online: require('../assets/images/icons/tr_9_online.png'),
  blue: require('../assets/images/themes/blue/preview.png'),
  green: require('../assets/images/themes/green/preview.png'),
  orange: require('../assets/images/themes/orange/preview.png'),
  pink: require('../assets/images/themes/pink/preview.png'),
  red: require('../assets/images/themes/red/preview.png'),
  teal: require('../assets/images/themes/teal/preview.png'),
  dark_blue: require('../assets/images/themes/dark_blue/preview.png'),
  dark_green: require('../assets/images/themes/dark_green/preview.png'),
  dark_orange: require('../assets/images/themes/dark_orange/preview.png'),
  dark_pink: require('../assets/images/themes/dark_pink/preview.png'),
  dark_red: require('../assets/images/themes/dark_red/preview.png'),
  dark_teal: require('../assets/images/themes/dark_teal/preview.png'),
  light_blue: require('../assets/images/themes/light_blue/preview.png'),
  light_green: require('../assets/images/themes/light_green/preview.png'),
  light_grey: require('../assets/images/themes/light_grey/preview.png'),
  light_teal: require('../assets/images/themes/light_teal/preview.png'),
  chat_img_download: require('../assets/images/chat_download_icon.png'),
  performanceIcon: require('../assets/images/performanceIcon.png'),
  report_usage: require('../assets/images/Report_Usage.png'),
  reportoverView: require('../assets/images/Report_overView.png'),
  report_Operators: require('../assets/images/operatorDeptPage.png'),
  Help_icon_blue: require('../assets/images/help_icon/Help_icon_blue.png'),
  Help_icon_blue2: require('../assets/images/help_icon/Help_icon_blue2.png'),
  Help_icon_brown: require('../assets/images/help_icon/Help_icon_brown.png'),
  Help_icon_Darkgreen: require('../assets/images/help_icon/Help_icon_Darkgreen.png'),
  Help_icon_deep_sky_blue: require('../assets/images/help_icon/Help_icon_deep_sky_blue.png'),
  Help_icon_green: require('../assets/images/help_icon/Help_icon_green.png'),
  Help_icon_Grey: require('../assets/images/help_icon/Help_icon_Grey.png'),
  Help_icon_Navyblue: require('../assets/images/help_icon/Help_icon_Navyblue.png'),
  Help_icon_pink2: require('../assets/images/help_icon/Help_icon_pink2.png'),
  Help_icon_SeaGreen: require('../assets/images/help_icon/Help_icon_SeaGreen.png'),
  Help_icon_voilet: require('../assets/images/help_icon/Help_icon_voilet.png'),
  Help_icon_Yellow: require('../assets/images/help_icon/Help_icon_Yellow.png'),
  iconTyping: require('../assets/images/icon-typing.png'),
  mute_blue: require('../assets/images/Mute_icons/mute_blue.png'),
  mute_brown: require('../assets/images/Mute_icons/mute_brown.png'),
  mute_dark_green: require('../assets/images/Mute_icons/mute_dark_green.png'),
  mute_green: require('../assets/images/Mute_icons/mute_green.png'),
  mute_grey: require('../assets/images/Mute_icons/mute_grey.png'),
  mute_navy_blue: require('../assets/images/Mute_icons/mute_navy_blue.png'),
  mute_yellow: require('../assets/images/Mute_icons/mute_yellow.png'),
  mute_voilet: require('../assets/images/Mute_icons/mute_voilet.png'),
  mute_pink: require('../assets/images/Mute_icons/mute_pink.png'),
  mute_sea_green: require('../assets/images/Mute_icons/mute_sea_green.png'),
  mute_sky_blue: require('../assets/images/Mute_icons/mute_sky_blue.png'),
  mute_steelblue: require('../assets/images/Mute_icons/mute_steelblue.png'),

  sound_blue: require('../assets/images/Mute_icons/blue.png'),
  sound_brown: require('../assets/images/Mute_icons/brown.png'),
  sound_dark_green: require('../assets/images/Mute_icons/dark_green.png'),
  sound_green: require('../assets/images/Mute_icons/green.png'),
  sound_grey: require('../assets/images/Mute_icons/grey.png'),
  sound_navy_blue: require('../assets/images/Mute_icons/navy_blue.png'),
  sound_yellow: require('../assets/images/Mute_icons/yellow.png'),
  sound_voilet: require('../assets/images/Mute_icons/voilet.png'),
  sound_pink: require('../assets/images/Mute_icons/pink.png'),
  sound_sea_green: require('../assets/images/Mute_icons/sea_green.png'),
  sound_sky_blue: require('../assets/images/Mute_icons/sky_blue.png'),
  sound_steelblue: require('../assets/images/Mute_icons/steelblue.png'),

  attachmentIcon: require('../assets/images/attachment_icon/attachmant_steelblue.png'),
  Italic_blue: require('../assets/images/Italic_icons/Italic_blue.png'),
  Italic_brown: require('../assets/images/Italic_icons/Italic_brown.png'),
  Italic_darkgreen: require('../assets/images/Italic_icons/Italic_darkgreen.png'),
  Italic_green: require('../assets/images/Italic_icons/Italic_green.png'),
  Italic_grey: require('../assets/images/Italic_icons/Italic_grey.png'),
  Italic_navyblue: require('../assets/images/Italic_icons/Italic_navyblue.png'),
  Italic_pink: require('../assets/images/Italic_icons/Italic_pink.png'),
  Italic_seagreen: require('../assets/images/Italic_icons/Italic_seagreen.png'),
  Italic_skyblue: require('../assets/images/Italic_icons/Italic_skyblue.png'),
  Italic_steelblue: require('../assets/images/Italic_icons/Italic_steelblue.png'),
  Italic_voilet: require('../assets/images/Italic_icons/Italic_voilet.png'),
  Italic_yellow: require('../assets/images/Italic_icons/Italic_yellow.png'),
  
  line_yellow: require('../assets/images/Underline_icons/line_yellow.png'),
  line_blue: require('../assets/images/Underline_icons/line_blue.png'),
  line_brown: require('../assets/images/Underline_icons/line_brown.png'),
  line_darkgreen: require('../assets/images/Underline_icons/line_darkgreen.png'),
  line_green: require('../assets/images/Underline_icons/line_green.png'),
  line_grey: require('../assets/images/Underline_icons/line_grey.png'),
  line_navyblue: require('../assets/images/Underline_icons/line_navyblue.png'),
  line_pink: require('../assets/images/Underline_icons/line_pink.png'),
  line_seagreen: require('../assets/images/Underline_icons/line_seagreen.png'),
  line_skyblue: require('../assets/images/Underline_icons/line_skyblue.png'),
  line_steelblue: require('../assets/images/Underline_icons/line_steelblue.png'),
  line_voilet: require('../assets/images/Underline_icons/line_voilet.png'),

  
  add_voilet2: require('../assets/images/Add_Icons/add_voilet2.png'),
  add_steelblue2: require('../assets/images/Add_Icons/add_steelblue2.png'),
  add_blue2: require('../assets/images/Add_Icons/add_blue2.png'),
  add_brown2: require('../assets/images/Add_Icons/add_brown2.png'),
  add_darkgreen2: require('../assets/images/Add_Icons/add_darkgreen2.png'),
  add_green2: require('../assets/images/Add_Icons/add_green2.png'),
  add_grey2: require('../assets/images/Add_Icons/add_grey2.png'),
  add_navyblue2: require('../assets/images/Add_Icons/add_navyblue2.png'),
  add_pink2: require('../assets/images/Add_Icons/add_pink2.png'),
  add_seagreen2: require('../assets/images/Add_Icons/add_seagreen2.png'),
  add_yellow2: require('../assets/images/Add_Icons/add_yellow2.png'),
  add_skyblue2: require('../assets/images/Add_Icons/add_skyblue2.png'),

  right_blue: require('../assets/images/right_icon/right_blue.png'),
  right_steelblue: require('../assets/images/right_icon/right_steelblue.png'),
  right_skyblue: require('../assets/images/right_icon/right_skyblue.png'),
  right_navyblue: require('../assets/images/right_icon/right_navyblue.png'),
  right_pink: require('../assets/images/right_icon/right_pink.png'),
  right_yellow: require('../assets/images/right_icon/right_yellow.png'),
  right_brown: require('../assets/images/right_icon/right_brown.png'),
  right_green: require('../assets/images/right_icon/right_green.png'),
  right_seagreen: require('../assets/images/right_icon/right_seagreen.png'),
  right_darkgreen: require('../assets/images/right_icon/right_darkgreen.png'),
  right_grey: require('../assets/images/right_icon/right_grey.png'),
  right_voilet: require('../assets/images/right_icon/right_voilet.png'),

  left_blue: require('../assets/images/right_icon/left_blue.png'),
  left_steelblue: require('../assets/images/right_icon/left_steelblue.png'),
  left_skyblue: require('../assets/images/right_icon/left_skyblue.png'),
  left_navyblue: require('../assets/images/right_icon/left_navyblue.png'),
  left_pink: require('../assets/images/right_icon/left_pink.png'),
  left_yellow: require('../assets/images/right_icon/left_yellow.png'),
  left_brown: require('../assets/images/right_icon/left_brown.png'),
  left_green: require('../assets/images/right_icon/left_green.png'),
  left_seagreen: require('../assets/images/right_icon/left_seagreen.png'),
  left_darkgreen: require('../assets/images/right_icon/left_darkgreen.png'),
  left_grey: require('../assets/images/right_icon/left_grey.png'),
  left_voilet: require('../assets/images/right_icon/left_voilet.png'),

  eye_blue: require('../assets/images/eye_icons/eye_blue.png'),
  eye_steelblue: require('../assets/images/eye_icons/eye_steelblue.png'),
  eye_skyblue: require('../assets/images/eye_icons/eye_skyblue.png'),
  eye_brown: require('../assets/images/eye_icons/eye_brown.png'),
  eye_navyblue: require('../assets/images/eye_icons/eye_navyblue.png'),
  eye_pink: require('../assets/images/eye_icons/eye_pink.png'),
  eye_voilet: require('../assets/images/eye_icons/eye_voilet.png'),
  eye_grey: require('../assets/images/eye_icons/eye_grey.png'),
  eye_green: require('../assets/images/eye_icons/eye_green.png'),
  eye_seagreen: require('../assets/images/eye_icons/eye_seagreen.png'),
  eye_darkgreen: require('../assets/images/eye_icons/eye_darkgreen.png'),
  eye_yellow: require('../assets/images/eye_icons/eye_yellow.png'),
  
  
  plus_blue: require('../assets/images/plus_icons/plus_blue.png'),
  plus_steelblue: require('../assets/images/plus_icons/plus_steelblue.png'),
  plus_skyblue: require('../assets/images/plus_icons/plus_skyblue.png'),
  plus_navyblue: require('../assets/images/plus_icons/plus_navyblue.png'),
  plus_green: require('../assets/images/plus_icons/plus_green.png'),
  plus_darkgreen: require('../assets/images/plus_icons/plus_darkgreen.png'),
  plus_seagreen: require('../assets/images/plus_icons/plus_seagreen.png'),
  plus_voilet: require('../assets/images/plus_icons/plus_voilet.png'),
  plus_pink: require('../assets/images/plus_icons/plus_pink.png'),
  plus_yellow: require('../assets/images/plus_icons/plus_yellow.png'),
  plus_grey: require('../assets/images/plus_icons/plus_grey.png'),
  plus_brown: require('../assets/images/plus_icons/plus_brown.png'),


  print_blue: require('../assets/images/print_Icons/print_blue.png'),
  print_skyblue: require('../assets/images/print_Icons/print_skyblue.png'),
  print_steelblue: require('../assets/images/print_Icons/print_steelblue.png'),
  print_navyblue: require('../assets/images/print_Icons/print_navyblue.png'),
  print_green: require('../assets/images/print_Icons/print_green.png'),
  print_darkgreen: require('../assets/images/print_Icons/print_darkgreen.png'),
  print_seagreen: require('../assets/images/print_Icons/print_seagreen.png'),
  print_voilet: require('../assets/images/print_Icons/print_voilet.png'),
  print_yellow: require('../assets/images/print_Icons/print_yellow.png'),
  print_pink: require('../assets/images/print_Icons/print_pink.png'),
  print_grey: require('../assets/images/print_Icons/print_grey.png'),
  print_brown: require('../assets/images/print_Icons/print_brown.png'),
  
  spell_steelblue: require('../assets/images/spell_icon/spell_steelblue.png'),
  spell_blue: require('../assets/images/spell_icon/spell_blue.png'),
  spell_brown: require('../assets/images/spell_icon/spell_brown.png'),
  spell_skyblue: require('../assets/images/spell_icon/spell_skyblue.png'),
  spell_navyblue: require('../assets/images/spell_icon/spell_navyblue.png'),
  spell_pink: require('../assets/images/spell_icon/spell_pink.png'),
  spell_yellow: require('../assets/images/spell_icon/spell_yellow.png'),
  spell_green: require('../assets/images/spell_icon/spell_green.png'),
  spell_darkgreen: require('../assets/images/spell_icon/spell_darkgreen.png'),
  spell_grey: require('../assets/images/spell_icon/spell_grey.png'),
  spell_seagreen: require('../assets/images/spell_icon/spell_seagreen.png'),
  spell_voilet: require('../assets/images/spell_icon/spell_voilet.png'),

  browse_steelblue: require('../assets/images/browse_icon/browse_steelblue.png'),
  browse_blue: require('../assets/images/browse_icon/browse_blue.png'),
  browse_navyblue: require('../assets/images/browse_icon/browse_navyblue.png'),
  browse_skyblue: require('../assets/images/browse_icon/browse_skyblue.png'),
  browse_green: require('../assets/images/browse_icon/browse_green.png'),
  browse_darkgreen: require('../assets/images/browse_icon/browse_darkgreen.png'),
  browse_seagreen: require('../assets/images/browse_icon/browse_seagreen.png'),
  browse_voilet: require('../assets/images/browse_icon/browse_voilet.png'),
  browse_pink: require('../assets/images/browse_icon/browse_pink.png'),
  browse_brown: require('../assets/images/browse_icon/browse_brown.png'),
  browse_grey: require('../assets/images/browse_icon/browse_grey.png'),
  browse_yellow: require('../assets/images/browse_icon/browse_yellow.png'),
  
  boxlink_blue: require('../assets/images/boxlink_icons/boxlink_blue.png'),
  boxlink_steelblue: require('../assets/images/boxlink_icons/boxlink_steel.png'),
  boxlink_skyblue: require('../assets/images/boxlink_icons/boxlink_skyblue.png'),
  boxlink_navyblue: require('../assets/images/boxlink_icons/boxlink_navyblue.png'),
  boxlink_pink: require('../assets/images/boxlink_icons/boxlink_pink.png'),
  boxlink_green: require('../assets/images/boxlink_icons/boxlink_green.png'),
  boxlink_seagreen: require('../assets/images/boxlink_icons/boxlink_seagreen.png'),
  boxlink_darkgreen: require('../assets/images/boxlink_icons/boxlink_darkgreen.png'),
  boxlink_grey: require('../assets/images/boxlink_icons/boxlink_grey.png'),
  boxlink_voilet: require('../assets/images/boxlink_icons/boxlink_voilet.png'),
  boxlink_yellow: require('../assets/images/boxlink_icons/boxlink_yellow.png'),
  boxlink_brown: require('../assets/images/boxlink_icons/boxlink_brown.png'),
  
  refresh_blue: require('../assets/images/refresh_icons/refresh_blue.png'),
  refresh_steelblue: require('../assets/images/refresh_icons/refresh_steelblue.png'),
  refresh_skyblue: require('../assets/images/refresh_icons/refresh_skyblue.png'),
  refresh_navyblue: require('../assets/images/refresh_icons/refresh_navyblue.png'),
  refresh_green: require('../assets/images/refresh_icons/refresh_green.png'),
  refresh_seagreen: require('../assets/images/refresh_icons/refresh_seagreen.png'),
  refresh_darkgreen: require('../assets/images/refresh_icons/refresh_darkgreen.png'),
  refresh_voilet: require('../assets/images/refresh_icons/refresh_voilet.png'),
  refresh_pink: require('../assets/images/refresh_icons/refresh_pink.png'),
  refresh_grey: require('../assets/images/refresh_icons/refresh_grey.png'),
  refresh_yellow: require('../assets/images/refresh_icons/refresh_yellow.png'),
  refresh_brown: require('../assets/images/refresh_icons/refresh_brown.png'),
  refresh_white: require('../assets/images/refresh_icons/refresh_white.png'), 
  

  download_brown: require('../assets/images/download_icon/download_brown.png'),
  download_blue: require('../assets/images/download_icon/download_blue.png'),
  download_steelblue: require('../assets/images/download_icon/download_steelblue.png'),
  download_skyblue: require('../assets/images/download_icon/download_skyblue.png'),
  download_navyblue: require('../assets/images/download_icon/download_navyblue.png'),
  download_yellow: require('../assets/images/download_icon/download_yellow.png'),
  download_voilet: require('../assets/images/download_icon/download_voilet.png'),
  download_green: require('../assets/images/download_icon/download_green.png'),
  download_darkgreen: require('../assets/images/download_icon/download_darkgreen.png'),
  download_seagreen: require('../assets/images/download_icon/download_seagreen.png'),
  download_grey: require('../assets/images/download_icon/download_grey.png'),
  download_pink: require('../assets/images/download_icon/download_pink.png'),
  download_white: require('../assets/images/download_icon/download_white.png'),
  

  ipban_brown: require('../assets/images/banip_icons/ipban_brown.png'),
  ipban_blue: require('../assets/images/banip_icons/ipban_blue.png'),
  ipban_skyblue: require('../assets/images/banip_icons/ipban_skyblue.png'),
  ipban_steelblue: require('../assets/images/banip_icons/ipban_steelblue.png'),
  ipban_navyblue: require('../assets/images/banip_icons/ipban_navyblue.png'),
  ipban_green: require('../assets/images/banip_icons/ipban_green.png'),
  ipban_darkgreen: require('../assets/images/banip_icons/ipban_darkgreen.png'),
  ipban_pink: require('../assets/images/banip_icons/ipban_pink.png'),
  ipban_yellow: require('../assets/images/banip_icons/ipban_yellow.png'),
  ipban_voilet: require('../assets/images/banip_icons/ipban_voilet.png'),
  ipban_grey: require('../assets/images/banip_icons/ipban_grey.png'),
  ipban_seagreen: require('../assets/images/banip_icons/ipban_seagren.png'),

  email_blue: require('../assets/images/email_icon/email_blue.png'),
  email_skyblue: require('../assets/images/email_icon/email_skyblue.png'),
  email_steelblue: require('../assets/images/email_icon/email_steelblue.png'),
  email_navyblue: require('../assets/images/email_icon/email_navyblue.png'),
  email_pink: require('../assets/images/email_icon/email_pink.png'),
  email_yellow: require('../assets/images/email_icon/email_yellow.png'),
  email_voilet: require('../assets/images/email_icon/email_voilet.png'),
  email_grey: require('../assets/images/email_icon/email_grey.png'),
  email_brown: require('../assets/images/email_icon/email_brown.png'),
  email_green: require('../assets/images/email_icon/email_green.png'),
  email_darkgreen: require('../assets/images/email_icon/email_darkgreen.png'),
  email_seagreen: require('../assets/images/email_icon/email_seagreen.png'),

  upload_pink: require('../assets/images/upload_icon/upload_pink.png'),
  upload_yellow: require('../assets/images/upload_icon/upload_yellow.png'),
  upload_brown: require('../assets/images/upload_icon/upload_brown.png'),
  upload_voilet: require('../assets/images/upload_icon/upload_voilet.png'),
  upload_grey: require('../assets/images/upload_icon/upload_grey.png'),
  upload_skyblue: require('../assets/images/upload_icon/upload_skyblue.png'),
  upload_navyblue: require('../assets/images/upload_icon/upload_navyblue.png'),
  upload_steelblue: require('../assets/images/upload_icon/upload_steelblue.png'),
  upload_blue: require('../assets/images/upload_icon/upload_blue.png'),
  upload_green: require('../assets/images/upload_icon/upload_green.png'),
  upload_darkgreen: require('../assets/images/upload_icon/upload_darkgreen.png'),
  upload_seagreen: require('../assets/images/upload_icon/upload_seagreen.png'),

  pin_brown: require('../assets/images/pin_icons/pin_brown.png'),
  pin_blue: require('../assets/images/pin_icons/pin_blue.png'),
  pin_steelblue: require('../assets/images/pin_icons/pin_steelblue.png'),
  pin_skyblue: require('../assets/images/pin_icons/pin_skyblue.png'),
  pin_navyblue: require('../assets/images/pin_icons/pin_navyblue.png'),
  pin_pink: require('../assets/images/pin_icons/pin_pink.png'),
  pin_yellow: require('../assets/images/pin_icons/pin_yellow.png'),
  pin_green: require('../assets/images/pin_icons/pin_green.png'),
  pin_darkgreen: require('../assets/images/pin_icons/pin_darkgreen.png'),
  pin_grey: require('../assets/images/pin_icons/pin_grey.png'),
  pin_voilet: require('../assets/images/pin_icons/pin_voilet.png'),
  pin_seagreen: require('../assets/images/pin_icons/pin_seagreen.png'),


  exit_blue2: require('../assets/images/exit_Icons/exit_blue2.png'),
  exit_navyblue2: require('../assets/images/exit_Icons/exit_navyblue2.png'),
  exit_steelblue2: require('../assets/images/exit_Icons/exit_steelblue2.png'),
  exit_skyblue2: require('../assets/images/exit_Icons/exit_skyblue2.png'),
  exit_green2: require('../assets/images/exit_Icons/exit_green2.png'),
  exit_darkgreen2: require('../assets/images/exit_Icons/exit_darkgreen2.png'),
  exit_seagreen2: require('../assets/images/exit_Icons/exit_seagreen2.png'),
  exit_pink2: require('../assets/images/exit_Icons/exit_pink2.png'),
  exit_brown2: require('../assets/images/exit_Icons/exit_brown2.png'),
  exit_voilet2: require('../assets/images/exit_Icons/exit_voilet2.png'),
  exit_grey2: require('../assets/images/exit_Icons/exit_grey2.png'),
  exit_yellow2: require('../assets/images/exit_Icons/exit_yellow2.png'),

  
  exit_blue: require('../assets/images/exit_Icons/exit_blue.png'),
  exit_navyblue: require('../assets/images/exit_Icons/exit_navyblue.png'),
  exit_steelblue: require('../assets/images/exit_Icons/exit_steelblue.png'),
  exit_skyblue: require('../assets/images/exit_Icons/exit_skyblue.png'),
  exit_green: require('../assets/images/exit_Icons/exit_green.png'),
  exit_darkgreen: require('../assets/images/exit_Icons/exit_darkgreen.png'),
  exit_seagreen: require('../assets/images/exit_Icons/exit_seagreen.png'),
  exit_pink: require('../assets/images/exit_Icons/exit_pink.png'),
  exit_brown: require('../assets/images/exit_Icons/exit_brown.png'),
  exit_voilet: require('../assets/images/exit_Icons/exit_voilet.png'),
  exit_grey: require('../assets/images/exit_Icons/exit_grey.png'),
  exit_yellow: require('../assets/images/exit_Icons/exit_yellow.png'),


  attachmant_blue: require('../assets/images/attachment_icon/attachmant_blue.png'),
  attachmant_steelblue: require('../assets/images/attachment_icon/attachmant_steelblue.png'),
  attachmant_navyblue: require('../assets/images/attachment_icon/attachmant_navyblue.png'),
  attachmant_skyblue: require('../assets/images/attachment_icon/attachmant_skyblue.png'),
  attachmant_brown: require('../assets/images/attachment_icon/attachmant_brown.png'),
  attachmant_voilet: require('../assets/images/attachment_icon/attachmant_voilet.png'),
  attachmant_yellow: require('../assets/images/attachment_icon/attachmant_yellow.png'),
  attachmant_pink: require('../assets/images/attachment_icon/attachmant_pink.png'),
  attachmant_green: require('../assets/images/attachment_icon/attachmant_green.png'),
  attachmant_darkgreen: require('../assets/images/attachment_icon/attachmant_darkgreen.png'),
  attachmant_seagreen: require('../assets/images/attachment_icon/attachmant_seagreen.png'),
  attachmant_grey: require('../assets/images/attachment_icon/attachmant_grey.png'),

  link_blue: require('../assets/images/linkIcons/blue.png'),
  link_steelblue: require('../assets/images/linkIcons/steelBlue.png'),
  link_navyblue: require('../assets/images/linkIcons/navyBlue.png'),
  link_skyblue: require('../assets/images/linkIcons/deepSkyBlue.png'),
  link_brown: require('../assets/images/linkIcons/brown.png'),
  link_voilet: require('../assets/images/linkIcons/violet.png'),
  link_yellow: require('../assets/images/linkIcons/mustardYellow.png'),
  link_pink: require('../assets/images/linkIcons/deepPink.png'),
  link_green: require('../assets/images/linkIcons/green.png'),
  link_darkgreen: require('../assets/images/linkIcons/darkGreen.png'),
  link_seagreen: require('../assets/images/linkIcons/lightSeaGreen.png'),
  link_grey: require('../assets/images/linkIcons/grey.png'),
 

  arrow_blue: require('../assets/images/arrow_icons/arrow_blue.png'),
  arrow_steelblue: require('../assets/images/arrow_icons/arrow_steelblue.png'),
  arrow_skyblue: require('../assets/images/arrow_icons/arrow_skyblue.png'),
  arrow_green: require('../assets/images/arrow_icons/arrow_green.png'),
  arrow_yellow: require('../assets/images/arrow_icons/arrow_yellow.png'),
  arrow_brown: require('../assets/images/arrow_icons/arrow_brown.png'),
  arrow_grey: require('../assets/images/arrow_icons/arrow_grey.png'),
  arrow_voilet: require('../assets/images/arrow_icons/arrow_voilet.png'),
  arrow_pink: require('../assets/images/arrow_icons/arrow_pink.png'),
  arrow_navyblue: require('../assets/images/arrow_icons/arrow_navyblue.png'),
  arrow_darkgreen: require('../assets/images/arrow_icons/arrow_darkgreen.png'),
  arrow_seagreen: require('../assets/images/arrow_icons/arrow_seagreen.png'),

  

  play_blue: require('../assets/images/play_icons/play_blue.png'),
  play_brown: require('../assets/images/play_icons/play_brown.png'),
  play_darkgreen: require('../assets/images/play_icons/play_darkgreen.png'),
  play_green: require('../assets/images/play_icons/play_green.png'),
  play_grey: require('../assets/images/play_icons/play_grey.png'),
  play_navyblue: require('../assets/images/play_icons/play_navyblue.png'),
  play_pink: require('../assets/images/play_icons/play_pink.png'),
  play_sea_green: require('../assets/images/play_icons/play_sea_green.png'),
  play_sky_blue: require('../assets/images/play_icons/play_sky_blue.png'),
  play_steel_blue: require('../assets/images/play_icons/play_steel_blue.png'),
  play_voilet: require('../assets/images/play_icons/play_voilet.png'),
  play_yellow: require('../assets/images/play_icons/play_yellow.png'),

  bell_blue: require('../assets/images/header_icons/bell_blue2.png'),
  bell_brown: require('../assets/images/header_icons/bell_brown2.png'),
  bell_dark_green: require('../assets/images/header_icons/bell_dark_green2.png'),
  bell_deep_sky_blue: require('../assets/images/header_icons/bell_deep_sky_blue2.png'),
  bell_green: require('../assets/images/header_icons/bell_green2.png'),
  bell_light_sea_green: require('../assets/images/header_icons/bell_light_sea_green2.png'),
  bell_grey: require('../assets/images/header_icons/bell_grey2.png'),
  bell_Navy_blue: require('../assets/images/header_icons/bell_Navy_blue2.png'),
  bell_steel_blue: require('../assets/images/header_icons/bell_steel_blue2.png'),
  bell_pink: require('../assets/images/header_icons/bell_black.png'),
  bell_voilet: require('../assets/images/header_icons/bell_voilet2.png'),
  bell_yellow: require('../assets/images/header_icons/bell_yellow2.png'),
  pdfIcon: require('../assets/images/pdficon.png'),
  txtIcon: require('../assets/images/txticon.png'),
  docxIcon: require('../assets/images/docxicon.png'),

  avatar_blue: require('../assets/images/avtar_icon/blue.png'),
  avatar_steelblue: require('../assets/images/avtar_icon/steelBlue.png'),
  avatar_navyblue: require('../assets/images/avtar_icon/navyBlue.png'),
  avatar_skyblue: require('../assets/images/avtar_icon/deepSkyBlue.png'),
  avatar_brown: require('../assets/images/avtar_icon/brown.png'),
  avatar_voilet: require('../assets/images/avtar_icon/violet.png'),
  avatar_yellow: require('../assets/images/avtar_icon/mustardYellow.png'),
  avatar_pink: require('../assets/images/avtar_icon/deepPink.png'),
  avatar_green: require('../assets/images/avtar_icon/green.png'),
  avatar_darkgreen: require('../assets/images/avtar_icon/darkGreen.png'),
  avatar_seagreen: require('../assets/images/avtar_icon/lightSeaGreen.png'),
  avatar_grey: require('../assets/images/avtar_icon/grey.png'),

  toparrow_blue: require('../assets/images/top_icon/blue.png'),
  toparrow_steelblue: require('../assets/images/top_icon/steelBlue.png'),
  toparrow_navyblue: require('../assets/images/top_icon/navyBlue.png'),
  toparrow_skyblue: require('../assets/images/top_icon/deepSkyBlue.png'),
  toparrow_brown: require('../assets/images/top_icon/brown.png'),
  toparrow_voilet: require('../assets/images/top_icon/violet.png'),
  toparrow_yellow: require('../assets/images/top_icon/mustardYellow.png'),
  toparrow_pink: require('../assets/images/top_icon/deepPink.png'),
  toparrow_green: require('../assets/images/top_icon/green.png'),
  toparrow_darkgreen: require('../assets/images/top_icon/darkGreen.png'),
  toparrow_seagreen: require('../assets/images/top_icon/lightSeaGreen.png'),
  toparrow_grey: require('../assets/images/top_icon/grey.png'),

  bottomarrow_blue: require('../assets/images/bottom_icon/blue.png'),
  bottomarrow_steelblue: require('../assets/images/bottom_icon/steelBlue.png'),
  bottomarrow_navyblue: require('../assets/images/bottom_icon/navyBlue.png'),
  bottomarrow_skyblue: require('../assets/images/bottom_icon/deepSkyBlue.png'),
  bottomarrow_brown: require('../assets/images/bottom_icon/brown.png'),
  bottomarrow_voilet: require('../assets/images/bottom_icon/violet.png'),
  bottomarrow_yellow: require('../assets/images/bottom_icon/mustardYellow.png'),
  bottomarrow_pink: require('../assets/images/bottom_icon/deepPink.png'),
  bottomarrow_green: require('../assets/images/bottom_icon/green.png'),
  bottomarrow_darkgreen: require('../assets/images/bottom_icon/darkGreen.png'),
  bottomarrow_seagreen: require('../assets/images/bottom_icon/lightSeaGreen.png'),
  bottomarrow_grey: require('../assets/images/bottom_icon/grey.png'),

  bottomleftarrow_blue: require('../assets/images/bottom_left/blue.png'),
  bottomleftarrow_steelblue: require('../assets/images/bottom_left/steelBlue.png'),
  bottomleftarrow_navyblue: require('../assets/images/bottom_left/navyBlue.png'),
  bottomleftarrow_skyblue: require('../assets/images/bottom_left/deepSkyBlue.png'),
  bottomleftarrow_brown: require('../assets/images/bottom_left/brown.png'),
  bottomleftarrow_voilet: require('../assets/images/bottom_left/violet.png'),
  bottomleftarrow_yellow: require('../assets/images/bottom_left/mustardYellow.png'),
  bottomleftarrow_pink: require('../assets/images/bottom_left/deepPink.png'),
  bottomleftarrow_green: require('../assets/images/bottom_left/green.png'),
  bottomleftarrow_darkgreen: require('../assets/images/bottom_left/darkGreen.png'),
  bottomleftarrow_seagreen: require('../assets/images/bottom_left/lightSeaGreen.png'),
  bottomleftarrow_grey: require('../assets/images/bottom_left/grey.png'),

  bottomrightarrow_blue: require('../assets/images/bottom_right/blue.png'),
  bottomrightarrow_steelblue: require('../assets/images/bottom_right/steelBlue.png'),
  bottomrightarrow_navyblue: require('../assets/images/bottom_right/navyBlue.png'),
  bottomrightarrow_skyblue: require('../assets/images/bottom_right/deepSkyBlue.png'),
  bottomrightarrow_brown: require('../assets/images/bottom_right/brown.png'),
  bottomrightarrow_voilet: require('../assets/images/bottom_right/violet.png'),
  bottomrightarrow_yellow: require('../assets/images/bottom_right/mustardYellow.png'),
  bottomrightarrow_pink: require('../assets/images/bottom_right/deepPink.png'),
  bottomrightarrow_green: require('../assets/images/bottom_right/green.png'),
  bottomrightarrow_darkgreen: require('../assets/images/bottom_right/darkGreen.png'),
  bottomrightarrow_seagreen: require('../assets/images/bottom_right/lightSeaGreen.png'),
  bottomrightarrow_grey: require('../assets/images/bottom_right/grey.png'),
  
}

export default imageURLs;