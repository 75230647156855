import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import { css } from '@emotion/core';
import ReportUsageStore from './ReportUsageStore'
import DateRangePicker from "react-daterange-picker";
import { GridLoader } from 'react-spinners';
import CustomScroll from 'react-custom-scroll';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import SmsTimeColumChart from './SmsTimeColumChart';
import SmsExportCSV from './smsExportCSV';
import originalMoment from "moment";
import axios from 'axios';
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`



const ReportUsage = observer(class ReportUsage extends Component {

    constructor(props) {
        const today = moment();
        super(props);
        this.reportUsageStore = new ReportUsageStore(this.props.appstore);
        this.state = {
            isOpen: false,
            value: moment.range(today.clone().subtract(7, "days"), today.clone())
        }


    }


    componentDidMount() {

        this.reportUsageStore.setWebsites(this.props.appstore.userInfo.allwebistes, this.props.appstore)
        this.handleWebsiteFilter();
        //  this.reportUsageStore.getStoragespace(this.props.appstore)
        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();

        this.getSms_Used_data(date + '-' + month + '-' + year)
    }

   
    getSms_Used_data = (dateRange = null) => {
        const today = moment();
        let since_from_today = today.clone().subtract(14, "days");

        let data = {
            "since": dateRange != null ? since_from_today.format("DD-MM-YYYY"):this.state.value.start.format("DD-MM-YYYY"),
            "till": dateRange != null ? dateRange:this.state.value.end.format("DD-MM-YYYY"),
            "website_uid": this.reportUsageStore.allwebsite.website_uid,
            "alltype": ["sms_usage"],

        }
        this.reportUsageStore.datepicker = false;

        axios({
            method: 'POST',
            url: this.props.appstore.url + 'getSmsUsgaeRecords.json',
            headers: { 'content-type': 'application/json' },
            data: data
        }).then(result => {
            if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
            this.reportUsageStore.getStoragespace(this.props.appstore, dateRange)
            this.reportUsageStore.exportdata = JSON.parse(JSON.stringify(result.data));

            let sms_usedDate = [];
            let previoustimeDate = "";
            let sms_used = [];

            for (let j = 0; j < result.data.length; j++) {
                if (previoustimeDate === result.data[j].quarter_date) {
                    sms_used[sms_used.length - 1] = sms_used[sms_used.length - 1] + result.data[j].cnt;
                }
                else {
                    sms_usedDate.push(result.data[j].quarter_date)
                    sms_used.push(result.data[j].cnt)
                    previoustimeDate = result.data[j].quarter_date;
                }
            }

            this.reportUsageStore.reportUsageData = {
                "sms_usedDate": sms_usedDate,
                "sms_used": sms_used,
                "previoustimeDate": previoustimeDate,

            };
            this.props.appstore.global_errorcount=0;
        }).catch((error) => {
            // if (error.response) {
            //     console.log(error)
            //     this.props.appstore.logout();
            // }
            console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
        });
    }

    selectedwebsite = (selectedOption) => {
        this.reportUsageStore.allwebsite = selectedOption;
        this.handleWebsiteFilter();
    }

    checkKeybackspace = (event) => {
        if (event.keyCode == 13 || event.keyCode == 8) {
            return
        }
    }

    handleWebsiteFilter() {
        this.reportUsageStore.pageLoder = false;
        axios({
            method: 'POST',
            url: this.props.appstore.url + 'getUserSmsRecords.json',
            headers: { 'content-type': 'application/json' },
            data: { "website_uid": this.reportUsageStore.allwebsite.website_uid },
        }).then(result => {
            if(result.data.length==1){
                if(result.data[0].logoutstatus==1){
                  this.props.appstore.logout();
                }
                }
            this.reportUsageStore.operators = result.data;
            this.reportUsageStore.pageLoder = true;
            this.props.appstore.global_errorcount=0;
        }).catch((error) => {
            // if (error.response) {
            //     console.log(error)
            //     this.props.appstore.logout();
            // }
            this.reportUsageStore.pageLoder = true;
            console.log(error);
            console.log("error count :"+this.props.appstore.global_errorcount);
            this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
            if(this.props.appstore.global_errorcount>20){
            // this.props.appstore.logout();
            }
        });

    }

    onSelect = (value, states) => {
        this.setState({ value, states });
        this.reportUsageStore.getStoragespace(this.props.appstore);
        this.getSms_Used_data();
    };

    refreshimagesetonhover = (color,button)=>{
		if(button==1){
			document.getElementById("smsusedimg").src=imageURLs.download_white;
		}
	}
	refreshimagesetonhoverout = (color,button)=>{
		if(button==1){
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("smsusedimg").src=imageurl;
		}
	}
    render() {

        const { appstore } = this.props;
        let self = this;
        let currentdate = moment();
        if (!this.reportUsageStore.pageLoder) {
            return (<div><div className="adminloader">
                <GridLoader
                    css={override}
                    size={20}
                    marging={2}
                    color={this.props.appstore.selectedColor.color}
                />
            </div> </div>)
        }

        return (

            <div className="innercontent_wrapper w-100 scriptWrapper">
                <div className="header_title row align-items-center m-0">
                    <img src={imageURLs.report_usage} alt="Charts" className="img-fluid" />
                    <h4 className="text-left">{appstore.getLanguageTrans("Usage")}</h4>

                </div>


                <div className="row fileTransferWrapper">
                    <div className="col-12 w-100 pr-0">
                        <div className="settingFormWrapper box">
                            {/* <Form> */}
                                {/* <Form.Group as={Row} controlId="formGroupLanguage" >
                                    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Date Range")}</Form.Label>
                                    <Col sm="8" lg="7" >
                                        <Form.Control type="text" placeholder={appstore.getLanguageTrans("Select Date Range")}
                                            name="date_picker"
                                            value={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
                                            onClick={() => this.reportUsageStore.datepicker = !this.reportUsageStore.datepicker}
                                            onChange={(event) => { this.reportUsageStore.getStoragespace(appstore, event); this.getSms_Used_data() }}
                                        
                                        />

                                        {this.reportUsageStore.datepicker &&
                                            <DateRangePicker
                                                value={this.state.value}
                                                onSelect={this.onSelect}
                                                singleDateRange={true}
                                            />
                                        }
                                    </Col>

                                </Form.Group> */}


                                {/* <Form.Group as={Row} controlId="formGroupLanguage">
                                    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Websites")}</Form.Label>
                                    <Col sm="8" lg="9" className="customDrpDwn"> */}

                                        {/* <DropDownComponent dropDownOptions={this.reportUsageStore.websiteDrpDwnOptions}
                                            store={this.reportUsageStore}
                                            callBack={(selectedOption) => this.selectedwebsite(selectedOption)}
                                            selectedOption={this.reportUsageStore.allwebsite} /> */}
                                        {/* onChange = {(event) => this.getStartPagedata(event)}  */}
                                    {/* </Col>
                                </Form.Group>
                            </Form> */}

                            {/* <div className="col-12 p-0">
                                <div className="headerTitle">
                                    <h4 className="text-left">{appstore.getLanguageTrans("SMS Usage")}</h4>
                                </div>
                            </div>

                            <div className={appstore.menuActive ? "performance-wrapper" : "performance-wrapper"}>
                                <div className="performance-content reportUsage row">

                                    <div className="chats col-6 reportUsageborder">
                                        <div className="reportschartsborder">
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.props.appstore.userInfo.accountsmodel.sms_monthly_used}</p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("SMS Used")}</p>
                                        </div>
                                    </div>

                                    <div className="chats col-6 reportUsageborder">
                                        <div>
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.props.appstore.userInfo.accountsmodel.sms_monthly_total - this.props.appstore.userInfo.accountsmodel.sms_monthly_used}</p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("SMS Available Now")}</p>
                                        </div>
                                    </div>

                                    {
                                        this.reportUsageStore.operators.map(function (fileData) {
                                            return (
                                                <div className="col-2" style={{ paddingTop: 20 }} >
                                                    <img src={fileData.image != '' ? self.props.appstore.resourceurl + self.props.appstore.userInfo.accountsmodel.account_uid + "/" + fileData.image :
                                                    "#3ba8c5" == appstore.selectedColor.color ? imageURLs.avatar_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.avatar_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.avatar_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.avatar_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.avatar_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.avatar_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.avatar_voilet : 
                                                    "#039be6" == appstore.selectedColor.color ? imageURLs.avatar_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.avatar_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.avatar_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.avatar_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.avatar_skyblue : imageURLs.avatar_steelblue} style={{ width: "80px", height: "80px", borderRadius: "50px", border: "2px solid " + appstore.selectedColor.color + " " }}  alt="avatar" className="img-fluid" />
                                                    <p className="Sms_operators">{fileData.nick_name} <br></br> {appstore.getLanguageTrans("Used")} <span className="opCss" style={{ color: appstore.selectedColor.color }}>{fileData.cnt}</span> {appstore.getLanguageTrans("Sms")}</p>
                                                </div>

                                            );
                                        })
                                    }

                                </div>

                                <div className="col-12">
                                </div>

                            </div> */}


                            {/* <div className="col-12 p-0">
                                <Row className="top_Row" id="top_row">
                                    <Col xs="12" lg="10" md="4" sm="6">
                                        <div className="headerTitle">
                                            <h4 className="text-left">{appstore.getLanguageTrans("SMS Used")}</h4>
                                        </div>
                                    </Col>
                                    <Col xs="4" lg="2" md="6" sm="2">
                                        <Form>
                                            <Form.Group as={Row} style={{ paddingTop: 36, right: 15 }}>
                                                <Button className="top_btn custom_btn export_csvbtn addBtn text-uppercase" style={{minWidth:"150px"}} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,1)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,1)} >
                                                    <img id="smsusedimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
                                                        "#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />
                                                    <SmsExportCSV
                                                        dataexport={this.reportUsageStore.exportdata}
                                                        show={this.reportUsageStore.export}
                                                        store={this.reportUsageStore}
                                                        appStore={appstore}
                                                    />
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div> */}


                            {/* <div className="row profile_wrapper">
                                <div className="settingsForm col-12 w-100 pr-0">
                                    <div className="settingFormWrapper box">
                                        <SmsTimeColumChart
                                            key={Math.random()}
                                            data={this.reportUsageStore.reportUsageData}
                                            appstore={appstore}
                                            store={this.reportUsageStore} />
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-12 p-0">
                                <div className="headerTitle">
                                    <h4 className="text-left">{appstore.getLanguageTrans("File Transfer")}</h4>
                                </div>
                            </div>

                            <div className={appstore.menuActive ? "performance-wrapper" : "performance-wrapper"}>
                                <div className="performance-content reportcharts row">
                                    <div className="chats col-4">
                                        <div className="reportschartsborder">
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.reportUsageStore.usedSpace} <span style={{ fontSize: 17 }}>{this.reportUsageStore.spaceUnit}</span></p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Used Space")}</p>
                                        </div>
                                    </div>
                                    <div className="chats col-4">
                                        <div className="reportschartsborder">
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.reportUsageStore.availablespace} <span style={{ fontSize: 17 }}>{this.reportUsageStore.spaceUnit}</span></p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Available Space")}</p>
                                        </div>
                                    </div>

                                    <div className="chats col-4 ">
                                        <div>
                                            <p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.reportUsageStore.rangeusedSpace} <span style={{ fontSize: 17 }}>{this.reportUsageStore.spaceUnit}</span></p>
                                            <p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Used During DateRange")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>




            </div>
        )
    }

});

export default ReportUsage;