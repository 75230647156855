import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../sharedFiles/ImagesURLs';
import navigationStore from '../sharedFiles/navigation-store';

import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import { ScaleLoader } from 'react-spinners';
import LoginService from './LoginService';
import FormValidator from '../sharedFiles/FormValidator';

import SockJsClient from 'react-stomp';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: #3ba8c5;
     background-color:transparent;
     margin-left:160px;`;
 


const LoginComponent = observer(class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.visitors=[];
        this.loginService =new LoginService(this.props)
        this.validator = new FormValidator([
          { 
            field: 'email', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Email is required.' 
          },
          { 
            field: 'email',
            method: 'isEmail', 
            validWhen: true, 
            message: 'Please enter a valid email.'
          }
         ]);

        this.state = { 
             socket:"",
             validation: this.validator.valid(),
        };
        
        this.submitted = false;
       // this.toggleShow = this.toggleShow.bind(this);
        
    }
    
    

    checkKeyEnter = (event) =>{
      if (event.keyCode == 13){
          document.getElementById('loginbtn').click()
      }
    }
   

    componentDidMount() {
      
    }

    componentWillUnmount() {
        window.scrollTo(0, 0);
    }
    
  

     static DgetDerivedStateFromProps(nextProps, prevState){
        if(nextProps.socket!==prevState.socket){
            console.log("getDerivedStateFromProps");
          return { socket: nextProps.socket};
       }
       else return null;
     }
     
   componentDidUpdate(prevProps, prevState) {

       if(prevProps.socket!==this.props.socket){
         console.log("componentDidUpdate");
         this.setState({socket: prevProps.socket});
         //this.classMethod();
       }
     }

 
    render() {
      let validation = this.submitted ?                         // if the form has been submitted at least once
      this.validator.validate(this.props.operator) :   // then check validity every time we render
      this.state.validation;

        const { appstore } = this.props;
        var self = this; 

        return (
            
            <div className="innercontent_wrapper w-100">
                <div className="websitesWrapper">
                    <div className="websiteDetailsWrapper loginWrapper">
              
                        <div className="websitesDetails col-12 col-sm-6 col-md-4">
                            <div className="box col-12">
                            <div className="loginHeader">
                                <h6>{!this.loginService.forgot?"Login":"Restore Password"}</h6>
                            </div>
                            <div className="content">
                                {this.loginService.loading && <div className='sweet-loading'>
                                    <ScaleLoader
                                      css={override}
                                      sizeUnit={"px"}
                                      height={35}
                                      width={4}
                                      radius={2}
                                      color={'#3ba8c5'}
                                      loading={this.loginService.loading}
                                    />
                                    </div>
                                }
                                { (this.loginService.invalidLogin && !this.loginService.forgot)&&
                                  <p className="invalidLogin">{appstore.userInfo}</p>  
                                }
                                { (this.loginService.failed && !this.loginService.forgot) &&
                                  <p className="invalidLogin">Something went wrong.</p>  
                                }
                                { (this.loginService.isValid && !this.loginService.forgot) &&
                                  <p className="invalidLogin">{this.loginService.formerrors}</p>  
                                }
                                {(this.loginService.forgot) &&
                                  <p className="invalidLogin">{this.loginService.formerrors}</p>  
                                }

                               { !this.loginService.forgot &&
                               <div>
                               <h6>{appstore.getLanguageTrans("Welcome to the live chat page.")}</h6>
                                <h6>{appstore.getLanguageTrans("Enter your email address and password below to log in.")}</h6>
                                </div>

                               }
                               { this.loginService.forgot && !this.loginService.forgotsentmail && !this.loginService.emailnotregisterd &&
                               <div>
                                <h6>{appstore.getLanguageTrans("Enter your email address below. You will get an email with instructions.")}</h6>
                                </div> 
                               }
                               {this.loginService.forgotsentmail && !this.loginService.emailnotregisterd &&
                                <div>
                                <h6>{this.loginService.forgotstatus} <span className="forgotnew" onClick={(e) => (this.loginService.forgot=false,this.loginService.forgotsentmail=false)} >{appstore.getLanguageTrans("Login ?")}</span></h6>
                                </div> 
                               }
                                {this.loginService.emailnotregisterd &&
                                <div>
                                <h6>{this.loginService.forgotstatus}</h6>
                                </div> 
                               }

                            </div>
                            {/*onSubmit={this.loginService.forgot? this.loginService.forgotPass:this.loginService.login}*/}
                            {(this.loginService.emailnotregisterd==true ? this.loginService.emailnotregisterd :
                             !this.loginService.forgotsentmail) &&
                            <React.Fragment>
                             <form  className="loginForm">                                 
                               <Form.Group as={Row} controlId="loginEmail">
            									    <Form.Label column  lg="12" sm="12">Email address:</Form.Label>
            									    <Col sm="12" lg="12">
                                    <Form.Control className={validation.email.isInvalid && 'has-error'}
                                       type="text" placeholder={appstore.getLanguageTrans("Enter Email")} name="email"
                                        value={this.loginService.user.email} 
                                        onChange={(e) => this.loginService.handleChange(e)}
                                        onKeyDown = {(e) => this.checkKeyEnter(e) }
                                        />
            									       <span className="error-block">{validation.email.message}</span>
                                  </Col>
            									  </Form.Group>
                            
                                {!this.loginService.forgot && 
                                 <Form.Group as={Row} controlId="loginPassword">
            										    <Form.Label column lg="12" sm="12">Password:</Form.Label>
            										    <Col sm="12" lg="12">
                                        <Form.Control type={this.loginService.hidden ? "password" : "text"} 
                                          placeholder="Enter Password" 
                                          name="pass" value={this.loginService.user.pass}  
                                          onChange={(e) => this.loginService.handleChange(e)}	
                                          onKeyDown = {(e) => this.checkKeyEnter(e) }
                                          autoComplete="off"
                                          	/>
            								        </Col>
            								      </Form.Group>
                                  
                                }  
                                   <div className="row m-0">
                                        <div className="col-12 loginBtns formBtns">
                                        {!this.loginService.forgot &&
                                            <p className="forgot" onClick={() => (this.loginService.forgot=true,this.loginService.formerrors="")} >Forgot password ?</p>
                                        } 
                                        {this.loginService.forgot  &&
                                            <p className="forgot" onClick={() => (this.loginService.forgot=false,this.loginService.formerrors="",this.loginService.forgotstatus="",this.loginService.emailnotregisterd=false,this.loginService.forgotsentmail=false)} >Login ?</p>
                                        }                          
                                            <div className="pullRight">
                                            {/*!this.state.forgot &&
                                               <Button className="btn_save" onClick={this.toggleShow}>
                                                    <p>Show</p>
                                                </Button>
                                            */}                
                                                <Button id="loginbtn" className={'btn_save ' +(this.loginService.forgot? 'restoredpass':'')} type="button" onClick={(e) =>  this.loginService.login(e,this.props.appstore)}>
                                                    <p>{!this.loginService.forgot?"Login":"Restore Password"}</p>
                                                </Button> 
                                              </div>
                                          </div>
                                      </div>
                                   
                                      <div className="row m-0">
                                      <p style={{fontSize:"13px",textAlign:"Left",paddingTop:"8px"}}><font color="red">{appstore.getLanguageTrans("Note")}</font>: {appstore.getLanguageTrans("To receive incoming chat request web push notifications, please use Google Chrome or Firefox browsers.")}</p>
                                      </div>
                                </form>
                                </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <SockJsClient url='http://localhost:8080/redis-chat?34=token' topics={['/topic/messages','/topic/count45','/topic/targetStation','/topic/operatorId']}
                        onMessage={(msg) => { console.log("message recieved from server: "+JSON.stringify(msg)); this.onMessage(msg); }}
                        ref={ (client) => { this.clientRef = client }} autoReconnect={true}
                        onConnect={ () => { console.log("connected"); } }
                        onDisconnect={ () => { console.log(" Disconnected"); } } />
                </div> */}
            </div>
        )
    }
})
export default LoginComponent;
