import { decorate, observable, extendObservable } from 'mobx';
import {data} from '../../assets/JSON/website_details';
import axios from 'axios';
class HomeComponentStore {
	  
  	constructor(appstore){
          this.websiteDetails = [{
            "name": "HelpOnClick",
            "id": 1,
            "active": false,
            "website": "",
            "invitations": 45,
            "chats" : 15,
            "totalChatTime": "2:45"
          }];
          this.deleteWebsite=false;
          this.confirm=true;
          this.isPageLoaded=false;
          this.appStore = appstore;
          this.lastWebsite=false;
          this.payDollar=false;
          this.errormodel=false;

    } 
    
    confirmAction=() =>{
  
        if(!this.appStore.isOwner){
          // this.appStore.logout();
          this.appStore.global_errorcount=this.appStore.global_errorcount+1;
          if(this.appStore.global_errorcount>20){
          // this.appStore.logout();
          } 
          this.confirm = false;
        }
        else
         window.open('https://clients.helponclick.com/index.php?cmd=index','_blank');
    }

  
    openUpgrade(appstore) {

      // if (GUI.Tray)
      //     trayOpenLink(Core.Account.params.upgrade_link);
      // else
          window.open(appstore.userInfo.accountsmodel.upgrade_link, '_blank');
    }
    
    getWebsites(appstore){
      axios({
            method: 'GET',
            url: this.props.appstore.url+'getprofile.json',
            headers: { 'content-type': 'application/json' },
          }).then(result=> { 
                console.log('getwebsites response*******');
							//  console.log(result);
							 if(result.data.usersmodel!=null)
							 {
								this.showProfileData(result.data);
								this.profileStore.pageisLoaded=true;
							 }
               this.props.appstore.global_errorcount=0;
         }).catch((error) => {
	        // if (error.response) {
				  //     console.log(error);
	        //     this.props.appstore.logout();
	        //  }
          this.profileStore.pageisLoaded=true;
          console.log(error);
          console.log("error count :"+this.props.appstore.global_errorcount);
          this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
          if(this.props.appstore.global_errorcount>20){
          // this.props.appstore.logout();
          } 
    })
  }

  deletAction(website){
    //if(this.websiteDetails.length >1){
        this.deleteWebsite=true;
        this.websiteForDelete = website;
    // }else{
    //   this.lastWebsite=true;
    // }
  }

  confirmdeleteWebsite=(appstore) =>{
        axios({
          method: 'POST',
          url: appstore.url+'deleteWebsite.json',
          headers: { 'content-type': 'application/json' },
          data:this.websiteForDelete,
          }).then(result=> { 
            if(result.data.length==1){
              if(result.data[0].logoutstatus==1){
                appstore.logout();
              }
              }
             if(result.data[0].querystatus==1){
              this.websiteDetails=result.data;
               appstore.userInfo.allwebistes = result.data;
               appstore.userInfo.alloperators = result.data[0].allusers;
               appstore.userInfo.alloperatorsnotrelated = result.data[0].allnotrelatedopt;
               if( appstore.selectedWebisiteUid !== this.websiteForDelete.website_uid)
               appstore.selectedWebisiteUid=appstore.userInfo.allwebistes[0].website_uid;

              //set height of add new site /pay dollar box 
              const element = document.getElementsByClassName("websitesDetails");
              if(element !=null && element.length > 1){
                      element[element.length-1].firstChild.style.height = element[0].offsetHeight+"px";
              }
               
             }else if(result.data[0].querystatus==2){
               //no websites
               this.websiteDetails=[];
               appstore.userInfo.allwebistes = "";
               appstore.selectedWebisiteUid="";
             }
             else{
               //error popup
               this.errormodel=true;
             }
             appstore.global_errorcount=0;
             if(appstore.userInfo.alloperators.length > appstore.userInfo.accountplan.max_ops && appstore.userInfo.accountplan.max_ops != 0 || appstore.userInfo.alldepartments.length > appstore.userInfo.accountplan.max_deps && appstore.userInfo.accountplan.max_deps !=0 && appstore.userInfo.allwebistes.length <= appstore.userInfo.accountplan.max_websites ) {
              if(appstore.isAdmin){
                  
                 // appstore.navigate('admin/setup/widgets');
                 appstore.deleteoperator=true;
              }else {
                  appstore.deleteoperator=true;
                  appstore.navigate('/traffic'); 
              }      
            }
         }).catch((error) => {
          // if (error.response) {
          //     console.log(error);
          //     appstore.logout();
          //  } 
          console.log(error);
          console.log("error count :"+appstore.global_errorcount);
          appstore.global_errorcount=appstore.global_errorcount+1;
          if(appstore.global_errorcount>20){
          // appstore.logout();
          }
      })
      this.deleteWebsite=false;
  }

}

decorate(HomeComponentStore, {
    websiteDetails: observable,
    confirm:observable,
    isPageLoaded:observable,
    deleteWebsite:observable,
    websiteForDelete:observable,
    lastWebsite:observable,
    payDollar:observable,
    errormodel:observable
})
 
export default HomeComponentStore;