import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button, Tabs, Tab} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';
import OperatorFileComponent from './OperatorFileComponent';

const OperatorChatComponent = observer(class OperatorChatComponent extends Component {

	constructor (props) {
        super(props);
    }
	componentDidMount(){
	}

	componentWillUnmount() {
    }


	render(){
		const { appstore,
				chatComponentStore,
				chatData,
				name,
				time,
				filePath,
				originalFileName,
				talkSessionUid,fullData,file_size } = this.props;
		var self = this; 
		if (filePath != "" && filePath != null) {
			return (
				<React.Fragment>
					<OperatorFileComponent fileData={chatComponentStore.fileData}  chatData={chatData}
					appstore={appstore} 
					fullData={fullData}
				chatComponentStore = {chatComponentStore} talkSessionUid={talkSessionUid}
				name={name} time={time} filePath={filePath} file_Size={file_size} originalFileName={originalFileName} /> 
				{/* <div className="chatSection operatorChat col-12 p-0">

					<p className="chatBy"><span>{name}</span> {time}</p>
					<div className="chatText float-left"> 
							<img className="fileImg" src={filePath}  />
							<span>{originalFileName}</span>
							<a href={filePath} download><img src={imageURLs.downloadIcon} /></a> 
					</div>
				</div>   */}
				{ chatData.length > 0 &&
				<div className="chatSection operatorChat col-12 p-0">
					<p className="chatBy">{name} <span>{time}</span></p>
					<div className="chatText float-left">
						<p className="response" dangerouslySetInnerHTML={{ __html: chatData }}>
						</p>
					</div>
				</div>
				}
				</React.Fragment> 
			)
		}
		if (chatData.length != 0) { 
		return (
			<div className="chatSection operatorChat col-12 p-0">
				<p className="chatBy">{name} <span>{time}</span></p>
				<div className="chatText float-left">
					<p className="response" dangerouslySetInnerHTML={{__html: chatData}}>
					</p>
				</div>
			</div>
			
		)
		}
	}
})
export default OperatorChatComponent;
