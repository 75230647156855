import { decorate, observable, extendObservable } from 'mobx';
import imageURLs from '../../../sharedFiles/ImagesURLs';
import { widgets } from '../../../assets/JSON/installedWidgets';
import { defualtdata } from '../../../assets/JSON/defualt_script';

import axios from 'axios';

class AddWebsiteStore {


	constructor() { 
		this.defualtdata = defualtdata;
		this.triggers = [
			{ label: "TRIGGER_CUSTOM", value: 1 },
			{ label: "TRIGGER_VISITOR_CLICKED", value: 2 },
			{ label: "TRIGGER_VISITOR_INVITED", value: 3 },
			{ label: "TRIGGER_NO_FOUND_INFORMATION", value: 4 },
			{ label: "TRIGGER_ABOUT_ME", value: 5 },
			{ label: "TRIGGER_GOODBYE", value: 6 },
			{ label: "TRIGGER_HELLO", value: 7 },
			{ label: "TRIGGER_I_GOOD", value: 8 },
			{ label: "TRIGGER_NO_REACTION", value: 9 },
			{ label: "TRIGGER_HOW_IT_WORK", value: 10 },
			{ label: "TRIGGER_TALK_WITH_HUMAN", value: 11 },
			{ label: "TRIGGER_CONTACT_ME", value: 12 },
			{ label: "TRIGGER_TEST", value: 13 },
			{ label: "TRIGGER_STUPID", value: 14 },
			{ label: "TRIGGER_COOKIES", value: 15 },
			{ label: "TRIGGER_THANK_YOU", value: 16 }
		  ];
		this.websiteDetails = {
			name: "",
			domainName: "",
			email: "",
			fbId: "",
			twitterId: "",
			googleId:"",
			website_uid: "",
			account_id: "",
			file: "",
			logo: "",
			selectedfile:"",
		}

		this.errormodel = false;
		this.isPageLoaded = false;
		this.saveinProcess = false;
		this.websiteName = this.websiteDetails.name;
		this.cropimage = {
			src: null,
			crop: {
			  unit: '%',
			  width: 50,
			  aspect: 1 / 1, 

			},
			croppedImageUrl: null,
		  }
		  this.imageCropModel = false;
		  this.imageRef = null;
		  this.imagewarning = false;
		  this.warning_image_name= "";
		  this.deleteImg=false;
		  this.fileMimeType="";
		  this.defulat_ques=[];
		  this.deleteImage=false;
		  this.deletewebsiteImage=false;
		 
	}

	valangsettings(appstore) { 
		let default_Data = this.defualtdata;
		let default_triggers = this.triggers;
		this.isPageLoaded = false;
		axios({
		  method: 'POST',
		  url: appstore.url + 'va_languagessetting.json',
		  headers: { 'content-type': 'application/json' },
		  data: { "lang": "en" }
		}).then(result => {
		  let languagedata = JSON.parse(result.data.language); 
		  this.languagesetting = result.data.language; 
		  let customlanguagedata = "";
		  if (result.data.data !== "") { customlanguagedata = JSON.parse(result.data.data); }
		  let basicquestion = [];
		  for (let i in default_Data) {
			try{
			let questionvalue = default_Data[i].question;
			let textvalue = default_Data[i].text; 
			  if (customlanguagedata != "") {
				let customequestionvalue = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === default_Data[i].question.toLowerCase())];
				if (customequestionvalue != undefined && customequestionvalue[1] !== null) {
				  if (customequestionvalue[1] != undefined || customequestionvalue[1].length != 0) {
					questionvalue = customequestionvalue[1];
				  }
				}
				let text=default_Data[i].text;
			  let prompt=false;
			  if(default_Data[i].text.indexOf("[prompt lead inputs]") !== -1){
				prompt=true;
				text= default_Data[i].text.replace("[prompt lead inputs]","")
			  }
				let custometextvalue = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === text.toLowerCase())];
				if (custometextvalue != undefined && custometextvalue[1] !== null) {
				  if (custometextvalue[1] != undefined || custometextvalue[1].length != 0) {
					textvalue = (prompt == true ? custometextvalue[1]+"[prompt lead inputs]" : custometextvalue[1]);
				  }
				}
			  } 
	 
			let keywords = ""; let related1 = ""; let related2 = "";
			let related3 = ""; let trigger = "";
			for (let k in default_triggers) {
			  if (default_triggers[k].label == default_Data[i].trigger) {
				trigger = default_triggers[k].value;
			  }
			}
	
			if (default_Data[i].hasOwnProperty("keywords")) {
			  for (let k in default_Data[i].keywords) {
				if (keywords !== "") {
				  keywords = keywords + ",";
				} 
				  let keywordsdata = "";
				  if (customlanguagedata != "") {
					  try{
					let cust_keywords = customlanguagedata[Object.keys(customlanguagedata).find(key => key.toLowerCase() === default_Data[i].keywords[k].toLowerCase())];
					if (cust_keywords != undefined && cust_keywords[1] !== null) {
					  if (cust_keywords[1] != undefined || cust_keywords[1].length != 0) {
						keywordsdata = cust_keywords[1];
						keywords = keywords + keywordsdata;
					  }
					} else {
					  keywords = keywords + default_Data[i].keywords[k];
					}
					}catch(err){
						keywords = keywords + default_Data[i].keywords[k];
					}
				  } else {
					keywords = keywords + default_Data[i].keywords[k];
				  } 
			  }
			}
			if (default_Data[i].hasOwnProperty("relates")) {
			  for (let k in default_Data[i].relates) {
				if (k == 0) {
				  related1 = default_Data[i].relates[k];
				} else if (k == 1) {
				  related2 = default_Data[i].relates[k];
				} else if (k == 2) {
				  related3 = default_Data[i].relates[k];
				}
			  }
			}
			let useLeadName = 0;
			let useLeadEmail = 0; let useLeadPhone = 0;
			if (default_Data[i].hasOwnProperty("leads")) {
			  useLeadName = 1;
			  useLeadEmail = 1;
			  useLeadPhone = 1;
			}
			var actions = {
			  "question": questionvalue, "id": i, "text": textvalue, "keywords": keywords,
			  "useLeadName": useLeadName, "useLeadEmail": useLeadEmail, "useLeadPhone": useLeadPhone,
			  "related_1": related1, "related_2": related2, "related_3": related3,
			  "lang": "", "trigger": trigger, "enable": 1
			}
			basicquestion.push(actions);
			}catch(err){
			}
		  } 
		  
		  this.defulat_ques=basicquestion
		//    console.log(this.defulat_ques);
		appstore.global_errorcount=0;
		}).catch((error) => {
		//   console.log(error);
		//   appstore.logout();
		console.log(error);
			console.log("error count :"+appstore.global_errorcount);
			appstore.global_errorcount=appstore.global_errorcount+1;
			if(appstore.global_errorcount>20){
			// appstore.logout();
			}
		});
		
	  }

	saveWebsiteDetails(appstore,formData,widgetstore) {
		this.saveinProcess = true; 
		this.websiteName = this.websiteDetails.name; 

		let newWebsite = {
			name: this.websiteDetails.name,
			domain_name: this.websiteDetails.domainName,
			email_messages: this.websiteDetails.email,
			facebook_link: this.websiteDetails.fbId,
			twitter_link: this.websiteDetails.twitterId,
			googleplus_link: this.websiteDetails.googleId,
			website_uid: this.websiteDetails.website_uid ? this.websiteDetails.website_uid :appstore.generateUid(),
			account_id: this.websiteDetails.account_id ? this.websiteDetails.account_id : null,
			logo: this.websiteDetails.logo
		}
		
		formData.append("name",newWebsite.name);
		formData.append("domain_name",newWebsite.domain_name);
		//formData.append("email_messages",newWebsite.email_messages);
		formData.append("facebook_link",newWebsite.facebook_link);
		formData.append("twitter_link",newWebsite.twitter_link);
		//formData.append("googleplus_link",newWebsite.googleplus_link);
		formData.append("googleplus_link","");
		formData.append("website_uid",newWebsite.website_uid);
		if(appstore.addNewSite!=true){formData.append("account_id",newWebsite.account_id)}
		formData.append("logo",newWebsite.logo); 
		formData.append("deleteimage",this.deleteImg);
	  
		axios({
			method: 'POST',
			url: appstore.url + 'saveWebsite.json',
			headers: { 'content-type': 'multipart/form-data' },
			data: formData
		}).then(result => {
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.appstore.logout();
				}
			  }
			if (result.data != null && result.data.length != 0) { 
				//if(appstore.userInfo.accountsmodel.canUseVirtualAgent == 1){ 
				this.addbasicquestions(newWebsite.website_uid,appstore);
				//}
				this.websiteDetails.website_uid=newWebsite.website_uid;
				appstore.userInfo.allwebistes = result.data;
				appstore.userInfo.alloperatorsnotrelated = result.data[0].allnotrelatedopt;
				this.imageCropModel = false;
				this.imageRef = null;
				this.imagewarning = false;
				this.warning_image_name= "";
				this.deleteImg=false;
				this.fileMimeType="";
				// let updated=true;
				// console.log(this.props);
				// this.props.sideMenuStore.logoDrpDown = this.props.sideMenuStore.getWesiteswithLogo(this.props.appstore.userInfo.allwebistes,updated);
				appstore.dontLoadWidgets = false;  
				if(!appstore.addNewSite){
					appstore.selectedWebisiteUid="";
				  //for edit
				//   let previousWebsiteUid = appstore.selectedWebisiteUid;
				//    for(let i in appstore.userInfo.allwebistes){
				// 	   //set selectedWebisiteUid to re-render website dropdown of sidemenu
				// 	 if(previousWebsiteUid != appstore.userInfo.allwebistes[i].website_uid){ 
				// 		appstore.dontLoadWidgets = true;  
				// 		appstore.selectedWebisiteUid = appstore.userInfo.allwebistes[i].website_uid;
				// 		break;
				// 	}
				//   }
				}else {
				  //set website dropdown as selected for new website
				  appstore.addNewSite = false;
				}
				appstore.selectedWebisiteUid=newWebsite.website_uid;
				
			} else {
				//show error 
				this.errormodel = true;
			} 
			this.saveinProcess = false;
			if(appstore.helpTourStarted){
			widgetstore.tourState.stepIndex=1;
			appstore.tourcurrent_step=2;
			}
			appstore.global_errorcount=0;
		}).catch((error) => {
			// appstore.logout();
			this.saveinProcess = false;
			this.imageCropModel = false;
				this.imageRef = null;
				this.imagewarning = false;
				this.warning_image_name= "";
				this.deleteImg=false;
				this.fileMimeType="";
			console.log(error);
			console.log("error count :"+appstore.global_errorcount);
			appstore.global_errorcount=appstore.global_errorcount+1;
			if(appstore.global_errorcount>20){
			// appstore.logout();
			}
		})

	}
	addbasicquestions(website_uid,appstore) { 
		axios({
		  method: 'POST', 
		  url: appstore.url + 'va_ScriptBasicQues.json',
		  headers: { 'content-type': 'application/json' },
		  data: { "lang": "en", "website_uid": website_uid, "basicquestion": this.defulat_ques }
		}).then(result => {
		  // console.log(result); 
		  appstore.global_errorcount=0;
		}).catch((error) => {
			// appstore.logout();
			console.log(error);
			console.log("error count :"+appstore.global_errorcount);
			appstore.global_errorcount=appstore.global_errorcount+1;
			if(appstore.global_errorcount>20){
			// appstore.logout();
			}
		});
	  }
	
}

decorate(AddWebsiteStore, {
	websiteDetails: observable,
	websiteName: observable,
	isPageLoaded: observable,
	saveinProcess: observable,
	errormodel: observable,
	cropimage:observable,
	imageCropModel:observable,
	imageRef:observable,
	deleteImg:observable,
	imagewarning:observable,
	warning_image_name:observable,
	defulat_ques:observable,
	defualtdata:observable,
	triggers:observable,
	deleteImage:observable,
	deletewebsiteImage:observable,
})

export default AddWebsiteStore;