import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../sharedComponents/dropDown/dropDown';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import WarningComponent from '../../../sharedComponents/confirmationPopup/WarningComponent';
import FormValidator from '../../../sharedFiles/FormValidator';
// import 'react-image-crop/dist/ReactCrop.css';
// import '../../../library/custom-image-crop.css';
import ImageCropComponent from '../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';

import SettingsStore from './SettingsStore';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const SettingsComponent = observer(class SettingsComponent extends Component {

	constructor(props, context) {
		super(props, context);
		this.editor = "";
		this.settingsStore = new SettingsStore(this.props.appstore);
		this.fileInputRef = React.createRef();
		
		this.validator = new FormValidator([
			{
				field: 'name',
				method: 'isEmpty',
				validWhen: false,
				message: 'Name of virtual agent is required.'
			},
			{
				field: 'leadLabelNameBegin',
				method: 'isEmpty',
				validWhen: false,
				message: 'lead name Say before is required.'
			},
			{
				field: 'leadLabelNameEnd',
				method: 'isEmpty',
				validWhen: false,
				message: 'lead name Say after is required.'
			},
			{
				field: 'leadLabelEmailBegin',
				method: 'isEmpty',
				validWhen: false,
				message: 'lead Email Say before is required.'
			},
			{
				field: 'leadLabelEmailEnd',
				method: 'isEmpty',
				validWhen: false,
				message: 'lead Email Say before is required.'
			},
			{
				field: 'leadLabelPhoneBegin',
				method: 'isEmpty',
				validWhen: false,
				message: 'lead Phone Say before is required.'
			},
			{
				field: 'leadLabelPhoneEnd',
				method: 'isEmpty',
				validWhen: false,
				message: 'lead Phone Say before is required.'
			}

		]);
		this.state = {
			validation: this.validator.valid(),
			websiteUid: this.props.appstore.selectedWebisiteUid,
			uploading: false,
			images: "",
			actual_file: ""
		}
		this.submitted = false;

	}

	componentDidMount() {
		this.props.appstore.menuChanged = window.location.pathname;
		// this.settingsStore.getsettingsdata();
		this.settingsStore.valangsettings();
	}
	componentWillUnmount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {
			// this.settingsStore.getsettingsdata();
			if(!this.props.appstore.componentDidUpdate){
				this.settingsStore.valangsettings(); 
				this.props.appstore.componentDidUpdate=true;
			} 
			// this.settingsStore.valangsettings();
			this.settingsStore.cropimage.croppedImageUrl = null;
			this.setState({ websiteUid: this.props.appstore.selectedWebisiteUid });
		}
	}
	handleChange = (event) => {
		this.updateProperty(event.target.name, event.target.value, event.target.id, event);
	}

	updateProperty(key, value, id, e) {
		let self = this;
		this.settingsStore.agentDetails[key] = value;
		// }
	}
	selectComponent = () => {
		this.settingsStore.deleteImg = true;
		// console.log(this.settingsStore.deleteImg);
	} 

	validatesubmit() {
		const validation = this.validator.validate(this.settingsStore.agentDetails);
		this.setState({ validation });
		this.submitted = true;
		if (validation.isValid) {
			const formData = new FormData()
			formData.set('id', '100');
			if (this.settingsStore.agentDetails.file != '' && this.settingsStore.agentDetails.file != undefined) {
				// this.settingsStore.agentDetails.file.forEach((file, i) => {
				this.state.images = this.settingsStore.agentDetails.file;
				this.state.actual_file = this.settingsStore.agentDetails.selectedfile[0];

				// })
				this.setState({ uploading: true })
				// this.settingsStore.agentDetails.file.forEach((file, i) => {
				formData.append("file", this.state.images)
				formData.append("actualfile", this.state.actual_file)
				formData.append("fileMimeType", this.state.fileMimeType);
				// })
			}
			this.settingsStore.savesettings(formData);
		}
		else {
			window.scrollTo(0, 450);
		}
	}

	// setValue(type) {
	// 	if(type === 'real'){
	// 		var val = this.state.realChat;
	// 		this.settingsStore.realchat=val;
	// 		this.setState({
	// 			realChat: !val
	// 		})
	// 	}
	// 	else {
	// 		var val = this.state.skipPreChat; 
	// 		this.settingsStore.prechat=val;
	// 		this.setState({
	// 			skipPreChat: !val
	// 		})
	// 	}
	// } 


	//-- Croping Images --//
	verifyFile = (files) => {
		let self=this;
		if (files && files.length > 0) {
			const currentFile = files[0]
			// console.log(currentFile);
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				this.settingsStore.warning_image_name = this.props.appstore.getLanguageTrans("Oops, the file is too large. The file size should be less than 10Mb.");
				this.settingsStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.settingsStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("An internal server error occurred.");
				this.settingsStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}

	onSelectFile = e => {
		this.settingsStore.agentDetails.selectedfile = e.target.files;
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					// this.setState({ src: reader.result })
					this.settingsStore.cropimage.src = reader.result
				);
				reader.readAsDataURL(e.target.files[0]);
				this.settingsStore.imageCropModel = true;
			}
		}
	};
	onCropComplete = crop => {
		this.settingsStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) { 
		if (this.settingsStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif';
			const mimetype = this.state.imagetype == 'image/png' ? 'image/png'
				: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
					: 'image/gif';
			this.state.fileMimeType = mimetype;
			const croppedImageUrl = await this.getCroppedImg(
				this.settingsStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			// this.setState({ croppedImageUrl }); 
			this.settingsStore.cropimage.croppedImageUrl = croppedImageUrl;

		}
	}

	handleClearToDefault = event => {
		this.settingsStore.imageCropModel = false;
		this.settingsStore.agentDetails.selectedfile = "";
		this.settingsStore.agentDetails.file="";
		this.state.actual_file="";
		this.state.fileMimeType="";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.settingsStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName,mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.settingsStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		this.settingsStore.agentDetails.file = myNewCroppedFile
		// download file
		// this.props.appstore.downloadBase64File(imageData64, myFilename)
		// this.handleClearToDefault()

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, mimetype);
		});
	}

	render() {
		const { crop, croppedImageUrl, src } = this.settingsStore.cropimage;

		const { appstore } = this.props;
		if (this.settingsStore.pagenotLoaded) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}


		let validation = this.submitted ?                         // if the form has been submitted at least once
			this.validator.validate(this.settingsStore.agentDetails) :   // then check validity every time we render
			this.state.validation;
		return (

			<div key={appstore.selectedWebisiteUid} className="settings_wrapper w-100">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.settingsIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Settings")}</h4>
				</div>
				<div className="row">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form className="customInputForm">
								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Enable mode")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">
										<DropDownComponent dropDownOptions={this.settingsStore.dropDownOptions}
											callBack={(selectedOption) => this.settingsStore.mode = selectedOption}
											selectedOption={this.settingsStore.mode}
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupAgent">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Image")}</Form.Label>
									<Col>
										<div className="image_upload">
											<Button variant="light" className="cursor-pointer">
												<p>{appstore.getLanguageTrans("Upload & edit")}</p>
												<input ref={this.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={this.onSelectFile} />
												{/* <input type="file" id="file" onChange={(e) => this.handleChange(e)} /> */}
											</Button>
											<div className="profileImg row align-items-center w-100 h-100 m-0">
												{this.settingsStore.cropimage.croppedImageUrl && (
													<React.Fragment>
													<img alt="Crop" className="img-fluid" style={{ width: "100px", height: "100px", borderRadius: "50px" }} src={croppedImageUrl} />
													<div className="img_delete pointerhand" onClick={() => this.handleClearToDefault()}><p className="rotate">+</p><p>{appstore.getLanguageTrans("Delete current image")}</p></div>
													</React.Fragment>
												)}
												{(!this.settingsStore.cropimage.croppedImageUrl && (this.settingsStore.agentDetails.image != '' && this.settingsStore.agentDetails.image != undefined)
													&& this.settingsStore.deleteImg == false) && (
														<React.Fragment>
															<img src={this.props.appstore.resourceurl + this.props.appstore.userInfo.accountsmodel.account_uid + "/" + this.settingsStore.agentDetails.image} style={{ width: "100px", height: "100px", borderRadius: "50px" }} alt="avatar" className="img-fluid" />
															<div className="img_delete pointerhand" onClick={() => this.selectComponent()}><p className="rotate">+</p><p>{appstore.getLanguageTrans("Delete current image")}</p></div>
														</React.Fragment>
													)}

											</div>
											{/* {this.settingsStore.agentDetails.image != '' &&
												<div className="profileImg row align-items-center w-100 h-100 m-0">
													<img src={"http://localhost:8080/images/image/" + this.props.appstore.userInfo.accountsmodel.account_uid + "/" + this.settingsStore.agentDetails.image} style={{ width: "100px", height: "100px", borderRadius: "50px" }} alt="avatar" className="img-fluid" />
													<div className="img_delete"><p className="rotate">+</p><p>Delete current image</p></div>
												</div>
											} */}
										</div>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupNameBefore">
									<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Skip pre-chat fields")}</Form.Label>
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={this.settingsStore.prechat}
											callBack={() => this.settingsStore.prechat = !this.settingsStore.prechat} />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupNameAfter">
									<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Show 'Chat with a real person' link during virtual chat if there's an available operator.")}</Form.Label>
									<Col className="custom_radioBtn">
										<CheckBoxComponent radioValue={this.settingsStore.realchat}
											callBack={() => this.settingsStore.realchat = !this.settingsStore.realchat} />
									</Col>
								</Form.Group>
								<div className="box innerForm">
									<Form.Group as={Row} controlId="formGroupLanguage">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Select Language")}</Form.Label>
										<Col sm="8" lg="9" className="customDrpDwn">
											<DropDownComponent dropDownOptions={this.settingsStore.lanDropDownOptions}
												store={this.settingsStore}
												callBack={(selectedOption) => this.settingsStore.setLanguage(selectedOption)}
												selectedOption={this.settingsStore.selectedLanguage} />
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupAgent">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Name of virtual agent")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text"  
												className={validation.name.isInvalid && 'has-error'}
												name="name"
												value={this.settingsStore.agentDetails.name}
												onChange={(e) => this.handleChange(e)}
												placeholder={appstore.getLanguageTrans("Enter Virtual Agent Name")} required />
											{this.settingsStore.customname &&
												<span className="error-block">*required</span>
											}
										</Col>
									</Form.Group>
									<p className="text-left text-uppercase"> {appstore.getLanguageTrans("Labels for lead name")}</p>
									<Form.Group as={Row} controlId="formGroupNameBefore">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Say before")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text"
												className={validation.leadLabelNameBegin.isInvalid && 'has-error'}
												name="leadLabelNameBegin"
												value={this.settingsStore.agentDetails.leadLabelNameBegin}
												onChange={(e) => this.handleChange(e)}
												placeholder={appstore.getLanguageTrans("Please enter your name")} required />
											{this.settingsStore.customnameBegin &&
												<span className="error-block">*required</span>
											}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupNameAfter">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Say after")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text"
												className={validation.leadLabelNameEnd.isInvalid && 'has-error'}
												name="leadLabelNameEnd"
												value={this.settingsStore.agentDetails.leadLabelNameEnd}
												onChange={(e) => this.handleChange(e)}
												placeholder={appstore.getLanguageTrans("Thank you!")} required />
											{this.settingsStore.customlNameEnd &&
												<span className="error-block">*required</span>
											}
										</Col>
									</Form.Group>
									<p className="text-left text-uppercase"> {appstore.getLanguageTrans("Labels for lead email")}</p>
									<Form.Group as={Row} controlId="formGroupEmailBefore">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Say before")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text"
												className={validation.leadLabelEmailBegin.isInvalid && 'has-error'}
												name="leadLabelEmailBegin"
												value={this.settingsStore.agentDetails.leadLabelEmailBegin}
												onChange={(e) => this.handleChange(e)}
												placeholder={appstore.getLanguageTrans("Please enter your address")} required />
											{this.settingsStore.customEmailBegin &&
												<span className="error-block">*required</span>
											}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupEmailAfter">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Say after")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text"
												className={validation.leadLabelEmailEnd.isInvalid && 'has-error'}
												name="leadLabelEmailEnd"
												value={this.settingsStore.agentDetails.leadLabelEmailEnd}
												onChange={(e) => this.handleChange(e)}
												placeholder={appstore.getLanguageTrans("Thank you!")} required />
											{this.settingsStore.customEmailEnd &&
												<span className="error-block">*required</span>
											}
										</Col>
									</Form.Group>
									<p className="text-left text-uppercase"> {appstore.getLanguageTrans("Labels for lead phone")}</p>
									<Form.Group as={Row} controlId="formGroupPhoneBefore">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Say before")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text" className={validation.leadLabelPhoneBegin.isInvalid && 'has-error'}
												name="leadLabelPhoneBegin"
												value={this.settingsStore.agentDetails.leadLabelPhoneBegin}
												onChange={(e) => this.handleChange(e)}
												placeholder={appstore.getLanguageTrans("Please enter your phone number")} required />
											{this.settingsStore.customPhoneBegin &&
												<span className="error-block">*required</span>
											}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formGroupPhoneAfter">
										<Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Say after")}</Form.Label>
										<Col sm="8" lg="9">
											<Form.Control type="text"
												className={validation.leadLabelPhoneEnd.isInvalid && 'has-error'}
												name="leadLabelPhoneEnd"
												value={this.settingsStore.agentDetails.leadLabelPhoneEnd}
												onChange={(e) => this.handleChange(e)}
												placeholder={appstore.getLanguageTrans("Thank you!")} required />
											{this.settingsStore.customPhoneEnd &&
												<span className="error-block">*required</span>
											}
										</Col>
									</Form.Group>
								</div>
								<div className="row m-0">
									<div className="col-12 formBtns">
										<div className="pullRight">
											<Button className="btn_clr" variant="light" onClick={() => this.settingsStore.cancelsettings()} >
												<p>{appstore.getLanguageTrans("Cancel")}</p>
											</Button>
											<Button className="btn_save" onClick={() => this.validatesubmit()}>
												<p>{appstore.getLanguageTrans("Save")}</p>
											</Button>
										</div>
									</div>
								</div>
							</Form>
							{this.settingsStore.errormodel &&
								<WarningComponent show={this.settingsStore.errormodel}
									store={this.settingsStore}
									cancel_btn="OK"
									closePopup={() => this.settingsStore.errormodel = false}
									appStore={appstore} />
							}
							{this.settingsStore.imagewarning &&
								<ImageWarningPopUp show={this.settingsStore.imagewarning}
									store={this.settingsStore}
									cancel_btn="Close"
									closePopup={() => this.settingsStore.imagewarning = false}
									appStore={appstore} />
							}
							{this.settingsStore.imageCropModel &&
								<ImageCropComponent show={this.settingsStore.imageCropModel}
									store={this.settingsStore}
									cancel_btn="Clear"
									multi="0"
									crop_btn="Save"
									onCropComplete={() => this.onCropComplete(crop)}
									closePopup={() => this.handleClearToDefault()}
									appStore={appstore} />
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
});
export default SettingsComponent;
