import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';
const AddModeComponent = observer(class AddModeComponent extends Component {

	constructor (props) {
        super(props);
		
    }
	
	componentDidMount(){


	}

	componentWillReceiveProps(nextProps){

		
	}
   
	render(){
       // alert(this.props.deptStore.existingOps.length)
		const { deptStore,appstore } = this.props;
   
		return (
			<div className="settingFormWrapper box">
					<div className="addWrapper" onClick={() =>deptStore.goToAddOperatorMode(appstore,true)}>
						<img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.add_steelblue2 : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.add_seagreen2 : "#ec407a" == appstore.selectedColor.color ?  imageURLs.add_pink2 : "#6e4c42" == appstore.selectedColor.color ?imageURLs.add_brown2 : "#234e91" == appstore.selectedColor.color ?imageURLs.add_navyblue2 : "#e89712" == appstore.selectedColor.color ?imageURLs.add_yellow2 : "#b968c7" ==appstore.selectedColor.color ?imageURLs.add_voilet2 : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.add_blue2 : "#00887a" == appstore.selectedColor.color ? imageURLs.add_darkgreen2 : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.add_green2 : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.add_grey2 : "#64a9e0"== appstore.selectedColor.color ? imageURLs.add_skyblue2 : imageURLs.add_steel_blue2} alt="" className="img-fluid" />
						<p>{appstore.getLanguageTrans("Add operator")}</p>
					</div>
			</div>

		)
	}
})
export default AddModeComponent;
