import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Router, Route, Switch } from "react-router-dom";
import navigationStore from '../sharedFiles/navigation-store';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import LoginComponent from '../components/LoginComponent';


const Login = observer(class Login extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {socket } = this.props;
      console.log('loginLayout'+socket);

  }

  componentWillUnmount() {
  }


  componentWillReceiveProps(nextProps){
  }

  /**
   * @param route
   */
  render() {
	  
	  
	  return (    
		      <Router history={navigationStore.history}>
		        <Switch>
		          <Route exact path="/" render={()=><LoginComponent socket={this.props.socket} appstore={this.props.appstore}/>}/>
		        </Switch>
		      </Router>
		    )
  }
})

export default Login;
