import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import Select , { components } from 'react-select';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../../sharedComponents/dropDown/dropDown';
import RadioButtonComponent from '../../../../../../sharedComponents/radionButton2/RadioButton';

const GeneralSettingsComponent = observer(class GeneralSettingsComponent extends Component {

	constructor (props) {
        super(props);
    }

	componentDidMount(){

		this.props.widgetStore.operatorDrpDwnOptions= this.props.appstore.deptOps.allOps;
		this.props.widgetStore.deptDrpDwnOptions=this.props.appstore.deptOps.allDept;
		this.props.widgetStore.toolBarGeneralSettings.department = this.props.widgetStore.deptDrpDwnOptions[0];
		this.props.widgetStore.toolBarGeneralSettings.operator = this.props.widgetStore.operatorDrpDwnOptions[0];
		this.props.widgetStore.toolBarGeneralSettings.language = this.props.appstore.selectedLanguage;
		this.props.widgetStore.pageisLoaded=true;
		this.props.widgetStore.setwidgetDataforEdit(this.props.appstore);
	}

	componentWillUnmount() {
	}
	
	checkcustomChatWindowSizewidth = (e) =>{

		if(e<350){
			this.props.widgetStore.toolBarGeneralSettings.customChatWindowSize.width =350 
		}else if(e>450){
			this.props.widgetStore.toolBarGeneralSettings.customChatWindowSize.width =450
		}else{
			this.props.widgetStore.toolBarGeneralSettings.customChatWindowSize.width =e
		}

	}

	checkcustomChatWindowSizeheight = (e) =>{

		if(e<300){
			this.props.widgetStore.toolBarGeneralSettings.customChatWindowSize.height =300 
		}else if(e>500){
			this.props.widgetStore.toolBarGeneralSettings.customChatWindowSize.height =500 
		}else{
			this.props.widgetStore.toolBarGeneralSettings.customChatWindowSize.height =e
		}

	}

	render(){

		var self = this;

		const { appstore,
				widgetStore,
				validation } = this.props;
        const vacss={
			pointerEvents:"none",
            opacity : 0.3
		}
		const nocss={}
		return (

				<form className="toolbar_general_tour">
					<Form.Group as={Row} className="installation_name_tour" controlId="formGroupMsg">
				    	<Form.Label column lg="5" sm="6" className={"pr-0" + (validation.installationName.isInvalid && ' field-error')}>Installation name <span className="mandatory">*</span></Form.Label>
				    	<Col sm="6" lg="7">
				    		<Form.Control type="text" className={validation.installationName.isInvalid && 'has-error'}
									name="installationName" placeholder={appstore.getLanguageTrans("Enter Installation Name")} 
				    				value={widgetStore.toolBarGeneralSettings.installationName} 
				    				onChange={(e) => widgetStore.toolBarGeneralSettings.installationName = e.target.value} />
				    		<span className="error-block">{validation.installationName.message}</span>
				    	</Col>
				  	</Form.Group>
				  	<Form.Group as={Row} controlId="formGroupMsg">
				    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Department")}</Form.Label>
				    	<Col sm="6" lg="7" className="customDrpDwn">
				    		<DropDownComponent dropDownOptions={widgetStore.deptDrpDwnOptions}
												store={widgetStore}
												key ={Math.random()}
							    				callBack={(selectedOption) => widgetStore.getOpforDept(appstore,widgetStore.toolBarGeneralSettings,selectedOption)}
							    				selectedOption = {widgetStore.toolBarGeneralSettings.department}/>
				    	</Col>
				  	</Form.Group>
				  	<Form.Group as={Row} controlId="formGroupMsg">
				    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Operator")}</Form.Label>
				    	<Col sm="6" lg="7" className="customDrpDwn">
				    		<DropDownComponent dropDownOptions={widgetStore.operatorDrpDwnOptions}
												store={widgetStore}
												key ={Math.random()}
							    				callBack={(selectedOption) => widgetStore.toolBarGeneralSettings.operator = selectedOption}
							    				selectedOption = {widgetStore.toolBarGeneralSettings.operator}/>
				    	</Col>
				  	</Form.Group>
				  	<Form.Group as={Row} controlId="formGroupMsg">
				    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Language")}</Form.Label>
				    	<Col sm="6" lg="7" className="customDrpDwn">
				    		{/* <DropDownComponent dropDownOptions={widgetStore.lanDropDownOptions}
					    						store={widgetStore}
							    				callBack={(selectedOption) => widgetStore.toolBarGeneralSettings.language = selectedOption}
							    				selectedOption = {widgetStore.toolBarGeneralSettings.language}/> */}
				    	    <DropDownComponent dropDownOptions={appstore.languages}
												store={appstore}
												key ={Math.random()}
												callBack={(selectedLanguage) =>widgetStore.setLanguage(selectedLanguage,appstore,widgetStore.toolBarGeneralSettings)}
												selectedOption = {appstore.selectedLanguage}/>	
						</Col>
				  	</Form.Group>
				  	<Form.Group as={Row} controlId="formGroupLead">
					    <Form.Label column lg="5" sm="6" className="pr-0 chatWindow">{appstore.getLanguageTrans("Chat window size")}</Form.Label>
					    <Col sm="6" lg="7" className="chat_windowWrapper">
					    	<div className="row m-0">
					    		<RadioButtonComponent selectedOption = {widgetStore.toolBarGeneralSettings.chatWindowSize}
					    				options={widgetStore.chatWindowOptions}
					    				groupName={"size"}
					    				callBack={(selectedOption) =>{widgetStore.toolBarGeneralSettings.chatWindowSize = selectedOption}}/>
					    	</div>

					    	{
					    		widgetStore.toolBarGeneralSettings.chatWindowSize === 'custom' && 
					    		<div className="customSize">
					    			<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="2" sm="4" className="pr-0">{appstore.getLanguageTrans("Height")}</Form.Label>
								    	<Col sm="8" lg="10">
											<Form.Control type="text"
											        onKeyDown={(e) => { widgetStore.checkForEnteredNumer(e) }}
													name="customHeight" placeholder="" 
								    				value={widgetStore.toolBarGeneralSettings.customChatWindowSize.height} 
													onChange={(e) => widgetStore.toolBarGeneralSettings.customChatWindowSize.height = e.target.value}
													onBlur={(e) => this.checkcustomChatWindowSizeheight(e.target.value)} />
								    		<span>px (300-500)</span>
								    	</Col>
								  	</Form.Group>
								  	<Form.Group as={Row} controlId="formGroupMsg">
								    	<Form.Label column lg="2" sm="4" className="pr-0">{appstore.getLanguageTrans("Width")}</Form.Label>
								    	<Col sm="8" lg="10">
								    		<Form.Control type="text"
													name="customHeight" placeholder="" 
													onKeyDown={(e) => { widgetStore.checkForEnteredNumer(e) }}
													value={widgetStore.toolBarGeneralSettings.customChatWindowSize.width} 
													onChange={(e) => this.props.widgetStore.toolBarGeneralSettings.customChatWindowSize.width=e.target.value}
								    				onBlur={(e) => this.checkcustomChatWindowSizewidth(e.target.value)} />
								    		<span>px (350-450)</span>
								    	</Col>
								  	</Form.Group>
					    		</div>
					    	}
					    
					    </Col>
				  	</Form.Group>
					<Form.Group as={Row} controlId="formGroupMsg">
				    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Allow ")}{appstore.getLanguageTrans("Virtual Agent")}</Form.Label>
						{appstore.userInfo.accountplan.canUseVirtualAgent !=1 &&
						<span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
				    	<Col className="custom_radioBtn" style={vacss}>
					    	<CheckBoxComponent radioValue={widgetStore.toolBarGeneralSettings.enableVirtualAgent}
					    							callBack = {() => widgetStore.toolBarGeneralSettings.enableVirtualAgent = !widgetStore.toolBarGeneralSettings.enableVirtualAgent}/>
					    </Col>
						</span>}
						{appstore.userInfo.accountplan.canUseVirtualAgent !=0 &&
						<Col className="custom_radioBtn">
						<CheckBoxComponent radioValue={widgetStore.toolBarGeneralSettings.enableVirtualAgent}
												callBack = {() => widgetStore.toolBarGeneralSettings.enableVirtualAgent = !widgetStore.toolBarGeneralSettings.enableVirtualAgent}/>
					</Col>
						}
				  	</Form.Group>
				  	{/* <Form.Group as={Row} controlId="formGroupMsg">
				    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Enable pre-chat fields")}</Form.Label>
				    	<Col className="custom_radioBtn">
					    	<CheckBoxComponent radioValue={widgetStore.toolBarGeneralSettings.preChatEnabled}
					    							callBack = {() => widgetStore.toolBarGeneralSettings.preChatEnabled = !widgetStore.toolBarGeneralSettings.preChatEnabled}/>
					    </Col>
				  	</Form.Group>
				  	<Form.Group as={Row} controlId="formGroupMsg">
				    	<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Show pre-chat fields to invited users")}</Form.Label>
				    	<Col className="custom_radioBtn">
					    	<CheckBoxComponent radioValue={widgetStore.toolBarGeneralSettings.preChatToUsers}
					    							callBack = {() => widgetStore.toolBarGeneralSettings.preChatToUsers = !widgetStore.toolBarGeneralSettings.preChatToUsers}/>
					    </Col>
				  	</Form.Group> */}
					  
				</form>
				

		)
	}
})
export default GeneralSettingsComponent;
