import { decorate, observable, action } from 'mobx';
import {data} from '../../assets/JSON/current_chats';

class CurrentChatsStore {
	
  	constructor(appstore){
      this.setVisitorName( appstore);
    }

    setVisitorName=(appstore) =>{
      const visitors = appstore.visitorList;
      this.currentChats=[];
      if(visitors.length==0){ 
        return;
      }
      for(let i=0; i<visitors.length; i++){

        if(!visitors[i])
        continue ;

        if(visitors[i] && !visitors[i].prechatfields )
        continue;

        let fields = Object.values(JSON.parse(visitors[i].prechatfields.replace(/\n/g, " ")));
       
        let name = fields.find((option) =>{
          if(option.type === "name"){
            return option;
          }
        })
        
        if (name && name.value !="") {
            visitors[i].name= name.value ;
        }else
        visitors[i].name = i==0 ? "Visitor":"Visitor"+i;

        this.currentChats.push(visitors[i]);
      }

      //sett selected visitor
      if(appstore.requestedVisitorId){
        this.selectedChat = appstore.requestedVisitorId;
        return;
      }

      let path = window.location.href;
      let pos = path.lastIndexOf("/");
      let visitorId = path.slice(pos + 1, path.length);
      this.selectedChat = visitorId;
       
    }
   
 }

 decorate(CurrentChatsStore, {
        currentChats: observable,
        selectedChat: observable
    })
 export default CurrentChatsStore;
