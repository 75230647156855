import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';

const Confirm_phone = observer(class Confirm_phone extends Component {

    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    handleChange = (event) => { 
        //console.log(event.target.value)
        event.target.value = event.target.value
        this.props.store.confirmcode= event.target.value
    }  
    confirmphonecode =()=>{
        axios({
            method: 'POST',
            url: this.props.appStore.url + 'confirm_phone_complete.json',
            headers: { 'content-type': 'application/json','charset':'utf-8' },
            data: {"sms_phone_cc": this.props.store.confirmcode}
          }).then(result => {
              if(result.data=="success"){
                  this.props.store.smsChats.sms_phone_confirmed=1;
                  this.props.store.sms_phone_changing=false;
                  this.props.store.confirmphone_model=false; 
              }else{
                this.props.store.confirmcode_error=result.data;
              }
           
            }).catch((error) => {
                this.props.store.confirmcode_error="Somthing went wrong";
            if (error.response) {
              console.log(error); 
            }
          })
    }
    render() {
        const { show,
            store,
            title,
            callBack,
            body_html,
            cancel_btn,
            save_btn,
            appStore,
            singleButton,
            logoutCss,
            from,
            closeButtonRequired } = this.props;
        return (
            <Modal backdrop='static' className={(appStore ? appStore.selectedColor.value : '') + " " + (logoutCss ? logoutCss : "")} show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={() => closeButtonRequired ? "" : this.props.closePopup()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{fontSize:"13px"}}>{appStore.getLanguageTrans(title)}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="formGroupNickName"> 
                            {/* <Form.Label column lg="5" sm="4">{appStore.getLanguageTrans("Enter Confirmation otp")}</Form.Label> */}
                            <Col sm="12" lg="12">
                                <Form.Control type="text"
                                    className={''}
                                    name="confirmcode"
                                    value={store.confirmcode}
                                    onChange={(e) => this.handleChange(e)}
                                    maxlength="50" /> 
                                    <span className="error-block">{appStore.getLanguageTrans(store.confirmcode_error)}</span>
                            </Col> 
                        </Form.Group>
                    </Form>
                </Modal.Body> 
                <Modal.Footer>
                    <Button className="btn_cancel" type="button" variant="secondary" onClick={() => { this.confirmphonecode() }} >{appStore.getLanguageTrans(save_btn)}</Button>
                    <Button className="btn_cancel" type="button" variant="secondary" onClick={() => { this.props.closePopup() }} >{appStore.getLanguageTrans(cancel_btn)}</Button>
                    
                </Modal.Footer>
            </Modal>
        )
    }
})
export default Confirm_phone;
