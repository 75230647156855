import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';

import CheckBoxComponent from '../../../../../../sharedComponents/checkBox/checkBox';
import RadioButtonComponent from '../../../../../../sharedComponents/radionButton2/RadioButton';
import DropDownComponent from '../../../../../../sharedComponents/dropDown/dropDown';
import IconFontLibraryModal from './iconFontLibraryModal';
import TextEditor from '../../../../../../sharedComponents/textEditor/textEditor';
import ImageCropComponent from '../../../../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';
import SheduleTimeComponent from './SheduleTimeComponent';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import ConfirmationComponent from '../../../../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import {TooltipComponent} from '@syncfusion/ej2-react-popups';
const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const ButtonSettingsComponent = observer(class ButtonSettingsComponent extends Component {

	constructor(props) {
		super(props);
		this.fileInputRef = React.createRef();
		this.state = {
			uploading: false,
			actual_file: "",
			images: ""
		}
	}

	componentDidMount() {
		this.props.widgetStore.addedButtonCount = 0;

	}


	selectIconType(buttons, buttonsIndex) {
		this.props.widgetStore.getIconFromLibrary = buttons.iconType.value === 3 ? true : false;
		this.props.widgetStore.selectedButtonIndex = buttonsIndex;
		if (buttons.iconType.value !== 3) {
			if (buttons.iconType.value == 1 && buttons.label === "Chat") {
				this.props.widgetStore.toolBarPreviewButtonSettings[buttonsIndex].libraryIconClass = "chat"
			} else if (buttons.iconType.value == 1 && buttons.label === "Call") {

				this.props.widgetStore.toolBarPreviewButtonSettings[buttonsIndex].libraryIconClass = "phone"
			} else if (buttons.iconType.value == 1 && buttons.label === "Email") {
				this.props.widgetStore.toolBarPreviewButtonSettings[buttonsIndex].libraryIconClass = "envelope-o"
			} else if (buttons.iconType.value == 1 && buttons.label === "Facebook") {
				this.props.widgetStore.toolBarPreviewButtonSettings[buttonsIndex].libraryIconClass = "facebook"
			} else if (buttons.iconType.value == 1 && buttons.label === "Twitter") {
				this.props.widgetStore.toolBarPreviewButtonSettings[buttonsIndex].libraryIconClass = "twitter"
			} else {
				this.props.widgetStore.toolBarPreviewButtonSettings[buttonsIndex].libraryIconClass = "";
			}

		}
	}

	setIcon(iconObj) {
		this.props.widgetStore.getIconFromLibrary = false;
		// this.props.widgetStore.toolBarPreviewButtonSettings[this.props.widgetStore.selectedButtonIndex].libraryIconClass = "HelpOnClick-" + iconObj.icon;
		this.props.widgetStore.toolBarPreviewButtonSettings[this.props.widgetStore.selectedButtonIndex].libraryIconClass = iconObj.icon;
	}

	addCustomButton() {
		this.props.widgetStore.addedButtonCount++;
		this.props.widgetStore.toolBarAddButton.id = this.props.widgetStore.toolBarAddButton.id + 1;
		this.props.widgetStore.toolBarPreviewButtonSettings.push(Object.assign({}, this.props.widgetStore.toolBarAddButton));
	}

	deleteCustomBtn = (customBtn) => {
		this.props.widgetStore.toolBarcustomButtonconform = true;
		this.props.widgetStore.toolBarcustomButton = customBtn;
		// this.props.widgetStore.addedButtonCount--;
		// //this.props.widgetStore.toolBarAddButton.id = this.props.widgetStore.toolBarAddButton.id-1;
		// const idToRemove = customBtn.id;
		// const filteredPeople = this.props.widgetStore.toolBarPreviewButtonSettings.filter((button) => button.id !== idToRemove);
		// this.props.widgetStore.toolBarPreviewButtonSettings = filteredPeople;
	}
	deleteCustomBtnConform = () => {
		this.props.widgetStore.addedButtonCount--;
		//this.props.widgetStore.toolBarAddButton.id = this.props.widgetStore.toolBarAddButton.id-1;
		const idToRemove = this.props.widgetStore.toolBarcustomButton.id;
		const filteredPeople = this.props.widgetStore.toolBarPreviewButtonSettings.filter((button) => button.id !== idToRemove);
		this.props.widgetStore.toolBarPreviewButtonSettings = filteredPeople;

		this.props.widgetStore.toolBarcustomButtonconform = false;
		this.props.widgetStore.toolBarcustomButton = {};
	}

	componentWillUnmount() {
	}


	//-- Croping Images --//
	verifyFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0]
			// console.log(currentFile);
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				this.props.widgetStore.warning_image_name = currentFile.name + ": " + this.props.appstore.getLanguageTrans("The uploaded file is too large. Max size: 10 Mb");
				this.props.widgetStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.props.widgetStore.warning_image_name = currentFile.name + ": " + this.props.appstore.getLanguageTrans("An internal server error occurred.");
				this.props.widgetStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}

	onSelectFile(e, index) {
		this.setState({ index: index })
		// this.settingsStore.agentDetails.selectedfile = e.target.files; 
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					// this.setState({ src: reader.result })
					this.props.widgetStore.cropimage.src = reader.result
				);
				reader.readAsDataURL(e.target.files[0]);
				this.setState({ actual_file: e.target.files[0] });
				this.props.widgetStore.imageCropModel = true;

			}
		}
	};
	onCropComplete = crop => {
		this.props.widgetStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) {
		if (this.props.widgetStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif';
			const mimetype = this.state.imagetype == 'image/png' ? 'image/png'
				: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
					: 'image/gif';
			this.state.fileMimeType = mimetype;
			const croppedImageUrl = await this.getCroppedImg(
				this.props.widgetStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			// this.setState({ croppedImageUrl });  
			// this.props.widgetStore.toolBarPreviewButtonSettings[this.state.index].croppedImgUrl = croppedImageUrl;

		}
	}

	handleClearToDefault = event => {
		this.props.widgetStore.imageCropModel = false;
		// this.settingsStore.agentDetails.selectedfile = "";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.props.widgetStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName, mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.props.widgetStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		// this.props.widgetStore.toolBarPreviewButtonSettings[this.state.index].iconUrl = myNewCroppedFile ;
		const formData = new FormData()
		formData.set('id', '100');
		this.state.images = myNewCroppedFile;
		let img = "";
		if (this.state.index == 0) {
			img = (this.props.widgetStore.chatresourceid == "" || this.props.widgetStore.chatresourceid == undefined) ? "" : this.props.widgetStore.chatresourceid;
		} else if (this.state.index == 1) {
			img = (this.props.widgetStore.emailresourceid == "" || this.props.widgetStore.emailresourceid == undefined) ? "" : this.props.widgetStore.emailresourceid;
		} else if (this.state.index == 2) {
			img = (this.props.widgetStore.callresourceid == "" || this.props.widgetStore.callresourceid == undefined) ? "" : this.props.widgetStore.callresourceid;
		} else if (this.state.index == 3) {
			img = (this.props.widgetStore.faccebookresourceid == "" || this.props.widgetStore.faccebookresourceid == undefined) ? "" : this.props.widgetStore.faccebookresourceid;
		} else if (this.state.index == 4) {
			img = (this.props.widgetStore.twitterresourceid == "" || this.props.widgetStore.twitterresourceid == undefined) ? "" : this.props.widgetStore.twitterresourceid;
		} else if (this.state.index == 5) {
			img = (this.props.widgetStore.customeOneresourceid == "" || this.props.widgetStore.customeOneresourceid == undefined) ? "" : this.props.widgetStore.customeOneresourceid;
		} else if (this.state.index == 6) {
			img = (this.props.widgetStore.customeTworesourceid == "" || this.props.widgetStore.customeTworesourceid == undefined) ? "" : this.props.widgetStore.customeTworesourceid;
		} else if (this.state.index == 7) {
			img = (this.props.widgetStore.customeThreeresourceid == "" || this.props.widgetStore.customeThreeresourceid == undefined) ? "" : this.props.widgetStore.customeThreeresourceid;
		}

		this.setState({ uploading: true })
		formData.append("fileMimeType", this.state.fileMimeType);
		formData.append("deleteresourceUid", img);
		formData.append("file", this.state.images);
		formData.append("actualfile", this.state.actual_file);
		formData.append("adjustType", "toolbarIcon");

		this.props.widgetStore.saveToolbarIcon(formData, this.props.appstore, this.state.index);


		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, mimetype);
		});
	}
	setcustomschedule = (selectedoption) => {
		let calls_availble = "";
		if (selectedoption.value == 3) {
			let textset = this.props.widgetStore.setCustomCallScheduletext();

			this.props.widgetStore.customeschedule = true;
			calls_availble = "We are available " + textset
		} else {

			if (selectedoption.value == 1) {
				calls_availble = this.props.widgetStore.callScheduleText[0].label;
			} else {
				calls_availble = this.props.widgetStore.callScheduleText[1].label;
			}

			this.props.widgetStore.customeschedule = false;
		}
		for (let i in this.props.widgetStore.toolBarPreviewButtonSettings) {
			if (this.props.widgetStore.toolBarPreviewButtonSettings[i].label == "Call") {
				this.props.widgetStore.toolBarPreviewButtonSettings[i].customText = calls_availble;
				break;
			}
		}
	}
	render() {
		var self = this;
		const vacss = {
			pointerEvents:"none",
			opacity: 0.3
		}
		const nocss = {};
		const { crop, croppedImageUrl, src } = this.props.widgetStore.cropimage;
		const { appstore,
			widgetStore, validation_btn } = this.props;
		if (this.props.widgetStore.iconnotLoaded) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}
		return (
			<div id="panelGroup" className="panelWrapper buttonSettingsWrapper">
				{
					widgetStore.toolBarPreviewButtonSettings.map(function (buttons, buttonsIndex) {
						return (
							<div className="scriptPanel panel row" key={buttonsIndex} style={{ zIndex: widgetStore.toolBarPreviewButtonSettings.length - buttonsIndex }}>
								<div className={"col-12 cursor-pointer panelHeading text-uppercase dropdown-toggle text-left " + (buttons.defaultOpen ? ' ' : ' collapsed ') + (buttons.label.toLowerCase() + "_tour")} data-toggle="collapse"
									data-target={"#" + (buttons.label != '' ? buttons.label : buttons.caption) + buttonsIndex} aria-expanded={buttons.defaultOpen ? true : false} aria-controls={buttons.label}>
									<p className="col-1"></p><p className="col-11">{buttons.label != '' ? appstore.getLanguageTrans(buttons.label) : appstore.getLanguageTrans(buttons.caption)}</p>
								</div>

								<div id={(buttons.label != '' ? buttons.label : buttons.caption) + buttonsIndex} className={"buttonsDataWrapper col-12 button_tour " + (buttons.defaultOpen ? 'show' : 'collapse')}>
									<div className="buttonsSection col-11">
										{buttons.id > 5 && <p className={"deleteCstm"} onClick={(event) => self.deleteCustomBtn(buttons)}>X</p>}
										<Form.Group as={Row} controlId="formGroupLead">
											<Form.Label column lg="3" sm="4" className="pr-0 chatWindow">{appstore.getLanguageTrans("Show button")}</Form.Label>
											<Col className="custom_radioBtn">
												<CheckBoxComponent radioValue={buttons.show}
													callBack={() => buttons.show = !buttons.show} />
											</Col>
										</Form.Group>
										{buttons.hasOwnProperty("hiddenOffline") &&
											<Form.Group as={Row} controlId="formGroupLead">
												<Form.Label column lg="3" sm="4" className="pr-0 chatWindow">{buttons.label == "Email" ? appstore.getLanguageTrans("Hide when online") : appstore.getLanguageTrans("Hide when offline")}</Form.Label>
												{appstore.userInfo.accountplan.hide_widget_offline !=1 &&
												<span data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top">
												<Col className="custom_radioBtn" style={vacss}>
													<CheckBoxComponent radioValue={buttons.hiddenOffline}
														callBack={() => buttons.hiddenOffline = !buttons.hiddenOffline}
														 />
												</Col>
												</span>}
												{appstore.userInfo.accountplan.hide_widget_offline !=0 &&
												
												<Col className="custom_radioBtn">
													<CheckBoxComponent radioValue={buttons.hiddenOffline}
														callBack={() => buttons.hiddenOffline = !buttons.hiddenOffline}
														 />
												</Col>
												}
											</Form.Group>
										}
										<Form.Group as={Row} controlId="formGroupMsg">
											<Form.Label column lg="3" sm="4" className={"pr-0"}>{appstore.getLanguageTrans("Caption")}</Form.Label>
											<Col sm="8" lg="9">
												<Form.Control type="text"
													name="caption" placeholder={appstore.getLanguageTrans("Caption")}
													value={buttons.caption}
													onChange={(e) => buttons.caption = e.target.value}
													maxLength={45} />
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupMsg">
											<Form.Label column lg="3" sm="4" className={"pr-0"}>{appstore.getLanguageTrans("Tooltip")}</Form.Label>
											<Col sm="8" lg="9">
												<Form.Control type="text"
													name="toolTip" placeholder={appstore.getLanguageTrans("ToolTip")}
													value={buttons.toolTip}
													onChange={(e) => buttons.toolTip = e.target.value}
													maxLength={45} />
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupMsg">
											<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Icon")}</Form.Label>
											<Col sm="8" lg="9" className="customDrpDwn forIcon">
												{buttons.id <= 5 &&
													<DropDownComponent dropDownOptions={widgetStore.toolBarButtonIcons}
														store={widgetStore}
														callBack={(selectedOption) => { buttons.iconType = selectedOption; self.selectIconType(buttons, buttonsIndex) }}
														selectedOption={buttons.iconType} />
												}
												{buttons.id > 5 &&
													<DropDownComponent dropDownOptions={widgetStore.toolBarCustomButtonIcons}
														store={widgetStore}
														callBack={(selectedOption) => { buttons.iconType = selectedOption; self.selectIconType(buttons, buttonsIndex) }}
														selectedOption={buttons.iconType} />
												}
												{
													buttons.iconType.value === 3 &&
													<div className="formBtns">
														<Button className="btn_save" variant="primary" onClick={() => self.selectIconType(buttons, buttonsIndex)}>Select Icon</Button>
													</div>
												}
												{
													buttons.iconType.value === 4 &&

													<div className="uploadBtn iconUpload">
														<Button variant="light" className="cursor-pointer btn_save uploadIcon">
															<p><img src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.upload_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.upload_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.upload_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.upload_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.upload_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.upload_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.upload_voilet :
																"#039be6" == appstore.selectedColor.color ? imageURLs.upload_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.upload_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.upload_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.upload_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.upload_skyblue : imageURLs.upload_steelblue} className="img-fluid" />{appstore.getLanguageTrans("Upload")}</p>
															{/* <input type = "file"/> */}
															<input ref={self.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={(e) => self.onSelectFile(e, buttonsIndex)} />
														</Button>&nbsp; &nbsp;
															{buttons.iconUrl && (
															<React.Fragment>
																<img alt="Crop" className="img-fluid" style={{ width: "100px", height: "100px", borderRadius: "5px" }} src={buttons.iconUrl} />
															</React.Fragment>
														)}
													</div>
												}
											</Col>
										</Form.Group>
										<Form.Group as={Row} controlId="formGroupMsg">
											<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Width")}</Form.Label>
											<Col sm="8" lg="9" className="customDrpDwn customDrpDwn-small">
												<DropDownComponent dropDownOptions={widgetStore.toolBarButtonWidth}
													store={widgetStore}
													callBack={(selectedOption) => { buttons.width = selectedOption }}
													selectedOption={buttons.width} />
												{
													buttons.width.value === 2 &&

													<div className="customSize">
														<Form.Group as={Row} controlId="formGroupMsg">
															<Col>
																<Form.Control type="text"
																	onKeyDown={(e) => { widgetStore.checkForEnteredNumer(e) }}
																	name="customWidth" placeholder=""
																	value={buttons.width.width}
																	onChange={(e) => { buttons.width.width = e.target.value }}
																	onBlur={(e) => { buttons.width.width = e.target.value > 300 ? 300 : e.target.value }} />
																<span>{appstore.getLanguageTrans("px (300px max)")}</span>
															</Col>
														</Form.Group>
													</div>
												}
											</Col>
										</Form.Group>
										{
											buttons.hasOwnProperty("skypeLogin") &&
											<Form.Group as={Row} controlId="formGroupMsg">
												<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Skype Login")}</Form.Label>
												<Col sm="8" lg="9">
													<Form.Control type="text"
														name="skypeLogin" placeholder=""
														value={buttons.skypeLogin}
														onChange={(e) => buttons.skypeLogin = e.target.value} />
												</Col>
											</Form.Group>
										}
										{
											buttons.showWhen &&
											<Form.Group as={Row} controlId="formGroupMsg">
												<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Show when")}</Form.Label>
												<Col sm="8" lg="9" className="customDrpDwn">
													<DropDownComponent dropDownOptions={widgetStore.buttonSettingsTimes}
														store={widgetStore}
														callBack={(selectedOption) => { buttons.showWhen = selectedOption; self.setcustomschedule(selectedOption) }}
														selectedOption={buttons.showWhen} />

													{(buttons.showWhen && widgetStore.customeschedule) &&
														<div className="scheduleformBtns">
															<Button className="btn_save" variant="primary" onClick={() => widgetStore.customescheduleset = true}>Set Custom Schedule</Button>
														</div>
													}
												</Col>
											</Form.Group>
										}
										{
											buttons.useCustomText !== undefined &&
											<Form.Group as={Row} controlId="formGroupLead">
												<Form.Label column lg="3" sm="4" className="pr-0 chatWindow">{appstore.getLanguageTrans("Use custom text")}</Form.Label>
												<Col className="custom_radioBtn">
													<CheckBoxComponent radioValue={buttons.useCustomText}
														callBack={() => buttons.useCustomText = !buttons.useCustomText} />
												</Col>
											</Form.Group>
										}
										{
											buttons.customText !== undefined && buttons.useCustomText &&
											<Form.Group as={Row} controlId="formGroupLead">
												<Form.Label column lg="3" sm="4" className="pr-0 chatWindow">{appstore.getLanguageTrans("Text")}</Form.Label>
												<Col sm="8" lg="9">
													<textarea name="customText" rows="4" value={buttons.customText} onChange={(e) => buttons.customText = e.target.value} />
												</Col>
											</Form.Group>
										}
										{
											buttons.faceBookId &&
											<Form.Group as={Row} controlId="formGroupMsg">
												<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Facebook Id")}</Form.Label>
												<Col sm="8" lg="9" className="customDrpDwn">
													<DropDownComponent dropDownOptions={widgetStore.fbOptions}
														store={widgetStore}
														callBack={(selectedOption) => buttons.faceBookId = selectedOption}
														selectedOption={buttons.faceBookId} />
												</Col>
											</Form.Group>
										}

										{
											buttons.faceBookId && buttons.faceBookId.value === 2 &&
											<Form.Group as={Row} controlId="formGroupMsg">
												<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Id")}</Form.Label>
												<Col sm="8" lg="9">
													<Form.Control type="text" className={widgetStore.toolBarFbButtonid && 'has-error'}
														name="fbid" placeholder=""
														value={buttons.fbid}
														onChange={(e) => buttons.fbid = e.target.value} />
												</Col>
											</Form.Group>
										}
										{
											buttons.widgetHtmlCode !== undefined &&
											<Form.Group as={Row} controlId="formGroupLead">
												<Form.Label column lg="3" sm="4" className="pr-0 chatWindow">{appstore.getLanguageTrans("Widget html code")}</Form.Label>
												<Col sm="8" lg="9">
													<textarea name="widgetHtmlCode" rows="4" value={buttons.widgetHtmlCode} onChange={(e) => buttons.widgetHtmlCode = e.target.value} />
												</Col>
											</Form.Group>
										}
										{
											buttons.accountId !== undefined &&
											<Form.Group as={Row} controlId="formGroupLead">
												<Form.Label column lg="3" sm="4" className="pr-0 chatWindow">{appstore.getLanguageTrans("Account ID")}</Form.Label>
												<Col sm="8" lg="9">
													<Form.Control type="text"
														name="fbid" placeholder=""
														value={buttons.accountId}
														onChange={(e) => buttons.accountId = e.target.value} />
												</Col>
											</Form.Group>
										}
										{
											buttons.widgetId !== undefined &&
											<Form.Group as={Row} controlId="formGroupLead">
												<Form.Label column lg="3" sm="4" className="pr-0 chatWindow">{appstore.getLanguageTrans("Widget ID")}</Form.Label>
												<Col sm="8" lg="9">
													<Form.Control type="text"
														name="fbid" placeholder=""
														value={buttons.widgetId}
														onChange={(e) => buttons.widgetId = e.target.value} />
												</Col>
											</Form.Group>
										}
										{
											buttons.contentType &&
											<Form.Group as={Row} controlId="formGroupMsg">
												<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Content type")}</Form.Label>
												<Col sm="8" lg="9" className="customDrpDwn">
													<DropDownComponent dropDownOptions={widgetStore.btnContentTypes}
														store={widgetStore}
														callBack={(selectedOption) => buttons.contentType = selectedOption}
														selectedOption={buttons.contentType} />
												</Col>
											</Form.Group>
										}
										{
											buttons.contentType && buttons.contentType.value === 1 &&
											<Form.Group as={Row} controlId="formGroupMsg">
												<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Frame text")}</Form.Label>
												<Col sm="8" lg="9" className="customDrpDwn">
													<TextEditor appstore={self.props.appstore} store={widgetStore} editorContent={buttons.contentType.data} />
												</Col>
											</Form.Group>
										}
										{
											buttons.contentType && buttons.contentType.value !== 1 &&
											<Form.Group as={Row} controlId="formGroupMsg">
												<Form.Label column lg="3" sm="4" className="pr-0">{appstore.getLanguageTrans("Url")}</Form.Label>
												<Col sm="8" lg="9" className="customDrpDwn">
													<Form.Control type="text"
														name="fbid" placeholder=""
														value={buttons.contentType.data}
														onChange={(e) => buttons.contentType.data = e.target.value} />
												</Col>
											</Form.Group>
										}
									</div>
								</div>

							</div>
						)
					})
				}{/*widgetStore.addedButtonCount < 3 &&	*/}
				{widgetStore.toolBarPreviewButtonSettings.length < 8 &&
					<div className="scriptPanel panel row" style={{ zIndex: widgetStore.toolBarPreviewButtonSettings.length - 1 }}>
						<div className="col-12 cursor-pointer panelHeading text-uppercase dropdown-toggle text-left collapsed" data-toggle="collapse"
							aria-expanded={false} onClick={() => this.addCustomButton()}>
							<p className="col-1"></p><p className="col-11">{appstore.getLanguageTrans("Create custom button")}</p>
						</div>
					</div>

				}

				{
					widgetStore.getIconFromLibrary &&
					<IconFontLibraryModal
						show={widgetStore.getIconFromLibrary}
						appStore={appstore}
						store={widgetStore}
						callBack={(selectedIcon) => this.setIcon(selectedIcon)} />
				}
				{widgetStore.imagewarning &&
					<ImageWarningPopUp show={widgetStore.imagewarning}
						store={widgetStore}
						cancel_btn="OK"
						closePopup={() => widgetStore.imagewarning = false}
						appStore={appstore} />
				}
				{widgetStore.imageCropModel &&
					<ImageCropComponent show={widgetStore.imageCropModel}
						store={widgetStore}
						cancel_btn="Clear"
						multi="0"
						crop_btn="Save"
						onCropComplete={() => this.onCropComplete(crop)}
						closePopup={() => this.handleClearToDefault()}
						appStore={appstore} />
				}
				{widgetStore.customescheduleset &&
					<SheduleTimeComponent show={widgetStore.customescheduleset}
						store={widgetStore}
						cancel_btn="Cancel"
						save_btn="Save"
						closePopup={() => widgetStore.customescheduleset = false}
						appStore={appstore} />
				}
				{widgetStore.toolBarcustomButtonconform &&
					<ConfirmationComponent show={widgetStore.toolBarcustomButtonconform}
						store={widgetStore}
						title="Delete custom button"
						body_html="<p>Really remove this custom button?</p>"
						cancel_btn="No"
						save_btn="Yes"
						callBack={() => this.deleteCustomBtnConform()}
						closePopup={() => widgetStore.toolBarcustomButtonconform = false}
						appStore={appstore} />
				}
			</div>
		)
	}
})
export default ButtonSettingsComponent;
