import { decorate, observable } from 'mobx';
import React, { Component } from 'react';

import imageURLs from '../../sharedFiles/ImagesURLs';
import { data } from '../../assets/JSON/menuData';

import navigationStore from '../../sharedFiles/navigation-store';


class SideMenuStore {

    constructor(appstore) {
        this.menuData = data;
        this.selectedMenu = this.menuData[0];
        this.subMenuList = [];
        this.submenuSelectedItem = "";
        this.appStore = appstore;
        this.logoDrpDown = this.getWesiteswithLogo(this.appStore.userInfo.allwebistes);
        this.selectedSite = this.logoDrpDown[0];
        this.menuloded = false;
    }

    getWesiteswithLogo(allwebsite,updated=false) {
        //alert('set selectedWebisiteUid');
        let index = 1;
        let nowebsite=[{}];
        if(allwebsite.length==0){
            return nowebsite;
        }
        for (let x in allwebsite) {
            // <div className="icon_wrapper customeicon_wrapper" ><div><img src={(allwebsite[x].logo != '' && allwebsite[x].logo != undefined) ? this.appStore.resourceurl + this.appStore.userInfo.accountsmodel.account_uid + "/" + allwebsite[x].logo  : imageURLs.logo}  alt="logo" className={(allwebsite[x].logo != '' && allwebsite[x].logo != undefined) ? "img-fluid imgweb" : "img-fluid"} /></div><div className="submenudropdwnp" title={allwebsite[x].name}>{allwebsite[x].name}</div></div>;
                                  
            allwebsite[x].label = <p className="icon_wrapper" ><img src={(allwebsite[x].logo != '' && allwebsite[x].logo != undefined) ? this.appStore.resourceurl + this.appStore.userInfo.accountsmodel.account_uid + "/" + allwebsite[x].logo  : imageURLs.logo}  alt="logo" className={(allwebsite[x].logo != '' && allwebsite[x].logo != undefined) ? "img-fluid imgweb" : "img-fluid"} /><p className="submenudropdwnp" title={allwebsite[x].name}>{allwebsite[x].name}</p></p>;
            allwebsite[x].value = index;
            allwebsite[x].websiteuid = allwebsite[x].website_uid;
            index++;
        }
        if(!updated){
            //for initail page laod
            //not for when user changes website option
        
         this.appStore.selectedWebisiteUid = allwebsite[0].websiteuid;
         this.appStore.selectedWebisiteLogo = allwebsite[0].logo;
         this.appStore.selectedWebisitename = allwebsite[0].name;
         this.appStore.dptoptforwebiste();
        }
        return allwebsite;
    }

    selectSite(option) {
        this.appStore.addNewSite = false;
        this.selectedSite = option;
        this.appStore.selectedWebisiteUid = this.selectedSite.websiteuid;
        this.appStore.dptoptforwebiste();

    }

    checkForSelectedMenu(appstore) {
        // alert(JSON.stringify( this.selectedMenu))
        // alert("appstore.menuActive   "+appstore.menuActive)
        var self = this;
        if (appstore.menuChanged && window.location.pathname === '/') {
            this.selectedMenu = {
                subMenu: [],
                menuId: 0,

            };
            appstore.menuActive = false;
            return;
        }
        this.menuData.map(function (menu, menuIndex) {
            if (menu.url !== "" && window.location.pathname.includes(menu.url)) {
                self.selectedMenu = menu;
                if (window.innerWidth > 768) { 
                    //appstore.menuActive = ((menu.subMenu.length !== 0 || menu.menuId === 2)) ? true : false;
                    if(menu.menuId == 4 && !appstore.editwebsite){
                        appstore.menuActive=false;
                    }else{
                        appstore.menuActive = ((menu.subMenu.length !== 0 || menu.menuId === 2)) ? true : false;
                    }
                }
                else {
                    appstore.menuActive = false;
                }
                if (self.selectedMenu.menuId === 1 || self.selectedMenu.menuId === 2) {
                    appstore.operatorMenuActive = true;
                }
                else {
                    appstore.operatorMenuActive = false;
                }
                menu.subMenu.map(function (submenu, submenuIndex) {
                    if (submenu.url !== "" && window.location.pathname.includes(submenu.url)) {
                        submenu.menuList.map(function (list, listIndex) {
                            if (list.url !== "" && window.location.pathname.includes(list.url)) {
                                self.submenuSelectedItem = list.listLabel;
                                submenu.active = true;
                                self.selectedMenu.menuOpenCount = 1;
                                submenu.openCount = 1;
                            }
                        })
                    }
                })
            }
        })
    }
    setMenuSelected(item) {
        this.selectedMenu = item;
    }

    navigate(item, appstore) {
        if (item.url !== "") {
            appstore.menuChanged = item.url;
            navigationStore.push(item.url);

            if (item.hasOwnProperty("listLabel")) {
                //change status if users directly click on widget tab option
                if (item.listLabel == "widgets")
                    appstore.addNewSite = false;
            }
            this.checkForSelectedMenu(appstore);
        }
    }
}

decorate(SideMenuStore, {
    menuData: observable,
    selectedMenu: observable,
    subMenuList: observable,
    submenuSelectedItem: observable,
    selectedSite: observable,
    menuloded: observable
})
export default SideMenuStore;