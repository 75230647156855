import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import navigationStore from '../../../../sharedFiles/navigation-store';

import WidgetCarouselComponent from './components/widgetsCarousel/WidgetsCarouselComponent';
import DirectLinkBuilderComponent from './components/DirectLinkBuilderComponent';
import WidgetBuilderComponent from './components/WidgetBuilderComponent';
import IconBuilderComponent from './components/IconBuilderComponent';
import WidgetIconBuilderComponent from './components/WidgetIconBuilderComponent';
import ToolBarComponent from './components/ToolBarComponent';

import WidgetStore from './WidgetStore';

import TourGuide from '../../../../sharedComponents/TourGuide';
import Tour from "react-product-intro"; 

const WidgetComponent = observer(class WidgetComponent extends Component {

	constructor (props) {
        super(props);
		this.widgetStore = new WidgetStore(); 
	    this.state = {
			// tourStep:4
			tourStep:0
	    }
	}

	componentDidUpdate(prevProps, prevState) { 
		//this.props.appstore.helpTourStarted
		if(this.props.appstore.helpTourStarted){
		  if (prevState.tourStep !== this.props.appstore.selectedtourStep) { 
				let stepNo = this.props.appstore.selectedtourStep;
				//check which tour step is selected
				if(!stepNo)
				return;

				stepNo = stepNo.slice(4);
				 //alert("widget component : "+stepNo)
				  
				if(stepNo == 2){
					// if(stepNo == 5){
				  this.widgetStore.selectedWidget=1;
				  this.widgetStore.getCode=true
				}
				if(stepNo == 3){  
					// if(stepNo == 6){ 
					this.widgetStore.selectedWidget=1;
					this.widgetStore.getCode=true
				  }
				  if(stepNo == 4){ 
					// if(stepNo == 7){  
					// this.widgetStore.selectedWidget=1;
					this.widgetStore.getCode=true
				  }
				//   if(stepNo == 5){   
				// 	//this.widgetStore.selectedWidget=1; 
				// 	this.widgetStore.getCode=true
				//   }if(stepNo == 6){
				// 	this.widgetStore.getCode=true
				//   }
				//   if(stepNo == 7){  
				// 	this.widgetStore.getCode=true
				// 	this.widgetStore.selectedWidget=1; 
				//   }
				// else if(stepNo == 6)
				//   this.widgetStore.selectedToolBarSetting =2;
				// else if(stepNo == 8)
				//   this.widgetStore.selectedToolBarSetting =3;
				// else if(stepNo == 10)
				//   this.widgetStore.selectedToolBarSetting =4;
				// else if(stepNo == 11)
				//   this.widgetStore.toolBarInvitationSettings.invitationType =2;
				// else if(stepNo == 12){
				//   this.widgetStore.getImageFromLibrary = true;
				// }else if(stepNo == 13){
				// 	this.widgetStore.getImageFromLibrary = false;
					
				// }
				// else if(stepNo == 15){
				// 	this.widgetStore.getImageFromLibrary = false;
				// 	this.widgetStore.getCode=true
				// }
				this.setState({tourStep:this.props.appstore.selectedtourStep});  		 
	        }
		} 
		
	}
	
  

	componentDidMount(){
		this.props.appstore.assistedit=false;
		this.widgetStore.loadCustomlibrary(this.props.appstore);
//this.props.appstore.helpTourStarted
	if(this.props.appstore.helpTourStarted)	{
		this.widgetStore.setwidgetTourStep(this.props.appstore);
		this.widgetStore.widgettourStart = true;
	}


		this.props.appstore.menuChanged = window.location.pathname; 
		let value = window.location.href.split("?");
		this.widgetStore.selectedWidget = value[1] ? JSON.parse(value[1]) : 0;
		//this.props.widgetStore.defaultmenuPath ="/admin/setup/widgets"; 


		var defulatSchedule="{\"1\":{\"sh\":9,\"sm\":0,\"th\":17,\"tm\":0},\"2\":{\"sh\":9,\"sm\":0,\"th\":17,\"tm\":0},\"3\":{\"sh\":9,\"sm\":0,\"th\":17,\"tm\":0},\"4\":{\"sh\":9,\"sm\":0,\"th\":15,\"tm\":0},\"5\":{\"sh\":9,\"sm\":0,\"th\":15,\"tm\":0},\"6\":{\"sh\":9,\"sm\":0,\"th\":15,\"tm\":0}}"
		let schedulelist = JSON.parse(defulatSchedule);
		// let schedulelist = JSON.parse(this.props.appstore.userInfo.usersmodel.sms_schedule);
		this.showSchedule(schedulelist);
		
		
	}
	showSchedule = (schedulelist) => {

		let i;
		//convert object into time format	
		for (i in schedulelist) {
			schedulelist[i].sh = schedulelist[i].sh == 0 ? "00" : schedulelist[i].sh;
			schedulelist[i].sm = schedulelist[i].sm == 0 ? "00" : schedulelist[i].sm;
			schedulelist[i].th = schedulelist[i].th == 0 ? "00" : schedulelist[i].th;
			schedulelist[i].tm = schedulelist[i].tm == 0 ? "00" : schedulelist[i].tm;

			if (schedulelist[i].sh != "00" && schedulelist[i].sh.toString().length == 1)
				schedulelist[i].sh = "0" + schedulelist[i].sh;

			if (schedulelist[i].sm != "00" && schedulelist[i].sm.toString().length == 1)
				schedulelist[i].sm = "0" + schedulelist[i].sm;

			if (schedulelist[i].th != "00" && schedulelist[i].th.toString().length == 1)
				schedulelist[i].th = "0" + schedulelist[i].th;

			if (schedulelist[i].tm != "00" && schedulelist[i].tm.toString().length == 1)
				schedulelist[i].tm = "0" + schedulelist[i].tm;

			schedulelist[i] = { startTime: schedulelist[i].sh + ":" + schedulelist[i].sm, EndTime: schedulelist[i].th + ":" + schedulelist[i].tm }
		}

		let index;
		for (index in this.widgetStore.weekDays) {
			if (schedulelist[index] != undefined) {
				this.widgetStore.weekDays[index].startTime = schedulelist[index].startTime;
				this.widgetStore.weekDays[index].EndTime = schedulelist[index].EndTime; 
			} else {
				this.widgetStore.weekDays[index].selected = false;
			}
		} 

		this.widgetStore.clearweekDays=JSON.parse(JSON.stringify(this.widgetStore.weekDays));

		let keys = { text:"xc", type:"custom",schedule:{}}
            let schedule={}
		for (let i = 0; i < this.widgetStore.weekDays.length; i++) { 
            if(this.widgetStore.weekDays[i].selected){
                schedule[this.widgetStore.weekDays[i].value]=this.widgetStore.weekDays[i].startTime+"-"+this.widgetStore.weekDays[i].EndTime ;
            }
        }
        keys.schedule=schedule; 
	 this.widgetStore.weekDaysschedule=keys;
	 this.widgetStore.clearweekDaysschedule=keys;
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
    }

	render(){
		var self = this;
		const { appstore, menuActive } = this.props;

		return (
			<div key={appstore.selectedtourStep} className="innercontent_wrapper w-100 widgetWrapper">
				<div className="header_title row align-items-center m-0">
						<img src={imageURLs.widgetPageIcon} alt="faq" className="img-fluid"/>
						<h4 className="text-left widgetName" style={{color:appstore.selectedColor.color}}>{appstore.getLanguageTrans("Widgets")}</h4>
						<img src={imageURLs.sectionIcon} alt="faq" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("Add New Widget")}</h4>
						<p className="float-right exit_btn" onClick={() => navigationStore.push('/admin/setup/widgets')}>
							<img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.exit_steelblue2 : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.exit_seagreen2 : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.exit_pink2 : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.exit_brown2 : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.exit_navyblue2 : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.exit_yellow2 : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.exit_voilet2 : 
									"#039be6" == this.props.appstore.selectedColor.color ? imageURLs.exit_blue2 : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.exit_darkgreen2 : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.exit_green2 : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.exit_grey2 : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.exit_skyblue2 : imageURLs.exit_steelblue2} className="img-fluid"/>
							<span style={{color:appstore.selectedColor.color}}>{appstore.getLanguageTrans("Exit")}</span>
						</p>
				</div>
				<div className="row widgetWrapper">
					<WidgetCarouselComponent widgetStore={this.widgetStore} appstore={appstore} />
					
					{
						this.widgetStore.selectedWidget === 0 && 
							<div className="widgetSelectorIndicator">
								<img src={imageURLs.selectIcon} className="img-fluid" />
								<p>{appstore.getLanguageTrans("Select a Widget to Add")}</p>
							</div>
					}
					{
						this.widgetStore.selectedWidget === 1 &&
							<WidgetBuilderComponent appstore={appstore}  widgetStore={this.widgetStore}/>
					}

					{
						this.widgetStore.selectedWidget === 2 &&
							<ToolBarComponent appstore={appstore} widgetStore={this.widgetStore}/>
					}

					{
						this.widgetStore.selectedWidget === 3 &&
							<IconBuilderComponent appstore={appstore} widgetStore={this.widgetStore}/>
					}

					{
						this.widgetStore.selectedWidget === 4 &&
							<DirectLinkBuilderComponent appstore={appstore} widgetStore={this.widgetStore}/>
					}

					{
						this.widgetStore.selectedWidget === 5 &&
							<WidgetIconBuilderComponent appstore={appstore} widgetStore={this.widgetStore}/>
					}
					
				</div>

				   {
					  this.widgetStore.widgettourStart && 
						<TourGuide 
						store={this.widgetStore}
						appStore = {appstore}
						tourState = {this.widgetStore.tourState}
						/>
						// <Tour
						// active={this.widgetStore.widgettourStart}
						// step={this.state.tourStep}
						// onNext={(step) => step==8 ? (this.widgetStore.widgettourStart=false,appstore.navigate('/traffic')) : step>4 ? (this.widgetStore.widgettourStart=false,this.props.appstore.selectedtourStep=step,this.setState({tourStep: step})):this.setState({tourStep: step})}
						// onBack={(step) => this.setState({tourStep: step})}
						// onCancel={() => {appstore.helpTourStarted = false;this.widgetStore.widgettourStart=false}}
						// steps = {appstore.tourstesps} />
					}
			</div>
		)
	}
})
export default WidgetComponent;
