import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CustomScroll from 'react-custom-scroll';
import { CompactPicker } from 'react-color';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';
import CheckBoxComponent from '../../../../../../sharedComponents/checkBox/checkBox';
import DropDownComponent from '../../../../../../sharedComponents/dropDown/dropDown';
import RadioButtonComponent from '../../../../../../sharedComponents/radionButton2/RadioButton';

import InvitationImageLibraryModal from './InvitationImageLibraryModal';
import ImageCropComponent from '../../../../../../sharedComponents/imageCropPopUp/ImageCropComponent';
import ImageWarningPopUp from '../../../../../../sharedComponents/imageCropPopUp/ImageWarningPopUp';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })
const ClickOutHandler = require('react-onclickout');

const InvitationSettingsComponent = observer(class InvitationSettingsComponent extends Component {

	constructor(props) {
		super(props);
		this.pickTextColorBasedOnBgColorAdvanced = this.pickTextColorBasedOnBgColorAdvanced.bind(this);
		this.fileInputRef = React.createRef();
		this.state = {
			uploading: false,
			actual_file: "",
			images: ""
		}
		this.props.widgetStore.loadCustomlibrary(this.props.appstore);
	}

	componentDidMount() { 
		if(!this.props.widgetStore.toobaredit){
			if(this.props.widgetStore.toolBarInvitationSettings.invitationType==1){
				this.props.widgetStore.invitationprieviewimg=this.props.appstore.imgpath + "op/images/default_logo.png";
			}
		}
		
	}

	setImage(iconObj) { 
		this.props.widgetStore.getImageFromLibrary = false;
		// this.props.widgetStore.invitationprieviewimg = iconObj.img
		// this.props.widgetStore.toolBarInvitationSettings.customImageUrl = iconObj.img
		this.props.widgetStore.invitationprieviewimg = iconObj.value
		this.props.widgetStore.toolBarInvitationSettings.customImageUrl = iconObj.value
	}

	pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
		if (bgColor) {
			var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
			var r = parseInt(color.substring(0, 2), 16); // hexToR
			var g = parseInt(color.substring(2, 4), 16); // hexToG
			var b = parseInt(color.substring(4, 6), 16); // hexToB
			var uicolors = [r / 255, g / 255, b / 255];
			var c = uicolors.map((col) => {
				if (col <= 0.03928) {
					return col / 12.92;
				}
				return Math.pow((col + 0.055) / 1.055, 2.4);
			});
			var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
			return (L > 0.298) ? darkColor : lightColor;
		}
	}

	componentWillUnmount() {
	}

	//-- Croping Images --//
	verifyFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0]
			// console.log(currentFile);
			const currentFileType = currentFile.type
			this.setState({ imagetype: currentFileType })
			const currentFileSize = currentFile.size
			if (currentFileSize > imageMaxSize) {
				this.props.widgetStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("The uploaded file is too large. Max size: 10 Mb");
				this.props.widgetStore.imagewarning = true;
				// alert("This file is not allowed. " + currentFileSize + " bytes is too large")
				return false
			}
			if (!acceptedFileTypesArray.includes(currentFileType)) {
				this.props.widgetStore.warning_image_name = currentFile.name + ": "+this.props.appstore.getLanguageTrans("An internal server error occurred.");
				this.props.widgetStore.imagewarning = true;
				// alert("This file is not allowed. Only images are allowed.")
				return false
			}
			return true
		}
	}
	setdefultimg = (types) =>{
		if(types.id==="default"){
			//this.props.widgetStore.invitationprieviewimg=this.props.appstore.imgpath + "op/images/default_logo.png";
			this.props.widgetStore.invitationprieviewimg=this.props.appstore.otherIcons + "images/default_logo.png";
		}else{
			this.props.widgetStore.invitationprieviewimg="";
		}
	}
	onSelectFile(e, index) {
		this.setState({ index: index })
		// this.settingsStore.agentDetails.selectedfile = e.target.files; 
		const files = e.target.files
		if (files && files.length > 0) {
			const isVerified = this.verifyFile(files)
			if (isVerified) {
				const reader = new FileReader();
				reader.addEventListener('load', () =>
					// this.setState({ src: reader.result })
					this.props.widgetStore.cropimage.src = reader.result
				);
				reader.readAsDataURL(e.target.files[0]);
				this.setState({ actual_file: e.target.files[0] });
				this.props.widgetStore.imageCropModel = true;

			}
		}
	};
	onCropComplete = crop => {
		this.props.widgetStore.imageCropModel = false
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) {
		if (this.props.widgetStore.imageRef && crop.width && crop.height) {
			const imagetypes = this.state.imagetype == 'image/png' ? 'newFile.png'
				: this.state.imagetype == 'image/jpg' ? 'newFile.jpg' : this.state.imagetype == 'image/jpeg' ? 'newFile.jpg'
					: 'newFile.gif';
			const mimetype = this.state.imagetype == 'image/png' ? 'image/png'
				: this.state.imagetype == 'image/jpg' ? 'image/jpg' : this.state.imagetype == 'image/jpeg' ? 'image/jpg'
					: 'image/gif';
			this.state.fileMimeType = mimetype;
			const croppedImageUrl = await this.getCroppedImg(
				this.props.widgetStore.imageRef,
				crop,
				imagetypes,
				mimetype
			);
			// this.setState({ croppedImageUrl });  
			// this.props.widgetStore.toolBarPreviewButtonSettings[this.state.index].croppedImgUrl = croppedImageUrl;

		}
	}

	handleClearToDefault = event => {
		this.props.widgetStore.imageCropModel = false;
		// this.settingsStore.agentDetails.selectedfile = "";
		if (event) event.preventDefault()
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height)

		this.props.widgetStore.cropimage = {
			src: null,
			crop: {
				unit: '%',
				width: 50,
				aspect: 1 / 1,
			},
			croppedImageUrl: null,
		}
		this.fileInputRef.current.value = null
	}

	getCroppedImg(image, crop, fileName, mimetype) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const imageData64 = canvas.toDataURL('image/' + this.props.widgetStore.cropimage)
		const myFilename = fileName
		// file to be uploaded
		const myNewCroppedFile = this.props.appstore.base64StringtoFile(imageData64, myFilename)
		// this.props.widgetStore.toolBarPreviewButtonSettings[this.state.index].iconUrl = myNewCroppedFile ;
		const formData = new FormData()
		formData.set('id', '100');
		this.state.images = myNewCroppedFile;
		let img = "";
		if (this.state.index == 1) {
			img = (this.props.widgetStore.invitationimgUrl == "" || this.props.widgetStore.invitationimgUrl == undefined) ? "" : this.props.widgetStore.invitationimgUrl;
			// this.props.widgetStore.invitationprieviewimg = (this.props.widgetStore.invitationimgUrl == "" || this.props.widgetStore.invitationimgUrl == undefined) ? "" : this.props.widgetStore.invitationimgUrl;
		}
		if (this.state.index == 2) {
			img = (this.props.widgetStore.invitationCustUrl == "" || this.props.widgetStore.invitationCustUrl == undefined) ? "" : this.props.widgetStore.invitationCustUrl;
			
		}


		this.setState({ uploading: true })
		formData.append("fileMimeType", this.state.fileMimeType);
		formData.append("deleteresourceUid", img);
		formData.append("file", this.state.images);
		formData.append("actualfile", this.state.actual_file);
		formData.append("adjustType", "toolbarInvitation");

		this.props.widgetStore.saveToolbarIcon(formData, this.props.appstore, this.state.index);


		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, mimetype);
		});
	}
	resetinvitaionimageprev = (e) =>{ 
		if(e==1){
			if(this.props.widgetStore.toolBarInvitationSettings.invitationImage.value==1){
				this.props.widgetStore.invitationprieviewimg=this.props.appstore.imgpath + "op/images/default_logo.png";
			}else if(this.props.widgetStore.toolBarInvitationSettings.invitationImage.value==2){
				this.props.widgetStore.invitationprieviewimg=this.props.widgetStore.toolBarInvitationSettings.url;
			}else{
				this.props.widgetStore.invitationprieviewimg="";
			}
			
		}else{
			this.props.widgetStore.invitationprieviewimg=this.props.widgetStore.toolBarInvitationSettings.customImageUrl;
		}
	}
	resetstyles = (e) =>{
		if(e==1){
			this.props.widgetStore.toolBarInvitationSettings.bgColor= '#eeeeee';
			this.props.widgetStore.toolBarInvitationSettings.bgTextColor= '#272727';
			this.props.widgetStore.toolBarInvitationSettings.buttonBgColor= '#dddddd';
			this.props.widgetStore.toolBarInvitationSettings.buttonBgTextColor= '#272727';
		}
	}

	render() {

		var self = this;
		const { crop, croppedImageUrl, src } = this.props.widgetStore.cropimage;
		const { appstore,
			widgetStore,
			validation } = this.props;

		return (
			<React.Fragment>
				<form>
					<Form.Group as={Row} className="invitation_customimage_tour" controlId="formGroupLead">
						<Form.Label column lg="5" sm="6" className="pr-0 chatWindow">{appstore.getLanguageTrans("Invitation type")}</Form.Label>
						<Col sm="6" lg="7" className="chat_windowWrapper">
							<div className="row m-0">
								<RadioButtonComponent selectedOption={widgetStore.toolBarInvitationSettings.invitationType}
									options={widgetStore.invitationTypes}
									groupName={"invitationType"}
									callBack={(selectedOption) => { widgetStore.toolBarInvitationSettings.invitationType = selectedOption;this.resetinvitaionimageprev(selectedOption) }}
									 />
							</div>
						</Col>
					</Form.Group>

					{
						widgetStore.toolBarInvitationSettings.invitationType === 1 &&
						<Form.Group as={Row} controlId="formGroupMsg">
							<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Invitation image")}</Form.Label>
							<Col sm="6" lg="7" className="customDrpDwn">
								<DropDownComponent dropDownOptions={widgetStore.invitationImageTypes}
									store={widgetStore}
									callBack={(selectedOption) => {widgetStore.toolBarInvitationSettings.invitationImage = selectedOption;self.setdefultimg(selectedOption)}}
									selectedOption={widgetStore.toolBarInvitationSettings.invitationImage} />
							</Col>
						</Form.Group>
					}

					{
						widgetStore.toolBarInvitationSettings.invitationType === 1 && widgetStore.toolBarInvitationSettings.invitationImage.value === 2 &&
						<Form.Group as={Row} controlId="formGroupMsg" >
							<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Url")}</Form.Label>
							<Col sm="6" lg="7" className="with_btn">
								<Form.Control type="text"
									name="url" placeholder=""
									value={widgetStore.toolBarInvitationSettings.url}
									onChange={(e) => widgetStore.toolBarInvitationSettings.url = e.target.value} />
								<div className="uploadBtn iconUpload">
									<Button variant="light" className="cursor-pointer btn_cancel uploadIcon">
										<p  className="cursor-pointer" style={{color:"#fff !important"}}>{appstore.getLanguageTrans("Upload")}</p>
										{/* <input type = "file"/> */}
										<input ref={self.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={(e) => self.onSelectFile(e, widgetStore.toolBarInvitationSettings.invitationType)} />
									</Button>
								</div>
							</Col>
						</Form.Group>
					}

					{
						widgetStore.toolBarInvitationSettings.invitationType === 2 &&
						<React.Fragment>
							<Form.Group as={Row} controlId="formGroupMsg" className="invitationCustomImg">
								<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Custom image URL")}</Form.Label>
								<Col sm="6" lg="7" className="with_btn">
									<Form.Control type="text"
										name="customImageUrl" placeholder=""
										value={widgetStore.toolBarInvitationSettings.customImageUrl}
										onChange={(e) => widgetStore.toolBarInvitationSettings.customImageUrl = e.target.value} />

								</Col>
							</Form.Group>
							<div className="row">
								<div className="col-6 col-lg-5"></div>
								<div className="btn_wrapper col-6 col-lg-7">
									<div className="uploadBtn iconUpload">
										<Button variant="light" className="cursor-pointer btn_cancel uploadIcon">
											<p className="cursor-pointer" >{appstore.getLanguageTrans("Upload")}</p>
											{/* <input type = "file"/> */}
											<input ref={self.fileInputRef} multiple={false} type="file" accept={acceptedFileTypes} id="file" onChange={(e) => self.onSelectFile(e, widgetStore.toolBarInvitationSettings.invitationType)} />
										</Button>
									</div>
									<div className="uploadBtn iconUpload">
										<Button variant="light" className="cursor-pointer btn_cancel uploadIcon" onClick={() => widgetStore.getImageFromLibrary = true}>
											<p>{appstore.getLanguageTrans("Choose from library")}</p>
										</Button>
									</div>
								</div>
							</div>
						</React.Fragment>
					}
					{
						widgetStore.toolBarInvitationSettings.invitationType === 2 &&
						<div className="customSize">
							<Form.Group as={Row} controlId="formGroupMsg">
								<Form.Label column lg="5" sm="6" className="pr-0">{appstore.getLanguageTrans("Margin from toolbar")}</Form.Label>
								<Col sm="6" lg="7">
									<Form.Control type="text"
										onKeyDown={(e) => { widgetStore.checkForEnteredNumer(e) }}
										name="customHeight" placeholder=""
										value={widgetStore.toolBarInvitationSettings.margin}
										onChange={(e) => widgetStore.toolBarInvitationSettings.margin = e.target.value}
										onBlur={(e) => widgetStore.toolBarInvitationSettings.margin.trim()=="" ? (widgetStore.toolBarInvitationSettings.margin="0"):widgetStore.toolBarInvitationSettings.margin} />
									<span>px</span>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formGroupMsg">
								<Form.Label column lg="5" sm="6" className="pr-0">{widgetStore.toolBarPreviewSettings.orientation=="vertical" ? appstore.getLanguageTrans("Top margin"): widgetStore.toolBarPreviewSettings.position=="left bottom" ? appstore.getLanguageTrans("left margin") : appstore.getLanguageTrans("right margin") }</Form.Label>
								<Col sm="6" lg="7">
									<Form.Control type="text"
										onKeyDown={(e) => { widgetStore.checkForEnteredNumer(e) }}
										name="customHeight" placeholder=""
										value={widgetStore.toolBarInvitationSettings.leftMargin}
										onChange={(e) => widgetStore.toolBarInvitationSettings.leftMargin = e.target.value}
										onBlur={(e) => widgetStore.toolBarInvitationSettings.leftMargin.trim()=="" ? (widgetStore.toolBarInvitationSettings.leftMargin="0") : widgetStore.toolBarInvitationSettings.leftMargin} />
									<span>px</span>
								</Col>
							</Form.Group>
						</div>
					}
					{
						widgetStore.toolBarInvitationSettings.invitationType === 1 &&
						<Form.Group as={Row} controlId="formGroupLead">
							<Form.Label column lg="5" sm="6" className="pr-0 chatWindow">{appstore.getLanguageTrans("Styles")}</Form.Label>
							<Col sm="6" lg="7" className="chat_windowWrapper">
								<div className="row m-0">
									<RadioButtonComponent selectedOption={widgetStore.toolBarInvitationSettings.styles}
										options={widgetStore.invitationStyles}
										groupName={"styles"}
										callBack={(selectedOption) => { widgetStore.toolBarInvitationSettings.styles = selectedOption;this.resetstyles(selectedOption) }} />
								</div>
							</Col>
						</Form.Group>
					}
					{
						widgetStore.toolBarInvitationSettings.invitationType === 1 && widgetStore.toolBarInvitationSettings.styles === 2 &&
						<React.Fragment>
							<Form.Group as={Row} controlId="formGroupLead">
								<Form.Label column lg="5" sm="6" className="pr-0 chatWindow">{appstore.getLanguageTrans("Background color")}</Form.Label>
								<Col sm="6" lg="7" className="invitationColorPicker">
									<ClickOutHandler onClickOut={() => widgetStore.bgColorSelector = false}>
										<div className="css-10nd86i text-left colorSelector" style={{border:widgetStore.inv_bgColorerror? "2px solid rgb(255, 5, 5)":""}}>
											<div className="css-vj8t7z" style={{ backgroundColor: widgetStore.toolBarInvitationSettings.bgColor }}>
												<div className="css-1hwfws3">
													<div className="css-1g6gooi">
														<div className="">
															<input autoComplete="off"
																autoCorrect="off"
																id="react-select-9-input"
																spellCheck="false" tabIndex="0" type="text"
																aria-autocomplete="list"
																value={widgetStore.toolBarInvitationSettings.bgColor}
																title={widgetStore.inv_bgColorerror ?'color must be in a hex format #FFFFFF':""}
																onChange={(event) => widgetStore.toolBarInvitationSettings.bgColor = event.target.value}
																onBlur={(event)=> widgetStore.checkhexapattern(event.target.value,5)}
																style={{ backgroundColor: widgetStore.toolBarInvitationSettings.bgColor, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarInvitationSettings.bgColor, '#FFFFFF', '#000000') }}
															/>

														</div>
													</div>
												</div>
												<div className="css-1wy0on6" onClick={() => { widgetStore.bgColorSelector = !widgetStore.bgColorSelector }}>
													<span className="css-d8oujb"></span>
													<div aria-hidden="true" className="css-1ep9fjw">
														<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
															<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
														</svg>
													</div>
												</div>
											</div>
										</div>
										{
											widgetStore.bgColorSelector &&
											<CompactPicker className="colorSelector"
												color={widgetStore.toolBarInvitationSettings.bgColor}
												onChangeComplete={(color, event) => { widgetStore.toolBarInvitationSettings.bgColor = color.hex; widgetStore.toolBarInvitationSettings.bgColor = color.hex }} />
										}

									</ClickOutHandler>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formGroupLead">
								<Form.Label column lg="5" sm="6" className="pr-0 chatWindow">{appstore.getLanguageTrans("Text color")}</Form.Label>
								<Col sm="6" lg="7" className="invitationColorPicker">
									<ClickOutHandler onClickOut={() => widgetStore.textColorSelector = false}>
										<div className="css-10nd86i text-left colorSelector" style={{border:widgetStore.inv_bgtextColorerror? "2px solid rgb(255, 5, 5)":""}}>
											<div className="css-vj8t7z" style={{ backgroundColor: widgetStore.toolBarInvitationSettings.bgTextColor }}>
												<div className="css-1hwfws3">
													<div className="css-1g6gooi">
														<div className="">
															<input autoComplete="off"
																autoCorrect="off"
																id="react-select-9-input"
																spellCheck="false" tabIndex="0" type="text"
																aria-autocomplete="list"
																value={widgetStore.toolBarInvitationSettings.bgTextColor}
																title={widgetStore.inv_bgtextColorerror ?'color must be in a hex format #FFFFFF':""}
																onChange={(event) => widgetStore.toolBarInvitationSettings.bgTextColor = event.target.value}
																onBlur={(event)=> widgetStore.checkhexapattern(event.target.value,6)}
																style={{ backgroundColor: widgetStore.toolBarInvitationSettings.bgTextColor, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarInvitationSettings.bgTextColor, '#FFFFFF', '#000000') }}
															/>

														</div>
													</div>
												</div>
												<div className="css-1wy0on6" onClick={() => { widgetStore.textColorSelector = !widgetStore.textColorSelector; widgetStore.bgColorSelector = false }}>
													<span className="css-d8oujb"></span>
													<div aria-hidden="true" className="css-1ep9fjw">
														<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
															<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
														</svg>
													</div>
												</div>
											</div>
										</div>
										{
											widgetStore.textColorSelector &&
											<CompactPicker className="colorSelector"
												color={widgetStore.toolBarInvitationSettings.bgTextColor}
												onChangeComplete={(color, event) => { widgetStore.toolBarInvitationSettings.bgTextColor = color.hex; widgetStore.toolBarInvitationSettings.bgTextColor = color.hex }} />
										}

									</ClickOutHandler>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formGroupLead">
								<Form.Label column lg="5" sm="6" className="pr-0 chatWindow">{appstore.getLanguageTrans("Button background color")}</Form.Label>
								<Col sm="6" lg="7" className="invitationColorPicker">
									<ClickOutHandler onClickOut={() => widgetStore.buttonBgColorSelector = false}>
										<div className="css-10nd86i text-left colorSelector" style={{border:widgetStore.inv_tootltipbgColorerror? "2px solid rgb(255, 5, 5)":""}}>
											<div className="css-vj8t7z" style={{ backgroundColor: widgetStore.toolBarInvitationSettings.buttonBgColor }}>
												<div className="css-1hwfws3">
													<div className="css-1g6gooi">
														<div className="">
															<input autoComplete="off"
																autoCorrect="off"
																id="react-select-9-input"
																spellCheck="false" tabIndex="0" type="text"
																aria-autocomplete="list"
																value={widgetStore.toolBarInvitationSettings.buttonBgColor}
																title={widgetStore.inv_tootltipbgColorerror ?'color must be in a hex format #FFFFFF':""}
																onChange={(event) => widgetStore.toolBarInvitationSettings.buttonBgColor = event.target.value}
																onBlur={(event)=> widgetStore.checkhexapattern(event.target.value,7)}
																style={{ backgroundColor: widgetStore.toolBarInvitationSettings.buttonBgColor, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarInvitationSettings.buttonBgColor, '#FFFFFF', '#000000') }}
															/>

														</div>
													</div>
												</div>
												<div className="css-1wy0on6" onClick={() => { widgetStore.buttonBgColorSelector = !widgetStore.buttonBgColorSelector }}>
													<span className="css-d8oujb"></span>
													<div aria-hidden="true" className="css-1ep9fjw">
														<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
															<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
														</svg>
													</div>
												</div>
											</div>
										</div>
										{
											widgetStore.buttonBgColorSelector &&
											<CompactPicker className="colorSelector"
												color={widgetStore.toolBarInvitationSettings.buttonBgColor}
												onChangeComplete={(color, event) => { widgetStore.toolBarInvitationSettings.buttonBgColor = color.hex }} />
										}

									</ClickOutHandler>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formGroupLead">
								<Form.Label column lg="5" sm="6" className="pr-0 chatWindow">{appstore.getLanguageTrans("Button text color")}</Form.Label>
								<Col sm="6" lg="7" className="invitationColorPicker">
									<ClickOutHandler onClickOut={() => widgetStore.buttonTextColorSelector = false}>
										<div className="css-10nd86i text-left colorSelector" style={{border:widgetStore.inv_tootltipbgtxtColorerror? "2px solid rgb(255, 5, 5)":""}}>
											<div className="css-vj8t7z" style={{ backgroundColor: widgetStore.toolBarInvitationSettings.buttonBgTextColor }}>
												<div className="css-1hwfws3">
													<div className="css-1g6gooi">
														<div className="">
															<input autoComplete="off"
																autoCorrect="off"
																id="react-select-9-input"
																spellCheck="false" tabIndex="0" type="text"
																aria-autocomplete="list"
																value={widgetStore.toolBarInvitationSettings.buttonBgTextColor}
																title={widgetStore.inv_tootltipbgtxtColorerror ?'color must be in a hex format #FFFFFF':""}
																onChange={(event) => widgetStore.toolBarInvitationSettings.buttonBgTextColor = event.target.value}
																onBlur={(event)=> widgetStore.checkhexapattern(event.target.value,8)}
																style={{ backgroundColor: widgetStore.toolBarInvitationSettings.buttonBgTextColor, color: this.pickTextColorBasedOnBgColorAdvanced(widgetStore.toolBarInvitationSettings.buttonBgTextColor, '#FFFFFF', '#000000') }}
															/>

														</div>
													</div>
												</div>
												<div className="css-1wy0on6" onClick={() => { widgetStore.buttonTextColorSelector = !widgetStore.buttonTextColorSelector }}>
													<span className="css-d8oujb"></span>
													<div aria-hidden="true" className="css-1ep9fjw">
														<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
															<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
														</svg>
													</div>
												</div>
											</div>
										</div>
										{
											widgetStore.buttonTextColorSelector &&
											<CompactPicker className="colorSelector"
												color={widgetStore.toolBarInvitationSettings.buttonBgTextColor}
												onChangeComplete={(color, event) => { widgetStore.toolBarInvitationSettings.buttonBgTextColor = color.hex }} />
										}

									</ClickOutHandler>
								</Col>
							</Form.Group>
						</React.Fragment>
					}

				</form>
				{
					widgetStore.getImageFromLibrary &&
					<InvitationImageLibraryModal
						show={widgetStore.getImageFromLibrary}
						appStore={appstore}
						store={widgetStore}
						callBack={(selectedImage) => this.setImage(selectedImage)} />
				}
				{widgetStore.imagewarning &&
					<ImageWarningPopUp show={widgetStore.imagewarning}
						store={widgetStore}
						cancel_btn="OK"
						closePopup={() => widgetStore.imagewarning = false}
						appStore={appstore} />
				}
				{widgetStore.imageCropModel &&
					<ImageCropComponent show={widgetStore.imageCropModel}
						store={widgetStore}
						cancel_btn="Clear"
						multi="0"
						crop_btn="Save"
						onCropComplete={() => this.onCropComplete(crop)}
						closePopup={() => this.handleClearToDefault()}
						appStore={appstore} />
				}
			</React.Fragment>

		)
	}
})
export default InvitationSettingsComponent;
