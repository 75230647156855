
import React, {Component } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import imageURLs from '../../../../../sharedFiles/ImagesURLs';
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg'
const CustomOptions = observer(class CustomOptions extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  addStar() {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '⭐',
      editorState.getCurrentInlineStyle(),
    );
    onChange(this.props.callBack());
  };

  render() {
    const {appstore,store} = this.props
    return (
      <React.Fragment>
        {/* { (store.widgetWebsite && store.widgetWebsite.enableFileTransfer !=="none") && */}
        {appstore.userInfo.accountplan.transfer_file !=1 &&
        <p data-tooltip={appstore.getLanguageTrans("Upgrade Your Plan")} data-tooltip-conf="top" style={{marginTop:"11px"}}>
         <div className="" title="Send a file" style={(store.widgetWebsite && store.widgetWebsite.enableFileTransfer !=="none" && appstore.userInfo.accountplan.transfer_file !=0) ? {"cursor":"pointer"}:{"pointerEvents":"none",opacity:0.4}} onClick={() => document.getElementById("file").click()}><img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.attachmant_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.attachmant_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.attachmant_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.attachmant_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.attachmant_voilet : 
                    "#039be6" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_grey :
                     "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.attachmant_skyblue : imageURLs.attachmant_steelblue} className="img-fluid editorAttachImg" />
            <input style={{"display":"none"}} ref={this.props.fileinputref} multiple={false} type="file" accept={acceptedFileTypes} 
            onChange={this.props.onselectfile} id="file" 
            disabled={(store.widgetWebsite && store.widgetWebsite.enableFileTransfer !=="none") ? false :true } />
			  </div>
        </p>
      }
      {appstore.userInfo.accountplan.transfer_file !=0 &&
         <div className="" title="Send a file" style={(store.widgetWebsite && store.widgetWebsite.enableFileTransfer !=="none" && appstore.userInfo.accountplan.transfer_file !=0) ? {"cursor":"pointer"}:{"pointerEvents":"none",opacity:0.4}} onClick={() => document.getElementById("file").click()}><img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.attachmant_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.attachmant_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.attachmant_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.attachmant_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.attachmant_voilet : 
                    "#039be6" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.attachmant_grey :
                     "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.attachmant_skyblue : imageURLs.attachmant_steelblue} className="img-fluid editorAttachImg" />
            <input style={{"display":"none"}} ref={this.props.fileinputref} multiple={false} type="file" accept={acceptedFileTypes} 
            onChange={this.props.onselectfile} id="file" 
            disabled={(store.widgetWebsite && store.widgetWebsite.enableFileTransfer !=="none") ? false :true } />
			  </div>
      }
        {/* } */}
        <div className="customProps">
          {
            /* just replace class in below div to change position  (editorPrint) (editorExitSession)*/
          }


          {/* <div style={{cursor:"pointer"}} className="editorCoBrowse" onClick={() => this.props.coBrowseCallBack()}><img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.browse_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.browse_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.browse_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.browse_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.browse_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.browse_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.browse_voilet : 
                    "#039be6" == appstore.selectedColor.color ? imageURLs.browse_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.browse_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.browse_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.browse_grey : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.browse_skyblue : imageURLs.browse_steelblue} className="img-fluid" /></div>

          <div style={{cursor:"pointer"}} className="editorSpellCheck" onClick={() => this.props.spellCheckCallBack()}><img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.spell_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.spell_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.spell_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.spell_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.spell_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.spell_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.spell_voilet : 
                    "#039be6" == appstore.selectedColor.color ? imageURLs.spell_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.spell_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.spell_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.spell_grey : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.spell_skyblue : imageURLs.spell_steelblue} className="img-fluid" /></div> */}
          <div  title="Print Transcript" style={{cursor:"pointer"}} className="editorCoBrowse" onClick={() => {this.props.onselectChatprint();this.props.printCallBack()}}><img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.print_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.print_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.print_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.print_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.print_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.print_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.print_voilet : 
                    "#039be6" == appstore.selectedColor.color ? imageURLs.print_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.print_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.print_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.print_grey : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.print_skyblue : imageURLs.print_steelblue} className="img-fluid" /></div>
          <div title="Quit Chat" style={{cursor:"pointer"}} className="editorExitSession" onClick={() => store.quitChatConfirm=true}><img src={"#3ba8c5" == this.props.appstore.selectedColor.color ? imageURLs.exit_steelblue : "#4cb6ac" == this.props.appstore.selectedColor.color ? imageURLs.exit_seagreen : "#ec407a" == this.props.appstore.selectedColor.color ?  imageURLs.exit_pink : "#6e4c42" == this.props.appstore.selectedColor.color ?imageURLs.exit_brown : "#234e91" == this.props.appstore.selectedColor.color ?imageURLs.exit_navyblue : "#e89712" == this.props.appstore.selectedColor.color ?imageURLs.exit_yellow : "#b968c7" ==this.props.appstore.selectedColor.color ?imageURLs.exit_voilet : 
                    "#039be6" == this.props.appstore.selectedColor.color ? imageURLs.exit_blue : "#00887a" == this.props.appstore.selectedColor.color ? imageURLs.exit_darkgreen : "#4b8f4e" == this.props.appstore.selectedColor.color ? imageURLs.exit_green : "#5f7c8c" == this.props.appstore.selectedColor.color ? imageURLs.exit_grey : "#64a9e0"== this.props.appstore.selectedColor.color ? imageURLs.exit_skyblue : imageURLs.exit_steelblue} className="img-fluid" /></div>
        </div>
      </React.Fragment>
    );
  }
})
export default CustomOptions;
