import React, { Component } from 'react';
import { decorate, observable, extendObservable } from 'mobx';
import {data} from '../../../../assets/JSON/admin_archivechat';
import axios from 'axios';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);


class ReportUsageStore {


    constructor(appstore){
        this.responseData = data[0];
        this.confirm = false;
        this.datepicker= false;
       this.websiteDrpDwnOptions = [];
       this.allDepartments =[];    
       this.allWebsites =[];
       this.chats = 80; 
       this.pageLoder= false;
       this.usedSpace=0;
       this.availablespace= 0;
       this.exportdata= [];
       this.export= false;
       this.rangeusedSpace=0;
       this.spaceUnit ="";
       this.operators= [];
       const today = moment(); 
       this.state = {
        isOpen: false,
        value: moment.range(today.clone().subtract(14, "days"), today.clone())
      }
      this.reportUsageData = {
        "sms_usedDate": "",
        "sms_used": 0,
        "previoustimeDate": "",

      };
     
  }

  setWebsites(websitesmodel,appstore) {
    let websites = JSON.parse(JSON.stringify(websitesmodel));
    this.pageLoder =false;
    if(websites !=null){

      let all = {"label":"All","value":0,"id":0,"optionType":"websiteAll","website_uid":"-1"};
      websites.splice(0, 0,all);   //insert All option in dropdown options
      let x;
      let index =0;
      for (x in websites)
      {
        if(x>0){
        websites[x].label=websites[x].name;
        websites[x].value= index;
        websites[x].website_uid= websites[x].website_uid;     
        }
        index++;
      }
     
       this.websiteDrpDwnOptions = websites;
       this.allwebsite = this.websiteDrpDwnOptions[0]
       this.pageLoder =true;

    }

   }


   getStoragespace(appstore,dateRange=null){

    const today = moment(); 
		let since_from_today=today.clone().subtract(14, "days");
		const data = {
			"since": dateRange !=null ? since_from_today.format("DD-MM-YYYY"):this.state.value.start.format("DD-MM-YYYY"),
			"till":  dateRange !=null ? since_from_today.format("DD-MM-YYYY"):this.state.value.end.format("DD-MM-YYYY"),
    }
    
    this.datepicker=false;
    axios({
      method: 'POST',
      url: appstore.url+'getfilestorage.json',
      headers: { 'content-type': 'application/json' },
      data:data,
    }).then(result=> {
      if(result.data==502){
        appstore.logout();
      }
                  if(result.data!=-1){
                  //  alert("sasa")
                    let usedSpace=(result.data/1024/1024)
                    let rangeusedSpace=(result.data/1024/1024)
                    this.usedSpace=usedSpace.toFixed(2);
                    this.availablespace= Math.round(200 - result.data/1024/1024);
                    this.spaceUnit ="Mb";
                    this.rangeusedSpace=rangeusedSpace.toFixed(2);
                    // this.responseData.totalSpace =200;
                    
                   }else{
                    this.availablespace=200;
                    this.spaceUnit ="Mb";
                    this.totalSpace =200;
                   }  
                   this.pageLoder=true;
                   appstore.global_errorcount=0;
      }).catch((error) => {
          //  appstore.logout();
          this.pageLoder=true;
          console.log(error);
          console.log("error count :"+appstore.global_errorcount);
          appstore.global_errorcount=appstore.global_errorcount+1;
          if(appstore.global_errorcount>20){
          // appstore.logout();
          }
      });
    }



}

decorate(ReportUsageStore, {
    datepicker:observable,
    usedSpace:observable,
    rangeusedSpace:observable,
    availablespace:observable,
    websiteDrpDwnOptions:observable,
    operatorDrpDwnOptions:observable,
    allwebsite:observable,
    responseData:observable,
    charts:observable,
    reportUsageData:observable,
    operators:observable,
    pageLoder:observable,
    exportdata:observable,
    export:observable,
})


export default ReportUsageStore;