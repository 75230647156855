import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

const ChatPreviewComponent = observer(class ChatPreviewComponent extends Component {

	constructor(props) {
		super(props);

		this.props.scriptStore.chatUserData = "";
	}

	sendChatData() {
		this.props.scriptStore.setChatData(this.props.scriptStore.chatUserData);
		this.props.scriptStore.chatUserData = "";
	}
	relateddata(data) {
		let basicchatSection = this.props.responseData.basicActions;
		let customchatSection = this.props.responseData.customResponse;
		let relatedchatSection = this.props.responseData.relatedLinks;
		let matched = 0;
		let res = "";
		for (let i in basicchatSection.chatTypes) {
			if (basicchatSection.chatTypes[i].related_uid == data.relatedids) {
				matched = 1;
				//  res=basicchatSection.chatTypes[i];
				res = {
					"response": basicchatSection.chatTypes[i],
					"typeid": 1
				}
				return res;
			}
			if (matched == 1)
				break;
		}
		if (matched == 0) {
			for (let k in customchatSection.chatTypes) {
				if (customchatSection.chatTypes[k].related_uid == data.relatedids) {
					matched = 1;
					//  res=customchatSection.chatTypes[i];
					res = {
						"response": customchatSection.chatTypes[k],
						"typeid": 2
					}
					return res;
				}
				if (matched == 1)
					break;
			}
			if (matched == 0) {
				for (let j in relatedchatSection.chatTypes) {
					if (relatedchatSection.chatTypes[j].related_uid == data.relatedids) {
						matched = 1;
						//  res=relatedchatSection.chatTypes[i];
						res = {
							"response": relatedchatSection.chatTypes[j],
							"typeid": 3
						}
						return res;
					}
					if (matched == 1)
						break;
				}
			}
		}
	}
	relatedlinks(data) {
		let chatSection = this.props.responseData.relatedLinks;
		let matched = 0;
		let res = "";
		for (let i in chatSection.chatTypes) {
			if (chatSection.chatTypes[i].related_uid == data.relatedids) {
				matched = 1;
				res = chatSection.chatTypes[i];
				return res;
			}
			if (matched == 1)
				break;
		}
		if (matched == 0) {
			return res;
		}
	}

	render() {

		const { scriptStore,
			previewChatData,
			responseData,
			appstore } = this.props;
		let self = this;
		// previewsend
		return (
			<div className="chatPreview">

				<div className={!scriptStore.previewOnFocus ? "headerTitle" : "headerTitle active"}>
					<h6 className="text-uppercase">{appstore.getLanguageTrans("Preview")}</h6>
				</div>
				<div className="chatBox">
					<div className="msgArea text-left">
						<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
							{
								previewChatData.map(function (chatData, chatIndex) {
									return (
										<div key={chatIndex} className="chat">
											{chatData.userChat !== '' &&
												<p>{chatData.userChat}</p>
											}
											{chatData.replyChat !== '' &&
												<div dangerouslySetInnerHTML={{ __html: chatData.replyChat }}></div>
											}
											{
												chatData.responseChat && chatData.responseChat.map(function (response, responseIndex) {
													let chatSection = {};
													let ty = 0;
													console.log(response);
													let relatedresponse = self.relateddata(response);
													if (relatedresponse != undefined && relatedresponse != null) {
														if (relatedresponse.typeid !== 3) {
															return (
																<p className="reply cursor-pointer" key={responseIndex}
																	onClick={() => scriptStore.addChatToPreview(relatedresponse)}>{relatedresponse.response.title}</p>
															)
															// chatSection = response.typeid === 1 ? responseData.basicActions : responseData.customResponse;											
															// // return (											
															// 	// <p className="reply cursor-pointer" key={responseIndex} 
															// 	// 		onClick={() => scriptStore.addChatToPreview(response)}>{chatSection.chatTypes[response.id-1] ? chatSection.chatTypes[response.id-1].title : ''}</p>
															// // }	

														}
														if (relatedresponse.typeid === 3) {
															// chatSection = responseData.relatedLinks;
															// let relatedresponse=self.relatedlinks(response);
															return (
																// <a className="reply cursor-pointer" key={responseIndex} target="_blank" href={chatSection.chatTypes.find(option => option.id === response.id).link}>{chatSection.chatTypes.find(option => option.id === response.id).title}</a>
																<a className="reply cursor-pointer" key={responseIndex} target="_blank" href={relatedresponse.response.link}>{relatedresponse.response.title}</a>
															)
														}
													}
												})
											}
										</div>
									)
								})
							}
						</CustomScroll>
					</div>
					<div className={!scriptStore.previewsend ? "chatArea chatareasend" : "chatArea"}>
						{/* <Form onSubmit= {() => this.sendChatData()}> commenting bcos on enter, page is refresing */}
						<Form.Group as={Row} controlId="formGroupMsg">
							<Col sm="12" className="pl-0">
								<Form.Control type="text" placeholder={appstore.getLanguageTrans("Enter message")}
									value={scriptStore.chatUserData}
									onFocus={(e) => scriptStore.previewOnFocus = true}
									onBlur={(e) => scriptStore.previewOnFocus = false}
									onChange={(e) => scriptStore.chatUserData = e.target.value} />
							</Col>
						</Form.Group>
						<div className="row m-0">
							<div className="col-12 formBtns">
								<div className="pullLeft">
									<Button className="btn_send" onClick={() => this.sendChatData()} >
										<p>{appstore.getLanguageTrans("Send")}</p>
									</Button>
								</div>
							</div>
						</div>
						{/* </Form> */}

					</div>
				</div>
			</div>
		)
	}
})
export default ChatPreviewComponent;
