import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';

import ScriptAddEditResponseComponent from './ScriptAddEditResponseComponent';
import ScriptAddEditLinkComponent from './ScriptAddEditLinkComponent';

const ScriptAddNewComponent = observer(class ScriptAddNewComponent extends Component {

	constructor (props) {
        super(props);
 
         this.editScriptRef = React.createRef();
    }
	
	componentDidMount(){
		if(this.props.selectedDataToEdit || this.props.selectedLinkToEdit) {
			this.scrollToPosition();
		}

	}

	componentWillReceiveProps(nextProps){
		if((nextProps.selectedDataToEdit && nextProps.selectedDataToEdit!=this.props.selectedDataToEdit) || (nextProps.selectedLinkToEdit && nextProps.selectedLinkToEdit!=this.props.selectedLinkToEdit)) {
			this.scrollToPosition();
		}
		
	}

	scrollToPosition(){
		setTimeout(() => {
			window.scrollTo(0, this.editScriptRef.current.offsetTop + 150); 
		},500);
	}

	render(){

		const { scriptStore,
				editData,
				selectedDataToEdit,
				selectedLinkToEdit,
				addResponseLink,
				scriptType,
				appstore,
				responseData } = this.props;

		return (
			<div className="settingFormWrapper box editScriptSection" ref={this.editScriptRef}>
				<div className="headerTitle"  style={{borderBottom:"4px solid "+appstore.selectedColor.color}}>
					<h4 className="text-left">{!addResponseLink ? appstore.getLanguageTrans("Edit") : appstore.getLanguageTrans("Add")}{selectedDataToEdit ? " "+appstore.getLanguageTrans("Script") : " "+appstore.getLanguageTrans("Related Link")}</h4>
				</div>
				<div className="contentWrapper">
					{
						addResponseLink && 
						<Form>
							<Form.Group as={Row} controlId="formGroupType">
						    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Type")}</Form.Label>
						    <Col sm="8" lg="9" className="customDrpDwn">
						    	<DropDownComponent dropDownOptions={scriptType} 
												   store={scriptStore}
												   key={Math.random()}
						    					   callBack={(selectedOption) => scriptStore.setScriptType(selectedOption)}
						    					   selectedOption = {scriptStore.selectedScriptType}/>
						    </Col>
						  </Form.Group>
						 </Form>
					}
					{
						selectedDataToEdit &&
							<ScriptAddEditResponseComponent scriptStore = {scriptStore}
															editData = { selectedDataToEdit }
															addResponseLink = {addResponseLink}
															appstore={appstore}
															responseData = {responseData} />
					}
					{
						selectedLinkToEdit && 
							<ScriptAddEditLinkComponent scriptStore = {scriptStore}
														editData = { selectedLinkToEdit }
														appstore={appstore}
														addResponseLink = {addResponseLink} />
					}
				</div>
			</div>
		)
	}
})
export default ScriptAddNewComponent;
