import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';

const InvitationPreviewComponent = observer(class InvitationPreviewComponent extends Component {

	constructor (props) {
        super(props);
    }

	componentDidMount(){
	}

	componentWillUnmount() {
	}
	 

	render(){

		var self = this;

		const { appstore, widgetStore } = this.props;
		return (
			<React.Fragment>
				{widgetStore.toolBarInvitationSettings.invitationType === 1 &&
					<div className="toolbarInvitationPreview" style={{ backgroundColor: widgetStore.toolBarInvitationSettings.bgColor }}>
						<div className="invitationRow row">
							<div className="leftSection col-3" id="">
								{widgetStore.invitationprieviewimg != "" &&
									<img id="" className="invitationimg" src={widgetStore.invitationprieviewimg} />
								}
							</div>
							<div className="rightSection col-9">
								<button type="button" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
								<div className="invitationMessageWrapper">
									<div className="invitationMessage">
										<div data-style="bodyText" id="ext-gen1145" style={{ color: widgetStore.toolBarInvitationSettings.bgTextColor }}>{appstore.getLanguageTrans("Hello, may I help with anything?")}</div>
									</div>
								</div>
								<div className="forBtnMiddle">
									<Button variant="light" className="cursor-pointer btn_chat"
										style={{
											backgroundColor: widgetStore.toolBarInvitationSettings.buttonBgColor,
											color: widgetStore.toolBarInvitationSettings.buttonBgTextColor
										}}>
										{appstore.getLanguageTrans("Chat Now")}
									</Button>
								</div>
							</div>
						</div>
					</div>
		}
			{widgetStore.toolBarInvitationSettings.invitationType === 2 &&
					<div className="toolbarInvitationPreview" style={{ backgroundColor: "transparent",border:"none" }}>
						<div className="invitationRow row">
							{/* <div className="leftSection col-9" id="" 
							style={widgetStore.toolBarPreviewSettings.position == "left top" ?{zIndex:"1030",left:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",top:widgetStore.toolBarInvitationSettings.margin+"px"}
								:widgetStore.toolBarPreviewSettings.position == "right top" ?{zIndex:"1030",right:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",top:widgetStore.toolBarInvitationSettings.margin+"px"}
								:widgetStore.toolBarPreviewSettings.position == "right bottom" ?{zIndex:"1030",right:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",bottom:widgetStore.toolBarInvitationSettings.margin+"px"}
								:widgetStore.toolBarPreviewSettings.position == "left bottom" ?{zIndex:"1030",left:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",bottom:widgetStore.toolBarInvitationSettings.margin+"px"}
								:{zIndex:"1030",right:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",bottom:widgetStore.toolBarInvitationSettings.margin+"px"}}>
								{widgetStore.invitationprieviewimg != "" &&
									<img id="" className="invitationimg" style={{width:"auto",objectFit:"scale-down"}} src={widgetStore.invitationprieviewimg} />
								}
							</div> */}
							<div className="leftSection col-9" id="" 
							style={widgetStore.toolBarPreviewSettings.position == "left top" ?{zIndex:"1030",top:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",margin:widgetStore.toolBarInvitationSettings.margin+"px"}
								:widgetStore.toolBarPreviewSettings.position == "right top" ?{zIndex:"1030",top:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",margin:widgetStore.toolBarInvitationSettings.margin+"px"}
								:widgetStore.toolBarPreviewSettings.position == "right bottom" ?{zIndex:"1030",right:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",bottom:widgetStore.toolBarInvitationSettings.margin+"px"}
								:widgetStore.toolBarPreviewSettings.position == "left bottom" ?{zIndex:"1030",left:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",bottom:widgetStore.toolBarInvitationSettings.margin+"px"}
								:{zIndex:"1030",right:this.props.widgetStore.toolBarInvitationSettings.leftMargin+"px",bottom:widgetStore.toolBarInvitationSettings.margin+"px"}}>
								{widgetStore.invitationprieviewimg != "" &&
									<img id="" className="invitationimg" style={{width:"auto",objectFit:"scale-down"}} src={widgetStore.invitationprieviewimg} />
								}
							</div>
						</div>
					</div> 
		}
			</React.Fragment>
		)
	}
})
export default InvitationPreviewComponent;
