import React, {Component} from 'react';
import { observer } from 'mobx-react';
import { Button} from 'react-bootstrap';
import imageURLs from '../../sharedFiles/ImagesURLs';
import CheckBoxCustomComponent from '../checkBox_2/checkBoxCustom2';
import axios from 'axios';

const HelpPopupTooltip = observer(class HelpPopupTooltip extends Component {

	constructor (props) {
        super(props);
        this.toVideo = this.toVideo.bind(this);
    }
	componentWillMount(){

	}


	setWelcomePopup(){
		this.props.store.show_welcome = !this.props.store.show_welcome	;
		axios({
			method: 'POST',
			url: this.props.appStore.url+'showwelcome.json',
			headers: { 'content-type': 'application/json' },
			data:{"show_welcome":this.props.store.show_welcome ? 0:1},
			}).then(result=> { 
			//console.log(result);
			this.props.appStore.global_errorcount=0;
		}).catch((error) => {
			// this.props.appStore.logout(); 
			console.log(error);
				console.log("error count :"+this.props.appStore.global_errorcount);
				this.props.appStore.global_errorcount=this.props.appStore.global_errorcount+1;
				if(this.props.appStore.global_errorcount>20){
				// this.props.appStore.logout();
				}
		})
    }

 toVideo(){   
	 //   window.open('https://www.youtube.com/watch?v=9pTXiFb_yRo','_blank');
	 this.props.store.playVideo=true
}
	render(){
		const parentScroll={
			  display: "flex", 
			  height:"55vh"
			}
      
        const flexScroll= {
               flex:1,
                minHeight: 0,
                 minWidth: 0

        }

		const {show, 
			   store,
				title,
				callBack,
				body_html,
				video_btn,
				close,
				checkSelected,
				appStore
			     } = this.props;

			     
		return (
			   <div  className={'chatArchivePopup ' + (appStore ? appStore.selectedColor.value : '') + ' helpPopuptip'} >
				{/* closeButton */}
					  <div  className="row">
						    <div className="helpTipTitle col-11" style={{textTransform:"none"}}>{title}</div>
							<div className="col-1"><img className="closeNoti" src={imageURLs.deleteIcon} onClick={() => this.props.callBack()}></img></div>
						    <div className="borderBtm" style={{borderBottom: "4px solid "+appStore.selectedColor.color}}></div>  
					  </div>

					<div className="helpTipScrollWrapper">
									<div className="row">
												<div className="col-12">
												<p className="">{appStore.getLanguageTrans("Here you can set up your chat widget, customize it and chat with your website visitors.")}</p>
													<Button className="dismiss text-uppercase helpBtn"  variant="secondary" style={{border: "1px solid"+appStore.selectedColor.color,color:appStore.selectedColor.color}} onClick={() =>{this.props.store.playVideo=true}}>{video_btn}</Button>
													<p>{appStore.getLanguageTrans("You can always play this and other tutorials by clicking the “Help” button")}</p>
												
												<p className="do_not_check">
													<CheckBoxCustomComponent radioValue={store.show_welcome} 
																		callBack = {() => this.setWelcomePopup()}/>  {appStore.getLanguageTrans("Do not show again")}
																		

												</p> 
												<p className="floatRight"><Button className="read_more text-uppercase helpBtn helpTooltiphover" variant="primary" style={{border: "1px solid"+appStore.selectedColor.color,color:appStore.selectedColor.color}} onClick={() => (this.props.callBack(),store.goToPageFromWelcome(appStore))}>{close}</Button>
												</p>
											</div>
											
									</div>
				
					</div>

			 </div>
		)
	}
})
export default HelpPopupTooltip;
