import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import { css } from '@emotion/core';
import OperatorReportsStore from './OperatorReportsStore';
import OnlineTimeChart from './OnlineTimeChart';
import ChartsColumChart from './ChartsColumChart';
import ExportCSV from './exportOperatorCSV';
import Export_CSV from './operatorChatsCSV';
import Export_to_CSV from './onlineTimeCSV';
import DateRangePicker from "react-daterange-picker";
import { GridLoader } from 'react-spinners';
import CustomScroll from 'react-custom-scroll';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import originalMoment from "moment";
import axios from 'axios';
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`



const OperatorReports = observer(class OperatorReports extends Component {

	constructor(props) {
		const today = moment();
		super(props);
		this.operatorReportsStore = new OperatorReportsStore(this.props.appstore);
		this.state = {
			isOpen: false,
			value: moment.range(today.clone().subtract(14, "days"), today.clone())
		}
	}

	componentDidMount() { 
		this.operatorReportsStore.setWebsites(this.props.appstore.userInfo.allwebistes, this.props.appstore); 
		this.operatorReportsStore.setOperators(this.props.appstore.userInfo.alloperators, this.props.appstore)
		var date = new Date().getDate() + 1;
		var month = new Date().getMonth() + 1;
		var year = new Date().getFullYear();

		this.getoperatordata(date + '-' + month + '-' + year);
		this.getOverviewdata(date + '-' + month + '-' + year);
	}

	getoperatordata = (dateRange = null) => { 
		this.operatorReportsStore.pageLodernew = false;
		const today = moment();
		let since_from_today = today.clone().subtract(14, "days");

		const data = {
			"since": dateRange != null ? since_from_today.format("DD-MM-YYYY") : this.state.value.start.format("DD-MM-YYYY"),
			"till": dateRange != null ? dateRange : this.state.value.end.format("DD-MM-YYYY"),
			"alltype": ["ops_time", "chat_missed", "chats","ops_rating","pickup_time"],
			"website_uid": this.operatorReportsStore.allwebsite.website_uid
		}
		this.operatorReportsStore.datepicker = false;
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getOperatorReports.json',
			headers: { 'content-type': 'application/json' },
			data: data,

		}).then(result => {
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			let x = []
			let y = result.data 
			let inserted = false
			let index = 0;
			let total_rating=0;
			let total_rating_cnt=0;
			let total_onlinetime=0;
			for (let i in y) {
				inserted = false
				for (let j in x) {
					if (x[j].str == y[i].str) {

						if (y[i].type == "chats") {
							x[j]["_" + y[i].type] = y[i].totalCount;
						}
						
						if (y[i].type == "chat_missed") {
							x[j]["_" + y[i].type] = y[i].totalCount;
						}
						// else if  (y[i].type != "chat_missed") {
						// 	x[j]["_" + "chat_missed"] = 0;
						// }
						if (y[i].type == "ops_time") {							
							total_onlinetime = parseFloat(total_onlinetime)+parseFloat(y[i].totaltime);
							x[j].totaltime = this.secondsToTimeDays(y[i].totaltime); 
						}
						if (y[i].type == "chats") {
							x[j].totaltime2 = this.secondsToTimeDays(y[i].totaltime);
						}
						if(y[i].type=="ops_rating"){
						    x[j]["_" + y[i].type] = y[i].totalCount;
							x[j].totaltime3 = (y[i].totaltime>0 && y[i].totalCount>0) ? y[i].totaltime/y[i].totalCount:0;
							x[j].totaltime3 = x[j].totaltime3.toFixed(1);
						}
						if(y[i].type=="pickup_time"){
						    x[j]["_" + y[i].type] = y[i].totalCount;
							x[j].totaltime4 = (y[i].totaltime>0 && y[i].totalCount>0) ? this.secondsToTimeDays(y[i].totaltime/y[i].totalCount):this.secondsToTime(0);
						}
						inserted = true
					}
				}
				if (inserted == false) {
					x[index] = y[i]
					x[index][y[i].type] = y[i].type
					if (y[i].type == "chats" || y[i].type == "chat_missed") {
						x[index]["_" + y[i].type] = y[i].totalCount;
					}
					else if (y[i].type != "chats" || y[i].type != "chat_missed") {
                        x[index]["_" + "chats"] = 0;
                        x[index]["_" + "chat_missed"] = 0;
                    }
					if (y[i].type == "chats") {
						x[index].totaltime2 = this.secondsToTimeDays(y[i].totaltime);
					}
					if (y[i].type != "chats") {
						x[index].totaltime2 = this.secondsToTimeDays(0);
					}
					if (y[i].type == "ops_time") { 
						total_onlinetime = parseFloat(total_onlinetime)+parseFloat(y[i].totaltime);
						x[index].totaltime = this.secondsToTimeDays(y[i].totaltime);  
					}
					if(y[i].type != "ops_rating"){
						x[index].totaltime3 = 0;
						x[index]["_" + "ops_rating"] = 0;
					}
					if(y[i].type == "ops_rating"){
						x[index]["_" + y[i].type] = y[i].totalCount;
						x[index].totaltime3 = (y[i].totaltime>0 && y[i].totalCount>0) ? y[i].totaltime/y[i].totalCount:0;
						x[index].totaltime3 = x[index].totaltime3.toFixed(1);
					}
					if(y[i].type != "pickup_time"){
						x[index].totaltime4 = this.secondsToTimeDays(0);
						x[index]["_" + "pickup_time"] = 0;
					}
					if(y[i].type == "pickup_time"){
						x[index]["_" + y[i].type] = y[i].totalCount; 
						x[index].totaltime4 = (y[i].totaltime>0 && y[i].totalCount>0) ? this.secondsToTimeDays(y[i].totaltime/y[i].totalCount):this.secondsToTime(0);
					}
					index++;
				}
			}
			for(let k=0;k<x.length;k++){ 
				total_rating=(parseFloat(total_rating)+parseFloat(x[k].totaltime3));
				total_rating_cnt=parseFloat(total_rating_cnt)+parseFloat(x[k]._ops_rating);
			}
			
			this.operatorReportsStore.avg_rating=total_rating.toFixed(1);
			this.operatorReportsStore.op_were_rated=total_rating_cnt;
			this.operatorReportsStore.op_onlinetime = this.secondsToTimeDays(total_onlinetime);
			//console.log(x);
			this.operatorReportsStore.optdata = x
			 this.operatorReportsStore.exportdata = JSON.parse(JSON.stringify(x));
			//this.operatorReportsStore.pageLoder = true;
			 
				this.operatorReportsStore.pageLodernew = true;
				this.props.appstore.global_errorcount=0;
		})
		.catch(err=> {
			// this.props.appstore.logout();
			this.operatorReportsStore.pageLodernew = true;
			console.log(err);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		})

	}
	

	checkKeybackspace = (event) =>{
		if (event.keyCode == 13 || event.keyCode == 8){
			return
		}
	  }


	dptoptforwebiste = (uid = "") => {
		this.operatorReportsStore.pageLoder = false;
		var website_id = this.operatorReportsStore.allwebsite.website_uid
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'dptoptforwebiste.json',
			headers: { 'content-type': 'application/json' },
			data: { "website_uid": website_id, "department_uid": uid },
		}).then(result => {
			if(result.data.length==1){
				if(result.data.logoutstatus==1){
				this.props.appstore.logout();
				}
			  }
			let opt = result.data.usersmodel;
			let allopt = { "label": "All Operators", "value": 0, "user_uid": "" };
			opt.splice(0, 0, allopt);
			let index = 1;
			for (let x in opt) {
				if (x > 0) {
					opt[x].label = opt[x].nick_name;
					opt[x].value = opt[x].user_uid;
					opt[x].user_uid = opt[x].user_uid;
					index++;
				}
			}
			this.operatorReportsStore.operator_report_opt=[];
			this.operatorReportsStore.operator_report_opt = opt;
			this.operatorReportsStore.alloperators=this.operatorReportsStore.operator_report_opt[0];
			this.operatorReportsStore.pageLoder = true;
			
			this.props.appstore.global_errorcount=0;
		})
		.catch(err=> {
			// this.props.appstore.logout();
			this.operatorReportsStore.pageLoder = true;
			console.log(err);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		})
		this.getoperatordata()
		this.getOverviewdata()
	}

	getOverviewdata = (dateRange = null) => {
		this.operatorReportsStore.pageLoder = false;
		const today = moment(); 
		let since_from_today = today.clone().subtract(14, "days");
		let since_from_todayending = today.clone().add(1, "days"); 
		let endtime = moment(Date.parse(this.state.value.end)).clone().add(1, "days");
		const data = {
			"todaydate": dateRange != null ? since_from_today.format("DD-MM-YYYY "):this.state.value.start.format("DD-MM-YYYY"),
			"till": dateRange != null ? dateRange:endtime.format("DD-MM-YYYY"),
			"alltype": ["ops_time", "chat_missed", "chats","ops_rating","pickup_time"],
			"website_uid": this.operatorReportsStore.allwebsite.website_uid,
			"userUid": this.operatorReportsStore.alloperators.user_uid
		}
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getoptoverviewrecords.json',
			headers: { 'content-type': 'application/json' },
			data: data,

		}).then(result => {
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			this.operatorReportsStore.chartexportdata=JSON.parse(JSON.stringify(result.data))
			let totalonlinetime = 0;
			let totalrating = 0;
			let totalratingcnt = 0;
			let onlinetimedate = [];
			let onlinetime = [];
			let totalcharts = 0;
			let missedtotalcharts = 0;
			let chartsDate = [];
			let chartsCount = [];
			let previouschartsDate = "";
			let missedchartsCount = [];
			let missedchartsDate = [];
			let previousmissedchartsDate = "";
			let previoustimeDate = "";


			for (let i in result.data) {
				if (result.data[i].type === "ops_time") {
					if (previoustimeDate === result.data[i].quarter_date) {
						// onlinetime[onlinetime.length - 1] = onlinetime[onlinetime.length - 1] + result.data[i].cnt;
						onlinetime[onlinetime.length - 1] = onlinetime[onlinetime.length - 1] + result.data[i].totaltime;
					}

					else {
						onlinetimedate.push(result.data[i].quarter_date)
						// onlinetime.push(result.data[i].time)
						onlinetime.push(result.data[i].totaltime)
						totalonlinetime = result.data[i].totaltime
						previoustimeDate = result.data[i].quarter_date;

					}
				}
				else if (result.data[i].type === "chats") {
					if (previouschartsDate === result.data[i].quarter_date) {
						// chartsCount[chartsCount.length - 1] = chartsCount[chartsCount.length - 1] + result.data[i].cnt;
						chartsCount[chartsCount.length - 1] = chartsCount[chartsCount.length - 1] + result.data[i].totalCount;
					}
					else {
						chartsDate.push(result.data[i].quarter_date)
						// chartsCount.push(result.data[i].cnt)
						chartsCount.push(result.data[i].totalCount)
						missedchartsCount.push(0);
						totalcharts = result.data[i].totalCount
						previouschartsDate = result.data[i].quarter_date;
					}
				}

				else if (result.data[i].type === "chat_missed") {
					if (previousmissedchartsDate === result.data[i].quarter_date) {
						// missedchartsCount[missedchartsCount.length - 1] = missedchartsCount[missedchartsCount.length - 1] + result.data[i].cnt;
						missedchartsCount[missedchartsCount.length - 1] = missedchartsCount[missedchartsCount.length - 1] + result.data[i].totalCount;
					}
					else {
						chartsDate.push(result.data[i].quarter_date )
						// missedchartsCount.push(result.data[i].cnt)
						missedchartsCount.push(result.data[i].totalCount)
						chartsCount.push(0);
						missedtotalcharts = result.data[i].totalCount
						previousmissedchartsDate = result.data[i].quarter_date;
					}
				}
				// else if (result.data[i].type === "ops_rating") {
				// 	if (previoustimeDate === result.data[i].quarter_date) {
				// 		totalratingcnt[totalratingcnt.length - 1] = totalratingcnt[totalratingcnt.length - 1] + result.data[i].totalCount;
				// 		totalrating[totalrating.length - 1] = totalrating[totalrating.length - 1] + result.data[i].totaltime;
				// 	}

				// 	else {
				// 		totalrating = result.data[i].totaltime;
				// 		totalratingcnt = result.data[i].totalCount

				// 	}
				// }


			}

			this.operatorReportsStore.performanceData = {
				"totalcharts": totalcharts,
				"onlinetime": onlinetime,
				"chartsDate": chartsDate,
				"chartsCount": chartsCount,
				"missedchartsDate": missedchartsDate,
				"missedchartsCount": missedchartsCount,
				"missedtotalcharts": missedtotalcharts,
				// "totalonlinetime": this.secondsToTime(totalonlinetime),
				"onlinetimedate": onlinetimedate,
			}
			this.props.appstore.global_errorcount=0;
		})
		.catch(err=> {
			// this.props.appstore.logout();
				console.log(err);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		})
		 
			//this.operatorReportsStore.pageLoder = true;
		 
			setTimeout(()=>{
				this.operatorReportsStore.pageLoder = true;
			},500);
		
	}

	secondsToTime(secs) {
		var hours = Math.floor(secs / (60 * 60));

		var divisor_for_minutes = secs % (60 * 60);
		var minutes = Math.floor(divisor_for_minutes / 60);

		var divisor_for_seconds = divisor_for_minutes % 60;
		var seconds = Math.ceil(divisor_for_seconds);

		if (hours == 0 && minutes == 0) {
			return seconds + "s";
		}
		if (hours == 0) {
			return minutes + "m " + seconds + "s";
		}
		else {
			return hours + "h " + minutes + "m " + seconds + "s";
		}
	}

	secondsToTimeDays(secs) {
		var days = Math.floor(secs / (3600*24));
		var divisor_for_hours = secs % (3600*24);
		var hours = Math.floor(divisor_for_hours / (60 * 60));

		var divisor_for_minutes = secs % (60 * 60);
		var minutes = Math.floor(divisor_for_minutes / 60);

		var divisor_for_seconds = divisor_for_minutes % 60;
		var seconds = Math.ceil(divisor_for_seconds);
		if(days>0 && hours == 0 && minutes == 0 && seconds==0){
			return days + "d";
		}
		if(days>0 && hours > 0 && minutes == 0 && seconds==0){
			return days + "d" + hours + "h";
		}
		if(days>0 && hours > 0 && minutes > 0 && seconds==0){
			return days + "d " + hours + "h " + minutes + "m";
		}
		if(days>0 && hours > 0 && minutes > 0 && seconds>0){
			return days + "d " + hours + "h " + minutes + "m " + seconds + "s";
		}
		if (hours == 0 && minutes == 0) {
			return seconds + "s";
		}
		if (hours == 0) {
			return minutes + "m " + seconds + "s";
		}
		else {
			return hours + "h " + minutes + "m " + seconds + "s";
		}
	}

	handleWebsiteFilter = (selectedOption) => {
		this.operatorReportsStore.allwebsite = selectedOption;

	}

	onSelect = (value, states) => {
		this.setState({ value, states });
		this.getoperatordata();
		this.getOverviewdata();
	};

	refreshimagesetonhover = (color,button)=>{
		if(button==1){
			document.getElementById("detailedimg").src=imageURLs.download_white;
		}else if(button==2){
			document.getElementById("no_chartimg").src=imageURLs.download_white;
		}else if(button==3){
			document.getElementById("onlinetimeimg").src=imageURLs.download_white;
		} 
	}
	refreshimagesetonhoverout = (color,button)=>{
		if(button==1){
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("detailedimg").src=imageurl;
		}else if(button==2){
			
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("no_chartimg").src=imageurl;
		}else if(button==3){
			
			let imageurl= "#3ba8c5" == color ? imageURLs.download_steelblue : 
			"#4cb6ac" == color ? imageURLs.download_seagreen :
			"#ec407a" == color ?  imageURLs.download_pink :
			"#6e4c42" == color ?imageURLs.download_brown :
			"#234e91" == color ?imageURLs.download_navyblue :
			"#e89712" == color ?imageURLs.download_yellow :
			"#b968c7" ==color ?imageURLs.download_voilet : 
			"#039be6" == color ? imageURLs.download_blue :
			"#00887a" == color ? imageURLs.download_darkgreen :
			"#4b8f4e" == color ? imageURLs.download_green :
			"#5f7c8c" == color ? imageURLs.download_grey :
			"#64a9e0"== color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("onlinetimeimg").src=imageurl;
		}
	}

	render() {
		const { appstore } = this.props;
		let isExportEnabled = appstore.userInfo.accountplan.export_report;
		const excss = {
			pointerEvents: "none",
			opacity: 0.3,
            minWidth:"150px",
			margin:"6px"};
		const nocss = {minWidth:"150px",margin:"6px"};	
		const excss1 = {
			pointerEvents: "none",
			opacity: 0.3,
            minWidth:"150px"};
		const nocss1 = {minWidth:"150px"};	
		let self = this;
		if (!this.operatorReportsStore.pageLoder) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}
		if (!this.operatorReportsStore.pageLodernew) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}
		return (

			<div className="innercontent_wrapper w-100 scriptWrapper">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.report_Operators} alt="Charts" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Operators")}</h4>

				</div>

				<div className="row fileTransferWrapper">
					<div className="col-12 w-100 pr-0">
						<div className="settingFormWrapper box">

							<Form>
								<Form.Group as={Row} controlId="formGroupLanguage" >
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Date Range")}</Form.Label>
									<Col sm="8" lg="7" >
										<Form.Control type="text" placeholder={appstore.getLanguageTrans("Select Date Range")}
											name="date_picker"
											value={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
											onClick={() => this.operatorReportsStore.datepicker = !this.operatorReportsStore.datepicker}
											onChange={(event) => {this.getOverviewdata();this.getoperatordata()}}
										/>

										{this.operatorReportsStore.datepicker &&
											<DateRangePicker
												value={this.state.value}
												onSelect={this.onSelect}
												singleDateRange={true}
											/>
										}
									</Col>

									<Col xs="4" lg="2" md="6" sm="2">
										
									</Col>
								</Form.Group>


								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Websites")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">

										<DropDownComponent dropDownOptions={this.operatorReportsStore.websiteDrpDwnOptions}
											store={this.operatorReportsStore}
											callBack={(selectedOption) => { this.operatorReportsStore.allwebsite = selectedOption; this.dptoptforwebiste() }}
											//callBack={(selectedOption) => this.handleWebsiteFilter(selectedOption)}
											selectedOption={this.operatorReportsStore.allwebsite} />
										{/* onChange = {(event) => this.getStartPagedata(event)}  */}
									</Col>
								</Form.Group>
							</Form>


							<div className={appstore.menuActive ? "performance-wrapper" : "performance-wrapper"}>
								<div className="performance-content reportUsage row">

									<div className="chats col-4 reportUsageborder">
										<div className="reportschartsborder">
											<p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.operatorReportsStore.op_onlinetime}</p>
											<p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("ONLINE TIME")}</p>
										</div>
									</div>

									<div className="chats col-4 reportUsageborder">
										<div className="reportschartsborder">
											<p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.operatorReportsStore.avg_rating}</p>
											<p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("AVERAGE RATING")}</p>
										</div>
									</div>
									<div className="chats col-4 reportUsageborder">
										<div >
											<p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.operatorReportsStore.op_were_rated}</p>
											<p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("OPERATORS WERE RATED")}</p>
										</div>
									</div>

									{/* <div className="chats col-6">
										<div className="reportschartsborder">
											<p className="type text-uppercase operatorreportsborder">{appstore.getLanguageTrans("BEST RATED OPERATORS")}</p>
											<p className="type text-uppercase operatorreportsborder"><img src={imageURLs.heartfullIcon} className="img-fluid" /> <img src={imageURLs.heartfullIcon} className="img-fluid" /> <img src={imageURLs.heartfullIcon} className="img-fluid" /> <img src={imageURLs.heartfullIcon} className="img-fluid" /> <img src={imageURLs.heartIcon} className="img-fluid" /></p>
										</div>
									</div>

									<div className="chats col-6">
										<div>
											<p className="type text-uppercase operatorreportsborder">{appstore.getLanguageTrans("WORST RATED OPERATORS")}</p>
											<p className="type " style={{ fontSize: 11 }}>{appstore.getLanguageTrans("Not Enough Data")}</p>
										</div>
									</div> */}
									<div className="col-12 ">
									<Form>
											<Form.Group as={Row}  style={{float: "right",margin: "6px"}} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
												<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,1)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,1)} >

													<img id="detailedimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
														"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />
													<Export_CSV
														data={this.operatorReportsStore.exportdata}
														show={this.operatorReportsStore.export}
														store={this.operatorReportsStore}
														appStore={appstore}
														dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
													/>
												</Button>
											</Form.Group>
										</Form>
									</div>

									<div className="col-12 ">
										<div className="row optpanelHeader">

											<div className="col-2 pl-0">
												<p className="text-left optheader" style={{ paddingLeft: 16, color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Operaters")}</p>
											</div>
											<div className="col-2 ">
												<p className="text-left optheader" style={{ color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Online Time")}</p>
											</div>
											<div className="col-1 ">
												<p className="text-center optheader" style={{ color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Chats")}</p>
											</div>
											<div className="col-1 ">
												<p className="text-center optheader" style={{ color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Missed Chats")}</p>
											</div>
											<div className="col-2">
												<p className="text-center" style={{ color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Chat Time")}</p>
											</div>
											<div className="col-2">
												<p className="text-center" style={{ color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Avg Pickup Time")}</p>
											</div>
											<div className="col-1">
												<p className="text-center" style={{ color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Rating")}</p>
											</div>

											<div className="col-1">
												<p className="text-center" style={{ color: 'white', fontSize: 14, fontFamily: 'Helvetica Neue Cyr Roman' }}>{appstore.getLanguageTrans("Number Of Rates")}</p>
											</div>

										</div>
									</div>


									<div className="scrollWrapper col-12 p-0">
										<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
											<div className="optscriptPanel row">
												<div className="col-12">
													{/* {alert(JSON.stringify(this.reportPerformanceStore.responseData.archivechatdata[0]))} */}
													{
														this.operatorReportsStore.optdata.map(function (fileData, dataIndex) {
															return (
																<div className="row action panelContent align-items-center" key={dataIndex} >

																	<div className="col-2 text-left" style={{padding:12}}>
																		<img src={fileData.image != '' ? self.props.appstore.resourceurl + self.props.appstore.userInfo.accountsmodel.account_uid + "/" + fileData.image : "#3ba8c5" == appstore.selectedColor.color ? imageURLs.avatar_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.avatar_seagreen : "#ec407a" == appstore.selectedColor.color ?imageURLs.avatar_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.avatar_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.avatar_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.avatar_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.avatar_voilet : 
                                                    "#039be6" == appstore.selectedColor.color ? imageURLs.avatar_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.avatar_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.avatar_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.avatar_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.avatar_skyblue : imageURLs.avatar_steelblue} style={{ width: "45px", height: "45px", borderRadius: "50px", border: "2px solid " + appstore.selectedColor.color + " " }} 
																		alt="avatar" className="img-fluid" />{<span className="Sms_operators text-center"> {fileData.nick_name}</span>}
																		{/* <p className="Sms_operators">{fileData.nick_name}</p> */}
																	</div>
																	<div className="col-2">
																		<p className="text-left">{fileData.totaltime} </p>
																	</div>
																	<div className="col-1">
																		<p className="text-center" style={{ color: appstore.selectedColor.color, fontWeight: 700 }}>{fileData._chats} </p>
																	</div>
																	<div className="col-1">
																		{fileData._chat_missed != 0 &&
																			<p className="text-center" style={{ color: "red", fontWeight: 700 }}>{fileData._chat_missed}</p>
																		}
																		{fileData._chat_missed == 0 &&
																			<p className="text-center" style={{  color: "rgb(14, 155, 30)",fontWeight: 700 }}>{fileData._chat_missed}</p>
																		}
																	</div>
																	<div className="col-2">
																		<p className="text-center">{fileData.totaltime2}</p>
																	</div>
																	<div className="col-2">
																		<p className="text-center">{fileData.totaltime4}</p>
																	</div>
																	<div className="col-1">
																		<p className="text-center">{fileData.totaltime3}</p>
																	</div>
																	<div className="col-1">
																		<p className="text-center">{fileData._ops_rating}</p>
																	</div>
																</div>
															);
														})
													}

												</div>
											</div>
										</CustomScroll>
									</div>


								</div>
							</div>



							<div className="col-12 p-0">
								<div className="headerTitle">
									<h4 className="text-left">{appstore.getLanguageTrans("Detailed Information")}</h4>
								</div>
							</div>


							<Form.Group as={Row} controlId="formGroupLanguage">
								<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Operators")}</Form.Label>
								<Col sm="8" lg="9" className="customDrpDwn">
									<DropDownComponent dropDownOptions={this.operatorReportsStore.operator_report_opt}
										key={Math.random()}
										store={this.operatorReportsStore}
										callBack={(selectedOption) => {this.operatorReportsStore.alloperators = selectedOption;this.getOverviewdata()}}
										selectedOption={this.operatorReportsStore.alloperators}
									/>
								</Col>
							</Form.Group>


							<div className="col-12 p-0">
							<Row className="top_Row" id="top_row">
							<Col xs="12" lg="10" md="4" sm="6">
								<div className="headerTitle">
									<h4 className="text-left">{appstore.getLanguageTrans("NUMBER OF CHATS")}</h4>
								</div>
							</Col>
								<Col xs="4" lg="2" md="6" sm="2">
											<Form.Group as={Row} style={{paddingTop:36 , right: 15}} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
												<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss1:nocss1} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,2)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,2)} >

													<img id="no_chartimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
														"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} alt="" style={{ paddingBottom: '4px' }} alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />

													<ExportCSV
														data={this.operatorReportsStore.chartexportdata}
														show={this.operatorReportsStore.export}
														store={this.operatorReportsStore}
														appStore={appstore}
														dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
													/>
												</Button>
											</Form.Group>
										
									</Col>
							</Row>
							</div>


							<div className="row profile_wrapper">
								<div className="settingsForm col-12 w-100 pr-0">
									<div className="settingFormWrapper box">
									  {this.operatorReportsStore.performanceData.chartsDate &&
										<ChartsColumChart
										key={Math.random()}
										data={this.operatorReportsStore.performanceData}
										appstore={appstore}
										store={this.operatorReportsStore} />
									  }
									</div>
								</div>
							</div>


						
							<div className="col-12 p-0">
							<Row className="top_Row" id="top_row">
							<Col xs="12" lg="10" md="4" sm="6">
								<div className="headerTitle">
									<h4 className="text-left">{appstore.getLanguageTrans("ONLINE TIME")}</h4>
								</div>
							</Col>
								<Col xs="4" lg="2" md="6" sm="2">
											<Form.Group as={Row} style={{paddingTop:36 , right: 15}} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
												<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss1:nocss1} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color,3)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color,3)} >

													<img id="onlinetimeimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
														"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} alt="" style={{ paddingBottom: '4px' }} alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />

													<Export_to_CSV
														data={this.operatorReportsStore.chartexportdata}
														show={this.operatorReportsStore.export}
														store={this.operatorReportsStore}
														appStore={appstore}
														dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
													/>
												</Button>
											</Form.Group>
										
									</Col>
							</Row>
							</div>

							<div className="row profile_wrapper">
								<div className="settingsForm col-12 w-100 pr-0">
									<div className="settingFormWrapper box">
									{this.operatorReportsStore.performanceData.onlinetimedate &&
										<OnlineTimeChart
											key={Math.random()}
											data={this.operatorReportsStore.performanceData}
											appstore={appstore}
											store={this.operatorReportsStore} />
									}
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>


			</div>
		)
	}
});
export default OperatorReports;