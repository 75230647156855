import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../../../sharedFiles/ImagesURLs';


const DeptDataModeComponent = observer(class DeptDataModeComponent extends Component {

	constructor (props) {
        super(props);
 
    }
	
	componentDidMount(){
	}

	componentWillReceiveProps(nextProps){

		
	}
	
	render(){

		const { department,
				deptStore,
				dptData,
			    appstore} = this.props;

		return (
			<div className="row action panelContent">
				<div className="col-3">
					<p className="text-left">{department.name}</p>
				</div>
				<div className="col-3">
					<p className="text-left">{department.email_messages}</p>
				</div>
				<div className="col-3">
				<p className="text-left" style={{width:"250px"}}>
					{ department.operators !=undefined &&
						department.operators.map(function(operator,operatorIndex) {
							return(
								<span key={operatorIndex}>{operator.label}{operatorIndex!=department.operators.length-1 ? ', ':''}</span>
								// <p key={operatorIndex}>{operator.label}{operatorIndex!=department.operators.length-1 ? ', ':''}</p>
							)
						})
					}
				</p>
				</div>
				<div className="col-3 optionArea align-self-center">
					<div className="editWrapper row m-0">
						
						<div className="align-self-center cursor-pointer" onClick={() => deptStore.editDepartment(department)}>
							<p><img src={imageURLs.editIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("edit")}</span></p>
						</div>
							<div className="align-self-center cursor-pointer" onClick = {() => deptStore.deleteComp(department,"Department")}>
								<p><img src={imageURLs.deleteIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("delete")}</span></p>
							</div>
					</div>
				</div>
			</div>
		)
	}
})
export default DeptDataModeComponent;
