import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import { css } from '@emotion/core';
import ReportChartsStore from './ReportChartsStore'
import { GridLoader } from 'react-spinners';
import CustomScroll from 'react-custom-scroll';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import DateRangePicker from "react-daterange-picker";
import AverageChatlengthCharts from './AverageChatlengthCharts';
import TotalChartTimeChart from './TotalChartTimeChart';
import SplineAreaChart from '../SplineAreaChart';
import ChatCountCSV from './chatCountCSV';
import ChatLengthCSV from './chatLengthCSV';
import TotalChatTimeCSV from './totalChatTimeCSV';
import originalMoment from "moment";
import axios from 'axios';
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);


const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const ReportCharts = observer(class ReportCharts extends Component {

	constructor(props) {
		const today = moment();
		super(props);
		this.reportChartsStore = new ReportChartsStore(this.props.appstore);
		this.state = {
			isOpen: false,
			value: moment.range(today.clone().subtract(14, "days"), today.clone())
		}
	}


	componentDidMount() {
		this.reportChartsStore.setWebsites(this.props.appstore.userInfo.allwebistes, this.props.appstore)
		this.reportChartsStore.setDepartments(this.props.appstore.userInfo.alldepartments, this.props.appstore)
		this.reportChartsStore.setOperators(this.props.appstore.userInfo.alloperators, this.props.appstore)
		
		//	this.reportPerformanceStore.isPageLoaded=true
		var date = new Date().getDate();
		var month = new Date().getMonth() + 1;
		var year = new Date().getFullYear();

		this.getChartsdata(date + '-' + month + '-' + year);


	}


	getChartsdata = (dateRange = null) => {

		this.reportChartsStore.pageLoder = false;
		const today = moment();
		let since_from_today = today.clone().subtract(14, "days");
		const data = {
			"since": dateRange != null ? since_from_today.format("DD-MM-YYYY"):this.state.value.start.format("DD-MM-YYYY"),
			"till": dateRange != null ? dateRange:this.state.value.end.format("DD-MM-YYYY"),
			"alltype": ["pickup_time", "auto_invitations", "start_pages", "chats","avg_chats"],
			"department_uid": this.reportChartsStore.alldepartment.department_uid,
			"website_uid": this.reportChartsStore.allwebsite.website_uid,
			"operator_uid": this.reportChartsStore.alloperators.user_uid
		}
		this.reportChartsStore.datepicker = false;
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getPerformanceRecords.json',
			headers: { 'content-type': 'application/json' },
			data: data,
		}).then(result => {
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			this.reportChartsStore.exportdata = JSON.parse(JSON.stringify(result.data));
			let chartsDate = [];
			let chartsCount = [];
			let chartstime = [];
			let previouschartsDate = "";
			let totalchartSum = 0;
			let totalchartstime = 0;
			let AverageChatlength = 0;
			let charttimeDate = "";
			let Chatlength = [];
			let Chatlengthdate = [];
			//
			let avg_chartsDate = [];
			let avg_chartsCount = [];
			let avg_chartstime = [];
			let avg_Chatlength = [];

			for (let i in result.data) {
				if (i === "chartRecords") {
					if (result.data[i] != null) {
						// for (let j in result.data[i]) {  
							for (let j=0 ;j<result.data[i].length;j++) { 
							if (previouschartsDate === result.data[i][j].quarter_date) {
								chartsCount[chartsCount.length - 1] = chartsCount[chartsCount.length - 1] + result.data[i][j].cnt;
								totalchartSum = totalchartSum + result.data[i][j].cnt;
							}
							if (charttimeDate === result.data[i][j].quarter_date) {
								chartstime[chartstime.length - 1] = chartstime[chartstime.length - 1] + result.data[i][j].time;
								totalchartstime = totalchartstime + result.data[i][j].time;
							}

							if (Chatlengthdate === result.data[i][j].quarter_date) {
								Chatlength[Chatlength.length - 1] = Chatlength[Chatlength.length - 1] + result.data[i][j].real_time;
								AverageChatlength = AverageChatlength + result.data[i][j].real_time;
							}

							else {
								chartsDate.push(result.data[i][j].quarter_date)
								chartsCount.push(result.data[i][j].cnt)
								chartstime.push(result.data[i][j].time)
								Chatlength.push(result.data[i][j].real_time)
								AverageChatlength = AverageChatlength + result.data[i][j].real_time;
								totalchartSum = totalchartSum + result.data[i][j].cnt;
								totalchartstime = totalchartstime + result.data[i][j].time;
								previouschartsDate = result.data[i][j].quarter_date;
								charttimeDate = result.data[i][j].quarter_date;
								Chatlengthdate = result.data[i][j].quarter_date;
							}

						}
					}
				}
				if (i === "avgchartRecords") {
					if (result.data[i] != null) {
						for (let j=0 ;j<result.data[i].length;j++) { 
							avg_chartsDate.push(result.data[i][j].quarter_date)
							avg_chartsCount.push(result.data[i][j].cnt)
							avg_chartstime.push(Number(result.data[i][j].time))
							avg_Chatlength.push(result.data[i][j].real_time);
				
						}
					}
				}

			}
			this.reportChartsStore.performanceData = {
				"chartsDate": chartsDate,
				"chartsCount": chartsCount,
				"totalchartSum": totalchartSum,
				"totalchartstime": this.secondsToTime(totalchartstime),
				"chartstime": chartstime,
				"Chatlength": Chatlength,
				"AverageChatlength": (totalchartstime>0 && totalchartSum>0) ? this.secondsToTime(totalchartstime/totalchartSum):this.secondsToTime(0),
			};
			this.reportChartsStore.avg_performanceData = {
				"chartsDate": avg_chartsDate,
				"chartsCount": avg_chartsCount,
				"chartstime": avg_chartstime,
				"Chatlength": avg_Chatlength,
			};
			this.reportChartsStore.pageLoder = true;
			// console.log(this.reportChartsStore.performanceData)
// console.log(this.reportChartsStore.avg_performanceData);
this.props.appstore.global_errorcount=0;
		}

		).catch((error) => {
			// if (error.response) {
			// 	console.log(error)
			// 	this.props.appstore.logout();
			// }
			this.reportChartsStore.pageLoder = true;
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		});
	}

	secondsToTime(secs) {
		var hours = Math.floor(secs / (60 * 60));

		var divisor_for_minutes = secs % (60 * 60);
		var minutes = Math.floor(divisor_for_minutes / 60);

		var divisor_for_seconds = divisor_for_minutes % 60;
		var seconds = Math.ceil(divisor_for_seconds);

		if (hours == 0 && minutes == 0) {
			return seconds + "s";
		}
		if (hours == 0) {
			return minutes + "m " + seconds + "s";
		}
		else {
			return hours + "h " + minutes + "m " + seconds + "s";
		}
	}

	handleWebsiteFilter = (selectedOption) => {

		this.reportChartsStore.allwebsite = selectedOption;
		this.reportChartsStore.alldepartment = "";
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'getDeptsforWebsite.json',
			headers: { 'content-type': 'application/json' },
			data: selectedOption,
		}).then(result => {
			// console.log(result);
			//this.getStartPagedata()
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			this.reportChartsStore.setDepartments(result.data);
			this.props.appstore.global_errorcount=0;
		}).catch((error) => {
			// if (error.response) {
			// 	console.log(error)
			// 	this.props.appstore.logout();
			// }
				console.log(error);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		});

	}

	dptoptforwebiste = (uid = "") => {
		//this.reportChartsStore.pageLoder==false
		var website_id = this.reportChartsStore.allwebsite.website_uid
		if(website_id==""){
			this.reportChartsStore.setDepartments(this.props.appstore.userInfo.alldepartments, this.props.appstore);
			this.reportChartsStore.setOperators(this.props.appstore.userInfo.alloperators, this.props.appstore)
			return;
		} 
		axios({
			method: 'POST',
			url: this.props.appstore.url + 'dptoptforwebiste.json',
			headers: { 'content-type': 'application/json' },
			data: { "website_uid": website_id, "department_uid": uid },
		}).then(result => {
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				this.props.appstore.logout();
				}
			  }
			let depts = result.data.departmentsmodel;
			let alldept = { "label": "All Departments", "value": 0, "department_uid": "" };
			depts.splice(0, 0, alldept);
			let index = 1;
			for (let x in depts) {
				if (x > 0) {
					depts[x].label = depts[x].name;
					depts[x].value = depts[x].department_uid;
					depts[x].department_uid = depts[x].department_uid;
					index++;
				}
			}
			//console.log(this.chat_report_dept)
			this.reportChartsStore.chat_report_dept = [];
			this.reportChartsStore.chat_report_dept = depts;
			this.reportChartsStore.alldepartment = this.reportChartsStore.chat_report_dept[0];



			let opt = result.data.usersmodel;
			let allopt = { "label": "All Operators", "value": 0, "user_uid": "" };
			opt.splice(0, 0, allopt);
			let ind = 1;
			for (let x in opt) {
				if (x > 0) {
					opt[x].label = opt[x].nick_name;
					opt[x].value = opt[x].user_uid;
					opt[x].user_uid = opt[x].user_uid;
					ind++;
				}
			}
			this.reportChartsStore.chat_report_opt = [];
			this.reportChartsStore.chat_report_opt = opt;
			this.reportChartsStore.alloperators = this.reportChartsStore.chat_report_opt[0];
			this.props.appstore.global_errorcount=0;
		})
		.catch(err=> {
			// this.props.appstore.logout();
			console.log(err);
				console.log("error count :"+this.props.appstore.global_errorcount);
				this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
				if(this.props.appstore.global_errorcount>20){
				// this.props.appstore.logout();
				}
		})
		
		this.getChartsdata();
	}

	onSelect = (value, states) => {
		this.setState({ value, states });
		this.getChartsdata();
	};

	refreshimagesetonhover = (color, button) => {
		if (button == 1) {
			document.getElementById("avgchatimg").src = imageURLs.download_white;
		} else if (button == 2) {
			document.getElementById("no_chartimg").src = imageURLs.download_white;
		} else if (button == 3) {
			document.getElementById("totalchatimg").src = imageURLs.download_white;
		}
	}
	refreshimagesetonhoverout = (color, button) => {
		if (button == 1) {
			let imageurl = "#3ba8c5" == color ? imageURLs.download_steelblue :
				"#4cb6ac" == color ? imageURLs.download_seagreen :
					"#ec407a" == color ? imageURLs.download_pink :
						"#6e4c42" == color ? imageURLs.download_brown :
							"#234e91" == color ? imageURLs.download_navyblue :
								"#e89712" == color ? imageURLs.download_yellow :
									"#b968c7" == color ? imageURLs.download_voilet :
										"#039be6" == color ? imageURLs.download_blue :
											"#00887a" == color ? imageURLs.download_darkgreen :
												"#4b8f4e" == color ? imageURLs.download_green :
													"#5f7c8c" == color ? imageURLs.download_grey :
														"#64a9e0" == color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("avgchatimg").src = imageurl;
		} else if (button == 2) {

			let imageurl = "#3ba8c5" == color ? imageURLs.download_steelblue :
				"#4cb6ac" == color ? imageURLs.download_seagreen :
					"#ec407a" == color ? imageURLs.download_pink :
						"#6e4c42" == color ? imageURLs.download_brown :
							"#234e91" == color ? imageURLs.download_navyblue :
								"#e89712" == color ? imageURLs.download_yellow :
									"#b968c7" == color ? imageURLs.download_voilet :
										"#039be6" == color ? imageURLs.download_blue :
											"#00887a" == color ? imageURLs.download_darkgreen :
												"#4b8f4e" == color ? imageURLs.download_green :
													"#5f7c8c" == color ? imageURLs.download_grey :
														"#64a9e0" == color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("no_chartimg").src = imageurl;
		} else if (button == 3) {

			let imageurl = "#3ba8c5" == color ? imageURLs.download_steelblue :
				"#4cb6ac" == color ? imageURLs.download_seagreen :
					"#ec407a" == color ? imageURLs.download_pink :
						"#6e4c42" == color ? imageURLs.download_brown :
							"#234e91" == color ? imageURLs.download_navyblue :
								"#e89712" == color ? imageURLs.download_yellow :
									"#b968c7" == color ? imageURLs.download_voilet :
										"#039be6" == color ? imageURLs.download_blue :
											"#00887a" == color ? imageURLs.download_darkgreen :
												"#4b8f4e" == color ? imageURLs.download_green :
													"#5f7c8c" == color ? imageURLs.download_grey :
														"#64a9e0" == color ? imageURLs.download_skyblue : imageURLs.download_steelblue;
			document.getElementById("totalchatimg").src = imageurl;
		}
	}

	render() {
		const { appstore } = this.props;
		let isExportEnabled = appstore.userInfo.accountplan.export_report;
		const excss = {
			pointerEvents: "none",
			opacity: 0.3,
            minWidth:"150px"};
		const nocss = {minWidth:"150px"};
		let self = this;
		if (!this.reportChartsStore.pageLoder) {
			return (<div><div className="adminloader">
				<GridLoader
					css={override}
					size={20}
					marging={2}
					color={this.props.appstore.selectedColor.color}
				/>
			</div> </div>)
		}

		return (

			<div className="innercontent_wrapper w-100 scriptWrapper">

				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.invitationIcon} alt="Charts" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Chats")}</h4>

				</div>

				<div className="row fileTransferWrapper">
					<div className="col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<Form>
								<Form.Group as={Row} controlId="formGroupLanguage">

									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Date Range")}</Form.Label>
									<Col sm="8" lg="7">
										<Form.Control type="text" placeholder={appstore.getLanguageTrans("Select Date Range")}
											name="date_picker"
											value={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
											onClick={() => this.reportChartsStore.datepicker = !this.reportChartsStore.datepicker}
											onChange={(event) => this.getChartsdata()}

										/>

										{this.reportChartsStore.datepicker &&
											<DateRangePicker
												value={this.state.value}
												onSelect={this.onSelect}
												singleDateRange={true}
											/>
										}
									</Col>

								</Form.Group>


								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Websites")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">

										<DropDownComponent dropDownOptions={this.reportChartsStore.websiteDrpDwnOptions}
											store={this.reportChartsStore}
											callBack={(selectedOption) => { this.reportChartsStore.allwebsite = selectedOption; this.dptoptforwebiste(); this.getChartsdata() }}
											selectedOption={this.reportChartsStore.allwebsite} />
										{/* onChange = {(event) => this.getStartPagedata(event)}  */}
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Departments")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">
										<DropDownComponent dropDownOptions={this.reportChartsStore.chat_report_dept}
											store={this.reportChartsStore}
											callBack={(event) => { this.reportChartsStore.alldepartment = event; this.getChartsdata() }}
											selectedOption={this.reportChartsStore.alldepartment}
										/>

									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="formGroupLanguage">
									<Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Operators")}</Form.Label>
									<Col sm="8" lg="9" className="customDrpDwn">
										<DropDownComponent dropDownOptions={this.reportChartsStore.chat_report_opt}
											store={this.reportChartsStore}
											callBack={(selectedOption) => { this.reportChartsStore.alloperators = selectedOption; this.getChartsdata() }}
											selectedOption={this.reportChartsStore.alloperators} />
										{/* onChange={(event) => this.ShowCurrentDate(event)}  */}
									</Col>
								</Form.Group>
							</Form>
							<div className="performance-content row">
								<div className="col-12">
									<div className={appstore.menuActive ? "performance-wrapper " : "performance-wrapper"}>
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Total Summary")}</h4>
										</div>
									</div>

									<div className={appstore.menuActive ? "performance-wrapper" : "performance-wrapper"}>
										<div className="performance-content reportcharts row">
											<div className="chats col-4  ">
												<div className="reportschartsborder">
													<p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.reportChartsStore.performanceData.totalchartSum}</p>
													<p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Number Of Chats")}</p>
												</div>
											</div>

											<div className="chats col-4">
												<div className="reportschartsborder">
													<p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.reportChartsStore.performanceData.AverageChatlength}</p>
													<p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Average Chat Length")}</p>
												</div>
											</div>
											<div className="chats col-4 ">
												<div>
													<p className="count reportschartstext" style={{ color: appstore.selectedColor.color }}>{this.reportChartsStore.performanceData.totalchartstime}</p>
													<p className="type text-uppercase reportschartsfontsize">{appstore.getLanguageTrans("Total Chat Time")}</p>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>

							<div className="col-12 p-0">
								<Row className="top_Row" id="top_row">
									<Col xs="12" lg="10" md="4" sm="6">
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Number Of Chats")}</h4>
										</div>
									</Col>
									<Col xs="4" lg="2" md="6" sm="2">
										<Form.Group as={Row} style={{ paddingTop: 36, right: 15 }} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
											<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color, 2)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color, 2)} >

												<img id="no_chartimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
													"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} alt="" style={{ paddingBottom: '4px' }} alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />

												<ChatCountCSV
													data={this.reportChartsStore.exportdata}
													show={this.reportChartsStore.export}
													store={this.reportChartsStore}
													appStore={appstore}
													dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
												/>
											</Button>
										</Form.Group>

									</Col>
								</Row>
							</div>

							<div className="row profile_wrapper">
								<div className="settingsForm col-12 w-100 pr-0">
									<div className="settingFormWrapper box">
										<SplineAreaChart
											key={Math.random()}
											data={this.reportChartsStore.performanceData}
											appstore={appstore}
											store={this.reportChartsStore}
										/>
									</div>
								</div>
							</div>

							<div className="col-12 p-0">
								<Row className="top_Row" id="top_row">
									<Col xs="12" lg="10" md="4" sm="6">
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Average Chat Length")}</h4>
										</div>
									</Col>
									<Col xs="4" lg="2" md="6" sm="2">
										<Form.Group as={Row} style={{ paddingTop: 36, right: 15 }} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
											<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color, 1)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color, 1)}  >

												<img id="avgchatimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
													"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} alt="" style={{ paddingBottom: '4px' }} alt="" style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />

												<ChatLengthCSV
													data={this.reportChartsStore.exportdata}
													show={this.reportChartsStore.export}
													store={this.reportChartsStore}
													appStore={appstore}
													dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
												/>
											</Button>
										</Form.Group>

									</Col>
								</Row>
							</div>
							
							<div className="row profile_wrapper">
								<div className="settingsForm col-12 w-100 pr-0">
									<div className="settingFormWrapper box">
										{/* <AverageChatlengthCharts
											key={Math.random()}
											data={this.reportChartsStore.performanceData}
											appstore={appstore}
											store={this.reportChartsStore}
										/> */}
										<AverageChatlengthCharts
											key={Math.random()}
											data={this.reportChartsStore.avg_performanceData}
											appstore={appstore}
											store={this.reportChartsStore}
										/>
									</div>
								</div>
							</div>

							<div className="col-12 p-0">
								<Row className="top_Row" id="top_row">
									<Col xs="12" lg="9" md="3" sm="3">
										<div className="headerTitle">
											<h4 className="text-left">{appstore.getLanguageTrans("Total Chat Time")}</h4>
										</div>
									</Col>
									{/* <Col xs="12" lg="5" md="4" sm="6" style={{ paddingLeft: 50 }}>
										<div className="headerTitle">
											<Form.Group as={Row} controlId="formGroupNameBefore">
												<Col className="custom_radioBtn">
													<Col sm="6" lg="1" className="searchText ">
														<CheckBoxComponent radioValue={this.reportChartsStore.Simultaneous}
															callBack={(e) => this.reportChartsStore.Simultaneous = !this.reportChartsStore.Simultaneous}
														/>

													</Col></Col>
												<Form.Label column sm="6" lg="7" style={{ paddingLeft: 0 }}>{appstore.getLanguageTrans("Merge Simultaneous Chats")}</Form.Label>

											</Form.Group>
										</div>
									</Col> */}
									<Col xs="4" lg="2" md="6" sm="2" style={{ paddingLeft: 39, paddingTop: 41, left: 34 }}>
										<Form>
											<Form.Group as={Row} title={isExportEnabled!=1 ? appstore.getLanguageTrans("Please upgrade your plan"):""}>
												<Button className="top_btn export_csvbtn custom_btn  addBtn text-uppercase" style={isExportEnabled!=1 ? excss:nocss} onMouseOver={() => self.refreshimagesetonhover(appstore.selectedColor.color, 3)} onMouseOut={() => self.refreshimagesetonhoverout(appstore.selectedColor.color, 3)} >
													<img id="totalchatimg" className="img-fluid" src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.download_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.download_seagreen : "#ec407a" == appstore.selectedColor.color ? imageURLs.download_pink : "#6e4c42" == appstore.selectedColor.color ? imageURLs.download_brown : "#234e91" == appstore.selectedColor.color ? imageURLs.download_navyblue : "#e89712" == appstore.selectedColor.color ? imageURLs.download_yellow : "#b968c7" == appstore.selectedColor.color ? imageURLs.download_voilet :
														"#039be6" == appstore.selectedColor.color ? imageURLs.download_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.download_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.download_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.download_grey : "#64a9e0" == appstore.selectedColor.color ? imageURLs.download_skyblue : imageURLs.spell_steelblue} style={{ paddingBottom: '4px', marginLeft: -11, paddingRight: "3px" }} />

													<TotalChatTimeCSV
														data={this.reportChartsStore.exportdata}
														show={this.reportChartsStore.export}
														store={this.reportChartsStore}
														appStore={appstore}
														dateselected={this.state.value.start.format("DD-MM-YYYY") + " - " + this.state.value.end.format("DD-MM-YYYY")}
													/>
												</Button>
											</Form.Group>
										</Form>
									</Col>
								</Row>

							</div>

							<div className="row profile_wrapper">
								<div className="settingsForm col-12 w-100 pr-0">
									<div className="settingFormWrapper box">
										<TotalChartTimeChart
											key={Math.random()}
											data={this.reportChartsStore.performanceData}
											appstore={appstore}
											store={this.reportChartsStore} />
									</div>
								</div>
							</div>






						</div>
					</div>
				</div>
			</div>
		)
	}

})

export default ReportCharts;