import { decorate, observable, extendObservable } from 'mobx';
import imageURLs from '../../../sharedFiles/ImagesURLs';
import axios from 'axios';
import {chatResponses} from '../../../assets/JSON/chats_responses';
import draftToHtml from 'draftjs-to-html';
import {  convertToRaw, ContentState } from 'draft-js';


class ResponseStore {
	  

  	constructor(){
        
        this.operatorDrpDwnOptions = [];
        this.deptDrpDwnOptions = [];
        this.websiteDrpDwnOptions = [];
        this.allResponses=[];            //this is filtered All response for login user
        this.allDepartments =[];        //this is filtered All departments for login user
        this.allWebsites =[];          //this is filtered All websites for login user
        this.allOperators =[];        //this is filtered All operators for login user
        this.filterDeptDrpDwnOptions=[];
        this.filterWebsiteDrpDwnOptions=[];
        this.deleteImage=false;
        this.imageForDelete="";
        this.responseImage="";
        this.titleExist=false;
        this.responseChangeObj = {
          "question" : "",
          "value" : "",
          "operator" : "",
          "department" : "",
          "website" : "",
          "type":"",
          "itemorder":null,
          "resource_uids":[],
          "response_uid":"",
          "resource_titles":[],
          "resource_link_live_types":[],
          "resource_detach":[],
          "account_id":"",
          "imageslist":[],
          "editedimageslist":[], 
        }
       
        this.responseFilter = {
          "operator":"",
          "department" : "",
          "website" : ""
        }
        this.listFilter = {
          "department" :this.filterDeptDrpDwnOptions[0] ,
          "website" :   this.filterWebsiteDrpDwnOptions[0] 

        }
        this.add = true;
        this.responses=[];
        this.responses1 = {
          "id":"",
          "question":"",
          "value":"",
          "op_uid":"",
          "department_uid":"",
          "website_uid":""
        };
        this.editing = false;
        this.newKey=Math.random();
        this.responseForDelete="";
  
        this.file=[];
        this.fileNames=[]; 
        this.image="";
        this.selectedfile=[];
        this.cropimage = {
          src: null,
          crop: {
            unit: '%',
            width: 50,
            aspect: 1 / 1,
          },
          croppedImageUrl: null,
        } 
        this.arraycroppedimg=[];
        this.imageCropModel = false;
        this.imageRef = null;
        this.imagewarning = false;
        this.warning_image_name= "";
        this.deleteImg=false;
        this.fileMimeType=[];
        this.maxImage=false;
        this.modifiedimages=[];
        
     }
     
     
	showResponses(data,appstore) {
 
    let allresponses = data.responsesmodel;
    let loggedOp = appstore.userInfo.usersmodel.user_uid;
    let allDept = data.dropdowndatavo.opDepts;
    let allWebsites = data.dropdowndatavo.opWebsites;
    let order=appstore.userInfo.usersmodel.response_order; 
    if(allresponses.length == 0 || allresponses==null){
      this.responses=allresponses;
      this.allResponses=allresponses.slice(0);
    }

    if(appstore.isAdmin){
      if(typeof(order)=="string" && order.length !=0){
        order = JSON.parse(order);
        allresponses =  this.sortResponse(order,allresponses);
      }
      this.responses=allresponses;
      this.allResponses=allresponses.slice(0);
      return;
    }
    let arrayresponse =[]; 
    let filteredResponse = allresponses.filter(function (response) {
      if(response.op_uid.length !=0 && response.op_uid === loggedOp ){
        arrayresponse.push(response);
      }
     else if(response.op_uid.length==0 && response.department_uid.length !=0 ){
        if(allDept.includes(response.department_uid)){
          arrayresponse.push(response);
        }
      }
      else if(response.op_uid.length==0 && response.department_uid.length ==0 && response.website_uid.length ==0){
      arrayresponse.push(response); 
      }
      else if (response.op_uid.length==0 && response.department_uid.length ==0 && response.website_uid.length !=0)
      {
        if(allWebsites.includes(response.website_uid)){
          arrayresponse.push(response);
        }

      }
      return arrayresponse;
    });
    //sort as per order for operator role
    if(typeof(order)=="string" && order.length !=0){
      order = JSON.parse(order);
      arrayresponse =  this.sortResponse(order,arrayresponse);
    }

    //store for using while filtering responses
     this.allResponses = arrayresponse;
    //clone array of responses
    this.responses = arrayresponse.slice(0);
	}

    sortResponse(order,responses){
      let sortedOrder =[];
      //sort order as per value
      if(order){
        for (var uid in order) {
          sortedOrder.push([uid, order[uid]]);
      } 
       sortedOrder.sort(function(a, b) {
            return a[1] - b[1];
        });
      }
      //sort responses as per the orders saved by user
      let sortedResponses=[];
        for(let i in sortedOrder)
        {
          for(let j in responses){
            if(sortedOrder[i][0]==responses[j].response_uid){
                sortedResponses.push(responses[j]);
            }
          }
        }
  
        let filtered =responses.filter( function(e) {
              if(this.indexOf(e) < 0){
                    sortedResponses.push(e);
               }
            },sortedResponses); 
      return sortedResponses;
    }


    deleteResponseImg(appstore) {
   
      let  response = this.responseChangeObj;

      response.deleteimages =  this.modifiedimages;
      response.account_uid =  appstore.userInfo.accountsmodel.account_uid ;
    
      const formData = new FormData();
      formData.append( "deleteimages",this.modifiedimages);
      formData.append("account_uid", appstore.userInfo.accountsmodel.account_uid);
      formData.append("response_uid",response.response_uid);
        axios({
          method: 'POST',
          url: appstore.url + 'deleteResponseImage.json',
          headers: { 'content-type': 'multipart/form-data' },
          data:  formData
        }).then(result => {
        
          appstore.global_errorcount=0;
        }).catch((error) => {
          // appstore.logout();
          console.log(error);
          console.log("error count :"+appstore.global_errorcount);
          appstore.global_errorcount=appstore.global_errorcount+1;
          if(appstore.global_errorcount>20){
          // appstore.logout();
          }
        });

    }


     setWebsites(websitesmodel,appstore) {
      let websites = websitesmodel;
    
      if(websites !=null){

        let all = {"label":"All","value":0,"id":0,"optionType":"websiteAll","website_uid":""};
        websites.splice(0, 0,all);   //insert All option in dropdown options
        let x;
        let index =0;
        for (x in websites)
        {
          if(x>0){
          websites[x].label=websites[x].name;
          websites[x].value= index;
          }
          index++;
        }
       
       // websites.push({"label":"All","value":index ,"id":0, "optionType":"websiteAll","website_uid":""})
        // if(!appstore.isAdmin) {
          //add response dropdown
         this.websiteDrpDwnOptions = websites;
        // }
        //store backup and use while filtering responses
        if(this.allWebsites.length == 0){
          this.allWebsites = websites;
        }
        //clone data in new array
        this.filterWebsiteDrpDwnOptions=  websites.slice(0)
        this.listFilter.website=this.filterWebsiteDrpDwnOptions[0];
      }
     }

     setDepartments(departmentsmodel,appstore) {
      let departments = departmentsmodel;
  
      if(departments !=null){

        let all = {"label":"All","value":0,"id":0,"optionType":"deptAll","department_uid":""};
        departments.splice(0, 0,all);   //insert All option in dropdown options  
        let i;
        let index =0;
        for (i in departments)
        {
          if(i>0){
            departments[i].label=departments[i].name;
            departments[i].value= index;
          }
          index++;
        }
       // departments.push({"label":"All","value":index,"id":0 , "optionType":"deptAll","department_uid":""});
        // if(appstore.isAdmin) {
           //add response dropdown
          this.deptDrpDwnOptions = departments;
        //  }
        //store backup and use while filtering responses
        if(this.allDepartments.length == 0){
        this.allDepartments = departments;
        }
        //clone data in new array
        this.filterDeptDrpDwnOptions= JSON.parse(JSON.stringify(departments));
        this.listFilter.department = this.filterDeptDrpDwnOptions[0];
        
      }else{
        alert();
        let departments=[];
        let all = {"label":"All","value":0,"id":0,"optionType":"deptAll","department_uid":""};
        departments.splice(0, 0,all);   //insert All option in dropdown options  
        this.deptDrpDwnOptions = departments;
        //store backup and use while filtering responses
        if(this.allDepartments.length == 0){
          this.allDepartments = departments;
          }
          //clone data in new array
          this.filterDeptDrpDwnOptions= JSON.parse(JSON.stringify(departments));
          this.listFilter.department = this.filterDeptDrpDwnOptions[0];
      }
     }

     setOperators(usersmodel,appstore) {
       let operators =usersmodel;
      if(operators !=null){
        let all = {"label":"All","value":0,"id":0,"op_uid":""};
        operators.splice(0, 0,all); // insert ALl option at first position
        let x;
        let index =0;
        for (x in operators)
        { 
          if(x>0){
          operators[x].label=operators[x].nick_name;
          operators[x].value= index;
          }
          index++;
        }
       // clone data and use while filtering dropdowns
       if(this.allOperators.length == 0){
          this.allOperators=operators;
       }
        this.operatorDrpDwnOptions = JSON.parse(JSON.stringify(operators));
      }
     }

     setDepartmentsforWebsites(departmentsmodel) {
      let departments = departmentsmodel;
    
      if(departments !=null){
        
        let all = {"label":"All","value":0,"id":0,"department_uid":""};
        //insert All option in dropdown options
        departments.splice(0, 0,all);
        let i;
        let index =0;
        for (i in departments)
        {
          if(i>0){
            departments[i].label=departments[i].name;
            departments[i].value= index;
          }
          index++;
        }
        //clone data in new array
        this.filterDeptDrpDwnOptions= departments.slice(0);
        this.listFilter.department = this.filterDeptDrpDwnOptions[0];
        
      }
     }

     editResponseData(response,appstore) {
      // if(appstore.isAdmin){
       this.filterDropdown(response);
      // }
       this.resetValues();
       this.newKey = Math.random();
        window.scrollTo(0,0);
      
       response.cloned = Object.assign({},response);
       response.cloned.operator = this.responseFilter.operator;
       response.cloned.department = this.responseFilter.department;
       response.cloned.website = this.responseFilter.website;
       this.responseChangeObj.value = "";
       this.modifiedimages=[];
        this.responseChangeObj = response.cloned;
        //console.log("response.imageslist====="+response.imageslist.length);
        this.responseChangeObj.editedimageslist=response.imageslist; 
        if(response.imageslist.length >=3){
        this.maxImage=true;
        }
       this.add = false;

     }
     
     filterDropdown(response){
        let assignedWebsite = response.website_uid ? this.websiteDrpDwnOptions.find(option => option.website_uid === response.website_uid) : this.websiteDrpDwnOptions[0];
       
        let assignedDept = response.department_uid ? this.deptDrpDwnOptions.find(option => option.department_uid === response.department_uid) : this.deptDrpDwnOptions[0];
        
        let assignedOp = response.op_uid ? this.operatorDrpDwnOptions.find(option => option.user_uid === response.op_uid) : this.operatorDrpDwnOptions[0];
       
        this.responseFilter = {
          "operator" : assignedOp ? assignedOp : this.websiteDrpDwnOptions[0],
          "department":assignedDept ? assignedDept : this.deptDrpDwnOptions[0],
          "website" : assignedWebsite ? assignedWebsite : this.operatorDrpDwnOptions[0]
         }
     }

     deleteResponse(response) {
       this.deleteResponseConfirm = true;
       this.responseForDelete = response;
     }
     
      onEditorStateChange(editorState) {
       this.editorState = editorState;
       this.responseChangeObj.value = draftToHtml(convertToRaw(this.editorState.getCurrentContent()));
       this.editing = true;

     }

     downloadingfile = (fileurl,filename,appstore) =>{
      appstore.toDataURL(fileurl, function(dataUrl) { 
        appstore.downloadBase64File(dataUrl, filename)
      });
    }


     saveValues(){
       var self  = this;
       //console.log(this.add);
       if(this.add) {
        //  this.responseChangeObj.id = this.responses.length+1;
        //  this.responses.push(this.responseChangeObj);
        if(document.getElementById('response')!=null){
          setTimeout(() => {
            let scrollPos = document.getElementById('responseMsgs').offsetTop + document.getElementById("response"+String(this.responses.length-1)).offsetTop;
            window.scrollTo(0,scrollPos);
            this.componentScroll = document.getElementById("response"+String(this.responses.length-1)).offsetTop;
          },0);
        }else{
          setTimeout(() => {
            let scrollPos = document.getElementById('responseMsgs').offsetTop;
            window.scrollTo(0,scrollPos);
            //this.componentScroll = document.getElementById("response"+String(this.responses.length-1)).offsetTop;
          },0);
        }
       }
       else {
        //  this.responses.map(function(resp,respIndex) {
        //    if(resp.id === self.responseChangeObj.id) {
        //      console.log(respIndex);
        //      self.responses[respIndex] = Object.assign({},self.responseChangeObj);
        //      setTimeout(() => {
        //        let scrollPos = document.getElementById('responseMsgs').offsetTop + document.getElementById("response"+String(respIndex)).offsetTop;
        //        window.scrollTo(0,scrollPos);
        //        self.componentScroll = document.getElementById("response"+String(respIndex)).offsetTop;
        //     },0);
        //    }
        //  })

        setTimeout(() => {
          //let scrollPos = document.getElementById('responseMsgs').offsetTop + document.getElementById("response"+String(respIndex)).offsetTop;
          let scrollPos = document.getElementById('responseMsgs').offsetTop + document.getElementById("response"+String(this.responses.length-1)).offsetTop;
          window.scrollTo(0,scrollPos);
       },0);
       }

       this.resetValues();
     }

     cancelEdit(){
      this.deleteImg=false;
      this.cropimage = {
        src: null,
        crop: {
          unit: '%',
          width: 50,
          aspect: 1 / 1,
        },
        croppedImageUrl: null,
      }
      this.arraycroppedimg=[];
      this.selectedfile=[];
       this.resetValues();
     }

     resetValues(){
      this.responseChangeObj = {
        "question" : "",
        "value" : "",
        "operator" :"",
        "department" :"",
        "website" :"",
        "imageslist":[],
        "editedimageslist":[], 
        "type":""
        
      } 
      this.responseChangeObj.value="";
      this.modifiedimages=[];
      this.deleteImg=false;
      this.cropimage = {
        src: null,
        crop: {
          unit: '%',
          width: 50,
          aspect: 1 / 1,
        },
        croppedImageUrl: null,
      }
      this.arraycroppedimg=[];
      this.selectedfile=[];
      this.file=[]; 
      this.fileNames=[]; 
        this.imageCropModel = false;
        this.imageRef = null;
        this.imagewarning = false;
        this.warning_image_name= "";
        this.deleteImg=false;
        this.fileMimeType=[];
        this.maxImage=false;
        this.modifiedimages=[];
      this.deptWebsiteUid = {
        "department_uid": null,
        "website_uid": null
      }
        this.add = true;
        this.editing = false;
     }

 }

 decorate(ResponseStore, {
     responseChangeObj: observable,
     add: observable,
     responses: observable,
     deleteResponseConfirm: observable,
     responseFilter: observable,
     editing: observable,
     componentScroll: observable,
     editorState:observable,
     websiteDrpDwnOptions:observable,
     operatorDrpDwnOptions:observable,
     filterDeptDrpDwnOptions:observable,
     filterWebsiteDrpDwnOptions:observable,
     listFilter:observable,
     deptWebsiteUid:observable,
     responseForDelete:observable,
     deleteImage:observable,
     imageForDelete:observable,
     responseImage:observable,
     titleExist:observable,
     deptDrpDwnOptions:observable,
     cropimage:observable,
     imageCropModel:observable,
     imageRef:observable,
     deleteImg:observable,
     imagewarning:observable,
     warning_image_name:observable,
     file:observable,
     image:observable,
     selectedfile:observable,
     arraycroppedimg:observable,
     fileMimeType:observable,
     maxImage:observable,
     modifiedimages:observable,
     fileNames:observable

    })
 export default ResponseStore;