import { decorate, observable, extendObservable } from 'mobx';
import imageURLs from '../../sharedFiles/ImagesURLs';
import navigationStore from '../../sharedFiles/navigation-store';
import data from '../../assets/JSON/traffic.json';
import axios from 'axios'
class TrafficStore {
	  

  	constructor(){

      this.trafficTourStep=false;
         this.start_offset = 0;
        this.trafficMonitorVisitor=[];
        this.operatorsObject={};
        this.rowLength = 0;
        this.invite = false;
        this.confirmInvite = false;
        this.openWindow= false;
        this.openchildWindow= false;
        this.showgeoMap=false;
        this.totalPages=0;
        this.allwebsiteObject={};
        this.infoTabData ={
         ipAddress :"",
         coordinates:{
           latitude:"",
           longitude:""
         }
       }

        this.websiteImages = [
          require('../../assets/images/flag1.png'),
          require('../../assets/images/flag2.png'),
          require('../../assets/images/flag3.png'),
          require('../../assets/images/flag4.png')
        ];

        this.rowsToDisplay = [
          {"label":15,"value":15},
          {"label":20,"value":20},
          {"label":30,"value":30}
        ];
        this.websites = [];
        this.selectedRows = this.rowsToDisplay[0];
        this.selectedWebsite = this.websites[0];
        this.errorMessageComponent=false;
     }
     
     setIndex(clickedRow,rowIndex) {
       this.responseData.map(function(data,dataIndex) {
         if(rowIndex !== dataIndex) {
            data.collapsed = true;
         }
        })
       clickedRow.collapsed = !clickedRow.collapsed;
       if(!clickedRow.collapsed) {
         this.activeIndex = rowIndex;
         this.rowLength = 1;
       }
       else{
         this.activeIndex = this.responseData.length;
         this.rowLength = 0;
       }
     }


     setTrafficTourStep(appStore) {
      this.tourState = {
        steps: [
          {
            target: '.traffic_row_tour',
            content: appStore.getLanguageTrans('Once you have placed the code to your website, you can start chatting with your website visitors. Here you can monitor your website traffic and manually invite visitors to chat. '),
            disableBeacon: true,
            title: "HelpOnClik Guide",
            placement:"auto",
            stepNumber:8,
            offset: -10,
            },
        ],
        stepIndex: 0,
        overlaydisplay:false,
        isHomePage : false,
        isWebsitePage:false,
        isWidgetPage:false,
        isTrafficPage:true,
        };
      }

     getOperatorName = (acceptedBy) =>{
      if(acceptedBy == "-2")
        return "Virtual Agent";
       
       return this.operatorsObject[acceptedBy.trim()]["nick_name"];
     }

    setWebsites(appstore) {

      let allwebsites = appstore.userInfo ? JSON.parse(JSON.stringify(appstore.userInfo.allwebistes)) : null;
      
      if(allwebsites !=null){
        let all = {"label":"All","value":0,"optionType":"websiteAll","website_uid":""};
        allwebsites.splice(0, 0,all);   //insert All option in dropdown options
        let x;
        let index =0;
        for (x in allwebsites)
        {
          if(x>0){
          allwebsites[x].label= allwebsites[x].name;
          allwebsites[x].value= index;
          }
          index++;

          //set wesbite Object and use for displaying in website column of monitor page
          this.allwebsiteObject[allwebsites[x].website_uid] = allwebsites[x].name; 
        }
        this.websites= allwebsites;
        this.selectedWebsite = this.websites[0];
     }
    }
    
    setOperatorsObject = (appstore) =>{
       this.operatorsObject = {};
      try{
        for(let operator of appstore.userInfo.alloperators){
            this.operatorsObject[operator.user_uid] = operator;
        }
      }catch(error){

      }
    }

     confirmAction(appstore) {
       this.confirmInvite = true;
       this.invite = false;
       this.inviteVisitorRequest(appstore)
     }


     inviteVisitorRequest=(appstore) => {
      const requestedVisitorId = appstore.trafficVisitorSelected.sourceStation ;
      const currentTime = Math.round((new Date()).getTime() / 1000);
      const user = appstore.userInfo.usersmodel;
      const userRole = user.user_type_id == 3 ? 'admin':'operator';
      let custom_message=user.custom_message;
      custom_message=custom_message.replace(/<p>​<\/p>/gi, '');
      custom_message = custom_message.replace(/<p>\s*<\/p>/ig, '');
      custom_message = custom_message.replace(/<p\s*\/>/ig, '');
      let op_invite_message="";
      if(custom_message.trim()!=0){
        op_invite_message=custom_message;
      } 
      //console.log("invite msg"+op_invite_message);
      appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify({id:'23',system_msg:true,userUid:requestedVisitorId,operatorUid:user.user_uid,
          creationTime:currentTime,role:userRole,account_uid:appstore.userInfo.accountsmodel.account_uid,
            acceptedBy:"", message:"",fileName:"",originalFileName:"",fileSize:"", hostname:'ip', 
            sourceStation:user.user_uid,chatStatus:"invite", name:user.nick_name, 
            email:user.email,targetStation:'/topic/'+requestedVisitorId,op_invite:true,op_invite_msg:op_invite_message}));   
            
            let entries = this.trafficMonitorVisitor;
            const foundindex = entries.findIndex(el => el.sourceStation === appstore.trafficVisitorSelected.sourceStation);
            entries[foundindex].status = "Invited By Me";     
     }


     getAddressforIp = (visitor) => {
        //get response data for response 
        if(!visitor.address) 
        return;
        
        this.infoTabData.ipAddress = visitor.address.ipAddress;
        this.infoTabData.coordinates = {"latitude":visitor.address.latitude,"longitude":visitor.address.longitude	};
        //this.infoTabData.location = result.data.city+", "+result.data.country;
        this.showgeoMap=true;

      }


      getAllvisitorsOnRefresh= (appstore) =>{
        axios({method: 'GET', url: appstore.url + 'getalltrafficVisitorsFromRedis.json', headers: { 'content-type': 'application/json' } })
        .then(result => {  
          let visitorData = result.data;
          if(visitorData){
              for(let i in visitorData){
                appstore.handleTrafficMonitorData(visitorData[i] , true);
              } 
              appstore.getAllvisitorsEntrysOnRefresh();
              
          }else{
            appstore.trafficVisitorStatus = [];
            this.trafficMonitorVisitor=[];

          }
          appstore.global_errorcount=0;
        })
        .catch(error =>{
          // appstore.logout();
          console.log(error);
          console.log("error count :"+appstore.global_errorcount);
          appstore.global_errorcount=appstore.global_errorcount+1;
          if(appstore.global_errorcount>20){
          // appstore.logout();
          }
        })
      }
     
      ChatforSupervisionhide(data,appstore){
       let suprvision=false;
        if(appstore.undersupervisionvistors[data.sourceStation]){
          suprvision=appstore.undersupervisionvistors[data.sourceStation];
        } 
        return suprvision;
      }

      getChatforSupervision = (data,appstore) => {
       
        if(!appstore.isAdmin)
         return;

        const visitorId = data.sourceStation ;
        const chattingOp = data.acceptedBy;
        let allchattingOperators = [];

        if(!visitorId)
        return ;

        if(data.undersupervision)
        return;

        data.undersupervision = true;
        appstore.undersupervisionvistors[visitorId]=true;

        axios({
          method: 'POST',
          url: appstore.url + 'getcurrentChat.json?csId='+visitorId,
          headers: { 'content-type': 'application/json' }
        }).then(result => {
        // console.log(JSON.stringify(result.data))
        if(result.data.length==1){
          if(result.data.logoutstatus==1){
          appstore.logout();
          }
        }
         if(result.data){
           let chatMessages = result.data.allChatMsg;
           appstore.visitors[visitorId] = [];
           let operator_uid;
           for(let i in chatMessages){
            // if(chatMessages[i].message.trim()){
            //    chatMessages[i].type = chatMessages[i].role === "visitor" ? "inMsg" : "opsMsg";
            //    chatMessages[i].supervision = true;
            //    appstore.visitors[visitorId].push(chatMessages[i]);

            //    if(chatMessages[i].acceptedBy)
            //        operator_uid = chatMessages[i].acceptedBy;
            //  }


            if (chatMessages[i].message.trim().length != 0 || (chatMessages[i].fileName != null && chatMessages[i].fileName != "")) { 
              
              if(chatMessages[i].chatStatus =="supervisionQuit"){
                chatMessages[i].system_msg = false; 
                chatMessages[i].staff_message =true;
              }
              if(chatMessages[i].role.toLowerCase() !== "visitor"){
                allchattingOperators.push(chatMessages[i].operatorUid);
              }
              chatMessages[i].supervision = true;
              chatMessages[i].type = chatMessages[i].role.toLowerCase() === "visitor" ? "inMsg" : "opsMsg";
              appstore.visitors[visitorId].push(chatMessages[i])
         
           }
            
           //if visitor has left the chat
           if(appstore.visitors[visitorId].length !=0 && chatMessages[i].chatStatus==="removeVisitor"){
               appstore.visitors[visitorId][0].visitorLeft = true;
            }

            //if visitor came back 
            if(appstore.visitors[visitorId].length !=0 && appstore.visitors[visitorId][0].visitorLeft && chatMessages[i].chatStatus==="chat")
               appstore.visitors[visitorId][0].visitorLeft = false;

           }

           //get unique array 
           allchattingOperators = [...new Set(allchattingOperators)];

           if(appstore.visitors[visitorId]){
            appstore.visitors[visitorId][0].allchattingOperators = allchattingOperators;
           }
           
           appstore.visitors[visitorId][0].chattingOp = chattingOp;
            //set visitor array for display in currentchat component
            appstore.visitorList =[];         
            for(let key in  appstore.visitors ){
             appstore.visitorList.push(appstore.visitors[key][0]);
            } 

            appstore.assignedTopics.push('/topic/' + operator_uid);
            appstore.assignedTopics.push('/topic/' + visitorId);

            appstore.requestedVisitorId = visitorId;
            //route to chat monitor
            navigationStore.push('chats/operator/'+appstore.requestedVisitorId);
          
            //send supervision status to operator and visitor for setting desitination
            const user =  appstore.userInfo.usersmodel;
            const currentTime = Math.round((new Date()).getTime() / 1000);
            const userRole = user.user_type_id == 3 ? 'admin' : 'operator';

            let opMsg = { 
                          id:'',system_msg:true, userUid:visitorId, operatorUid:user.user_uid, creationTime:currentTime,
                          role:userRole, account_uid:appstore.userInfo.accountsmodel.account_uid, acceptedBy:"", message:"",
                          fileName:"",originalFileName:"",fileSize:"", hostname:'ip', sourceStation:user.user_uid,chatStatus:"chat", 
                          name:user.nick_name,staff_message:true, email:user.email,targetStation:'/topic/'+visitorId
                        }

            //send to visitor
            appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));  
            //send to operator
            for(let opId of allchattingOperators){
                 opMsg.targetStation = '/topic/' + opId; 
                 opMsg.sourceStation = visitorId;
                 appstore.clientRef.sendMessage("/app/opsmsg", JSON.stringify(opMsg));  
            }
           
          }
        
          this.errorMessageComponent=false;
          appstore.global_errorcount=0;
        }).catch((error) => {
          //appstore.logout();
          this.errorMessageComponent=true;
          console.log(error);
        console.log("error count :"+appstore.global_errorcount);
        appstore.global_errorcount=appstore.global_errorcount+1;
        if(appstore.global_errorcount>20){
        // appstore.logout();
        }
        });
   
        
      }

      getBrowserIcon = (appstore,data)=> {
        if(data.browser.match(/Firefox/i) )
          return appstore.otherIcons+"op/images/browsers/ff.png"
   
        else if(data.browser.match(/chrome/i))
        return appstore.otherIcons+"op/images/browsers/chrome.png";
        
        else if(data.browser.match(/safari/i))
        return appstore.otherIcons+"op/images/browsers/safari.png";
         
        else
        return null;
     }

     getcurrentUtc = () =>{
      //get current utc time with seconds
      let now = new Date();
      //return Math.floor((now.getTime() + (now.getTimezoneOffset() * 60000)));
      return Math.floor((now.getTime()));
    }

    timeSpentByVisitor = (visitor) =>{

      if(!visitor)
      return;
      
      let resolution;
      let EndTime = this.getcurrentUtc();
      let StartTime = visitor.creationTime*1000;
      resolution = EndTime - StartTime
      let totalSeconds = resolution / 1000;
     
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = (totalSeconds % 60).toFixed(0);
      
     if(hours == 0)
        return minutes+"m "+seconds+"s";
     else
        return hours +"hr "+ minutes+"m "+seconds+"s";
      
    }

  }
 decorate(TrafficStore, {
     responseData: observable,
     rowLength: observable,
     activeIndex: observable,
     invite: observable,
     confirmInvite: observable,
     selectedRows: observable,
     selectedWebsite: observable,
     websites:observable,
     openWindow:observable,
     showgeoMap:observable,
     infoTabData:observable,
     trafficMonitorVisitor:observable,
     totalPages:observable,
     start_offset:observable,
     operatorsObject:observable,
     trafficTourStep:observable,
     tourState:observable,
     openchildWindow:observable,
     errorMessageComponent:observable
    
    })
 export default TrafficStore;