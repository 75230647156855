import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import TextEditor from '../../../../sharedComponents/textEditor/textEditor'; 

import ResponseDropDownComponent from './ResponseDropDown';

const ScriptAddEditResponseComponent = observer(class ScriptAddEditResponseComponent extends Component {

	constructor (props) {
        super(props);
    }

	leadinputfields(event){ 
			let index1=event[0]; 
			let index2=event[1]; 
			let index3=event[2];  
			let replay =this.props.scriptStore.editorEditedValue; 
			this.props.scriptStore.count=this.props.scriptStore.count+1;
		
			if(index1 == true || index2 == true || index3 == true){				
				// console.log(replay.indexOf("[prompt lead inputs]"));
				if(replay.indexOf("[prompt lead inputs]") == -1){ 
					this.props.scriptStore.editorEditedValue=replay+"[prompt lead inputs]"; 
					this.props.scriptStore.selectedDataToEdit.reply=this.props.scriptStore.editorEditedValue
					// console.log(this.props.scriptStore.editorEditedValue)
				}
				// [prompt lead inputs]
			}
			if((index1 == false || index1 == 0) && (index2 == false || index2 == 0) && (index3 == false || index3 == 0)){
				 
				 this.props.scriptStore.editorEditedValue= this.props.scriptStore.editorEditedValue.replace("[prompt lead inputs]","");
				 this.props.scriptStore.selectedDataToEdit.reply=this.props.scriptStore.editorEditedValue 
			}
			
	}

	render(){ 
		const { scriptStore,
				editData,
				addResponseLink,
				responseData,
			appstore } = this.props; 
			if(!addResponseLink){
				this.props.scriptStore.editorEditedValue=editData.reply;
			}  
			 
		return (
			<Form>						

						  <Form.Group as={Row} controlId="formGroupQuestion">
							    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Visitors Question")}</Form.Label>
							    <Col sm="8" lg="9">
							    	<Form.Control type="text"   placeholder={appstore.getLanguageTrans("Please enter your question")} 
							    				  value={editData.title}
							    				  onChange={(e) => (editData.title = e.target.value,scriptStore.validatequestion(e))} required />
							   {scriptStore.validateques &&
							    <span className="error-block">*required</span>
							   } 
								</Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupReply">
							    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Reply")}</Form.Label>
							    <Col sm="8" lg="9"> 
								<TextEditor appstore={this.props.appstore} key={scriptStore.count} store={scriptStore}   editorContent={editData.reply} />
								 
							    </Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupKeyWords">
							    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Keywords")}</Form.Label>
							    <Col sm="8" lg="9">
							    	<Form.Control type="text" placeholder={appstore.getLanguageTrans("Please enter Keywords")} 
													value={editData.keyWords} 
													required
							    					onChange={(e) => (editData.keyWords = e.target.value,scriptStore.validatefields(e))}  />
							{scriptStore.validate &&
							<span className="error-block">*required</span>
							}
							    </Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupLead">
							    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Lead")}</Form.Label>
							    <Col sm="8" lg="9">
							    	<div className="custom_radioBtn">
							    		<CheckBoxComponent radioValue={editData.leadId ? editData.leadId[0] : false}
							    							  callBack = {() => (scriptStore.setEditFormLead(0,editData.reply),this.leadinputfields(editData.leadId))}/> 
							    		<p>{appstore.getLanguageTrans("Name")}</p>
							    	</div>
							    	<div className="custom_radioBtn">
							    		<CheckBoxComponent radioValue={editData.leadId ? editData.leadId[1] : false}
							    							  callBack = {() => (scriptStore.setEditFormLead(1,editData.reply),this.leadinputfields(editData.leadId))}
															  /> 
							    		<p>{appstore.getLanguageTrans("Email")}</p>
							    	</div>
							    	<div className="custom_radioBtn">
							    		<CheckBoxComponent radioValue={editData.leadId ? editData.leadId[2] : false}
							    							  callBack = {() => (scriptStore.setEditFormLead(2,editData.reply),this.leadinputfields(editData.leadId))}/> 
							    		<p>{appstore.getLanguageTrans("Phone")}</p>
							    	</div>
							    </Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupEmail">
							    <Form.Label column sm="4" lg="3">{appstore.getLanguageTrans("Email Lead to")}</Form.Label>
							    <Col sm="8" lg="9">
							    	<Form.Control type="text" placeholder={appstore.getLanguageTrans("xyz@gmail.com")} 
							    					value={editData.leadMail}
							    					onChange={(e) => editData.leadMail = e.target.value}/>
							    </Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupResponse1">
						    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Related Response 1")}</Form.Label>
						    <Col sm="8" lg="9" className="customDrpDwn">
								<ResponseDropDownComponent dropDownOptions={responseData} 
													key={Math.random()}
						    					   store={scriptStore}
						    					   selectedOption = {editData.responseIds[0]}
						    					   callBack = {(value) => scriptStore.setRelatedResponseData(value,1)}/>
						    </Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupResponse2">
						    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Related Response 2")}</Form.Label>
						    <Col sm="8" lg="9" className="customDrpDwn">
								<ResponseDropDownComponent dropDownOptions={responseData} 
													key={Math.random()}
						    					   store={scriptStore}
						    					   selectedOption = {editData.responseIds[1]}
						    					   callBack = {(value) => scriptStore.setRelatedResponseData(value,2)}/>
						    </Col>
						  </Form.Group>

						  <Form.Group as={Row} controlId="formGroupResponse3">
						    <Form.Label column lg="3" sm="4">{appstore.getLanguageTrans("Related Response 3")}</Form.Label>
						    <Col sm="8" lg="9" className="customDrpDwn">
								<ResponseDropDownComponent dropDownOptions={responseData} 
													key={Math.random()}
						    					   store={scriptStore}
						    					   selectedOption = {editData.responseIds[2]}
						    					   callBack = {(value) => scriptStore.setRelatedResponseData(value,3)}/>
						    </Col>
						  </Form.Group>
						  <div className="row m-0">
							<div className="col-12 formBtns">
								<div className="pullRight">
									<Button className="btn_clr" variant="light" onClick={() => scriptStore.cancelSaveResponse()}>
								    	<p>{appstore.getLanguageTrans("Cancel")}</p>
								    </Button>
								    <Button className="btn_save" onClick={() => scriptStore.saveResponse(editData,addResponseLink,editData.related_uid)}>
								    	<p>{appstore.getLanguageTrans("Save")} 
										</p>
								    </Button>
								</div>
							</div>
						</div>
					</Form>
		)
	}
})
export default ScriptAddEditResponseComponent;
