import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';
import GetWidgetCodeComponent from './getCodeComponent';
import ToobarThumbnailpreview from './ToobarThumbnailpreview';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const WidgetThumbnail = observer(class WidgetThumbnail extends Component {

	constructor (props) {
        super(props);
        this.calculateHeight = this.calculateHeight.bind(this);
    }

	componentDidMount(){
		window.addEventListener("resize", this.calculateHeight);
		this.calculateHeight();
		this.props.widgetStore.loadCustomlibrary(this.props.appstore)
		
	}

	calculateHeight() {
		try{
		let contentHeight = document.getElementsByClassName('detailsWrapper')[this.props.widgetIndex].offsetHeight;
		this.props.widgetStore.thumbnailHeight = contentHeight > this.props.widgetStore.thumbnailHeight ? contentHeight : this.props.widgetStore.thumbnailHeight;
		}catch(error){

		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.calculateHeight);
    }
	
    editWidget =async (widgetData) =>{
		this.props.appstore.assistedit=true;
		const type = widgetData.modesString.split(","); 
		//set options for dropdown
		await this.props.appstore.dptoptforwebiste();
		
		if(type[0] === "widget" && type[1] !== "icon"){
			this.props.widgetStore.selectedWidget = 1; 
			this.getWidgetforUid(widgetData); 
	        this.props.appstore.navigate('/admin/setup/widgets/add?1');
		}
		else if(type[0] ==="toolbar"){
			this.props.widgetStore.selectedWidget = 2; 
			this.getWidgetforUid(widgetData);
	        this.props.appstore.navigate('/admin/setup/widgets/add?2');
		}
		else if(type[0] === "icon"){
			this.props.widgetStore.selectedWidget = 3; 
			this.getWidgetforUid(widgetData); 
			this.props.appstore.navigate('/admin/setup/widgets/add?3');      
			 
		}
		else if(type[0] === "help"){
			this.props.widgetStore.selectedWidget = 4; 
			this.getWidgetforUid(widgetData);
	        this.props.appstore.navigate('/admin/setup/widgets/add?4');
		}
		else if(type[0] === "widget" && type[1] === "icon"){
			this.props.widgetStore.selectedWidget = 5; 
			this.getWidgetforUid(widgetData);
	        this.props.appstore.navigate('/admin/setup/widgets/add?5');
		}
	}

	deleteWidget = (widget) =>{ 
		this.props.widgetStore.widgetfordelete = widget
		this.props.widgetStore.confirmDelete = true
	}
	
	getWidgetforUid=(data) =>{
		//set widget for edit and show in widgetbuilder component
		let allwidgets = this.props.appstore.storedWidgets;
		for(let i in allwidgets){
           if(data.uid === allwidgets[i].uid){
			this.props.appstore.widgetForEdit = allwidgets[i];
			// console.log(this.props.appstore.widgetForEdit);
			break;
		   }
		}
	 }
	 filterwidgetimages=(title,clientConfigJson)=>{ 
		 let link=imageURLs.widget1Logo;
		 if(title=="widget,popupInvitation" || title=="widget"){
			for(let i in this.props.widgetStore.widgetThemes){
				if(this.props.widgetStore.widgetThemes[i].theme==clientConfigJson.color){ 
					link= this.props.widgetStore.widgetThemes[i].image;
					break;
				}
			}
		 }else if(title=="widget,icon,popupInvitation" || title=="widget,icon"){
			for(let i in this.props.widgetStore.widgetThemes){
				if(this.props.widgetStore.widgetThemes[i].theme==clientConfigJson.color){ 
					link= this.props.widgetStore.widgetThemes[i].image;
					break;
				}
			}
		 } 
		 return link;
		
	 }

	//  getActiveStatus = (widget) =>{
	// 	if(this.props.appstore.activeWidgets[widget.uid])
	// 	return this.props.appstore.activeWidgets[widget.uid] > 0 ? true : false; 

	// 	return false;
	// }
	

	render(){

		var self = this;
		const { appstore,
				widget,
				widgetStore } = this.props;   
				if (appstore.assistedit) {
					return (
						<div className="adminloader">
							<GridLoader
								css={override}
								size={20}
								marging={2}
								color={appstore.selectedColor.color}
							/>
						</div>
					)
				}
		return (
			<div className="widgetThumbnailWrapper col-12 col-sm-6 col-md-4" key={appstore.selectedWebisiteUid} style={appstore.helpTourStarted ? {pointerEvents:"none",opacity:0.3}:{}}>
				<div className="widgetContent box">
					<div className="logoWrapper" style={(widget.modesString!="widget,icon,popupInvitation" || widget.modesString!="widget,icon") ? {height:"165px"} :{}}>
					<p><span className={widget.installed ? "installed_icon" : "uninstalled_icon"}></span> </p>
					{/* 260px */}
					{(widget.modesString=="widget,popupInvitation" || widget.modesString == "widget") && 
						<div className="previewsave img-fluid" 
						style={(widget.modesString!="widget,icon,popupInvitation" || widget.modesString!="widget,icon")? {marginTop:"35px",backgroundSize: "100%",backgroundRepeat: "no-repeat",position:"relative", height: "100px",backgroundImage: 'url('+ this.filterwidgetimages(widget.modesString,widget.clientConfigJson) + ')'}
						:{backgroundSize: "260px", backgroundRepeat: "no-repeat",height: "80px",backgroundImage: 'url('+ this.filterwidgetimages(widget.modesString,widget.clientConfigJson) + ')'}}>						  
						<div className="widget-preview">
							<div className="widget-preview-title">{appstore.getLanguageTrans("Live Chat")}</div>
							<div className="widget-preview-text">{appstore.getLanguageTrans("Type your question here")}</div>
							<div className="widget-preview-copy"></div>
						</div>
						</div>
						//<img src={this.filterwidgetimages(widget.modesString,widget.clientConfigJson)} className="img-fluid" />						 
					}
					{(widget.modesString=="widget,icon,popupInvitation" || widget.modesString=="widget,icon") && 
						<React.Fragment> 
							<div id="icon_buttons">
						<ul>
							<li style={{marginBottom: "115px"}}>
								<div>
									<span>
									{/* img-fluid  */}
									<div className="previewsave widget_iconpreview"  style={{backgroundImage: 'url('+ this.filterwidgetimages(widget.modesString,widget.clientConfigJson) + ')'}}>
										<div className="widget-preview" style={{marginLeft: "-148px"}}>
											<div className="widgeticon-preview-title">{appstore.getLanguageTrans("Live Chat")}</div>
											<div className="widgeticon-preview-text" style={{marginLeft: "-72px",padding: "34px 0 0"}}>{appstore.getLanguageTrans("Type your question here")}</div>
											<div className="widget-preview-copy"></div>
										</div>
									</div>
									</span>
								</div>
							</li>
						</ul>
						</div>
						<div id="icon_buttons">
						<ul>
						 	{widget.clientConfigJson!=null && widget.clientConfigJson.icon.iconLinks.offline!=null && widget.clientConfigJson.icon.iconLinks.offline !="" &&
							<li><div><span><img src={widget.clientConfigJson.icon.iconLinks.offline} className="img-fluid" /></span></div></li>
							}
							 {widget.clientConfigJson!=null && widget.clientConfigJson.icon.iconLinks.online!=null && widget.clientConfigJson.icon.iconLinks.online !="" &&
							<li><div><span><img src={widget.clientConfigJson.icon.iconLinks.online} className="img-fluid" /></span></div></li> 
							}				  
						</ul>
					</div>	
						</React.Fragment>
					}
					
					{widget.modesString=="icon,popupInvitation" &&
					<React.Fragment>
					<div id="icon_buttons" style={(widget.modesString!="widget,icon,popupInvitation" || widget.modesString!="widget,icon") ? {marginTop:"35px"}:{}}>
					<ul>
						{widget.clientConfigJson!=null && widget.clientConfigJson.offline !="" &&
						 <li><div><span><img src={widget.clientConfigJson.offline} className="img-fluid" /></span></div></li>
						}
						{widget.clientConfigJson!=null && widget.clientConfigJson.online !="" &&
						<li><div><span><img src={widget.clientConfigJson.online} className="img-fluid" /></span></div></li> 
						}				  
					</ul>
				  </div>
				  </React.Fragment>
					}	
					{widget.modesString=="toolbar" &&
							<ToobarThumbnailpreview widgetStore={widgetStore} widgetdata={widget.clientConfigJson} appstore={appstore} />
						}
					</div>
					<div className="detailsWrapper" style={{height: widgetStore.thumbnailHeight !=0 ? widgetStore.thumbnailHeight+"px" : ''}}>
						{
							widget.installation_name && 
								<p>{widget.installation_name}</p>
						}
						<p className="domain">
							<span onMouseOver={() => widget.showDomain = true} onMouseOut={() => widget.showDomain = false}>{widget.domains.length>0 ? appstore.getLanguageTrans("Show Domain") : appstore.getLanguageTrans("Check Domain")} </span>
							{
								widget.showDomain && widget.domains.length>0 && 
								<div className="domainList">
									{widget.domains.join(', ')}
								</div>
							}
						</p>
						<p className=""><span>{appstore.getLanguageTrans("Language")}: </span>{widget.language}</p>
						<p className=""><span>{appstore.getLanguageTrans("Departments")}: </span>{widget.departments.length !=0 ? widget.departments.join(', '): appstore.getLanguageTrans("All Departments")}</p>
						<p className="operators">
							<span>{appstore.getLanguageTrans("Operators")}: </span>
							{widget.operators.length !=0? widget.operators.slice(0,3).join(', '): appstore.getLanguageTrans("All Operators")}
							{
								widget.operators.length > 3 && 
									<span className="count" onMouseOver={() => widget.showOperators = true} onMouseOut={() => widget.showOperators = false}>
										+{(widget.operators.length-3)}
									</span>
							}
							{
								widget.showOperators && 
								<div className="operatorsList">
									{widget.operators.slice(3,widget.operators.length).join(', ')}
								</div>
							}
							
						</p>
					</div>
					<div className="tumbnail_footer">
						<div className="col-12 formBtns">
								<div className="">
									<Button className="btn_clr" variant="light" onClick={() => {widgetStore.getCode = true ;widgetStore.thumbnailGetcode =widget }}>
								    	<p>{appstore.getLanguageTrans("Get Code")}</p>
								    </Button>
									<img src={imageURLs.editIcon} className="img-fluid edit_btn" onClick={() =>this.editWidget(widget)}/>
									<img src={imageURLs.deleteIcon} className="img-fluid" onClick={() => this.deleteWidget(widget)}/>
								</div>
							</div>
					</div>
				</div>
			</div>
		)
	}
})
export default WidgetThumbnail;
