import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form, FormGroup, ControlLabel, FormControl, Row, Col, Button } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import imageURLs from '../../../../sharedFiles/ImagesURLs';
import RadioButtonComponent from '../../../../sharedComponents/radionButton2/RadioButton';
import CheckBoxComponent from '../../../../sharedComponents/checkBox/checkBox';
import navigationStore from '../../../../sharedFiles/navigation-store';
import ConfirmationComponent from '../../../../sharedComponents/confirmationPopup/ConfirmationComponent';
import WarningComponent from '../../../../sharedComponents/confirmationPopup/WarningComponent';
import DataModeComponent from './components/operators/DataMode';
import EditModeComponent from './components/operators/EditMode';
import AddModeComponent from './components/operators/AddMode';
import DropdownModel from '../../../../sharedComponents/dropdownModel/DropdownModel';
import DeptDataModeComponent from './components/departments/DeptDataMode';
import DeptEditModeComponent from './components/departments/DeptEditMode';
import OperatorsDeptStore from './OperatorsDeptStore';
import axios from 'axios';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';

const override = css`
position: absolute; 
top: 289px;
z-index: 999;
left: 35%; 
opacity: 0.6 ;
position: relative`

const OperatorsDeptComponent = observer(class OperatorsDeptComponent extends Component {

	constructor(props) {
		super(props);
		this.operatorsDeptStore = new OperatorsDeptStore(this.props.appstore);
		this.updateProperty = this.updateProperty.bind(this);
		this.operatorsDeptStore.previouswebsite_uid = this.props.appstore.selectedWebisiteUid;
		this.state = { websiteUid: this.props.appstore.selectedWebisiteUid }
		this.htmlText = this.props.appstore.userInfo.alloperatorsnotrelated.map(function(o){
			return `
				${o.nick_name} ${o.email}
			`
		  });
	}
	componentDidMount() {
		this.operatorsDeptStore.showImage=false;
		this.props.appstore.menuChanged = window.location.pathname;
		this.operatorsDeptStore.getOpandDeptforAdmin();
		let websites = this.props.appstore.userInfo.allwebistes;
		try{
			for (let i in websites) {
				if (websites[i].website_uid === this.props.appstore.selectedWebisiteUid) {
					this.operatorsDeptStore.showImage = websites[i].op_images == 1 ? true : false;
					break;
				}
			} 
		}catch(error){
		}
	}

	updateProperty(key, value) {
		this.chatInvitationStore.formData[key] = value;
	}

	handleChange(event) {
		this.updateProperty(event.target.name, event.target.value);
	}


	componentWillUnmount() {
		window.scrollTo(0, 0);
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevState.websiteUid !== this.props.appstore.selectedWebisiteUid) {
			this.operatorsDeptStore.isPageLoaded = false;
			this.operatorsDeptStore.getOpandDeptforAdmin();
			this.setState({ websiteUid: this.props.appstore.selectedWebisiteUid });
			this.operatorsDeptStore.deptEditMode = false;
			this.operatorsDeptStore.operatorEditing = false;
			this.operatorsDeptStore.showImage=false;
			let websites = this.props.appstore.userInfo.allwebistes;
			try{
				for (let i in websites) {
					if (websites[i].website_uid === this.props.appstore.selectedWebisiteUid) {
						this.operatorsDeptStore.showImage = websites[i].op_images == 1 ? true : false;
						break;
					}
				}
			}catch(error){
			}
		}
	}


	confirmAction() {
		let restUrl = '';
		let data = '';
		let mode = '';
		this.operatorsDeptStore.isPageLoaded = false;
		this.operatorsDeptStore.componentToDel.website_uid = this.props.appstore.selectedWebisiteUid;
		if (this.operatorsDeptStore.componentToDelTitle == "Operator") {
			//handle Op here
			data = this.operatorsDeptStore.componentToDel;
			restUrl = "deleteoperator.json";
			mode = "operator";
		} else {
			//handle department here
			data = this.operatorsDeptStore.componentToDel;
			restUrl = "deletedepartment.json";
			mode = "department";
		}

		axios({
			method: 'POST',
			url: this.props.appstore.url + restUrl,
			headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
			data: data,
		}).then(result => {
			// console.log(result.data);
			if(result.data.length==1){
				if(result.data[0].logoutstatus==1){
				  this.props.appstore.logout();
				}
			  }
			// if(result.data.length==1){
			// 	if(result.data[0].logoutstatus==1){
			// 	  this.logout();
			// 	}
			//   }
			this.operatorsDeptStore.componentToDelTitle = '';
			this.operatorsDeptStore.componentToDeltext = '';
			try{
			if (mode === "department") {
				//handle department data here
				if (result.data.length == 0) {
					this.operatorsDeptStore.departments = [];
					this.props.appstore.deptOps.allDept = [];
				}
				else if (result.data[0].errorStatus == -1) {
					this.operatorsDeptStore.errormodel = true;
				}
				else {
					this.props.appstore.deptOps.allDept = JSON.parse(JSON.stringify(result.data));
					let deptData = this.operatorsDeptStore.setOpforDept(result.data);
					this.operatorsDeptStore.departments = deptData;
				}
				this.operatorsDeptStore.isPageLoaded = true
			} else {
				if (result.data[0].usersmodel != undefined) {
					//handle operator response here 
					if (result.data[0].usersmodel.status == 0 || result.data[0].usersmodel.status == 3 || result.data[0].usersmodel.status == 4) {
						this.operatorsDeptStore.errormodel = true;

					} else if (result.data[0].usersmodel.status == 2) {
						//can not delete owner from last website popup
						this.operatorsDeptStore.canNotdeleteOwner = true;

					} else if (result.data[0].usersmodel.status == 1) {
						//success
						this.props.appstore.userInfo.alloperators = result.data[0].usersmodel.allusers;
						this.operatorsDeptStore.showOperators(result.data[0].usersmodel);
						this.props.appstore.deptOps.allDept = JSON.parse(JSON.stringify(result.data[0].departmentsmodel));
						let deptData = this.operatorsDeptStore.setOpforDept(result.data[0].departmentsmodel);
						this.operatorsDeptStore.departments = deptData;
						this.operatorsDeptStore.goToAddOperatorModeafterdelete(this.props.appstore);

					} else {
						try{
						this.props.appstore.userInfo.alloperators = result.data[0].usersmodel[0].allusers;
						}catch(er){
						}
						this.operatorsDeptStore.showOperators(result.data[0].usersmodel);
						this.props.appstore.deptOps.allDept = JSON.parse(JSON.stringify(result.data[0].departmentsmodel));
						let deptData = this.operatorsDeptStore.setOpforDept(result.data[0].departmentsmodel);

						this.operatorsDeptStore.departments = deptData;
						this.operatorsDeptStore.goToAddOperatorModeafterdelete(this.props.appstore);
					}
				}else{
					this.operatorsDeptStore.getOpandDeptforAdmin();
					this.operatorsDeptStore.goToAddOperatorModeafterdelete(this.props.appstore);
				}
				this.operatorsDeptStore.isPageLoaded = true;
			}  
			}catch(error){
				this.operatorsDeptStore.isPageLoaded = true;
			}
			this.props.appstore.global_errorcoun=0;
		}).catch((error) => {
			// handle error
			//this.props.appstore.logout();
			console.log(error);
			console.log("error count :"+this.props.appstore.global_errorcount);
			this.props.appstore.global_errorcount=this.props.appstore.global_errorcount+1;
			if(this.props.appstore.global_errorcount>20){
			// this.appstore.logout();
			}
			this.operatorsDeptStore.isPageLoaded = true;
		})
		this.operatorsDeptStore.componentToDel = "";
		this.operatorsDeptStore.confirm = false;
	}

	closeConfirm = () => {
		this.operatorsDeptStore.confirm = false
		this.operatorsDeptStore.componentToDel = '';
		this.operatorsDeptStore.componentToDelTitle = '';
		this.operatorsDeptStore.componentToDeltext = '';
	}
	op_imageupdate = (appstore) => { 
		let showimage = this.operatorsDeptStore.showImage == true ? 1 : 0;
		axios({
			method: 'POST',
			url: appstore.url + 'updateOpImageShow.json',
			headers: { 'content-type': 'application/json', 'charset': 'utf-8' },
			data: { op_images: showimage, website_uid: appstore.selectedWebisiteUid },
		}).then(result => {
			let websites = appstore.userInfo.allwebistes;
			try{
				for (let i in websites) {
					if (websites[i].website_uid === appstore.selectedWebisiteUid) {
						websites[i].op_images = this.operatorsDeptStore.showImage == true ? 1 : 0;
						break;
					}
				}
			}catch(error){
			}
			appstore.global_errorcount=0;
		}).catch((error) => {
			//appstore.logout();
			console.log(error);
			console.log("error count :"+appstore.global_errorcount);
			appstore.global_errorcount=appstore.global_errorcount+1;
			if(appstore.global_errorcount>20){
			// appstore.logout();
			}
		})


	}

	render() {

		var self = this;
    const vacss={
			pointerEvents:"none",
			opacity:0.3,
		}
		const nocss={}
		const { appstore } = this.props;
		//appstore.getLanguageTrans("Korea, Democratic People'S Republic of")}
		if (!this.operatorsDeptStore.isPageLoaded) {
			return (
				<div className="adminloader">
					<GridLoader
						css={override}
						size={20}
						marging={2}
						color={appstore.selectedColor.color}
					/>
				</div>
			)
		}

		return (
			<div id={this.props.appstore.selectedWebisiteUid} className="innercontent_wrapper w-100">
				<div className="header_title row align-items-center m-0">
					<img src={imageURLs.optDeptPageIcon} alt="faq" className="img-fluid" />
					<h4 className="text-left">{appstore.getLanguageTrans("Operators & Departments")}</h4>
				</div>
				<div className="row optDeptWrapper with-header">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box p-0">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Operators")}</h4>
							</div>
							<div className="contentWrapper">
								<Form>
									{/* <Form.Group as={Row} controlId="formGroupIncomingChat">
									    <Form.Label column xs="12" sm="4" lg="3">{appstore.getLanguageTrans("An incoming chat rings to")}</Form.Label>
									    <Col xs="12" sm="8" lg="9" className="chatRedirect">
									    	<RadioButtonComponent selectedOption = {this.operatorsDeptStore.chatRingsDirect}
							    				options={this.operatorsDeptStore.chatRingsDirectOptions}
							    				groupName = {"chatRingsRedirect"}
							    				callBack={(selectedOption) =>{this.operatorsDeptStore.chatRingsDirect = selectedOption}}/>
									    </Col>
									  </Form.Group> */}
									<Form.Group as={Row} controlId="formGroupIncomingChat">
										<Form.Label column xs="7" sm="4" lg="3">{appstore.getLanguageTrans("Show operator images on visitor chat if exists")}</Form.Label>
										<Col className="custom_radioBtn">
											<CheckBoxComponent radioValue={this.operatorsDeptStore.showImage}
												 callBack={() => {this.operatorsDeptStore.showImage = !this.operatorsDeptStore.showImage;this.op_imageupdate(appstore)}}
												// onChange={() => {this.operatorsDeptStore.showImage = !this.operatorsDeptStore.showImage;this.op_imageupdate(appstore)}}
												 />
										</Col>
										{this.props.appstore.userInfo.alloperatorsnotrelated.length!=0 &&
										<Col className="custom_removeBtn">
										<p>{appstore.getLanguageTrans("You have "+this.props.appstore.userInfo.alloperatorsnotrelated.length+" unassigned Operators:- ")}</p>
										<button type="button" className="custom_btn addBtn btn btn-primary text-uppercase" onClick={() => this.operatorsDeptStore.viewallopt = true}>{appstore.getLanguageTrans("View")}</button>
										<button type="button" className="custom_btn addBtn btn btn-primary text-uppercase" onClick={() => this.operatorsDeptStore.deleteallopt = true} >{appstore.getLanguageTrans("Delete")}</button>
									    </Col>
	                                    }
									</Form.Group>
								</Form>
							</div>
							<div className="header-no-border operatorSection col-12 p-0">
								{/*<div className="headerTitle">
									<h4 className="text-left">Add/Edit Mode</h4>
								</div>*/}
								{/* {this.operatorsDeptStore.operators &&
										this.operatorsDeptStore.operators.map(function(operator, operatorIndex) { */}
								<div className="operatorsWrapper col-12">{}
									{appstore.operators &&
										appstore.operators.map(function (operator, operatorIndex) {
											return (
												<React.Fragment key={operatorIndex}>
													{!operator.edit &&
														<DataModeComponent operator={operator}
															appstore={appstore}
															deptStore={self.operatorsDeptStore} />
													}
													{operator.edit &&
														<EditModeComponent operator={operator}
															appstore={appstore}
															deptStore={self.operatorsDeptStore} />
													}
												</React.Fragment>
											)
										})
									}
									{
										this.operatorsDeptStore.addOperator &&
										<EditModeComponent operator={this.operatorsDeptStore.operatorToEdit}
											appstore={appstore}
											deptStore={this.operatorsDeptStore} />
									}
									{
										!this.operatorsDeptStore.addOperator &&
										<AddModeComponent appstore={appstore}
											deptStore={this.operatorsDeptStore} />
									}

								</div>

							</div>
						</div>
					</div>
				</div>

				<div className="row optDeptWrapper section_Dept">
					<div className="settingsForm col-12 w-100 pr-0">
						<div className="settingFormWrapper box">
							<div className="headerTitle">
								<h4 className="text-left">{appstore.getLanguageTrans("Departments")}</h4>
							</div>
							<div className="contentWrapper">
								<div className="scroll_row row m-0">
									<div id="panelGroup" className={"panelWrapper col-12 col-lg-12 p-0" + (this.operatorsDeptStore.departments.length % 2 === 0 ? ' odd' : ' even')}>
										<div className="col-12">
											<div className="row panelHeader">
												<div className="col-3">
													<p className="text-left">{appstore.getLanguageTrans("Department Name")}</p>
												</div>
												<div className="col-3">
													<p className="text-left">{appstore.getLanguageTrans("Email to collect messages")}</p>
												</div>
												<div className="col-3">
													<p className="text-left">{appstore.getLanguageTrans("Operators")}</p>
												</div>
												<div className="col-3">
													<p className="text-left"></p>
												</div>
											</div>
										</div>
										<div className="scrollWrapper col-12 p-0">
											<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
												<div className="scriptPanel row">
													<div className="col-12">
														{
															this.operatorsDeptStore.departments.map(function (dept, deptIndex) {
																return (
																	<React.Fragment key={deptIndex}>
																		{!dept.edit &&
																			<DeptDataModeComponent department={dept}
																				deptStore={self.operatorsDeptStore}
																				appstore={appstore}
																			/>
																		}
																		{dept.edit &&
																			<DeptEditModeComponent departToEdit={dept}
																				deptCopy={Object.assign({}, self.operatorsDeptStore.departments[deptIndex])}
																				deptStore={self.operatorsDeptStore}
																				appstore={appstore}
																			/>
																		}
																	</React.Fragment>
																)
															})
														}

													</div>
												</div>
											</CustomScroll>
										</div>
										{!this.operatorsDeptStore.maxDeptCrossed(appstore) &&
											<React.Fragment>
												{
													!this.operatorsDeptStore.deptEditMode &&
													<button type="button" className="custom_btn float-left addBtn btn btn-primary text-uppercase" onClick={() => this.operatorsDeptStore.addNewDept(appstore)}>{appstore.getLanguageTrans("Add new department")}</button>
												}
											</React.Fragment>
										}
										{
											this.operatorsDeptStore.maxDeptCrossed(appstore) &&
											<button type="button" className="custom_btn float-left addBtn btn btn-primary text-uppercase" onClick={() => this.operatorsDeptStore.payDollarforDept = true}>{appstore.getLanguageTrans("Add new department ")} $</button>
										}
									</div>

								</div>
							</div>
							{/*<div className="col-12 formBtns">
								<div className="pullRight">
									<Button className="btn_clr" variant="light">
								    	<p>Cancel</p>
								    </Button>
								    <Button className="btn_save">
								    	<p>Save</p>
								    </Button>
								</div>
							</div>*/}
						</div>

					</div>
				</div>
				{
					this.operatorsDeptStore.confirm &&
					<ConfirmationComponent show={this.operatorsDeptStore.confirm}
						store={this.operatorsDeptStore}
						title={"Delete " + this.operatorsDeptStore.componentToDelTitle}
						body_html={"<p>Are you sure you want to delete the selected " + this.operatorsDeptStore.componentToDelTitle + "?" + this.operatorsDeptStore.componentToDeltext + "</p>"}
						cancel_btn="Cancel"
						save_btn="Delete"
						callBack={() => this.confirmAction()}
						closePopup={() => this.closeConfirm()}
						appStore={appstore} />
				}
				{
					this.operatorsDeptStore.emailExist &&
					<ConfirmationComponent show={this.operatorsDeptStore.emailExist}
						store={this.operatorsDeptStore}
						title={"Warning"}
						body_html={"<p>The email is used for another operator</p>"}
						cancel_btn="Okay"
						closePopup={() => this.operatorsDeptStore.emailExist = false}
						appStore={appstore}
						singleButton={true}
					/>
				}
				{/* { (this.operatorsDeptStore.showExisting && this.operatorsDeptStore.existingOps.length !=0) &&  */}
				{this.operatorsDeptStore.showExisting &&
					<DropdownModel
						show={this.operatorsDeptStore.showExisting}
						store={this.operatorsDeptStore}
						options={this.operatorsDeptStore.existingOps}
						title={"Existing Operators"}
						btnName={"Add"}
						closePopup={() => this.showExisting = false}
						appStore={appstore}
					/>
				}

				{
					this.operatorsDeptStore.payDollarforDept &&
					<ConfirmationComponent show={this.operatorsDeptStore.payDollarforDept}
						store={this.operatorsDeptStore}
						title="Upgrade Plan"
						body_html={this.operatorsDeptStore.getmaxDeptContent(appstore)}
						cancel_btn={appstore.isOwner ? "Cancel" : "Ok"}
						save_btn="Upgrade"
						singleButton={!appstore.isOwner}
						callBack={() => this.operatorsDeptStore.openUpgrade(appstore)}
						closePopup={() => this.operatorsDeptStore.payDollarforDept = false}
						appStore={appstore} />
				}
				{
					this.operatorsDeptStore.errormodel &&
					<WarningComponent show={this.operatorsDeptStore.errormodel}
						store={this.operatorsDeptStore}
						cancel_btn="OKAY"
						closePopup={() => this.operatorsDeptStore.errormodel = false}
						appStore={appstore} />
				}
				{
					this.operatorsDeptStore.canNotdeleteOwner &&
					<ConfirmationComponent show={this.operatorsDeptStore.canNotdeleteOwner}
						store={this.operatorsDeptStore}
						title={"Warning"}
						body_html={"<p>You cannot delete the Owner</p>"}
						cancel_btn={"Okay"}
						closePopup={() => this.operatorsDeptStore.canNotdeleteOwner = false}
						appStore={appstore}
						singleButton={true}
					/>
				}
				{
					this.operatorsDeptStore.viewallopt &&
					<ConfirmationComponent show={this.operatorsDeptStore.viewallopt}
						store={this.operatorsDeptStore}
						title={"Unassgined Operators"}
						body_html={this.htmlText}
						cancel_btn={"Okay"}
						closePopup={() => this.operatorsDeptStore.viewallopt = false}
						appStore={appstore}
						singleButton={true}
					/>
				}
				{
				  	this.operatorsDeptStore.deleteallopt && 
                   	<ConfirmationComponent show={this.operatorsDeptStore.deleteallopt}
           		  	 	store={this.operatorsDeptStore}
           		   		title={"All the below operators will be deleted"}
          		   		body_html={this.htmlText}
		   		   		cancel_btn="Cancel"
		   	       		save_btn="Confirm"
		   		   		callBack={() => this.operatorsDeptStore.confirmDeleteallopt(this.props.appstore.userInfo.alloperatorsnotrelated)}
           		   		closePopup={() => this.operatorsDeptStore.deleteallopt = false}
           		   		appStore = {appstore}
					/>
        	    }      
         
			</div>
		)
	}
})
export default OperatorsDeptComponent;
