import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';
import { withBaseIcon } from 'react-icons-kit';
import { exclamationCircle } from 'react-icons-kit/fa/exclamationCircle'
import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import   '../../library/custom-image-crop.css';
const SideIconContainer =
	withBaseIcon({ size: 64, style: { color: '#EF233C' } });

const ImageCropComponent = observer(class ImageCropComponentStore extends Component {


	constructor(props) {
		super(props);
	}
	componentWillMount() {

	}
	// If you setState the crop in here you should return false.
	onImageLoaded = image => {
		this.props.store.imageRef = image;
	};
	onCropChange = (crop, percentCrop) => {
		// You could also use percentCrop:
		// this.setState({ crop: percentCrop });
		// this.setState({ crop });
		this.props.store.cropimage.crop = crop;
	};

	render() {
		const { show,
			store,
			title,
			callBack,
			body_html,
			cancel_btn,
			crop_btn,
			save_btn,
			multi,
			appStore,
			singleButton,
			onCropComplete } = this.props;
		//console.log(this.props.callBack());
		const { crop, src } = store.cropimage;

		return (
			<Modal backdrop='static' className={'imgCropModel '+(appStore ? appStore.selectedColor.value : '')} show={show} centered onHide={() => store.confirm = false}>


				<Modal.Body >
					<div style={{ textAlign: 'center' }}>
						<p>{appStore.getLanguageTrans("Image Crop Utilty")}</p>
						<div>
							{src && (
								<ReactCrop
									src={src}
									crop={crop}
									ruleOfThirds
									onImageLoaded={this.onImageLoaded}
									// onComplete={this.onCropComplete}
									onChange={this.onCropChange}
								/>
							)} 
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					{ multi==0 &&
						<Button className="btn_cancel" type="button" variant="secondary" onClick={() => this.props.closePopup()} >{appStore.getLanguageTrans("cancel")}</Button>
					}
					<Button className="btn_save btn btn-primary" type="button" variant="secondary" onClick={() => this.props.onCropComplete(crop)} >{appStore.getLanguageTrans("crop")}</Button>
					
					</Modal.Footer>
			</Modal>
		)
	}
})
export default ImageCropComponent;
