import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';

import imageURLs from '../../../../sharedFiles/ImagesURLs';

import DropDownComponent from '../../../../sharedComponents/dropDown/dropDown';
import RadioButtonComponent from '../../../../sharedComponents/radionButton2/RadioButton';

import ResponseDropDownComponent from '../../script/components/ResponseDropDown';
import AddConditionComponent from '../components/AddCondition';

const AddEditRulesComponent = observer(class AddEditRulesComponent extends Component {

	constructor (props) {
        super(props);
        this.editScriptRef = React.createRef();
    }

    componentDidMount(){
		if(this.props.ruleObj) {
			//console.log(this.editScriptRef.height());
			this.setDocHeight();
		}
	    
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.ruleObj && nextProps.ruleObj!=this.props.ruleObj) {
			this.setDocHeight();
			//this.props.invitationRuleStore.scrollToPosition(this.editScriptRef.current.offsetTop);
		}
		
	}

	setDocHeight() {
		setTimeout(() => { 
	        let elHeight = document.getElementById('rule_section').clientHeight;
			let docHeight = document.getElementById('innerContainer').clientHeight;
			let windowHeight = window.outerHeight - 61 - 55; 
			this.props.invitationRuleStore.docHeight = windowHeight > elHeight ? docHeight+(window.outerHeight-elHeight - 300) : docHeight;
			this.props.invitationRuleStore.scrollToPosition(windowHeight > elHeight ? this.props.invitationRuleStore.docHeight - elHeight : document.getElementById('rule_section').offsetTop + 55);
      },0);
	}

	validateSecondsField(event) {
		let val;

		var key = window.event ? event.keyCode : event.which;

			if (event.keyCode == 8 || event.keyCode == 46
			 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 9) {
			    val = true;
			}
			// else if ( key < 48 || key > 57 ) {
			//     val = false;
			// }
			else if (((key < 48 || (key <96 && key>57) ) || key > 105) || event.shiftKey) {
			    val = false;
			}
			else val = true;
			if(!val) {
				event.preventDefault();
			}
	}


	getConform(conform){
		let label=""; 
		this.props.invitationRuleStore.conditionConform.filter(function (option) {
			if(conform===option.value)
				label = option.label;
		  });
		return label;
	}
	render(){
		let self = this;
		const { invitationRuleStore,
				editData,
				addResponseLink,
				responseData,
				ruleObj,
				appstore,
				chatInvitation} = this.props;
				 
		return (
			<Form>
						
				<div key={appstore.selectedWebisiteUid} className="settingFormWrapper box editRulesSection m-0"  ref={this.editScriptRef}>

				  <Form.Group as={Row} controlId="formGroupOpenFor">
					    <Form.Label column sm="2" xl="3">{appstore.getLanguageTrans("Will open for:")}</Form.Label>
					    <Col sm="10" xl="9" className="customFormGroup align-self-center">
					    	<div className="customDrpDwn">
								<DropDownComponent dropDownOptions={invitationRuleStore.ruleOpenFor} 
												key={Math.random()} 
					    					   store={invitationRuleStore}
					    					   callBack={(selectedOption) =>{ruleObj.selected_will_open = selectedOption;ruleObj.will_open=selectedOption.value}}
					    					   selectedOption = {ruleObj.selected_will_open}/>
					    	</div>
					    	<span className="align-self-center">{appstore.getLanguageTrans("after")}</span>
					    	<Form.Control className="customInput" type="text" placeholder=""
					    					maxLength="4"
					    					onKeyDown={(e) => {this.validateSecondsField(e)}}
					    					value={ruleObj.time_passed}
										  onChange={(e) => ruleObj.time_passed = e.target.value} required
										  onBlur={(e) => {e.target.value==0 ? ruleObj.time_passed="": ruleObj.time_passed=e.target.value}} />
					    	<span>{appstore.getLanguageTrans("seconds")}</span>

					    </Col>
				  </Form.Group>

				  <Form.Group as={Row} controlId="formGroupConditions align-items-center">
					    <Form.Label column sm="2" xl="3">{appstore.getLanguageTrans("Conditions")}</Form.Label>
					    <Col sm="10" xl="9" className="conditions">
					    	<RadioButtonComponent selectedOption = {ruleObj.conditions_combine}
					    				options={invitationRuleStore.conditions_combine}
					    				groupName = {"conditions"}
					    				callBack={(selectedOption) =>{ruleObj.conditions_combine = selectedOption}}/>
					    	{ ruleObj.conditions.length > 0 &&
						    	<div className="conditionsWrapper col-12 p-0">
							    	<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
											
							    		{
							    			ruleObj.conditions.map(function(condition,conditionIndex) {
							    				return (
							    					<div className={(conditionIndex === 0 || conditionIndex%2 === 0) ? "row conditionsList ruleComponent even" : "row conditionsList ruleComponent odd"} key={conditionIndex}>
							    						<div className="col-12 col-sm-6 col-xl-8">
							    							<p className="text-left">
							    								<span className="text-capitalize">{condition.type}</span>
							    								<span>{" "+self.getConform(condition.conform)}</span>
							    								<span>{" "+condition.mask}</span>
							    							</p>
							    						</div>
							    						<div className="col-12 col-sm-6 col-xl-4 optionArea align-self-center">
															<div className="editWrapper row m-2">
																
																<div className="align-self-center cursor-pointer" onClick={() => invitationRuleStore.editCondition(condition, conditionIndex)}>
																	<p><img src={imageURLs.editIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("Edit")}</span></p>
																</div>
																	<div className="align-self-center cursor-pointer" onClick={() => invitationRuleStore.deleteCondition(condition, conditionIndex)}>
																		<p><img src={imageURLs.deleteIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("Delete")}</span></p>
																	</div>
															</div>
														</div>
							    					</div>
							    				)
							    			})
							    		}
						    		</CustomScroll>
						    	</div>
						    }
					    	<Button className="custom_btn float-left addBtn text-uppercase" onClick={() => invitationRuleStore.editCondition()}>
							{appstore.getLanguageTrans("ADD CONDITION")}
							</Button> 
							{invitationRuleStore.conditionToEdit && 
								<AddConditionComponent invitationRuleStore={invitationRuleStore}/>
							}
					    </Col>
				  </Form.Group>

				  {
				  	chatInvitation && 
				  		<Form.Group as={Row} controlId="formGroupRedirectChats">
						    <Form.Label column sm="2" xl="3">{appstore.getLanguageTrans("Redirect chat to")}</Form.Label>
						    <Col sm="10" xl="9" className="customFormGroup align-self-center drpDwnGrp">
						    	<div className="customDrpDwn">
									<DropDownComponent dropDownOptions={invitationRuleStore.chat_redirect_dept}
													key={Math.random()} 
												    store={invitationRuleStore} 
						    						callBack={(selectedOption) =>{invitationRuleStore.getOpforDept(appstore,ruleObj,selectedOption)}}
						    					    selectedOption = {ruleObj.selected_redirect_dept ? ruleObj.selected_redirect_dept : invitationRuleStore.chat_redirect_dept} />
						    	</div>
						    	<div className="customDrpDwn">
									<DropDownComponent dropDownOptions={invitationRuleStore.chat_redirect_opt} 
												   key={Math.random()} 
												   store={invitationRuleStore} 
												   callBack={(selectedOption) =>{ruleObj.selected_redirect_opt = selectedOption; ruleObj.op_uid = selectedOption.user_uid}}
						    					   selectedOption = {ruleObj.selected_redirect_opt ? ruleObj.selected_redirect_opt : invitationRuleStore.chat_redirect_opt[0]}/>
						    	</div>

						    </Col>
					  </Form.Group>
				  } 
				  <Form.Group as={Row} controlId="formGroupLead">
					    <Form.Label column sm="2" xl="3">{!chatInvitation ? appstore.getLanguageTrans("Invitation message:") : appstore.getLanguageTrans("Invitation message:")}</Form.Label>
					    <Col sm="10" xl="9" className="invitation_msg">
					    	<div className="row m-0">
					    	<RadioButtonComponent selectedOption = {ruleObj.message_action}
					    				options={!chatInvitation ? invitationRuleStore.invitation_msgs : invitationRuleStore.chat_invitation_msg}
					    				groupName={"messages"}
					    				callBack={(selectedOption) =>{ruleObj.message_action = selectedOption}}/>
					    	</div>

					    	{
					    		ruleObj.message_action === 'custom' && 
					    		<React.Fragment>
								<textarea className="col-12" value={ruleObj.new_message} onChange={(e) => ruleObj.new_message = e.target.value} /><br/>
								<span className="error-block">{invitationRuleStore.custom_message_isvalid ? "":"Invitation message was empty*"}</span>
								</React.Fragment>
							}
					    	{ 
					    		ruleObj.message_action === 'related' && 
					    			<Col sm="12 relatedDropDown pl-0">
										<ResponseDropDownComponent dropDownOptions={invitationRuleStore.responseData}
											   key={Math.random()} 
					    					   store={invitationRuleStore} 
					    					   callBack = {(value) => {invitationRuleStore.selectedResponseData = {"typeid": value.type,"id": value.value,relatedids:value.related_id};ruleObj.related_uid=value.related_id;invitationRuleStore.getselectditems(value)}}
											   selectedOption = {invitationRuleStore.selectedResponseData} />
				    				</Col>  
					    	}
					    
					    </Col>
				  </Form.Group>
</div>
				  <div className="row m-0">
					<div className="col-12 formBtns">
						<div className="pullRight">
							<Button className="btn_clr" variant="light" onClick={() => invitationRuleStore.cancelSaveResponse(chatInvitation)}>
						    	<p>{appstore.getLanguageTrans("Cancel")}</p>
						    </Button>
						    <Button className="btn_save" onClick={() => invitationRuleStore.addRuleToParent(ruleObj,chatInvitation)}>
						    	<p>{appstore.getLanguageTrans("Save")}</p>
						    </Button>
						</div>
					</div>
				</div>
			</Form>
		)
	}
})
export default AddEditRulesComponent;
