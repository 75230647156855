import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { Form,FormGroup, ControlLabel, FormControl,Row,Col, Button} from 'react-bootstrap';

import imageURLs from '../../../sharedFiles/ImagesURLs';

import OverviewStore from './OverviewStore';

const OverviewComponent = observer(class OverviewComponent extends Component {

	constructor (props) {
        super(props);
        this.overviewStore = new OverviewStore();
    }
	componentDidMount(){
		
	}

	componentWillUnmount() {
		window.scrollTo(0, 0);
    }


	render(){

		const { appstore } = this.props;

		return (
			<div className="innercontent_wrapper w-100">
				<div className="header_title row align-items-center m-0">
						<img src={imageURLs.overviewIcon} alt="faq" className="img-fluid"/>
						<h4 className="text-left">{appstore.getLanguageTrans("Overview")}</h4>
				</div>
				<div className="row">
					<div className="col-12 w-100 pr-0">
						<div className="innerContentWrapper box overview">

							<div className="section">
								<h5 className="text-uppercase text-left">{appstore.getLanguageTrans("what is the virtual agent?")}</h5>
								<p className="text-left">The Virtual Agent is a set of automated replies for your website live chat. 
								When your chat operators are offline, the Virtual Agent is available to cover any 
								shift 24/7/365. When your chat agents are unavailable, your website visitors can 
								still get live help addressing their questions to the Virtual Agent.</p>

								<p className="text-left">The Virtual Agent analyses the keywords of the question and searches for the best 
								matching reply. All the keywords and replies are defined by the admin users, and you can 
								make the conversation flow almost as a live communication. Here are some tips on how to 
								teach you Virtual Agent to chat like a real person. </p>
							</div>

							<div className="section">
								<h5 className="text-uppercase text-left">{appstore.getLanguageTrans("how to set it up?")}</h5>
								<ol className="text-left">
									<li><p>Teach the Virtual Agent about your business by <a className="cursor-pointer"  onClick={() => appstore.navigate('/admin/virtual_agent/script')}>{appstore.getLanguageTrans("adding your custom responses")}</a></p></li>
									<li><p>Choose a name, image, and default settings for your Virtual Agent on <a className="cursor-pointer"  onClick={() => appstore.navigate('/admin/virtual_agent/settings')}>{appstore.getLanguageTrans("Settings section")}</a></p></li>
									<li><p><a className="cursor-pointer" onClick={() => appstore.navigate('/admin/virtual_agent/invitation_rules')}>{appstore.getLanguageTrans("Setup invitation rules")}</a> to get the attention of your visitors</p></li>
								</ol>
							</div>

							<div className="section">
								<h5 className="text-uppercase text-left">{appstore.getLanguageTrans("How the smart search works: tips and tricks")}</h5>
								<ul className="text-left">
									<li>The visitor's question and Keywords fields are taken into consideration to match a customer's inquiry. The best match defines the reply that the customer will see.</li>
									<li>Keywords have the main priority for the smart search.</li>
									<li>If a keyword for a Basic action and a keyword of a Custom response compete, then the Basic action message wins. You can edit the Basic actions to fit your needs</li>
									<li>The phrase that appears both in the Visitor's question field and in the Keywords field wins over all other matches, including Basic actions.</li>
									<li>If messages with the same keywords compete, then the first in line wins</li>
								</ul>
							</div>

							<div className="section">
								<h5 className="text-uppercase text-left">{appstore.getLanguageTrans("Helpful advice")}</h5>
								<ul className="text-left">
									<li>Use unique keywords for each message.</li>
									<li>Use multi-word keywords to better match your visitor's inquiries.</li>
									<li>Fit replies into 5-15 words like in human chat sessions.</li>
									<li>Ask questions in response to broad inquiries.</li>
									<li>Humor your visitors when they test your system sending weird messages.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
})
export default OverviewComponent;
