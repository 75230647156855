import React, {Component } from 'react';
import { observer } from 'mobx-react';
import { CSVLink} from "react-csv";
 

const chatLengthCSV = observer(class chatLengthCSV extends Component {

	constructor (props) {
        super(props);
    }
	componentWillMount(){

    }
    secondsToTime(secs) {
      var hours = Math.floor(secs / (60 * 60));
  
      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
  
      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);
  
      if (hours == 0 && minutes == 0) {
        return seconds + "s";
      }
      if (hours == 0) {
        return minutes + "m " + seconds + "s";
      }
      else {
        return hours + "h " + minutes + "m " + seconds + "s";
      }
    }
    invexport(data) {
        let exportdata = []
        let chat_Date=0
        let prev_count=0
        if(data == null || !data){
          return exportdata;
        }
        if (data != null) {
        for (let j = 0; j < data.length; j++) {
          if (chat_Date=== data[j].quarter_date) {
            prev_count= Number(prev_count) + Number(data[j].time);
           exportdata[exportdata.length-1].Chattime = prev_count + " s";
           exportdata[exportdata.length-1].Chattimesec = this.secondsToTime(prev_count);
         }
          else{
            data[j].Date = data[j].quarter_date
            data[j].Chattime = data[j].time + " s" ;
            data[j].Chattimesec = this.secondsToTime(data[j].time);
            prev_count=Number(data[j].time)
            chat_Date=data[j].quarter_date
           exportdata.push(data[j])
          }
          
          }
        
        }
        return exportdata;
        
      }

    render() {
      
    let  headers = [
        { label: "Date", key: "quarter_date" },
        { label: "Chats average time(sec)", key: "Chattime" },
        { label: "Chats average time", key: "Chattimesec" },
       
      ];

      let exportdata = this.invexport(this.props.data.avgchartRecords);
      const {appStore}=this.props;
      return(
      <CSVLink  data={exportdata} headers={headers} target="_blank" filename={"Average_Chat_Length_"+this.props.dateselected+".csv"} >
               {appStore.getLanguageTrans("Export To CSV")}
      </CSVLink>
      );
      }


});

export default chatLengthCSV;
