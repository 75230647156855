import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PieChart from 'react-minimal-pie-chart';

const TimePieChartComponent = observer(class TimePieChartComponent extends Component {

    constructor (props) {
        super(props);
    }

    render(){
        const {appstore} = this.props;
        let self = this;


        return (

<PieChart
  animate={false}
  animationDuration={500}
  animationEasing="ease-out"
  cx={50}
  cy={50}
  data={[
   
    {
      color: '#e44e4e',
      title: 'Two',
      value: 30 
    },
  
  ]}
  label
  labelPosition={10}
  labelStyle={{
    fill: '#121212',
    fontFamily: 'sans-serif',
    fontSize: '25px'
  }}
  lengthAngle={360}
  lineWidth={100}
  onClick={undefined}
  onMouseOut={undefined}
  onMouseOver={undefined}
  paddingAngle={0}
  radius={50}
  rounded={false}
  startAngle={0}
  style={{
    height: '200px'
  }}
  viewBoxSize={[
    100,
    100
  ]}
/>


        )
}
})

export default TimePieChartComponent;