import React, {Component } from 'react';
import { observer } from 'mobx-react';

import imageURLs from '../../../../sharedFiles/ImagesURLs'

const RuleComponent = observer(class RuleComponent extends Component {

	constructor (props) {
		super(props);  
    }
	componentWillMount(){ 

	}	  
	getConfirm(conform){
		let label="";
		
		this.props.invitationRulesStore.conditionConform.filter(function (option) {
			if(conform===option.value)
				label = option.label;
		  });
		return label;
	}
	//Website Name Displaying by using websiteid
	getwebsitesName(wbisteid){ 
		  let label="";		
		this.props.appstore.userInfo.allwebistes.map(function(condition,conditionIndex) {
			if(wbisteid===condition.website_uid)
				label = condition.name;
		  });
		  return label;
	}
	//Department Name Displaying by using Departmentid
	getDepartmentName(department_uid){ 
		let label="";
		try{		
	    this.props.appstore.deptOps.allDept.map(function(condition,conditionIndex) {
		  if(department_uid===condition.department_uid)
			  label = condition.name;
		});
		}
		catch(err){
			console.log(err);
		}
		return label;
	}
	//Operator Name Displaying by using Operatorid
	getOperatorName(op_id){ 
		let label="";		
	  this.props.appstore.userInfo.alloperators.map(function(condition,conditionIndex) {
		  if(op_id===condition.user_uid)
			  label = condition.nick_name;
		});
		return label;
	}

	render(){

		let self = this;

		const { invitationRulesStore,
				rule,
				pos,
				appstore
				 } = this.props;
	
		return (
			<div  className={((pos === 0 || pos%2 === 0) ? "row ruleComponent even " : "row ruleComponent odd ")+appstore.selectedColor.value}>
				<div className="align-self-center">
					<img  style={{opacity:0.5}} src={"#3ba8c5" == appstore.selectedColor.color ? imageURLs.arrow_steelblue : "#4cb6ac" == appstore.selectedColor.color ? imageURLs.arrow_seagreen : "#ec407a" == appstore.selectedColor.color ?  imageURLs.arrow_pink : "#6e4c42" == appstore.selectedColor.color ?imageURLs.arrow_brown : "#234e91" == appstore.selectedColor.color ?imageURLs.arrow_navyblue : "#e89712" == appstore.selectedColor.color ?imageURLs.arrow_yellow : "#b968c7" ==appstore.selectedColor.color ?imageURLs.arrow_voilet : 
									"#039be6" == appstore.selectedColor.color ? imageURLs.arrow_blue : "#00887a" == appstore.selectedColor.color ? imageURLs.arrow_darkgreen : "#4b8f4e" == appstore.selectedColor.color ? imageURLs.arrow_green : "#5f7c8c" == appstore.selectedColor.color ? imageURLs.arrow_grey : "#64a9e0"== appstore.selectedColor.color ? imageURLs.arrow_skyblue : imageURLs.arrow_steelblue} alt="faq" className="img-fluid"/>
				</div>
				<div className="rule_data">
					<div className="row">
						<div className='col-12 col-sm-8 col-xl-9 text-left'>
							<p className="time">{appstore.getLanguageTrans("After")} {rule.time_passed} {appstore.getLanguageTrans("seconds")} {rule.conditions.length>0 ? 
																				 rule.conditions_combine === '||' ? 
																				 appstore.getLanguageTrans(', if any of the following applies:') : appstore.getLanguageTrans(', if all the conditions apply:')
																				  : ''}</p>
							{
								rule.conditions.length>0 && 
								<ul>
									{
										rule.conditions.map(function(condition,conditionIndex) {
											return(
												<li key={conditionIndex}>
													<p>
														<span className="text-capitalize">{condition.type}</span>
														<span>{" "+self.getConfirm(condition.conform)}</span>
														<span>{" "+condition.mask}</span>
													</p>
												</li>
											)
											
										})
									}
								</ul>
							} 
							{
								rule.will_open !== 'all' && 
									<p>only to {rule.will_open === 'lead' ? appstore.getLanguageTrans('first time vistors') : appstore.getLanguageTrans('returning visitors')}</p>
							}
							{
								rule.websites.length > 0 && 
								// <p>only at {rule.websites[0]}</p>
									<p>only at {self.getwebsitesName(rule.website_uid)}</p>
							}
							{
								rule.message_action !== 'related' && 
									<p>{rule.message_action === 'default' ? appstore.getLanguageTrans('show default message') : appstore.getLanguageTrans('show message "')+rule.new_message+'"'}</p>
							}
							{
								rule.website_uid !== '' &&
									<p> {appstore.getLanguageTrans("and redirect chat to")} {appstore.getLanguageTrans("Website")}: {self.getwebsitesName(rule.website_uid)} 
									{rule.department_uid !== '' && <span>,{appstore.getLanguageTrans("Department")}: {self.getDepartmentName(rule.department_uid)} </span>}
									{rule.op_uid !== '' && <span>,{appstore.getLanguageTrans("Operator")}: {self.getOperatorName(rule.op_uid)}</span>} </p>
							}
						</div>
							<div className="col-12 col-sm-4 col-xl-3 optionArea align-self-center">
								<div className="editWrapper row m-0">
									
									<div className="align-self-center cursor-pointer" onClick={() => invitationRulesStore.editRuleData(rule)}>
										<p><img src={imageURLs.editIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("edit")}</span></p>
									</div>
										<div className="align-self-center cursor-pointer" onClick={() => invitationRulesStore.deleteRule(rule)}>
											<p><img src={imageURLs.deleteIcon}/><span className="text-uppercase">{appstore.getLanguageTrans("delete")}</span></p>
										</div>
								</div>
							</div>
					</div>
				</div>
			</div>
		)
	}
})
export default RuleComponent;
