import React, {Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import { Button} from 'react-bootstrap';

import imageURLs from '../../sharedFiles/ImagesURLs';
import { withBaseIcon } from 'react-icons-kit';
import {exclamationCircle} from 'react-icons-kit/fa/exclamationCircle'
const SideIconContainer  = 
withBaseIcon({ size: 64, style: {color: '#EF233C'}});
	
const WarningComponent = observer(class WarningComponentStore extends Component {

	
	constructor (props) {
        super(props);
    }
	componentWillMount(){

	}


	render(){
		const {show, 
			   store,
                title, 
				callBack,
				body_html,
				cancel_btn,
				save_btn,
				appStore,
			    singleButton} = this.props;
				//console.log(this.props.callBack());
				
		return (
			<Modal backdrop='static' className={appStore ? appStore.selectedColor.value : ''} show = {show} centered onHide={() => store.confirm = false}>
				 

				  <Modal.Body >
					  <div style={{textAlign: 'center'}}>
				  <SideIconContainer icon={exclamationCircle}/> 
				  <p>OOPS! something went wrong.!</p>
				  </div>
				  </Modal.Body>

				  <Modal.Footer>
				    <Button className="btn_cancel" type="button" variant="secondary" onClick={() => this.props.closePopup()} >{appStore.getLanguageTrans(cancel_btn)}</Button>  
				  </Modal.Footer>                      
				</Modal>
		)
	}
})
export default WarningComponent;
